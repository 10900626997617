import React from "react";
import { linecss, shortNumber } from "./vaxx";

/*const mortalagenj = {
  "1960-1969": [
    {
      age: "0",
      dx: 2260
    },
    {
      age: "1-4",
      dx: 321
    },
    {
      age: "5-9",
      dx: 198
    },
    {
      age: "10-14",
      dx: 172
    },
    {
      age: "15-19",
      dx: 376
    },
    {
      age: "20-24",
      dx: 518
    },
    {
      age: "25-29",
      dx: 555
    },
    {
      age: "30-34",
      dx: 693
    },
    {
      age: "35-39",
      dx: 1037
    },
    {
      age: "40-44",
      dx: 1609
    },
    {
      age: "45-49",
      dx: 2571
    },
    {
      age: "50-54",
      dx: 4056
    },
    {
      age: "55-59",
      dx: 5868
    },
    {
      age: "60-64",
      dx: 8259
    },
    {
      age: "65-69",
      dx: 10924
    },
    {
      age: "70-74",
      dx: 13510
    },
    {
      age: "75-79",
      dx: 14832
    },
    {
      age: "80-84",
      dx: 14149
    },
    {
      age: "85-89",
      dx: 10651
    },
    {
      age: "90-94",
      dx: 5435
    },
    {
      age: "95-99",
      dx: 1692
    },
    {
      age: "100-104",
      dx: 289
    },
    {
      age: "105-109",
      dx: 25
    },
    {
      age: "110",
      dx: 1
    }
  ],
  "1970-1979": [
    {
      age: "0",
      dx: 1581
    },
    {
      age: "1-4",
      dx: 255
    },
    {
      age: "5-9",
      dx: 163
    },
    {
      age: "10-14",
      dx: 150
    },
    {
      age: "15-19",
      dx: 404
    },
    {
      age: "20-24",
      dx: 591
    },
    {
      age: "25-29",
      dx: 549
    },
    {
      age: "30-34",
      dx: 644
    },
    {
      age: "35-39",
      dx: 901
    },
    {
      age: "40-44",
      dx: 1413
    },
    {
      age: "45-49",
      dx: 2265
    },
    {
      age: "50-54",
      dx: 3409
    },
    {
      age: "55-59",
      dx: 5056
    },
    {
      age: "60-64",
      dx: 7279
    },
    {
      age: "65-69",
      dx: 9754
    },
    {
      age: "70-74",
      dx: 12478
    },
    {
      age: "75-79",
      dx: 14802
    },
    {
      age: "80-84",
      dx: 15102
    },
    {
      age: "85-89",
      dx: 12459
    },
    {
      age: "90-94",
      dx: 7346
    },
    {
      age: "95-99",
      dx: 2760
    },
    {
      age: "100-104",
      dx: 574
    },
    {
      age: "105-109",
      dx: 61
    },
    {
      age: "110",
      dx: 3
    }
  ],
  "1980-1989": [
    {
      age: "0",
      dx: 1074
    },
    {
      age: "1-4",
      dx: 196
    },
    {
      age: "5-9",
      dx: 123
    },
    {
      age: "10-14",
      dx: 119
    },
    {
      age: "15-19",
      dx: 326
    },
    {
      age: "20-24",
      dx: 470
    },
    {
      age: "25-29",
      dx: 577
    },
    {
      age: "30-34",
      dx: 740
    },
    {
      age: "35-39",
      dx: 898
    },
    {
      age: "40-44",
      dx: 1180
    },
    {
      age: "45-49",
      dx: 1847
    },
    {
      age: "50-54",
      dx: 2893
    },
    {
      age: "55-59",
      dx: 4406
    },
    {
      age: "60-64",
      dx: 6471
    },
    {
      age: "65-69",
      dx: 8849
    },
    {
      age: "70-74",
      dx: 11846
    },
    {
      age: "75-79",
      dx: 13961
    },
    {
      age: "80-84",
      dx: 15834
    },
    {
      age: "85-89",
      dx: 14161
    },
    {
      age: "90-94",
      dx: 9304
    },
    {
      age: "95-99",
      dx: 3784
    },
    {
      age: "100-104",
      dx: 843
    },
    {
      age: "105-109",
      dx: 92
    },
    {
      age: "110",
      dx: 5
    }
  ],
  "1990-1999": [
    {
      age: "0",
      dx: 810
    },
    {
      age: "1-4",
      dx: 142
    },
    {
      age: "5-9",
      dx: 85
    },
    {
      age: "10-14",
      dx: 91
    },
    {
      age: "15-19",
      dx: 270
    },
    {
      age: "20-24",
      dx: 410
    },
    {
      age: "25-29",
      dx: 509
    },
    {
      age: "30-34",
      dx: 732
    },
    {
      age: "35-39",
      dx: 1004
    },
    {
      age: "40-44",
      dx: 1296
    },
    {
      age: "45-49",
      dx: 1684
    },
    {
      age: "50-54",
      dx: 2406
    },
    {
      age: "55-59",
      dx: 3659
    },
    {
      age: "60-64",
      dx: 5479
    },
    {
      age: "65-69",
      dx: 7896
    },
    {
      age: "70-74",
      dx: 10672
    },
    {
      age: "75-79",
      dx: 13367
    },
    {
      age: "80-84",
      dx: 16097
    },
    {
      age: "85-89",
      dx: 15615
    },
    {
      age: "90-94",
      dx: 11382
    },
    {
      age: "95-99",
      dx: 5057
    },
    {
      age: "100-104",
      dx: 1198
    },
    {
      age: "105-109",
      dx: 132
    },
    {
      age: "110",
      dx: 7
    }
  ],
  "2000-2009": [
    {
      age: "0",
      dx: 584
    },
    {
      age: "1-4",
      dx: 82
    },
    {
      age: "5-9",
      dx: 50
    },
    {
      age: "10-14",
      dx: 67
    },
    {
      age: "15-19",
      dx: 230
    },
    {
      age: "20-24",
      dx: 434
    },
    {
      age: "25-29",
      dx: 436
    },
    {
      age: "30-34",
      dx: 485
    },
    {
      age: "35-39",
      dx: 651
    },
    {
      age: "40-44",
      dx: 983
    },
    {
      age: "45-49",
      dx: 1453
    },
    {
      age: "50-54",
      dx: 2112
    },
    {
      age: "55-59",
      dx: 3017
    },
    {
      age: "60-64",
      dx: 4456
    },
    {
      age: "65-69",
      dx: 6472
    },
    {
      age: "70-74",
      dx: 9317
    },
    {
      age: "75-79",
      dx: 12825
    },
    {
      age: "80-84",
      dx: 16653
    },
    {
      age: "85-89",
      dx: 18103
    },
    {
      age: "90-94",
      dx: 13818
    },
    {
      age: "95-99",
      dx: 6249
    },
    {
      age: "100-104",
      dx: 1388
    },
    {
      age: "105-109",
      dx: 132
    },
    {
      age: "110",
      dx: 5
    }
  ],
  "2010-2018": [
    {
      age: "0",
      dx: 446
    },
    {
      age: "1-4",
      dx: 72
    },
    {
      age: "5-9",
      dx: 46
    },
    {
      age: "10-14",
      dx: 53
    },
    {
      age: "15-19",
      dx: 170
    },
    {
      age: "20-24",
      dx: 426
    },
    {
      age: "25-29",
      dx: 523
    },
    {
      age: "30-34",
      dx: 561
    },
    {
      age: "35-39",
      dx: 627
    },
    {
      age: "40-44",
      dx: 810
    },
    {
      age: "45-49",
      dx: 1245
    },
    {
      age: "50-54",
      dx: 1896
    },
    {
      age: "55-59",
      dx: 2752
    },
    {
      age: "60-64",
      dx: 3891
    },
    {
      age: "65-69",
      dx: 5482
    },
    {
      age: "70-74",
      dx: 7953
    },
    {
      age: "75-79",
      dx: 11284
    },
    {
      age: "80-84",
      dx: 15415
    },
    {
      age: "85-89",
      dx: 18582
    },
    {
      age: "90-94",
      dx: 16492
    },
    {
      age: "95-99",
      dx: 8723
    },
    {
      age: "100-104",
      dx: 2288
    },
    {
      age: "105-109",
      dx: 252
    },
    {
      age: "110",
      dx: 11
    }
  ]
};*/
//usa.mortality.org
const usmortality = {
  "1965": [
    {
      age: "0",
      dx: 2519,
      pop: 3947
    },
    {
      age: "1-4",
      dx: 394,
      pop: 16218
    },
    {
      age: "5-9",
      dx: 221,
      pop: 20236
    },
    {
      age: "10-14",
      dx: 205,
      pop: 18547
    },
    {
      age: "15-19",
      dx: 440,
      pop: 16155
    },
    {
      age: "20-24",
      dx: 593,
      pop: 12941
    },
    {
      age: "25-29",
      dx: 621,
      pop: 11051
    },
    {
      age: "30-34",
      dx: 769,
      pop: 11144
    },
    {
      age: "35-39",
      dx: 1110,
      pop: 12133
    },
    {
      age: "40-44",
      dx: 1687,
      pop: 12330
    },
    {
      age: "45-49",
      dx: 2625,
      pop: 11232
    },
    {
      age: "50-54",
      dx: 4027,
      pop: 10324
    },
    {
      age: "55-59",
      dx: 5670,
      pop: 9324
    },
    {
      age: "60-64",
      dx: 7826,
      pop: 7433
    },
    {
      age: "65-69",
      dx: 10338,
      pop: 6492
    },
    {
      age: "70-74",
      dx: 12787,
      pop: 5267
    },
    {
      age: "75-79",
      dx: 14321,
      pop: 3429
    },
    {
      age: "80-84",
      dx: 14658,
      pop: 1899
    },
    {
      age: "85-89",
      dx: 11292,
      pop: 300
    }, //1040
    {
      age: "90-94",
      dx: 5758,
      pop: 250
    },
    {
      age: "95-99",
      dx: 1808,
      pop: 200
    },
    {
      age: "100-104",
      dx: 304,
      pop: 150
    },
    {
      age: "105-109",
      dx: 25,
      pop: 75
    },
    {
      age: "110",
      dx: 1,
      pop: 25
    }
  ],
  "1970": [
    {
      age: "0",
      dx: 2250,
      pop: 3413
    },
    {
      age: "1-4",
      dx: 360,
      pop: 13963
    },
    {
      age: "5-9",
      dx: 213,
      pop: 20243
    },
    {
      age: "10-14",
      dx: 200,
      pop: 20641
    },
    {
      age: "15-19",
      dx: 513,
      pop: 18622
    },
    {
      age: "20-24",
      dx: 651,
      pop: 15767
    },
    {
      age: "25-29",
      dx: 666,
      pop: 13119
    },
    {
      age: "30-34",
      dx: 811,
      pop: 11287
    },
    {
      age: "35-39",
      dx: 1149,
      pop: 11155
    },
    {
      age: "40-44",
      dx: 1748,
      pop: 12088
    },
    {
      age: "45-49",
      dx: 2629,
      pop: 12053
    },
    {
      age: "50-54",
      dx: 3970,
      pop: 10966
    },
    {
      age: "55-59",
      dx: 5658,
      pop: 9922
    },
    {
      age: "60-64",
      dx: 7736,
      pop: 8467
    },
    {
      age: "65-69",
      dx: 10139,
      pop: 6880
    },
    {
      age: "70-74",
      dx: 12599,
      pop: 5421
    },
    {
      age: "75-79",
      dx: 14368,
      pop: 3844
    },
    {
      age: "80-84",
      dx: 14424,
      pop: 2228
    },
    {
      age: "85-89",
      dx: 11441,
      pop: 400
    }, //1307
    {
      age: "90-94",
      dx: 6108,
      pop: 325
    },
    {
      age: "95-99",
      dx: 1984,
      pop: 275
    },
    {
      age: "100-104",
      dx: 351,
      pop: 200
    },
    {
      age: "105-109",
      dx: 31,
      pop: 75
    },
    {
      age: "110",
      dx: 1,
      pop: 25
    }
  ],
  "1975": [
    {
      age: "0",
      dx: 1848,
      pop: 3065
    },
    {
      age: "1-4",
      dx: 314,
      pop: 13421
    },
    {
      age: "5-9",
      dx: 200,
      pop: 17805
    },
    {
      age: "10-14",
      dx: 195,
      pop: 20911
    },
    {
      age: "15-19",
      dx: 534,
      pop: 20978
    },
    {
      age: "20-24",
      dx: 694,
      pop: 18758
    },
    {
      age: "25-29",
      dx: 674,
      pop: 16428
    },
    {
      age: "30-34",
      dx: 790,
      pop: 13644
    },
    {
      age: "35-39",
      dx: 1111,
      pop: 11400
    },
    {
      age: "40-44",
      dx: 1674,
      pop: 11355
    },
    {
      age: "45-49",
      dx: 2569,
      pop: 11842
    },
    {
      age: "50-54",
      dx: 3728,
      pop: 11958
    },
    {
      age: "55-59",
      dx: 5388,
      pop: 10386
    },
    {
      age: "60-64",
      dx: 7496,
      pop: 9327
    },
    {
      age: "65-69",
      dx: 9675,
      pop: 7864
    },
    {
      age: "70-74",
      dx: 12202,
      pop: 5710
    },
    {
      age: "75-79",
      dx: 14259,
      pop: 4126
    },
    {
      age: "80-84",
      dx: 14539,
      pop: 2655
    },
    {
      age: "85-89",
      dx: 12081,
      pop: 500
    }, //1706
    {
      age: "90-94",
      dx: 6983,
      pop: 425
    },
    {
      age: "95-99",
      dx: 2496,
      pop: 350
    },
    {
      age: "100-104",
      dx: 496,
      pop: 275
    },
    {
      age: "105-109",
      dx: 50,
      pop: 125
    },
    {
      age: "110",
      dx: 2,
      pop: 25
    }
  ],
  "1980": [
    {
      age: "0",
      dx: 1465,
      pop: 3426
    },
    {
      age: "1-4",
      dx: 265,
      pop: 12637
    },
    {
      age: "5-9",
      dx: 164,
      pop: 16947
    },
    {
      age: "10-14",
      dx: 165,
      pop: 18445
    },
    {
      age: "15-19",
      dx: 482,
      pop: 21348
    },
    {
      age: "20-24",
      dx: 640,
      pop: 21096
    },
    {
      age: "25-29",
      dx: 623,
      pop: 19077
    },
    {
      age: "30-34",
      dx: 674,
      pop: 16961
    },
    {
      age: "35-39",
      dx: 930,
      pop: 13592
    },
    {
      age: "40-44",
      dx: 1428,
      pop: 11522
    },
    {
      age: "45-49",
      dx: 2224,
      pop: 11212
    },
    {
      age: "50-54",
      dx: 3368,
      pop: 11724
    },
    {
      age: "55-59",
      dx: 4859,
      pop: 11582
    },
    {
      age: "60-64",
      dx: 6885,
      pop: 9866
    },
    {
      age: "65-69",
      dx: 9064,
      pop: 8745
    },
    {
      age: "70-74",
      dx: 11478,
      pop: 6593
    },
    {
      age: "75-79",
      dx: 13740,
      pop: 4724
    },
    {
      age: "80-84",
      dx: 15019,
      pop: 2875
    },
    {
      age: "85-89",
      dx: 13342,
      pop: 600
    }, //2197
    {
      age: "90-94",
      dx: 8672,
      pop: 500
    },
    {
      age: "95-99",
      dx: 3572,
      pop: 400
    },
    {
      age: "100-104",
      dx: 835,
      pop: 300
    },
    {
      age: "105-109",
      dx: 99,
      pop: 200
    },
    {
      age: "110",
      dx: 6,
      pop: 100
    }
  ],
  "1985": [
    {
      age: "0",
      dx: 1161,
      pop: 3572
    },
    {
      age: "1-4",
      dx: 228,
      pop: 14123
    },
    {
      age: "5-9",
      dx: 137,
      pop: 16338
    },
    {
      age: "10-14",
      dx: 142,
      pop: 17450
    },
    {
      age: "15-19",
      dx: 423,
      pop: 18931
    },
    {
      age: "20-24",
      dx: 575,
      pop: 21529
    },
    {
      age: "25-29",
      dx: 588,
      pop: 21459
    },
    {
      age: "30-34",
      dx: 646,
      pop: 19503
    },
    {
      age: "35-39",
      dx: 831,
      pop: 16867
    },
    {
      age: "40-44",
      dx: 1244,
      pop: 13636
    },
    {
      age: "45-49",
      dx: 1973,
      pop: 11429
    },
    {
      age: "50-54",
      dx: 3073,
      pop: 10957
    },
    {
      age: "55-59",
      dx: 4583,
      pop: 11352
    },
    {
      age: "60-64",
      dx: 6522,
      pop: 10803
    },
    {
      age: "65-69",
      dx: 8803,
      pop: 9214
    },
    {
      age: "70-74",
      dx: 11415,
      pop: 7412
    },
    {
      age: "75-79",
      dx: 13604,
      pop: 5374
    },
    {
      age: "80-84",
      dx: 15344,
      pop: 3282
    },
    {
      age: "85-89",
      dx: 13995,
      pop: 700
    }, //2595
    {
      age: "90-94",
      dx: 9466,
      pop: 600
    },
    {
      age: "95-99",
      dx: 4107,
      pop: 525
    },
    {
      age: "100-104",
      dx: 1006,
      pop: 350
    },
    {
      age: "105-109",
      dx: 125,
      pop: 275
    },
    {
      age: "110",
      dx: 8,
      pop: 150
    }
  ],
  "1990": [
    {
      age: "0",
      dx: 1040,
      pop: 3858
    },
    {
      age: "1-4",
      dx: 204,
      pop: 14650
    },
    {
      age: "5-9",
      dx: 121,
      pop: 17917
    },
    {
      age: "10-14",
      dx: 136,
      pop: 16797
    },
    {
      age: "15-19",
      dx: 413,
      pop: 18133
    },
    {
      age: "20-24",
      dx: 542,
      pop: 19258
    },
    {
      age: "25-29",
      dx: 589,
      pop: 21560
    },
    {
      age: "30-34",
      dx: 715,
      pop: 21676
    },
    {
      age: "35-39",
      dx: 893,
      pop: 19455
    },
    {
      age: "40-44",
      dx: 1202,
      pop: 16960
    },
    {
      age: "45-49",
      dx: 1818,
      pop: 13421
    },
    {
      age: "50-54",
      dx: 2854,
      pop: 11212
    },
    {
      age: "55-59",
      dx: 4314,
      pop: 10534
    },
    {
      age: "60-64",
      dx: 6299,
      pop: 10707
    },
    {
      age: "65-69",
      dx: 8492,
      pop: 9962
    },
    {
      age: "70-74",
      dx: 11236,
      pop: 7902
    },
    {
      age: "75-79",
      dx: 13561,
      pop: 6027
    },
    {
      age: "80-84",
      dx: 15480,
      pop: 3823
    },
    {
      age: "85-89",
      dx: 14506,
      pop: 750
    }, //2968
    {
      age: "90-94",
      dx: 10052,
      pop: 650
    },
    {
      age: "95-99",
      dx: 4369,
      pop: 550
    },
    {
      age: "100-104",
      dx: 1038,
      pop: 450
    },
    {
      age: "105-109",
      dx: 120,
      pop: 350
    },
    {
      age: "110",
      dx: 7,
      pop: 250
    }
  ],
  "1995": [
    {
      age: "0",
      dx: 890,
      pop: 3870
    },
    {
      age: "1-4",
      dx: 178,
      pop: 15774
    },
    {
      age: "5-9",
      dx: 104,
      pop: 18859
    },
    {
      age: "10-14",
      dx: 126,
      pop: 18753
    },
    {
      age: "15-19",
      dx: 423,
      pop: 17616
    },
    {
      age: "20-24",
      dx: 530,
      pop: 18326
    },
    {
      age: "25-29",
      dx: 587,
      pop: 19176
    },
    {
      age: "30-34",
      dx: 751,
      pop: 22177
    },
    {
      age: "35-39",
      dx: 966,
      pop: 21961
    },
    {
      age: "40-44",
      dx: 1247,
      pop: 19699
    },
    {
      age: "45-49",
      dx: 1751,
      pop: 16679
    },
    {
      age: "50-54",
      dx: 2615,
      pop: 13191
    },
    {
      age: "55-59",
      dx: 3984,
      pop: 10936
    },
    {
      age: "60-64",
      dx: 5869,
      pop: 10082
    },
    {
      age: "65-69",
      dx: 8089,
      pop: 9970
    },
    {
      age: "70-74",
      dx: 10658,
      pop: 8741
    },
    {
      age: "75-79",
      dx: 13158,
      pop: 6574
    },
    {
      age: "80-84",
      dx: 15630,
      pop: 4351
    },
    {
      age: "85-89",
      dx: 15052,
      pop: 900
    }, //3522
    {
      age: "90-94",
      dx: 10972,
      pop: 800
    },
    {
      age: "95-99",
      dx: 5010,
      pop: 600
    },
    {
      age: "100-104",
      dx: 1251,
      pop: 500
    },
    {
      age: "105-109",
      dx: 151,
      pop: 400
    },
    {
      age: "110",
      dx: 9,
      pop: 300
    }
  ],
  "2000": [
    {
      age: "0",
      dx: 751,
      pop: 3797
    },
    {
      age: "1-4",
      dx: 146,
      pop: 15353
    },
    {
      age: "5-9",
      dx: 90,
      pop: 19738
    },
    {
      age: "10-14",
      dx: 112,
      pop: 19040
    },
    {
      age: "15-19",
      dx: 367,
      pop: 19068
    },
    {
      age: "20-24",
      dx: 475,
      pop: 17512
    },
    {
      age: "25-29",
      dx: 487,
      pop: 18869
    },
    {
      age: "30-34",
      dx: 630,
      pop: 20741
    },
    {
      age: "35-39",
      dx: 854,
      pop: 22625
    },
    {
      age: "40-44",
      dx: 1195,
      pop: 21373
    },
    {
      age: "45-49",
      dx: 1672,
      pop: 18470
    },
    {
      age: "50-54",
      dx: 2431,
      pop: 15163
    },
    {
      age: "55-59",
      dx: 3698,
      pop: 11757
    },
    {
      age: "60-64",
      dx: 5555,
      pop: 10056
    },
    {
      age: "65-69",
      dx: 7739,
      pop: 9762
    },
    {
      age: "70-74",
      dx: 10509,
      pop: 8736
    },
    {
      age: "75-79",
      dx: 13217,
      pop: 7063
    },
    {
      age: "80-84",
      dx: 16221,
      pop: 4642
    },
    {
      age: "85-89",
      dx: 15971,
      pop: 900
    }, //3871
    {
      age: "90-94",
      dx: 11539,
      pop: 800
    },
    {
      age: "95-99",
      dx: 5060,
      pop: 700
    },
    {
      age: "100-104",
      dx: 1153,
      pop: 600
    },
    {
      age: "105-109",
      dx: 121,
      pop: 500
    },
    {
      age: "110",
      dx: 6,
      pop: 400
    }
  ],
  "2005": [
    {
      age: "0",
      dx: 703,
      pop: 4000
    },
    {
      age: "1-4",
      dx: 126,
      pop: 15940
    },
    {
      age: "5-9",
      dx: 75,
      pop: 19636
    },
    {
      age: "10-14",
      dx: 96,
      pop: 21176
    },
    {
      age: "15-19",
      dx: 327,
      pop: 20313
    },
    {
      age: "20-24",
      dx: 468,
      pop: 20313
    },
    {
      age: "25-29",
      dx: 469,
      pop: 20339
    },
    {
      age: "30-34",
      dx: 546,
      pop: 19008
    },
    {
      age: "35-39",
      dx: 773,
      pop: 21786
    },
    {
      age: "40-44",
      dx: 1152,
      pop: 21787
    },
    {
      age: "45-49",
      dx: 1689,
      pop: 20534
    },
    {
      age: "50-54",
      dx: 2375,
      pop: 20534
    },
    {
      age: "55-59",
      dx: 3447,
      pop: 14187
    },
    {
      age: "60-64",
      dx: 5096,
      pop: 14188
    },
    {
      age: "65-69",
      dx: 7194,
      pop: 9119
    },
    {
      age: "70-74",
      dx: 9910,
      pop: 9119
    },
    {
      age: "75-79",
      dx: 13031,
      pop: 6425
    },
    {
      age: "80-84",
      dx: 16468,
      pop: 6425
    },
    {
      age: "85-89",
      dx: 17004,
      pop: 1370
    }, //3571
    {
      age: "90-94",
      dx: 12491,
      pop: 900
    },
    {
      age: "95-99",
      dx: 5327,
      pop: 700
    },
    {
      age: "100-104",
      dx: 1126,
      pop: 300
    },
    {
      age: "105-109",
      dx: 103,
      pop: 200
    },
    {
      age: "110",
      dx: 4,
      pop: 100
    }
  ],
  "2010": [
    {
      age: "0",
      dx: 687,
      pop: 5000
    },
    {
      age: "1-4",
      dx: 115,
      pop: 15201
    },
    {
      age: "5-9",
      dx: 67,
      pop: 20349
    },
    {
      age: "10-14",
      dx: 80,
      pop: 20677
    },
    {
      age: "15-19",
      dx: 292,
      pop: 22040
    },
    {
      age: "20-24",
      dx: 472,
      pop: 21586
    },
    {
      age: "25-29",
      dx: 494,
      pop: 21102
    },
    {
      age: "30-34",
      dx: 549,
      pop: 19962
    },
    {
      age: "35-39",
      dx: 713,
      pop: 20180
    },
    {
      age: "40-44",
      dx: 1075,
      pop: 20891
    },
    {
      age: "45-49",
      dx: 1629,
      pop: 22709
    },
    {
      age: "50-54",
      dx: 2370,
      pop: 22298
    },
    {
      age: "55-59",
      dx: 3252,
      pop: 19665
    },
    {
      age: "60-64",
      dx: 4634,
      pop: 16818
    },
    {
      age: "65-69",
      dx: 6483,
      pop: 12435
    },
    {
      age: "70-74",
      dx: 9048,
      pop: 9278
    },
    {
      age: "75-79",
      dx: 12248,
      pop: 7318
    },
    {
      age: "80-84",
      dx: 15912,
      pop: 5743
    },
    {
      age: "85-89",
      dx: 17644,
      pop: 2789 /** 3620*/
    },
    {
      age: "90-94",
      dx: 14105,
      pop: 1448
    },
    {
      age: "95-99",
      dx: 6496,
      pop: 371
    },
    {
      age: "100-104",
      dx: 1487,
      pop: 33
    },
    {
      age: "105-109",
      dx: 145,
      pop: 20
    },
    {
      age: "110",
      dx: 6,
      pop: 10
    }
  ],
  "2015": [
    {
      age: "0",
      dx: 598,
      pop: 4000
    },
    {
      age: "1-4",
      dx: 103,
      pop: 15865
    },
    {
      age: "5-9",
      dx: 58,
      pop: 20522
    },
    {
      age: "10-14",
      dx: 70,
      pop: 20642
    },
    {
      age: "15-19",
      dx: 232,
      pop: 20807
    },
    {
      age: "20-24",
      dx: 420,
      pop: 22278
    },
    {
      age: "25-29",
      dx: 480,
      pop: 21474
    },
    {
      age: "30-34",
      dx: 557,
      pop: 20974
    },
    {
      age: "35-39",
      dx: 686,
      pop: 19407
    },
    {
      age: "40-44",
      dx: 968,
      pop: 20382
    },
    {
      age: "45-49",
      dx: 1507,
      pop: 20668
    },
    {
      age: "50-54",
      dx: 2301,
      pop: 22355
    },
    {
      age: "55-59",
      dx: 3268,
      pop: 21109
    },
    {
      age: "60-64",
      dx: 4439,
      pop: 18440
    },
    {
      age: "65-69",
      dx: 6093,
      pop: 14986
    },
    {
      age: "70-74",
      dx: 8523,
      pop: 10805
    },
    {
      age: "75-79",
      dx: 11708,
      pop: 7804 //75-79 23.5%+/5yrs+ 2015-2020 7.804m-9643.m
    },
    {
      age: "80-84",
      dx: 15248,
      pop: 5627 //80-84 9.15%/5yrs+ 2015-2020 5.627m-6.142m
    },
    {
      age: "85-89",
      dx: 17845,
      pop: 1800
    }, //5255
    {
      age: "90-94",
      dx: 15306,
      pop: 1200
    },
    {
      age: "95-99",
      dx: 7539,
      pop: 1000
    },
    {
      age: "100-104",
      dx: 1855,
      pop: 600
    },
    {
      age: "105-109",
      dx: 192,
      pop: 400
    },
    {
      age: "110",
      dx: 8,
      pop: 200
    }
  ],
  /*"2015-2018": [
    {
      age: "0",
      dx: 575,
      pop: 5000
    },
    {
      age: "1-4",
      dx: 98,
      pop: 14580
    },
    {
      age: "5-9",
      dx: 58,
      pop: 20290
    },
    {
      age: "10-14",
      dx: 74,
      pop: 20800
    },
    {
      age: "15-19",
      dx: 248,
      pop: 21060
    },
    {
      age: "20-24",
      dx: 460,
      pop: 21630
    },
    {
      age: "25-29",
      dx: 570,
      pop: 13500
    },
    {
      age: "30-34",
      dx: 678,
      pop: 22430
    },
    {
      age: "35-39",
      dx: 818,
      pop: 21730
    },
    {
      age: "40-44",
      dx: 1027,
      pop: 19920
    },
    {
      age: "45-49",
      dx: 1482,
      pop: 20400
    },
    {
      age: "50-54",
      dx: 2267,
      pop: 20480
    },
    {
      age: "55-59",
      dx: 3322,
      pop: 21870
    },
    {
      age: "60-64",
      dx: 4541,
      pop: 20570
    },
    {
      age: "65-69",
      dx: 5980,
      pop: 17460
    },
    {
      age: "70-74",
      dx: 8290,
      pop: 14030
    },
    {
      age: "75-79",
      dx: 11368,
      pop: 9650
    },
    {
      age: "80-84",
      dx: 14775,
      pop: 6320
    },
    {
      age: "85-89",
      dx: 17261,
      pop: 2200
    }, //6610
    {
      age: "90-94",
      dx: 15555,
      pop: 1800
    },
    {
      age: "95-99",
      dx: 8114,
      pop: 1200
    },
    {
      age: "100-104",
      dx: 2178,
      pop: 800
    },
    {
      age: "105-109",
      dx: 251,
      pop: 400
    },
    {
      age: "110",
      dx: 12,
      pop: 200
    }
  ],*[
 {
   "age": 0,
   "deathprob": 0.0055635
 },
 {
   "age": 1,
   "deathprob": 0.000387
 },
 {
   "age": 2,
   "deathprob": 0.000236
 },
 {
   "age": 3,
   "deathprob": 0.00018
 },
 {
   "age": 4,
   "deathprob": 0.0001455
 },
 {
   "age": 5,
   "deathprob": 0.000132
 },
 {
   "age": 6,
   "deathprob": 0.000123
 },
 {
   "age": 7,
   "deathprob": 0.000115
 },
 {
   "age": 8,
   "deathprob": 0.0001075
 },
 {
   "age": 9,
   "deathprob": 0.0000995
 },
 {
   "age": 10,
   "deathprob": 0.0000965
 },
 {
   "age": 11,
   "deathprob": 0.000104
 },
 {
   "age": 12,
   "deathprob": 0.0001305
 },
 {
   "age": 13,
   "deathprob": 0.0001795
 },
 {
   "age": 14,
   "deathprob": 0.000247
 },
 {
   "age": 15,
   "deathprob": 0.0003205
 },
 {
   "age": 16,
   "deathprob": 0.000396
 },
 {
   "age": 17,
   "deathprob": 0.000477
 },
 {
   "age": 18,
   "deathprob": 0.0005625
 },
 {
   "age": 19,
   "deathprob": 0.0006495
 },
 {
   "age": 20,
   "deathprob": 0.000742
 },
 {
   "age": 21,
   "deathprob": 0.000833
 },
 {
   "age": 22,
   "deathprob": 0.000909
 },
 {
   "age": 23,
   "deathprob": 0.0009645
 },
 {
   "age": 24,
   "deathprob": 0.0010055
 },
 {
   "age": 25,
   "deathprob": 0.0010415
 },
 {
   "age": 26,
   "deathprob": 0.001082
 },
 {
   "age": 27,
   "deathprob": 0.001127
 },
 {
   "age": 28,
   "deathprob": 0.001181
 },
 {
   "age": 29,
   "deathprob": 0.0012415
 },
 {
   "age": 30,
   "deathprob": 0.0013045
 },
 {
   "age": 31,
   "deathprob": 0.001366
 },
 {
   "age": 32,
   "deathprob": 0.0014295
 },
 {
   "age": 33,
   "deathprob": 0.001492
 },
 {
   "age": 34,
   "deathprob": 0.0015565
 },
 {
   "age": 35,
   "deathprob": 0.001627
 },
 {
   "age": 36,
   "deathprob": 0.0017025
 },
 {
   "age": 37,
   "deathprob": 0.001777
 },
 {
   "age": 38,
   "deathprob": 0.0018475
 },
 {
   "age": 39,
   "deathprob": 0.0019195
 },
 {
   "age": 40,
   "deathprob": 0.002002
 },
 {
   "age": 41,
   "deathprob": 0.0020995
 },
 {
   "age": 42,
   "deathprob": 0.002212
 },
 {
   "age": 43,
   "deathprob": 0.0023425
 },
 {
   "age": 44,
   "deathprob": 0.0024925
 },
 {
   "age": 45,
   "deathprob": 0.0026635
 },
 {
   "age": 46,
   "deathprob": 0.002859
 },
 {
   "age": 47,
   "deathprob": 0.0030815
 },
 {
   "age": 48,
   "deathprob": 0.003335
 },
 {
   "age": 49,
   "deathprob": 0.003621
 },
 {
   "age": 50,
   "deathprob": 0.003934
 },
 {
   "age": 51,
   "deathprob": 0.0042795
 },
 {
   "age": 52,
   "deathprob": 0.0046685
 },
 {
   "age": 53,
   "deathprob": 0.005106
 },
 {
   "age": 54,
   "deathprob": 0.0055885
 },
 {
   "age": 55,
   "deathprob": 0.0061085
 },
 {
   "age": 56,
   "deathprob": 0.006658
 },
 {
   "age": 57,
   "deathprob": 0.007236
 },
 {
   "age": 58,
   "deathprob": 0.0078385
 },
 {
   "age": 59,
   "deathprob": 0.0084685
 },
 {
   "age": 60,
   "deathprob": 0.0091635
 },
 {
   "age": 61,
   "deathprob": 0.009903
 },
 {
   "age": 62,
   "deathprob": 0.010627
 },
 {
   "age": 63,
   "deathprob": 0.011316
 },
 {
   "age": 64,
   "deathprob": 0.01201
 },
 {
   "age": 65,
   "deathprob": 0.0127985
 },
 {
   "age": 66,
   "deathprob": 0.0137135
 },
 {
   "age": 67,
   "deathprob": 0.014719
 },
 {
   "age": 68,
   "deathprob": 0.015824
 },
 {
   "age": 69,
   "deathprob": 0.017065
 },
 {
   "age": 70,
   "deathprob": 0.018513
 },
 {
   "age": 71,
   "deathprob": 0.0201895
 },
 {
   "age": 72,
   "deathprob": 0.0220705
 },
 {
   "age": 73,
   "deathprob": 0.0241715
 },
 {
   "age": 74,
   "deathprob": 0.02654
 },
 {
   "age": 75,
   "deathprob": 0.0293285
 },
 {
   "age": 76,
   "deathprob": 0.0325245
 },
 {
   "age": 77,
   "deathprob": 0.035998
 },
 {
   "age": 78,
   "deathprob": 0.039734
 },
 {
   "age": 79,
   "deathprob": 0.0438635
 },
 {
   "age": 80,
   "deathprob": 0.0486535
 },
 {
   "age": 81,
   "deathprob": 0.0542035
 },
 {
   "age": 82,
   "deathprob": 0.060424
 },
 {
   "age": 83,
   "deathprob": 0.067356
 },
 {
   "age": 84,
   "deathprob": 0.075118
 },
 {
   "age": 85,
   "deathprob": 0.08385
 },
 {
   "age": 86,
   "deathprob": 0.093674
 },
 {
   "age": 87,
   "deathprob": 0.104679
 },
 {
   "age": 88,
   "deathprob": 0.1169185
 },
 {
   "age": 89,
   "deathprob": 0.130413
 },
 {
   "age": 90,
   "deathprob": 0.1451655
 },
 {
   "age": 91,
   "deathprob": 0.1611645
 },
 {
   "age": 92,
   "deathprob": 0.178389
 },
 {
   "age": 93,
   "deathprob": 0.1968065
 },
 {
   "age": 94,
   "deathprob": 0.2163775
 },
 {
   "age": 95,
   "deathprob": 0.2359515
 },
 {
   "age": 96,
   "deathprob": 0.255178
 },
 {
   "age": 97,
   "deathprob": 0.2736815
 },
 {
   "age": 98,
   "deathprob": 0.2910725
 },
 {
   "age": 99,
   "deathprob": 0.306959
 },
 {
   "age": 100,
   "deathprob": 0.32372
 },
 {
   "age": 101,
   "deathprob": 0.3414035
 },
 {
   "age": 102,
   "deathprob": 0.3600615
 },
 {
   "age": 103,
   "deathprob": 0.379747
 },
 {
   "age": 104,
   "deathprob": 0.4005185
 },
 {
   "age": 105,
   "deathprob": 0.422435
 },
 {
   "age": 106,
   "deathprob": 0.445561
 },
 {
   "age": 107,
   "deathprob": 0.4699635
 },
 {
   "age": 108,
   "deathprob": 0.495714
 },
 {
   "age": 109,
   "deathprob": 0.5228865
 },
 {
   "age": 110,
   "deathprob": 0.551561
 },
 {
   "age": 111,
   "deathprob": 0.5818215
 },
 {
   "age": 112,
   "deathprob": 0.6137555
 },
 {
   "age": 113,
   "deathprob": 0.6474565
 },
 {
   "age": 114,
   "deathprob": 0.683024
 },
 {
   "age": 115,
   "deathprob": 0.7205615
 },
 {
   "age": 116,
   "deathprob": 0.759422
 },
 {
   "age": 117,
   "deathprob": 0.797393
 },
 {
   "age": 118,
   "deathprob": 0.837263
 },
 {
   "age": 119,
   "deathprob": 0.879126
 }
  ]*å
  "2018": [
    {
      age: "0",
      dx: 556.35,
      pop: 5000
    },
    {
      age: "1-4",
      dx: 94.85,
      pop: 14580
    },
    {
      age: "5-9",
      dx: 57.7,
      pop: 20430
    },
    {
      age: "10-14",
      dx: 75.75,
      pop: 21827
    },
    {
      age: "15-19",
      dx: 240.55,
      pop: 21628
    },
    {
      age: "20-24",
      dx: 653.0,
      pop: 21537
    },
    {
      age: "25-29",
      dx: 817.0,
      pop: 22964
    },
    {
      age: "30-34",
      dx: 951.0,
      pop: 22902
    },
    {
      age: "35-39",
      dx: 1139.0,
      pop: 22292
    },
    {
      age: "40-44",
      dx: 1373.0,
      pop: 20643
    },
    {
      age: "45-49",
      dx: 1943.0,
      pop: 20510
    },
    {
      age: "50-54",
      dx: 2953.0,
      pop: 20846
    },
    {
      age: "55-59",
      dx: 4593.0,
      pop: 22187
    },
    {
      age: "60-64",
      dx: 6673.0,
      pop: 21072
    },
    {
      age: "65-69",
      dx: 9253.0,
      pop: 17962
    },
    {
      age: "70-74",
      dx: 13611.0,
      pop: 14493
    },
    {
      age: "75-79",
      dx: 21631.0,
      pop: 9643
    },
    {
      age: "80-84",
      dx: 35581.0,
      pop: 6142
    },
    {
      age: "85-89",
      dx: 60425.0,
      pop: 2000
    }, //6063
    {
      age: "90-94",
      dx: 100686.0,
      pop: 1700
    },
    {
      age: "95-99",
      dx: 152096.0,
      pop: 1120
    },
    {
      age: "100-104",
      dx: 180545.05,
      pop: 720
    },
    {
      age: "105-109",
      dx: 235656,
      pop: 310
    },
    {
      age: "110",
      dx: 307761.85 + 399376.55,
      pop: 196
    }
  ],
  "2019": [
    {
      age: "0",
      dx: 556.35,
      pop: 5000
    },
    {
      age: "1-4",
      dx: 94.85,
      pop: 14580
    },
    {
      age: "5-9",
      dx: 57.7,
      pop: 20430
    },
    {
      age: "10-14",
      dx: 75.75,
      pop: 21827
    },
    {
      age: "15-19",
      dx: 240.55,
      pop: 21628
    },
    {
      age: "20-24",
      dx: 445.4,
      pop: 21537
    },
    {
      age: "25-29",
      dx: 567.3,
      pop: 22964
    },
    {
      age: "30-34",
      dx: 714.85,
      pop: 22902
    },
    {
      age: "35-39",
      dx: 887.35,
      pop: 22292
    },
    {
      age: "40-44",
      dx: 1114.85,
      pop: 20643
    },
    {
      age: "45-49",
      dx: 1556,
      pop: 20510
    },
    {
      age: "50-54",
      dx: 2357.65,
      pop: 20846
    },
    {
      age: "55-59",
      dx: 3630.95,
      pop: 22187
    },
    {
      age: "60-64",
      dx: 5301.95,
      pop: 21072
    },
    {
      age: "65-69",
      dx: 7412,
      pop: 17962
    },
    {
      age: "70-74",
      dx: 11148.45,
      pop: 14493
    },
    {
      age: "75-79",
      dx: 18144.85,
      pop: 9643
    },
    {
      age: "80-84",
      dx: 30575.5,
      pop: 6142
    },
    {
      age: "85-89",
      dx: 52953.45,
      pop: 2000
    }, //6063
    {
      age: "90-94",
      dx: 89790.3,
      pop: 1700
    },
    {
      age: "95-99",
      dx: 136284.25,
      pop: 1120
    },
    {
      age: "100-104",
      dx: 180545.05,
      pop: 720
    },
    {
      age: "105-109",
      dx: 235656,
      pop: 310
    },
    {
      age: "110",
      dx: 307761.85 + 399376.55,
      pop: 196
    }
  ],*/
  "2020": [
    {
      age: "0",
      dx: 897,
      pop: 5000
    },
    {
      age: "1-4",
      dx: 160,
      pop: 14580
    },
    {
      age: "5-9",
      dx: 95,
      pop: 20430
    },
    {
      age: "10-14",
      dx: 123,
      pop: 21827
    },
    {
      age: "15-19",
      dx: 467,
      pop: 21628
    },
    {
      age: "20-24",
      dx: 618,
      pop: 21537
    },
    {
      age: "25-29",
      dx: 571,
      pop: 22964
    },
    {
      age: "30-34",
      dx: 696,
      pop: 22902
    },
    {
      age: "35-39",
      dx: 1004,
      pop: 22292
    },
    {
      age: "40-44",
      dx: 1425,
      pop: 20643
    },
    {
      age: "45-49",
      dx: 1888,
      pop: 20510
    },
    {
      age: "50-54",
      dx: 2475,
      pop: 20846
    },
    {
      age: "55-59",
      dx: 3727,
      pop: 22187
    },
    {
      age: "60-64",
      dx: 5709,
      pop: 21072
    },
    {
      age: "65-69",
      dx: 9176,
      pop: 17962
    },
    {
      age: "70-74",
      dx: 13175,
      pop: 14493
    },
    {
      age: "75-79",
      dx: 18031,
      pop: 9643
    },
    {
      age: "80-84",
      dx: 24959,
      pop: 6142
    },
    {
      age: "85-89",
      dx: 35907,
      pop: 2000
    }, //6063
    {
      age: "90-94",
      dx: 36585,
      pop: 1700
    },
    {
      age: "95-99",
      dx: 25859,
      pop: 1120
    },
    {
      age: "100-104",
      dx: 11800,
      pop: 720
    },
    {
      age: "105-109",
      dx: 3793,
      pop: 310
    },
    {
      age: "110",
      dx: 771 + 82,
      pop: 196
    }
  ]
};

const yearlydeathprob = {
  "2000": {
    "20": 0.00136,
    "21": 0.00145,
    "22": 0.00138,
    "23": 0.00136,
    "24": 0.00135,
    "25": 0.00126,
    "26": 0.00128,
    "27": 0.0013,
    "28": 0.00127,
    "29": 0.00132,
    "30": 0.00127,
    "31": 0.00143,
    "32": 0.00145,
    "33": 0.00157,
    "34": 0.00168,
    "35": 0.0017,
    "36": 0.0019,
    "37": 0.00205,
    "38": 0.00225,
    "39": 0.00235,
    "40": 0.00255,
    "41": 0.00284,
    "42": 0.00298,
    "43": 0.00336,
    "44": 0.0036,
    "45": 0.00379,
    "46": 0.00426,
    "47": 0.00455,
    "48": 0.00498,
    "49": 0.00532,
    "50": 0.00546,
    "51": 0.00598,
    "52": 0.00631,
    "53": 0.00693,
    "54": 0.00766,
    "55": 0.00826,
    "56": 0.00893,
    "57": 0.00978,
    "58": 0.01086,
    "59": 0.0119,
    "60": 0.01265,
    "61": 0.01371,
    "62": 0.0152,
    "63": 0.01686,
    "64": 0.01835,
    "65": 0.02024,
    "66": 0.02169,
    "67": 0.024,
    "68": 0.02607,
    "69": 0.02818,
    "70": 0.03115,
    "71": 0.03434,
    "72": 0.03756,
    "73": 0.04072,
    "74": 0.04424,
    "75": 0.04819,
    "76": 0.05249,
    "77": 0.05761,
    "78": 0.0629,
    "79": 0.06885,
    "80": 0.07581,
    "81": 0.08346,
    "82": 0.09182,
    "83": 0.10103,
    "84": 0.11113,
    "85": 0.1224,
    "86": 0.13532,
    "87": 0.14781,
    "88": 0.16166,
    "89": 0.17753,
    "90": 0.19208,
    "91": 0.20788,
    "92": 0.2274,
    "93": 0.24697,
    "94": 0.267,
    "95": 0.28792,
    "96": 0.30016,
    "97": 0.31635,
    "98": 0.33773,
    "99": 0.34082
  },
  "2001": {
    "20": 0.00135,
    "21": 0.00149,
    "22": 0.00144,
    "23": 0.00139,
    "24": 0.00138,
    "25": 0.00133,
    "26": 0.00138,
    "27": 0.00135,
    "28": 0.0014,
    "29": 0.00137,
    "30": 0.00142,
    "31": 0.00141,
    "32": 0.00147,
    "33": 0.00161,
    "34": 0.00169,
    "35": 0.00187,
    "36": 0.0019,
    "37": 0.00215,
    "38": 0.00231,
    "39": 0.00243,
    "40": 0.00261,
    "41": 0.00276,
    "42": 0.00298,
    "43": 0.00331,
    "44": 0.00355,
    "45": 0.0039,
    "46": 0.00412,
    "47": 0.00461,
    "48": 0.00496,
    "49": 0.00532,
    "50": 0.00562,
    "51": 0.00591,
    "52": 0.00636,
    "53": 0.00678,
    "54": 0.00711,
    "55": 0.0083,
    "56": 0.00875,
    "57": 0.00968,
    "58": 0.01045,
    "59": 0.01144,
    "60": 0.01269,
    "61": 0.01334,
    "62": 0.01484,
    "63": 0.01605,
    "64": 0.01768,
    "65": 0.01957,
    "66": 0.02137,
    "67": 0.02328,
    "68": 0.02557,
    "69": 0.02794,
    "70": 0.03041,
    "71": 0.03316,
    "72": 0.03637,
    "73": 0.03999,
    "74": 0.04344,
    "75": 0.04732,
    "76": 0.0519,
    "77": 0.05648,
    "78": 0.06171,
    "79": 0.06743,
    "80": 0.07386,
    "81": 0.08192,
    "82": 0.0908,
    "83": 0.09933,
    "84": 0.10881,
    "85": 0.12029,
    "86": 0.13252,
    "87": 0.14565,
    "88": 0.15992,
    "89": 0.17533,
    "90": 0.19017,
    "91": 0.20594,
    "92": 0.22405,
    "93": 0.24466,
    "94": 0.26516,
    "95": 0.28284,
    "96": 0.30478,
    "97": 0.32141,
    "98": 0.33228,
    "99": 0.33095
  },
  "2002": {
    "20": 0.00137,
    "21": 0.00144,
    "22": 0.00139,
    "23": 0.00143,
    "24": 0.00141,
    "25": 0.00135,
    "26": 0.00142,
    "27": 0.00132,
    "28": 0.00134,
    "29": 0.00142,
    "30": 0.00144,
    "31": 0.00147,
    "32": 0.00141,
    "33": 0.00161,
    "34": 0.00167,
    "35": 0.0018,
    "36": 0.0019,
    "37": 0.00203,
    "38": 0.00224,
    "39": 0.00241,
    "40": 0.00268,
    "41": 0.00283,
    "42": 0.00298,
    "43": 0.0033,
    "44": 0.0036,
    "45": 0.00383,
    "46": 0.0043,
    "47": 0.00445,
    "48": 0.00497,
    "49": 0.00529,
    "50": 0.00576,
    "51": 0.00611,
    "52": 0.00641,
    "53": 0.00698,
    "54": 0.00743,
    "55": 0.00781,
    "56": 0.00901,
    "57": 0.00936,
    "58": 0.01045,
    "59": 0.01128,
    "60": 0.01255,
    "61": 0.01345,
    "62": 0.01473,
    "63": 0.016,
    "64": 0.01724,
    "65": 0.01909,
    "66": 0.02084,
    "67": 0.02286,
    "68": 0.02497,
    "69": 0.02742,
    "70": 0.03,
    "71": 0.03259,
    "72": 0.0355,
    "73": 0.03895,
    "74": 0.04286,
    "75": 0.04666,
    "76": 0.05109,
    "77": 0.05602,
    "78": 0.06091,
    "79": 0.06657,
    "80": 0.07318,
    "81": 0.08065,
    "82": 0.08915,
    "83": 0.09835,
    "84": 0.10822,
    "85": 0.12016,
    "86": 0.13279,
    "87": 0.14559,
    "88": 0.16086,
    "89": 0.1764,
    "90": 0.19217,
    "91": 0.2099,
    "92": 0.23016,
    "93": 0.24978,
    "94": 0.2684,
    "95": 0.28485,
    "96": 0.30673,
    "97": 0.32883,
    "98": 0.34698,
    "99": 0.34845
  },
  "2003": {
    "20": 0.00139,
    "21": 0.00142,
    "22": 0.00147,
    "23": 0.00142,
    "24": 0.00142,
    "25": 0.00137,
    "26": 0.0014,
    "27": 0.00137,
    "28": 0.00133,
    "29": 0.00142,
    "30": 0.00141,
    "31": 0.00146,
    "32": 0.00153,
    "33": 0.00153,
    "34": 0.00163,
    "35": 0.00176,
    "36": 0.0019,
    "37": 0.00204,
    "38": 0.00215,
    "39": 0.00236,
    "40": 0.00255,
    "41": 0.00282,
    "42": 0.00304,
    "43": 0.00324,
    "44": 0.00361,
    "45": 0.00381,
    "46": 0.00426,
    "47": 0.00464,
    "48": 0.00491,
    "49": 0.00536,
    "50": 0.00579,
    "51": 0.00619,
    "52": 0.00657,
    "53": 0.00677,
    "54": 0.00762,
    "55": 0.00813,
    "56": 0.00839,
    "57": 0.00955,
    "58": 0.01014,
    "59": 0.01135,
    "60": 0.01214,
    "61": 0.01341,
    "62": 0.01472,
    "63": 0.01542,
    "64": 0.01703,
    "65": 0.01888,
    "66": 0.02035,
    "67": 0.02232,
    "68": 0.02441,
    "69": 0.02679,
    "70": 0.02914,
    "71": 0.03143,
    "72": 0.03461,
    "73": 0.03786,
    "74": 0.04137,
    "75": 0.04575,
    "76": 0.04994,
    "77": 0.05453,
    "78": 0.05986,
    "79": 0.06547,
    "80": 0.07179,
    "81": 0.07891,
    "82": 0.08646,
    "83": 0.09556,
    "84": 0.10638,
    "85": 0.11654,
    "86": 0.12834,
    "87": 0.14263,
    "88": 0.15752,
    "89": 0.17301,
    "90": 0.18971,
    "91": 0.20778,
    "92": 0.22534,
    "93": 0.24365,
    "94": 0.26196,
    "95": 0.28286,
    "96": 0.30725,
    "97": 0.32325,
    "98": 0.33325,
    "99": 0.3465
  },
  "2004": {
    "20": 0.00133,
    "21": 0.00145,
    "22": 0.00143,
    "23": 0.00139,
    "24": 0.00139,
    "25": 0.00145,
    "26": 0.00142,
    "27": 0.00143,
    "28": 0.00135,
    "29": 0.00139,
    "30": 0.00131,
    "31": 0.00144,
    "32": 0.0015,
    "33": 0.0015,
    "34": 0.00154,
    "35": 0.00175,
    "36": 0.0018,
    "37": 0.00196,
    "38": 0.00208,
    "39": 0.0022,
    "40": 0.00243,
    "41": 0.0027,
    "42": 0.00296,
    "43": 0.00316,
    "44": 0.00331,
    "45": 0.00381,
    "46": 0.00407,
    "47": 0.00449,
    "48": 0.00485,
    "49": 0.00506,
    "50": 0.00567,
    "51": 0.00611,
    "52": 0.00666,
    "53": 0.00707,
    "54": 0.00723,
    "55": 0.00794,
    "56": 0.00841,
    "57": 0.00886,
    "58": 0.01011,
    "59": 0.01062,
    "60": 0.0119,
    "61": 0.01267,
    "62": 0.01405,
    "63": 0.01525,
    "64": 0.01617,
    "65": 0.01752,
    "66": 0.01961,
    "67": 0.02138,
    "68": 0.02316,
    "69": 0.02537,
    "70": 0.02785,
    "71": 0.03015,
    "72": 0.03277,
    "73": 0.03587,
    "74": 0.03959,
    "75": 0.04374,
    "76": 0.04775,
    "77": 0.05213,
    "78": 0.05727,
    "79": 0.06297,
    "80": 0.06896,
    "81": 0.07543,
    "82": 0.08331,
    "83": 0.0925,
    "84": 0.10182,
    "85": 0.11225,
    "86": 0.12359,
    "87": 0.13661,
    "88": 0.15182,
    "89": 0.16622,
    "90": 0.18205,
    "91": 0.19931,
    "92": 0.21629,
    "93": 0.23648,
    "94": 0.25864,
    "95": 0.27879,
    "96": 0.29965,
    "97": 0.31842,
    "98": 0.32704,
    "99": 0.33746
  },
  "2005": {
    "20": 0.00134,
    "21": 0.00151,
    "22": 0.00149,
    "23": 0.00149,
    "24": 0.00145,
    "25": 0.00145,
    "26": 0.00149,
    "27": 0.00148,
    "28": 0.00143,
    "29": 0.00139,
    "30": 0.00139,
    "31": 0.0015,
    "32": 0.00153,
    "33": 0.00153,
    "34": 0.00163,
    "35": 0.00162,
    "36": 0.00173,
    "37": 0.00195,
    "38": 0.00209,
    "39": 0.00225,
    "40": 0.00244,
    "41": 0.00263,
    "42": 0.00299,
    "43": 0.00318,
    "44": 0.00352,
    "45": 0.00372,
    "46": 0.00409,
    "47": 0.00437,
    "48": 0.00478,
    "49": 0.00536,
    "50": 0.00563,
    "51": 0.00608,
    "52": 0.00683,
    "53": 0.00716,
    "54": 0.00755,
    "55": 0.00798,
    "56": 0.00846,
    "57": 0.00919,
    "58": 0.00947,
    "59": 0.01106,
    "60": 0.01173,
    "61": 0.01256,
    "62": 0.01386,
    "63": 0.01515,
    "64": 0.01646,
    "65": 0.0172,
    "66": 0.0193,
    "67": 0.02091,
    "68": 0.02259,
    "69": 0.02483,
    "70": 0.02746,
    "71": 0.03018,
    "72": 0.03278,
    "73": 0.03573,
    "74": 0.03913,
    "75": 0.04257,
    "76": 0.04682,
    "77": 0.05173,
    "78": 0.05696,
    "79": 0.06258,
    "80": 0.06871,
    "81": 0.07539,
    "82": 0.08289,
    "83": 0.0912,
    "84": 0.10091,
    "85": 0.11192,
    "86": 0.12404,
    "87": 0.13709,
    "88": 0.15059,
    "89": 0.16658,
    "90": 0.18464,
    "91": 0.20301,
    "92": 0.2208,
    "93": 0.2432,
    "94": 0.26521,
    "95": 0.28228,
    "96": 0.2971,
    "97": 0.32368,
    "98": 0.33997,
    "99": 0.34641
  },
  "2006": {
    "20": 0.00141,
    "21": 0.0015,
    "22": 0.00149,
    "23": 0.00154,
    "24": 0.00153,
    "25": 0.00152,
    "26": 0.00148,
    "27": 0.00153,
    "28": 0.00147,
    "29": 0.00151,
    "30": 0.00147,
    "31": 0.00153,
    "32": 0.00151,
    "33": 0.00157,
    "34": 0.00166,
    "35": 0.00176,
    "36": 0.00172,
    "37": 0.00188,
    "38": 0.00206,
    "39": 0.00223,
    "40": 0.00246,
    "41": 0.00255,
    "42": 0.00289,
    "43": 0.00316,
    "44": 0.00337,
    "45": 0.00368,
    "46": 0.00386,
    "47": 0.00438,
    "48": 0.0048,
    "49": 0.00514,
    "50": 0.00572,
    "51": 0.00591,
    "52": 0.00663,
    "53": 0.00711,
    "54": 0.00753,
    "55": 0.00804,
    "56": 0.00824,
    "57": 0.00908,
    "58": 0.00995,
    "59": 0.01013,
    "60": 0.01179,
    "61": 0.01196,
    "62": 0.0135,
    "63": 0.01442,
    "64": 0.01582,
    "65": 0.01798,
    "66": 0.01907,
    "67": 0.02073,
    "68": 0.02208,
    "69": 0.0244,
    "70": 0.02612,
    "71": 0.02866,
    "72": 0.03167,
    "73": 0.03449,
    "74": 0.03776,
    "75": 0.04119,
    "76": 0.04595,
    "77": 0.05003,
    "78": 0.05469,
    "79": 0.06027,
    "80": 0.0668,
    "81": 0.07423,
    "82": 0.08153,
    "83": 0.08849,
    "84": 0.09769,
    "85": 0.10761,
    "86": 0.11975,
    "87": 0.13341,
    "88": 0.14643,
    "89": 0.16294,
    "90": 0.17829,
    "91": 0.19627,
    "92": 0.21518,
    "93": 0.23391,
    "94": 0.25657,
    "95": 0.27484,
    "96": 0.29219,
    "97": 0.31449,
    "98": 0.32309,
    "99": 0.33457
  },
  "2007": {
    "20": 0.00132,
    "21": 0.00146,
    "22": 0.00153,
    "23": 0.00146,
    "24": 0.00152,
    "25": 0.00143,
    "26": 0.00145,
    "27": 0.00146,
    "28": 0.00148,
    "29": 0.00151,
    "30": 0.00147,
    "31": 0.00151,
    "32": 0.00146,
    "33": 0.00155,
    "34": 0.00165,
    "35": 0.00167,
    "36": 0.00176,
    "37": 0.00186,
    "38": 0.00202,
    "39": 0.00212,
    "40": 0.00233,
    "41": 0.00254,
    "42": 0.00268,
    "43": 0.00299,
    "44": 0.00338,
    "45": 0.00364,
    "46": 0.00395,
    "47": 0.00407,
    "48": 0.0046,
    "49": 0.00499,
    "50": 0.00548,
    "51": 0.00604,
    "52": 0.0063,
    "53": 0.00701,
    "54": 0.00735,
    "55": 0.00815,
    "56": 0.00846,
    "57": 0.00893,
    "58": 0.00964,
    "59": 0.01033,
    "60": 0.01084,
    "61": 0.01222,
    "62": 0.01281,
    "63": 0.01444,
    "64": 0.01544,
    "65": 0.01706,
    "66": 0.01851,
    "67": 0.02,
    "68": 0.0216,
    "69": 0.02362,
    "70": 0.02554,
    "71": 0.02794,
    "72": 0.03049,
    "73": 0.034,
    "74": 0.03662,
    "75": 0.04037,
    "76": 0.0437,
    "77": 0.04828,
    "78": 0.05337,
    "79": 0.05852,
    "80": 0.06475,
    "81": 0.072,
    "82": 0.07886,
    "83": 0.08658,
    "84": 0.0963,
    "85": 0.10687,
    "86": 0.11724,
    "87": 0.12923,
    "88": 0.14351,
    "89": 0.15677,
    "90": 0.1762,
    "91": 0.19251,
    "92": 0.20903,
    "93": 0.23033,
    "94": 0.25103,
    "95": 0.27607,
    "96": 0.28463,
    "97": 0.30597,
    "98": 0.32565,
    "99": 0.33339
  },
  "2008": {
    "20": 0.00131,
    "21": 0.00138,
    "22": 0.00144,
    "23": 0.0014,
    "24": 0.00142,
    "25": 0.00141,
    "26": 0.00138,
    "27": 0.00141,
    "28": 0.00144,
    "29": 0.00149,
    "30": 0.00148,
    "31": 0.0015,
    "32": 0.00148,
    "33": 0.00153,
    "34": 0.00157,
    "35": 0.00162,
    "36": 0.00171,
    "37": 0.00189,
    "38": 0.00193,
    "39": 0.00203,
    "40": 0.00218,
    "41": 0.00247,
    "42": 0.00267,
    "43": 0.00283,
    "44": 0.00321,
    "45": 0.0035,
    "46": 0.00384,
    "47": 0.0042,
    "48": 0.00442,
    "49": 0.005,
    "50": 0.00545,
    "51": 0.00584,
    "52": 0.00641,
    "53": 0.00673,
    "54": 0.00751,
    "55": 0.00819,
    "56": 0.0086,
    "57": 0.00907,
    "58": 0.00948,
    "59": 0.01036,
    "60": 0.01153,
    "61": 0.01155,
    "62": 0.01318,
    "63": 0.01386,
    "64": 0.01573,
    "65": 0.0168,
    "66": 0.01823,
    "67": 0.01985,
    "68": 0.02161,
    "69": 0.02362,
    "70": 0.02525,
    "71": 0.02764,
    "72": 0.03042,
    "73": 0.03347,
    "74": 0.03673,
    "75": 0.04004,
    "76": 0.04344,
    "77": 0.04795,
    "78": 0.05304,
    "79": 0.05915,
    "80": 0.06443,
    "81": 0.07045,
    "82": 0.07833,
    "83": 0.08693,
    "84": 0.09624,
    "85": 0.10566,
    "86": 0.11869,
    "87": 0.12971,
    "88": 0.14396,
    "89": 0.16041,
    "90": 0.17538,
    "91": 0.19472,
    "92": 0.21112,
    "93": 0.23286,
    "94": 0.25536,
    "95": 0.27298,
    "96": 0.28781,
    "97": 0.31228,
    "98": 0.33278,
    "99": 0.34477
  },
  "2009": {
    "20": 0.00119,
    "21": 0.00133,
    "22": 0.00127,
    "23": 0.00135,
    "24": 0.00133,
    "25": 0.00132,
    "26": 0.00138,
    "27": 0.00133,
    "28": 0.00138,
    "29": 0.00145,
    "30": 0.00141,
    "31": 0.00149,
    "32": 0.00147,
    "33": 0.00149,
    "34": 0.00156,
    "35": 0.00168,
    "36": 0.00173,
    "37": 0.00181,
    "38": 0.00193,
    "39": 0.002,
    "40": 0.00222,
    "41": 0.00244,
    "42": 0.00267,
    "43": 0.00282,
    "44": 0.00311,
    "45": 0.00342,
    "46": 0.00377,
    "47": 0.00412,
    "48": 0.00445,
    "49": 0.00472,
    "50": 0.00539,
    "51": 0.00582,
    "52": 0.0064,
    "53": 0.00692,
    "54": 0.00717,
    "55": 0.00798,
    "56": 0.00851,
    "57": 0.00928,
    "58": 0.00967,
    "59": 0.01015,
    "60": 0.01131,
    "61": 0.01175,
    "62": 0.01234,
    "63": 0.01416,
    "64": 0.01475,
    "65": 0.01661,
    "66": 0.01766,
    "67": 0.0192,
    "68": 0.02097,
    "69": 0.02267,
    "70": 0.0245,
    "71": 0.02658,
    "72": 0.0293,
    "73": 0.03196,
    "74": 0.03497,
    "75": 0.0384,
    "76": 0.0417,
    "77": 0.04621,
    "78": 0.05178,
    "79": 0.05553,
    "80": 0.06188,
    "81": 0.06813,
    "82": 0.07545,
    "83": 0.08349,
    "84": 0.09245,
    "85": 0.10191,
    "86": 0.11325,
    "87": 0.12518,
    "88": 0.13709,
    "89": 0.15239,
    "90": 0.16917,
    "91": 0.18323,
    "92": 0.20698,
    "93": 0.22531,
    "94": 0.24308,
    "95": 0.26431,
    "96": 0.28667,
    "97": 0.29963,
    "98": 0.30935,
    "99": 0.33038
  },
  "2010": {
    "20": 0.00111,
    "21": 0.00128,
    "22": 0.00126,
    "23": 0.00129,
    "24": 0.00136,
    "25": 0.00131,
    "26": 0.00137,
    "27": 0.00133,
    "28": 0.00137,
    "29": 0.00139,
    "30": 0.00139,
    "31": 0.00147,
    "32": 0.00147,
    "33": 0.00151,
    "34": 0.00151,
    "35": 0.00155,
    "36": 0.00164,
    "37": 0.00176,
    "38": 0.00186,
    "39": 0.00198,
    "40": 0.00201,
    "41": 0.00226,
    "42": 0.0025,
    "43": 0.00265,
    "44": 0.00302,
    "45": 0.00325,
    "46": 0.00363,
    "47": 0.004,
    "48": 0.00434,
    "49": 0.00482,
    "50": 0.00511,
    "51": 0.00558,
    "52": 0.00613,
    "53": 0.00664,
    "54": 0.00714,
    "55": 0.00768,
    "56": 0.00838,
    "57": 0.00908,
    "58": 0.00975,
    "59": 0.01042,
    "60": 0.01091,
    "61": 0.01154,
    "62": 0.01268,
    "63": 0.01287,
    "64": 0.0151,
    "65": 0.01624,
    "66": 0.01762,
    "67": 0.01839,
    "68": 0.02065,
    "69": 0.02256,
    "70": 0.02446,
    "71": 0.02615,
    "72": 0.02878,
    "73": 0.03147,
    "74": 0.03468,
    "75": 0.03796,
    "76": 0.04206,
    "77": 0.04588,
    "78": 0.05051,
    "79": 0.05512,
    "80": 0.06126,
    "81": 0.0682,
    "82": 0.07544,
    "83": 0.08184,
    "84": 0.09241,
    "85": 0.10279,
    "86": 0.11245,
    "87": 0.12446,
    "88": 0.13966,
    "89": 0.15146,
    "90": 0.1711,
    "91": 0.18571,
    "92": 0.20409,
    "93": 0.22684,
    "94": 0.24507,
    "95": 0.26503,
    "96": 0.28455,
    "97": 0.29669,
    "98": 0.31836,
    "99": 0.33631
  },
  "2011": {
    "20": 0.00109,
    "21": 0.00123,
    "22": 0.00134,
    "23": 0.00135,
    "24": 0.00133,
    "25": 0.00134,
    "26": 0.00135,
    "27": 0.00136,
    "28": 0.00137,
    "29": 0.00141,
    "30": 0.00143,
    "31": 0.00143,
    "32": 0.00153,
    "33": 0.00154,
    "34": 0.00156,
    "35": 0.0016,
    "36": 0.00161,
    "37": 0.00175,
    "38": 0.00189,
    "39": 0.00203,
    "40": 0.00216,
    "41": 0.00222,
    "42": 0.00247,
    "43": 0.00269,
    "44": 0.00286,
    "45": 0.00325,
    "46": 0.00346,
    "47": 0.00398,
    "48": 0.00432,
    "49": 0.00476,
    "50": 0.0052,
    "51": 0.00552,
    "52": 0.00621,
    "53": 0.00667,
    "54": 0.00719,
    "55": 0.00785,
    "56": 0.00849,
    "57": 0.009,
    "58": 0.00965,
    "59": 0.01043,
    "60": 0.01117,
    "61": 0.01157,
    "62": 0.01242,
    "63": 0.01347,
    "64": 0.01396,
    "65": 0.01605,
    "66": 0.01706,
    "67": 0.01874,
    "68": 0.02014,
    "69": 0.02198,
    "70": 0.02435,
    "71": 0.02621,
    "72": 0.02862,
    "73": 0.03117,
    "74": 0.03402,
    "75": 0.03733,
    "76": 0.04133,
    "77": 0.04539,
    "78": 0.04952,
    "79": 0.0545,
    "80": 0.06086,
    "81": 0.06732,
    "82": 0.07516,
    "83": 0.08319,
    "84": 0.09113,
    "85": 0.10196,
    "86": 0.1119,
    "87": 0.12472,
    "88": 0.13643,
    "89": 0.15237,
    "90": 0.16801,
    "91": 0.18746,
    "92": 0.20904,
    "93": 0.22061,
    "94": 0.24724,
    "95": 0.26316,
    "96": 0.28439,
    "97": 0.30533,
    "98": 0.3151,
    "99": 0.3405
  },
  "2012": {
    "20": 0.00109,
    "21": 0.00121,
    "22": 0.00131,
    "23": 0.00125,
    "24": 0.00131,
    "25": 0.00133,
    "26": 0.00137,
    "27": 0.00134,
    "28": 0.00141,
    "29": 0.00146,
    "30": 0.00144,
    "31": 0.00151,
    "32": 0.00145,
    "33": 0.00157,
    "34": 0.0016,
    "35": 0.00164,
    "36": 0.00174,
    "37": 0.00176,
    "38": 0.00185,
    "39": 0.00196,
    "40": 0.00215,
    "41": 0.00228,
    "42": 0.00235,
    "43": 0.00261,
    "44": 0.00285,
    "45": 0.00309,
    "46": 0.00347,
    "47": 0.00379,
    "48": 0.00422,
    "49": 0.0046,
    "50": 0.00518,
    "51": 0.00566,
    "52": 0.00601,
    "53": 0.00658,
    "54": 0.00712,
    "55": 0.0079,
    "56": 0.00846,
    "57": 0.00892,
    "58": 0.00981,
    "59": 0.01049,
    "60": 0.01125,
    "61": 0.0119,
    "62": 0.01267,
    "63": 0.0135,
    "64": 0.01448,
    "65": 0.01509,
    "66": 0.0176,
    "67": 0.01854,
    "68": 0.0203,
    "69": 0.02142,
    "70": 0.02375,
    "71": 0.02578,
    "72": 0.02825,
    "73": 0.03058,
    "74": 0.0333,
    "75": 0.0367,
    "76": 0.04014,
    "77": 0.04455,
    "78": 0.04912,
    "79": 0.05409,
    "80": 0.05961,
    "81": 0.06585,
    "82": 0.07317,
    "83": 0.08233,
    "84": 0.09033,
    "85": 0.09946,
    "86": 0.1107,
    "87": 0.12315,
    "88": 0.13698,
    "89": 0.14918,
    "90": 0.16848,
    "91": 0.18389,
    "92": 0.20075,
    "93": 0.22431,
    "94": 0.24365,
    "95": 0.26806,
    "96": 0.2887,
    "97": 0.30367,
    "98": 0.33441,
    "99": 0.33032
  },
  "2013": {
    "20": 0.00105,
    "21": 0.00119,
    "22": 0.00123,
    "23": 0.00127,
    "24": 0.00129,
    "25": 0.00131,
    "26": 0.00137,
    "27": 0.00139,
    "28": 0.00137,
    "29": 0.00143,
    "30": 0.00148,
    "31": 0.00149,
    "32": 0.0015,
    "33": 0.00156,
    "34": 0.00167,
    "35": 0.00168,
    "36": 0.00172,
    "37": 0.00179,
    "38": 0.00181,
    "39": 0.00198,
    "40": 0.00214,
    "41": 0.00225,
    "42": 0.00248,
    "43": 0.00256,
    "44": 0.00285,
    "45": 0.00307,
    "46": 0.00351,
    "47": 0.00386,
    "48": 0.00414,
    "49": 0.00459,
    "50": 0.0051,
    "51": 0.00563,
    "52": 0.00603,
    "53": 0.00647,
    "54": 0.00717,
    "55": 0.00781,
    "56": 0.00848,
    "57": 0.0091,
    "58": 0.0098,
    "59": 0.01055,
    "60": 0.01134,
    "61": 0.01216,
    "62": 0.01296,
    "63": 0.01363,
    "64": 0.01458,
    "65": 0.01571,
    "66": 0.01642,
    "67": 0.01887,
    "68": 0.01999,
    "69": 0.02204,
    "70": 0.0233,
    "71": 0.02608,
    "72": 0.02797,
    "73": 0.03073,
    "74": 0.03347,
    "75": 0.03652,
    "76": 0.04019,
    "77": 0.04475,
    "78": 0.04905,
    "79": 0.05377,
    "80": 0.05976,
    "81": 0.0661,
    "82": 0.07405,
    "83": 0.08022,
    "84": 0.09102,
    "85": 0.09963,
    "86": 0.11142,
    "87": 0.12444,
    "88": 0.13656,
    "89": 0.15233,
    "90": 0.16731,
    "91": 0.18381,
    "92": 0.20442,
    "93": 0.22605,
    "94": 0.24433,
    "95": 0.26342,
    "96": 0.28678,
    "97": 0.30486,
    "98": 0.31961,
    "99": 0.3394
  },
  "2014": {
    "20": 0.00105,
    "21": 0.00118,
    "22": 0.00126,
    "23": 0.00127,
    "24": 0.00132,
    "25": 0.00136,
    "26": 0.00138,
    "27": 0.00138,
    "28": 0.00144,
    "29": 0.00145,
    "30": 0.00147,
    "31": 0.00158,
    "32": 0.00156,
    "33": 0.00161,
    "34": 0.00169,
    "35": 0.00176,
    "36": 0.00183,
    "37": 0.00185,
    "38": 0.0019,
    "39": 0.002,
    "40": 0.00214,
    "41": 0.00239,
    "42": 0.00244,
    "43": 0.0026,
    "44": 0.00274,
    "45": 0.00309,
    "46": 0.0034,
    "47": 0.00378,
    "48": 0.00418,
    "49": 0.00451,
    "50": 0.00501,
    "51": 0.00556,
    "52": 0.00603,
    "53": 0.00665,
    "54": 0.00702,
    "55": 0.00788,
    "56": 0.0085,
    "57": 0.00922,
    "58": 0.00994,
    "59": 0.01046,
    "60": 0.01142,
    "61": 0.01243,
    "62": 0.01317,
    "63": 0.01401,
    "64": 0.01444,
    "65": 0.01585,
    "66": 0.01713,
    "67": 0.01748,
    "68": 0.0204,
    "69": 0.02142,
    "70": 0.02353,
    "71": 0.02516,
    "72": 0.02805,
    "73": 0.03063,
    "74": 0.0334,
    "75": 0.03666,
    "76": 0.0397,
    "77": 0.04427,
    "78": 0.0481,
    "79": 0.0527,
    "80": 0.05917,
    "81": 0.06541,
    "82": 0.07205,
    "83": 0.07982,
    "84": 0.08905,
    "85": 0.0982,
    "86": 0.10933,
    "87": 0.12066,
    "88": 0.13525,
    "89": 0.14915,
    "90": 0.16469,
    "91": 0.18076,
    "92": 0.20063,
    "93": 0.22241,
    "94": 0.23908,
    "95": 0.25884,
    "96": 0.28135,
    "97": 0.30983,
    "98": 0.32692,
    "99": 0.32417
  },
  "2015": {
    "20": 0.00112,
    "21": 0.00125,
    "22": 0.00135,
    "23": 0.00136,
    "24": 0.00142,
    "25": 0.00144,
    "26": 0.00143,
    "27": 0.00151,
    "28": 0.00152,
    "29": 0.00164,
    "30": 0.00157,
    "31": 0.00166,
    "32": 0.00172,
    "33": 0.00176,
    "34": 0.00184,
    "35": 0.00183,
    "36": 0.00193,
    "37": 0.002,
    "38": 0.00205,
    "39": 0.00216,
    "40": 0.00216,
    "41": 0.00238,
    "42": 0.00255,
    "43": 0.00266,
    "44": 0.00295,
    "45": 0.00305,
    "46": 0.00347,
    "47": 0.00368,
    "48": 0.00408,
    "49": 0.0046,
    "50": 0.00496,
    "51": 0.00553,
    "52": 0.00602,
    "53": 0.00664,
    "54": 0.00729,
    "55": 0.00768,
    "56": 0.0085,
    "57": 0.00926,
    "58": 0.00984,
    "59": 0.01069,
    "60": 0.0113,
    "61": 0.01228,
    "62": 0.01321,
    "63": 0.01448,
    "64": 0.01498,
    "65": 0.01623,
    "66": 0.01721,
    "67": 0.01821,
    "68": 0.01886,
    "69": 0.02206,
    "70": 0.02326,
    "71": 0.02588,
    "72": 0.02735,
    "73": 0.03053,
    "74": 0.0332,
    "75": 0.03674,
    "76": 0.03997,
    "77": 0.04379,
    "78": 0.04831,
    "79": 0.0529,
    "80": 0.05901,
    "81": 0.06672,
    "82": 0.07231,
    "83": 0.08,
    "84": 0.08929,
    "85": 0.09793,
    "86": 0.1095,
    "87": 0.12412,
    "88": 0.13528,
    "89": 0.15031,
    "90": 0.16677,
    "91": 0.18476,
    "92": 0.20286,
    "93": 0.22562,
    "94": 0.24509,
    "95": 0.26751,
    "96": 0.28601,
    "97": 0.30816,
    "98": 0.33108,
    "99": 0.35099
  },
  "2016": {
    "20": 0.00119,
    "21": 0.0014,
    "22": 0.00142,
    "23": 0.0015,
    "24": 0.00156,
    "25": 0.00161,
    "26": 0.00157,
    "27": 0.00169,
    "28": 0.00178,
    "29": 0.00172,
    "30": 0.00178,
    "31": 0.00179,
    "32": 0.00192,
    "33": 0.00193,
    "34": 0.00202,
    "35": 0.00208,
    "36": 0.00207,
    "37": 0.00222,
    "38": 0.00227,
    "39": 0.00235,
    "40": 0.00239,
    "41": 0.00248,
    "42": 0.00259,
    "43": 0.00295,
    "44": 0.00307,
    "45": 0.00324,
    "46": 0.00345,
    "47": 0.0038,
    "48": 0.00417,
    "49": 0.00457,
    "50": 0.00504,
    "51": 0.00535,
    "52": 0.00606,
    "53": 0.00661,
    "54": 0.00727,
    "55": 0.00791,
    "56": 0.00826,
    "57": 0.00931,
    "58": 0.0099,
    "59": 0.01077,
    "60": 0.01149,
    "61": 0.01236,
    "62": 0.01327,
    "63": 0.01409,
    "64": 0.01526,
    "65": 0.01616,
    "66": 0.01705,
    "67": 0.01819,
    "68": 0.01968,
    "69": 0.02049,
    "70": 0.0233,
    "71": 0.02529,
    "72": 0.02776,
    "73": 0.02946,
    "74": 0.0331,
    "75": 0.03654,
    "76": 0.03968,
    "77": 0.0433,
    "78": 0.04686,
    "79": 0.05271,
    "80": 0.05793,
    "81": 0.06363,
    "82": 0.0711,
    "83": 0.07796,
    "84": 0.08728,
    "85": 0.09623,
    "86": 0.10733,
    "87": 0.1199,
    "88": 0.13281,
    "89": 0.14801,
    "90": 0.16559,
    "91": 0.18165,
    "92": 0.19907,
    "93": 0.21813,
    "94": 0.23904,
    "95": 0.25775,
    "96": 0.28216,
    "97": 0.30054,
    "98": 0.3212,
    "99": 0.33948
  },
  "2017": {
    "20": 0.00121,
    "21": 0.0013,
    "22": 0.00138,
    "23": 0.00146,
    "24": 0.00155,
    "25": 0.0016,
    "26": 0.00168,
    "27": 0.00171,
    "28": 0.00179,
    "29": 0.00181,
    "30": 0.00181,
    "31": 0.0019,
    "32": 0.00197,
    "33": 0.00205,
    "34": 0.0021,
    "35": 0.00213,
    "36": 0.00223,
    "37": 0.00223,
    "38": 0.00235,
    "39": 0.00243,
    "40": 0.00256,
    "41": 0.00262,
    "42": 0.00264,
    "43": 0.00286,
    "44": 0.00304,
    "45": 0.0034,
    "46": 0.0036,
    "47": 0.00382,
    "48": 0.00413,
    "49": 0.00447,
    "50": 0.00498,
    "51": 0.00556,
    "52": 0.00585,
    "53": 0.00655,
    "54": 0.00717,
    "55": 0.00788,
    "56": 0.00845,
    "57": 0.00901,
    "58": 0.00989,
    "59": 0.01073,
    "60": 0.01154,
    "61": 0.01235,
    "62": 0.01305,
    "63": 0.01432,
    "64": 0.01526,
    "65": 0.0162,
    "66": 0.0171,
    "67": 0.01844,
    "68": 0.0195,
    "69": 0.02112,
    "70": 0.0219,
    "71": 0.02564,
    "72": 0.02715,
    "73": 0.03016,
    "74": 0.0324,
    "75": 0.03575,
    "76": 0.03928,
    "77": 0.04357,
    "78": 0.04768,
    "79": 0.05271,
    "80": 0.05841,
    "81": 0.06415,
    "82": 0.07144,
    "83": 0.0799,
    "84": 0.08822,
    "85": 0.09725,
    "86": 0.10868,
    "87": 0.12045,
    "88": 0.13537,
    "89": 0.14992,
    "90": 0.16526,
    "91": 0.18216,
    "92": 0.20497,
    "93": 0.22089,
    "94": 0.24028,
    "95": 0.26441,
    "96": 0.28571,
    "97": 0.31038,
    "98": 0.32968,
    "99": 0.34625
  },
  "2018": {
    "20": 0.00109,
    "21": 0.00127,
    "22": 0.00133,
    "23": 0.00138,
    "24": 0.00146,
    "25": 0.0015,
    "26": 0.00156,
    "27": 0.00168,
    "28": 0.0017,
    "29": 0.00173,
    "30": 0.00184,
    "31": 0.00183,
    "32": 0.00189,
    "33": 0.00193,
    "34": 0.00202,
    "35": 0.00214,
    "36": 0.00215,
    "37": 0.00227,
    "38": 0.00233,
    "39": 0.0025,
    "40": 0.00251,
    "41": 0.00263,
    "42": 0.00271,
    "43": 0.00277,
    "44": 0.00311,
    "45": 0.00336,
    "46": 0.00363,
    "47": 0.00384,
    "48": 0.00404,
    "49": 0.00456,
    "50": 0.00487,
    "51": 0.00534,
    "52": 0.00591,
    "53": 0.00635,
    "54": 0.00706,
    "55": 0.00778,
    "56": 0.00849,
    "57": 0.00923,
    "58": 0.00972,
    "59": 0.01071,
    "60": 0.0114,
    "61": 0.01236,
    "62": 0.01342,
    "63": 0.01429,
    "64": 0.01526,
    "65": 0.01624,
    "66": 0.01732,
    "67": 0.01832,
    "68": 0.01948,
    "69": 0.02117,
    "70": 0.0226,
    "71": 0.02372,
    "72": 0.02761,
    "73": 0.02953,
    "74": 0.03265,
    "75": 0.03526,
    "76": 0.03917,
    "77": 0.04325,
    "78": 0.04682,
    "79": 0.05181,
    "80": 0.05704,
    "81": 0.06354,
    "82": 0.07028,
    "83": 0.07746,
    "84": 0.08749,
    "85": 0.09645,
    "86": 0.10712,
    "87": 0.11857,
    "88": 0.13355,
    "89": 0.14856,
    "90": 0.16467,
    "91": 0.18096,
    "92": 0.19944,
    "93": 0.22232,
    "94": 0.23947,
    "95": 0.26026,
    "96": 0.28698,
    "97": 0.30834,
    "98": 0.32813,
    "99": 0.33725
  }
};
export const yearly = {
  "1999": {
    /*"0": 27937,
    "1": 1989,
    "2": 1376,
    "3": 1046,
    "4": 838,
    "5": 763,
    "6": 696,
    "7": 683,
    "8": 692,
    "9": 640,
    "10": 687,
    "11": 711,
    "12": 717,
    "13": 847,
    "14": 1159,
    "15": 1509,
    "16": 2354,
    "17": 2904,
    "18": 3404,
    "19": 3607,*/
    "20": 3501,
    "21": 3538,
    "22": 3373,
    "23": 3288,
    "24": 3178,
    "25": 3137,
    "26": 3277,
    "27": 3549,
    "28": 3762,
    "29": 3978,
    "30": 4005,
    "31": 4184,
    "32": 4599,
    "33": 4965,
    "34": 5610,
    "35": 6275,
    "36": 6667,
    "37": 7306,
    "38": 8041,
    "39": 8515,
    "40": 9036,
    "41": 9807,
    "42": 10473,
    "43": 11212,
    "44": 11924,
    "45": 12407,
    "46": 13106,
    "47": 13550,
    "48": 14039,
    "49": 15018,
    "50": 15633,
    "51": 16870,
    "52": 18686,
    "53": 16198,
    "54": 17467,
    "55": 19151,
    "56": 21335,
    "57": 20641,
    "58": 21328,
    "59": 22429,
    "60": 23520,
    "61": 25087,
    "62": 26240,
    "63": 28216,
    "64": 31032,
    "65": 31942,
    "66": 34234,
    "67": 36624,
    "68": 40061,
    "69": 43834,
    "70": 45803,
    "71": 49997,
    "72": 53562,
    "73": 56456,
    "74": 60087,
    "75": 62824,
    "76": 64443,
    "77": 68654,
    "78": 70776,
    "79": 71652,
    "80": 70344,
    "81": 72038,
    "82": 72361,
    "83": 72459,
    "84": 73039,
    "85": 72299,
    "86": 70110,
    "87": 66398,
    "88": 63168,
    "89": 58928,
    "90": 53410,
    "91": 49652,
    "92": 43121,
    "93": 36454,
    "94": 31354,
    "95": 25851,
    "96": 20738,
    "97": 15821,
    "98": 12093,
    "99": 8831,
    "100": 17913
  },
  "2000": {
    /*"0": 28035,
    "1": 1946,
    "2": 1317,
    "3": 932,
    "4": 784,
    "5": 744,
    "6": 653,
    "7": 642,
    "8": 609,
    "9": 605,
    "10": 644,
    "11": 742,
    "12": 741,
    "13": 872,
    "14": 1161,
    "15": 1559,
    "16": 2209,
    "17": 2736,
    "18": 3367,
    "19": 3692,*/
    "20": 3723,
    "21": 3779,
    "22": 3512,
    "23": 3396,
    "24": 3334,
    "25": 3299,
    "26": 3249,
    "27": 3482,
    "28": 3629,
    "29": 4022,
    "30": 4025,
    "31": 4255,
    "32": 4382,
    "33": 4786,
    "34": 5322,
    "35": 5891,
    "36": 6566,
    "37": 7235,
    "38": 7901,
    "39": 8547,
    "40": 9405,
    "41": 9973,
    "42": 10600,
    "43": 11629,
    "44": 12051,
    "45": 12905,
    "46": 13390,
    "47": 14164,
    "48": 14887,
    "49": 15486,
    "50": 16356,
    "51": 16992,
    "52": 18527,
    "53": 20212,
    "54": 17422,
    "55": 18781,
    "56": 20403,
    "57": 22527,
    "58": 22248,
    "59": 22792,
    "60": 23748,
    "61": 25092,
    "62": 26924,
    "63": 28018,
    "64": 30313,
    "65": 32150,
    "66": 33135,
    "67": 36042,
    "68": 38801,
    "69": 41611,
    "70": 45594,
    "71": 48157,
    "72": 52391,
    "73": 55183,
    "74": 58145,
    "75": 62634,
    "76": 64746,
    "77": 66877,
    "78": 70755,
    "79": 72688,
    "80": 73680,
    "81": 70977,
    "82": 73375,
    "83": 72303,
    "84": 72410,
    "85": 72959,
    "86": 71132,
    "87": 68345,
    "88": 63584,
    "89": 59793,
    "90": 54952,
    "91": 49513,
    "92": 44901,
    "93": 37997,
    "94": 31920,
    "95": 26185,
    "96": 21011,
    "97": 16402,
    "98": 12134,
    "99": 8909,
    "100": 18434
  },
  "2001": {
    /*"0": 27568,
    "1": 2002,
    "2": 1303,
    "3": 983,
    "4": 819,
    "5": 670,
    "6": 633,
    "7": 581,
    "8": 584,
    "9": 625,
    "10": 636,
    "11": 681,
    "12": 736,
    "13": 861,
    "14": 1088,
    "15": 1461,
    "16": 2182,
    "17": 2771,
    "18": 3377,
    "19": 3764,*/
    "20": 3757,
    "21": 4095,
    "22": 3715,
    "23": 3609,
    "24": 3521,
    "25": 3406,
    "26": 3573,
    "27": 3511,
    "28": 3763,
    "29": 3958,
    "30": 4382,
    "31": 4438,
    "32": 4571,
    "33": 4879,
    "34": 5202,
    "35": 5901,
    "36": 6597,
    "37": 7472,
    "38": 8173,
    "39": 8752,
    "40": 9507,
    "41": 10204,
    "42": 10659,
    "43": 11861,
    "44": 12548,
    "45": 13231,
    "46": 14021,
    "47": 14833,
    "48": 15328,
    "49": 16271,
    "50": 16640,
    "51": 17558,
    "52": 18537,
    "53": 19897,
    "54": 21749,
    "55": 18963,
    "56": 20109,
    "57": 22022,
    "58": 24525,
    "59": 23904,
    "60": 24353,
    "61": 25239,
    "62": 26883,
    "63": 28576,
    "64": 29565,
    "65": 31559,
    "66": 33635,
    "67": 34965,
    "68": 37350,
    "69": 40723,
    "70": 43556,
    "71": 47443,
    "72": 50027,
    "73": 54608,
    "74": 57094,
    "75": 60252,
    "76": 64767,
    "77": 66922,
    "78": 68726,
    "79": 73010,
    "80": 74602,
    "81": 74618,
    "82": 72642,
    "83": 73768,
    "84": 72622,
    "85": 72205,
    "86": 71689,
    "87": 69432,
    "88": 65507,
    "89": 60942,
    "90": 55277,
    "91": 50250,
    "92": 44059,
    "93": 39272,
    "94": 32456,
    "95": 26789,
    "96": 21492,
    "97": 16364,
    "98": 12544,
    "99": 8765,
    "100": 18488
  },
  "2002": {
    /*"0": 28034,
    "1": 1910,
    "2": 1257,
    "3": 912,
    "4": 779,
    "5": 674,
    "6": 568,
    "7": 565,
    "8": 613,
    "9": 598,
    "10": 635,
    "11": 656,
    "12": 800,
    "13": 947,
    "14": 1094,
    "15": 1417,
    "16": 2340,
    "17": 2773,
    "18": 3439,
    "19": 3843,*/
    "20": 3844,
    "21": 4089,
    "22": 3881,
    "23": 3754,
    "24": 3666,
    "25": 3507,
    "26": 3587,
    "27": 3486,
    "28": 3551,
    "29": 3828,
    "30": 4165,
    "31": 4525,
    "32": 4656,
    "33": 4938,
    "34": 5112,
    "35": 5592,
    "36": 6137,
    "37": 7051,
    "38": 7856,
    "39": 8711,
    "40": 9522,
    "41": 10318,
    "42": 11136,
    "43": 11953,
    "44": 12864,
    "45": 13633,
    "46": 14378,
    "47": 15201,
    "48": 16194,
    "49": 16659,
    "50": 17411,
    "51": 18161,
    "52": 18955,
    "53": 20211,
    "54": 21582,
    "55": 23706,
    "56": 20472,
    "57": 21475,
    "58": 23564,
    "59": 26224,
    "60": 25813,
    "61": 25863,
    "62": 27401,
    "63": 28551,
    "64": 30273,
    "65": 31152,
    "66": 32892,
    "67": 35781,
    "68": 36341,
    "69": 39425,
    "70": 42908,
    "71": 46022,
    "72": 49512,
    "73": 52427,
    "74": 56530,
    "75": 60029,
    "76": 62645,
    "77": 66983,
    "78": 69209,
    "79": 71274,
    "80": 75456,
    "81": 76729,
    "82": 76857,
    "83": 73680,
    "84": 74792,
    "85": 72656,
    "86": 72099,
    "87": 70322,
    "88": 67154,
    "89": 63154,
    "90": 56949,
    "91": 51598,
    "92": 45961,
    "93": 39917,
    "94": 34162,
    "95": 27281,
    "96": 21624,
    "97": 17001,
    "98": 12890,
    "99": 9486,
    "100": 18822
  },
  "2003": {
    /*"0": 28025,
    "1": 1864,
    "2": 1338,
    "3": 1003,
    "4": 760,
    "5": 649,
    "6": 582,
    "7": 565,
    "8": 558,
    "9": 544,
    "10": 683,
    "11": 623,
    "12": 756,
    "13": 911,
    "14": 1083,
    "15": 1397,
    "16": 2186,
    "17": 2702,
    "18": 3537,
    "19": 3773,*/
    "20": 3943,
    "21": 4019,
    "22": 4161,
    "23": 4004,
    "24": 3846,
    "25": 3615,
    "26": 3676,
    "27": 3604,
    "28": 3576,
    "29": 3779,
    "30": 3906,
    "31": 4256,
    "32": 4719,
    "33": 5063,
    "34": 5106,
    "35": 5537,
    "36": 5952,
    "37": 6639,
    "38": 7503,
    "39": 8424,
    "40": 9212,
    "41": 10161,
    "42": 11110,
    "43": 12029,
    "44": 12894,
    "45": 13673,
    "46": 14973,
    "47": 15596,
    "48": 16579,
    "49": 17352,
    "50": 18055,
    "51": 18846,
    "52": 19506,
    "53": 20241,
    "54": 21960,
    "55": 23261,
    "56": 25373,
    "57": 21829,
    "58": 23316,
    "59": 25560,
    "60": 28145,
    "61": 27509,
    "62": 28126,
    "63": 28881,
    "64": 30519,
    "65": 31760,
    "66": 32535,
    "67": 35034,
    "68": 37184,
    "69": 38304,
    "70": 41374,
    "71": 43803,
    "72": 47962,
    "73": 51700,
    "74": 53841,
    "75": 58764,
    "76": 61925,
    "77": 64656,
    "78": 68459,
    "79": 71381,
    "80": 73082,
    "81": 76366,
    "82": 77613,
    "83": 77263,
    "84": 73515,
    "85": 73905,
    "86": 71865,
    "87": 69675,
    "88": 67694,
    "89": 63708,
    "90": 58486,
    "91": 52427,
    "92": 46435,
    "93": 40449,
    "94": 33606,
    "95": 28565,
    "96": 22406,
    "97": 17015,
    "98": 12892,
    "99": 9393,
    "100": 19331
  },
  "2004": {
    /*"0": 27936,
    "1": 1964,
    "2": 1194,
    "3": 889,
    "4": 738,
    "5": 657,
    "6": 602,
    "7": 538,
    "8": 521,
    "9": 570,
    "10": 577,
    "11": 581,
    "12": 734,
    "13": 880,
    "14": 1174,
    "15": 1527,
    "16": 2210,
    "17": 2712,
    "18": 3491,
    "19": 3766,*/
    "20": 3748,
    "21": 4095,
    "22": 4020,
    "23": 3905,
    "24": 3947,
    "25": 3856,
    "26": 3783,
    "27": 3783,
    "28": 3551,
    "29": 3798,
    "30": 3592,
    "31": 3980,
    "32": 4515,
    "33": 4800,
    "34": 5210,
    "35": 5495,
    "36": 5606,
    "37": 6220,
    "38": 6831,
    "39": 7792,
    "40": 8867,
    "41": 9654,
    "42": 10741,
    "43": 11709,
    "44": 12447,
    "45": 13605,
    "46": 14805,
    "47": 15809,
    "48": 16368,
    "49": 17340,
    "50": 18365,
    "51": 19112,
    "52": 19968,
    "53": 20651,
    "54": 21674,
    "55": 22772,
    "56": 24262,
    "57": 26838,
    "58": 23093,
    "59": 24198,
    "60": 26363,
    "61": 29422,
    "62": 28754,
    "63": 28976,
    "64": 30019,
    "65": 31294,
    "66": 32894,
    "67": 34033,
    "68": 35789,
    "69": 37974,
    "70": 39344,
    "71": 41942,
    "72": 45322,
    "73": 48520,
    "74": 52554,
    "75": 55089,
    "76": 59134,
    "77": 62377,
    "78": 65378,
    "79": 68768,
    "80": 71315,
    "81": 72786,
    "82": 76388,
    "83": 77067,
    "84": 75928,
    "85": 71116,
    "86": 71293,
    "87": 68096,
    "88": 65235,
    "89": 62400,
    "90": 57365,
    "91": 51846,
    "92": 45308,
    "93": 39487,
    "94": 33655,
    "95": 27074,
    "96": 22038,
    "97": 16862,
    "98": 12366,
    "99": 9106,
    "100": 18526
  },
  "2005": {
    /*"0": 28440,
    "1": 1915,
    "2": 1244,
    "3": 875,
    "4": 722,
    "5": 596,
    "6": 619,
    "7": 547,
    "8": 530,
    "9": 545,
    "10": 509,
    "11": 601,
    "12": 647,
    "13": 849,
    "14": 1159,
    "15": 1514,
    "16": 2106,
    "17": 2760,
    "18": 3454,
    "19": 3869,*/
    "20": 3859,
    "21": 4177,
    "22": 4189,
    "23": 4182,
    "24": 4124,
    "25": 4119,
    "26": 4006,
    "27": 3896,
    "28": 3860,
    "29": 3687,
    "30": 3940,
    "31": 4064,
    "32": 4444,
    "33": 4679,
    "34": 5230,
    "35": 5501,
    "36": 5646,
    "37": 6092,
    "38": 6630,
    "39": 7551,
    "40": 8594,
    "41": 9554,
    "42": 10773,
    "43": 11676,
    "44": 12768,
    "45": 13782,
    "46": 14714,
    "47": 15779,
    "48": 16969,
    "49": 18139,
    "50": 19183,
    "51": 19926,
    "52": 21181,
    "53": 21619,
    "54": 22238,
    "55": 23400,
    "56": 24329,
    "57": 26278,
    "58": 28623,
    "59": 24848,
    "60": 26050,
    "61": 28086,
    "62": 31895,
    "63": 30880,
    "64": 30912,
    "65": 31533,
    "66": 32790,
    "67": 34775,
    "68": 35565,
    "69": 37573,
    "70": 40502,
    "71": 41615,
    "72": 44448,
    "73": 48368,
    "74": 51186,
    "75": 54667,
    "76": 57153,
    "77": 62123,
    "78": 65646,
    "79": 68299,
    "80": 72197,
    "81": 74274,
    "82": 75397,
    "83": 78135,
    "84": 78774,
    "85": 77060,
    "86": 71933,
    "87": 71210,
    "88": 67493,
    "89": 63702,
    "90": 60272,
    "91": 54510,
    "92": 48560,
    "93": 41558,
    "94": 34806,
    "95": 28986,
    "96": 22975,
    "97": 18051,
    "98": 13184,
    "99": 9565,
    "100": 19304
  },
  "2006": {
    /*"0": 28527,
    "1": 1841,
    "2": 1218,
    "3": 864,
    "4": 708,
    "5": 687,
    "6": 544,
    "7": 508,
    "8": 514,
    "9": 482,
    "10": 511,
    "11": 570,
    "12": 583,
    "13": 758,
    "14": 992,
    "15": 1414,
    "16": 2152,
    "17": 2724,
    "18": 3539,
    "19": 3910,*/
    "20": 4005,
    "21": 4353,
    "22": 4157,
    "23": 4275,
    "24": 4358,
    "25": 4345,
    "26": 4216,
    "27": 4276,
    "28": 3976,
    "29": 4084,
    "30": 3982,
    "31": 4278,
    "32": 4241,
    "33": 4524,
    "34": 5030,
    "35": 5753,
    "36": 5895,
    "37": 6047,
    "38": 6545,
    "39": 7259,
    "40": 8075,
    "41": 9137,
    "42": 10485,
    "43": 11507,
    "44": 12340,
    "45": 13556,
    "46": 14516,
    "47": 15790,
    "48": 17184,
    "49": 18222,
    "50": 19181,
    "51": 20212,
    "52": 21327,
    "53": 22088,
    "54": 22955,
    "55": 23641,
    "56": 24552,
    "57": 26014,
    "58": 28325,
    "59": 30521,
    "60": 26299,
    "61": 27110,
    "62": 29990,
    "63": 33146,
    "64": 31803,
    "65": 31888,
    "66": 32682,
    "67": 34477,
    "68": 35715,
    "69": 37121,
    "70": 39233,
    "71": 41674,
    "72": 42333,
    "73": 45897,
    "74": 49073,
    "75": 52331,
    "76": 56867,
    "77": 59092,
    "78": 63064,
    "79": 66356,
    "80": 69171,
    "81": 72756,
    "82": 75132,
    "83": 75017,
    "84": 77552,
    "85": 77234,
    "86": 74920,
    "87": 69050,
    "88": 67930,
    "89": 62978,
    "90": 58627,
    "91": 54359,
    "92": 48560,
    "93": 41846,
    "94": 34970,
    "95": 28775,
    "96": 22773,
    "97": 17591,
    "98": 13590,
    "99": 9507,
    "100": 19282
  },
  "2007": {
    /*"0": 29138,
    "1": 1940,
    "2": 1178,
    "3": 882,
    "4": 703,
    "5": 595,
    "6": 570,
    "7": 528,
    "8": 511,
    "9": 507,
    "10": 551,
    "11": 535,
    "12": 590,
    "13": 781,
    "14": 979,
    "15": 1372,
    "16": 2009,
    "17": 2593,
    "18": 3504,
    "19": 3821,*/
    "20": 3769,
    "21": 4187,
    "22": 4309,
    "23": 4113,
    "24": 4305,
    "25": 4129,
    "26": 4233,
    "27": 4226,
    "28": 4160,
    "29": 4183,
    "30": 4117,
    "31": 4102,
    "32": 4263,
    "33": 4339,
    "34": 4820,
    "35": 5309,
    "36": 5837,
    "37": 6255,
    "38": 6598,
    "39": 6882,
    "40": 7607,
    "41": 8475,
    "42": 9546,
    "43": 10932,
    "44": 12165,
    "45": 13071,
    "46": 14477,
    "47": 15643,
    "48": 16708,
    "49": 17839,
    "50": 19360,
    "51": 20623,
    "52": 21352,
    "53": 22451,
    "54": 23162,
    "55": 24254,
    "56": 24955,
    "57": 26279,
    "58": 27504,
    "59": 29466,
    "60": 32041,
    "61": 27403,
    "62": 28804,
    "63": 31648,
    "64": 34756,
    "65": 33801,
    "66": 33496,
    "67": 34281,
    "68": 35602,
    "69": 37811,
    "70": 38341,
    "71": 40687,
    "72": 43613,
    "73": 44334,
    "74": 47272,
    "75": 51055,
    "76": 54369,
    "77": 58251,
    "78": 60579,
    "79": 64775,
    "80": 67786,
    "81": 70562,
    "82": 73985,
    "83": 75459,
    "84": 75861,
    "85": 77832,
    "86": 77066,
    "87": 73003,
    "88": 66968,
    "89": 64355,
    "90": 58928,
    "91": 54036,
    "92": 48749,
    "93": 42448,
    "94": 36019,
    "95": 29592,
    "96": 22912,
    "97": 18237,
    "98": 13730,
    "99": 10006,
    "100": 19766
  },
  "2008": {
    /*"0": 28059,
    "1": 1967,
    "2": 1169,
    "3": 896,
    "4": 698,
    "5": 568,
    "6": 497,
    "7": 498,
    "8": 489,
    "9": 450,
    "10": 484,
    "11": 506,
    "12": 569,
    "13": 699,
    "14": 891,
    "15": 1303,
    "16": 1831,
    "17": 2399,
    "18": 3277,
    "19": 3597,*/
    "20": 3784,
    "21": 3948,
    "22": 4073,
    "23": 4012,
    "24": 3974,
    "25": 4141,
    "26": 4023,
    "27": 4152,
    "28": 4266,
    "29": 4204,
    "30": 4235,
    "31": 4190,
    "32": 4180,
    "33": 4367,
    "34": 4517,
    "35": 4951,
    "36": 5362,
    "37": 6243,
    "38": 6577,
    "39": 6731,
    "40": 7216,
    "41": 8126,
    "42": 9021,
    "43": 10322,
    "44": 11821,
    "45": 12769,
    "46": 14114,
    "47": 15627,
    "48": 16695,
    "49": 18212,
    "50": 19581,
    "51": 20741,
    "52": 21817,
    "53": 22877,
    "54": 24109,
    "55": 25085,
    "56": 25952,
    "57": 26521,
    "58": 27898,
    "59": 29252,
    "60": 32013,
    "61": 34646,
    "62": 29582,
    "63": 31105,
    "64": 34128,
    "65": 37040,
    "66": 35615,
    "67": 35708,
    "68": 36530,
    "69": 38557,
    "70": 39931,
    "71": 40787,
    "72": 43896,
    "73": 46481,
    "74": 47034,
    "75": 50235,
    "76": 53618,
    "77": 57532,
    "78": 61804,
    "79": 64181,
    "80": 67733,
    "81": 70756,
    "82": 73025,
    "83": 76795,
    "84": 77881,
    "85": 77640,
    "86": 79196,
    "87": 76935,
    "88": 73109,
    "89": 65672,
    "90": 62598,
    "91": 56051,
    "92": 50172,
    "93": 44453,
    "94": 38107,
    "95": 31242,
    "96": 24661,
    "97": 19289,
    "98": 14415,
    "99": 10259,
    "100": 20892
  },
  "2009": {
    /*"0": 26412,
    "1": 1748,
    "2": 1157,
    "3": 876,
    "4": 669,
    "5": 586,
    "6": 523,
    "7": 446,
    "8": 458,
    "9": 510,
    "10": 466,
    "11": 575,
    "12": 572,
    "13": 674,
    "14": 841,
    "15": 1159,
    "16": 1718,
    "17": 2250,
    "18": 3051,
    "19": 3342,
    "20": 3519,*/
    "21": 3863,
    "22": 3658,
    "23": 3931,
    "24": 3925,
    "25": 3877,
    "26": 4107,
    "27": 4018,
    "28": 4225,
    "29": 4385,
    "30": 4303,
    "31": 4192,
    "32": 4319,
    "33": 4401,
    "34": 4675,
    "35": 4878,
    "36": 5243,
    "37": 5832,
    "38": 6557,
    "39": 7000,
    "40": 7447,
    "41": 8020,
    "42": 8728,
    "43": 9670,
    "44": 11290,
    "45": 12568,
    "46": 13917,
    "47": 15340,
    "48": 16649,
    "49": 17933,
    "50": 19359,
    "51": 20905,
    "52": 22684,
    "53": 23593,
    "54": 24620,
    "55": 25556,
    "56": 26572,
    "57": 27649,
    "58": 28269,
    "59": 29660,
    "60": 31518,
    "61": 33317,
    "62": 36617,
    "63": 31634,
    "64": 32515,
    "65": 35435,
    "66": 38535,
    "67": 37050,
    "68": 37086,
    "69": 37665,
    "70": 39434,
    "71": 41317,
    "72": 42164,
    "73": 44731,
    "74": 47615,
    "75": 47955,
    "76": 51082,
    "77": 54733,
    "78": 59223,
    "79": 61994,
    "80": 64312,
    "81": 68109,
    "82": 71549,
    "83": 72946,
    "84": 75824,
    "85": 76411,
    "86": 76038,
    "87": 75946,
    "88": 73111,
    "89": 67626,
    "90": 60015,
    "91": 55639,
    "92": 49329,
    "93": 43309,
    "94": 37242,
    "95": 30646,
    "96": 24575,
    "97": 18713,
    "98": 13909,
    "99": 10277,
    "100": 20392
  },
  "2010": {
    "20": 3425.96325,
    "21": 3751.238115,
    "22": 3664.81215,
    "23": 3668.37588,
    "24": 3967.2798,
    "25": 3881.42735,
    "26": 3952.30305,
    "27": 3940.06683,
    "28": 4183.42502,
    "29": 4252.02425,
    "30": 4411.422675,
    "31": 4325.10371,
    "32": 4383.697435,
    "33": 4405.04512,
    "34": 4433.21144,
    "35": 4757.16407,
    "36": 4997.762415,
    "37": 5415.74163,
    "38": 6037.8894,
    "39": 6831.65772,
    "40": 7106.6565,
    "41": 7597.360025,
    "42": 8184.83105,
    "43": 8882.42845,
    "44": 10256.87216,
    "45": 11627.56,
    "46": 13496.99812,
    "47": 14738.7305,
    "48": 15937.58998,
    "49": 17842.50204,
    "50": 19279.60935,
    "51": 20288.35226,
    "52": 22266.3952,
    "53": 23415.79885,
    "54": 24592.06425,
    "55": 25721.6118,
    "56": 27155.68156,
    "57": 28096.93688,
    "58": 29314.44543,
    "59": 30327.6579,
    "60": 31300.25358,
    "61": 32717.35896,
    "62": 35717.54074,
    "63": 38473.50395,
    "64": 33350.18053,
    "65": 35554.8573,
    "66": 38164.672,
    "67": 41171.43144,
    "68": 40551.66791,
    "69": 40612.79625,
    "70": 42156.80504,
    "71": 43093.90116,
    "72": 45557.88913,
    "73": 46460.04117,
    "74": 49347.34672,
    "75": 52488.45525,
    "76": 52970.31025,
    "77": 56669.24538,
    "78": 60771.30466,
    "79": 64054.12665,
    "80": 69298.65473,
    "81": 70889.92334,
    "82": 75270.63812,
    "83": 76923.82377,
    "84": 79379.31816,
    "85": 82148.23739,
    "86": 81206.37208,
    "87": 78828.79719,
    "88": 78285.66725,
    "89": 74032.99144,
    "90": 68096.60562,
    "91": 57535.51668,
    "92": 53030.8568,
    "93": 44817.09228,
    "94": 37927.01568,
    "95": 31876.44915,
    "96": 25364.17461,
    "97": 19032.20219,
    "98": 14025.03596,
    "99": 10287.5409,
    "100": 17726.5925
  },
  "2011": {
    "20": 3356.60351,
    "21": 3836.02841,
    "22": 3927.56021,
    "23": 3945.544965,
    "24": 3817.8045,
    "25": 3990.313525,
    "26": 4074.3297,
    "27": 3972.17325,
    "28": 4085.65152,
    "29": 4349.51562,
    "30": 4436.1492,
    "31": 4640.59655,
    "32": 4661.0995,
    "33": 4633.306355,
    "34": 4769.30091,
    "35": 4748.32704,
    "36": 5080.34259,
    "37": 5271.97,
    "38": 5868.408875,
    "39": 6530.7632,
    "40": 7458.0237,
    "41": 7826.630595,
    "42": 8444.80609,
    "43": 8992.997685,
    "44": 9730.98224,
    "45": 11054.1888,
    "46": 12734.98213,
    "47": 14505.23565,
    "48": 15874.06275,
    "49": 17559.61725,
    "50": 19359.44433,
    "51": 20777.98686,
    "52": 22462.12482,
    "53": 23765.70248,
    "54": 25458.52825,
    "55": 26669.92514,
    "56": 28066.25523,
    "57": 29217.47199,
    "58": 29881.36088,
    "59": 31077.74534,
    "60": 32196.48033,
    "61": 33084.3555,
    "62": 35104.20375,
    "63": 37987.35228,
    "64": 41438.56366,
    "65": 35609.47622,
    "66": 37333.45514,
    "67": 40249.72674,
    "68": 44145.37088,
    "69": 42820.77105,
    "70": 43282.12274,
    "71": 44298.52531,
    "72": 45987.57924,
    "73": 48289.94625,
    "74": 49262.53464,
    "75": 51969.79424,
    "76": 55589.33592,
    "77": 55881.69053,
    "78": 59369.14368,
    "79": 63195.5808,
    "80": 67813.89808,
    "81": 72965.6979,
    "82": 74314.7015,
    "83": 78084.52942,
    "84": 79761.76667,
    "85": 81028.57986,
    "86": 82314.54009,
    "87": 81664.46912,
    "88": 78094.53111,
    "89": 76566.53736,
    "90": 71721.37824,
    "91": 64316.87055,
    "92": 54420.84378,
    "93": 47906.9241,
    "94": 39678.26801,
    "95": 32861.29174,
    "96": 26689.71308,
    "97": 20822.11507,
    "98": 15144.53292,
    "99": 10840.81842,
    "100": 19274.04413
  },
  "2012": {
    "20": 3364.24942,
    "21": 3749.84964,
    "22": 4070.18128,
    "23": 3799.066815,
    "24": 3929.21497,
    "25": 3929.41292,
    "26": 4101.859655,
    "27": 4076.6625,
    "28": 4267.414235,
    "29": 4490.8311,
    "30": 4582.36916,
    "31": 4686.04422,
    "32": 4655.430475,
    "33": 4837.18221,
    "34": 4825.12547,
    "35": 5017.55283,
    "36": 5160.569975,
    "37": 5405.16249,
    "38": 5643.63576,
    "39": 6166.37818,
    "40": 7023.58732,
    "41": 7933.092185,
    "42": 8437.011275,
    "43": 8916.97521,
    "44": 9514.62531,
    "45": 10433.20198,
    "46": 11847.14616,
    "47": 13725.33269,
    "48": 15573.15683,
    "49": 16954.65968,
    "50": 18919.8408,
    "51": 20928.31045,
    "52": 22348.1889,
    "53": 23582.62427,
    "54": 25398.32703,
    "55": 27535.75209,
    "56": 28714.65188,
    "57": 29843.95392,
    "58": 31260.8925,
    "59": 32271.46524,
    "60": 33416.90468,
    "61": 34487.97911,
    "62": 35772.8519,
    "63": 37711.92516,
    "64": 40183.23762,
    "65": 44326.86947,
    "66": 38299.7055,
    "67": 39926.4363,
    "68": 43163.7852,
    "69": 46615.86806,
    "70": 45324.32138,
    "71": 45373.9488,
    "72": 46944.1779,
    "73": 47974.84153,
    "74": 50635.4242,
    "75": 51878.56343,
    "76": 54783.75699,
    "77": 58366.16687,
    "78": 58273.25292,
    "79": 62692.76898,
    "80": 66427.4338,
    "81": 70199.94669,
    "82": 75452.14125,
    "83": 76524.4547,
    "84": 79564.87693,
    "85": 81012.16032,
    "86": 81138.57868,
    "87": 82243.4325,
    "88": 80633.7532,
    "89": 76173.35863,
    "90": 73765.31778,
    "91": 67201.31919,
    "92": 58459.30778,
    "93": 48503.00448,
    "94": 42873.66819,
    "95": 34315.34295,
    "96": 27691.1895,
    "97": 21706.9158,
    "98": 16651.03392,
    "99": 11477.62819,
    "100": 20551.40003
  },
  "2013": {
    "20": 3228.0741,
    "21": 3635.76555,
    "22": 3867.52942,
    "23": 4049.87262,
    "24": 4002.19656,
    "25": 3931.8489,
    "26": 4125.1104,
    "27": 4254.99512,
    "28": 4271.52208,
    "29": 4330.4692,
    "30": 4596.5274,
    "31": 4706.68887,
    "32": 4807.56692,
    "33": 5058.657175,
    "34": 5195.06145,
    "35": 5255.109515,
    "36": 5283.24915,
    "37": 5437.331355,
    "38": 5748.19875,
    "39": 6109.7499,
    "40": 6754.68812,
    "41": 7453.04085,
    "42": 8664.617525,
    "43": 9218.13601,
    "44": 9675.78867,
    "45": 10334.8536,
    "46": 11596.41062,
    "47": 13110.50785,
    "48": 14815.0968,
    "49": 16987.70178,
    "50": 18603.67374,
    "51": 20549.1776,
    "52": 22267.3542,
    "53": 24009.31191,
    "54": 25613.10675,
    "55": 27637.87835,
    "56": 29569.71785,
    "57": 30673.17925,
    "58": 32370.85875,
    "59": 33310.81782,
    "60": 34636.07768,
    "61": 35744.83623,
    "62": 36940.42206,
    "63": 38389.80252,
    "64": 40476.45975,
    "65": 43905.2394,
    "66": 47563.69866,
    "67": 40605.39278,
    "68": 42691.80371,
    "69": 46174.84503,
    "70": 49915.14165,
    "71": 48759.9292,
    "72": 48156.25887,
    "73": 50121.6282,
    "74": 51638.16951,
    "75": 53883.25892,
    "76": 55298.81049,
    "77": 58686.08763,
    "78": 61987.27574,
    "79": 61654.506,
    "80": 66145.72078,
    "81": 69781.99322,
    "82": 74188.83555,
    "83": 78086.04469,
    "84": 79185.34673,
    "85": 81883.74725,
    "86": 82471.99869,
    "87": 82479.16152,
    "88": 81970.6225,
    "89": 79098.80087,
    "90": 73871.86037,
    "91": 69085.91547,
    "92": 62827.9772,
    "93": 54003.23475,
    "94": 43314.3942,
    "95": 36816.9384,
    "96": 28596.98565,
    "97": 22500.71466,
    "98": 17181.53994,
    "99": 12364.79789,
    "100": 21714.8708
  },
  "2014": {
    "20": 3153.80065,
    "21": 3651.41679,
    "22": 3959.5389,
    "23": 4011.912225,
    "24": 4194.285935,
    "25": 4231.17124,
    "26": 4287.235875,
    "27": 4195.05212,
    "28": 4453.878175,
    "29": 4601.2995,
    "30": 4501.76382,
    "31": 4940.046395,
    "32": 5026.051155,
    "33": 5207.22962,
    "34": 5551.3296,
    "35": 5615.726875,
    "36": 5646.22938,
    "37": 5847.547525,
    "38": 5888.74968,
    "39": 6350.9392,
    "40": 6634.048125,
    "41": 7557.717195,
    "42": 8249.32448,
    "43": 9243.2271,
    "44": 10019.7805,
    "45": 10555.46894,
    "46": 11374.314,
    "47": 12551.58473,
    "48": 14240.8127,
    "49": 16313.12599,
    "50": 18297.49524,
    "51": 20434.22277,
    "52": 22003.5407,
    "53": 24402.90678,
    "54": 25964.90957,
    "55": 28116.57413,
    "56": 30011.57394,
    "57": 31999.00104,
    "58": 33070.53141,
    "59": 34514.45933,
    "60": 36085.87538,
    "61": 37625.76934,
    "62": 38631.17104,
    "63": 39834.37148,
    "64": 40580.2508,
    "65": 43373.32615,
    "66": 46852.60223,
    "67": 50496.30904,
    "68": 43339.58568,
    "69": 45206.6679,
    "70": 48416.24942,
    "71": 53196.52688,
    "72": 51667.96015,
    "73": 51462.62446,
    "74": 53170.05056,
    "75": 55069.46224,
    "76": 57162.7944,
    "77": 58566.74844,
    "78": 61324.64003,
    "79": 64549.5565,
    "80": 64920.6852,
    "81": 69108.7929,
    "82": 72438.34058,
    "83": 75629.35503,
    "84": 80700.12027,
    "85": 79455.36372,
    "86": 81831.21975,
    "87": 81934.58466,
    "88": 80147.28927,
    "89": 78767.68445,
    "90": 75281.75593,
    "91": 69224.57613,
    "92": 63996.17048,
    "93": 56954.01778,
    "94": 47188.39179,
    "95": 36897.56772,
    "96": 31050.29555,
    "97": 23451.88804,
    "98": 17987.65568,
    "99": 12728.38211,
    "100": 23205.17865
  },
  "2015": {
    "20": 3327.192315,
    "21": 3796.746345,
    "22": 4107.25952,
    "23": 4267.719,
    "24": 4561.017495,
    "25": 4719.543,
    "26": 4533.612,
    "27": 4694.38596,
    "28": 4724.696515,
    "29": 5072.46888,
    "30": 5068.7952,
    "31": 5188.16664,
    "32": 5591.89329,
    "33": 5740.1014,
    "34": 5933.759655,
    "35": 6070.72075,
    "36": 6183.56325,
    "37": 6221.67055,
    "38": 6455.369375,
    "39": 6477.54912,
    "40": 6895.190155,
    "41": 7391.16864,
    "42": 8128.30304,
    "43": 8882.37375,
    "44": 10405.11234,
    "45": 11015.20224,
    "46": 11803.98465,
    "47": 12308.09955,
    "48": 13742.73781,
    "49": 15527.34181,
    "50": 17817.54947,
    "51": 20059.76908,
    "52": 21978.75838,
    "53": 24039.4888,
    "54": 26418.68636,
    "55": 28092.94979,
    "56": 30138.48063,
    "57": 32363.84662,
    "58": 34099.06896,
    "59": 35394.79997,
    "60": 36891.33162,
    "61": 38451.81027,
    "62": 39946.71594,
    "63": 41773.89983,
    "64": 42297.83656,
    "65": 44951.55356,
    "66": 46603.98373,
    "67": 49550.33831,
    "68": 53644.32416,
    "69": 46442.60238,
    "70": 48435.05252,
    "71": 52221.7416,
    "72": 57139.64982,
    "73": 55174.81996,
    "74": 54820.84809,
    "75": 57286.944,
    "76": 58460.79816,
    "77": 61368.975,
    "78": 62246.88464,
    "79": 65270.4127,
    "80": 69102.8307,
    "81": 69072.02795,
    "82": 72858.29908,
    "83": 76023.38265,
    "84": 79475.37636,
    "85": 82802.78204,
    "86": 81780.22218,
    "87": 84464.28865,
    "88": 82775.24325,
    "89": 79928.07945,
    "90": 78325.99665,
    "91": 73060.12442,
    "92": 66024.43731,
    "93": 59943.82155,
    "94": 51675.61664,
    "95": 42278.34125,
    "96": 32363.93743,
    "97": 26187.90398,
    "98": 19081.80433,
    "99": 14149.0882,
    "100": 25737.16288
  },
  "2016": {
    "20": 3465.24156,
    "21": 4114.14876,
    "22": 4315.50123,
    "23": 4637.6595,
    "24": 4904.5815,
    "25": 5234.955,
    "26": 5225.8866,
    "27": 5408.65995,
    "28": 5549.675895,
    "29": 5389.11708,
    "30": 5672.87562,
    "31": 5891.38529,
    "32": 6113.41245,
    "33": 6225.05169,
    "34": 6587.86236,
    "35": 6790.26488,
    "36": 7004.5024,
    "37": 6996.462385,
    "38": 7158.40528,
    "39": 7301.23746,
    "40": 7334.6669,
    "41": 7775.84828,
    "42": 8088.7317,
    "43": 9222.53544,
    "44": 10103.99198,
    "45": 11494.64602,
    "46": 12353.09716,
    "47": 12735.107,
    "48": 13758.8276,
    "49": 15266.47551,
    "50": 17024.21931,
    "51": 19216.9472,
    "52": 21846.86044,
    "53": 23943.24199,
    "54": 26064.91687,
    "55": 28620.75855,
    "56": 30272.54076,
    "57": 32555.73295,
    "58": 34386.2145,
    "59": 36788.15364,
    "60": 37945.03908,
    "61": 39831.35208,
    "62": 41429.87495,
    "63": 42282.04218,
    "64": 43979.98535,
    "65": 45344.71574,
    "66": 47034.98785,
    "67": 49158.08298,
    "68": 52715.51067,
    "69": 57411.82187,
    "70": 48338.77759,
    "71": 51536.94301,
    "72": 55100.20399,
    "73": 60197.32858,
    "74": 58547.39989,
    "75": 58836.75467,
    "76": 59913.48849,
    "77": 61674.2625,
    "78": 63802.95843,
    "79": 65371.20594,
    "80": 68464.29686,
    "81": 71346.89198,
    "82": 70950.03872,
    "83": 73927.2975,
    "84": 77250.10362,
    "85": 80067.16274,
    "86": 83051.1657,
    "87": 81259.66541,
    "88": 82018.18212,
    "89": 80252.42246,
    "90": 76906.48185,
    "91": 73613.40964,
    "92": 67054.3362,
    "93": 59434.24867,
    "94": 52310.68416,
    "95": 44206.84182,
    "96": 35197.60664,
    "97": 26485.64622,
    "98": 20768.69404,
    "99": 14368.6227,
    "100": 27214.65684
  },
  "2017": {
    "20": 3495.05894,
    "21": 3849.705565,
    "22": 4161.590055,
    "23": 4522.89798,
    "24": 4848.98272,
    "25": 5215.1463,
    "26": 5455.83366,
    "27": 5779.91469,
    "28": 5872.99584,
    "29": 5734.0416,
    "30": 5788.21446,
    "31": 6137.93976,
    "32": 6424.05095,
    "33": 6586.58574,
    "34": 6876.9855,
    "35": 7038.93551,
    "36": 7253.475105,
    "37": 7561.432275,
    "38": 7333.19769,
    "39": 7648.3075,
    "40": 7981.60826,
    "41": 7993.9881,
    "42": 8512.23734,
    "43": 8913.979265,
    "44": 9529.1394,
    "45": 11029.40487,
    "46": 12551.80053,
    "47": 13526.19662,
    "48": 13900.65688,
    "49": 14730.56415,
    "50": 16447.08713,
    "51": 18500.57088,
    "52": 20748.48876,
    "53": 23338.17723,
    "54": 25564.44804,
    "55": 28005.71358,
    "56": 30336.74446,
    "57": 32764.96241,
    "58": 34507.29954,
    "59": 36908.8264,
    "60": 39212.6946,
    "61": 40418.7278,
    "62": 42078.3045,
    "63": 44095.69073,
    "64": 45281.46538,
    "65": 46536.70416,
    "66": 47598.91845,
    "67": 50251.56346,
    "68": 52304.05741,
    "69": 56241.18585,
    "70": 60709.98164,
    "71": 52039.97815,
    "72": 55065.16245,
    "73": 58932.79505,
    "74": 64580.12987,
    "75": 62096.52403,
    "76": 61740.63354,
    "77": 64016.75394,
    "78": 65795.4427,
    "79": 68683.73428,
    "80": 69607.60275,
    "81": 72160.85844,
    "82": 75743.2809,
    "83": 74856.79464,
    "84": 78265.88828,
    "85": 80026.30874,
    "86": 82553.12653,
    "87": 85098.61968,
    "88": 82477.69542,
    "89": 81830.17022,
    "90": 79111.7618,
    "91": 74019.43427,
    "92": 70658.61624,
    "93": 62451.56649,
    "94": 54277.41553,
    "95": 46877.51145,
    "96": 38754.75792,
    "97": 29777.49718,
    "98": 22117.58613,
    "99": 16506.5655,
    "100": 29208.0848
  },
  "2018": {
    "20": 3239.17928,
    "21": 3680.39322,
    "22": 3910.70591,
    "23": 4181.419935,
    "24": 4520.476,
    "25": 4777.53432,
    "26": 5165.28582,
    "27": 5644.621275,
    "28": 5799.5421,
    "29": 5822.894935,
    "30": 6065.361,
    "31": 5935.63564,
    "32": 6262.726325,
    "33": 6459.18885,
    "34": 6533.602635,
    "35": 7081.749,
    "36": 7196.3166,
    "37": 7471.54501,
    "38": 7825.80057,
    "39": 8032.313,
    "40": 7907.36829,
    "41": 8196.64936,
    "42": 8297.41115,
    "43": 8900.60192,
    "44": 9498.74562,
    "45": 10526.2092,
    "46": 11941.19719,
    "47": 13251.19177,
    "48": 14183.30366,
    "49": 15029.25766,
    "50": 15843.24826,
    "51": 17565.58645,
    "52": 19523.7717,
    "53": 22142.68155,
    "54": 25040.26181,
    "55": 27387.84048,
    "56": 29947.8575,
    "57": 32647.63807,
    "58": 34704.2436,
    "59": 36783.1485,
    "60": 38842.56244,
    "61": 41470.71708,
    "62": 43069.22672,
    "63": 45370.3673,
    "64": 46269.13718,
    "65": 47716.19076,
    "66": 48955.09142,
    "67": 50412.6909,
    "68": 52650.46602,
    "69": 55245.75845,
    "70": 58965.37313,
    "71": 64450.94397,
    "72": 55311.84106,
    "73": 58178.71963,
    "74": 62481.59843,
    "75": 68609.32788,
    "76": 65890.96088,
    "77": 65660.81144,
    "78": 67014.19294,
    "79": 68797.81445,
    "80": 71539.9342,
    "81": 72487.76576,
    "82": 75313.25705,
    "83": 77841.0921,
    "84": 76692.45454,
    "85": 79640.76034,
    "86": 81207.9142,
    "87": 82535.7807,
    "88": 85069.30008,
    "89": 81133.08476,
    "90": 79624.7915,
    "91": 75444.62258,
    "92": 68932.03557,
    "93": 64296.0513,
    "94": 56083.62758,
    "95": 47747.07652,
    "96": 40103.28634,
    "97": 32511.97298,
    "98": 23787.42417,
    "99": 17161.41312,
    "100": 31311.47371
  },
  "2019": {
    "20": 3168.104786,
    "21": 3563.843059,
    "22": 3907.583748,
    "23": 4187.515638,
    "24": 4468.962849,
    "25": 4727.428907,
    "26": 4989.34004,
    "27": 5335.070363,
    "28": 5690.914225,
    "29": 5966.827776,
    "30": 6019.463928,
    "31": 6150.156826,
    "32": 6320.541398,
    "33": 6613.995716,
    "34": 6942.195458,
    "35": 7021.913982,
    "36": 7444.08591,
    "37": 7750.005222,
    "38": 7954.55166,
    "39": 8411.918906,
    "40": 8218.836626,
    "41": 8440.523273,
    "42": 8792.127092,
    "43": 9028.0887,
    "44": 9888.432938,
    "45": 10222.27062,
    "46": 11119.71455,
    "47": 12504.8441,
    "48": 14282.6611,
    "49": 15678.11528,
    "50": 16115.91425,
    "51": 17136.58587,
    "52": 18682.31927,
    "53": 20775.55321,
    "54": 24061.86237,
    "55": 26722.0303,
    "56": 29035.64453,
    "57": 31421.49786,
    "58": 34376.29045,
    "59": 37378.73107,
    "60": 38969.2774,
    "61": 41742.84832,
    "62": 44172.66642,
    "63": 45219.73181,
    "64": 47446.44178,
    "65": 48309.1797,
    "66": 49616.38923,
    "67": 50993.0482,
    "68": 52938.7964,
    "69": 55502.5985,
    "70": 58069.80115,
    "71": 62245.90423,
    "72": 70428.02488,
    "73": 56426.74726,
    "74": 60595.17256,
    "75": 64472.43095,
    "76": 72282.1886,
    "77": 68801.57348,
    "78": 68374.94268,
    "79": 70177.60842,
    "80": 71777.43817,
    "81": 74889.77794,
    "82": 75006.78858,
    "83": 77539.55364,
    "84": 80207.77033,
    "85": 77348.85795,
    "86": 80245.4574,
    "87": 81536.88072,
    "88": 82202.82514,
    "89": 82940.32057,
    "90": 77873.59698,
    "91": 75291.54179,
    "92": 70297.21806,
    "93": 63132.5731,
    "94": 57183.59657,
    "95": 48862.25233,
    "96": 40181.09341,
    "97": 32012.25137,
    "98": 25075.89588,
    "99": 17534.72592,
    "100": 32476.23784
  },
  "2020": {
    "0": "19582",
    "1": "1364",
    "2": 889,
    "3": 725,
    "4": 551,
    "5": 516,
    "6": 450,
    "7": 441,
    "8": 405,
    "9": 398,
    "10": 456,
    "11": 526,
    "12": 617,
    "13": 800,
    "14": "1014",
    "15": "1332",
    "16": "1793",
    "17": "2229",
    "18": "3159",
    "19": "3765",
    "20": "3983",
    "21": "4576",
    "22": "4685",
    "23": "5032",
    "24": "5262",
    "25": "5706",
    "26": "5962",
    "27": "6533",
    "28": "6970",
    "29": "7525",
    "30": "7906",
    "31": "7944",
    "32": "8049",
    "33": "8345",
    "34": "8546",
    "35": "8785",
    "36": "9051",
    "37": "9771",
    "38": "9872",
    "39": "10602",
    "40": "10945",
    "41": "11118",
    "42": "10991",
    "43": "11538",
    "44": "11817",
    "45": "12743",
    "46": "13429",
    "47": "14763",
    "48": "16683",
    "49": "18813",
    "50": "20160",
    "51": "20976",
    "52": "22399",
    "53": "24222",
    "54": "26954",
    "55": "30507",
    "56": "33814",
    "57": "36561",
    "58": "39240",
    "59": "43106",
    "60": "46304",
    "61": "48871",
    "62": "51325",
    "63": "54811",
    "64": "56010",
    "65": "58027",
    "66": "59907",
    "67": "61164",
    "68": "62889",
    "69": "64401",
    "70": "67279",
    "71": "71049",
    "72": "76103",
    "73": "82553",
    "74": "71135",
    "75": "72225",
    "76": "78100",
    "77": "85310",
    "78": "82048",
    "79": "80508",
    "80": "81661",
    "81": "83337",
    "82": "85630",
    "83": "85649",
    "84": "87616",
    "85": "89658",
    "86": "86428",
    "87": "87456",
    "88": "88017",
    "89": "87692",
    "90": "85205",
    "91": "79355",
    "92": "74460",
    "93": "67957",
    "94": "58709",
    "95": "50701",
    "96": "42141",
    "97": "33368",
    "98": "25839",
    "99": "19187",
    "100": "36632"
  },

  "2021": {
    0: 3564493,
    1: 3702936,
    2: 3767428,
    3: 3856743,
    4: 3935738,
    5: 4040153,
    6: 4076629,
    7: 4064596,
    8: 4051555,
    9: 4058615,
    10: 4103489,
    11: 4189406,
    12: 4279315,
    13: 4442581,
    14: 4432993,
    15: 4355926,
    16: 4310462,
    17: 4333375,
    18: 4292780,
    19: 4272123,
    20: 4346679,
    21: 4347378,
    22: 4278923,
    23: 4271130,
    24: 4279887,
    25: 4309146,
    26: 4387353,
    27: 4481186,
    28: 4554775,
    29: 4660017,
    30: 4730811,
    31: 4745375,
    32: 4612291,
    33: 4531756,
    34: 4482395,
    35: 4492867,
    36: 4490254,
    37: 4397037,
    38: 4463130,
    39: 4456030,
    40: 4391796,
    41: 4372860,
    42: 4193750,
    43: 4099002,
    44: 4047128,
    45: 3908305,
    46: 3951786,
    47: 3875209,
    48: 3927492,
    49: 4118718,
    55: 4366085,
    51: 4297812,
    52: 4136193,
    53: 4046823,
    54: 4060013,
    55: 4119517,
    56: 4280217,
    57: 4383354,
    58: 4384908,
    59: 4399318,
    60: 4391144,
    61: 4312796,
    62: 4228770,
    63: 4190193,
    64: 4112847,
    65: 3928157,
    66: 3834444,
    67: 3695234,
    68: 3537207,
    69: 3399278,
    70: 3229649,
    71: 3076389,
    72: 2988058,
    73: 2917030,
    74: 3060676,
    75: 2157557,
    76: 2060045,
    77: 1992972,
    78: 1997941,
    79: 1696254,
    80: 1507612,
    81: 1366271,
    82: 1254351,
    83: 1156487,
    84: 1016585,
    85: 922486,
    86: 808993,
    87: 705041,
    88: 608268,
    89: 534694,
    90: 465612,
    91: 411840,
    92: 345678,
    93: 282638,
    94: 231507,
    95: 181805,
    96: 141314,
    97: 106920,
    98: 78467,
    99: 52579,
    100: 97914
  }

  /*
  "2020": {
    /*"0": 6581.08762,
    "1": 1664.282844,
    "2": 1125.0833,
    "3": 909.794032,
    "4": 711.158919,
    "5": 652.748096,
    "6": 618.145857,
    "7": 584.314185,
    "8": 532.59162,
    "9": 517.990836,
    "10": 521.494144,
    "11": 549.85419,
    "12": 613.342642,
    "13": 692.067044,
    "14": 803.780736,
    "15": 931.23016,
    "16": 1059.532122,
    "17": 1149.510468,
    "18": 1218.838101,
    "19": 1298.80504,*
    "20": 1359.851036,
    "21": 1469.12045,
    "22": 1618.46854,
    "23": 1816.70762,
    "24": 2031.197538,
    "25": 2319.72312,
    "26": 2646.751891,
    "27": 2916.02934,
    "28": 3216.427677,
    "29": 3481.27704,
    "30": 3678.484803,
    "31": 3697.24466,
    "32": 3720.142464,
    "33": 3692.438267,
    "34": 3687.31401,
    "35": 3676.132781,
    "36": 3537.556659,
    "37": 3604.539384,
    "38": 3647.185432,
    "39": 3672.244329,
    "40": 3849.103203,
    "41": 3727.03635,
    "42": 3793.501215,
    "43": 3888.4538,
    "44": 3919.770996,
    "45": 4215.24018,
    "46": 4331.817608,
    "47": 4749.230675,
    "48": 5438.43756,
    "49": 6334.369965,
    "50": 7138.46952,
    "51": 7639.237316,
    "52": 8449.484967,
    "53": 9539.968914,
    "54": 10924.82653,
    "55": 12970.45939,
    "56": 14725.75659,
    "57": 16333.35119,
    "58": 18005.95732,
    "59": 20052.18051,
    "60": 22170.89375,
    "61": 23376.6527,
    "62": 25306.55674,
    "63": 27201.40301,
    "64": 28499.60495,
    "65": 30727.25928,
    "66": 30648.73758,
    "67": 30845.40152,
    "68": 31219.09237,
    "69": 32099.43107,
    "70": 33487.57624,
    "71": 34922.80606,
    "72": 37367.68761,
    "73": 42390.20123,
    "74": 34148.02177,
    "75": 37000.14855,
    "76": 39598.24665,
    "77": 44691.82406,
    "78": 43035.41675,
    "79": 43536.33349,
    "80": 45579.66645,
    "81": 47449.48214,
    "82": 50254.0141,
    "83": 51043.36612,
    "84": 53468.25649,
    "85": 55909.69806,
    "86": 54269.67454,
    "87": 56457.02095,
    "88": 57178.68691,
    "89": 57200.37628,
    "90": 56997.88015,
    "91": 52616.19201,
    "92": 49909.08366,
    "93": 45724.50781,
    "94": 40366.28236,
    "95": 36208.23397,
    "96": 30179.18805,
    "97": 24506.6873,
    "98": 19208.87965,
    "99": 15069.3732,
    "100": 27407.65284
  }*/

  /*"2020": {
    "20": 3646.114368,
    "21": 3415.620416,
    "22": 3344.851328,
    "23": 3306.984512,
    "24": 3206.56128,
    "25": 6264.327225,
    "26": 6060.058311,
    "27": 6141.318388,
    "28": 6407.642002,
    "29": 6762.315403,
    "30": 6836.714725,
    "31": 6468.487188,
    "32": 6322.857597,
    "33": 6318.813778,
    "34": 6424.715729,
    "35": 10600.39459,
    "36": 10770.17903,
    "37": 10736.82139,
    "38": 10690.95187,
    "39": 10797.27334,
    "40": 10866.91101,
    "41": 10470.05631,
    "42": 10377.75346,
    "43": 10233.65999,
    "44": 9838.368656,
    "45": 18480.80388,
    "46": 17657.56477,
    "47": 16925.32678,
    "48": 16206.63629,
    "49": 15650.13205,
    "50": 15214.83479,
    "51": 14673.50131,
    "52": 14422.66227,
    "53": 14927.72254,
    "54": 10920.47757,
    "55": 23482.34174,
    "56": 22609.37151,
    "57": 22857.30172,
    "58": 19657.31939,
    "59": 17698.60285,
    "60": 16455.06407,
    "61": 15173.23423,
    "62": 14210.17769,
    "63": 12767.19219,
    "64": 11839.98915,
    "65": 22089.75682,
    "66": 19083.9973,
    "67": 17722.29245,
    "68": 16114.35902,
    "69": 14545.27766,
    "70": 13157.19562,
    "71": 11098.01554,
    "72": 9664.854336,
    "73": 8152.458096,
    "74": 6636.40008,
    "75": 13161.52315,
    "76": 10313.29697,
    "77": 7841.972326,
    "78": 5825.290138,
    "79": 4290.4423,
    "80": 2844.889448,
    "81": 4996.236244,
    "82": 64932.29582,
    "83": 57932.1267,
    "84": 53303.42921,
    "85": 147724.2914,
    "86": 126396.2247,
    "87": 116252.7231,
    "88": 104472.2142,
    "89": 93133.37314,
    "90": 83112.93216,
    "91": 69057.60162,
    "92": 59160.82161,
    "93": 49037.12972,
    "94": 39122.79106,
    "95": 31610.53684,
    "96": 24192.67917,
    "97": 17970.46106,
    "98": 12931.72651,
    "99": 9349.6102,
    "100": 15730.60661
  } */
};
export const yearlypop = {
  "1990": {
    /*"0": 3986488,
    "1": 3800632,
    "2": 3711376,
    "3": 3658876,
    "4": 3699075,
    "5": 3684102,
    "6": 3561588,
    "7": 3643051,
    "8": 3509403,
    "9": 3678602,
    "10": 3709456,
    "11": 3480601,
    "12": 3379084,
    "13": 3384708,
    "14": 3259038,
    "15": 3346796,
    "16": 3286594,
    "17": 3439042,
    "18": 3599611,
    "19": 4099550,*/
    "20": 4054116,
    "21": 3836827,
    "22": 3671346,
    "23": 3708282,
    "24": 3879815,
    "25": 4072660,
    "26": 4193967,
    "27": 4289896,
    "28": 4146848,
    "29": 4573676,
    "30": 4515448,
    "31": 4348350,
    "32": 4379099,
    "33": 4356081,
    "34": 4339795,
    "35": 4278653,
    "36": 4072625,
    "37": 3999937,
    "38": 3703701,
    "39": 3935379,
    "40": 3801229,
    "41": 3633450,
    "42": 3619863,
    "43": 3845526,
    "44": 2892874,
    "45": 2907092,
    "46": 2822206,
    "47": 3001489,
    "48": 2499420,
    "49": 2598809,
    "50": 2436832,
    "51": 2312474,
    "52": 2265069,
    "53": 2169906,
    "54": 2193481,
    "55": 2169429,
    "56": 2059482,
    "57": 2088237,
    "58": 1975268,
    "59": 2185743,
    "60": 2155202,
    "61": 2092965,
    "62": 2141981,
    "63": 2110263,
    "64": 2123071,
    "65": 2120737,
    "66": 2056074,
    "67": 2000161,
    "68": 1961341,
    "69": 1940610,
    "70": 1785637,
    "71": 1684943,
    "72": 1606680,
    "73": 1498595,
    "74": 1449208,
    "75": 1404203,
    "76": 1299109,
    "77": 1230704,
    "78": 1148210,
    "79": 1064770,
    "80": 953035,
    "81": 863829,
    "82": 788454,
    "83": 705351,
    "84": 626028
    //"85": 3059600
  },
  "1991": {
    /*"0": 4003780,
    "1": 3956187,
    "2": 3805832,
    "3": 3721868,
    "4": 3720379,
    "5": 3710209,
    "6": 3684704,
    "7": 3582318,
    "8": 3536785,
    "9": 3767114,
    "10": 3715571,
    "11": 3676577,
    "12": 3503980,
    "13": 3431857,
    "14": 3427970,
    "15": 3317193,
    "16": 3380995,
    "17": 3369699,
    "18": 3400389,
    "19": 3802004,*/
    "20": 4072063,
    "21": 3964964,
    "22": 3743834,
    "23": 3653983,
    "24": 3799581,
    "25": 3879903,
    "26": 4077195,
    "27": 4260580,
    "28": 4127345,
    "29": 4578114,
    "30": 4529278,
    "31": 4445245,
    "32": 4401664,
    "33": 4395231,
    "34": 4529447,
    "35": 4340039,
    "36": 4205271,
    "37": 4126598,
    "38": 3806340,
    "39": 4099100,
    "40": 3840792,
    "41": 3710894,
    "42": 3632474,
    "43": 3589949,
    "44": 3978009,
    "45": 2844550,
    "46": 2828029,
    "47": 2855102,
    "48": 2872716,
    "49": 2728695,
    "50": 2542744,
    "51": 2348686,
    "52": 2308790,
    "53": 2284342,
    "54": 2206498,
    "55": 2143337,
    "56": 2192820,
    "57": 2065187,
    "58": 1958273,
    "59": 2083172,
    "60": 2136556,
    "61": 2102042,
    "62": 2082428,
    "63": 2147688,
    "64": 2134358,
    "65": 2073172,
    "66": 2067018,
    "67": 2027159,
    "68": 1912345,
    "69": 1958672,
    "70": 1892156,
    "71": 1728116,
    "72": 1645240,
    "73": 1524970,
    "74": 1465056,
    "75": 1413664,
    "76": 1338326,
    "77": 1245951,
    "78": 1198775,
    "79": 1085352,
    "80": 978711,
    "81": 884929,
    "82": 802882,
    "83": 736789,
    "84": 643202
    //"85": 3189139
  },
  "1992": {
    /*"0": 3972775,
    "1": 3977686,
    "2": 3963688,
    "3": 3824569,
    "4": 3789323,
    "5": 3735861,
    "6": 3713080,
    "7": 3711736,
    "8": 3494131,
    "9": 3776296,
    "10": 3812500,
    "11": 3690646,
    "12": 3706910,
    "13": 3557411,
    "14": 3478296,
    "15": 3491225,
    "16": 3352809,
    "17": 3460235,
    "18": 3347464,
    "19": 3594507,*/
    "20": 3803224,
    "21": 4003995,
    "22": 3888090,
    "23": 3742455,
    "24": 3750238,
    "25": 3821532,
    "26": 3887651,
    "27": 4153627,
    "28": 4121313,
    "29": 4518494,
    "30": 4560699,
    "31": 4452937,
    "32": 4504133,
    "33": 4415197,
    "34": 4561387,
    "35": 4542352,
    "36": 4265487,
    "37": 4254660,
    "38": 3948302,
    "39": 4173598,
    "40": 4023999,
    "41": 3742574,
    "42": 3719172,
    "43": 3605570,
    "44": 3700620,
    "45": 3927797,
    "46": 2768334,
    "47": 2861431,
    "48": 2743321,
    "49": 3111815,
    "50": 2684545,
    "51": 2451865,
    "52": 2348662,
    "53": 2324729,
    "54": 2325219,
    "55": 2160410,
    "56": 2157802,
    "57": 2197520,
    "58": 1950852,
    "59": 2054316,
    "60": 2045600,
    "61": 2080951,
    "62": 2094208,
    "63": 2087253,
    "64": 2169146,
    "65": 2086548,
    "66": 2023843,
    "67": 2041446,
    "68": 1938566,
    "69": 1905169,
    "70": 1913049,
    "71": 1828861,
    "72": 1686834,
    "73": 1570301,
    "74": 1491402,
    "75": 1428664,
    "76": 1347350,
    "77": 1281680,
    "78": 1209805,
    "79": 1133146,
    "80": 1005000,
    "81": 905785,
    "82": 823425,
    "83": 748469,
    "84": 671991
    //"85": 3314660
  },
  "1993": {
    /*"0": 3894191,
    "1": 3955186,
    "2": 3989174,
    "3": 3991628,
    "4": 3899096,
    "5": 3809291,
    "6": 3741372,
    "7": 3746691,
    "8": 3636422,
    "9": 3712180,
    "10": 3830889,
    "11": 3794055,
    "12": 3727205,
    "13": 3761538,
    "14": 3607716,
    "15": 3544262,
    "16": 3527533,
    "17": 3426509,
    "18": 3447455,
    "19": 3528094,*/
    "20": 3603965,
    "21": 3741649,
    "22": 3925253,
    "23": 3885527,
    "24": 3833530,
    "25": 3783732,
    "26": 3820798,
    "27": 3963571,
    "28": 4034007,
    "29": 4466785,
    "30": 4522388,
    "31": 4471388,
    "32": 4511973,
    "33": 4509492,
    "34": 4568262,
    "35": 4585545,
    "36": 4459890,
    "37": 4306139,
    "38": 4089276,
    "39": 4285745,
    "40": 4119960,
    "41": 3911748,
    "42": 3757697,
    "43": 3693264,
    "44": 3702286,
    "45": 3675112,
    "46": 3823358,
    "47": 2802245,
    "48": 2760194,
    "49": 2952415,
    "50": 3077789,
    "51": 2589331,
    "52": 2455486,
    "53": 2360245,
    "54": 2367774,
    "55": 2281510,
    "56": 2167642,
    "57": 2162229,
    "58": 2090636,
    "59": 2034236,
    "60": 2025891,
    "61": 1988235,
    "62": 2076248,
    "63": 2097856,
    "64": 2103988,
    "65": 2122724,
    "66": 2039966,
    "67": 2001902,
    "68": 1952473,
    "69": 1926714,
    "70": 1864221,
    "71": 1845446,
    "72": 1785331,
    "73": 1617580,
    "74": 1536262,
    "75": 1452509,
    "76": 1361808,
    "77": 1288840,
    "78": 1240505,
    "79": 1143537,
    "80": 1055732,
    "81": 927460,
    "82": 844765,
    "83": 765197,
    "84": 683273
    //"85": 3445566
  },
  "1994": {
    /*"0": 3837113,
    "1": 3882988,
    "2": 3966826,
    "3": 4021040,
    "4": 4068895,
    "5": 3916329,
    "6": 3811882,
    "7": 3776454,
    "8": 3682214,
    "9": 3837800,
    "10": 3769302,
    "11": 3814271,
    "12": 3831807,
    "13": 3775834,
    "14": 3810072,
    "15": 3671558,
    "16": 3575264,
    "17": 3591286,
    "18": 3420476,
    "19": 3617225,*/
    "20": 3538504,
    "21": 3547069,
    "22": 3674415,
    "23": 3924167,
    "24": 3971909,
    "25": 3877530,
    "26": 3774994,
    "27": 3896371,
    "28": 3863783,
    "29": 4326887,
    "30": 4488415,
    "31": 4418642,
    "32": 4527461,
    "33": 4506418,
    "34": 4649053,
    "35": 4600981,
    "36": 4495789,
    "37": 4489009,
    "38": 4156409,
    "39": 4392419,
    "40": 4251275,
    "41": 3994145,
    "42": 3932797,
    "43": 3730693,
    "44": 3775349,
    "45": 3693541,
    "46": 3574810,
    "47": 3862399,
    "48": 2712014,
    "49": 2947806,
    "50": 2934447,
    "51": 2967730,
    "52": 2594697,
    "53": 2461316,
    "54": 2404001,
    "55": 2325621,
    "56": 2278864,
    "57": 2169418,
    "58": 2069581,
    "59": 2165482,
    "60": 2014172,
    "61": 1963894,
    "62": 1985014,
    "63": 2077702,
    "64": 2109389,
    "65": 2060387,
    "66": 2077858,
    "67": 2020305,
    "68": 1913812,
    "69": 1935364,
    "70": 1888121,
    "71": 1793292,
    "72": 1800022,
    "73": 1719000,
    "74": 1581630,
    "75": 1493140,
    "76": 1383961,
    "77": 1300381,
    "78": 1242310,
    "79": 1171770,
    "80": 1071912,
    "81": "970610",
    "82": 865753,
    "83": 781779,
    "84": 698364
    //"85": 3561032
  },
  "1995": {
    /*"0": 3791386,
    "1": 3834008,
    "2": 3895345,
    "3": 4004986,
    "4": 4100780,
    "5": 4084484,
    "6": 3917803,
    "7": 3850123,
    "8": 3723757,
    "9": 3862004,
    "10": 3900641,
    "11": 3755164,
    "12": 3853796,
    "13": 3875499,
    "14": 3821949,
    "15": 3872736,
    "16": 3698273,
    "17": 3630405,
    "18": 3592599,
    "19": 3579985,*/
    "20": 3630445,
    "21": 3486375,
    "22": 3492857,
    "23": 3682513,
    "24": 4008024,
    "25": 4032852,
    "26": 3865666,
    "27": 3855835,
    "28": 3817360,
    "29": 4108223,
    "30": 4372531,
    "31": 4375109,
    "32": 4476018,
    "33": 4515500,
    "34": 4633029,
    "35": 4695437,
    "36": 4507344,
    "37": 4515393,
    "38": 4353269,
    "39": 4420177,
    "40": 4381995,
    "41": 4111159,
    "42": 4022674,
    "43": 3904445,
    "44": 3798875,
    "45": 3785616,
    "46": 3592314,
    "47": 3613678,
    "48": 3755164,
    "49": 2876969,
    "50": 2946456,
    "51": 2828827,
    "52": 2977455,
    "53": 2595882,
    "54": 2507878,
    "55": 2365518,
    "56": 2314396,
    "57": 2279463,
    "58": 2091303,
    "59": 2131618,
    "60": 2153524,
    "61": 1949659,
    "62": 1963465,
    "63": 1985401,
    "64": 2085675,
    "65": 2068210,
    "66": 2019570,
    "67": 2062202,
    "68": 1933312,
    "69": 1893191,
    "70": 1900607,
    "71": 1813884,
    "72": 1750051,
    "73": 1742939,
    "74": 1682122,
    "75": 1536466,
    "76": 1424088,
    "77": 1321478,
    "78": 1250734,
    "79": 1175116,
    "80": 1106839,
    "81": 983987,
    "82": 909049,
    "83": 799663,
    "84": 715074
    //"85": 3680722
  },
  "1996": {
    /*"0": 3744999,
    "1": 3794741,
    "2": 3845840,
    "3": 3937003,
    "4": 4085689,
    "5": 4115730,
    "6": 4086392,
    "7": 3961488,
    "8": 3812082,
    "9": 3884838,
    "10": 3931789,
    "11": 3889245,
    "12": 3797740,
    "13": 3893842,
    "14": 3922493,
    "15": 3884361,
    "16": 3898198,
    "17": 3747042,
    "18": 3643452,
    "19": 3747107,
    "20": 3597090,*/
    "21": 3576997,
    "22": 3437702,
    "23": 3505978,
    "24": 3758878,
    "25": 4084507,
    "26": 4013523,
    "27": 3952011,
    "28": 3794394,
    "29": 4019705,
    "30": 4175189,
    "31": 4251100,
    "32": 4432969,
    "33": 4456520,
    "34": 4629387,
    "35": 4692240,
    "36": 4594923,
    "37": 4517088,
    "38": 4399058,
    "39": 4582442,
    "40": 4435161,
    "41": 4227082,
    "42": 4147836,
    "43": 3994702,
    "44": 3960731,
    "45": 3828110,
    "46": 3679581,
    "47": 3629275,
    "48": 3524316,
    "49": 3949244,
    "50": 2891366,
    "51": 2839579,
    "52": 2841209,
    "53": 2971683,
    "54": 2645602,
    "55": 2470980,
    "56": 2344046,
    "57": 2312452,
    "58": 2211949,
    "59": 2141273,
    "60": 2129047,
    "61": 2079936,
    "62": 1950949,
    "63": 1961449,
    "64": 1987905,
    "65": 2046644,
    "66": 2029293,
    "67": 2007390,
    "68": 1972804,
    "69": 1907956,
    "70": 1863251,
    "71": 1821327,
    "72": 1768882,
    "73": 1701901,
    "74": 1705040,
    "75": 1631594,
    "76": 1465185,
    "77": 1357586,
    "78": 1266429,
    "79": 1182894,
    "80": 1116525,
    "81": 1012769,
    "82": 922919,
    "83": 836505,
    "84": 731263
    //"85": 3794892
  },
  "1997": {
    /*"0": 3751141,
    "1": 3754617,
    "2": 3810061,
    "3": 3894969,
    "4": 4021883,
    "5": 4105686,
    "6": 4116997,
    "7": 4136708,
    "8": 3938810,
    "9": 3955831,
    "10": 3961198,
    "11": 3925877,
    "12": 3937360,
    "13": 3834967,
    "14": 3941295,
    "15": 3984667,
    "16": 3907945,
    "17": 3940725,
    "18": 3773223,
    "19": 3791131,*/
    "20": 3765281,
    "21": 3554549,
    "22": 3540701,
    "23": 3463110,
    "24": 3586435,
    "25": 3856528,
    "26": 4063569,
    "27": 4110734,
    "28": 3908129,
    "29": 3960080,
    "30": 4112244,
    "31": 4052248,
    "32": 4312131,
    "33": 4409408,
    "34": 4559892,
    "35": 4706298,
    "36": 4590690,
    "37": 4597017,
    "38": 4423735,
    "39": 4585881,
    "40": 4625228,
    "41": 4268525,
    "42": 4273481,
    "43": 4120119,
    "44": 4037441,
    "45": 4010954,
    "46": 3721193,
    "47": 3717966,
    "48": 3554154,
    "49": 3682373,
    "50": 3984667,
    "51": 2787021,
    "52": 2856744,
    "53": 2831212,
    "54": 3031224,
    "55": 2611145,
    "56": 2439854,
    "57": 2341620,
    "58": 2260218,
    "59": 2252250,
    "60": 2148377,
    "61": 2052756,
    "62": 2084414,
    "63": 1948570,
    "64": 1960107,
    "65": 1953482,
    "66": 2010708,
    "67": 2020810,
    "68": 1920710,
    "69": 1944120,
    "70": 1882546,
    "71": 1781503,
    "72": 1776000,
    "73": 1728567,
    "74": 1665428,
    "75": 1652142,
    "76": 1556046,
    "77": 1395475,
    "78": 1296971,
    "79": 1198196,
    "80": 1130912,
    "81": 1018872,
    "82": 951866,
    "83": 846282,
    "84": 765449
    //"85": 3905476
  },
  "1998": {
    /*"0": 3762809,
    "1": 3768436,
    "2": 3770264,
    "3": 3863033,
    "4": 3980381,
    "5": 4042435,
    "6": 4107812,
    "7": 4169579,
    "8": 4127042,
    "9": 4063460,
    "10": 4038441,
    "11": 3958076,
    "12": 3977099,
    "13": 3970327,
    "14": 3881238,
    "15": 4002537,
    "16": 4005760,
    "17": 3942677,
    "18": 3976876,
    "19": 3911882,*/
    "20": 3807500,
    "21": 3719968,
    "22": 3527162,
    "23": 3571955,
    "24": 3540518,
    "25": 3694746,
    "26": 3831757,
    "27": 4162091,
    "28": 4078505,
    "29": 4036545,
    "30": 4075280,
    "31": 3981240,
    "32": 4113184,
    "33": 4282820,
    "34": 4500344,
    "35": 4651269,
    "36": 4601444,
    "37": 4583073,
    "38": 4523808,
    "39": 4566603,
    "40": 4658667,
    "41": 4442033,
    "42": 4324516,
    "43": 4247297,
    "44": 4149644,
    "45": 4110945,
    "46": 3899413,
    "47": 3759596,
    "48": 3655985,
    "49": 3688224,
    "50": 3737099,
    "51": 3842825,
    "52": 2807858,
    "53": 2840167,
    "54": 2889745,
    "55": 2994615,
    "56": 2568414,
    "57": 2435237,
    "58": 2303724,
    "59": 2287457,
    "60": 2269149,
    "61": 2067462,
    "62": 2059970,
    "63": 2081451,
    "64": 1943476,
    "65": 1928407,
    "66": 1920478,
    "67": 2005513,
    "68": 1933965,
    "69": 1888902,
    "70": 1922504,
    "71": 1796192,
    "72": 1737458,
    "73": 1744445,
    "74": 1692383,
    "75": 1613030,
    "76": 1576460,
    "77": 1481217,
    "78": 1329494,
    "79": 1227917,
    "80": 1153176,
    "81": 1029595,
    "82": 959834,
    "83": "870521",
    "84": 775133
    //"85": 4032535
  },
  "1999": {
    /*"0": 3795762,
    "1": 3785088,
    "2": 3782635,
    "3": 3824806,
    "4": 3947253,
    "5": 3999762,
    "6": 4042620,
    "7": 4163303,
    "8": 4170706,
    "9": 4230069,
    "10": 4148653,
    "11": 4036182,
    "12": 4009910,
    "13": 4003607,
    "14": 4015012,
    "15": 3939152,
    "16": 4020305,
    "17": 4031224,
    "18": 3989843,
    "19": 4104078,*/
    "20": 3925495,
    "21": 3763401,
    "22": 3694190,
    "23": 3562469,
    "24": 3645874,
    "25": 3657407,
    "26": 3666228,
    "27": 3935064,
    "28": 4146484,
    "29": 4169874,
    "30": 4181212,
    "31": 3936187,
    "32": 4043957,
    "33": 4079717,
    "34": 4362276,
    "35": 4605618,
    "36": 4543561,
    "37": 4582792,
    "38": 4530009,
    "39": 4620569,
    "40": 4667525,
    "41": 4461898,
    "42": 4506931,
    "43": 4297852,
    "44": 4259922,
    "45": 4245882,
    "46": 3994950,
    "47": 3937147,
    "48": 3710656,
    "49": 3765572,
    "50": 3762700,
    "51": 3601854,
    "52": 3873166,
    "53": 2785325,
    "54": 2900567,
    "55": 2859176,
    "56": 2934509,
    "57": 2561216,
    "58": 2412512,
    "59": 2317408,
    "60": 2313430,
    "61": 2178551,
    "62": 2076327,
    "63": 2054965,
    "64": 2069932,
    "65": 1913454,
    "66": 1896361,
    "67": 1916683,
    "68": 1917811,
    "69": 1897189,
    "70": 1872535,
    "71": 1830815,
    "72": 1751382,
    "73": 1714390,
    "74": 1708289,
    "75": 1637734,
    "76": 1539287,
    "77": 1499362,
    "78": 1407240,
    "79": 1258983,
    "80": 1189382,
    "81": 1047265,
    "82": 972176,
    "83": 875092,
    "84": 798393
    //"85": 4154018
  },
  "2000": {
    /*"0": 3855956,
    "1": 3798691,
    "2": 3800144,
    "3": 3821118,
    "4": 3902384,
    "5": 3967834,
    "6": 4004335,
    "7": 4091467,
    "8": 4164871,
    "9": 4235345,
    "10": 4318540,
    "11": 4151953,
    "12": 4084732,
    "13": 4034618,
    "14": 4047853,
    "15": 4064678,
    "16": 3983291,
    "17": 4048379,
    "18": 4079606,
    "19": 4119001,*/
    "20": 4101286,
    "21": 3893853,
    "22": 3771190,
    "23": 3722452,
    "24": 3627886,
    "25": 3747738,
    "26": 3644037,
    "27": 3747633,
    "28": 3947343,
    "29": 4193512,
    "30": 4297742,
    "31": 4062757,
    "32": 3999268,
    "33": 4019117,
    "34": 4145350,
    "35": 4446557,
    "36": 4528984,
    "37": 4523611,
    "38": 4547235,
    "39": 4604465,
    "40": 4696305,
    "41": 4513298,
    "42": 4522455,
    "43": 4476856,
    "44": 4309077,
    "45": 4335271,
    "46": 4155176,
    "47": 4025331,
    "48": 3898395,
    "49": 3805354,
    "50": 3800059,
    "51": 3663455,
    "52": 3637919,
    "53": 3816136,
    "54": 2861878,
    "55": 2854393,
    "56": 2817330,
    "57": 2913690,
    "58": 2580483,
    "59": 2400041,
    "60": 2337902,
    "61": 2230218,
    "62": 2190359,
    "63": 2069449,
    "64": 2035201,
    "65": 2039337,
    "66": 1876784,
    "67": 1882892,
    "68": 1867764,
    "69": 1857132,
    "70": 1884298,
    "71": 1788317,
    "72": 1781935,
    "73": 1735378,
    "74": 1670100,
    "75": 1649707,
    "76": 1568457,
    "77": 1472736,
    "78": 1417670,
    "79": 1330049,
    "80": 1229253,
    "81": 1066141,
    "82": 1002304,
    "83": 884002,
    "84": 802840
    //"85": 4262472
  },
  "2001": {
    /*0": 4012658,
    "1": 3855407,
    "2": 3800096,
    "3": 3802710,
    "4": 3827346,
    "5": 3910033,
    "6": 3972508,
    "7": 4012049,
    "8": 4099542,
    "9": 4179230,
    "10": 4277249,
    "11": 4344913,
    "12": 4183955,
    "13": 4113191,
    "14": 4059370,
    "15": 4083878,
    "16": 4108304,
    "17": 4028736,
    "18": 4102851,
    "19": 4132515,*/
    "20": 4156725,
    "21": 4133311,
    "22": 3919316,
    "23": 3798554,
    "24": 3749380,
    "25": 3659719,
    "26": 3768152,
    "27": 3668700,
    "28": 3766129,
    "29": 3956647,
    "30": 4218260,
    "31": 4302689,
    "32": 4082902,
    "33": 4014912,
    "34": 4033412,
    "35": 4161140,
    "36": 4457678,
    "37": 4534648,
    "38": 4529329,
    "39": 4553123,
    "40": 4606601,
    "41": 4700471,
    "42": 4514435,
    "43": 4523433,
    "44": 4470894,
    "45": 4301955,
    "46": 4331582,
    "47": 4152347,
    "48": 4018921,
    "49": 3894178,
    "50": 3796146,
    "51": 3796980,
    "52": 3654405,
    "53": 3631014,
    "54": 3808740,
    "55": 2851092,
    "56": 2846838,
    "57": 2805407,
    "58": 2900132,
    "59": 2565506,
    "60": 2380300,
    "61": 2323707,
    "62": 2213270,
    "63": 2172024,
    "64": 2047019,
    "65": 2012270,
    "66": 2013441,
    "67": 1850485,
    "68": 1852792,
    "69": 1833245,
    "70": 1817859,
    "71": 1844676,
    "72": 1743767,
    "73": 1733630,
    "74": 1682014,
    "75": 1610892,
    "76": 1589103,
    "77": 1503097,
    "78": 1405933,
    "79": 1346388,
    "80": 1254832,
    "81": 1154138,
    "82": 993071,
    "83": 927192,
    "84": 808972
    //"85": 4312494
  },

  "2002": {
    /*"0": 3951461,
    "1": 4004674,
    "2": 3856114,
    "3": 3804336,
    "4": 3812607,
    "5": 3837187,
    "6": 3913054,
    "7": 3982360,
    "8": 4020970,
    "9": 4118846,
    "10": 4243816,
    "11": 4295720,
    "12": 4380351,
    "13": 4209025,
    "14": 4132509,
    "15": 4097876,
    "16": 4126961,
    "17": 4148590,
    "18": 4084316,
    "19": 4152627,*/
    "20": 4158896,
    "21": 4182678,
    "22": 4145736,
    "23": 3936927,
    "24": 3819528,
    "25": 3779169,
    "26": 3669915,
    "27": 3791713,
    "28": 3682001,
    "29": 3768542,
    "30": 3994164,
    "31": 4210913,
    "32": 4330977,
    "33": 4095531,
    "34": 4026721,
    "35": 4050190,
    "36": 4168398,
    "37": 4459389,
    "38": 4538848,
    "39": 4534393,
    "40": 4551912,
    "41": 4610846,
    "42": 4699196,
    "43": 4515522,
    "44": 4511955,
    "45": 4461379,
    "46": 4301101,
    "47": 4330110,
    "48": 4143330,
    "49": 4016368,
    "50": 3881009,
    "51": 3797199,
    "52": 3785188,
    "53": 3650409,
    "54": 3626101,
    "55": 3788111,
    "56": 2846712,
    "57": 2833628,
    "58": 2792832,
    "59": 2881784,
    "60": 2540735,
    "61": 2370358,
    "62": 2306156,
    "63": 2195850,
    "64": 2147166,
    "65": 2025379,
    "66": 1986541,
    "67": 1986526,
    "68": 1821142,
    "69": 1818893,
    "70": 1792760,
    "71": 1782107,
    "72": 1797946,
    "73": 1697450,
    "74": 1679791,
    "75": 1620812,
    "76": 1554475,
    "77": 1522890,
    "78": 1436185,
    "79": 1336099,
    "80": 1269310,
    "81": 1180698,
    "82": 1075053,
    "83": 919945,
    "84": 849397
    //"85": 4368808
  },
  "2003": {
    /*"0": 3975871,
    "1": 3936139,
    "2": 4002836,
    "3": 3860727,
    "4": 3816873,
    "5": 3823568,
    "6": 3837761,
    "7": 3924182,
    "8": 3991075,
    "9": 4044265,
    "10": 4205042,
    "11": 4254047,
    "12": 4332987,
    "13": 4401393,
    "14": 4221884,
    "15": 4172654,
    "16": 4139141,
    "17": 4160313,
    "18": 4201835,
    "19": 4123223,*/
    "20": 4156877,
    "21": 4167554,
    "22": 4175294,
    "23": 4147777,
    "24": 3944186,
    "25": 3842468,
    "26": 3771710,
    "27": 3688646,
    "28": 3794838,
    "29": 3673923,
    "30": 3813536,
    "31": 3973726,
    "32": 4245067,
    "33": 4337299,
    "34": 4102582,
    "35": 4042650,
    "36": 4051962,
    "37": 4165007,
    "38": 4462051,
    "39": 4542489,
    "40": 4529389,
    "41": 4556844,
    "42": 4606465,
    "43": 4699388,
    "44": 4497961,
    "45": 4499971,
    "46": 4462427,
    "47": 4300483,
    "48": 4317939,
    "49": 4141856,
    "50": 3998812,
    "51": 3886144,
    "52": 3782009,
    "53": 3783390,
    "54": 3646923,
    "55": 3601691,
    "56": 3785668,
    "57": 2831596,
    "58": 2821608,
    "59": 2773994,
    "60": 2849408,
    "61": 2534959,
    "62": 2352131,
    "63": 2289143,
    "64": 2168747,
    "65": 2125962,
    "66": 1999916,
    "67": 1961151,
    "68": 1955990,
    "69": 1787777,
    "70": 1777336,
    "71": 1760292,
    "72": 1736351,
    "73": 1751589,
    "74": 1644551,
    "75": 1617308,
    "76": 1567629,
    "77": 1489790,
    "78": 1456995,
    "79": 1366120,
    "80": 1259724,
    "81": 1196760,
    "82": 1100969,
    "83": 997727,
    "84": 843416
    //"85": 4466176
  },
  "2004": {
    /*"0": 4014258,
    "1": 3953063,
    "2": 3933735,
    "3": 4008220,
    "4": 3876609,
    "5": 3829607,
    "6": 3822089,
    "7": 3850646,
    "8": 3933161,
    "9": 4018734,
    "10": 4151951,
    "11": 4207721,
    "12": 4293550,
    "13": 4350199,
    "14": 4408259,
    "15": 4264863,
    "16": 4213762,
    "17": 4167308,
    "18": 4214061,
    "19": 4242558,*/
    "20": 4131771,
    "21": 4180191,
    "22": 4173077,
    "23": 4192674,
    "24": 4167847,
    "25": 3981519,
    "26": 3838225,
    "27": 3801676,
    "28": 3697495,
    "29": 3788140,
    "30": 3737940,
    "31": 3790984,
    "32": 4023788,
    "33": 4257394,
    "34": 4349395,
    "35": 4127056,
    "36": 4048580,
    "37": 4051890,
    "38": 4174027,
    "39": 4473096,
    "40": 4540929,
    "41": 4540376,
    "42": 4555019,
    "43": 4610120,
    "44": 4679182,
    "45": 4486566,
    "46": 4506067,
    "47": 4465196,
    "48": 4287486,
    "49": 4319947,
    "50": 4121104,
    "51": 4010062,
    "52": 3868188,
    "53": 3782872,
    "54": 3782442,
    "55": 3618001,
    "56": 3603856,
    "57": 3763702,
    "58": 2819754,
    "59": 2801863,
    "60": 2739059,
    "61": 2848602,
    "62": 2515357,
    "63": 2335553,
    "64": 2259557,
    "65": 2148431,
    "66": 2098650,
    "67": 1975087,
    "68": 1930847,
    "69": 1920414,
    "70": 1744903,
    "71": 1747347,
    "72": 1713793,
    "73": 1691931,
    "74": 1696130,
    "75": 1581121,
    "76": 1566753,
    "77": 1501866,
    "78": 1425682,
    "79": 1385977,
    "80": 1286733,
    "81": 1189534,
    "82": 1115106,
    "83": 1023151,
    "84": 913980
    //"85": 4545883
  },
  "2005": {
    /*"0": 4004393,
    "1": 3987032,
    "2": 3952632,
    "3": 3943215,
    "4": 4030128,
    "5": 3893128,
    "6": 3828153,
    "7": 3838840,
    "8": 3861877,
    "9": 3967069,
    "10": 4150122,
    "11": 4149228,
    "12": 4251162,
    "13": 4308851,
    "14": 4353216,
    "15": 4456969,
    "16": 4308145,
    "17": 4239509,
    "18": 4225126,
    "19": 4256465,*/
    "20": 4241197,
    "21": 4150658,
    "22": 4176096,
    "23": 4183656,
    "24": 4208348,
    "25": 4206096,
    "26": 3966722,
    "27": 3870040,
    "28": 3805526,
    "29": 3686326,
    "30": 3866581,
    "31": 3705594,
    "32": 3848038,
    "33": 4035045,
    "34": 4268679,
    "35": 4377057,
    "36": 4130501,
    "37": 4044933,
    "38": 4060608,
    "39": 4185554,
    "40": 4470383,
    "41": 4553968,
    "42": 4537278,
    "43": 4559728,
    "44": 4585528,
    "45": 4666734,
    "46": 4496805,
    "47": 4511695,
    "48": 4449860,
    "49": 4292077,
    "50": 4295348,
    "51": 4138594,
    "52": 3989065,
    "53": 3871906,
    "54": 3783820,
    "55": 3748247,
    "56": 3625240,
    "57": 3582008,
    "58": 3748661,
    "59": 2799064,
    "60": 2763609,
    "61": 2745139,
    "62": 2827064,
    "63": 2498812,
    "64": 2303653,
    "65": 2240858,
    "66": 2121740,
    "67": 2074635,
    "68": 1945279,
    "69": 1896115,
    "70": 1873726,
    "71": 1718367,
    "72": 1700960,
    "73": 1671512,
    "74": 1638505,
    "75": 1629763,
    "76": 1535359,
    "77": 1501643,
    "78": 1439475,
    "79": 1357369,
    "80": 1305661,
    "81": 1218283,
    "82": 1109756,
    "83": 1038431,
    "84": 939062
    //"85": 4693299
  },
  "2006": {
    /*"0": 4041738,
    "1": 3972124,
    "2": 3988119,
    "3": 3966022,
    "4": 3970880,
    "5": 4050582,
    "6": 3891329,
    "7": 3848758,
    "8": 3852265,
    "9": 3901754,
    "10": 4121105,
    "11": 4141970,
    "12": 4196880,
    "13": 4264733,
    "14": 4308450,
    "15": 4406515,
    "16": 4502997,
    "17": 4331493,
    "18": 4301050,
    "19": 4265654,*/
    "20": 4248095,
    "21": 4258790,
    "22": 4141532,
    "23": 4186427,
    "24": 4201291,
    "25": 4254643,
    "26": 4186325,
    "27": 4004498,
    "28": 3874154,
    "29": 3790391,
    "30": 3779051,
    "31": 3824265,
    "32": 3772538,
    "33": 3860165,
    "34": 4049149,
    "35": 4302604,
    "36": 4380502,
    "37": 4126573,
    "38": 4056686,
    "39": 4074868,
    "40": 4184594,
    "41": 4488189,
    "42": 4552280,
    "43": 4544742,
    "44": 4532763,
    "45": 4573546,
    "46": 4682144,
    "47": 4506142,
    "48": 4495342,
    "49": 4458112,
    "50": 4264904,
    "51": 4320192,
    "52": 4114856,
    "53": 3996095,
    "54": 3874826,
    "55": 3745012,
    "56": 3760899,
    "57": 3601973,
    "58": 3568659,
    "59": 3720317,
    "60": 2757092,
    "61": 2776869,
    "62": 2725651,
    "63": 2810202,
    "64": 2463439,
    "65": 2286008,
    "66": 2213751,
    "67": 2098690,
    "68": 2044245,
    "69": 1910507,
    "70": 1848556,
    "71": 1848947,
    "72": 1672443,
    "73": 1660842,
    "74": 1619038,
    "75": 1573506,
    "76": 1587027,
    "77": 1472328,
    "78": 1441641,
    "79": 1372532,
    "80": 1278890,
    "81": 1239927,
    "82": 1138135,
    "83": 1036784,
    "84": 954381
    //"85": 4865929
  },
  "2007": {
    /*"0": 4147997,
    "1": 4002215,
    "2": 3973479,
    "3": 4004011,
    "4": 3998260,
    "5": 3993489,
    "6": 4046402,
    "7": 3914743,
    "8": 3863089,
    "9": 3896888,
    "10": 4075718,
    "11": 4106492,
    "12": 4192002,
    "13": 4207326,
    "14": 4259504,
    "15": 4364495,
    "16": 4452250,
    "17": 4521045,
    "18": 4394638,
    "19": 4335388,*/
    "20": 4242404,
    "21": 4257371,
    "22": 4236043,
    "23": 4143940,
    "24": 4198241,
    "25": 4245572,
    "26": 4222850,
    "27": 4224395,
    "28": 3999799,
    "29": 3850082,
    "30": 3894527,
    "31": 3724612,
    "32": 3899463,
    "33": 3780927,
    "34": 3871236,
    "35": 4083733,
    "36": 4302578,
    "37": 4370533,
    "38": 4136991,
    "39": 4070056,
    "40": 4071341,
    "41": 4202260,
    "42": 4485432,
    "43": 4560505,
    "44": 4512801,
    "45": 4519792,
    "46": 4591932,
    "47": 4694196,
    "48": 4487653,
    "49": 4506188,
    "50": 4426479,
    "51": 4294933,
    "52": 4292620,
    "53": 4124978,
    "54": 4001168,
    "55": 3829701,
    "56": 3761384,
    "57": 3734883,
    "58": 3588953,
    "59": 3539851,
    "60": 3659454,
    "61": 2775802,
    "62": 2757918,
    "63": 2711325,
    "64": 2769163,
    "65": 2445756,
    "66": 2257671,
    "67": 2191327,
    "68": 2068095,
    "69": 2007386,
    "70": 1860716,
    "71": 1826784,
    "72": 1798955,
    "73": 1633722,
    "74": 1608315,
    "75": 1553097,
    "76": 1535224,
    "77": 1521903,
    "78": 1414814,
    "79": 1375325,
    "80": 1292775,
    "81": 1216593,
    "82": 1158822,
    "83": 1065220,
    "84": 953666
    //"85": 5039545
  },
  "2008": {
    /*"0": 4132735,
    "1": 4100756,
    "2": 4004146,
    "3": 3992320,
    "4": 4041170,
    "5": 4024297,
    "6": 3988117,
    "7": 4073291,
    "8": 3930544,
    "9": 3913353,
    "10": 4093680,
    "11": 4055388,
    "12": 4159477,
    "13": 4200016,
    "14": 4198094,
    "15": 4318982,
    "16": 4411176,
    "17": 4467060,
    "18": 4588123,
    "19": 4425539,*/
    "20": 4302988,
    "21": 4251950,
    "22": 4232792,
    "23": 4237432,
    "24": 4155450,
    "25": 4246210,
    "26": 4206583,
    "27": 4265165,
    "28": 4215620,
    "29": 3969090,
    "30": 3970191,
    "31": 3828094,
    "32": 3807761,
    "33": 3907253,
    "34": 3791506,
    "35": 3908041,
    "36": 4083819,
    "37": 4291673,
    "38": 4382157,
    "39": 4151773,
    "40": 4065710,
    "41": 4090091,
    "42": 4199463,
    "43": 4495218,
    "44": 4524541,
    "45": 4499251,
    "46": 4541484,
    "47": 4606123,
    "48": 4672955,
    "49": 4501160,
    "50": 4471128,
    "51": 4464006,
    "52": 4264599,
    "53": 4306809,
    "54": 4132932,
    "55": 3949524,
    "56": 3850704,
    "57": 3733201,
    "58": 3722427,
    "59": 3558712,
    "60": 3476545,
    "61": 3694987,
    "62": 2757325,
    "63": 2744863,
    "64": 2668775,
    "65": 2751913,
    "66": 2416007,
    "67": 2235669,
    "68": 2160024,
    "69": 2030734,
    "70": 1953328,
    "71": 1841560,
    "72": 1776463,
    "73": 1758650,
    "74": 1581331,
    "75": 1541027,
    "76": 1518327,
    "77": 1472006,
    "78": 1464084,
    "79": 1350299,
    "80": 1294601,
    "81": 1232307,
    "82": 1136851,
    "83": 1086404,
    "84": 980196
    //"85": 5195840
  },
  "2009": {
    /*"0": 4003587,
    "1": 4078797,
    "2": 4103002,
    "3": 4025675,
    "4": 4033457,
    "5": 4070265,
    "6": 4018200,
    "7": 4017024,
    "8": 4089946,
    "9": 3987064,
    "10": 4133090,
    "11": 4066605,
    "12": 4110576,
    "13": 4164413,
    "14": 4185880,
    "15": 4259907,
    "16": 4365338,
    "17": 4421341,
    "18": 4534634,
    "19": 4611590,*/
    "20": 4383628,
    "21": 4307414,
    "22": 4217667,
    "23": 4230115,
    "24": 4245298,
    "25": 4204602,
    "26": 4197922,
    "27": 4250014,
    "28": 4250613,
    "29": 4175077,
    "30": 4104133,
    "31": 3890200,
    "32": 3921281,
    "33": 3813001,
    "34": 3916499,
    "35": 3829432,
    "36": 3905615,
    "37": 4071181,
    "38": 4304006,
    "39": 4397562,
    "40": 4145183,
    "41": 4085265,
    "42": 4085849,
    "43": 4209233,
    "44": 4454485,
    "45": 4509265,
    "46": 4523596,
    "47": 4557336,
    "48": 4582655,
    "49": 4689393,
    "50": 4462192,
    "51": 4514833,
    "52": 4429228,
    "53": 4281465,
    "54": 4317125,
    "55": 4074019,
    "56": 3975863,
    "57": 3819855,
    "58": 3721219,
    "59": 3689647,
    "60": 3489302,
    "61": 3517588,
    "62": 3673134,
    "63": 2745716,
    "64": 2699257,
    "65": 2653345,
    "66": 2719454,
    "67": 2394376,
    "68": 2204229,
    "69": 2120973,
    "70": 1974076,
    "71": 1936308,
    "72": 1790136,
    "73": 1737897,
    "74": 1702305,
    "75": 1513301,
    "76": 1510000,
    "77": 1455744,
    "78": 1417539,
    "79": 1398480,
    "80": 1270444,
    "81": 1236769,
    "82": 1152248,
    "83": 1067638,
    "84": 1000612
    //"85": 5367301
  },
  "2010": {
    "20": 4567951,
    "21": 4387413,
    "22": 4286330,
    "23": 4216524,
    "24": 4243080,
    "25": 4288870,
    "26": 4160319,
    "27": 4236631,
    "28": 4247132,
    "29": 4209925,
    "30": 4303827,
    "31": 4042153,
    "32": 3967147,
    "33": 3933076,
    "34": 3821734,
    "35": 3947854,
    "36": 3829703,
    "37": 3896217,
    "38": 4079655,
    "39": 4323834,
    "40": 4386825,
    "41": 4162937,
    "42": 4082210,
    "43": 4093285,
    "44": 4177952,
    "45": 4438000,
    "46": 4529194,
    "47": 4534994,
    "48": 4534165,
    "49": 4598583,
    "50": 4645689,
    "51": 4498526,
    "52": 4480160,
    "53": 4439014,
    "54": 4288067,
    "55": 4258545,
    "56": 4092793,
    "57": 3946199,
    "58": 3802133,
    "59": 3693990,
    "60": 3616436,
    "61": 3519888,
    "62": 3494867,
    "63": 3651970,
    "64": 2705897,
    "65": 2678332,
    "66": 2621200,
    "67": 2693584,
    "68": 2359713,
    "69": 2167750,
    "70": 2062466,
    "71": 1953486,
    "72": 1883725,
    "73": 1750237,
    "74": 1685936,
    "75": 1631850,
    "76": 1481687,
    "77": 1449156,
    "78": 1402199,
    "79": 1354926,
    "80": 1319723,
    "81": 1212519,
    "82": 1158188,
    "83": 1081226,
    "84": 986814,
    "85": 914842,
    "86": 821428,
    "87": 721149,
    "88": 636650,
    "89": 546248,
    "90": 448417,
    "91": 344555,
    "92": 288965,
    "93": 219192,
    "94": 170904,
    "95": 131195,
    "96": 97259,
    "97": 68966,
    "98": 47086,
    "99": 32214,
    "100": 54437
  },
  "2011": {
    "20": 4598087,
    "21": 4594046,
    "22": 4412989,
    "23": 4312071,
    "24": 4242005,
    "25": 4267715,
    "26": 4311460,
    "27": 4181235,
    "28": 4255887,
    "29": 4264231,
    "30": 4224904,
    "31": 4316834,
    "32": 4053130,
    "33": 3977087,
    "34": 3941571,
    "35": 3829296,
    "36": 3953574,
    "37": 3834160,
    "38": 3899275,
    "39": 4081727,
    "40": 4323492,
    "41": 4384667,
    "42": 4160003,
    "43": 4078457,
    "44": 4088648,
    "45": 4171392,
    "46": 4429559,
    "47": 4518765,
    "48": 4522525,
    "49": 4519850,
    "50": 4582117,
    "51": 4627614,
    "52": 4478988,
    "53": 4458856,
    "54": 4416050,
    "55": 4263777,
    "56": 4233221,
    "57": 4066454,
    "58": 3918867,
    "59": 3773861,
    "60": 3664938,
    "61": 3586380,
    "62": 3487750,
    "63": 3459686,
    "64": 3612778,
    "65": 2674388,
    "66": 2644949,
    "67": 2585082,
    "68": 2652967,
    "69": 2320909,
    "70": 2128978,
    "71": 2022302,
    "72": 1911768,
    "73": 1839617,
    "74": 1705176,
    "75": 1638392,
    "76": 1581264,
    "77": 1430846,
    "78": 1394624,
    "79": 1343872,
    "80": 1292555,
    "81": 1252415,
    "82": 1143567,
    "83": 1084658,
    "84": 1004493,
    "85": 908494,
    "86": 834114,
    "87": 740788,
    "88": 642674,
    "89": 559656,
    "90": 473596,
    "91": 382497,
    "92": 289188,
    "93": 238035,
    "94": 176717,
    "95": 135101,
    "96": 101295,
    "97": 73267,
    "98": 50654,
    "99": 33604,
    "100": 57513
  },
  "2012": {
    "20": 4546283,
    "21": 4629444,
    "22": 4625206,
    "23": 4443353,
    "24": 4341674,
    "25": 4271101,
    "26": 4295141,
    "27": 4336875,
    "28": 4204349,
    "29": 4276982,
    "30": 4282588,
    "31": 4240764,
    "32": 4330633,
    "33": 4064859,
    "34": 3987707,
    "35": 3950829,
    "36": 3836855,
    "37": 3959826,
    "38": 3839208,
    "39": 3902771,
    "40": 4083481,
    "41": 4323211,
    "42": 4382863,
    "43": 4157098,
    "44": 4074786,
    "45": 4083445,
    "46": 4164199,
    "47": 4420397,
    "48": 4507426,
    "49": 4509218,
    "50": 4504724,
    "51": 4564517,
    "52": 4607874,
    "53": 4457963,
    "54": 4436389,
    "55": 4391667,
    "56": 4238325,
    "57": 4206336,
    "58": 4038875,
    "59": 3890472,
    "60": 3744191,
    "61": 3634139,
    "62": 3554183,
    "63": 3453473,
    "64": 3422763,
    "65": 3571867,
    "66": 2641359,
    "67": 2609571,
    "68": 2546536,
    "69": 2610071,
    "70": 2280469,
    "71": 2089040,
    "72": 1980767,
    "73": 1868543,
    "74": 1793992,
    "75": 1658787,
    "76": 1589317,
    "77": 1529111,
    "78": 1378596,
    "79": 1338588,
    "80": 1283870,
    "81": 1228453,
    "82": 1183563,
    "83": 1073199,
    "84": 1010027,
    "85": 927337,
    "86": 830572,
    "87": 754700,
    "88": 662480,
    "89": 567505,
    "90": 486948,
    "91": 406038,
    "92": 322223,
    "93": 239592,
    "94": 193338,
    "95": 140263,
    "96": 105060,
    "97": 76840,
    "98": 54192,
    "99": 36514,
    "100": 61035
  },
  "2013": {
    "20": 4452516,
    "21": 4573290,
    "22": 4659674,
    "23": 4655026,
    "24": 4471728,
    "25": 4368721,
    "26": 4296990,
    "27": 4319792,
    "28": 4358696,
    "29": 4224848,
    "30": 4295820,
    "31": 4298346,
    "32": 4254484,
    "33": 4342195,
    "34": 4074558,
    "35": 3996281,
    "36": 3957490,
    "37": 3842637,
    "38": 3964275,
    "39": 3842610,
    "40": 3904444,
    "41": 4083858,
    "42": 4321505,
    "43": 4379162,
    "44": 4152699,
    "45": 4068840,
    "46": 4076067,
    "47": 4155470,
    "48": 4409255,
    "49": 4494101,
    "50": 4493641,
    "51": 4486720,
    "52": 4544358,
    "53": 4586306,
    "54": 4435170,
    "55": 4411473,
    "56": 4364534,
    "57": 4210457,
    "58": 4176885,
    "59": 4008522,
    "60": 3859173,
    "61": 3711821,
    "62": 3600431,
    "63": 3518772,
    "64": 3415735,
    "65": 3382530,
    "66": 3527156,
    "67": 2605415,
    "68": 2571021,
    "69": 2504738,
    "70": 2563695,
    "71": 2236694,
    "72": 2045286,
    "73": 1935198,
    "74": 1821131,
    "75": 1744077,
    "76": 1607991,
    "77": 1535682,
    "78": 1472207,
    "79": 1321640,
    "80": 1277561,
    "81": 1218793,
    "82": 1159110,
    "83": 1109098,
    "84": 997485,
    "85": 930550,
    "86": 846127,
    "87": 749538,
    "88": 673270,
    "89": 583346,
    "90": 492709,
    "91": 415817,
    "92": 341169,
    "93": 265470,
    "94": 193974,
    "95": 153120,
    "96": 108285,
    "97": 79369,
    "98": 56508,
    "99": 38797,
    "100": 64898
  },
  "2014": {
    "20": 4410910,
    "21": 4480266,
    "22": 4604115,
    "23": 4692295,
    "24": 4686353,
    "25": 4501246,
    "26": 4397165,
    "27": 4324796,
    "28": 4345247,
    "29": 4382190,
    "30": 4246947,
    "31": 4314451,
    "32": 4314207,
    "33": 4268221,
    "34": 4353984,
    "35": 4084165,
    "36": 4004418,
    "37": 3964439,
    "38": 3848856,
    "39": 3969337,
    "40": 3845825,
    "41": 3905797,
    "42": 4083824,
    "43": 4319265,
    "44": 4375450,
    "45": 4147532,
    "46": 4062255,
    "47": 4068585,
    "48": 4145797,
    "49": 4397069,
    "50": 4479191,
    "51": 4476281,
    "52": 4467724,
    "53": 4523245,
    "54": 4563253,
    "55": 4410443,
    "56": 4384452,
    "57": 4335908,
    "58": 4180851,
    "59": 4145881,
    "60": 3976405,
    "61": 3825701,
    "62": 3677408,
    "63": 3564597,
    "64": 3481789,
    "65": 3376670,
    "66": 3340649,
    "67": 3480104,
    "68": 2567511,
    "69": 2530460,
    "70": 2461426,
    "71": 2516392,
    "72": 2191642,
    "73": 2000102,
    "74": 1888141,
    "75": 1772432,
    "76": 1692960,
    "77": 1555971,
    "78": 1480735,
    "79": 1414010,
    "80": 1263540,
    "81": 1215634,
    "82": 1152926,
    "83": 1089054,
    "84": 1034418,
    "85": 922398,
    "86": 852675,
    "87": 767609,
    "88": 672433,
    "89": 596951,
    "90": 510281,
    "91": 424834,
    "92": 352528,
    "93": 284642,
    "94": 217013,
    "95": 155992,
    "96": 120427,
    "97": 82948,
    "98": 59546,
    "99": 41277,
    "100": 70685
  },
  "2015": {
    "20": 4349271,
    "21": 4440639,
    "22": 4513472,
    "23": 4638825,
    "24": 4726443,
    "25": 4719543,
    "26": 4533612,
    "27": 4428666,
    "28": 4354559,
    "29": 4372818,
    "30": 4407648,
    "31": 4270096,
    "32": 4334801,
    "33": 4332152,
    "34": 4284303,
    "35": 4367425,
    "36": 4095075,
    "37": 4013981,
    "38": 3972535,
    "39": 3855684,
    "40": 3974173,
    "41": 3849567,
    "42": 3907838,
    "43": 4083850,
    "44": 4317474,
    "45": 4371112,
    "46": 4141749,
    "47": 4055387,
    "48": 4059893,
    "49": 4135111,
    "50": 4383161,
    "51": 4462685,
    "52": 4458166,
    "53": 4447639,
    "54": 4500628,
    "55": 4538441,
    "56": 4383779,
    "57": 4355834,
    "58": 4305438,
    "59": 4149449,
    "60": 4112746,
    "61": 3941754,
    "62": 3790011,
    "63": 3640427,
    "64": 3526289,
    "65": 3441926,
    "66": 3334811,
    "67": 3295666,
    "68": 3429944,
    "69": 2526801,
    "70": 2486399,
    "71": 2414320,
    "72": 2463978,
    "73": 2141464,
    "74": 1949879,
    "75": 1836120,
    "76": 1718677,
    "77": 1636506,
    "78": 1498661,
    "79": 1420466,
    "80": 1350324,
    "81": 1200209,
    "82": 1148098,
    "83": 1081183,
    "84": 1013458,
    "85": 954664,
    "86": 843182,
    "87": 771610,
    "88": 687017,
    "89": 594305,
    "90": 520698,
    "91": 438523,
    "92": 359346,
    "93": 292623,
    "94": 232203,
    "95": 173006,
    "96": 122315,
    "97": 92078,
    "98": 61585,
    "99": 43276,
    "100": 75449
  },
  "2016": {
    "20": 4278076,
    "21": 4376754,
    "22": 4472022,
    "23": 4546725,
    "24": 4671030,
    "25": 4759050,
    "26": 4750806,
    "27": 4564270,
    "28": 4457571,
    "29": 4381396,
    "30": 4397578,
    "31": 4429613,
    "32": 4290114,
    "33": 4353183,
    "34": 4348424,
    "35": 4297636,
    "36": 4377814,
    "37": 4103497,
    "38": 4021576,
    "39": 3978876,
    "40": 3860351,
    "41": 3977416,
    "42": 3851777,
    "43": 3907854,
    "44": 4082421,
    "45": 4313188,
    "46": 4365052,
    "47": 4134775,
    "48": 4046714,
    "49": 4049463,
    "50": 4122087,
    "51": 4367488,
    "52": 4444936,
    "53": 4438043,
    "54": 4425283,
    "55": 4475490,
    "56": 4511556,
    "57": 4355282,
    "58": 4325310,
    "59": 4272724,
    "60": 4115514,
    "61": 4076904,
    "62": 3904795,
    "63": 3751734,
    "64": 3600490,
    "65": 3485374,
    "66": 3399710,
    "67": 3290367,
    "68": 3248029,
    "69": 3376173,
    "70": 2483369,
    "71": 2440774,
    "72": 2365831,
    "73": 2409821,
    "74": 2089859,
    "75": 1898266,
    "76": 1782609,
    "77": 1663500,
    "78": 1578695,
    "79": 1440053,
    "80": 1358823,
    "81": 1285298,
    "82": 1135564,
    "83": 1079467,
    "84": 1008948,
    "85": 937939,
    "86": 875790,
    "87": 765842,
    "88": 693336,
    "89": 610099,
    "90": 520782,
    "91": 450056,
    "92": 373146,
    "93": 300773,
    "94": 240128,
    "95": 187214,
    "96": 136011,
    "97": 94732,
    "98": 69464,
    "99": 45030,
    "100": 81199
  },
  "2017": {
    "20": 4262267,
    "21": 4301347,
    "22": 4403799,
    "23": 4500396,
    "24": 4574512,
    "25": 4698330,
    "26": 4785819,
    "27": 4776789,
    "28": 4588278,
    "29": 4479720,
    "30": 4401684,
    "31": 4415784,
    "32": 4445710,
    "33": 4304958,
    "34": 4366340,
    "35": 4358474,
    "36": 4304733,
    "37": 4383439,
    "38": 4108234,
    "39": 4025425,
    "40": 3980852,
    "41": 3861830,
    "42": 3977681,
    "43": 3850531,
    "44": 3905385,
    "45": 4077414,
    "46": 4305935,
    "47": 4356263,
    "48": 4124824,
    "49": 4035771,
    "50": 4036095,
    "51": 4106675,
    "52": 4349788,
    "53": 4424299,
    "54": 4415276,
    "55": 4399955,
    "56": 4448203,
    "57": 4482211,
    "58": 4324223,
    "59": 4291724,
    "60": 4236920,
    "61": 4078580,
    "62": 4038225,
    "63": 3864653,
    "64": 3710075,
    "65": 3557852,
    "66": 3441715,
    "67": 3354577,
    "68": 3242657,
    "69": 3196430,
    "70": 3318392,
    "71": 2436899,
    "72": 2391538,
    "73": 2313358,
    "74": 2351361,
    "75": 2033951,
    "76": 1842454,
    "77": 1725054,
    "78": 1604180,
    "79": 1516532,
    "80": 1377005,
    "81": 1292742,
    "82": 1215783,
    "83": 1066792,
    "84": 1006959,
    "85": 933197,
    "86": 859615,
    "87": 795017,
    "88": 687486,
    "89": 614987,
    "90": 534178,
    "91": 449297,
    "92": 382476,
    "93": 311689,
    "94": 246766,
    "95": 192745,
    "96": 147449,
    "97": 104068,
    "98": 71571,
    "99": 50969,
    "100": 85663
  },
  "2018": {
    "20": 4262078,
    "21": 4279527,
    "22": 4321222,
    "23": 4424783,
    "24": 4520476,
    "25": 4593783,
    "26": 4717156,
    "27": 4803933,
    "28": 4793010,
    "29": 4603079,
    "30": 4492860,
    "31": 4413112,
    "32": 4425955,
    "33": 4454613,
    "34": 4312609,
    "35": 4371450,
    "36": 4361404,
    "37": 4306366,
    "38": 4384202,
    "39": 4108600,
    "40": 4024106,
    "41": 3978956,
    "42": 3859261,
    "43": 3973483,
    "44": 3845646,
    "45": 3898596,
    "46": 4068551,
    "47": 4295362,
    "48": 4344044,
    "49": 4111972,
    "50": 4021129,
    "51": 4019585,
    "52": 4088748,
    "53": 4328970,
    "54": 4400749,
    "55": 4389077,
    "56": 4371950,
    "57": 4417813,
    "58": 4449262,
    "59": 4289580,
    "60": 4254388,
    "61": 4197441,
    "62": 4038371,
    "63": 3995629,
    "64": 3820738,
    "65": 3664838,
    "66": 3511843,
    "67": 3394794,
    "68": 3305114,
    "69": 3190630,
    "70": 3141469,
    "71": 3256743,
    "72": 2387218,
    "73": 2338843,
    "74": 2257691,
    "75": 2288885,
    "76": 1974261,
    "77": 1783292,
    "78": 1664122,
    "79": 1541515,
    "80": 1450820,
    "81": 1310691,
    "82": 1223611,
    "83": 1143041,
    "84": 995618,
    "85": 932507,
    "86": 856172,
    "87": 781145,
    "88": 714808,
    "89": 610666,
    "90": 539372,
    "91": 461887,
    "92": 382414,
    "93": 320359,
    "94": 256353,
    "95": 198913,
    "96": 151823,
    "97": 113716,
    "98": 77943,
    "99": 53184,
    "100": 93038
  },
  "2019": {
    "20": 4269683,
    "21": 4278323,
    "22": 4298772,
    "23": 4341644,
    "24": 4444518,
    "25": 4539058,
    "26": 4611220,
    "27": 4733869,
    "28": 4818725,
    "29": 4806144,
    "30": 4614384,
    "31": 4502311,
    "32": 4421505,
    "33": 4432973,
    "34": 4460132,
    "35": 4315866,
    "36": 4372444,
    "37": 4361286,
    "38": 4305576,
    "39": 4382349,
    "40": 4105313,
    "41": 4020254,
    "42": 3974741,
    "43": 3854040,
    "44": 3967275,
    "45": 3837909,
    "46": 3889372,
    "47": 4058038,
    "48": 4282657,
    "49": 4329775,
    "50": 4096572,
    "51": 4004343,
    "52": 4001782,
    "53": 4068851,
    "54": 4305603,
    "55": 4374565,
    "56": 4361016,
    "57": 4342385,
    "58": 4385570,
    "59": 4413855,
    "60": 4252663,
    "61": 4215172,
    "62": 4156645,
    "63": 3996088,
    "64": 3950578,
    "65": 3774597,
    "66": 3618069,
    "67": 3464437,
    "68": 3345475,
    "69": 3252423,
    "70": 3136704,
    "71": 3083083,
    "72": 3191048,
    "73": 2334433,
    "74": 2283164,
    "75": 2198286,
    "76": 2222392,
    "77": 1911261,
    "78": 1720817,
    "79": 1599909,
    "80": 1475278,
    "81": 1381641,
    "82": 1241341,
    "83": 1151190,
    "84": 1067757,
    "85": 922467,
    "86": 856646,
    "87": 778923,
    "88": 703078,
    "89": 635982,
    "90": 536447,
    "91": 467172,
    "92": 394067,
    "93": 320785,
    "94": 264277,
    "95": 207086,
    "96": 157463,
    "97": 116969,
    "98": 86150,
    "99": 57124,
    "100": 100322
  },
  "2020": {
    /*"0": 3735010,
    "1": 3773884,
    "2": 3853025,
    "3": 3921526,
    "4": 4017847,
    "5": 4054336,
    "6": 4040169,
    "7": 4029753,
    "8": 4034785,
    "9": 4078668,
    "10": 4074173,
    "11": 4072994,
    "12": 4200977,
    "13": 4219921,
    "14": 4186358,
    "15": 4175920,
    "16": 4187874,
    "17": 4164893,
    "18": 4159857,
    "19": 4272385,*/
    "20": 4344572,
    "21": 4283150,
    "22": 4293020,
    "23": 4315220,
    "24": 4358793,
    "25": 4461006,
    "26": 4555511,
    "27": 4628618,
    "28": 4751001,
    "29": 4835107,
    "30": 4821081,
    "31": 4627340,
    "32": 4514736,
    "33": 4432699,
    "34": 4442547,
    "35": 4466747,
    "36": 4319361,
    "37": 4374441,
    "38": 4362662,
    "39": 4305093,
    "40": 4378957,
    "41": 4100150,
    "42": 4014287,
    "43": 3967810,
    "44": 3846684,
    "45": 3957972,
    "46": 3826694,
    "47": 3876923,
    "48": 4043448,
    "49": 4265569,
    "50": 4310670,
    "51": 4076434,
    "52": 3983727,
    "53": 3979962,
    "54": 4044734,
    "55": 4277856,
    "56": 4343881,
    "57": 4327862,
    "58": 4306615,
    "59": 4346885,
    "60": 4372095,
    "61": 4208976,
    "62": 4168433,
    "63": 4106492,
    "64": 3944582,
    "65": 3897420,
    "66": 3720410,
    "67": 3562236,
    "68": 3407082,
    "69": 3286519,
    "70": 3192029,
    "71": 3074732,
    "72": 3017173,
    "73": 3117385,
    "74": 2274412,
    "75": 2219565,
    "76": 2130427,
    "77": 2147097,
    "78": 1839828,
    "79": 1649916,
    "80": 1526957,
    "81": 1400392,
    "82": 1303809,
    "83": 1163249,
    "84": 1070307,
    "85": 984343,
    "86": 842226,
    "87": 774636,
    "88": 696138,
    "89": 620583,
    "90": 553813,
    "91": 460157,
    "92": 394211,
    "93": 326753,
    "94": 260690,
    "95": 210633,
    "96": 161205,
    "97": 119744,
    "98": 86169,
    "99": 62300,
    "100": 104819
  },
  "2021": {
    0: 3564493,
    1: 3702936,
    2: 3767428,
    3: 3856743,
    4: 3935738,
    5: 4040153,
    6: 4076629,
    7: 4064596,
    8: 4051555,
    9: 4058615,
    10: 4103489,
    11: 4189406,
    12: 4279315,
    13: 4442581,
    14: 4432993,
    15: 4355926,
    16: 4310462,
    17: 4333375,
    18: 4292780,
    19: 4272123,
    20: 4346679,
    21: 4347378,
    22: 4278923,
    23: 4271130,
    24: 4279887,
    25: 4309146,
    26: 4387353,
    27: 4481186,
    28: 4554775,
    29: 4660017,
    30: 4730811,
    31: 4745375,
    32: 4612291,
    33: 4531756,
    34: 4482395,
    35: 4492867,
    36: 4490254,
    37: 4397037,
    38: 4463130,
    39: 4456030,
    40: 4391796,
    41: 4372860,
    42: 4193750,
    43: 4099002,
    44: 4047128,
    45: 3908305,
    46: 3951786,
    47: 3875209,
    48: 3927492,
    49: 4118718,
    50: 4366085,
    51: 4297812,
    52: 4136193,
    53: 4046823,
    54: 4060013,
    55: 4119517,
    56: 4280217,
    57: 4383354,
    58: 4384908,
    59: 4399318,
    60: 4391144,
    61: 4312796,
    62: 4228770,
    63: 4190193,
    64: 4112847,
    65: 3928157,
    66: 3834444,
    67: 3695234,
    68: 3537207,
    69: 3399278,
    70: 3229649,
    71: 3076389,
    72: 2988058,
    73: 2917030,
    74: 3060676,
    75: 2157557,
    76: 2060045,
    77: 1992972,
    78: 1997941,
    79: 1696254,
    80: 1507612,
    81: 1366271,
    82: 1254351,
    83: 1156487,
    84: 1016585,
    85: 922486,
    86: 808993,
    87: 705041,
    88: 608268,
    89: 534694,
    90: 465612,
    91: 411840,
    92: 345678,
    93: 282638,
    94: 231507,
    95: 181805,
    96: 141314,
    97: 106920,
    98: 78467,
    99: 52579,
    100: 97914
  }
};
const frequency = (chosen5yrs, a, print) => {
  var frequency = null;
  if (!chosen5yrs && print) {
    if (a === 0) {
      frequency = ["15-29"];
    } else if (a === 1) {
      frequency = ["30-34"];
    } else if (a === 2) {
      frequency = ["35-49"];
    } else if (a === 3) {
      frequency = ["50-64"];
    } else if (a === 4) {
      frequency = ["65-74"];
    } else if (a === 5) {
      frequency = ["75-84"];
    } else if (a === 6) {
      frequency = ["85-109"];
    }
  } else if (chosen5yrs || print) {
    //high
    if (a === 0) {
      frequency = ["75-79"];
    } else if (a === 1) {
      frequency = ["80-84"];
    } else if (a === 2) {
      frequency = ["85-89"];
    } else if (a === 3) {
      frequency = ["90-94"];
    } else if (a === 4) {
      frequency = ["95-99"];
    } else if (a === 5) {
      frequency = ["100-104"];
    } else if (a === 6) {
      frequency = ["105-109"];
    }
  } else {
    //programmatic range not 5 yrs
    //cohort
    if (a === 0) {
      frequency = [/*"0", "1-4", "5-9", "10-14", */ "15-19", "20-24", "25-29"];
    } else if (a === 1) {
      frequency = ["30-34", "35-39"];
    } else if (a === 2) {
      frequency = ["40-44", "45-49"];
    } else if (a === 3) {
      frequency = ["50-54", "55-59", "60-64"];
    } else if (a === 4) {
      frequency = ["65-69", "70-74"];
    } else if (a === 5) {
      frequency = ["75-79", "80-84"];
    } else if (a === 6) {
      frequency = ["85-89", "90-94", "95-99", "100-104", "105-109", "110"];
    }
  }
  return frequency;
};
class Mortal extends React.Component {
  constructor(props) {
    super(props);

    let dates = [];
    let noData = [];
    var averageLifetimeData = [];
    var averageLifetime = [];
    let mortalZeroNJData = [];
    var mortalZeroNJ = [];
    let mortalEightyFiveNJDataAge = [];
    let mortalEightyFiveNJAge = [];
    let mortalSixtyFiveNJDataAge = [];
    let mortalSixtyFiveNJAge = [];
    let mortalSeventyFiveNJDataAge = [];
    let mortalSeventyFiveNJAge = [];
    let mortalFiftyNJDataAge = [];
    let mortalFiftyNJAge = [];
    let mortalZeroNJDataAge = [];
    let mortalZeroNJAge = [];
    let mortalFiftyNJData = [];
    let mortalFiftyNJ = [];
    let mortalSeventyFiveNJData = [];
    let mortalSeventyFiveNJ = [];
    let mortalSixtyFiveNJData = [];
    let mortalSixtyFiveNJ = [];
    let mortalEightyFiveNJData = [];
    let mortalEightyFiveNJ = [];
    let mortalNinetyFiveNJData = [];
    let mortalNinetyFiveNJ = [];
    let mortalOneTenNJData = [];
    let mortalOneTenNJ = [];

    let yearlyZeroNJData = [];
    let yearlyZeroNJ = [];
    let yearlyFiftyNJData = [];
    let yearlyFiftyNJ = [];
    let yearlySeventyFiveNJData = [];
    let yearlySeventyFiveNJ = [];
    let yearlySixtyFiveNJData = [];
    let yearlySixtyFiveNJ = [];
    let yearlyEightyFiveNJData = [];
    let yearlyEightyFiveNJ = [];
    let yearlyNinetyFiveNJData = [];
    let yearlyNinetyFiveNJ = [];
    let yearlyOneTenNJData = [];
    let yearlyOneTenNJ = [];

    let yearlyZeroNJDataAge = [];
    let yearlyZeroNJAge = [];
    let yearlyFiftyNJDataAge = [];
    let yearlyFiftyNJAge = [];
    let yearlySeventyFiveNJDataAge = [];
    let yearlySeventyFiveNJAge = [];
    let yearlySixtyFiveNJDataAge = [];
    let yearlySixtyFiveNJAge = [];
    let yearlyEightyFiveNJDataAge = [];
    let yearlyEightyFiveNJAge = [];
    let yearlyNinetyFiveNJDataAge = [];
    let yearlyNinetyFiveNJAge = [];
    let yearlyOneTenNJDataAge = [];
    let yearlyOneTenNJAge = [];

    let mZeroNJData = [];
    let mEightyFiveNJDataAge = [];
    let mSixtyFiveNJDataAge = [];
    let mSeventyFiveNJDataAge = [];
    let mFiftyNJDataAge = [];

    let yZeroNJData = [];
    let yFiftyNJData = [];
    let ySixtyFiveNJData = [];
    let ySeventyFiveNJData = [];
    let yEightyFiveNJData = [];
    let yNinetyFiveNJData = [];
    let yOneTenNJData = [];

    let yZeroNJDataAge = [];
    let yFiftyNJDataAge = [];
    let ySixtyFiveNJDataAge = [];
    let ySeventyFiveNJDataAge = [];
    let yEightyFiveNJDataAge = [];
    let yNinetyFiveNJDataAge = [];
    let yOneTenNJDataAge = [];

    let mZeroNJDataAge = [];
    let mFiftyNJData = [];
    let mSixtyFiveNJData = [];
    let mSeventyFiveNJData = [];
    let mEightyFiveNJData = [];
    let mNinetyFiveNJData = [];
    let mOneTenNJData = [];
    let avgLifetime = {};
    let chosen5yrs = null;
    Object.keys(usmortality).forEach((yearSet, i) => {
      const year = yearSet; //Number(yearSet.split("-")[1]);

      const val = (d, p = 1000) => Math.round((d / 100000) * p * 1000);
      Object.values(usmortality)[i].forEach((x) => {
        if (!avgLifetime[x.age])
          avgLifetime[x.age] = { total: 0, length: 0, year };
        //console.log(x);
        const state = {
          year,
          length: avgLifetime[x.age].length + 1,
          total:
            (avgLifetime[x.age].total + val(x.dx, x.pop)) /
            avgLifetime[x.age].total
        };
        avgLifetime[x.age] = state;
      });
    });
    //console.log(avgLifetime);
    Object.keys(avgLifetime).forEach((y, i) => {
      const x = Object.values(avgLifetime)[i];
      averageLifetime.push(x.total / x.length);
      averageLifetimeData.push([x.year, x.total / x.length]);
    });
    Object.keys(usmortality).forEach((yearSet, i) => {
      const year = yearSet; //Number(yearSet.split("-")[1]);
      noData.push([year, 0]);
      dates.push(year);
      /*const thisdecade = popdata.find(
        (x) => x.year - year < 5 && x.year - year > -5
      );*/
      const val = (d, p = 1000) => Math.round((d / 100000) * p * 1000);
      Object.values(usmortality)[i].forEach((x) => {
        if (frequency(chosen5yrs, 0).includes(x.age)) {
          mZeroNJData.push([year, val(x.dx, x.pop)]);
          mZeroNJDataAge.push([year, x.pop * 1000]);
        } else if (frequency(chosen5yrs, 1).includes(x.age)) {
          mFiftyNJData.push([year, val(x.dx, x.pop)]);
          mFiftyNJDataAge.push([year, x.pop * 1000]);
        } else if (frequency(chosen5yrs, 2).includes(x.age)) {
          mSixtyFiveNJData.push([year, val(x.dx, x.pop)]);
          mSixtyFiveNJDataAge.push([year, x.pop * 1000]);
        } else if (frequency(chosen5yrs, 3).includes(x.age)) {
          mSeventyFiveNJData.push([year, val(x.dx, x.pop)]);
          mSeventyFiveNJDataAge.push([year, x.pop * 1000]);
        } else if (frequency(chosen5yrs, 4).includes(x.age)) {
          mEightyFiveNJData.push([year, val(x.dx, x.pop)]);
          mEightyFiveNJDataAge.push([year, x.pop * 1000]);
        } else if (frequency(chosen5yrs, 5).includes(x.age)) {
          mNinetyFiveNJData.push([year, val(x.dx, x.pop)]);
        } else if (frequency(chosen5yrs, 6).includes(x.age)) {
          mOneTenNJData.push([year, val(x.dx, x.pop)]);
        }
      });
    });

    Object.keys(yearly).forEach((year, i) => {
      noData.push([year, 0]);
      dates.push(year);
      var yearSet = null;
      Object.keys(usmortality).forEach((y, i) => {
        if (y - year > -1 && y - year < 6) {
          yearSet = Object.values(usmortality)[i];
        }
      });
      if (!yearSet) return console.log(yearSet);
      let obj = {};
      const ageDec = Object.values(yearly)[i];
      Object.keys(ageDec).forEach((age, i) => {
        const pop = yearSet.find(
          (y) => y.age.split("-")[1] - age > -1 && y.age.split("-")[1] - age < 5
        );
        const deaths = Number(Object.values(ageDec)[i]);
        if (!pop) console.log(age);
        if (!obj[pop.age]) obj[pop.age] = 0;
        if (deaths) obj[pop.age] = obj[pop.age] + deaths; //Math.round(deathprob * (pop.pop / 5) * 1000);
      });
      Object.keys(obj).forEach((age, i) => {
        if (frequency(chosen5yrs, 0).includes(age)) {
          yZeroNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 1).includes(age)) {
          yFiftyNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 2).includes(age)) {
          ySixtyFiveNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 3).includes(age)) {
          ySeventyFiveNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 4).includes(age)) {
          yEightyFiveNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 5).includes(age)) {
          yNinetyFiveNJData.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 6).includes(age)) {
          yOneTenNJData.push([year, Object.values(obj)[i]]);
        }
      });
      /**
    =[{
      age: "50-54",
      dx: 2357.65 ,
      pop: 20846
     },{}]
      */
    });
    Object.keys(yearlypop).forEach((year, i) => {
      var yearSet = null;
      Object.keys(usmortality).forEach((y, i) => {
        if (y - year > -1 && y - year < 5) {
          yearSet = Object.values(usmortality)[i];
        }
      });
      if (!yearSet) return console.log(yearSet);
      let obj = {};
      const ageDec = Object.values(yearlypop)[i];
      Object.keys(ageDec).forEach((age, i) => {
        const pop = yearSet.find(
          (y) => y.age.split("-")[1] - age > -1 && y.age.split("-")[1] - age < 5
        );
        const population = Number(Object.values(ageDec)[i]);

        if (!obj[pop.age]) obj[pop.age] = 0;
        if (population) obj[pop.age] = obj[pop.age] + Math.round(population); //Math.round(deathprob * (pop.pop / 5) * 1000);
      });
      Object.keys(obj).forEach((age, i) => {
        if (frequency(chosen5yrs, 0).includes(age)) {
          yZeroNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 1).includes(age)) {
          yFiftyNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 2).includes(age)) {
          ySixtyFiveNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 3).includes(age)) {
          ySeventyFiveNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 4).includes(age)) {
          yEightyFiveNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 5).includes(age)) {
          yNinetyFiveNJDataAge.push([year, Object.values(obj)[i]]);
        } else if (frequency(chosen5yrs, 6).includes(age)) {
          yOneTenNJDataAge.push([year, Object.values(obj)[i]]);
        }
      });
      /**
    =[{
      age: "50-54",
      dx: 2357.65 ,
      pop: 20846
     },{}]
      */
    });
    dates.forEach((x) => {
      let yOA = 0;
      yOneTenNJDataAge.forEach((obj) => {
        if (obj[0] === x) yOA = yOA + obj[1];
      });
      yearlyOneTenNJAge.push(yOA);
      yearlyOneTenNJDataAge.push([x, yOA]);
      let yNA = 0;
      yNinetyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) yNA = yNA + obj[1];
      });
      yearlyNinetyFiveNJAge.push(yNA);
      yearlyNinetyFiveNJDataAge.push([x, yNA]);
      let yAA = 0;
      yEightyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) yAA = yAA + obj[1];
      });
      yearlyEightyFiveNJAge.push(yAA);
      yearlyEightyFiveNJDataAge.push([x, yAA]);
      let yEA = 0;
      ySeventyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) yEA = yEA + obj[1];
      });
      yearlySeventyFiveNJAge.push(yEA);
      yearlySeventyFiveNJDataAge.push([x, yEA]);
      let ySA = 0;
      ySixtyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) ySA = ySA + obj[1];
      });
      yearlySixtyFiveNJAge.push(ySA);
      yearlySixtyFiveNJDataAge.push([x, ySA]);
      let yMA = 0;
      yFiftyNJDataAge.forEach((obj) => {
        if (obj[0] === x) yMA = yMA + obj[1];
      });
      yearlyFiftyNJAge.push(yMA);
      yearlyFiftyNJDataAge.push([x, yMA]);
      let yZA = 0;
      yZeroNJDataAge.forEach((obj) => {
        if (obj[0] === x) yZA = yZA + obj[1];
      });
      yearlyZeroNJAge.push(yZA);
      yearlyZeroNJDataAge.push([x, yZA]);

      let yO = 0;
      yOneTenNJData.forEach((obj) => {
        if (obj[0] === x) yO = yO + obj[1];
      });
      yearlyOneTenNJ.push(yO);
      yearlyOneTenNJData.push([x, yO]);
      let yN = 0;
      yNinetyFiveNJData.forEach((obj) => {
        if (obj[0] === x) yN = yN + obj[1];
      });
      yearlyNinetyFiveNJ.push(yN);
      yearlyNinetyFiveNJData.push([x, yN]);
      let yA = 0;
      yEightyFiveNJData.forEach((obj) => {
        if (obj[0] === x) yA = yA + obj[1];
      });
      yearlyEightyFiveNJ.push(yA);
      yearlyEightyFiveNJData.push([x, yA]);
      let yE = 0;
      ySeventyFiveNJData.forEach((obj) => {
        if (obj[0] === x) yE = yE + obj[1];
      });
      yearlySeventyFiveNJ.push(yE);
      yearlySeventyFiveNJData.push([x, yE]);
      let yS = 0;
      ySixtyFiveNJData.forEach((obj) => {
        if (obj[0] === x) yS = yS + obj[1];
      });
      yearlySixtyFiveNJ.push(yS);
      yearlySixtyFiveNJData.push([x, yS]);
      let yM = 0;
      yFiftyNJData.forEach((obj) => {
        if (obj[0] === x) yM = yM + obj[1];
      });
      yearlyFiftyNJ.push(yM);
      yearlyFiftyNJData.push([x, yM]);
      let yZ = 0;
      yZeroNJData.forEach((obj) => {
        if (obj[0] === x) yZ = yZ + obj[1];
      });
      yearlyZeroNJ.push(yZ);
      yearlyZeroNJData.push([x, yZ]);

      let totmO = 0;
      mOneTenNJData.forEach((obj) => {
        if (obj[0] === x) totmO = totmO + obj[1];
      });
      mortalOneTenNJ.push(totmO);
      mortalOneTenNJData.push([x, totmO]);

      let totmN = 0;
      mNinetyFiveNJData.forEach((obj) => {
        if (obj[0] === x) totmN = totmN + obj[1];
      });
      mortalNinetyFiveNJ.push(totmN);
      mortalNinetyFiveNJData.push([x, totmN]);
      let totmE = 0;
      mEightyFiveNJData.forEach((obj) => {
        if (obj[0] === x) totmE = totmE + obj[1];
      });
      mortalEightyFiveNJ.push(totmE);
      mortalEightyFiveNJData.push([x, totmE]);
      let totmSe = 0;
      mSeventyFiveNJData.forEach((obj) => {
        if (obj[0] === x) totmSe = totmSe + obj[1];
      });
      mortalSeventyFiveNJ.push(totmSe);
      mortalSeventyFiveNJData.push([x, totmSe]);
      let totmS = 0;
      mSixtyFiveNJData.forEach((obj) => {
        if (obj[0] === x) totmS = totmS + obj[1];
      });
      mortalSixtyFiveNJ.push(totmS);
      mortalSixtyFiveNJData.push([x, totmS]);
      let totmF = 0;
      mFiftyNJData.forEach((obj) => {
        if (obj[0] === x) totmF = totmF + obj[1];
      });
      mortalFiftyNJ.push(totmF);
      mortalFiftyNJData.push([x, totmF]);
      let totmZ = 0;
      mZeroNJData.forEach((obj) => {
        if (obj[0] === x) totmZ = totmZ + obj[1];
      });
      mortalZeroNJ.push(totmZ);
      mortalZeroNJData.push([x, totmZ]);

      let totmEFA = 0;
      mEightyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) totmEFA = totmEFA + obj[1];
      });
      mortalEightyFiveNJAge.push(totmEFA);
      mortalEightyFiveNJDataAge.push([x, totmEFA]);
      let totmSeA = 0;
      mSeventyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) totmSeA = totmSeA + obj[1];
      });
      mortalSeventyFiveNJAge.push(totmSeA);
      mortalSeventyFiveNJDataAge.push([x, totmSeA]);
      let totmSA = 0;
      mSixtyFiveNJDataAge.forEach((obj) => {
        if (obj[0] === x) totmSA = totmSA + obj[1];
      });
      mortalSixtyFiveNJAge.push(totmSA);
      mortalSixtyFiveNJDataAge.push([x, totmSA]);
      let totmFA = 0;
      mFiftyNJDataAge.forEach((obj) => {
        if (obj[0] === x) totmFA = totmFA + obj[1];
      });
      mortalFiftyNJAge.push(totmFA);
      mortalFiftyNJDataAge.push([x, totmFA]);
      let totmZA = 0;
      mZeroNJDataAge.forEach((obj) => {
        if (obj[0] === x) totmZA = totmZA + obj[1];
      });
      mortalZeroNJAge.push(totmZA);
      mortalZeroNJDataAge.push([x, totmZA]);
    });
    var date = [...new Set(dates)];
    var lowDate = Math.min(...date);
    var highDate = Math.max(...date);
    var lowDeaths = 0; //Math.min(...mortalZeroNJ);
    const all = [
      ...yearlyZeroNJ,
      ...yearlyFiftyNJ,
      ...yearlySixtyFiveNJ,
      ...yearlySeventyFiveNJ,
      ...yearlyEightyFiveNJ,
      ...mortalZeroNJ,
      ...mortalFiftyNJ,
      ...mortalSixtyFiveNJ,
      ...mortalSeventyFiveNJ,
      ...mortalEightyFiveNJ,
      ...mortalNinetyFiveNJ,
      ...mortalOneTenNJ
    ];
    var highlifetime = Math.max(...averageLifetime);
    var highDeaths = Math.max(...all);
    var cappop = Math.max(
      ...yearlyZeroNJAge,
      ...yearlyFiftyNJAge,
      ...yearlySixtyFiveNJAge,
      ...yearlySeventyFiveNJAge,
      ...yearlyEightyFiveNJAge,
      ...mortalZeroNJAge,
      ...mortalFiftyNJAge,
      ...mortalSixtyFiveNJAge,
      ...mortalSeventyFiveNJAge,
      ...mortalEightyFiveNJAge
    );
    /*Object.keys(yearly).forEach((x) => {
      Object.keys(yearlypop).forEach((y, n) => {
        const yearSet = Object.values(yearly)[n];
        const yearSetPop = Object.values(yearlypop)[n];
        if (y === x) {
          /* var total = 0;
          for (let i = 0; i < 10; i++) {
            total = total + yearSetPop[75 + i];
            //console.log(yearSet[75 + i]);
          }*
          //console.log(y,x[1] / total, x[1], total);
          Object.values(yearSet).forEach((e, i) => {
            const pop = yearSetPop[i];
            if (
              [17, 18, 19, 20, 21, 22, 23, 24, 25, 26].includes(
                Object.values(yearSet).length - i
              )
            )
              ["2018", "2019", "2020"].includes(y) &&
                console.log(
                  y,
                  Object.keys(yearSet)[i],
                  y === "2020" ? e / (e + pop) : e / pop,
                  e,
                  y === "2020" ? e + pop : pop
                );
          });
        }
      });
    });*/
    noData.sort((a, b) => a[0] - b[0]);
    mortalZeroNJData.sort((a, b) => a[0] - b[0]);
    mortalZeroNJDataAge.sort((a, b) => a[0] - b[0]);
    mortalFiftyNJDataAge.sort((a, b) => a[0] - b[0]);
    mortalSixtyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
    mortalSeventyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
    mortalEightyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
    mortalFiftyNJData.sort((a, b) => a[0] - b[0]);
    mortalSixtyFiveNJData.sort((a, b) => a[0] - b[0]);
    mortalSeventyFiveNJData.sort((a, b) => a[0] - b[0]);
    mortalEightyFiveNJData.sort((a, b) => a[0] - b[0]);
    mortalNinetyFiveNJData.sort((a, b) => a[0] - b[0]);
    mortalOneTenNJData.sort((a, b) => a[0] - b[0]);
    var state = {
      cappop,
      highlifetime,
      averageLifetimeData,
      date,
      mortalZeroNJDataAge,
      mortalFiftyNJDataAge,
      mortalSixtyFiveNJDataAge,
      mortalSeventyFiveNJDataAge,
      mortalEightyFiveNJDataAge,
      mortalZeroNJData,
      mortalFiftyNJData,
      mortalSixtyFiveNJData,
      mortalSeventyFiveNJData,
      mortalEightyFiveNJData,
      mortalNinetyFiveNJData,
      mortalOneTenNJData,
      yearlyZeroNJData,
      yearlyFiftyNJData,
      yearlySixtyFiveNJData,
      yearlySeventyFiveNJData,
      yearlyEightyFiveNJData,
      yearlyNinetyFiveNJData,
      yearlyOneTenNJData,
      yearlyZeroNJDataAge,
      yearlyFiftyNJDataAge,
      yearlySixtyFiveNJDataAge,
      yearlySeventyFiveNJDataAge,
      yearlyEightyFiveNJDataAge,
      yearlyNinetyFiveNJDataAge,
      yearlyOneTenNJDataAge,
      noData,
      yAxis: highDeaths - lowDeaths,
      xAxis: highDate - lowDate,
      lowDate,
      highDate,
      lowDeaths,
      highDeaths,
      yaxis: true
      //chosenRate: true
    };
    this.state = state;
  }
  componentDidUpdate = (prevProps) => {
    const { chosenRate, chosen5yrs } = this.state;
    if (
      chosenRate !== this.state.lastChosenRate ||
      this.state.lastchosenfrequency !== chosen5yrs
    )
      this.setState(
        {
          lastchosenfrequency: chosen5yrs,
          lastChosenRate: chosenRate
        },
        () => {
          let dates = [];
          let noData = [];
          let mortalZeroNJData = [];
          var mortalZeroNJ = [];
          let mortalZeroNJDataAge = [];
          let mortalZeroNJAge = [];
          let mortalFiftyNJDataAge = [];
          let mortalFiftyNJAge = [];
          let mortalSixtyFiveNJDataAge = [];
          let mortalSixtyFiveNJAge = [];
          let mortalSeventyFiveNJDataAge = [];
          let mortalSeventyFiveNJAge = [];
          let mortalEightyFiveNJDataAge = [];
          let mortalEightyFiveNJAge = [];
          let mortalFiftyNJData = [];
          let mortalFiftyNJ = [];
          let mortalSixtyFiveNJData = [];
          let mortalSixtyFiveNJ = [];
          let mortalSeventyFiveNJData = [];
          let mortalSeventyFiveNJ = [];
          let mortalEightyFiveNJData = [];
          let mortalEightyFiveNJ = [];
          let mortalNinetyFiveNJData = [];
          let mortalNinetyFiveNJ = [];
          let mortalOneTenNJData = [];
          let mortalOneTenNJ = [];

          let yearlyZeroNJData = [];
          let yearlyZeroNJ = [];
          let yearlyFiftyNJData = [];
          let yearlyFiftyNJ = [];
          let yearlySeventyFiveNJData = [];
          let yearlySeventyFiveNJ = [];
          let yearlySixtyFiveNJData = [];
          let yearlySixtyFiveNJ = [];
          let yearlyEightyFiveNJData = [];
          let yearlyEightyFiveNJ = [];
          let yearlyNinetyFiveNJData = [];
          let yearlyNinetyFiveNJ = [];
          let yearlyOneTenNJData = [];
          let yearlyOneTenNJ = [];

          let yearlyZeroNJDataAge = [];
          let yearlyZeroNJAge = [];
          let yearlyFiftyNJDataAge = [];
          let yearlyFiftyNJAge = [];
          let yearlySeventyFiveNJDataAge = [];
          let yearlySeventyFiveNJAge = [];
          let yearlySixtyFiveNJDataAge = [];
          let yearlySixtyFiveNJAge = [];
          let yearlyEightyFiveNJDataAge = [];
          let yearlyEightyFiveNJAge = [];
          let yearlyNinetyFiveNJDataAge = [];
          let yearlyNinetyFiveNJAge = [];
          let yearlyOneTenNJDataAge = [];
          let yearlyOneTenNJAge = [];

          let mZeroNJData = [];
          let mZeroNJDataAge = [];
          let mFiftyNJDataAge = [];
          let mSixtyFiveNJDataAge = [];
          let mSeventyFiveNJDataAge = [];
          let mEightyFiveNJDataAge = [];
          let mFiftyNJData = [];
          let mSixtyFiveNJData = [];
          let mSeventyFiveNJData = [];
          let mEightyFiveNJData = [];
          let mNinetyFiveNJData = [];
          let mOneTenNJData = [];

          let yZeroNJData = [];
          let yFiftyNJData = [];
          let ySixtyFiveNJData = [];
          let ySeventyFiveNJData = [];
          let yEightyFiveNJData = [];
          let yNinetyFiveNJData = [];
          let yOneTenNJData = [];

          let yZeroNJDataAge = [];
          let yFiftyNJDataAge = [];
          let ySixtyFiveNJDataAge = [];
          let ySeventyFiveNJDataAge = [];
          let yEightyFiveNJDataAge = [];
          let yNinetyFiveNJDataAge = [];
          let yOneTenNJDataAge = [];
          const averageIt = chosenRate && !chosen5yrs;
          Object.keys(usmortality).forEach((yearSet, i) => {
            const year = yearSet; //Number(yearSet.split("-")[1]);
            noData.push([year, 0]);
            dates.push(year);
            /*const thisdecade = popdata.find(
          (x) => x.year - year < 5 && x.year - year > -5
        );*/
            const val = (d, p = 1000, rate = 1) =>
              Math.round(((d / 100000) * p * 1000) / rate);
            Object.values(usmortality)[i].forEach((x) => {
              if (frequency(chosen5yrs, 0).includes(x.age)) {
                mZeroNJDataAge.push([
                  year,
                  averageIt ? (x.pop * 1000) / 6 : x.pop * 1000
                ]);
              } else if (frequency(chosen5yrs, 1).includes(x.age)) {
                mFiftyNJDataAge.push([
                  year,
                  averageIt ? (x.pop * 1000) / 2 : x.pop * 1000
                ]);
              } else if (frequency(chosen5yrs, 2).includes(x.age)) {
                mSixtyFiveNJDataAge.push([
                  year,
                  averageIt ? (x.pop * 1000) / 2 : x.pop * 1000
                ]);
              } else if (frequency(chosen5yrs, 3).includes(x.age)) {
                mSeventyFiveNJDataAge.push([
                  year,
                  averageIt ? (x.pop * 1000) / 3 : x.pop * 1000
                ]);
              } else if (frequency(chosen5yrs, 4).includes(x.age)) {
                mEightyFiveNJDataAge.push([
                  year,
                  averageIt ? (x.pop * 1000) / 2 : x.pop * 1000
                ]);
              }
              if (frequency(chosen5yrs, 0).includes(x.age)) {
                mZeroNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 6) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 1).includes(x.age)) {
                mFiftyNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 2) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 2).includes(x.age)) {
                mSixtyFiveNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 2) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 3).includes(x.age)) {
                mSeventyFiveNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 3) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 4).includes(x.age)) {
                mEightyFiveNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 2) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 5).includes(x.age)) {
                mNinetyFiveNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 2) : val(x.dx, x.pop)
                ]);
              } else if (frequency(chosen5yrs, 6).includes(x.age)) {
                mOneTenNJData.push([
                  year,
                  averageIt ? val(x.dx, x.pop, 6) : val(x.dx, x.pop)
                ]);
              }
            });
          });
          Object.keys(yearly).forEach((year, i) => {
            noData.push([year, 0]);
            dates.push(year);
            var yearSet = null;
            Object.keys(usmortality).forEach((y, i) => {
              if (y - year > -1 && y - year < 6) {
                yearSet = Object.values(usmortality)[i];
              }
            });
            if (!yearSet) return console.log(yearSet);
            let obj = {};
            const ageDec = Object.values(yearly)[i];
            Object.keys(ageDec).forEach((age, i) => {
              const pop = yearSet.find(
                (y) =>
                  y.age.split("-")[1] - age > -1 &&
                  y.age.split("-")[1] - age < 5
              );
              const deaths = Number(Object.values(ageDec)[i]);
              //console.log(deaths);
              if (!obj[pop.age]) obj[pop.age] = 0;
              if (deaths) obj[pop.age] = obj[pop.age] + deaths; //Math.round(deathprob * (pop.pop / 5) * 1000);
            });
            Object.keys(obj).forEach((age, i) => {
              if (frequency(chosen5yrs, 0).includes(age)) {
                yZeroNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 6 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 1).includes(age)) {
                yFiftyNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 2).includes(age)) {
                ySixtyFiveNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 3).includes(age)) {
                ySeventyFiveNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 3 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 4).includes(age)) {
                yEightyFiveNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 5).includes(age)) {
                yNinetyFiveNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 6).includes(age)) {
                yOneTenNJData.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 6 : Object.values(obj)[i]
                ]);
              }
            });
            /**
          =[{
            age: "50-54",
            dx: 2357.65 ,
            pop: 20846
           },{}]
            */
          });
          Object.keys(yearlypop).forEach((year, i) => {
            var yearSet = null;
            Object.keys(usmortality).forEach((y, i) => {
              if (y - year > -1 && y - year < 5) {
                yearSet = Object.values(usmortality)[i];
              }
            });
            if (!yearSet) return console.log(yearSet);
            let obj = {};
            const ageDec = Object.values(yearlypop)[i];
            Object.keys(ageDec).forEach((age, i) => {
              const pop = yearSet.find(
                (y) =>
                  y.age.split("-")[1] - age > -1 &&
                  y.age.split("-")[1] - age < 5
              );
              const population = Number(Object.values(ageDec)[i]);

              if (!obj[pop.age]) obj[pop.age] = 0;
              if (population)
                obj[pop.age] = obj[pop.age] + Math.round(population); //Math.round(deathprob * (pop.pop / 5) * 1000);
            });
            Object.keys(obj).forEach((age, i) => {
              if (frequency(chosen5yrs, 0).includes(age)) {
                yZeroNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 6 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 1).includes(age)) {
                yFiftyNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 2).includes(age)) {
                ySixtyFiveNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 3).includes(age)) {
                ySeventyFiveNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 3 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 4).includes(age)) {
                yEightyFiveNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 5).includes(age)) {
                yNinetyFiveNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 2 : Object.values(obj)[i]
                ]);
              } else if (frequency(chosen5yrs, 6).includes(age)) {
                yOneTenNJDataAge.push([
                  year,
                  averageIt ? Object.values(obj)[i] / 6 : Object.values(obj)[i]
                ]);
              }
            });
            /**
    =[{
      age: "50-54",
      dx: 2357.65 ,
      pop: 20846
     },{}]
      */
          });

          dates.sort((a, b) => a - b);
          var date = [...new Set(dates)];
          date.forEach((x) => {
            let yOA = 0;
            yOneTenNJDataAge.forEach((obj) => {
              if (obj[0] === x) yOA = yOA + obj[1];
            });
            yearlyOneTenNJAge.push(yOA);
            yearlyOneTenNJDataAge.push([x, yOA]);
            let yNA = 0;
            yNinetyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) yNA = yNA + obj[1];
            });
            yearlyNinetyFiveNJAge.push(yNA);
            yearlyNinetyFiveNJDataAge.push([x, yNA]);
            let yAA = 0;
            yEightyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) yAA = yAA + obj[1];
            });
            yearlyEightyFiveNJAge.push(yAA);
            yearlyEightyFiveNJDataAge.push([x, yAA]);
            let yEA = 0;
            ySeventyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) yEA = yEA + obj[1];
            });
            yearlySeventyFiveNJAge.push(yEA);
            yearlySeventyFiveNJDataAge.push([x, yEA]);
            let ySA = 0;
            ySixtyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) ySA = ySA + obj[1];
            });
            yearlySixtyFiveNJAge.push(ySA);
            yearlySixtyFiveNJDataAge.push([x, ySA]);
            let yMA = 0;
            yFiftyNJDataAge.forEach((obj) => {
              if (obj[0] === x) yMA = yMA + obj[1];
            });
            yearlyFiftyNJAge.push(yMA);
            yearlyFiftyNJDataAge.push([x, yMA]);
            let yZA = 0;
            yZeroNJDataAge.forEach((obj) => {
              if (obj[0] === x) yZA = yZA + obj[1];
            });
            yearlyZeroNJAge.push(yZA);
            yearlyZeroNJDataAge.push([x, yZA]);

            let yO = 0;
            yOneTenNJData.forEach((obj) => {
              if (obj[0] === x) yO = yO + obj[1];
            });
            yearlyOneTenNJ.push(yO);
            yearlyOneTenNJData.push([x, yO]);
            let yN = 0;
            yNinetyFiveNJData.forEach((obj) => {
              if (obj[0] === x) yN = yN + obj[1];
            });
            yearlyNinetyFiveNJ.push(yN);
            yearlyNinetyFiveNJData.push([x, yN]);
            let yA = 0;
            yEightyFiveNJData.forEach((obj) => {
              if (obj[0] === x) yA = yA + obj[1];
            });
            yearlyEightyFiveNJ.push(yA);
            yearlyEightyFiveNJData.push([x, yA]);
            let yE = 0;
            ySeventyFiveNJData.forEach((obj) => {
              if (obj[0] === x) yE = yE + obj[1];
            });
            yearlySeventyFiveNJ.push(yE);
            yearlySeventyFiveNJData.push([x, yE]);
            let yS = 0;
            ySixtyFiveNJData.forEach((obj) => {
              if (obj[0] === x) yS = yS + obj[1];
            });
            yearlySixtyFiveNJ.push(yS);
            yearlySixtyFiveNJData.push([x, yS]);
            let yM = 0;
            yFiftyNJData.forEach((obj) => {
              if (obj[0] === x) yM = yM + obj[1];
            });
            yearlyFiftyNJ.push(yM);
            yearlyFiftyNJData.push([x, yM]);
            let yZ = 0;
            yZeroNJData.forEach((obj) => {
              if (obj[0] === x) yZ = yZ + obj[1];
            });
            yearlyZeroNJ.push(yZ);
            yearlyZeroNJData.push([x, yZ]);

            let totmO = 0;
            mOneTenNJData.forEach((obj) => {
              if (obj[0] === x) totmO = totmO + obj[1];
            });
            mortalOneTenNJ.push(totmO);
            mortalOneTenNJData.push([x, totmO]);

            let totmN = 0;
            mNinetyFiveNJData.forEach((obj) => {
              if (obj[0] === x) totmN = totmN + obj[1];
            });
            mortalNinetyFiveNJ.push(totmN);
            mortalNinetyFiveNJData.push([x, totmN]);
            let totmE = 0;
            mEightyFiveNJData.forEach((obj) => {
              if (obj[0] === x) totmE = totmE + obj[1];
            });
            mortalEightyFiveNJ.push(totmE);
            mortalEightyFiveNJData.push([x, totmE]);
            let totmSe = 0;
            mSeventyFiveNJData.forEach((obj) => {
              if (obj[0] === x) totmSe = totmSe + obj[1];
            });
            mortalSeventyFiveNJ.push(totmSe);
            mortalSeventyFiveNJData.push([x, totmSe]);
            let totmS = 0;
            mSixtyFiveNJData.forEach((obj) => {
              if (obj[0] === x) totmS = totmS + obj[1];
            });
            mortalSixtyFiveNJ.push(totmS);
            mortalSixtyFiveNJData.push([x, totmS]);
            let totmF = 0;
            mFiftyNJData.forEach((obj) => {
              if (obj[0] === x) totmF = totmF + obj[1];
            });
            mortalFiftyNJ.push(totmF);
            mortalFiftyNJData.push([x, totmF]);
            let totmZ = 0;
            mZeroNJData.forEach((obj) => {
              if (obj[0] === x) totmZ = totmZ + obj[1];
            });
            mortalZeroNJ.push(totmZ);
            mortalZeroNJData.push([x, totmZ]);

            let totmEFA = 0;
            mEightyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) totmEFA = totmEFA + obj[1];
            });
            mortalEightyFiveNJAge.push(totmEFA);
            mortalEightyFiveNJDataAge.push([x, totmEFA]);
            let totmSeA = 0;
            mSeventyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) totmSeA = totmSeA + obj[1];
            });
            mortalSeventyFiveNJAge.push(totmSeA);
            mortalSeventyFiveNJDataAge.push([x, totmSeA]);
            let totmSA = 0;
            mSixtyFiveNJDataAge.forEach((obj) => {
              if (obj[0] === x) totmSA = totmSA + obj[1];
            });
            mortalSixtyFiveNJAge.push(totmSA);
            mortalSixtyFiveNJDataAge.push([x, totmSA]);
            let totmFA = 0;
            mFiftyNJDataAge.forEach((obj) => {
              if (obj[0] === x) totmFA = totmFA + obj[1];
            });
            mortalFiftyNJAge.push(totmFA);
            mortalFiftyNJDataAge.push([x, totmFA]);
            let totmZA = 0;
            mZeroNJDataAge.forEach((obj) => {
              if (obj[0] === x) totmZA = totmZA + obj[1];
            });
            mortalZeroNJAge.push(totmZA);
            mortalZeroNJDataAge.push([x, totmZA]);
          });
          //console.log(yearlyFiftyNJAge, yearlyZeroNJAge);
          var lowDate = Math.min(...date);
          var highDate = Math.max(...date);
          var lowDeaths = 0; //Math.min(...mortalZeroNJ);
          const all = [
            ...yearlyZeroNJ,
            ...yearlyFiftyNJ,
            ...yearlySixtyFiveNJ,
            ...yearlySeventyFiveNJ,
            ...yearlyEightyFiveNJ,
            ...mortalZeroNJ,
            ...mortalFiftyNJ,
            ...mortalSixtyFiveNJ,
            ...mortalSeventyFiveNJ,
            ...mortalEightyFiveNJ,
            ...mortalNinetyFiveNJ,
            ...mortalOneTenNJ
          ];
          var highDeaths = Math.max(...all);
          var cappop = Math.max(
            ...yearlyZeroNJAge,
            ...yearlyFiftyNJAge,
            ...yearlySixtyFiveNJAge,
            ...yearlySeventyFiveNJAge,
            ...yearlyEightyFiveNJAge,
            ...(averageIt ? [] : mortalZeroNJAge),
            ...mortalFiftyNJAge,
            ...mortalSixtyFiveNJAge,
            ...mortalSeventyFiveNJAge,
            ...mortalEightyFiveNJAge
          );
          noData.sort((a, b) => a[0] - b[0]);
          mortalZeroNJData.sort((a, b) => a[0] - b[0]);
          mortalFiftyNJDataAge.sort((a, b) => a[0] - b[0]);
          mortalSixtyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
          mortalSeventyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
          mortalEightyFiveNJDataAge.sort((a, b) => a[0] - b[0]);
          mortalFiftyNJData.sort((a, b) => a[0] - b[0]);
          mortalSixtyFiveNJData.sort((a, b) => a[0] - b[0]);
          mortalSeventyFiveNJData.sort((a, b) => a[0] - b[0]);
          mortalEightyFiveNJData.sort((a, b) => a[0] - b[0]);
          mortalNinetyFiveNJData.sort((a, b) => a[0] - b[0]);
          mortalOneTenNJData.sort((a, b) => a[0] - b[0]);
          this.setState({
            date,
            cappop,
            mortalZeroNJData,
            mortalFiftyNJDataAge,
            mortalSixtyFiveNJDataAge,
            mortalSeventyFiveNJDataAge,
            mortalEightyFiveNJDataAge,
            mortalFiftyNJData,
            mortalSixtyFiveNJData,
            mortalSeventyFiveNJData,
            mortalEightyFiveNJData,
            mortalNinetyFiveNJData,
            mortalOneTenNJData,
            yearlyZeroNJData,
            yearlyFiftyNJData,
            yearlySixtyFiveNJData,
            yearlySeventyFiveNJData,
            yearlyEightyFiveNJData,
            yearlyNinetyFiveNJData,
            yearlyOneTenNJData,
            yearlyZeroNJDataAge,
            yearlyFiftyNJDataAge,
            yearlySixtyFiveNJDataAge,
            yearlySeventyFiveNJDataAge,
            yearlyEightyFiveNJDataAge,
            yearlyNinetyFiveNJDataAge,
            yearlyOneTenNJDataAge,
            noData,
            yAxis: highDeaths, //- lowDeaths,
            xAxis: highDate - lowDate,
            lowDate,
            highDate,
            lowDeaths,
            highDeaths
          });
        }
      );
  };
  render() {
    const yaxis = this.state.yaxis ? this.state.yAxis : this.state.cappop;
    const { chosenRate, lowDate } = this.state;
    const noData = this.state.noData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      0
    ]);
    const mortalZeroNJDataAge = this.state.mortalZeroNJDataAge.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / this.state.cappop) * 150
    ]);
    const mortalFiftyNJDataAge = this.state.mortalFiftyNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const mortalSixtyFiveNJDataAge = this.state.mortalSixtyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const mortalSeventyFiveNJDataAge = this.state.mortalSeventyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const mortalEightyFiveNJDataAge = this.state.mortalEightyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const mortalZeroNJData = this.state.mortalZeroNJData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / yaxis) * 150
    ]);
    const mortalFiftyNJData = this.state.mortalFiftyNJData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / yaxis) * 150
    ]);
    const mortalSixtyFiveNJData = this.state.mortalSixtyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const mortalSeventyFiveNJData = this.state.mortalSeventyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const mortalEightyFiveNJData = this.state.mortalEightyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const mortalNinetyFiveNJData = this.state.mortalNinetyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const mortalOneTenNJData = this.state.mortalOneTenNJData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / yaxis) * 150
    ]);
    const averageLifetimeData = this.state.averageLifetimeData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - 0) / this.state.highlifetime) * 150
    ]);

    const yearlyZeroNJData = this.state.yearlyZeroNJData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / yaxis) * 150
    ]);
    const yearlyFiftyNJData = this.state.yearlyFiftyNJData.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / yaxis) * 150
    ]);
    const yearlySixtyFiveNJData = this.state.yearlySixtyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const yearlySeventyFiveNJData = this.state.yearlySeventyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const yearlyEightyFiveNJData = this.state.yearlyEightyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const yearlyNinetyFiveNJData = this.state.yearlyNinetyFiveNJData.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / yaxis) * 150
      ]
    );
    const yearlyOneTenNJData = this.state.yearlyOneTenNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlyZeroNJDataAge = this.state.yearlyZeroNJDataAge.map(([x, y]) => [
      ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
      ((y - this.state.lowDeaths) / this.state.cappop) * 150
    ]);
    const yearlyFiftyNJDataAge = this.state.yearlyFiftyNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlySixtyFiveNJDataAge = this.state.yearlySixtyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlySeventyFiveNJDataAge = this.state.yearlySeventyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlyEightyFiveNJDataAge = this.state.yearlyEightyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlyNinetyFiveNJDataAge = this.state.yearlyNinetyFiveNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const yearlyOneTenNJDataAge = this.state.yearlyOneTenNJDataAge.map(
      ([x, y]) => [
        ((x - lowDate) / this.state.xAxis) * this.props.lastWidth * 0.9,
        ((y - this.state.lowDeaths) / this.state.cappop) * 150
      ]
    );
    const ite = { border: "1px grey dashed", width: "max-content" };
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };
    const averageIt = chosenRate && !this.state.chosen5yrs;
    return (
      <div style={this.props.style}>
        {/*<div
          style={{
            position: "relative",
            top: "0px",
            height: "min-content",
            flexWrap: "wrap",
            display: "flex"
          }}
        >
          {["New Jersey"].map((x, i) => {
            return (
              <div
                key={i}
                onMouseEnter={() =>
                  this.setState({}, () => {
                    clearTimeout(this.unHover);
                    this.unHover = setTimeout(
                      () => this.setState({ hoveredBtn: x }),
                      200
                    );
                  })
                }
                style={{
                  transition: ".3s ease-in",
                  boxShadow: `inset 0px 0px ${
                    this.state.hoveredBtn === x ? 7 : 0
                  }px  ${this.state.hoveredBtn === x ? 2 : 0.5}px black`,
                  alignItems: "center",
                  padding: "4px 7px",
                  border: this.state.chosenState === x ? "1px solid black" : {1},
                  display: "flex"
                }}
              >
                <div onClick={() => this.setState({ chosenState: x })}>{x}</div>
                &nbsp;
                {this.state.chosenState === x && (
                  <button onClick={() => this.setState({ chosenState: null })}>
                    &times;
                  </button>
                )}
              </div>
            );
          })}
        </div>*/}
        <div style={{ width: "100%", minHeight: "230px" }}>
          <div style={labelstyle}>
            <div
              style={buttonStyle}
              onClick={() =>
                this.setState(
                  //{ chosen5yrs: !this.state.chosen5yrs }
                  this.state.chosenRate && this.state.chosen5yrs
                    ? { chosenRate: false, chosen5yrs: false }
                    : !this.state.chosenRate
                    ? { chosenRate: true }
                    : { chosen5yrs: true }
                )
              }
            >
              {averageIt
                ? "population per 5yr "
                : `highest accu yearly mortality `}
            </div>
            <button
              style={buttonStyle}
              onClick={() => {
                this.setState({
                  yaxis: !this.state.yaxis
                });
              }}
            >
              {!this.state.yaxis ? "pop" : !averageIt ? "high" : "cohort"}
            </button>
            <div>
              -&nbsp;
              <br />
              {shortNumber(Math.round(this.state.highDeaths /*/5 */))}
            </div>
            <div
              style={{
                top: "0px",
                height: "min-content",
                display: "flex",
                position: "relative",
                right: "0px"
              }}
            >
              {lowDate}&nbsp;
              {this.state.highDate}
            </div>
            <br />
            {/* <div
              style={{
                textAlign: "right",
                top: "0px",
                height: "min-content",
                display: "flex",
                position: "relative",
                right: "0px"
              }}
            >
              {this.state.highlifetime} average
            </div>
            {/*<div
              style={{
                top: "200px",
                height: "min-content",
                display: "flex",
                position: "absolute",
                right: "0px",
                flexDirection: "column"
              }}
            >
              {this.state.lowDeaths}
            </div>*/}
          </div>
          <div style={{ transform: "translate(0px,200px)" }}>
            <svg
              className="all"
              style={linecss}
              xmlns="http://www.w3.org/2000/svg"
            >
              {noData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="rgb(230,230,230)"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {/** */}
              {mortalZeroNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="white"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalFiftyNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="purple"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalSixtyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="blue"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalSeventyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="green"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalEightyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="orange"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalNinetyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="red"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {mortalOneTenNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="black"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {/*<BasisCurve
              showPoints={false}
              strokeWidth={4}
              stroke="purple"
              data={mortalZeroNJData}
            />*/}
              {mortalZeroNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="white"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {mortalFiftyNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="purple"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {mortalSixtyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="blue"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {mortalSeventyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="green"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {mortalEightyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="orange"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlyZeroNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="white"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlyFiftyNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="purple"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlySixtyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="blue"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlySeventyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="green"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlyEightyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="orange"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlyNinetyFiveNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="red"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlyOneTenNJDataAge.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={8}
                      height={1}
                      stroke="black"
                      fill="transparent"
                      strokeWidth={1}
                      key={i}
                    />
                  )
              )}
              {yearlySeventyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="green"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlyEightyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="orange"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlyNinetyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="red"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlyOneTenNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="black"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {averageLifetimeData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="black"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlySixtyFiveNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="blue"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlyZeroNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="white"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
              {yearlyFiftyNJData.map(
                ([x, y], i) =>
                  !isNaN(x) &&
                  !isNaN(y) && (
                    <rect
                      x={x}
                      y={y}
                      width={2}
                      height={2}
                      stroke="purple"
                      fill="transparent"
                      strokeWidth={3}
                      key={i}
                    />
                  )
              )}
            </svg>
          </div>
          <div style={labelstyle}>
            <div
              style={{
                display: "flex",
                position: "absolute",
                width: "max-content"
              }}
            >
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "white"
                  }}
                />
                {frequency(this.state.chosen5yrs, 0, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "purple"
                  }}
                />
                {frequency(this.state.chosen5yrs, 1, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "blue"
                  }}
                />
                {frequency(this.state.chosen5yrs, 2, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "green"
                  }}
                />
                {frequency(this.state.chosen5yrs, 3, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "orange"
                  }}
                />
                {frequency(this.state.chosen5yrs, 4, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "red"
                  }}
                />
                {frequency(this.state.chosen5yrs, 5, true)}&nbsp;&nbsp;
              </div>
              <div style={{ width: "max-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "black"
                  }}
                />
                {
                  frequency(this.state.chosen5yrs, 6, true) //chosen5yrs, index, range defaults cohort
                }
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,  minmax(10px, max-content))",
            width: "100%"
          }}
        >
          {this.state.date.map(
            (x) =>
              ["0", "5"].includes(x[3]) && (
                <div
                  key={x}
                  style={{
                    transform: "rotate(40deg)",
                    width: "max-content",
                    wordBreak: "none",
                    margin: "0px 10px"
                  }}
                >
                  {x}
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}

export default Mortal;
