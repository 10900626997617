export const yearsOfDeathByAge = {
  1959: {
    deathByAge: [
      2650,
      168,
      103,
      76,
      66,
      58,
      52,
      46,
      41,
      41,
      39,
      40,
      45,
      44,
      55,
      68,
      82,
      93,
      106,
      110,
      120,
      124,
      122,
      114,
      116,
      120,
      121,
      120,
      122,
      126,
      136,
      144,
      155,
      163,
      172,
      181,
      197,
      210,
      236,
      255,
      280,
      288,
      340,
      365,
      397,
      435,
      470,
      523,
      585,
      630,
      691,
      726,
      821,
      863,
      928,
      987,
      1053,
      1077,
      1238,
      1306,
      1379,
      1419,
      1608,
      1679,
      1691,
      1894,
      1903,
      2096,
      2176,
      2250,
      2395,
      2344,
      2580,
      2624,
      2723,
      2878,
      2881,
      2861,
      3008,
      3091,
      3056,
      2868,
      2938,
      2889,
      2775,
      2612,
      2450,
      2262,
      2006,
      1820,
      1593,
      1277,
      1126,
      936,
      753,
      581,
      447,
      336,
      246,
      175,
      121,
      82,
      53,
      34,
      21,
      13,
      7,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 69.98
  },
  1960: {
    deathByAge: [
      2613,
      171,
      103,
      81,
      66,
      56,
      51,
      46,
      42,
      39,
      36,
      39,
      39,
      47,
      50,
      61,
      76,
      92,
      103,
      114,
      117,
      123,
      117,
      113,
      117,
      123,
      123,
      122,
      124,
      131,
      138,
      138,
      150,
      163,
      171,
      186,
      196,
      213,
      241,
      259,
      287,
      298,
      347,
      366,
      406,
      445,
      475,
      531,
      580,
      636,
      713,
      739,
      829,
      864,
      934,
      995,
      1055,
      1136,
      1183,
      1331,
      1419,
      1410,
      1641,
      1700,
      1795,
      1930,
      1881,
      2117,
      2175,
      2256,
      2446,
      2356,
      2642,
      2646,
      2749,
      2848,
      2804,
      2893,
      3014,
      3035,
      3102,
      2814,
      2923,
      2844,
      2778,
      2597,
      2393,
      2240,
      2008,
      1758,
      1595,
      1253,
      1115,
      928,
      710,
      566,
      434,
      324,
      235,
      166,
      115,
      77,
      50,
      31,
      19,
      11,
      7,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 69.91
  },
  1961: {
    deathByAge: [
      2534,
      160,
      97,
      74,
      63,
      54,
      48,
      43,
      38,
      36,
      36,
      37,
      40,
      43,
      52,
      55,
      74,
      86,
      99,
      102,
      110,
      119,
      121,
      119,
      113,
      115,
      117,
      118,
      123,
      127,
      140,
      142,
      145,
      154,
      172,
      184,
      196,
      213,
      232,
      254,
      278,
      301,
      326,
      362,
      396,
      430,
      462,
      523,
      562,
      607,
      693,
      733,
      787,
      868,
      910,
      982,
      1041,
      1108,
      1199,
      1235,
      1398,
      1443,
      1547,
      1654,
      1749,
      1947,
      1869,
      2042,
      2179,
      2204,
      2393,
      2377,
      2594,
      2631,
      2674,
      2855,
      2743,
      2812,
      3072,
      3050,
      3083,
      2959,
      2915,
      2926,
      2800,
      2679,
      2504,
      2312,
      2042,
      1828,
      1640,
      1368,
      1138,
      959,
      772,
      613,
      473,
      356,
      261,
      186,
      130,
      88,
      57,
      37,
      23,
      14,
      8,
      5,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.32
  },
  1962: {
    deathByAge: [
      2496,
      156,
      96,
      73,
      58,
      55,
      48,
      43,
      39,
      35,
      34,
      37,
      39,
      43,
      50,
      60,
      69,
      86,
      101,
      112,
      111,
      123,
      120,
      120,
      117,
      120,
      120,
      117,
      121,
      132,
      136,
      142,
      150,
      158,
      174,
      190,
      202,
      224,
      239,
      259,
      281,
      295,
      339,
      358,
      398,
      435,
      467,
      522,
      566,
      624,
      683,
      740,
      827,
      842,
      943,
      979,
      1047,
      1112,
      1222,
      1292,
      1345,
      1458,
      1602,
      1609,
      1788,
      1966,
      1964,
      2057,
      2146,
      2232,
      2381,
      2357,
      2646,
      2642,
      2742,
      2837,
      2841,
      2731,
      2916,
      3162,
      3044,
      2892,
      3051,
      2886,
      2818,
      2690,
      2469,
      2284,
      2032,
      1844,
      1600,
      1284,
      1161,
      905,
      742,
      587,
      448,
      333,
      241,
      170,
      116,
      77,
      50,
      31,
      19,
      11,
      6,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.21
  },
  1963: {
    deathByAge: [
      2496,
      159,
      98,
      74,
      60,
      54,
      49,
      43,
      37,
      36,
      35,
      35,
      38,
      42,
      51,
      62,
      83,
      83,
      103,
      109,
      116,
      121,
      123,
      121,
      118,
      123,
      120,
      125,
      135,
      128,
      136,
      139,
      157,
      162,
      178,
      191,
      208,
      220,
      246,
      266,
      282,
      301,
      346,
      371,
      391,
      445,
      478,
      531,
      572,
      623,
      686,
      723,
      834,
      877,
      918,
      1005,
      1072,
      1156,
      1230,
      1314,
      1393,
      1394,
      1655,
      1687,
      1705,
      1987,
      1986,
      2121,
      2155,
      2230,
      2448,
      2403,
      2643,
      2690,
      2752,
      2891,
      2854,
      2816,
      2759,
      2859,
      3072,
      2909,
      2968,
      2978,
      2780,
      2673,
      2451,
      2285,
      2039,
      1793,
      1591,
      1279,
      1102,
      923,
      706,
      565,
      428,
      316,
      227,
      158,
      107,
      70,
      45,
      28,
      17,
      10,
      5,
      3,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.04
  },
  1964: {
    deathByAge: [
      2452,
      155,
      95,
      72,
      60,
      52,
      46,
      42,
      40,
      34,
      34,
      37,
      37,
      44,
      52,
      63,
      79,
      100,
      103,
      117,
      115,
      125,
      119,
      122,
      124,
      124,
      124,
      127,
      131,
      133,
      140,
      148,
      163,
      172,
      180,
      188,
      207,
      226,
      244,
      268,
      287,
      300,
      348,
      370,
      403,
      428,
      477,
      531,
      565,
      608,
      682,
      722,
      796,
      867,
      927,
      966,
      1067,
      1138,
      1207,
      1276,
      1378,
      1416,
      1545,
      1668,
      1727,
      1854,
      1957,
      2112,
      2143,
      2190,
      2352,
      2372,
      2620,
      2635,
      2736,
      2792,
      2849,
      2791,
      2877,
      2644,
      3056,
      2853,
      2971,
      2892,
      2915,
      2642,
      2530,
      2331,
      2101,
      1877,
      1678,
      1376,
      1162,
      976,
      806,
      634,
      488,
      367,
      269,
      191,
      132,
      89,
      58,
      37,
      23,
      13,
      8,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.33
  },
  1965: {
    deathByAge: [
      2382,
      148,
      94,
      71,
      61,
      51,
      45,
      43,
      38,
      35,
      34,
      35,
      38,
      42,
      49,
      64,
      79,
      94,
      117,
      113,
      116,
      125,
      128,
      119,
      119,
      121,
      125,
      128,
      133,
      135,
      143,
      150,
      161,
      166,
      177,
      197,
      200,
      226,
      244,
      266,
      292,
      306,
      345,
      371,
      402,
      447,
      456,
      530,
      569,
      615,
      689,
      716,
      808,
      847,
      937,
      976,
      1037,
      1142,
      1214,
      1282,
      1378,
      1431,
      1603,
      1595,
      1758,
      1888,
      1855,
      2097,
      2188,
      2248,
      2355,
      2325,
      2614,
      2623,
      2712,
      2837,
      2828,
      2864,
      2912,
      2872,
      3050,
      2866,
      2932,
      2910,
      2811,
      2724,
      2472,
      2313,
      2048,
      1873,
      1678,
      1329,
      1174,
      977,
      787,
      624,
      481,
      361,
      264,
      187,
      130,
      87,
      57,
      36,
      22,
      13,
      8,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.41
  },
  1966: {
    deathByAge: [
      2320,
      152,
      92,
      71,
      61,
      54,
      46,
      41,
      38,
      36,
      34,
      35,
      38,
      43,
      51,
      64,
      84,
      106,
      124,
      129,
      117,
      128,
      127,
      129,
      121,
      130,
      128,
      130,
      131,
      136,
      145,
      149,
      161,
      174,
      179,
      193,
      207,
      213,
      252,
      265,
      289,
      318,
      353,
      380,
      400,
      445,
      484,
      512,
      575,
      617,
      691,
      726,
      809,
      860,
      915,
      1002,
      1040,
      1105,
      1246,
      1309,
      1410,
      1423,
      1590,
      1655,
      1682,
      1921,
      1920,
      2001,
      2185,
      2218,
      2403,
      2321,
      2561,
      2670,
      2733,
      2857,
      2837,
      2867,
      2969,
      2915,
      3035,
      2860,
      2894,
      2867,
      2775,
      2651,
      2502,
      2279,
      2056,
      1827,
      1650,
      1380,
      1161,
      969,
      792,
      618,
      476,
      357,
      261,
      186,
      128,
      86,
      56,
      36,
      22,
      13,
      8,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.43
  },
  1967: {
    deathByAge: [
      2222,
      137,
      89,
      67,
      58,
      51,
      45,
      40,
      36,
      35,
      35,
      33,
      38,
      41,
      52,
      62,
      84,
      100,
      124,
      131,
      128,
      122,
      133,
      128,
      127,
      124,
      125,
      135,
      133,
      134,
      141,
      147,
      159,
      166,
      186,
      201,
      207,
      219,
      248,
      270,
      291,
      315,
      347,
      373,
      399,
      445,
      469,
      515,
      553,
      618,
      671,
      713,
      782,
      857,
      919,
      956,
      1062,
      1116,
      1187,
      1300,
      1367,
      1426,
      1551,
      1614,
      1697,
      1814,
      1941,
      2026,
      2015,
      2229,
      2352,
      2358,
      2527,
      2597,
      2738,
      2830,
      2836,
      2819,
      2895,
      2966,
      2966,
      2895,
      2894,
      2898,
      2801,
      2663,
      2537,
      2388,
      2066,
      1932,
      1699,
      1443,
      1251,
      1031,
      851,
      666,
      518,
      393,
      290,
      208,
      146,
      99,
      65,
      42,
      26,
      16,
      9,
      5,
      3,
      2,
      2
    ],
    lifeExpectancy: 70.76
  },
  1968: {
    deathByAge: [
      2180,
      140,
      88,
      68,
      57,
      53,
      48,
      42,
      39,
      35,
      34,
      36,
      36,
      45,
      52,
      67,
      90,
      111,
      130,
      133,
      131,
      146,
      131,
      135,
      130,
      137,
      130,
      136,
      136,
      143,
      151,
      155,
      164,
      172,
      182,
      205,
      212,
      232,
      253,
      266,
      307,
      322,
      356,
      389,
      406,
      454,
      488,
      533,
      573,
      617,
      696,
      728,
      804,
      850,
      934,
      998,
      1053,
      1157,
      1231,
      1270,
      1428,
      1457,
      1596,
      1645,
      1729,
      1900,
      1866,
      2124,
      2134,
      2118,
      2394,
      2379,
      2625,
      2579,
      2708,
      2854,
      2858,
      2861,
      2909,
      2921,
      2993,
      2787,
      2895,
      2841,
      2771,
      2631,
      2451,
      2291,
      2100,
      1854,
      1666,
      1390,
      1206,
      983,
      781,
      625,
      482,
      362,
      264,
      188,
      130,
      87,
      57,
      36,
      22,
      13,
      7,
      4,
      2,
      1,
      1
    ],
    lifeExpectancy: 70.42
  },
  1969: {
    deathByAge: [
      2129,
      130,
      87,
      69,
      59,
      50,
      45,
      42,
      39,
      38,
      33,
      34,
      38,
      43,
      55,
      70,
      94,
      112,
      138,
      141,
      137,
      141,
      151,
      135,
      140,
      135,
      135,
      131,
      147,
      143,
      148,
      157,
      163,
      175,
      187,
      198,
      215,
      236,
      249,
      274,
      298,
      316,
      364,
      385,
      417,
      457,
      481,
      524,
      558,
      611,
      654,
      714,
      779,
      839,
      914,
      969,
      1051,
      1115,
      1206,
      1267,
      1325,
      1445,
      1581,
      1605,
      1693,
      1837,
      1883,
      1960,
      2166,
      2169,
      2230,
      2370,
      2532,
      2612,
      2686,
      2793,
      2837,
      2852,
      2930,
      2921,
      2994,
      2844,
      2872,
      2875,
      2814,
      2650,
      2517,
      2330,
      2111,
      1942,
      1689,
      1471,
      1253,
      1054,
      860,
      675,
      526,
      400,
      296,
      213,
      149,
      101,
      67,
      43,
      27,
      16,
      9,
      5,
      3,
      2,
      2
    ],
    lifeExpectancy: 70.66
  },
  1970: {
    deathByAge: [
      2063,
      124,
      82,
      68,
      56,
      51,
      46,
      39,
      37,
      35,
      33,
      33,
      36,
      43,
      53,
      67,
      89,
      109,
      130,
      141,
      138,
      142,
      148,
      143,
      129,
      137,
      134,
      137,
      138,
      139,
      147,
      152,
      163,
      169,
      185,
      203,
      210,
      230,
      245,
      270,
      297,
      308,
      357,
      374,
      411,
      455,
      485,
      524,
      554,
      617,
      661,
      693,
      785,
      826,
      909,
      973,
      1033,
      1129,
      1184,
      1269,
      1339,
      1380,
      1559,
      1623,
      1697,
      1849,
      1852,
      1976,
      2019,
      2214,
      2278,
      2227,
      2511,
      2552,
      2704,
      2772,
      2826,
      2836,
      2939,
      2920,
      2929,
      2831,
      2870,
      2830,
      2771,
      2667,
      2523,
      2339,
      2139,
      1928,
      1799,
      1496,
      1321,
      1112,
      932,
      738,
      585,
      452,
      341,
      251,
      180,
      125,
      85,
      56,
      36,
      22,
      13,
      8,
      4,
      2,
      3
    ],
    lifeExpectancy: 70.92
  },
  1971: {
    deathByAge: [
      1890,
      119,
      80,
      66,
      56,
      53,
      44,
      40,
      36,
      34,
      34,
      33,
      35,
      41,
      52,
      69,
      90,
      111,
      132,
      137,
      134,
      141,
      147,
      140,
      142,
      131,
      136,
      137,
      137,
      141,
      147,
      149,
      158,
      168,
      181,
      193,
      212,
      226,
      247,
      263,
      285,
      305,
      339,
      377,
      398,
      438,
      487,
      513,
      546,
      595,
      650,
      687,
      743,
      820,
      882,
      945,
      1020,
      1092,
      1161,
      1228,
      1336,
      1385,
      1497,
      1622,
      1683,
      1812,
      1832,
      1933,
      2008,
      2068,
      2343,
      2327,
      2378,
      2555,
      2607,
      2812,
      2818,
      2845,
      2973,
      2995,
      2924,
      2897,
      2922,
      2858,
      2821,
      2751,
      2608,
      2422,
      2205,
      1996,
      1805,
      1568,
      1356,
      1181,
      943,
      756,
      596,
      458,
      343,
      251,
      178,
      123,
      82,
      53,
      34,
      21,
      12,
      7,
      4,
      2,
      2
    ],
    lifeExpectancy: 71.24
  },
  1972: {
    deathByAge: [
      1787,
      117,
      81,
      64,
      52,
      46,
      43,
      42,
      35,
      34,
      36,
      33,
      34,
      41,
      54,
      70,
      93,
      113,
      129,
      135,
      138,
      147,
      139,
      137,
      141,
      137,
      124,
      134,
      136,
      150,
      142,
      150,
      159,
      172,
      177,
      194,
      205,
      220,
      237,
      259,
      279,
      301,
      346,
      361,
      400,
      437,
      484,
      527,
      559,
      597,
      646,
      695,
      747,
      790,
      872,
      936,
      1013,
      1074,
      1155,
      1239,
      1337,
      1425,
      1527,
      1570,
      1721,
      1790,
      1847,
      1974,
      2024,
      2123,
      2229,
      2433,
      2491,
      2462,
      2714,
      2791,
      2846,
      2837,
      2940,
      3016,
      2969,
      2852,
      2899,
      2879,
      2823,
      2653,
      2604,
      2419,
      2192,
      2026,
      1795,
      1565,
      1366,
      1120,
      985,
      761,
      601,
      464,
      348,
      255,
      181,
      126,
      84,
      55,
      35,
      21,
      13,
      7,
      4,
      2,
      2
    ],
    lifeExpectancy: 71.34
  },
  1973: {
    deathByAge: [
      1764,
      118,
      80,
      61,
      54,
      48,
      43,
      41,
      35,
      35,
      33,
      33,
      36,
      42,
      53,
      68,
      94,
      110,
      135,
      137,
      143,
      143,
      142,
      138,
      136,
      135,
      135,
      127,
      142,
      139,
      153,
      144,
      157,
      164,
      172,
      192,
      203,
      220,
      237,
      258,
      285,
      296,
      329,
      358,
      378,
      429,
      473,
      510,
      558,
      590,
      639,
      677,
      734,
      780,
      840,
      915,
      993,
      1080,
      1131,
      1232,
      1333,
      1377,
      1534,
      1577,
      1657,
      1785,
      1818,
      1934,
      1992,
      2090,
      2272,
      2255,
      2569,
      2525,
      2542,
      2746,
      2753,
      2861,
      2911,
      2954,
      3031,
      2916,
      2929,
      2948,
      2889,
      2775,
      2606,
      2472,
      2287,
      2089,
      1880,
      1583,
      1405,
      1215,
      956,
      783,
      617,
      474,
      354,
      258,
      183,
      125,
      84,
      54,
      34,
      21,
      12,
      7,
      4,
      2,
      2
    ],
    lifeExpectancy: 71.54
  },
  1974: {
    deathByAge: [
      1702,
      110,
      72,
      61,
      48,
      42,
      39,
      37,
      33,
      32,
      29,
      30,
      35,
      42,
      50,
      62,
      86,
      106,
      125,
      134,
      129,
      138,
      134,
      134,
      131,
      132,
      127,
      135,
      122,
      133,
      135,
      143,
      146,
      159,
      167,
      174,
      190,
      205,
      224,
      242,
      262,
      282,
      320,
      338,
      376,
      409,
      452,
      486,
      535,
      582,
      620,
      660,
      719,
      763,
      818,
      868,
      962,
      1021,
      1113,
      1184,
      1297,
      1366,
      1461,
      1557,
      1631,
      1711,
      1798,
      1904,
      1986,
      2060,
      2208,
      2260,
      2407,
      2575,
      2583,
      2580,
      2751,
      2770,
      2898,
      2905,
      3011,
      2962,
      2970,
      2956,
      2993,
      2852,
      2736,
      2513,
      2413,
      2180,
      1953,
      1706,
      1510,
      1283,
      1082,
      866,
      689,
      535,
      405,
      298,
      213,
      148,
      100,
      66,
      42,
      26,
      15,
      9,
      5,
      3,
      3
    ],
    lifeExpectancy: 72.08
  },
  1975: {
    deathByAge: [
      1621,
      105,
      71,
      54,
      48,
      41,
      37,
      35,
      30,
      29,
      29,
      30,
      31,
      36,
      48,
      62,
      78,
      101,
      122,
      127,
      129,
      134,
      131,
      135,
      131,
      130,
      130,
      131,
      130,
      121,
      133,
      137,
      143,
      148,
      154,
      171,
      181,
      198,
      209,
      235,
      258,
      272,
      313,
      325,
      350,
      392,
      417,
      481,
      508,
      550,
      606,
      643,
      689,
      737,
      810,
      866,
      914,
      1020,
      1088,
      1146,
      1246,
      1299,
      1446,
      1499,
      1610,
      1678,
      1710,
      1885,
      1961,
      2027,
      2147,
      2205,
      2369,
      2375,
      2622,
      2634,
      2621,
      2798,
      2861,
      2940,
      2984,
      2915,
      3022,
      3005,
      2911,
      2847,
      2742,
      2600,
      2411,
      2265,
      2086,
      1827,
      1609,
      1417,
      1188,
      980,
      795,
      631,
      488,
      369,
      271,
      194,
      135,
      91,
      60,
      38,
      24,
      14,
      8,
      5,
      5
    ],
    lifeExpectancy: 72.68
  },
  1976: {
    deathByAge: [
      1539,
      102,
      69,
      55,
      45,
      41,
      36,
      33,
      30,
      28,
      28,
      26,
      30,
      37,
      46,
      56,
      77,
      96,
      118,
      123,
      124,
      125,
      128,
      123,
      125,
      123,
      121,
      122,
      123,
      127,
      118,
      129,
      135,
      142,
      149,
      159,
      169,
      186,
      209,
      221,
      245,
      262,
      291,
      323,
      347,
      386,
      414,
      449,
      497,
      542,
      590,
      632,
      691,
      735,
      782,
      848,
      931,
      980,
      1074,
      1145,
      1231,
      1308,
      1435,
      1506,
      1569,
      1676,
      1715,
      1825,
      1942,
      2054,
      2156,
      2177,
      2314,
      2419,
      2422,
      2735,
      2686,
      2727,
      2905,
      2927,
      3006,
      2931,
      3009,
      3071,
      2989,
      2876,
      2847,
      2702,
      2479,
      2325,
      2148,
      1867,
      1680,
      1442,
      1215,
      995,
      801,
      629,
      482,
      359,
      260,
      183,
      125,
      83,
      53,
      33,
      20,
      12,
      7,
      4,
      4
    ],
    lifeExpectancy: 72.99
  },
  1977: {
    deathByAge: [
      1458,
      100,
      70,
      51,
      42,
      40,
      35,
      30,
      31,
      29,
      27,
      29,
      31,
      36,
      45,
      60,
      81,
      97,
      121,
      128,
      128,
      129,
      130,
      123,
      125,
      122,
      124,
      121,
      122,
      126,
      127,
      120,
      138,
      136,
      152,
      158,
      173,
      183,
      199,
      214,
      228,
      260,
      290,
      314,
      337,
      370,
      398,
      432,
      476,
      539,
      577,
      618,
      681,
      727,
      765,
      850,
      900,
      976,
      1021,
      1124,
      1193,
      1267,
      1395,
      1473,
      1547,
      1633,
      1703,
      1806,
      1880,
      2030,
      2106,
      2185,
      2278,
      2393,
      2474,
      2549,
      2739,
      2744,
      2746,
      2939,
      2989,
      2957,
      2984,
      3036,
      3034,
      2898,
      2805,
      2706,
      2545,
      2372,
      2190,
      1977,
      1764,
      1513,
      1281,
      1078,
      881,
      703,
      548,
      417,
      308,
      222,
      155,
      106,
      70,
      45,
      28,
      17,
      10,
      6,
      6
    ],
    lifeExpectancy: 73.38
  },
  1978: {
    deathByAge: [
      1389,
      102,
      68,
      52,
      42,
      38,
      36,
      32,
      29,
      27,
      27,
      27,
      29,
      38,
      42,
      57,
      80,
      100,
      118,
      126,
      127,
      133,
      129,
      128,
      122,
      125,
      121,
      124,
      121,
      125,
      122,
      127,
      125,
      138,
      145,
      159,
      166,
      182,
      192,
      211,
      227,
      252,
      281,
      306,
      326,
      373,
      392,
      435,
      465,
      505,
      568,
      608,
      660,
      716,
      773,
      820,
      888,
      955,
      1041,
      1081,
      1185,
      1257,
      1372,
      1456,
      1545,
      1621,
      1685,
      1820,
      1892,
      1979,
      2104,
      2172,
      2318,
      2368,
      2488,
      2624,
      2614,
      2804,
      2795,
      2797,
      2997,
      3013,
      3053,
      3018,
      3021,
      2954,
      2877,
      2702,
      2594,
      2419,
      2252,
      1962,
      1796,
      1557,
      1314,
      1094,
      892,
      710,
      552,
      418,
      308,
      220,
      153,
      104,
      68,
      43,
      27,
      16,
      9,
      5,
      6
    ],
    lifeExpectancy: 73.58
  },
  1979: {
    deathByAge: [
      1336,
      93,
      63,
      51,
      42,
      38,
      34,
      28,
      28,
      26,
      25,
      25,
      29,
      34,
      42,
      57,
      80,
      99,
      120,
      125,
      127,
      128,
      130,
      128,
      125,
      127,
      125,
      127,
      125,
      123,
      125,
      128,
      133,
      129,
      142,
      155,
      169,
      172,
      189,
      201,
      218,
      237,
      267,
      293,
      323,
      342,
      392,
      415,
      449,
      492,
      540,
      591,
      643,
      699,
      750,
      800,
      846,
      924,
      995,
      1077,
      1128,
      1221,
      1336,
      1407,
      1505,
      1609,
      1655,
      1733,
      1862,
      1959,
      2048,
      2160,
      2286,
      2365,
      2457,
      2576,
      2629,
      2641,
      2846,
      2832,
      2964,
      3002,
      3034,
      3066,
      3047,
      2961,
      2937,
      2782,
      2582,
      2472,
      2312,
      2049,
      1828,
      1646,
      1418,
      1179,
      972,
      784,
      617,
      474,
      354,
      258,
      183,
      126,
      84,
      54,
      34,
      21,
      12,
      7,
      8
    ],
    lifeExpectancy: 74.03
  },
  1980: {
    deathByAge: [
      1279,
      97,
      64,
      50,
      38,
      34,
      32,
      30,
      28,
      27,
      25,
      25,
      28,
      31,
      41,
      55,
      81,
      98,
      121,
      123,
      127,
      131,
      129,
      130,
      128,
      128,
      127,
      128,
      128,
      124,
      127,
      128,
      132,
      141,
      139,
      152,
      162,
      174,
      187,
      203,
      224,
      241,
      263,
      284,
      311,
      354,
      366,
      412,
      463,
      480,
      538,
      589,
      634,
      694,
      746,
      810,
      870,
      915,
      1006,
      1081,
      1167,
      1202,
      1355,
      1402,
      1491,
      1614,
      1711,
      1797,
      1865,
      1986,
      2126,
      2150,
      2344,
      2427,
      2465,
      2616,
      2689,
      2759,
      2784,
      2827,
      3065,
      3012,
      3068,
      3069,
      3060,
      2980,
      2930,
      2798,
      2592,
      2424,
      2296,
      2044,
      1809,
      1578,
      1364,
      1119,
      912,
      726,
      563,
      426,
      313,
      224,
      155,
      105,
      68,
      43,
      27,
      16,
      9,
      5,
      6
    ],
    lifeExpectancy: 73.93
  },
  1981: {
    deathByAge: [
      1190,
      92,
      62,
      46,
      39,
      33,
      32,
      27,
      28,
      23,
      23,
      25,
      28,
      32,
      38,
      48,
      70,
      91,
      112,
      113,
      115,
      120,
      123,
      121,
      123,
      124,
      122,
      122,
      132,
      124,
      128,
      127,
      131,
      135,
      145,
      138,
      162,
      168,
      195,
      194,
      213,
      240,
      260,
      278,
      296,
      339,
      374,
      398,
      464,
      471,
      522,
      581,
      628,
      676,
      730,
      805,
      869,
      913,
      985,
      1055,
      1123,
      1231,
      1329,
      1409,
      1443,
      1572,
      1696,
      1779,
      1859,
      1922,
      2089,
      2173,
      2267,
      2439,
      2430,
      2563,
      2672,
      2705,
      2812,
      2692,
      3077,
      3089,
      3044,
      3067,
      3040,
      3015,
      2949,
      2817,
      2679,
      2496,
      2294,
      2112,
      1890,
      1659,
      1407,
      1186,
      976,
      785,
      616,
      472,
      352,
      255,
      180,
      123,
      82,
      53,
      33,
      20,
      12,
      7,
      8
    ],
    lifeExpectancy: 74.36
  },
  1982: {
    deathByAge: [
      1153,
      89,
      59,
      46,
      35,
      32,
      29,
      28,
      25,
      25,
      22,
      23,
      26,
      30,
      38,
      47,
      66,
      85,
      106,
      108,
      107,
      114,
      114,
      115,
      113,
      112,
      112,
      116,
      120,
      117,
      119,
      126,
      130,
      132,
      129,
      145,
      149,
      161,
      180,
      188,
      201,
      225,
      244,
      263,
      285,
      317,
      349,
      387,
      434,
      474,
      499,
      562,
      632,
      649,
      702,
      768,
      849,
      892,
      1001,
      1025,
      1111,
      1194,
      1376,
      1363,
      1445,
      1537,
      1653,
      1769,
      1888,
      1929,
      2044,
      2168,
      2280,
      2396,
      2437,
      2545,
      2645,
      2734,
      2782,
      2870,
      2952,
      3103,
      3119,
      3066,
      3066,
      2991,
      2963,
      2829,
      2670,
      2534,
      2327,
      2101,
      1927,
      1707,
      1485,
      1246,
      1034,
      839,
      665,
      514,
      387,
      284,
      203,
      141,
      95,
      62,
      39,
      24,
      15,
      8,
      10
    ],
    lifeExpectancy: 74.65
  },
  1983: {
    deathByAge: [
      1107,
      85,
      58,
      44,
      35,
      31,
      27,
      27,
      25,
      21,
      21,
      22,
      26,
      29,
      36,
      46,
      64,
      80,
      98,
      103,
      102,
      105,
      107,
      110,
      104,
      107,
      110,
      110,
      119,
      111,
      114,
      121,
      125,
      134,
      131,
      136,
      148,
      154,
      180,
      178,
      201,
      221,
      241,
      262,
      276,
      310,
      338,
      377,
      434,
      445,
      504,
      557,
      599,
      665,
      692,
      777,
      847,
      894,
      1005,
      1044,
      1104,
      1192,
      1347,
      1430,
      1426,
      1551,
      1622,
      1737,
      1890,
      1963,
      2099,
      2130,
      2312,
      2448,
      2461,
      2596,
      2671,
      2707,
      2820,
      2902,
      3017,
      2990,
      3172,
      3179,
      3060,
      3026,
      2972,
      2874,
      2673,
      2545,
      2376,
      2110,
      1918,
      1726,
      1455,
      1225,
      1009,
      811,
      637,
      487,
      362,
      262,
      185,
      126,
      83,
      54,
      33,
      20,
      12,
      7,
      8
    ],
    lifeExpectancy: 74.71
  },
  1984: {
    deathByAge: [
      1082,
      82,
      54,
      39,
      32,
      28,
      26,
      25,
      24,
      21,
      23,
      23,
      26,
      31,
      36,
      48,
      66,
      79,
      96,
      100,
      103,
      110,
      107,
      110,
      107,
      107,
      108,
      110,
      115,
      109,
      118,
      125,
      125,
      134,
      135,
      141,
      151,
      165,
      171,
      182,
      203,
      223,
      239,
      258,
      283,
      307,
      336,
      367,
      419,
      450,
      483,
      558,
      598,
      636,
      681,
      748,
      823,
      886,
      999,
      1042,
      1114,
      1176,
      1333,
      1396,
      1457,
      1522,
      1642,
      1704,
      1855,
      1959,
      2087,
      2163,
      2273,
      2418,
      2461,
      2535,
      2701,
      2708,
      2807,
      2891,
      3018,
      3031,
      3033,
      3172,
      3137,
      3034,
      2993,
      2889,
      2723,
      2566,
      2393,
      2171,
      1958,
      1717,
      1494,
      1255,
      1034,
      832,
      653,
      500,
      372,
      269,
      189,
      129,
      85,
      55,
      34,
      20,
      12,
      7,
      8
    ],
    lifeExpectancy: 74.81
  },
  1985: {
    deathByAge: [
      1083,
      80,
      54,
      39,
      32,
      28,
      27,
      23,
      23,
      22,
      20,
      22,
      26,
      30,
      38,
      49,
      68,
      78,
      99,
      100,
      98,
      108,
      107,
      108,
      107,
      108,
      107,
      110,
      120,
      111,
      120,
      125,
      134,
      139,
      138,
      148,
      158,
      163,
      188,
      171,
      200,
      220,
      250,
      254,
      277,
      307,
      335,
      364,
      425,
      435,
      490,
      541,
      601,
      640,
      675,
      763,
      805,
      879,
      1003,
      1024,
      1133,
      1195,
      1315,
      1377,
      1437,
      1581,
      1606,
      1703,
      1823,
      1925,
      2087,
      2176,
      2310,
      2392,
      2463,
      2581,
      2647,
      2761,
      2828,
      2884,
      3045,
      3070,
      3103,
      3083,
      3185,
      3111,
      2969,
      2875,
      2756,
      2588,
      2393,
      2148,
      1989,
      1730,
      1482,
      1228,
      1008,
      807,
      630,
      479,
      354,
      254,
      177,
      120,
      78,
      50,
      31,
      18,
      10,
      6,
      6
    ],
    lifeExpectancy: 74.79
  },
  1986: {
    deathByAge: [
      1042,
      80,
      54,
      41,
      33,
      26,
      25,
      23,
      22,
      21,
      21,
      22,
      26,
      29,
      40,
      53,
      75,
      88,
      105,
      104,
      110,
      114,
      111,
      112,
      112,
      113,
      113,
      117,
      123,
      124,
      131,
      136,
      141,
      152,
      150,
      160,
      166,
      169,
      189,
      191,
      193,
      221,
      244,
      268,
      275,
      307,
      337,
      354,
      410,
      417,
      480,
      536,
      582,
      631,
      668,
      730,
      793,
      835,
      971,
      1000,
      1100,
      1197,
      1315,
      1346,
      1416,
      1543,
      1644,
      1673,
      1839,
      1876,
      2052,
      2171,
      2342,
      2398,
      2414,
      2544,
      2680,
      2693,
      2837,
      2857,
      3039,
      3060,
      3120,
      3120,
      3050,
      3105,
      3040,
      2877,
      2736,
      2610,
      2452,
      2201,
      1993,
      1771,
      1498,
      1266,
      1042,
      838,
      657,
      501,
      372,
      268,
      188,
      127,
      84,
      53,
      33,
      20,
      11,
      6,
      7
    ],
    lifeExpectancy: 74.87
  },
  1987: {
    deathByAge: [
      1023,
      78,
      53,
      41,
      33,
      27,
      26,
      24,
      24,
      21,
      19,
      23,
      25,
      29,
      38,
      49,
      73,
      86,
      102,
      100,
      104,
      110,
      109,
      111,
      109,
      111,
      116,
      117,
      125,
      121,
      132,
      139,
      144,
      153,
      153,
      163,
      172,
      178,
      196,
      186,
      211,
      207,
      241,
      252,
      279,
      301,
      330,
      362,
      406,
      411,
      465,
      531,
      583,
      619,
      674,
      731,
      768,
      848,
      951,
      987,
      1088,
      1180,
      1300,
      1347,
      1384,
      1520,
      1617,
      1710,
      1796,
      1865,
      1969,
      2126,
      2327,
      2415,
      2429,
      2481,
      2658,
      2722,
      2796,
      2896,
      3031,
      3074,
      3098,
      3112,
      3087,
      3042,
      3095,
      2975,
      2793,
      2607,
      2460,
      2255,
      2027,
      1779,
      1564,
      1296,
      1067,
      856,
      670,
      510,
      377,
      271,
      189,
      127,
      83,
      53,
      32,
      19,
      11,
      6,
      7
    ],
    lifeExpectancy: 75.01
  },
  1988: {
    deathByAge: [
      1022,
      79,
      52,
      40,
      32,
      28,
      24,
      23,
      23,
      23,
      22,
      22,
      24,
      30,
      38,
      51,
      75,
      88,
      104,
      104,
      106,
      110,
      110,
      113,
      111,
      113,
      119,
      118,
      129,
      121,
      137,
      142,
      147,
      153,
      153,
      169,
      176,
      182,
      203,
      195,
      206,
      230,
      232,
      262,
      273,
      305,
      327,
      362,
      404,
      411,
      457,
      509,
      569,
      628,
      650,
      739,
      794,
      822,
      966,
      959,
      1087,
      1168,
      1270,
      1351,
      1389,
      1501,
      1600,
      1667,
      1847,
      1841,
      1980,
      2059,
      2282,
      2403,
      2464,
      2531,
      2612,
      2717,
      2816,
      2876,
      3067,
      3087,
      3150,
      3153,
      3163,
      3106,
      3005,
      3018,
      2841,
      2672,
      2482,
      2244,
      2033,
      1781,
      1530,
      1278,
      1046,
      834,
      648,
      489,
      359,
      255,
      176,
      117,
      76,
      47,
      29,
      17,
      10,
      5,
      6
    ],
    lifeExpectancy: 75.02
  },
  1989: {
    deathByAge: [
      1032,
      79,
      48,
      39,
      31,
      26,
      24,
      23,
      23,
      23,
      21,
      23,
      26,
      29,
      37,
      52,
      74,
      87,
      104,
      100,
      99,
      103,
      106,
      110,
      111,
      115,
      117,
      123,
      130,
      123,
      132,
      142,
      155,
      159,
      163,
      171,
      180,
      186,
      206,
      191,
      213,
      222,
      251,
      253,
      272,
      296,
      336,
      354,
      399,
      397,
      456,
      502,
      547,
      599,
      647,
      700,
      776,
      810,
      936,
      970,
      1033,
      1143,
      1237,
      1310,
      1374,
      1476,
      1543,
      1632,
      1796,
      1851,
      1936,
      2038,
      2222,
      2319,
      2420,
      2498,
      2626,
      2655,
      2772,
      2856,
      3002,
      3083,
      3118,
      3165,
      3143,
      3102,
      3013,
      2971,
      2870,
      2738,
      2523,
      2327,
      2099,
      1874,
      1606,
      1373,
      1135,
      915,
      719,
      549,
      408,
      294,
      206,
      139,
      91,
      58,
      36,
      21,
      12,
      7,
      7
    ],
    lifeExpectancy: 75.1
  },
  1990: {
    deathByAge: [
      975,
      73,
      46,
      35,
      29,
      26,
      23,
      21,
      20,
      19,
      19,
      19,
      23,
      28,
      39,
      52,
      74,
      92,
      109,
      104,
      102,
      107,
      109,
      111,
      108,
      115,
      117,
      122,
      128,
      127,
      135,
      141,
      148,
      156,
      158,
      172,
      180,
      187,
      205,
      194,
      210,
      223,
      239,
      261,
      254,
      294,
      324,
      355,
      393,
      395,
      445,
      492,
      543,
      570,
      626,
      711,
      738,
      808,
      921,
      918,
      1039,
      1102,
      1231,
      1297,
      1347,
      1478,
      1553,
      1611,
      1757,
      1797,
      1955,
      2001,
      2159,
      2288,
      2365,
      2455,
      2618,
      2704,
      2704,
      2818,
      3037,
      3061,
      3114,
      3143,
      3144,
      3110,
      3092,
      2979,
      2847,
      2763,
      2610,
      2362,
      2151,
      1952,
      1668,
      1420,
      1182,
      961,
      761,
      587,
      440,
      320,
      226,
      155,
      103,
      66,
      41,
      25,
      14,
      8,
      9
    ],
    lifeExpectancy: 75.4
  },
  1991: {
    deathByAge: [
      927,
      74,
      48,
      37,
      28,
      26,
      22,
      19,
      20,
      19,
      19,
      21,
      22,
      27,
      39,
      51,
      77,
      88,
      109,
      105,
      107,
      111,
      110,
      106,
      105,
      110,
      114,
      121,
      126,
      122,
      136,
      145,
      145,
      156,
      161,
      170,
      181,
      190,
      208,
      199,
      217,
      231,
      244,
      250,
      278,
      280,
      332,
      342,
      403,
      394,
      440,
      485,
      527,
      566,
      602,
      683,
      747,
      785,
      910,
      933,
      1012,
      1124,
      1175,
      1266,
      1350,
      1436,
      1538,
      1624,
      1711,
      1806,
      1898,
      2062,
      2101,
      2297,
      2352,
      2430,
      2571,
      2673,
      2704,
      2775,
      3017,
      3082,
      3109,
      3177,
      3155,
      3129,
      3081,
      3020,
      2901,
      2766,
      2606,
      2444,
      2188,
      1960,
      1731,
      1453,
      1211,
      985,
      781,
      603,
      452,
      330,
      233,
      160,
      106,
      68,
      42,
      25,
      15,
      8,
      9
    ],
    lifeExpectancy: 75.5
  },
  1992: {
    deathByAge: [
      872,
      67,
      45,
      33,
      27,
      24,
      20,
      20,
      19,
      18,
      17,
      19,
      22,
      27,
      37,
      54,
      74,
      84,
      99,
      99,
      99,
      108,
      104,
      103,
      101,
      106,
      109,
      115,
      126,
      121,
      131,
      140,
      147,
      158,
      161,
      173,
      181,
      186,
      213,
      204,
      222,
      238,
      251,
      264,
      266,
      301,
      312,
      347,
      383,
      402,
      426,
      477,
      522,
      562,
      595,
      660,
      723,
      774,
      885,
      918,
      1008,
      1072,
      1180,
      1236,
      1311,
      1415,
      1506,
      1619,
      1748,
      1762,
      1893,
      2011,
      2139,
      2221,
      2356,
      2418,
      2510,
      2636,
      2696,
      2788,
      2968,
      3074,
      3162,
      3139,
      3180,
      3169,
      3100,
      3028,
      2942,
      2794,
      2627,
      2438,
      2279,
      2020,
      1781,
      1499,
      1255,
      1025,
      817,
      634,
      478,
      351,
      249,
      172,
      115,
      74,
      47,
      28,
      17,
      9,
      11
    ],
    lifeExpectancy: 75.8
  },
  1993: {
    deathByAge: [
      854,
      68,
      46,
      36,
      28,
      22,
      23,
      21,
      21,
      18,
      17,
      18,
      22,
      29,
      40,
      51,
      76,
      85,
      106,
      104,
      103,
      109,
      107,
      108,
      103,
      105,
      108,
      114,
      128,
      127,
      136,
      146,
      154,
      162,
      166,
      170,
      188,
      198,
      217,
      212,
      229,
      245,
      256,
      273,
      276,
      293,
      332,
      338,
      388,
      391,
      445,
      478,
      522,
      570,
      594,
      655,
      718,
      776,
      894,
      913,
      1012,
      1103,
      1174,
      1253,
      1295,
      1426,
      1500,
      1605,
      1771,
      1812,
      1896,
      2028,
      2121,
      2292,
      2315,
      2447,
      2563,
      2645,
      2754,
      2824,
      3061,
      3106,
      3185,
      3221,
      3180,
      3201,
      3150,
      3064,
      2947,
      2838,
      2623,
      2377,
      2212,
      1989,
      1711,
      1429,
      1180,
      950,
      745,
      567,
      420,
      301,
      209,
      141,
      92,
      58,
      35,
      21,
      12,
      6,
      7
    ],
    lifeExpectancy: 75.5
  },
  1994: {
    deathByAge: [
      821,
      65,
      44,
      33,
      28,
      22,
      21,
      19,
      19,
      17,
      18,
      18,
      22,
      27,
      39,
      52,
      73,
      89,
      103,
      106,
      103,
      109,
      106,
      105,
      103,
      103,
      108,
      113,
      126,
      125,
      136,
      147,
      154,
      167,
      168,
      178,
      186,
      201,
      215,
      216,
      230,
      249,
      261,
      273,
      285,
      305,
      323,
      361,
      366,
      394,
      426,
      491,
      518,
      567,
      597,
      644,
      699,
      757,
      857,
      901,
      979,
      1093,
      1167,
      1214,
      1303,
      1366,
      1484,
      1583,
      1712,
      1822,
      1904,
      2004,
      2110,
      2215,
      2324,
      2365,
      2551,
      2631,
      2699,
      2821,
      3066,
      3113,
      3159,
      3241,
      3250,
      3228,
      3183,
      3076,
      2996,
      2829,
      2692,
      2444,
      2238,
      1997,
      1748,
      1464,
      1213,
      979,
      770,
      589,
      437,
      315,
      220,
      149,
      97,
      62,
      38,
      22,
      13,
      7,
      8
    ],
    lifeExpectancy: 75.7
  },
  1995: {
    deathByAge: [
      777,
      63,
      41,
      32,
      25,
      21,
      20,
      19,
      19,
      17,
      17,
      19,
      22,
      28,
      38,
      49,
      72,
      85,
      100,
      101,
      102,
      105,
      104,
      103,
      101,
      100,
      106,
      110,
      122,
      123,
      134,
      149,
      151,
      157,
      169,
      174,
      188,
      199,
      217,
      218,
      228,
      253,
      266,
      281,
      287,
      303,
      326,
      345,
      397,
      372,
      430,
      471,
      524,
      555,
      591,
      639,
      706,
      757,
      830,
      885,
      981,
      1072,
      1164,
      1222,
      1271,
      1374,
      1444,
      1557,
      1688,
      1796,
      1908,
      2023,
      2100,
      2219,
      2291,
      2439,
      2494,
      2651,
      2722,
      2800,
      3068,
      3127,
      3210,
      3221,
      3267,
      3252,
      3233,
      3145,
      3020,
      2885,
      2700,
      2480,
      2244,
      2026,
      1746,
      1470,
      1213,
      975,
      763,
      580,
      428,
      306,
      212,
      142,
      92,
      57,
      35,
      20,
      11,
      6,
      7
    ],
    lifeExpectancy: 75.8
  },
  1996: {
    deathByAge: [
      755,
      58,
      38,
      32,
      24,
      21,
      20,
      18,
      18,
      17,
      17,
      18,
      21,
      26,
      34,
      45,
      70,
      79,
      94,
      98,
      97,
      102,
      99,
      95,
      93,
      98,
      97,
      99,
      106,
      111,
      112,
      129,
      139,
      143,
      150,
      156,
      172,
      180,
      196,
      198,
      215,
      238,
      243,
      263,
      276,
      297,
      320,
      342,
      366,
      390,
      386,
      463,
      496,
      554,
      577,
      627,
      689,
      739,
      827,
      864,
      951,
      1068,
      1155,
      1230,
      1286,
      1355,
      1449,
      1520,
      1679,
      1781,
      1889,
      2046,
      2118,
      2191,
      2294,
      2382,
      2582,
      2608,
      2773,
      2841,
      3087,
      3156,
      3220,
      3293,
      3316,
      3307,
      3265,
      3160,
      3071,
      2889,
      2749,
      2540,
      2318,
      2012,
      1766,
      1493,
      1232,
      991,
      775,
      589,
      434,
      310,
      214,
      143,
      93,
      58,
      35,
      20,
      11,
      6,
      7
    ],
    lifeExpectancy: 76.1
  },
  1997: {
    deathByAge: [
      744,
      56,
      35,
      29,
      22,
      21,
      18,
      17,
      17,
      16,
      17,
      17,
      21,
      25,
      32,
      41,
      62,
      78,
      90,
      94,
      92,
      100,
      96,
      95,
      90,
      92,
      94,
      96,
      96,
      96,
      103,
      112,
      118,
      127,
      134,
      141,
      151,
      162,
      174,
      179,
      199,
      219,
      226,
      250,
      260,
      279,
      305,
      324,
      360,
      371,
      408,
      424,
      490,
      527,
      576,
      610,
      680,
      737,
      806,
      851,
      918,
      1032,
      1128,
      1189,
      1289,
      1354,
      1433,
      1529,
      1648,
      1756,
      1874,
      2015,
      2137,
      2207,
      2258,
      2399,
      2518,
      2700,
      2726,
      2880,
      3086,
      3191,
      3260,
      3326,
      3332,
      3337,
      3328,
      3264,
      3094,
      2977,
      2833,
      2578,
      2336,
      2099,
      1811,
      1514,
      1248,
      1001,
      781,
      591,
      434,
      309,
      212,
      141,
      91,
      56,
      34,
      20,
      11,
      6,
      6
    ],
    lifeExpectancy: 76.5
  },
  1998: {
    deathByAge: [
      749,
      54,
      35,
      26,
      21,
      20,
      17,
      18,
      16,
      15,
      16,
      17,
      20,
      24,
      30,
      40,
      59,
      70,
      89,
      88,
      88,
      95,
      91,
      91,
      89,
      88,
      88,
      92,
      89,
      92,
      96,
      107,
      110,
      122,
      129,
      135,
      145,
      158,
      169,
      179,
      189,
      211,
      225,
      248,
      268,
      276,
      301,
      321,
      354,
      366,
      389,
      456,
      449,
      521,
      533,
      622,
      665,
      716,
      786,
      833,
      904,
      1012,
      1094,
      1187,
      1246,
      1373,
      1461,
      1490,
      1634,
      1713,
      1845,
      2019,
      2142,
      2216,
      2312,
      2376,
      2538,
      2644,
      2803,
      2831,
      3127,
      3212,
      3300,
      3375,
      3389,
      3369,
      3335,
      3290,
      3159,
      3012,
      2847,
      2589,
      2364,
      2108,
      1828,
      1523,
      1252,
      1003,
      780,
      589,
      431,
      306,
      210,
      139,
      89,
      55,
      33,
      19,
      11,
      6,
      6
    ],
    lifeExpectancy: 76.7
  },
  1999: {
    deathByAge: [
      732,
      53,
      36,
      27,
      21,
      19,
      17,
      16,
      16,
      15,
      16,
      17,
      18,
      21,
      29,
      38,
      58,
      71,
      84,
      87,
      88,
      93,
      91,
      90,
      87,
      83,
      87,
      88,
      90,
      94,
      94,
      103,
      110,
      117,
      125,
      132,
      142,
      155,
      171,
      178,
      186,
      212,
      225,
      249,
      269,
      279,
      312,
      328,
      355,
      376,
      388,
      429,
      483,
      507,
      554,
      603,
      682,
      717,
      787,
      851,
      892,
      1002,
      1070,
      1151,
      1266,
      1344,
      1450,
      1490,
      1613,
      1746,
      1794,
      1980,
      2128,
      2214,
      2309,
      2421,
      2514,
      2674,
      2799,
      2970,
      3128,
      3235,
      3345,
      3402,
      3421,
      3438,
      3401,
      3314,
      3231,
      3054,
      2832,
      2652,
      2369,
      2055,
      1796,
      1489,
      1212,
      959,
      737,
      548,
      395,
      275,
      185,
      120,
      75,
      45,
      26,
      15,
      8,
      4,
      4
    ],
    lifeExpectancy: 76.7
  },
  2000: {
    deathByAge: [
      725,
      51,
      34,
      24,
      20,
      19,
      16,
      15,
      15,
      14,
      15,
      18,
      18,
      21,
      28,
      38,
      54,
      67,
      82,
      88,
      91,
      96,
      91,
      91,
      89,
      87,
      87,
      89,
      89,
      94,
      93,
      103,
      106,
      115,
      123,
      128,
      141,
      154,
      168,
      179,
      194,
      212,
      225,
      253,
      266,
      285,
      309,
      334,
      364,
      383,
      403,
      434,
      458,
      556,
      522,
      599,
      642,
      734,
      769,
      837,
      890,
      972,
      1069,
      1135,
      1225,
      1322,
      1400,
      1502,
      1597,
      1676,
      1813,
      1914,
      2077,
      2176,
      2265,
      2425,
      2532,
      2634,
      2777,
      2941,
      3191,
      3188,
      3328,
      3387,
      3444,
      3469,
      3436,
      3367,
      3231,
      3110,
      2910,
      2670,
      2442,
      2124,
      1824,
      1511,
      1230,
      973,
      746,
      555,
      399,
      277,
      186,
      120,
      75,
      45,
      26,
      15,
      8,
      4,
      4
    ],
    lifeExpectancy: 76.8
  },
  2001: {
    deathByAge: [
      696,
      51,
      33,
      26,
      21,
      17,
      16,
      14,
      14,
      15,
      15,
      16,
      17,
      21,
      27,
      36,
      53,
      67,
      82,
      90,
      90,
      99,
      93,
      94,
      93,
      90,
      94,
      92,
      97,
      97,
      102,
      103,
      108,
      118,
      124,
      135,
      145,
      160,
      174,
      185,
      198,
      213,
      223,
      253,
      270,
      289,
      313,
      337,
      360,
      393,
      407,
      436,
      462,
      504,
      573,
      560,
      638,
      698,
      789,
      814,
      884,
      946,
      1030,
      1124,
      1188,
      1278,
      1382,
      1457,
      1570,
      1677,
      1766,
      1899,
      1990,
      2168,
      2250,
      2369,
      2532,
      2635,
      2715,
      2920,
      3072,
      3272,
      3309,
      3398,
      3456,
      3494,
      3471,
      3417,
      3300,
      3157,
      2942,
      2715,
      2440,
      2197,
      1870,
      1554,
      1266,
      1003,
      770,
      572,
      412,
      286,
      192,
      124,
      77,
      46,
      27,
      15,
      8,
      4,
      4
    ],
    lifeExpectancy: 76.9
  },
  2002: {
    deathByAge: [
      701,
      48,
      32,
      24,
      20,
      17,
      14,
      14,
      15,
      14,
      15,
      15,
      18,
      22,
      26,
      34,
      56,
      67,
      83,
      92,
      91,
      97,
      93,
      93,
      94,
      91,
      95,
      92,
      93,
      99,
      102,
      106,
      107,
      116,
      123,
      133,
      141,
      155,
      168,
      185,
      202,
      214,
      231,
      250,
      273,
      292,
      314,
      335,
      367,
      388,
      419,
      441,
      468,
      506,
      537,
      615,
      599,
      681,
      742,
      831,
      876,
      929,
      1020,
      1088,
      1185,
      1246,
      1331,
      1457,
      1520,
      1655,
      1783,
      1864,
      1980,
      2097,
      2251,
      2385,
      2470,
      2630,
      2733,
      2844,
      3114,
      3186,
      3405,
      3393,
      3483,
      3497,
      3531,
      3446,
      3350,
      3214,
      2988,
      2769,
      2507,
      2221,
      1912,
      1564,
      1271,
      1002,
      766,
      567,
      405,
      280,
      186,
      119,
      74,
      44,
      25,
      14,
      7,
      4,
      4
    ],
    lifeExpectancy: 76.9
  },
  2003: {
    deathByAge: [
      699,
      47,
      33,
      26,
      20,
      17,
      15,
      14,
      14,
      13,
      16,
      14,
      17,
      21,
      25,
      33,
      52,
      64,
      84,
      89,
      94,
      95,
      98,
      96,
      95,
      92,
      96,
      94,
      93,
      99,
      100,
      104,
      109,
      116,
      120,
      132,
      142,
      153,
      165,
      179,
      196,
      214,
      230,
      248,
      271,
      290,
      321,
      338,
      366,
      391,
      422,
      450,
      473,
      496,
      547,
      579,
      654,
      638,
      737,
      802,
      896,
      925,
      1007,
      1071,
      1162,
      1237,
      1303,
      1412,
      1513,
      1600,
      1747,
      1816,
      1949,
      2074,
      2164,
      2363,
      2467,
      2575,
      2709,
      2842,
      3064,
      3186,
      3268,
      3476,
      3443,
      3510,
      3535,
      3492,
      3404,
      3260,
      3067,
      2829,
      2574,
      2278,
      1936,
      1628,
      1325,
      1046,
      800,
      591,
      422,
      291,
      193,
      124,
      76,
      45,
      26,
      14,
      8,
      4,
      4
    ],
    lifeExpectancy: 77.1
  },
  2004: {
    deathByAge: [
      693,
      49,
      30,
      22,
      19,
      17,
      15,
      14,
      13,
      14,
      14,
      14,
      17,
      20,
      27,
      35,
      52,
      64,
      82,
      88,
      89,
      97,
      95,
      92,
      94,
      94,
      97,
      98,
      93,
      100,
      92,
      102,
      109,
      111,
      119,
      128,
      134,
      148,
      157,
      170,
      189,
      205,
      226,
      242,
      258,
      286,
      313,
      337,
      356,
      381,
      416,
      444,
      477,
      499,
      530,
      569,
      601,
      691,
      674,
      765,
      834,
      931,
      966,
      1038,
      1117,
      1193,
      1287,
      1362,
      1450,
      1556,
      1664,
      1776,
      1900,
      1991,
      2140,
      2259,
      2404,
      2535,
      2654,
      2780,
      3004,
      3133,
      3282,
      3348,
      3540,
      3467,
      3534,
      3515,
      3450,
      3326,
      3142,
      2925,
      2654,
      2403,
      2102,
      1742,
      1438,
      1153,
      896,
      675,
      491,
      346,
      234,
      153,
      97,
      59,
      34,
      19,
      11,
      6,
      5
    ],
    lifeExpectancy: 77.5
  },
  2005: {
    deathByAge: [
      704,
      48,
      31,
      22,
      18,
      15,
      16,
      14,
      14,
      14,
      12,
      14,
      15,
      20,
      26,
      34,
      48,
      64,
      80,
      90,
      90,
      98,
      99,
      98,
      97,
      97,
      99,
      98,
      100,
      96,
      101,
      105,
      112,
      113,
      120,
      124,
      131,
      145,
      157,
      172,
      188,
      203,
      227,
      245,
      265,
      286,
      307,
      331,
      362,
      392,
      423,
      447,
      491,
      513,
      534,
      574,
      602,
      649,
      735,
      724,
      829,
      876,
      1004,
      1032,
      1108,
      1167,
      1249,
      1353,
      1423,
      1521,
      1669,
      1749,
      1886,
      2025,
      2110,
      2246,
      2336,
      2542,
      2674,
      2780,
      3019,
      3139,
      3256,
      3370,
      3438,
      3614,
      3524,
      3547,
      3496,
      3375,
      3216,
      2975,
      2716,
      2403,
      2082,
      1743,
      1430,
      1138,
      877,
      654,
      471,
      327,
      219,
      141,
      87,
      52,
      30,
      17,
      9,
      5,
      4
    ],
    lifeExpectancy: 77.4
  },
  2006: {
    deathByAge: [
      698,
      46,
      30,
      21,
      18,
      17,
      14,
      13,
      13,
      12,
      12,
      14,
      14,
      18,
      23,
      32,
      48,
      62,
      81,
      90,
      93,
      102,
      98,
      101,
      102,
      101,
      100,
      104,
      100,
      105,
      102,
      110,
      108,
      114,
      121,
      131,
      133,
      140,
      156,
      171,
      184,
      199,
      222,
      242,
      259,
      281,
      299,
      328,
      362,
      386,
      415,
      442,
      478,
      508,
      542,
      572,
      595,
      645,
      697,
      783,
      771,
      852,
      934,
      1041,
      1065,
      1139,
      1213,
      1308,
      1395,
      1488,
      1606,
      1712,
      1796,
      1957,
      2082,
      2199,
      2343,
      2451,
      2612,
      2742,
      2955,
      3102,
      3239,
      3314,
      3428,
      3471,
      3632,
      3510,
      3525,
      3413,
      3273,
      3070,
      2820,
      2509,
      2182,
      1844,
      1528,
      1229,
      958,
      723,
      527,
      371,
      251,
      164,
      103,
      63,
      36,
      20,
      11,
      6,
      6
    ],
    lifeExpectancy: 77.7
  },
  2007: {
    deathByAge: [
      703,
      48,
      29,
      22,
      17,
      15,
      14,
      13,
      13,
      13,
      13,
      13,
      14,
      18,
      23,
      31,
      45,
      58,
      79,
      87,
      88,
      97,
      101,
      97,
      101,
      95,
      99,
      99,
      102,
      105,
      104,
      106,
      108,
      111,
      120,
      126,
      133,
      141,
      153,
      162,
      179,
      193,
      207,
      231,
      258,
      276,
      298,
      321,
      348,
      374,
      413,
      443,
      468,
      500,
      531,
      577,
      597,
      641,
      678,
      728,
      831,
      792,
      904,
      983,
      1098,
      1130,
      1199,
      1270,
      1356,
      1481,
      1560,
      1657,
      1810,
      1889,
      2043,
      2203,
      2285,
      2435,
      2540,
      2718,
      2900,
      3062,
      3205,
      3315,
      3421,
      3521,
      3548,
      3633,
      3500,
      3440,
      3319,
      3136,
      2876,
      2584,
      2276,
      1921,
      1602,
      1296,
      1017,
      772,
      567,
      401,
      274,
      180,
      114,
      70,
      41,
      23,
      13,
      7,
      6
    ],
    lifeExpectancy: 77.9
  },
  2008: {
    deathByAge: [
      679,
      48,
      29,
      22,
      17,
      14,
      12,
      12,
      12,
      11,
      12,
      12,
      14,
      16,
      21,
      30,
      41,
      53,
      72,
      80,
      87,
      91,
      95,
      94,
      93,
      96,
      94,
      96,
      100,
      103,
      104,
      107,
      106,
      111,
      114,
      122,
      127,
      142,
      148,
      155,
      171,
      190,
      204,
      223,
      251,
      270,
      296,
      321,
      343,
      377,
      412,
      436,
      470,
      497,
      534,
      580,
      610,
      636,
      677,
      724,
      799,
      883,
      854,
      971,
      1065,
      1164,
      1193,
      1272,
      1353,
      1471,
      1581,
      1643,
      1800,
      1926,
      2017,
      2201,
      2295,
      2435,
      2584,
      2696,
      2878,
      3037,
      3178,
      3338,
      3437,
      3519,
      3596,
      3563,
      3657,
      3450,
      3360,
      3154,
      2913,
      2611,
      2305,
      1926,
      1598,
      1287,
      1003,
      756,
      550,
      385,
      260,
      169,
      106,
      63,
      37,
      20,
      11,
      6,
      5
    ],
    lifeExpectancy: 77.8
  },
  2009: {
    deathByAge: [
      652,
      43,
      28,
      22,
      16,
      14,
      13,
      11,
      11,
      13,
      11,
      14,
      14,
      16,
      20,
      27,
      39,
      50,
      67,
      72,
      79,
      89,
      85,
      92,
      91,
      90,
      96,
      93,
      98,
      104,
      103,
      106,
      108,
      112,
      118,
      123,
      130,
      138,
      148,
      156,
      173,
      189,
      204,
      218,
      244,
      266,
      293,
      319,
      343,
      365,
      404,
      435,
      480,
      508,
      530,
      574,
      611,
      653,
      678,
      722,
      791,
      818,
      924,
      943,
      1024,
      1101,
      1207,
      1253,
      1336,
      1404,
      1532,
      1626,
      1726,
      1846,
      1995,
      2118,
      2231,
      2379,
      2530,
      2619,
      2791,
      2947,
      3134,
      3249,
      3387,
      3480,
      3563,
      3587,
      3536,
      3558,
      3347,
      3191,
      3000,
      2744,
      2413,
      2045,
      1725,
      1414,
      1124,
      865,
      644,
      463,
      321,
      214,
      138,
      86,
      51,
      29,
      16,
      9,
      9
    ],
    lifeExpectancy: 78.352
  },
  2010: {
    deathByAge: [
      616,
      43,
      27,
      21,
      16,
      12,
      11,
      11,
      11,
      11,
      10,
      13,
      13,
      15,
      20,
      25,
      37,
      46,
      64,
      70,
      76,
      86,
      85,
      87,
      93,
      91,
      93,
      91,
      97,
      99,
      103,
      106,
      108,
      111,
      111,
      119,
      126,
      133,
      141,
      153,
      160,
      177,
      192,
      207,
      230,
      251,
      286,
      309,
      332,
      364,
      396,
      421,
      464,
      497,
      523,
      560,
      607,
      645,
      692,
      728,
      768,
      811,
      861,
      1018,
      984,
      1079,
      1149,
      1281,
      1338,
      1421,
      1525,
      1607,
      1752,
      1829,
      1960,
      2149,
      2222,
      2378,
      2524,
      2608,
      2791,
      2937,
      3114,
      3233,
      3404,
      3535,
      3572,
      3594,
      3611,
      3524,
      3533,
      3202,
      3048,
      2756,
      2453,
      2075,
      1747,
      1427,
      1130,
      866,
      641,
      458,
      315,
      209,
      133,
      82,
      48,
      27,
      15,
      8,
      8
    ],
    lifeExpectancy: 78.507
  },
  2011: {
    deathByAge: [
      604,
      42,
      26,
      20,
      16,
      14,
      12,
      11,
      12,
      11,
      11,
      11,
      13,
      15,
      20,
      24,
      36,
      46,
      62,
      72,
      74,
      84,
      89,
      91,
      89,
      93,
      95,
      92,
      96,
      100,
      102,
      109,
      111,
      114,
      119,
      119,
      128,
      132,
      143,
      153,
      168,
      176,
      194,
      211,
      226,
      249,
      277,
      306,
      332,
      365,
      397,
      426,
      463,
      496,
      534,
      568,
      610,
      648,
      682,
      732,
      770,
      808,
      859,
      938,
      1069,
      998,
      1120,
      1217,
      1361,
      1395,
      1511,
      1589,
      1715,
      1844,
      1932,
      2078,
      2260,
      2328,
      2508,
      2633,
      2769,
      2966,
      3101,
      3255,
      3382,
      3501,
      3572,
      3634,
      3611,
      3599,
      3465,
      3375,
      3059,
      2782,
      2462,
      2101,
      1767,
      1442,
      1140,
      872,
      644,
      458,
      314,
      207,
      132,
      80,
      47,
      27,
      14,
      8,
      7
    ],
    lifeExpectancy: 78.654
  },
  2012: {
    deathByAge: [
      596,
      41,
      27,
      20,
      17,
      13,
      11,
      11,
      10,
      11,
      11,
      11,
      12,
      15,
      20,
      26,
      34,
      43,
      61,
      67,
      74,
      81,
      88,
      85,
      90,
      91,
      94,
      94,
      99,
      104,
      105,
      108,
      107,
      115,
      118,
      124,
      129,
      135,
      140,
      152,
      165,
      179,
      189,
      204,
      223,
      243,
      269,
      298,
      329,
      355,
      394,
      429,
      457,
      487,
      528,
      577,
      611,
      646,
      691,
      735,
      784,
      824,
      869,
      929,
      980,
      1101,
      1068,
      1199,
      1300,
      1425,
      1472,
      1582,
      1677,
      1781,
      1931,
      2034,
      2190,
      2369,
      2441,
      2656,
      2722,
      2883,
      3083,
      3216,
      3361,
      3480,
      3569,
      3633,
      3663,
      3607,
      3547,
      3311,
      3151,
      2794,
      2562,
      2159,
      1822,
      1493,
      1184,
      909,
      673,
      481,
      331,
      219,
      139,
      85,
      50,
      28,
      15,
      8,
      8
    ],
    lifeExpectancy: 78.797
  },
  2013: {
    deathByAge: [
      592,
      42,
      25,
      19,
      16,
      14,
      12,
      11,
      11,
      11,
      10,
      11,
      13,
      16,
      19,
      25,
      32,
      43,
      55,
      65,
      72,
      80,
      83,
      88,
      89,
      89,
      95,
      98,
      98,
      100,
      106,
      108,
      110,
      116,
      123,
      128,
      130,
      136,
      143,
      152,
      166,
      176,
      195,
      206,
      222,
      242,
      270,
      297,
      322,
      359,
      390,
      427,
      457,
      491,
      528,
      575,
      620,
      652,
      699,
      736,
      790,
      837,
      882,
      932,
      995,
      1048,
      1186,
      1133,
      1284,
      1393,
      1531,
      1579,
      1680,
      1788,
      1921,
      2050,
      2167,
      2339,
      2503,
      2583,
      2695,
      2862,
      3045,
      3199,
      3339,
      3475,
      3553,
      3642,
      3642,
      3607,
      3512,
      3339,
      3119,
      2921,
      2516,
      2172,
      1836,
      1506,
      1197,
      920,
      682,
      488,
      336,
      223,
      142,
      87,
      51,
      29,
      16,
      8,
      8
    ],
    lifeExpectancy: 78.941
  },
  2014: {
    deathByAge: [
      583,
      37,
      25,
      18,
      16,
      13,
      11,
      11,
      11,
      11,
      10,
      11,
      13,
      16,
      19,
      24,
      34,
      43,
      57,
      66,
      72,
      81,
      87,
      86,
      90,
      93,
      97,
      96,
      101,
      104,
      103,
      113,
      114,
      118,
      127,
      133,
      137,
      144,
      146,
      157,
      165,
      185,
      194,
      208,
      223,
      241,
      266,
      292,
      322,
      354,
      386,
      428,
      456,
      500,
      530,
      579,
      623,
      670,
      702,
      744,
      796,
      856,
      903,
      949,
      983,
      1048,
      1111,
      1255,
      1209,
      1355,
      1466,
      1627,
      1670,
      1779,
      1885,
      2034,
      2169,
      2286,
      2437,
      2609,
      2676,
      2809,
      2970,
      3113,
      3313,
      3378,
      3491,
      3584,
      3581,
      3559,
      3515,
      3375,
      3184,
      2902,
      2653,
      2219,
      1891,
      1565,
      1256,
      975,
      732,
      530,
      369,
      248,
      160,
      100,
      60,
      34,
      19,
      10,
      10
    ],
    lifeExpectancy: 79.087
  },
  2015: {
    deathByAge: [
      587,
      40,
      25,
      19,
      15,
      13,
      13,
      11,
      11,
      10,
      11,
      11,
      13,
      17,
      21,
      26,
      35,
      47,
      61,
      71,
      77,
      86,
      91,
      92,
      97,
      100,
      98,
      105,
      107,
      114,
      115,
      118,
      126,
      130,
      134,
      138,
      145,
      150,
      158,
      160,
      170,
      183,
      198,
      208,
      233,
      245,
      269,
      286,
      319,
      351,
      386,
      422,
      459,
      498,
      539,
      572,
      619,
      670,
      712,
      749,
      793,
      846,
      905,
      973,
      1005,
      1056,
      1122,
      1180,
      1331,
      1294,
      1460,
      1574,
      1742,
      1778,
      1888,
      2033,
      2156,
      2320,
      2419,
      2589,
      2782,
      2819,
      2952,
      3103,
      3257,
      3388,
      3471,
      3588,
      3604,
      3561,
      3519,
      3363,
      3155,
      2914,
      2568,
      2208,
      1875,
      1546,
      1236,
      955,
      713,
      513,
      356,
      237,
      152,
      94,
      56,
      32,
      17,
      9,
      9
    ],
    lifeExpectancy: 79.235
  },
  2016: {
    deathByAge: [
      583,
      39,
      26,
      20,
      16,
      13,
      12,
      13,
      12,
      11,
      11,
      12,
      13,
      16,
      21,
      28,
      37,
      48,
      66,
      74,
      81,
      94,
      97,
      102,
      105,
      110,
      110,
      117,
      123,
      121,
      126,
      132,
      138,
      140,
      148,
      152,
      159,
      163,
      172,
      178,
      180,
      191,
      199,
      223,
      235,
      256,
      273,
      289,
      319,
      352,
      382,
      414,
      457,
      497,
      538,
      583,
      614,
      666,
      709,
      765,
      800,
      853,
      907,
      954,
      1019,
      1064,
      1105,
      1182,
      1246,
      1420,
      1351,
      1541,
      1650,
      1828,
      1872,
      2015,
      2108,
      2266,
      2400,
      2534,
      2710,
      2866,
      2906,
      2989,
      3152,
      3304,
      3419,
      3487,
      3540,
      3551,
      3485,
      3373,
      3163,
      2920,
      2628,
      2259,
      1938,
      1616,
      1308,
      1025,
      777,
      568,
      401,
      273,
      179,
      113,
      68,
      40,
      22,
      12,
      12
    ],
    lifeExpectancy: 79.235
  },
  2017: {
    deathByAge: [
      571,
      38,
      25,
      19,
      15,
      13,
      11,
      12,
      11,
      11,
      12,
      12,
      14,
      17,
      22,
      29,
      40,
      46,
      64,
      76,
      82,
      89,
      94,
      101,
      106,
      111,
      114,
      121,
      126,
      126,
      129,
      136,
      143,
      148,
      154,
      157,
      162,
      171,
      171,
      183,
      194,
      196,
      208,
      218,
      230,
      256,
      279,
      299,
      315,
      341,
      379,
      416,
      446,
      488,
      530,
      578,
      617,
      664,
      706,
      760,
      814,
      852,
      900,
      964,
      1020,
      1075,
      1115,
      1175,
      1256,
      1331,
      1508,
      1447,
      1643,
      1758,
      1953,
      1980,
      2116,
      2251,
      2404,
      2577,
      2692,
      2842,
      3037,
      3059,
      3153,
      3256,
      3390,
      3492,
      3508,
      3501,
      3485,
      3340,
      3195,
      2904,
      2634,
      2255,
      1934,
      1612,
      1303,
      1021,
      773,
      566,
      399,
      271,
      177,
      112,
      68,
      39,
      22,
      12,
      12
    ],
    lifeExpectancy: 79.235
  },
  2018: {
    deathByAge: [
      558,
      37,
      26,
      18,
      15,
      13,
      11,
      10,
      11,
      11,
      11,
      12,
      14,
      16,
      21,
      28,
      36,
      48,
      63,
      69,
      76,
      86,
      90,
      95,
      100,
      104,
      109,
      117,
      121,
      124,
      133,
      131,
      139,
      143,
      146,
      158,
      161,
      167,
      176,
      186,
      189,
      199,
      203,
      218,
      233,
      255,
      277,
      295,
      313,
      340,
      367,
      405,
      439,
      477,
      524,
      569,
      618,
      665,
      704,
      752,
      801,
      863,
      908,
      971,
      1013,
      1074,
      1135,
      1187,
      1236,
      1331,
      1409,
      1605,
      1543,
      1740,
      1871,
      2074,
      2105,
      2253,
      2358,
      2526,
      2695,
      2812,
      2987,
      3139,
      3150,
      3222,
      3328,
      3419,
      3524,
      3500,
      3459,
      3360,
      3149,
      2941,
      2663,
      2293,
      1979,
      1661,
      1355,
      1071,
      819,
      606,
      433,
      298,
      198,
      126,
      77,
      46,
      26,
      14,
      15
    ],
    lifeExpectancy: 79.235
  }
};

const lifeExpectancy = [
  {
    year: 1900,
    life: 48.9
  },
  {
    year: 1901,
    life: 49.3
  },
  {
    year: 1902,
    life: 50.5
  },
  {
    year: 1903,
    life: 50.6
  },
  {
    year: 1904,
    life: 49.6
  },
  {
    year: 1905,
    life: 50.3
  },
  {
    year: 1906,
    life: 50.1
  },
  {
    year: 1907,
    life: 50.2
  },
  {
    year: 1908,
    life: 51.9
  },
  {
    year: 1909,
    life: 52.8
  },
  {
    year: 1910,
    life: 51.8
  },
  {
    year: 1911,
    life: 53.4
  },
  {
    year: 1912,
    life: 54.1
  },
  {
    year: 1913,
    life: 53.5
  },
  {
    year: 1914,
    life: 54.6
  },
  {
    year: 1915,
    life: 55.1
  },
  {
    year: 1916,
    life: 54.2
  },
  {
    year: 1917,
    life: 54
  },
  {
    year: 1918,
    life: 47.2
  },
  {
    year: 1919,
    life: 55.3
  },
  {
    year: 1920,
    life: 55.4
  },
  {
    year: 1921,
    life: 58.2
  },
  {
    year: 1922,
    life: 58.1
  },
  {
    year: 1923,
    life: 57.5
  },
  {
    year: 1924,
    life: 58.5
  },
  {
    year: 1925,
    life: 58.5
  },
  {
    year: 1926,
    life: 57.9
  },
  {
    year: 1927,
    life: 59.4
  },
  {
    year: 1928,
    life: 58.3
  },
  {
    year: 1929,
    life: 58.5
  },
  {
    year: 1930,
    life: 59.6
  },
  {
    year: 1931,
    life: 60.3
  },
  {
    year: 1932,
    life: 61
  },
  {
    year: 1933,
    life: 60.96
  },
  {
    year: 1934,
    life: 60.29
  },
  {
    year: 1935,
    life: 60.94
  },
  {
    year: 1936,
    life: 60.4
  },
  {
    year: 1937,
    life: 61.1
  },
  {
    year: 1938,
    life: 62.43
  },
  {
    year: 1939,
    life: 63.12
  },
  {
    year: 1940,
    life: 63.28
  },
  {
    year: 1941,
    life: 63.84
  },
  {
    year: 1942,
    life: 64.63
  },
  {
    year: 1943,
    life: 64.34
  },
  {
    year: 1944,
    life: 65.13
  },
  {
    year: 1945,
    life: 65.63
  },
  {
    year: 1946,
    life: 66.33
  },
  {
    year: 1947,
    life: 66.73
  },
  {
    year: 1948,
    life: 67.3
  },
  {
    year: 1949,
    life: 67.68
  },
  {
    year: 1950,
    life: 68.12
  },
  {
    year: 1951,
    life: 68.22
  },
  {
    year: 1952,
    life: 68.44
  },
  {
    year: 1953,
    life: 68.79
  },
  {
    year: 1954,
    life: 69.58
  },
  {
    year: 1955,
    life: 69.63
  },
  {
    year: 1956,
    life: 69.71
  },
  {
    year: 1957,
    life: 69.49
  },
  {
    year: 1958,
    life: 69.76
  },
  {
    year: 1959,
    life: 69.98
  },
  {
    year: 1960,
    life: 69.91
  },
  {
    year: 1961,
    life: 70.32
  },
  {
    year: 1962,
    life: 70.21
  },
  {
    year: 1963,
    life: 70.04
  },
  {
    year: 1964,
    life: 70.33
  },
  {
    year: 1965,
    life: 70.41
  },
  {
    year: 1966,
    life: 70.43
  },
  {
    year: 1967,
    life: 70.76
  },
  {
    year: 1968,
    life: 70.42
  },
  {
    year: 1969,
    life: 70.66
  },
  {
    year: 1970,
    life: 70.92
  },
  {
    year: 1971,
    life: 71.24
  },
  {
    year: 1972,
    life: 71.34
  },
  {
    year: 1973,
    life: 71.54
  },
  {
    year: 1974,
    life: 72.08
  },
  {
    year: 1975,
    life: 72.68
  },
  {
    year: 1976,
    life: 72.99
  },
  {
    year: 1977,
    life: 73.38
  },
  {
    year: 1978,
    life: 73.58
  },
  {
    year: 1979,
    life: 74.03
  },
  {
    year: 1980,
    life: 73.93
  },
  {
    year: 1981,
    life: 74.36
  },
  {
    year: 1982,
    life: 74.65
  },
  {
    year: 1983,
    life: 74.71
  },
  {
    year: 1984,
    life: 74.81
  },
  {
    year: 1985,
    life: 74.79
  },
  {
    year: 1986,
    life: 74.87
  },
  {
    year: 1987,
    life: 75.01
  },
  {
    year: 1988,
    life: 75.02
  },
  {
    year: 1989,
    life: 75.1
  },
  {
    year: 1990,
    life: 75.4
  },
  {
    year: 1991,
    life: 75.5
  },
  {
    year: 1992,
    life: 75.8
  },
  {
    year: 1993,
    life: 75.5
  },
  {
    year: 1994,
    life: 75.7
  },
  {
    year: 1995,
    life: 75.8
  },
  {
    year: 1996,
    life: 76.1
  },
  {
    year: 1997,
    life: 76.5
  },
  {
    year: 1998,
    life: 76.7
  },
  {
    year: 1999,
    life: 76.7
  },
  {
    year: 2000,
    life: 76.8
  },
  {
    year: 2001,
    life: 76.9
  },
  {
    year: 2002,
    life: 76.9
  },
  {
    year: 2003,
    life: 77.1
  },
  {
    year: 2004,
    life: 77.5
  },
  {
    year: 2005,
    life: 77.4
  },
  {
    year: 2006,
    life: 77.7
  },
  {
    year: 2007,
    life: 77.9
  },
  {
    year: 2008,
    life: 77.8
  },
  {
    year: 2009,
    life: 78.352
  },
  {
    year: 2010,
    life: 78.507
  },
  {
    year: 2011,
    life: 78.654
  },
  {
    year: 2012,
    life: 78.797
  },
  {
    year: 2013,
    life: 78.941
  },
  {
    year: 2014,
    life: 79.087
  },
  {
    year: 2015,
    life: 79.235
  }
];

export const lifeTable = {
  1959: [
    {
      age: 0,
      deaths: 2650
    },
    {
      age: 1,
      deaths: 168
    },
    {
      age: 2,
      deaths: 103
    },
    {
      age: 3,
      deaths: 76
    },
    {
      age: 4,
      deaths: 66
    },
    {
      age: 5,
      deaths: 58
    },
    {
      age: 6,
      deaths: 52
    },
    {
      age: 7,
      deaths: 46
    },
    {
      age: 8,
      deaths: 41
    },
    {
      age: 9,
      deaths: 41
    },
    {
      age: 10,
      deaths: 39
    },
    {
      age: 11,
      deaths: 40
    },
    {
      age: 12,
      deaths: 45
    },
    {
      age: 13,
      deaths: 44
    },
    {
      age: 14,
      deaths: 55
    },
    {
      age: 15,
      deaths: 68
    },
    {
      age: 16,
      deaths: 82
    },
    {
      age: 17,
      deaths: 93
    },
    {
      age: 18,
      deaths: 106
    },
    {
      age: 19,
      deaths: 110
    },
    {
      age: 20,
      deaths: 120
    },
    {
      age: 21,
      deaths: 124
    },
    {
      age: 22,
      deaths: 122
    },
    {
      age: 23,
      deaths: 114
    },
    {
      age: 24,
      deaths: 116
    },
    {
      age: 25,
      deaths: 120
    },
    {
      age: 26,
      deaths: 121
    },
    {
      age: 27,
      deaths: 120
    },
    {
      age: 28,
      deaths: 122
    },
    {
      age: 29,
      deaths: 126
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 144
    },
    {
      age: 32,
      deaths: 155
    },
    {
      age: 33,
      deaths: 163
    },
    {
      age: 34,
      deaths: 172
    },
    {
      age: 35,
      deaths: 181
    },
    {
      age: 36,
      deaths: 197
    },
    {
      age: 37,
      deaths: 210
    },
    {
      age: 38,
      deaths: 236
    },
    {
      age: 39,
      deaths: 255
    },
    {
      age: 40,
      deaths: 280
    },
    {
      age: 41,
      deaths: 288
    },
    {
      age: 42,
      deaths: 340
    },
    {
      age: 43,
      deaths: 365
    },
    {
      age: 44,
      deaths: 397
    },
    {
      age: 45,
      deaths: 435
    },
    {
      age: 46,
      deaths: 470
    },
    {
      age: 47,
      deaths: 523
    },
    {
      age: 48,
      deaths: 585
    },
    {
      age: 49,
      deaths: 630
    },
    {
      age: 50,
      deaths: 691
    },
    {
      age: 51,
      deaths: 726
    },
    {
      age: 52,
      deaths: 821
    },
    {
      age: 53,
      deaths: 863
    },
    {
      age: 54,
      deaths: 928
    },
    {
      age: 55,
      deaths: 987
    },
    {
      age: 56,
      deaths: 1053
    },
    {
      age: 57,
      deaths: 1077
    },
    {
      age: 58,
      deaths: 1238
    },
    {
      age: 59,
      deaths: 1306
    },
    {
      age: 60,
      deaths: 1379
    },
    {
      age: 61,
      deaths: 1419
    },
    {
      age: 62,
      deaths: 1608
    },
    {
      age: 63,
      deaths: 1679
    },
    {
      age: 64,
      deaths: 1691
    },
    {
      age: 65,
      deaths: 1894
    },
    {
      age: 66,
      deaths: 1903
    },
    {
      age: 67,
      deaths: 2096
    },
    {
      age: 68,
      deaths: 2176
    },
    {
      age: 69,
      deaths: 2250
    },
    {
      age: 70,
      deaths: 2395
    },
    {
      age: 71,
      deaths: 2344
    },
    {
      age: 72,
      deaths: 2580
    },
    {
      age: 73,
      deaths: 2624
    },
    {
      age: 74,
      deaths: 2723
    },
    {
      age: 75,
      deaths: 2878
    },
    {
      age: 76,
      deaths: 2881
    },
    {
      age: 77,
      deaths: 2861
    },
    {
      age: 78,
      deaths: 3008
    },
    {
      age: 79,
      deaths: 3091
    },
    {
      age: 80,
      deaths: 3056
    },
    {
      age: 81,
      deaths: 2868
    },
    {
      age: 82,
      deaths: 2938
    },
    {
      age: 83,
      deaths: 2889
    },
    {
      age: 84,
      deaths: 2775
    },
    {
      age: 85,
      deaths: 2612
    },
    {
      age: 86,
      deaths: 2450
    },
    {
      age: 87,
      deaths: 2262
    },
    {
      age: 88,
      deaths: 2006
    },
    {
      age: 89,
      deaths: 1820
    },
    {
      age: 90,
      deaths: 1593
    },
    {
      age: 91,
      deaths: 1277
    },
    {
      age: 92,
      deaths: 1126
    },
    {
      age: 93,
      deaths: 936
    },
    {
      age: 94,
      deaths: 753
    },
    {
      age: 95,
      deaths: 581
    },
    {
      age: 96,
      deaths: 447
    },
    {
      age: 97,
      deaths: 336
    },
    {
      age: 98,
      deaths: 246
    },
    {
      age: 99,
      deaths: 175
    },
    {
      age: 100,
      deaths: 121
    },
    {
      age: 101,
      deaths: 82
    },
    {
      age: 102,
      deaths: 53
    },
    {
      age: 103,
      deaths: 34
    },
    {
      age: 104,
      deaths: 21
    },
    {
      age: 105,
      deaths: 13
    },
    {
      age: 106,
      deaths: 7
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1960: [
    {
      age: 0,
      deaths: 2613
    },
    {
      age: 1,
      deaths: 171
    },
    {
      age: 2,
      deaths: 103
    },
    {
      age: 3,
      deaths: 81
    },
    {
      age: 4,
      deaths: 66
    },
    {
      age: 5,
      deaths: 56
    },
    {
      age: 6,
      deaths: 51
    },
    {
      age: 7,
      deaths: 46
    },
    {
      age: 8,
      deaths: 42
    },
    {
      age: 9,
      deaths: 39
    },
    {
      age: 10,
      deaths: 36
    },
    {
      age: 11,
      deaths: 39
    },
    {
      age: 12,
      deaths: 39
    },
    {
      age: 13,
      deaths: 47
    },
    {
      age: 14,
      deaths: 50
    },
    {
      age: 15,
      deaths: 61
    },
    {
      age: 16,
      deaths: 76
    },
    {
      age: 17,
      deaths: 92
    },
    {
      age: 18,
      deaths: 103
    },
    {
      age: 19,
      deaths: 114
    },
    {
      age: 20,
      deaths: 117
    },
    {
      age: 21,
      deaths: 123
    },
    {
      age: 22,
      deaths: 117
    },
    {
      age: 23,
      deaths: 113
    },
    {
      age: 24,
      deaths: 117
    },
    {
      age: 25,
      deaths: 123
    },
    {
      age: 26,
      deaths: 123
    },
    {
      age: 27,
      deaths: 122
    },
    {
      age: 28,
      deaths: 124
    },
    {
      age: 29,
      deaths: 131
    },
    {
      age: 30,
      deaths: 138
    },
    {
      age: 31,
      deaths: 138
    },
    {
      age: 32,
      deaths: 150
    },
    {
      age: 33,
      deaths: 163
    },
    {
      age: 34,
      deaths: 171
    },
    {
      age: 35,
      deaths: 186
    },
    {
      age: 36,
      deaths: 196
    },
    {
      age: 37,
      deaths: 213
    },
    {
      age: 38,
      deaths: 241
    },
    {
      age: 39,
      deaths: 259
    },
    {
      age: 40,
      deaths: 287
    },
    {
      age: 41,
      deaths: 298
    },
    {
      age: 42,
      deaths: 347
    },
    {
      age: 43,
      deaths: 366
    },
    {
      age: 44,
      deaths: 406
    },
    {
      age: 45,
      deaths: 445
    },
    {
      age: 46,
      deaths: 475
    },
    {
      age: 47,
      deaths: 531
    },
    {
      age: 48,
      deaths: 580
    },
    {
      age: 49,
      deaths: 636
    },
    {
      age: 50,
      deaths: 713
    },
    {
      age: 51,
      deaths: 739
    },
    {
      age: 52,
      deaths: 829
    },
    {
      age: 53,
      deaths: 864
    },
    {
      age: 54,
      deaths: 934
    },
    {
      age: 55,
      deaths: 995
    },
    {
      age: 56,
      deaths: 1055
    },
    {
      age: 57,
      deaths: 1136
    },
    {
      age: 58,
      deaths: 1183
    },
    {
      age: 59,
      deaths: 1331
    },
    {
      age: 60,
      deaths: 1419
    },
    {
      age: 61,
      deaths: 1410
    },
    {
      age: 62,
      deaths: 1641
    },
    {
      age: 63,
      deaths: 1700
    },
    {
      age: 64,
      deaths: 1795
    },
    {
      age: 65,
      deaths: 1930
    },
    {
      age: 66,
      deaths: 1881
    },
    {
      age: 67,
      deaths: 2117
    },
    {
      age: 68,
      deaths: 2175
    },
    {
      age: 69,
      deaths: 2256
    },
    {
      age: 70,
      deaths: 2446
    },
    {
      age: 71,
      deaths: 2356
    },
    {
      age: 72,
      deaths: 2642
    },
    {
      age: 73,
      deaths: 2646
    },
    {
      age: 74,
      deaths: 2749
    },
    {
      age: 75,
      deaths: 2848
    },
    {
      age: 76,
      deaths: 2804
    },
    {
      age: 77,
      deaths: 2893
    },
    {
      age: 78,
      deaths: 3014
    },
    {
      age: 79,
      deaths: 3035
    },
    {
      age: 80,
      deaths: 3102
    },
    {
      age: 81,
      deaths: 2814
    },
    {
      age: 82,
      deaths: 2923
    },
    {
      age: 83,
      deaths: 2844
    },
    {
      age: 84,
      deaths: 2778
    },
    {
      age: 85,
      deaths: 2597
    },
    {
      age: 86,
      deaths: 2393
    },
    {
      age: 87,
      deaths: 2240
    },
    {
      age: 88,
      deaths: 2008
    },
    {
      age: 89,
      deaths: 1758
    },
    {
      age: 90,
      deaths: 1595
    },
    {
      age: 91,
      deaths: 1253
    },
    {
      age: 92,
      deaths: 1115
    },
    {
      age: 93,
      deaths: 928
    },
    {
      age: 94,
      deaths: 710
    },
    {
      age: 95,
      deaths: 566
    },
    {
      age: 96,
      deaths: 434
    },
    {
      age: 97,
      deaths: 324
    },
    {
      age: 98,
      deaths: 235
    },
    {
      age: 99,
      deaths: 166
    },
    {
      age: 100,
      deaths: 115
    },
    {
      age: 101,
      deaths: 77
    },
    {
      age: 102,
      deaths: 50
    },
    {
      age: 103,
      deaths: 31
    },
    {
      age: 104,
      deaths: 19
    },
    {
      age: 105,
      deaths: 11
    },
    {
      age: 106,
      deaths: 7
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1961: [
    {
      age: 0,
      deaths: 2534
    },
    {
      age: 1,
      deaths: 160
    },
    {
      age: 2,
      deaths: 97
    },
    {
      age: 3,
      deaths: 74
    },
    {
      age: 4,
      deaths: 63
    },
    {
      age: 5,
      deaths: 54
    },
    {
      age: 6,
      deaths: 48
    },
    {
      age: 7,
      deaths: 43
    },
    {
      age: 8,
      deaths: 38
    },
    {
      age: 9,
      deaths: 36
    },
    {
      age: 10,
      deaths: 36
    },
    {
      age: 11,
      deaths: 37
    },
    {
      age: 12,
      deaths: 40
    },
    {
      age: 13,
      deaths: 43
    },
    {
      age: 14,
      deaths: 52
    },
    {
      age: 15,
      deaths: 55
    },
    {
      age: 16,
      deaths: 74
    },
    {
      age: 17,
      deaths: 86
    },
    {
      age: 18,
      deaths: 99
    },
    {
      age: 19,
      deaths: 102
    },
    {
      age: 20,
      deaths: 110
    },
    {
      age: 21,
      deaths: 119
    },
    {
      age: 22,
      deaths: 121
    },
    {
      age: 23,
      deaths: 119
    },
    {
      age: 24,
      deaths: 113
    },
    {
      age: 25,
      deaths: 115
    },
    {
      age: 26,
      deaths: 117
    },
    {
      age: 27,
      deaths: 118
    },
    {
      age: 28,
      deaths: 123
    },
    {
      age: 29,
      deaths: 127
    },
    {
      age: 30,
      deaths: 140
    },
    {
      age: 31,
      deaths: 142
    },
    {
      age: 32,
      deaths: 145
    },
    {
      age: 33,
      deaths: 154
    },
    {
      age: 34,
      deaths: 172
    },
    {
      age: 35,
      deaths: 184
    },
    {
      age: 36,
      deaths: 196
    },
    {
      age: 37,
      deaths: 213
    },
    {
      age: 38,
      deaths: 232
    },
    {
      age: 39,
      deaths: 254
    },
    {
      age: 40,
      deaths: 278
    },
    {
      age: 41,
      deaths: 301
    },
    {
      age: 42,
      deaths: 326
    },
    {
      age: 43,
      deaths: 362
    },
    {
      age: 44,
      deaths: 396
    },
    {
      age: 45,
      deaths: 430
    },
    {
      age: 46,
      deaths: 462
    },
    {
      age: 47,
      deaths: 523
    },
    {
      age: 48,
      deaths: 562
    },
    {
      age: 49,
      deaths: 607
    },
    {
      age: 50,
      deaths: 693
    },
    {
      age: 51,
      deaths: 733
    },
    {
      age: 52,
      deaths: 787
    },
    {
      age: 53,
      deaths: 868
    },
    {
      age: 54,
      deaths: 910
    },
    {
      age: 55,
      deaths: 982
    },
    {
      age: 56,
      deaths: 1041
    },
    {
      age: 57,
      deaths: 1108
    },
    {
      age: 58,
      deaths: 1199
    },
    {
      age: 59,
      deaths: 1235
    },
    {
      age: 60,
      deaths: 1398
    },
    {
      age: 61,
      deaths: 1443
    },
    {
      age: 62,
      deaths: 1547
    },
    {
      age: 63,
      deaths: 1654
    },
    {
      age: 64,
      deaths: 1749
    },
    {
      age: 65,
      deaths: 1947
    },
    {
      age: 66,
      deaths: 1869
    },
    {
      age: 67,
      deaths: 2042
    },
    {
      age: 68,
      deaths: 2179
    },
    {
      age: 69,
      deaths: 2204
    },
    {
      age: 70,
      deaths: 2393
    },
    {
      age: 71,
      deaths: 2377
    },
    {
      age: 72,
      deaths: 2594
    },
    {
      age: 73,
      deaths: 2631
    },
    {
      age: 74,
      deaths: 2674
    },
    {
      age: 75,
      deaths: 2855
    },
    {
      age: 76,
      deaths: 2743
    },
    {
      age: 77,
      deaths: 2812
    },
    {
      age: 78,
      deaths: 3072
    },
    {
      age: 79,
      deaths: 3050
    },
    {
      age: 80,
      deaths: 3083
    },
    {
      age: 81,
      deaths: 2959
    },
    {
      age: 82,
      deaths: 2915
    },
    {
      age: 83,
      deaths: 2926
    },
    {
      age: 84,
      deaths: 2800
    },
    {
      age: 85,
      deaths: 2679
    },
    {
      age: 86,
      deaths: 2504
    },
    {
      age: 87,
      deaths: 2312
    },
    {
      age: 88,
      deaths: 2042
    },
    {
      age: 89,
      deaths: 1828
    },
    {
      age: 90,
      deaths: 1640
    },
    {
      age: 91,
      deaths: 1368
    },
    {
      age: 92,
      deaths: 1138
    },
    {
      age: 93,
      deaths: 959
    },
    {
      age: 94,
      deaths: 772
    },
    {
      age: 95,
      deaths: 613
    },
    {
      age: 96,
      deaths: 473
    },
    {
      age: 97,
      deaths: 356
    },
    {
      age: 98,
      deaths: 261
    },
    {
      age: 99,
      deaths: 186
    },
    {
      age: 100,
      deaths: 130
    },
    {
      age: 101,
      deaths: 88
    },
    {
      age: 102,
      deaths: 57
    },
    {
      age: 103,
      deaths: 37
    },
    {
      age: 104,
      deaths: 23
    },
    {
      age: 105,
      deaths: 14
    },
    {
      age: 106,
      deaths: 8
    },
    {
      age: 107,
      deaths: 5
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1962: [
    {
      age: 0,
      deaths: 2496
    },
    {
      age: 1,
      deaths: 156
    },
    {
      age: 2,
      deaths: 96
    },
    {
      age: 3,
      deaths: 73
    },
    {
      age: 4,
      deaths: 58
    },
    {
      age: 5,
      deaths: 55
    },
    {
      age: 6,
      deaths: 48
    },
    {
      age: 7,
      deaths: 43
    },
    {
      age: 8,
      deaths: 39
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 37
    },
    {
      age: 12,
      deaths: 39
    },
    {
      age: 13,
      deaths: 43
    },
    {
      age: 14,
      deaths: 50
    },
    {
      age: 15,
      deaths: 60
    },
    {
      age: 16,
      deaths: 69
    },
    {
      age: 17,
      deaths: 86
    },
    {
      age: 18,
      deaths: 101
    },
    {
      age: 19,
      deaths: 112
    },
    {
      age: 20,
      deaths: 111
    },
    {
      age: 21,
      deaths: 123
    },
    {
      age: 22,
      deaths: 120
    },
    {
      age: 23,
      deaths: 120
    },
    {
      age: 24,
      deaths: 117
    },
    {
      age: 25,
      deaths: 120
    },
    {
      age: 26,
      deaths: 120
    },
    {
      age: 27,
      deaths: 117
    },
    {
      age: 28,
      deaths: 121
    },
    {
      age: 29,
      deaths: 132
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 142
    },
    {
      age: 32,
      deaths: 150
    },
    {
      age: 33,
      deaths: 158
    },
    {
      age: 34,
      deaths: 174
    },
    {
      age: 35,
      deaths: 190
    },
    {
      age: 36,
      deaths: 202
    },
    {
      age: 37,
      deaths: 224
    },
    {
      age: 38,
      deaths: 239
    },
    {
      age: 39,
      deaths: 259
    },
    {
      age: 40,
      deaths: 281
    },
    {
      age: 41,
      deaths: 295
    },
    {
      age: 42,
      deaths: 339
    },
    {
      age: 43,
      deaths: 358
    },
    {
      age: 44,
      deaths: 398
    },
    {
      age: 45,
      deaths: 435
    },
    {
      age: 46,
      deaths: 467
    },
    {
      age: 47,
      deaths: 522
    },
    {
      age: 48,
      deaths: 566
    },
    {
      age: 49,
      deaths: 624
    },
    {
      age: 50,
      deaths: 683
    },
    {
      age: 51,
      deaths: 740
    },
    {
      age: 52,
      deaths: 827
    },
    {
      age: 53,
      deaths: 842
    },
    {
      age: 54,
      deaths: 943
    },
    {
      age: 55,
      deaths: 979
    },
    {
      age: 56,
      deaths: 1047
    },
    {
      age: 57,
      deaths: 1112
    },
    {
      age: 58,
      deaths: 1222
    },
    {
      age: 59,
      deaths: 1292
    },
    {
      age: 60,
      deaths: 1345
    },
    {
      age: 61,
      deaths: 1458
    },
    {
      age: 62,
      deaths: 1602
    },
    {
      age: 63,
      deaths: 1609
    },
    {
      age: 64,
      deaths: 1788
    },
    {
      age: 65,
      deaths: 1966
    },
    {
      age: 66,
      deaths: 1964
    },
    {
      age: 67,
      deaths: 2057
    },
    {
      age: 68,
      deaths: 2146
    },
    {
      age: 69,
      deaths: 2232
    },
    {
      age: 70,
      deaths: 2381
    },
    {
      age: 71,
      deaths: 2357
    },
    {
      age: 72,
      deaths: 2646
    },
    {
      age: 73,
      deaths: 2642
    },
    {
      age: 74,
      deaths: 2742
    },
    {
      age: 75,
      deaths: 2837
    },
    {
      age: 76,
      deaths: 2841
    },
    {
      age: 77,
      deaths: 2731
    },
    {
      age: 78,
      deaths: 2916
    },
    {
      age: 79,
      deaths: 3162
    },
    {
      age: 80,
      deaths: 3044
    },
    {
      age: 81,
      deaths: 2892
    },
    {
      age: 82,
      deaths: 3051
    },
    {
      age: 83,
      deaths: 2886
    },
    {
      age: 84,
      deaths: 2818
    },
    {
      age: 85,
      deaths: 2690
    },
    {
      age: 86,
      deaths: 2469
    },
    {
      age: 87,
      deaths: 2284
    },
    {
      age: 88,
      deaths: 2032
    },
    {
      age: 89,
      deaths: 1844
    },
    {
      age: 90,
      deaths: 1600
    },
    {
      age: 91,
      deaths: 1284
    },
    {
      age: 92,
      deaths: 1161
    },
    {
      age: 93,
      deaths: 905
    },
    {
      age: 94,
      deaths: 742
    },
    {
      age: 95,
      deaths: 587
    },
    {
      age: 96,
      deaths: 448
    },
    {
      age: 97,
      deaths: 333
    },
    {
      age: 98,
      deaths: 241
    },
    {
      age: 99,
      deaths: 170
    },
    {
      age: 100,
      deaths: 116
    },
    {
      age: 101,
      deaths: 77
    },
    {
      age: 102,
      deaths: 50
    },
    {
      age: 103,
      deaths: 31
    },
    {
      age: 104,
      deaths: 19
    },
    {
      age: 105,
      deaths: 11
    },
    {
      age: 106,
      deaths: 6
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1963: [
    {
      age: 0,
      deaths: 2496
    },
    {
      age: 1,
      deaths: 159
    },
    {
      age: 2,
      deaths: 98
    },
    {
      age: 3,
      deaths: 74
    },
    {
      age: 4,
      deaths: 60
    },
    {
      age: 5,
      deaths: 54
    },
    {
      age: 6,
      deaths: 49
    },
    {
      age: 7,
      deaths: 43
    },
    {
      age: 8,
      deaths: 37
    },
    {
      age: 9,
      deaths: 36
    },
    {
      age: 10,
      deaths: 35
    },
    {
      age: 11,
      deaths: 35
    },
    {
      age: 12,
      deaths: 38
    },
    {
      age: 13,
      deaths: 42
    },
    {
      age: 14,
      deaths: 51
    },
    {
      age: 15,
      deaths: 62
    },
    {
      age: 16,
      deaths: 83
    },
    {
      age: 17,
      deaths: 83
    },
    {
      age: 18,
      deaths: 103
    },
    {
      age: 19,
      deaths: 109
    },
    {
      age: 20,
      deaths: 116
    },
    {
      age: 21,
      deaths: 121
    },
    {
      age: 22,
      deaths: 123
    },
    {
      age: 23,
      deaths: 121
    },
    {
      age: 24,
      deaths: 118
    },
    {
      age: 25,
      deaths: 123
    },
    {
      age: 26,
      deaths: 120
    },
    {
      age: 27,
      deaths: 125
    },
    {
      age: 28,
      deaths: 135
    },
    {
      age: 29,
      deaths: 128
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 139
    },
    {
      age: 32,
      deaths: 157
    },
    {
      age: 33,
      deaths: 162
    },
    {
      age: 34,
      deaths: 178
    },
    {
      age: 35,
      deaths: 191
    },
    {
      age: 36,
      deaths: 208
    },
    {
      age: 37,
      deaths: 220
    },
    {
      age: 38,
      deaths: 246
    },
    {
      age: 39,
      deaths: 266
    },
    {
      age: 40,
      deaths: 282
    },
    {
      age: 41,
      deaths: 301
    },
    {
      age: 42,
      deaths: 346
    },
    {
      age: 43,
      deaths: 371
    },
    {
      age: 44,
      deaths: 391
    },
    {
      age: 45,
      deaths: 445
    },
    {
      age: 46,
      deaths: 478
    },
    {
      age: 47,
      deaths: 531
    },
    {
      age: 48,
      deaths: 572
    },
    {
      age: 49,
      deaths: 623
    },
    {
      age: 50,
      deaths: 686
    },
    {
      age: 51,
      deaths: 723
    },
    {
      age: 52,
      deaths: 834
    },
    {
      age: 53,
      deaths: 877
    },
    {
      age: 54,
      deaths: 918
    },
    {
      age: 55,
      deaths: 1005
    },
    {
      age: 56,
      deaths: 1072
    },
    {
      age: 57,
      deaths: 1156
    },
    {
      age: 58,
      deaths: 1230
    },
    {
      age: 59,
      deaths: 1314
    },
    {
      age: 60,
      deaths: 1393
    },
    {
      age: 61,
      deaths: 1394
    },
    {
      age: 62,
      deaths: 1655
    },
    {
      age: 63,
      deaths: 1687
    },
    {
      age: 64,
      deaths: 1705
    },
    {
      age: 65,
      deaths: 1987
    },
    {
      age: 66,
      deaths: 1986
    },
    {
      age: 67,
      deaths: 2121
    },
    {
      age: 68,
      deaths: 2155
    },
    {
      age: 69,
      deaths: 2230
    },
    {
      age: 70,
      deaths: 2448
    },
    {
      age: 71,
      deaths: 2403
    },
    {
      age: 72,
      deaths: 2643
    },
    {
      age: 73,
      deaths: 2690
    },
    {
      age: 74,
      deaths: 2752
    },
    {
      age: 75,
      deaths: 2891
    },
    {
      age: 76,
      deaths: 2854
    },
    {
      age: 77,
      deaths: 2816
    },
    {
      age: 78,
      deaths: 2759
    },
    {
      age: 79,
      deaths: 2859
    },
    {
      age: 80,
      deaths: 3072
    },
    {
      age: 81,
      deaths: 2909
    },
    {
      age: 82,
      deaths: 2968
    },
    {
      age: 83,
      deaths: 2978
    },
    {
      age: 84,
      deaths: 2780
    },
    {
      age: 85,
      deaths: 2673
    },
    {
      age: 86,
      deaths: 2451
    },
    {
      age: 87,
      deaths: 2285
    },
    {
      age: 88,
      deaths: 2039
    },
    {
      age: 89,
      deaths: 1793
    },
    {
      age: 90,
      deaths: 1591
    },
    {
      age: 91,
      deaths: 1279
    },
    {
      age: 92,
      deaths: 1102
    },
    {
      age: 93,
      deaths: 923
    },
    {
      age: 94,
      deaths: 706
    },
    {
      age: 95,
      deaths: 565
    },
    {
      age: 96,
      deaths: 428
    },
    {
      age: 97,
      deaths: 316
    },
    {
      age: 98,
      deaths: 227
    },
    {
      age: 99,
      deaths: 158
    },
    {
      age: 100,
      deaths: 107
    },
    {
      age: 101,
      deaths: 70
    },
    {
      age: 102,
      deaths: 45
    },
    {
      age: 103,
      deaths: 28
    },
    {
      age: 104,
      deaths: 17
    },
    {
      age: 105,
      deaths: 10
    },
    {
      age: 106,
      deaths: 5
    },
    {
      age: 107,
      deaths: 3
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1964: [
    {
      age: 0,
      deaths: 2452
    },
    {
      age: 1,
      deaths: 155
    },
    {
      age: 2,
      deaths: 95
    },
    {
      age: 3,
      deaths: 72
    },
    {
      age: 4,
      deaths: 60
    },
    {
      age: 5,
      deaths: 52
    },
    {
      age: 6,
      deaths: 46
    },
    {
      age: 7,
      deaths: 42
    },
    {
      age: 8,
      deaths: 40
    },
    {
      age: 9,
      deaths: 34
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 37
    },
    {
      age: 12,
      deaths: 37
    },
    {
      age: 13,
      deaths: 44
    },
    {
      age: 14,
      deaths: 52
    },
    {
      age: 15,
      deaths: 63
    },
    {
      age: 16,
      deaths: 79
    },
    {
      age: 17,
      deaths: 100
    },
    {
      age: 18,
      deaths: 103
    },
    {
      age: 19,
      deaths: 117
    },
    {
      age: 20,
      deaths: 115
    },
    {
      age: 21,
      deaths: 125
    },
    {
      age: 22,
      deaths: 119
    },
    {
      age: 23,
      deaths: 122
    },
    {
      age: 24,
      deaths: 124
    },
    {
      age: 25,
      deaths: 124
    },
    {
      age: 26,
      deaths: 124
    },
    {
      age: 27,
      deaths: 127
    },
    {
      age: 28,
      deaths: 131
    },
    {
      age: 29,
      deaths: 133
    },
    {
      age: 30,
      deaths: 140
    },
    {
      age: 31,
      deaths: 148
    },
    {
      age: 32,
      deaths: 163
    },
    {
      age: 33,
      deaths: 172
    },
    {
      age: 34,
      deaths: 180
    },
    {
      age: 35,
      deaths: 188
    },
    {
      age: 36,
      deaths: 207
    },
    {
      age: 37,
      deaths: 226
    },
    {
      age: 38,
      deaths: 244
    },
    {
      age: 39,
      deaths: 268
    },
    {
      age: 40,
      deaths: 287
    },
    {
      age: 41,
      deaths: 300
    },
    {
      age: 42,
      deaths: 348
    },
    {
      age: 43,
      deaths: 370
    },
    {
      age: 44,
      deaths: 403
    },
    {
      age: 45,
      deaths: 428
    },
    {
      age: 46,
      deaths: 477
    },
    {
      age: 47,
      deaths: 531
    },
    {
      age: 48,
      deaths: 565
    },
    {
      age: 49,
      deaths: 608
    },
    {
      age: 50,
      deaths: 682
    },
    {
      age: 51,
      deaths: 722
    },
    {
      age: 52,
      deaths: 796
    },
    {
      age: 53,
      deaths: 867
    },
    {
      age: 54,
      deaths: 927
    },
    {
      age: 55,
      deaths: 966
    },
    {
      age: 56,
      deaths: 1067
    },
    {
      age: 57,
      deaths: 1138
    },
    {
      age: 58,
      deaths: 1207
    },
    {
      age: 59,
      deaths: 1276
    },
    {
      age: 60,
      deaths: 1378
    },
    {
      age: 61,
      deaths: 1416
    },
    {
      age: 62,
      deaths: 1545
    },
    {
      age: 63,
      deaths: 1668
    },
    {
      age: 64,
      deaths: 1727
    },
    {
      age: 65,
      deaths: 1854
    },
    {
      age: 66,
      deaths: 1957
    },
    {
      age: 67,
      deaths: 2112
    },
    {
      age: 68,
      deaths: 2143
    },
    {
      age: 69,
      deaths: 2190
    },
    {
      age: 70,
      deaths: 2352
    },
    {
      age: 71,
      deaths: 2372
    },
    {
      age: 72,
      deaths: 2620
    },
    {
      age: 73,
      deaths: 2635
    },
    {
      age: 74,
      deaths: 2736
    },
    {
      age: 75,
      deaths: 2792
    },
    {
      age: 76,
      deaths: 2849
    },
    {
      age: 77,
      deaths: 2791
    },
    {
      age: 78,
      deaths: 2877
    },
    {
      age: 79,
      deaths: 2644
    },
    {
      age: 80,
      deaths: 3056
    },
    {
      age: 81,
      deaths: 2853
    },
    {
      age: 82,
      deaths: 2971
    },
    {
      age: 83,
      deaths: 2892
    },
    {
      age: 84,
      deaths: 2915
    },
    {
      age: 85,
      deaths: 2642
    },
    {
      age: 86,
      deaths: 2530
    },
    {
      age: 87,
      deaths: 2331
    },
    {
      age: 88,
      deaths: 2101
    },
    {
      age: 89,
      deaths: 1877
    },
    {
      age: 90,
      deaths: 1678
    },
    {
      age: 91,
      deaths: 1376
    },
    {
      age: 92,
      deaths: 1162
    },
    {
      age: 93,
      deaths: 976
    },
    {
      age: 94,
      deaths: 806
    },
    {
      age: 95,
      deaths: 634
    },
    {
      age: 96,
      deaths: 488
    },
    {
      age: 97,
      deaths: 367
    },
    {
      age: 98,
      deaths: 269
    },
    {
      age: 99,
      deaths: 191
    },
    {
      age: 100,
      deaths: 132
    },
    {
      age: 101,
      deaths: 89
    },
    {
      age: 102,
      deaths: 58
    },
    {
      age: 103,
      deaths: 37
    },
    {
      age: 104,
      deaths: 23
    },
    {
      age: 105,
      deaths: 13
    },
    {
      age: 106,
      deaths: 8
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1965: [
    {
      age: 0,
      deaths: 2382
    },
    {
      age: 1,
      deaths: 148
    },
    {
      age: 2,
      deaths: 94
    },
    {
      age: 3,
      deaths: 71
    },
    {
      age: 4,
      deaths: 61
    },
    {
      age: 5,
      deaths: 51
    },
    {
      age: 6,
      deaths: 45
    },
    {
      age: 7,
      deaths: 43
    },
    {
      age: 8,
      deaths: 38
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 35
    },
    {
      age: 12,
      deaths: 38
    },
    {
      age: 13,
      deaths: 42
    },
    {
      age: 14,
      deaths: 49
    },
    {
      age: 15,
      deaths: 64
    },
    {
      age: 16,
      deaths: 79
    },
    {
      age: 17,
      deaths: 94
    },
    {
      age: 18,
      deaths: 117
    },
    {
      age: 19,
      deaths: 113
    },
    {
      age: 20,
      deaths: 116
    },
    {
      age: 21,
      deaths: 125
    },
    {
      age: 22,
      deaths: 128
    },
    {
      age: 23,
      deaths: 119
    },
    {
      age: 24,
      deaths: 119
    },
    {
      age: 25,
      deaths: 121
    },
    {
      age: 26,
      deaths: 125
    },
    {
      age: 27,
      deaths: 128
    },
    {
      age: 28,
      deaths: 133
    },
    {
      age: 29,
      deaths: 135
    },
    {
      age: 30,
      deaths: 143
    },
    {
      age: 31,
      deaths: 150
    },
    {
      age: 32,
      deaths: 161
    },
    {
      age: 33,
      deaths: 166
    },
    {
      age: 34,
      deaths: 177
    },
    {
      age: 35,
      deaths: 197
    },
    {
      age: 36,
      deaths: 200
    },
    {
      age: 37,
      deaths: 226
    },
    {
      age: 38,
      deaths: 244
    },
    {
      age: 39,
      deaths: 266
    },
    {
      age: 40,
      deaths: 292
    },
    {
      age: 41,
      deaths: 306
    },
    {
      age: 42,
      deaths: 345
    },
    {
      age: 43,
      deaths: 371
    },
    {
      age: 44,
      deaths: 402
    },
    {
      age: 45,
      deaths: 447
    },
    {
      age: 46,
      deaths: 456
    },
    {
      age: 47,
      deaths: 530
    },
    {
      age: 48,
      deaths: 569
    },
    {
      age: 49,
      deaths: 615
    },
    {
      age: 50,
      deaths: 689
    },
    {
      age: 51,
      deaths: 716
    },
    {
      age: 52,
      deaths: 808
    },
    {
      age: 53,
      deaths: 847
    },
    {
      age: 54,
      deaths: 937
    },
    {
      age: 55,
      deaths: 976
    },
    {
      age: 56,
      deaths: 1037
    },
    {
      age: 57,
      deaths: 1142
    },
    {
      age: 58,
      deaths: 1214
    },
    {
      age: 59,
      deaths: 1282
    },
    {
      age: 60,
      deaths: 1378
    },
    {
      age: 61,
      deaths: 1431
    },
    {
      age: 62,
      deaths: 1603
    },
    {
      age: 63,
      deaths: 1595
    },
    {
      age: 64,
      deaths: 1758
    },
    {
      age: 65,
      deaths: 1888
    },
    {
      age: 66,
      deaths: 1855
    },
    {
      age: 67,
      deaths: 2097
    },
    {
      age: 68,
      deaths: 2188
    },
    {
      age: 69,
      deaths: 2248
    },
    {
      age: 70,
      deaths: 2355
    },
    {
      age: 71,
      deaths: 2325
    },
    {
      age: 72,
      deaths: 2614
    },
    {
      age: 73,
      deaths: 2623
    },
    {
      age: 74,
      deaths: 2712
    },
    {
      age: 75,
      deaths: 2837
    },
    {
      age: 76,
      deaths: 2828
    },
    {
      age: 77,
      deaths: 2864
    },
    {
      age: 78,
      deaths: 2912
    },
    {
      age: 79,
      deaths: 2872
    },
    {
      age: 80,
      deaths: 3050
    },
    {
      age: 81,
      deaths: 2866
    },
    {
      age: 82,
      deaths: 2932
    },
    {
      age: 83,
      deaths: 2910
    },
    {
      age: 84,
      deaths: 2811
    },
    {
      age: 85,
      deaths: 2724
    },
    {
      age: 86,
      deaths: 2472
    },
    {
      age: 87,
      deaths: 2313
    },
    {
      age: 88,
      deaths: 2048
    },
    {
      age: 89,
      deaths: 1873
    },
    {
      age: 90,
      deaths: 1678
    },
    {
      age: 91,
      deaths: 1329
    },
    {
      age: 92,
      deaths: 1174
    },
    {
      age: 93,
      deaths: 977
    },
    {
      age: 94,
      deaths: 787
    },
    {
      age: 95,
      deaths: 624
    },
    {
      age: 96,
      deaths: 481
    },
    {
      age: 97,
      deaths: 361
    },
    {
      age: 98,
      deaths: 264
    },
    {
      age: 99,
      deaths: 187
    },
    {
      age: 100,
      deaths: 130
    },
    {
      age: 101,
      deaths: 87
    },
    {
      age: 102,
      deaths: 57
    },
    {
      age: 103,
      deaths: 36
    },
    {
      age: 104,
      deaths: 22
    },
    {
      age: 105,
      deaths: 13
    },
    {
      age: 106,
      deaths: 8
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1966: [
    {
      age: 0,
      deaths: 2320
    },
    {
      age: 1,
      deaths: 152
    },
    {
      age: 2,
      deaths: 92
    },
    {
      age: 3,
      deaths: 71
    },
    {
      age: 4,
      deaths: 61
    },
    {
      age: 5,
      deaths: 54
    },
    {
      age: 6,
      deaths: 46
    },
    {
      age: 7,
      deaths: 41
    },
    {
      age: 8,
      deaths: 38
    },
    {
      age: 9,
      deaths: 36
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 35
    },
    {
      age: 12,
      deaths: 38
    },
    {
      age: 13,
      deaths: 43
    },
    {
      age: 14,
      deaths: 51
    },
    {
      age: 15,
      deaths: 64
    },
    {
      age: 16,
      deaths: 84
    },
    {
      age: 17,
      deaths: 106
    },
    {
      age: 18,
      deaths: 124
    },
    {
      age: 19,
      deaths: 129
    },
    {
      age: 20,
      deaths: 117
    },
    {
      age: 21,
      deaths: 128
    },
    {
      age: 22,
      deaths: 127
    },
    {
      age: 23,
      deaths: 129
    },
    {
      age: 24,
      deaths: 121
    },
    {
      age: 25,
      deaths: 130
    },
    {
      age: 26,
      deaths: 128
    },
    {
      age: 27,
      deaths: 130
    },
    {
      age: 28,
      deaths: 131
    },
    {
      age: 29,
      deaths: 136
    },
    {
      age: 30,
      deaths: 145
    },
    {
      age: 31,
      deaths: 149
    },
    {
      age: 32,
      deaths: 161
    },
    {
      age: 33,
      deaths: 174
    },
    {
      age: 34,
      deaths: 179
    },
    {
      age: 35,
      deaths: 193
    },
    {
      age: 36,
      deaths: 207
    },
    {
      age: 37,
      deaths: 213
    },
    {
      age: 38,
      deaths: 252
    },
    {
      age: 39,
      deaths: 265
    },
    {
      age: 40,
      deaths: 289
    },
    {
      age: 41,
      deaths: 318
    },
    {
      age: 42,
      deaths: 353
    },
    {
      age: 43,
      deaths: 380
    },
    {
      age: 44,
      deaths: 400
    },
    {
      age: 45,
      deaths: 445
    },
    {
      age: 46,
      deaths: 484
    },
    {
      age: 47,
      deaths: 512
    },
    {
      age: 48,
      deaths: 575
    },
    {
      age: 49,
      deaths: 617
    },
    {
      age: 50,
      deaths: 691
    },
    {
      age: 51,
      deaths: 726
    },
    {
      age: 52,
      deaths: 809
    },
    {
      age: 53,
      deaths: 860
    },
    {
      age: 54,
      deaths: 915
    },
    {
      age: 55,
      deaths: 1002
    },
    {
      age: 56,
      deaths: 1040
    },
    {
      age: 57,
      deaths: 1105
    },
    {
      age: 58,
      deaths: 1246
    },
    {
      age: 59,
      deaths: 1309
    },
    {
      age: 60,
      deaths: 1410
    },
    {
      age: 61,
      deaths: 1423
    },
    {
      age: 62,
      deaths: 1590
    },
    {
      age: 63,
      deaths: 1655
    },
    {
      age: 64,
      deaths: 1682
    },
    {
      age: 65,
      deaths: 1921
    },
    {
      age: 66,
      deaths: 1920
    },
    {
      age: 67,
      deaths: 2001
    },
    {
      age: 68,
      deaths: 2185
    },
    {
      age: 69,
      deaths: 2218
    },
    {
      age: 70,
      deaths: 2403
    },
    {
      age: 71,
      deaths: 2321
    },
    {
      age: 72,
      deaths: 2561
    },
    {
      age: 73,
      deaths: 2670
    },
    {
      age: 74,
      deaths: 2733
    },
    {
      age: 75,
      deaths: 2857
    },
    {
      age: 76,
      deaths: 2837
    },
    {
      age: 77,
      deaths: 2867
    },
    {
      age: 78,
      deaths: 2969
    },
    {
      age: 79,
      deaths: 2915
    },
    {
      age: 80,
      deaths: 3035
    },
    {
      age: 81,
      deaths: 2860
    },
    {
      age: 82,
      deaths: 2894
    },
    {
      age: 83,
      deaths: 2867
    },
    {
      age: 84,
      deaths: 2775
    },
    {
      age: 85,
      deaths: 2651
    },
    {
      age: 86,
      deaths: 2502
    },
    {
      age: 87,
      deaths: 2279
    },
    {
      age: 88,
      deaths: 2056
    },
    {
      age: 89,
      deaths: 1827
    },
    {
      age: 90,
      deaths: 1650
    },
    {
      age: 91,
      deaths: 1380
    },
    {
      age: 92,
      deaths: 1161
    },
    {
      age: 93,
      deaths: 969
    },
    {
      age: 94,
      deaths: 792
    },
    {
      age: 95,
      deaths: 618
    },
    {
      age: 96,
      deaths: 476
    },
    {
      age: 97,
      deaths: 357
    },
    {
      age: 98,
      deaths: 261
    },
    {
      age: 99,
      deaths: 186
    },
    {
      age: 100,
      deaths: 128
    },
    {
      age: 101,
      deaths: 86
    },
    {
      age: 102,
      deaths: 56
    },
    {
      age: 103,
      deaths: 36
    },
    {
      age: 104,
      deaths: 22
    },
    {
      age: 105,
      deaths: 13
    },
    {
      age: 106,
      deaths: 8
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1967: [
    {
      age: 0,
      deaths: 2222
    },
    {
      age: 1,
      deaths: 137
    },
    {
      age: 2,
      deaths: 89
    },
    {
      age: 3,
      deaths: 67
    },
    {
      age: 4,
      deaths: 58
    },
    {
      age: 5,
      deaths: 51
    },
    {
      age: 6,
      deaths: 45
    },
    {
      age: 7,
      deaths: 40
    },
    {
      age: 8,
      deaths: 36
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 35
    },
    {
      age: 11,
      deaths: 33
    },
    {
      age: 12,
      deaths: 38
    },
    {
      age: 13,
      deaths: 41
    },
    {
      age: 14,
      deaths: 52
    },
    {
      age: 15,
      deaths: 62
    },
    {
      age: 16,
      deaths: 84
    },
    {
      age: 17,
      deaths: 100
    },
    {
      age: 18,
      deaths: 124
    },
    {
      age: 19,
      deaths: 131
    },
    {
      age: 20,
      deaths: 128
    },
    {
      age: 21,
      deaths: 122
    },
    {
      age: 22,
      deaths: 133
    },
    {
      age: 23,
      deaths: 128
    },
    {
      age: 24,
      deaths: 127
    },
    {
      age: 25,
      deaths: 124
    },
    {
      age: 26,
      deaths: 125
    },
    {
      age: 27,
      deaths: 135
    },
    {
      age: 28,
      deaths: 133
    },
    {
      age: 29,
      deaths: 134
    },
    {
      age: 30,
      deaths: 141
    },
    {
      age: 31,
      deaths: 147
    },
    {
      age: 32,
      deaths: 159
    },
    {
      age: 33,
      deaths: 166
    },
    {
      age: 34,
      deaths: 186
    },
    {
      age: 35,
      deaths: 201
    },
    {
      age: 36,
      deaths: 207
    },
    {
      age: 37,
      deaths: 219
    },
    {
      age: 38,
      deaths: 248
    },
    {
      age: 39,
      deaths: 270
    },
    {
      age: 40,
      deaths: 291
    },
    {
      age: 41,
      deaths: 315
    },
    {
      age: 42,
      deaths: 347
    },
    {
      age: 43,
      deaths: 373
    },
    {
      age: 44,
      deaths: 399
    },
    {
      age: 45,
      deaths: 445
    },
    {
      age: 46,
      deaths: 469
    },
    {
      age: 47,
      deaths: 515
    },
    {
      age: 48,
      deaths: 553
    },
    {
      age: 49,
      deaths: 618
    },
    {
      age: 50,
      deaths: 671
    },
    {
      age: 51,
      deaths: 713
    },
    {
      age: 52,
      deaths: 782
    },
    {
      age: 53,
      deaths: 857
    },
    {
      age: 54,
      deaths: 919
    },
    {
      age: 55,
      deaths: 956
    },
    {
      age: 56,
      deaths: 1062
    },
    {
      age: 57,
      deaths: 1116
    },
    {
      age: 58,
      deaths: 1187
    },
    {
      age: 59,
      deaths: 1300
    },
    {
      age: 60,
      deaths: 1367
    },
    {
      age: 61,
      deaths: 1426
    },
    {
      age: 62,
      deaths: 1551
    },
    {
      age: 63,
      deaths: 1614
    },
    {
      age: 64,
      deaths: 1697
    },
    {
      age: 65,
      deaths: 1814
    },
    {
      age: 66,
      deaths: 1941
    },
    {
      age: 67,
      deaths: 2026
    },
    {
      age: 68,
      deaths: 2015
    },
    {
      age: 69,
      deaths: 2229
    },
    {
      age: 70,
      deaths: 2352
    },
    {
      age: 71,
      deaths: 2358
    },
    {
      age: 72,
      deaths: 2527
    },
    {
      age: 73,
      deaths: 2597
    },
    {
      age: 74,
      deaths: 2738
    },
    {
      age: 75,
      deaths: 2830
    },
    {
      age: 76,
      deaths: 2836
    },
    {
      age: 77,
      deaths: 2819
    },
    {
      age: 78,
      deaths: 2895
    },
    {
      age: 79,
      deaths: 2966
    },
    {
      age: 80,
      deaths: 2966
    },
    {
      age: 81,
      deaths: 2895
    },
    {
      age: 82,
      deaths: 2894
    },
    {
      age: 83,
      deaths: 2898
    },
    {
      age: 84,
      deaths: 2801
    },
    {
      age: 85,
      deaths: 2663
    },
    {
      age: 86,
      deaths: 2537
    },
    {
      age: 87,
      deaths: 2388
    },
    {
      age: 88,
      deaths: 2066
    },
    {
      age: 89,
      deaths: 1932
    },
    {
      age: 90,
      deaths: 1699
    },
    {
      age: 91,
      deaths: 1443
    },
    {
      age: 92,
      deaths: 1251
    },
    {
      age: 93,
      deaths: 1031
    },
    {
      age: 94,
      deaths: 851
    },
    {
      age: 95,
      deaths: 666
    },
    {
      age: 96,
      deaths: 518
    },
    {
      age: 97,
      deaths: 393
    },
    {
      age: 98,
      deaths: 290
    },
    {
      age: 99,
      deaths: 208
    },
    {
      age: 100,
      deaths: 146
    },
    {
      age: 101,
      deaths: 99
    },
    {
      age: 102,
      deaths: 65
    },
    {
      age: 103,
      deaths: 42
    },
    {
      age: 104,
      deaths: 26
    },
    {
      age: 105,
      deaths: 16
    },
    {
      age: 106,
      deaths: 9
    },
    {
      age: 107,
      deaths: 5
    },
    {
      age: 108,
      deaths: 3
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 2
    }
  ],
  1968: [
    {
      age: 0,
      deaths: 2180
    },
    {
      age: 1,
      deaths: 140
    },
    {
      age: 2,
      deaths: 88
    },
    {
      age: 3,
      deaths: 68
    },
    {
      age: 4,
      deaths: 57
    },
    {
      age: 5,
      deaths: 53
    },
    {
      age: 6,
      deaths: 48
    },
    {
      age: 7,
      deaths: 42
    },
    {
      age: 8,
      deaths: 39
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 36
    },
    {
      age: 12,
      deaths: 36
    },
    {
      age: 13,
      deaths: 45
    },
    {
      age: 14,
      deaths: 52
    },
    {
      age: 15,
      deaths: 67
    },
    {
      age: 16,
      deaths: 90
    },
    {
      age: 17,
      deaths: 111
    },
    {
      age: 18,
      deaths: 130
    },
    {
      age: 19,
      deaths: 133
    },
    {
      age: 20,
      deaths: 131
    },
    {
      age: 21,
      deaths: 146
    },
    {
      age: 22,
      deaths: 131
    },
    {
      age: 23,
      deaths: 135
    },
    {
      age: 24,
      deaths: 130
    },
    {
      age: 25,
      deaths: 137
    },
    {
      age: 26,
      deaths: 130
    },
    {
      age: 27,
      deaths: 136
    },
    {
      age: 28,
      deaths: 136
    },
    {
      age: 29,
      deaths: 143
    },
    {
      age: 30,
      deaths: 151
    },
    {
      age: 31,
      deaths: 155
    },
    {
      age: 32,
      deaths: 164
    },
    {
      age: 33,
      deaths: 172
    },
    {
      age: 34,
      deaths: 182
    },
    {
      age: 35,
      deaths: 205
    },
    {
      age: 36,
      deaths: 212
    },
    {
      age: 37,
      deaths: 232
    },
    {
      age: 38,
      deaths: 253
    },
    {
      age: 39,
      deaths: 266
    },
    {
      age: 40,
      deaths: 307
    },
    {
      age: 41,
      deaths: 322
    },
    {
      age: 42,
      deaths: 356
    },
    {
      age: 43,
      deaths: 389
    },
    {
      age: 44,
      deaths: 406
    },
    {
      age: 45,
      deaths: 454
    },
    {
      age: 46,
      deaths: 488
    },
    {
      age: 47,
      deaths: 533
    },
    {
      age: 48,
      deaths: 573
    },
    {
      age: 49,
      deaths: 617
    },
    {
      age: 50,
      deaths: 696
    },
    {
      age: 51,
      deaths: 728
    },
    {
      age: 52,
      deaths: 804
    },
    {
      age: 53,
      deaths: 850
    },
    {
      age: 54,
      deaths: 934
    },
    {
      age: 55,
      deaths: 998
    },
    {
      age: 56,
      deaths: 1053
    },
    {
      age: 57,
      deaths: 1157
    },
    {
      age: 58,
      deaths: 1231
    },
    {
      age: 59,
      deaths: 1270
    },
    {
      age: 60,
      deaths: 1428
    },
    {
      age: 61,
      deaths: 1457
    },
    {
      age: 62,
      deaths: 1596
    },
    {
      age: 63,
      deaths: 1645
    },
    {
      age: 64,
      deaths: 1729
    },
    {
      age: 65,
      deaths: 1900
    },
    {
      age: 66,
      deaths: 1866
    },
    {
      age: 67,
      deaths: 2124
    },
    {
      age: 68,
      deaths: 2134
    },
    {
      age: 69,
      deaths: 2118
    },
    {
      age: 70,
      deaths: 2394
    },
    {
      age: 71,
      deaths: 2379
    },
    {
      age: 72,
      deaths: 2625
    },
    {
      age: 73,
      deaths: 2579
    },
    {
      age: 74,
      deaths: 2708
    },
    {
      age: 75,
      deaths: 2854
    },
    {
      age: 76,
      deaths: 2858
    },
    {
      age: 77,
      deaths: 2861
    },
    {
      age: 78,
      deaths: 2909
    },
    {
      age: 79,
      deaths: 2921
    },
    {
      age: 80,
      deaths: 2993
    },
    {
      age: 81,
      deaths: 2787
    },
    {
      age: 82,
      deaths: 2895
    },
    {
      age: 83,
      deaths: 2841
    },
    {
      age: 84,
      deaths: 2771
    },
    {
      age: 85,
      deaths: 2631
    },
    {
      age: 86,
      deaths: 2451
    },
    {
      age: 87,
      deaths: 2291
    },
    {
      age: 88,
      deaths: 2100
    },
    {
      age: 89,
      deaths: 1854
    },
    {
      age: 90,
      deaths: 1666
    },
    {
      age: 91,
      deaths: 1390
    },
    {
      age: 92,
      deaths: 1206
    },
    {
      age: 93,
      deaths: 983
    },
    {
      age: 94,
      deaths: 781
    },
    {
      age: 95,
      deaths: 625
    },
    {
      age: 96,
      deaths: 482
    },
    {
      age: 97,
      deaths: 362
    },
    {
      age: 98,
      deaths: 264
    },
    {
      age: 99,
      deaths: 188
    },
    {
      age: 100,
      deaths: 130
    },
    {
      age: 101,
      deaths: 87
    },
    {
      age: 102,
      deaths: 57
    },
    {
      age: 103,
      deaths: 36
    },
    {
      age: 104,
      deaths: 22
    },
    {
      age: 105,
      deaths: 13
    },
    {
      age: 106,
      deaths: 7
    },
    {
      age: 107,
      deaths: 4
    },
    {
      age: 108,
      deaths: 2
    },
    {
      age: 109,
      deaths: 1
    },
    {
      age: 110,
      deaths: 1
    }
  ],
  1969: [
    {
      age: 0,
      deaths: 2129
    },
    {
      age: 1,
      deaths: 130
    },
    {
      age: 2,
      deaths: 87
    },
    {
      age: 3,
      deaths: 69
    },
    {
      age: 4,
      deaths: 59
    },
    {
      age: 5,
      deaths: 50
    },
    {
      age: 6,
      deaths: 45
    },
    {
      age: 7,
      deaths: 42
    },
    {
      age: 8,
      deaths: 39
    },
    {
      age: 9,
      deaths: 38
    },
    {
      age: 10,
      deaths: 33
    },
    {
      age: 11,
      deaths: 34
    },
    {
      age: 12,
      deaths: 38
    },
    {
      age: 13,
      deaths: 43
    },
    {
      age: 14,
      deaths: 55
    },
    {
      age: 15,
      deaths: 70
    },
    {
      age: 16,
      deaths: 94
    },
    {
      age: 17,
      deaths: 112
    },
    {
      age: 18,
      deaths: 138
    },
    {
      age: 19,
      deaths: 141
    },
    {
      age: 20,
      deaths: 137
    },
    {
      age: 21,
      deaths: 141
    },
    {
      age: 22,
      deaths: 151
    },
    {
      age: 23,
      deaths: 135
    },
    {
      age: 24,
      deaths: 140
    },
    {
      age: 25,
      deaths: 135
    },
    {
      age: 26,
      deaths: 135
    },
    {
      age: 27,
      deaths: 131
    },
    {
      age: 28,
      deaths: 147
    },
    {
      age: 29,
      deaths: 143
    },
    {
      age: 30,
      deaths: 148
    },
    {
      age: 31,
      deaths: 157
    },
    {
      age: 32,
      deaths: 163
    },
    {
      age: 33,
      deaths: 175
    },
    {
      age: 34,
      deaths: 187
    },
    {
      age: 35,
      deaths: 198
    },
    {
      age: 36,
      deaths: 215
    },
    {
      age: 37,
      deaths: 236
    },
    {
      age: 38,
      deaths: 249
    },
    {
      age: 39,
      deaths: 274
    },
    {
      age: 40,
      deaths: 298
    },
    {
      age: 41,
      deaths: 316
    },
    {
      age: 42,
      deaths: 364
    },
    {
      age: 43,
      deaths: 385
    },
    {
      age: 44,
      deaths: 417
    },
    {
      age: 45,
      deaths: 457
    },
    {
      age: 46,
      deaths: 481
    },
    {
      age: 47,
      deaths: 524
    },
    {
      age: 48,
      deaths: 558
    },
    {
      age: 49,
      deaths: 611
    },
    {
      age: 50,
      deaths: 654
    },
    {
      age: 51,
      deaths: 714
    },
    {
      age: 52,
      deaths: 779
    },
    {
      age: 53,
      deaths: 839
    },
    {
      age: 54,
      deaths: 914
    },
    {
      age: 55,
      deaths: 969
    },
    {
      age: 56,
      deaths: 1051
    },
    {
      age: 57,
      deaths: 1115
    },
    {
      age: 58,
      deaths: 1206
    },
    {
      age: 59,
      deaths: 1267
    },
    {
      age: 60,
      deaths: 1325
    },
    {
      age: 61,
      deaths: 1445
    },
    {
      age: 62,
      deaths: 1581
    },
    {
      age: 63,
      deaths: 1605
    },
    {
      age: 64,
      deaths: 1693
    },
    {
      age: 65,
      deaths: 1837
    },
    {
      age: 66,
      deaths: 1883
    },
    {
      age: 67,
      deaths: 1960
    },
    {
      age: 68,
      deaths: 2166
    },
    {
      age: 69,
      deaths: 2169
    },
    {
      age: 70,
      deaths: 2230
    },
    {
      age: 71,
      deaths: 2370
    },
    {
      age: 72,
      deaths: 2532
    },
    {
      age: 73,
      deaths: 2612
    },
    {
      age: 74,
      deaths: 2686
    },
    {
      age: 75,
      deaths: 2793
    },
    {
      age: 76,
      deaths: 2837
    },
    {
      age: 77,
      deaths: 2852
    },
    {
      age: 78,
      deaths: 2930
    },
    {
      age: 79,
      deaths: 2921
    },
    {
      age: 80,
      deaths: 2994
    },
    {
      age: 81,
      deaths: 2844
    },
    {
      age: 82,
      deaths: 2872
    },
    {
      age: 83,
      deaths: 2875
    },
    {
      age: 84,
      deaths: 2814
    },
    {
      age: 85,
      deaths: 2650
    },
    {
      age: 86,
      deaths: 2517
    },
    {
      age: 87,
      deaths: 2330
    },
    {
      age: 88,
      deaths: 2111
    },
    {
      age: 89,
      deaths: 1942
    },
    {
      age: 90,
      deaths: 1689
    },
    {
      age: 91,
      deaths: 1471
    },
    {
      age: 92,
      deaths: 1253
    },
    {
      age: 93,
      deaths: 1054
    },
    {
      age: 94,
      deaths: 860
    },
    {
      age: 95,
      deaths: 675
    },
    {
      age: 96,
      deaths: 526
    },
    {
      age: 97,
      deaths: 400
    },
    {
      age: 98,
      deaths: 296
    },
    {
      age: 99,
      deaths: 213
    },
    {
      age: 100,
      deaths: 149
    },
    {
      age: 101,
      deaths: 101
    },
    {
      age: 102,
      deaths: 67
    },
    {
      age: 103,
      deaths: 43
    },
    {
      age: 104,
      deaths: 27
    },
    {
      age: 105,
      deaths: 16
    },
    {
      age: 106,
      deaths: 9
    },
    {
      age: 107,
      deaths: 5
    },
    {
      age: 108,
      deaths: 3
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 2
    }
  ],
  1970: [
    {
      age: 0,
      deaths: 2063
    },
    {
      age: 1,
      deaths: 124
    },
    {
      age: 2,
      deaths: 82
    },
    {
      age: 3,
      deaths: 68
    },
    {
      age: 4,
      deaths: 56
    },
    {
      age: 5,
      deaths: 51
    },
    {
      age: 6,
      deaths: 46
    },
    {
      age: 7,
      deaths: 39
    },
    {
      age: 8,
      deaths: 37
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 33
    },
    {
      age: 11,
      deaths: 33
    },
    {
      age: 12,
      deaths: 36
    },
    {
      age: 13,
      deaths: 43
    },
    {
      age: 14,
      deaths: 53
    },
    {
      age: 15,
      deaths: 67
    },
    {
      age: 16,
      deaths: 89
    },
    {
      age: 17,
      deaths: 109
    },
    {
      age: 18,
      deaths: 130
    },
    {
      age: 19,
      deaths: 141
    },
    {
      age: 20,
      deaths: 138
    },
    {
      age: 21,
      deaths: 142
    },
    {
      age: 22,
      deaths: 148
    },
    {
      age: 23,
      deaths: 143
    },
    {
      age: 24,
      deaths: 129
    },
    {
      age: 25,
      deaths: 137
    },
    {
      age: 26,
      deaths: 134
    },
    {
      age: 27,
      deaths: 137
    },
    {
      age: 28,
      deaths: 138
    },
    {
      age: 29,
      deaths: 139
    },
    {
      age: 30,
      deaths: 147
    },
    {
      age: 31,
      deaths: 152
    },
    {
      age: 32,
      deaths: 163
    },
    {
      age: 33,
      deaths: 169
    },
    {
      age: 34,
      deaths: 185
    },
    {
      age: 35,
      deaths: 203
    },
    {
      age: 36,
      deaths: 210
    },
    {
      age: 37,
      deaths: 230
    },
    {
      age: 38,
      deaths: 245
    },
    {
      age: 39,
      deaths: 270
    },
    {
      age: 40,
      deaths: 297
    },
    {
      age: 41,
      deaths: 308
    },
    {
      age: 42,
      deaths: 357
    },
    {
      age: 43,
      deaths: 374
    },
    {
      age: 44,
      deaths: 411
    },
    {
      age: 45,
      deaths: 455
    },
    {
      age: 46,
      deaths: 485
    },
    {
      age: 47,
      deaths: 524
    },
    {
      age: 48,
      deaths: 554
    },
    {
      age: 49,
      deaths: 617
    },
    {
      age: 50,
      deaths: 661
    },
    {
      age: 51,
      deaths: 693
    },
    {
      age: 52,
      deaths: 785
    },
    {
      age: 53,
      deaths: 826
    },
    {
      age: 54,
      deaths: 909
    },
    {
      age: 55,
      deaths: 973
    },
    {
      age: 56,
      deaths: 1033
    },
    {
      age: 57,
      deaths: 1129
    },
    {
      age: 58,
      deaths: 1184
    },
    {
      age: 59,
      deaths: 1269
    },
    {
      age: 60,
      deaths: 1339
    },
    {
      age: 61,
      deaths: 1380
    },
    {
      age: 62,
      deaths: 1559
    },
    {
      age: 63,
      deaths: 1623
    },
    {
      age: 64,
      deaths: 1697
    },
    {
      age: 65,
      deaths: 1849
    },
    {
      age: 66,
      deaths: 1852
    },
    {
      age: 67,
      deaths: 1976
    },
    {
      age: 68,
      deaths: 2019
    },
    {
      age: 69,
      deaths: 2214
    },
    {
      age: 70,
      deaths: 2278
    },
    {
      age: 71,
      deaths: 2227
    },
    {
      age: 72,
      deaths: 2511
    },
    {
      age: 73,
      deaths: 2552
    },
    {
      age: 74,
      deaths: 2704
    },
    {
      age: 75,
      deaths: 2772
    },
    {
      age: 76,
      deaths: 2826
    },
    {
      age: 77,
      deaths: 2836
    },
    {
      age: 78,
      deaths: 2939
    },
    {
      age: 79,
      deaths: 2920
    },
    {
      age: 80,
      deaths: 2929
    },
    {
      age: 81,
      deaths: 2831
    },
    {
      age: 82,
      deaths: 2870
    },
    {
      age: 83,
      deaths: 2830
    },
    {
      age: 84,
      deaths: 2771
    },
    {
      age: 85,
      deaths: 2667
    },
    {
      age: 86,
      deaths: 2523
    },
    {
      age: 87,
      deaths: 2339
    },
    {
      age: 88,
      deaths: 2139
    },
    {
      age: 89,
      deaths: 1928
    },
    {
      age: 90,
      deaths: 1799
    },
    {
      age: 91,
      deaths: 1496
    },
    {
      age: 92,
      deaths: 1321
    },
    {
      age: 93,
      deaths: 1112
    },
    {
      age: 94,
      deaths: 932
    },
    {
      age: 95,
      deaths: 738
    },
    {
      age: 96,
      deaths: 585
    },
    {
      age: 97,
      deaths: 452
    },
    {
      age: 98,
      deaths: 341
    },
    {
      age: 99,
      deaths: 251
    },
    {
      age: 100,
      deaths: 180
    },
    {
      age: 101,
      deaths: 125
    },
    {
      age: 102,
      deaths: 85
    },
    {
      age: 103,
      deaths: 56
    },
    {
      age: 104,
      deaths: 36
    },
    {
      age: 105,
      deaths: 22
    },
    {
      age: 106,
      deaths: 13
    },
    {
      age: 107,
      deaths: 8
    },
    {
      age: 108,
      deaths: 4
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 3
    }
  ],
  1971: [
    {
      age: 0,
      deaths: 1890
    },
    {
      age: 1,
      deaths: 119
    },
    {
      age: 2,
      deaths: 80
    },
    {
      age: 3,
      deaths: 66
    },
    {
      age: 4,
      deaths: 56
    },
    {
      age: 5,
      deaths: 53
    },
    {
      age: 6,
      deaths: 44
    },
    {
      age: 7,
      deaths: 40
    },
    {
      age: 8,
      deaths: 36
    },
    {
      age: 9,
      deaths: 34
    },
    {
      age: 10,
      deaths: 34
    },
    {
      age: 11,
      deaths: 33
    },
    {
      age: 12,
      deaths: 35
    },
    {
      age: 13,
      deaths: 41
    },
    {
      age: 14,
      deaths: 52
    },
    {
      age: 15,
      deaths: 69
    },
    {
      age: 16,
      deaths: 90
    },
    {
      age: 17,
      deaths: 111
    },
    {
      age: 18,
      deaths: 132
    },
    {
      age: 19,
      deaths: 137
    },
    {
      age: 20,
      deaths: 134
    },
    {
      age: 21,
      deaths: 141
    },
    {
      age: 22,
      deaths: 147
    },
    {
      age: 23,
      deaths: 140
    },
    {
      age: 24,
      deaths: 142
    },
    {
      age: 25,
      deaths: 131
    },
    {
      age: 26,
      deaths: 136
    },
    {
      age: 27,
      deaths: 137
    },
    {
      age: 28,
      deaths: 137
    },
    {
      age: 29,
      deaths: 141
    },
    {
      age: 30,
      deaths: 147
    },
    {
      age: 31,
      deaths: 149
    },
    {
      age: 32,
      deaths: 158
    },
    {
      age: 33,
      deaths: 168
    },
    {
      age: 34,
      deaths: 181
    },
    {
      age: 35,
      deaths: 193
    },
    {
      age: 36,
      deaths: 212
    },
    {
      age: 37,
      deaths: 226
    },
    {
      age: 38,
      deaths: 247
    },
    {
      age: 39,
      deaths: 263
    },
    {
      age: 40,
      deaths: 285
    },
    {
      age: 41,
      deaths: 305
    },
    {
      age: 42,
      deaths: 339
    },
    {
      age: 43,
      deaths: 377
    },
    {
      age: 44,
      deaths: 398
    },
    {
      age: 45,
      deaths: 438
    },
    {
      age: 46,
      deaths: 487
    },
    {
      age: 47,
      deaths: 513
    },
    {
      age: 48,
      deaths: 546
    },
    {
      age: 49,
      deaths: 595
    },
    {
      age: 50,
      deaths: 650
    },
    {
      age: 51,
      deaths: 687
    },
    {
      age: 52,
      deaths: 743
    },
    {
      age: 53,
      deaths: 820
    },
    {
      age: 54,
      deaths: 882
    },
    {
      age: 55,
      deaths: 945
    },
    {
      age: 56,
      deaths: 1020
    },
    {
      age: 57,
      deaths: 1092
    },
    {
      age: 58,
      deaths: 1161
    },
    {
      age: 59,
      deaths: 1228
    },
    {
      age: 60,
      deaths: 1336
    },
    {
      age: 61,
      deaths: 1385
    },
    {
      age: 62,
      deaths: 1497
    },
    {
      age: 63,
      deaths: 1622
    },
    {
      age: 64,
      deaths: 1683
    },
    {
      age: 65,
      deaths: 1812
    },
    {
      age: 66,
      deaths: 1832
    },
    {
      age: 67,
      deaths: 1933
    },
    {
      age: 68,
      deaths: 2008
    },
    {
      age: 69,
      deaths: 2068
    },
    {
      age: 70,
      deaths: 2343
    },
    {
      age: 71,
      deaths: 2327
    },
    {
      age: 72,
      deaths: 2378
    },
    {
      age: 73,
      deaths: 2555
    },
    {
      age: 74,
      deaths: 2607
    },
    {
      age: 75,
      deaths: 2812
    },
    {
      age: 76,
      deaths: 2818
    },
    {
      age: 77,
      deaths: 2845
    },
    {
      age: 78,
      deaths: 2973
    },
    {
      age: 79,
      deaths: 2995
    },
    {
      age: 80,
      deaths: 2924
    },
    {
      age: 81,
      deaths: 2897
    },
    {
      age: 82,
      deaths: 2922
    },
    {
      age: 83,
      deaths: 2858
    },
    {
      age: 84,
      deaths: 2821
    },
    {
      age: 85,
      deaths: 2751
    },
    {
      age: 86,
      deaths: 2608
    },
    {
      age: 87,
      deaths: 2422
    },
    {
      age: 88,
      deaths: 2205
    },
    {
      age: 89,
      deaths: 1996
    },
    {
      age: 90,
      deaths: 1805
    },
    {
      age: 91,
      deaths: 1568
    },
    {
      age: 92,
      deaths: 1356
    },
    {
      age: 93,
      deaths: 1181
    },
    {
      age: 94,
      deaths: 943
    },
    {
      age: 95,
      deaths: 756
    },
    {
      age: 96,
      deaths: 596
    },
    {
      age: 97,
      deaths: 458
    },
    {
      age: 98,
      deaths: 343
    },
    {
      age: 99,
      deaths: 251
    },
    {
      age: 100,
      deaths: 178
    },
    {
      age: 101,
      deaths: 123
    },
    {
      age: 102,
      deaths: 82
    },
    {
      age: 103,
      deaths: 53
    },
    {
      age: 104,
      deaths: 34
    },
    {
      age: 105,
      deaths: 21
    },
    {
      age: 106,
      deaths: 12
    },
    {
      age: 107,
      deaths: 7
    },
    {
      age: 108,
      deaths: 4
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 2
    }
  ],
  1972: [
    {
      age: 0,
      deaths: 1787
    },
    {
      age: 1,
      deaths: 117
    },
    {
      age: 2,
      deaths: 81
    },
    {
      age: 3,
      deaths: 64
    },
    {
      age: 4,
      deaths: 52
    },
    {
      age: 5,
      deaths: 46
    },
    {
      age: 6,
      deaths: 43
    },
    {
      age: 7,
      deaths: 42
    },
    {
      age: 8,
      deaths: 35
    },
    {
      age: 9,
      deaths: 34
    },
    {
      age: 10,
      deaths: 36
    },
    {
      age: 11,
      deaths: 33
    },
    {
      age: 12,
      deaths: 34
    },
    {
      age: 13,
      deaths: 41
    },
    {
      age: 14,
      deaths: 54
    },
    {
      age: 15,
      deaths: 70
    },
    {
      age: 16,
      deaths: 93
    },
    {
      age: 17,
      deaths: 113
    },
    {
      age: 18,
      deaths: 129
    },
    {
      age: 19,
      deaths: 135
    },
    {
      age: 20,
      deaths: 138
    },
    {
      age: 21,
      deaths: 147
    },
    {
      age: 22,
      deaths: 139
    },
    {
      age: 23,
      deaths: 137
    },
    {
      age: 24,
      deaths: 141
    },
    {
      age: 25,
      deaths: 137
    },
    {
      age: 26,
      deaths: 124
    },
    {
      age: 27,
      deaths: 134
    },
    {
      age: 28,
      deaths: 136
    },
    {
      age: 29,
      deaths: 150
    },
    {
      age: 30,
      deaths: 142
    },
    {
      age: 31,
      deaths: 150
    },
    {
      age: 32,
      deaths: 159
    },
    {
      age: 33,
      deaths: 172
    },
    {
      age: 34,
      deaths: 177
    },
    {
      age: 35,
      deaths: 194
    },
    {
      age: 36,
      deaths: 205
    },
    {
      age: 37,
      deaths: 220
    },
    {
      age: 38,
      deaths: 237
    },
    {
      age: 39,
      deaths: 259
    },
    {
      age: 40,
      deaths: 279
    },
    {
      age: 41,
      deaths: 301
    },
    {
      age: 42,
      deaths: 346
    },
    {
      age: 43,
      deaths: 361
    },
    {
      age: 44,
      deaths: 400
    },
    {
      age: 45,
      deaths: 437
    },
    {
      age: 46,
      deaths: 484
    },
    {
      age: 47,
      deaths: 527
    },
    {
      age: 48,
      deaths: 559
    },
    {
      age: 49,
      deaths: 597
    },
    {
      age: 50,
      deaths: 646
    },
    {
      age: 51,
      deaths: 695
    },
    {
      age: 52,
      deaths: 747
    },
    {
      age: 53,
      deaths: 790
    },
    {
      age: 54,
      deaths: 872
    },
    {
      age: 55,
      deaths: 936
    },
    {
      age: 56,
      deaths: 1013
    },
    {
      age: 57,
      deaths: 1074
    },
    {
      age: 58,
      deaths: 1155
    },
    {
      age: 59,
      deaths: 1239
    },
    {
      age: 60,
      deaths: 1337
    },
    {
      age: 61,
      deaths: 1425
    },
    {
      age: 62,
      deaths: 1527
    },
    {
      age: 63,
      deaths: 1570
    },
    {
      age: 64,
      deaths: 1721
    },
    {
      age: 65,
      deaths: 1790
    },
    {
      age: 66,
      deaths: 1847
    },
    {
      age: 67,
      deaths: 1974
    },
    {
      age: 68,
      deaths: 2024
    },
    {
      age: 69,
      deaths: 2123
    },
    {
      age: 70,
      deaths: 2229
    },
    {
      age: 71,
      deaths: 2433
    },
    {
      age: 72,
      deaths: 2491
    },
    {
      age: 73,
      deaths: 2462
    },
    {
      age: 74,
      deaths: 2714
    },
    {
      age: 75,
      deaths: 2791
    },
    {
      age: 76,
      deaths: 2846
    },
    {
      age: 77,
      deaths: 2837
    },
    {
      age: 78,
      deaths: 2940
    },
    {
      age: 79,
      deaths: 3016
    },
    {
      age: 80,
      deaths: 2969
    },
    {
      age: 81,
      deaths: 2852
    },
    {
      age: 82,
      deaths: 2899
    },
    {
      age: 83,
      deaths: 2879
    },
    {
      age: 84,
      deaths: 2823
    },
    {
      age: 85,
      deaths: 2653
    },
    {
      age: 86,
      deaths: 2604
    },
    {
      age: 87,
      deaths: 2419
    },
    {
      age: 88,
      deaths: 2192
    },
    {
      age: 89,
      deaths: 2026
    },
    {
      age: 90,
      deaths: 1795
    },
    {
      age: 91,
      deaths: 1565
    },
    {
      age: 92,
      deaths: 1366
    },
    {
      age: 93,
      deaths: 1120
    },
    {
      age: 94,
      deaths: 985
    },
    {
      age: 95,
      deaths: 761
    },
    {
      age: 96,
      deaths: 601
    },
    {
      age: 97,
      deaths: 464
    },
    {
      age: 98,
      deaths: 348
    },
    {
      age: 99,
      deaths: 255
    },
    {
      age: 100,
      deaths: 181
    },
    {
      age: 101,
      deaths: 126
    },
    {
      age: 102,
      deaths: 84
    },
    {
      age: 103,
      deaths: 55
    },
    {
      age: 104,
      deaths: 35
    },
    {
      age: 105,
      deaths: 21
    },
    {
      age: 106,
      deaths: 13
    },
    {
      age: 107,
      deaths: 7
    },
    {
      age: 108,
      deaths: 4
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 2
    }
  ],
  1973: [
    {
      age: 0,
      deaths: 1764
    },
    {
      age: 1,
      deaths: 118
    },
    {
      age: 2,
      deaths: 80
    },
    {
      age: 3,
      deaths: 61
    },
    {
      age: 4,
      deaths: 54
    },
    {
      age: 5,
      deaths: 48
    },
    {
      age: 6,
      deaths: 43
    },
    {
      age: 7,
      deaths: 41
    },
    {
      age: 8,
      deaths: 35
    },
    {
      age: 9,
      deaths: 35
    },
    {
      age: 10,
      deaths: 33
    },
    {
      age: 11,
      deaths: 33
    },
    {
      age: 12,
      deaths: 36
    },
    {
      age: 13,
      deaths: 42
    },
    {
      age: 14,
      deaths: 53
    },
    {
      age: 15,
      deaths: 68
    },
    {
      age: 16,
      deaths: 94
    },
    {
      age: 17,
      deaths: 110
    },
    {
      age: 18,
      deaths: 135
    },
    {
      age: 19,
      deaths: 137
    },
    {
      age: 20,
      deaths: 143
    },
    {
      age: 21,
      deaths: 143
    },
    {
      age: 22,
      deaths: 142
    },
    {
      age: 23,
      deaths: 138
    },
    {
      age: 24,
      deaths: 136
    },
    {
      age: 25,
      deaths: 135
    },
    {
      age: 26,
      deaths: 135
    },
    {
      age: 27,
      deaths: 127
    },
    {
      age: 28,
      deaths: 142
    },
    {
      age: 29,
      deaths: 139
    },
    {
      age: 30,
      deaths: 153
    },
    {
      age: 31,
      deaths: 144
    },
    {
      age: 32,
      deaths: 157
    },
    {
      age: 33,
      deaths: 164
    },
    {
      age: 34,
      deaths: 172
    },
    {
      age: 35,
      deaths: 192
    },
    {
      age: 36,
      deaths: 203
    },
    {
      age: 37,
      deaths: 220
    },
    {
      age: 38,
      deaths: 237
    },
    {
      age: 39,
      deaths: 258
    },
    {
      age: 40,
      deaths: 285
    },
    {
      age: 41,
      deaths: 296
    },
    {
      age: 42,
      deaths: 329
    },
    {
      age: 43,
      deaths: 358
    },
    {
      age: 44,
      deaths: 378
    },
    {
      age: 45,
      deaths: 429
    },
    {
      age: 46,
      deaths: 473
    },
    {
      age: 47,
      deaths: 510
    },
    {
      age: 48,
      deaths: 558
    },
    {
      age: 49,
      deaths: 590
    },
    {
      age: 50,
      deaths: 639
    },
    {
      age: 51,
      deaths: 677
    },
    {
      age: 52,
      deaths: 734
    },
    {
      age: 53,
      deaths: 780
    },
    {
      age: 54,
      deaths: 840
    },
    {
      age: 55,
      deaths: 915
    },
    {
      age: 56,
      deaths: 993
    },
    {
      age: 57,
      deaths: 1080
    },
    {
      age: 58,
      deaths: 1131
    },
    {
      age: 59,
      deaths: 1232
    },
    {
      age: 60,
      deaths: 1333
    },
    {
      age: 61,
      deaths: 1377
    },
    {
      age: 62,
      deaths: 1534
    },
    {
      age: 63,
      deaths: 1577
    },
    {
      age: 64,
      deaths: 1657
    },
    {
      age: 65,
      deaths: 1785
    },
    {
      age: 66,
      deaths: 1818
    },
    {
      age: 67,
      deaths: 1934
    },
    {
      age: 68,
      deaths: 1992
    },
    {
      age: 69,
      deaths: 2090
    },
    {
      age: 70,
      deaths: 2272
    },
    {
      age: 71,
      deaths: 2255
    },
    {
      age: 72,
      deaths: 2569
    },
    {
      age: 73,
      deaths: 2525
    },
    {
      age: 74,
      deaths: 2542
    },
    {
      age: 75,
      deaths: 2746
    },
    {
      age: 76,
      deaths: 2753
    },
    {
      age: 77,
      deaths: 2861
    },
    {
      age: 78,
      deaths: 2911
    },
    {
      age: 79,
      deaths: 2954
    },
    {
      age: 80,
      deaths: 3031
    },
    {
      age: 81,
      deaths: 2916
    },
    {
      age: 82,
      deaths: 2929
    },
    {
      age: 83,
      deaths: 2948
    },
    {
      age: 84,
      deaths: 2889
    },
    {
      age: 85,
      deaths: 2775
    },
    {
      age: 86,
      deaths: 2606
    },
    {
      age: 87,
      deaths: 2472
    },
    {
      age: 88,
      deaths: 2287
    },
    {
      age: 89,
      deaths: 2089
    },
    {
      age: 90,
      deaths: 1880
    },
    {
      age: 91,
      deaths: 1583
    },
    {
      age: 92,
      deaths: 1405
    },
    {
      age: 93,
      deaths: 1215
    },
    {
      age: 94,
      deaths: 956
    },
    {
      age: 95,
      deaths: 783
    },
    {
      age: 96,
      deaths: 617
    },
    {
      age: 97,
      deaths: 474
    },
    {
      age: 98,
      deaths: 354
    },
    {
      age: 99,
      deaths: 258
    },
    {
      age: 100,
      deaths: 183
    },
    {
      age: 101,
      deaths: 125
    },
    {
      age: 102,
      deaths: 84
    },
    {
      age: 103,
      deaths: 54
    },
    {
      age: 104,
      deaths: 34
    },
    {
      age: 105,
      deaths: 21
    },
    {
      age: 106,
      deaths: 12
    },
    {
      age: 107,
      deaths: 7
    },
    {
      age: 108,
      deaths: 4
    },
    {
      age: 109,
      deaths: 2
    },
    {
      age: 110,
      deaths: 2
    }
  ],
  1974: [
    {
      age: 0,
      deaths: 1702
    },
    {
      age: 1,
      deaths: 110
    },
    {
      age: 2,
      deaths: 72
    },
    {
      age: 3,
      deaths: 61
    },
    {
      age: 4,
      deaths: 48
    },
    {
      age: 5,
      deaths: 42
    },
    {
      age: 6,
      deaths: 39
    },
    {
      age: 7,
      deaths: 37
    },
    {
      age: 8,
      deaths: 33
    },
    {
      age: 9,
      deaths: 32
    },
    {
      age: 10,
      deaths: 29
    },
    {
      age: 11,
      deaths: 30
    },
    {
      age: 12,
      deaths: 35
    },
    {
      age: 13,
      deaths: 42
    },
    {
      age: 14,
      deaths: 50
    },
    {
      age: 15,
      deaths: 62
    },
    {
      age: 16,
      deaths: 86
    },
    {
      age: 17,
      deaths: 106
    },
    {
      age: 18,
      deaths: 125
    },
    {
      age: 19,
      deaths: 134
    },
    {
      age: 20,
      deaths: 129
    },
    {
      age: 21,
      deaths: 138
    },
    {
      age: 22,
      deaths: 134
    },
    {
      age: 23,
      deaths: 134
    },
    {
      age: 24,
      deaths: 131
    },
    {
      age: 25,
      deaths: 132
    },
    {
      age: 26,
      deaths: 127
    },
    {
      age: 27,
      deaths: 135
    },
    {
      age: 28,
      deaths: 122
    },
    {
      age: 29,
      deaths: 133
    },
    {
      age: 30,
      deaths: 135
    },
    {
      age: 31,
      deaths: 143
    },
    {
      age: 32,
      deaths: 146
    },
    {
      age: 33,
      deaths: 159
    },
    {
      age: 34,
      deaths: 167
    },
    {
      age: 35,
      deaths: 174
    },
    {
      age: 36,
      deaths: 190
    },
    {
      age: 37,
      deaths: 205
    },
    {
      age: 38,
      deaths: 224
    },
    {
      age: 39,
      deaths: 242
    },
    {
      age: 40,
      deaths: 262
    },
    {
      age: 41,
      deaths: 282
    },
    {
      age: 42,
      deaths: 320
    },
    {
      age: 43,
      deaths: 338
    },
    {
      age: 44,
      deaths: 376
    },
    {
      age: 45,
      deaths: 409
    },
    {
      age: 46,
      deaths: 452
    },
    {
      age: 47,
      deaths: 486
    },
    {
      age: 48,
      deaths: 535
    },
    {
      age: 49,
      deaths: 582
    },
    {
      age: 50,
      deaths: 620
    },
    {
      age: 51,
      deaths: 660
    },
    {
      age: 52,
      deaths: 719
    },
    {
      age: 53,
      deaths: 763
    },
    {
      age: 54,
      deaths: 818
    },
    {
      age: 55,
      deaths: 868
    },
    {
      age: 56,
      deaths: 962
    },
    {
      age: 57,
      deaths: 1021
    },
    {
      age: 58,
      deaths: 1113
    },
    {
      age: 59,
      deaths: 1184
    },
    {
      age: 60,
      deaths: 1297
    },
    {
      age: 61,
      deaths: 1366
    },
    {
      age: 62,
      deaths: 1461
    },
    {
      age: 63,
      deaths: 1557
    },
    {
      age: 64,
      deaths: 1631
    },
    {
      age: 65,
      deaths: 1711
    },
    {
      age: 66,
      deaths: 1798
    },
    {
      age: 67,
      deaths: 1904
    },
    {
      age: 68,
      deaths: 1986
    },
    {
      age: 69,
      deaths: 2060
    },
    {
      age: 70,
      deaths: 2208
    },
    {
      age: 71,
      deaths: 2260
    },
    {
      age: 72,
      deaths: 2407
    },
    {
      age: 73,
      deaths: 2575
    },
    {
      age: 74,
      deaths: 2583
    },
    {
      age: 75,
      deaths: 2580
    },
    {
      age: 76,
      deaths: 2751
    },
    {
      age: 77,
      deaths: 2770
    },
    {
      age: 78,
      deaths: 2898
    },
    {
      age: 79,
      deaths: 2905
    },
    {
      age: 80,
      deaths: 3011
    },
    {
      age: 81,
      deaths: 2962
    },
    {
      age: 82,
      deaths: 2970
    },
    {
      age: 83,
      deaths: 2956
    },
    {
      age: 84,
      deaths: 2993
    },
    {
      age: 85,
      deaths: 2852
    },
    {
      age: 86,
      deaths: 2736
    },
    {
      age: 87,
      deaths: 2513
    },
    {
      age: 88,
      deaths: 2413
    },
    {
      age: 89,
      deaths: 2180
    },
    {
      age: 90,
      deaths: 1953
    },
    {
      age: 91,
      deaths: 1706
    },
    {
      age: 92,
      deaths: 1510
    },
    {
      age: 93,
      deaths: 1283
    },
    {
      age: 94,
      deaths: 1082
    },
    {
      age: 95,
      deaths: 866
    },
    {
      age: 96,
      deaths: 689
    },
    {
      age: 97,
      deaths: 535
    },
    {
      age: 98,
      deaths: 405
    },
    {
      age: 99,
      deaths: 298
    },
    {
      age: 100,
      deaths: 213
    },
    {
      age: 101,
      deaths: 148
    },
    {
      age: 102,
      deaths: 100
    },
    {
      age: 103,
      deaths: 66
    },
    {
      age: 104,
      deaths: 42
    },
    {
      age: 105,
      deaths: 26
    },
    {
      age: 106,
      deaths: 15
    },
    {
      age: 107,
      deaths: 9
    },
    {
      age: 108,
      deaths: 5
    },
    {
      age: 109,
      deaths: 3
    },
    {
      age: 110,
      deaths: 3
    }
  ],
  1975: [
    {
      age: 0,
      deaths: 1621
    },
    {
      age: 1,
      deaths: 105
    },
    {
      age: 2,
      deaths: 71
    },
    {
      age: 3,
      deaths: 54
    },
    {
      age: 4,
      deaths: 48
    },
    {
      age: 5,
      deaths: 41
    },
    {
      age: 6,
      deaths: 37
    },
    {
      age: 7,
      deaths: 35
    },
    {
      age: 8,
      deaths: 30
    },
    {
      age: 9,
      deaths: 29
    },
    {
      age: 10,
      deaths: 29
    },
    {
      age: 11,
      deaths: 30
    },
    {
      age: 12,
      deaths: 31
    },
    {
      age: 13,
      deaths: 36
    },
    {
      age: 14,
      deaths: 48
    },
    {
      age: 15,
      deaths: 62
    },
    {
      age: 16,
      deaths: 78
    },
    {
      age: 17,
      deaths: 101
    },
    {
      age: 18,
      deaths: 122
    },
    {
      age: 19,
      deaths: 127
    },
    {
      age: 20,
      deaths: 129
    },
    {
      age: 21,
      deaths: 134
    },
    {
      age: 22,
      deaths: 131
    },
    {
      age: 23,
      deaths: 135
    },
    {
      age: 24,
      deaths: 131
    },
    {
      age: 25,
      deaths: 130
    },
    {
      age: 26,
      deaths: 130
    },
    {
      age: 27,
      deaths: 131
    },
    {
      age: 28,
      deaths: 130
    },
    {
      age: 29,
      deaths: 121
    },
    {
      age: 30,
      deaths: 133
    },
    {
      age: 31,
      deaths: 137
    },
    {
      age: 32,
      deaths: 143
    },
    {
      age: 33,
      deaths: 148
    },
    {
      age: 34,
      deaths: 154
    },
    {
      age: 35,
      deaths: 171
    },
    {
      age: 36,
      deaths: 181
    },
    {
      age: 37,
      deaths: 198
    },
    {
      age: 38,
      deaths: 209
    },
    {
      age: 39,
      deaths: 235
    },
    {
      age: 40,
      deaths: 258
    },
    {
      age: 41,
      deaths: 272
    },
    {
      age: 42,
      deaths: 313
    },
    {
      age: 43,
      deaths: 325
    },
    {
      age: 44,
      deaths: 350
    },
    {
      age: 45,
      deaths: 392
    },
    {
      age: 46,
      deaths: 417
    },
    {
      age: 47,
      deaths: 481
    },
    {
      age: 48,
      deaths: 508
    },
    {
      age: 49,
      deaths: 550
    },
    {
      age: 50,
      deaths: 606
    },
    {
      age: 51,
      deaths: 643
    },
    {
      age: 52,
      deaths: 689
    },
    {
      age: 53,
      deaths: 737
    },
    {
      age: 54,
      deaths: 810
    },
    {
      age: 55,
      deaths: 866
    },
    {
      age: 56,
      deaths: 914
    },
    {
      age: 57,
      deaths: 1020
    },
    {
      age: 58,
      deaths: 1088
    },
    {
      age: 59,
      deaths: 1146
    },
    {
      age: 60,
      deaths: 1246
    },
    {
      age: 61,
      deaths: 1299
    },
    {
      age: 62,
      deaths: 1446
    },
    {
      age: 63,
      deaths: 1499
    },
    {
      age: 64,
      deaths: 1610
    },
    {
      age: 65,
      deaths: 1678
    },
    {
      age: 66,
      deaths: 1710
    },
    {
      age: 67,
      deaths: 1885
    },
    {
      age: 68,
      deaths: 1961
    },
    {
      age: 69,
      deaths: 2027
    },
    {
      age: 70,
      deaths: 2147
    },
    {
      age: 71,
      deaths: 2205
    },
    {
      age: 72,
      deaths: 2369
    },
    {
      age: 73,
      deaths: 2375
    },
    {
      age: 74,
      deaths: 2622
    },
    {
      age: 75,
      deaths: 2634
    },
    {
      age: 76,
      deaths: 2621
    },
    {
      age: 77,
      deaths: 2798
    },
    {
      age: 78,
      deaths: 2861
    },
    {
      age: 79,
      deaths: 2940
    },
    {
      age: 80,
      deaths: 2984
    },
    {
      age: 81,
      deaths: 2915
    },
    {
      age: 82,
      deaths: 3022
    },
    {
      age: 83,
      deaths: 3005
    },
    {
      age: 84,
      deaths: 2911
    },
    {
      age: 85,
      deaths: 2847
    },
    {
      age: 86,
      deaths: 2742
    },
    {
      age: 87,
      deaths: 2600
    },
    {
      age: 88,
      deaths: 2411
    },
    {
      age: 89,
      deaths: 2265
    },
    {
      age: 90,
      deaths: 2086
    },
    {
      age: 91,
      deaths: 1827
    },
    {
      age: 92,
      deaths: 1609
    },
    {
      age: 93,
      deaths: 1417
    },
    {
      age: 94,
      deaths: 1188
    },
    {
      age: 95,
      deaths: 980
    },
    {
      age: 96,
      deaths: 795
    },
    {
      age: 97,
      deaths: 631
    },
    {
      age: 98,
      deaths: 488
    },
    {
      age: 99,
      deaths: 369
    },
    {
      age: 100,
      deaths: 271
    },
    {
      age: 101,
      deaths: 194
    },
    {
      age: 102,
      deaths: 135
    },
    {
      age: 103,
      deaths: 91
    },
    {
      age: 104,
      deaths: 60
    },
    {
      age: 105,
      deaths: 38
    },
    {
      age: 106,
      deaths: 24
    },
    {
      age: 107,
      deaths: 14
    },
    {
      age: 108,
      deaths: 8
    },
    {
      age: 109,
      deaths: 5
    },
    {
      age: 110,
      deaths: 5
    }
  ],
  1976: [
    {
      age: 0,
      deaths: 1539
    },
    {
      age: 1,
      deaths: 102
    },
    {
      age: 2,
      deaths: 69
    },
    {
      age: 3,
      deaths: 55
    },
    {
      age: 4,
      deaths: 45
    },
    {
      age: 5,
      deaths: 41
    },
    {
      age: 6,
      deaths: 36
    },
    {
      age: 7,
      deaths: 33
    },
    {
      age: 8,
      deaths: 30
    },
    {
      age: 9,
      deaths: 28
    },
    {
      age: 10,
      deaths: 28
    },
    {
      age: 11,
      deaths: 26
    },
    {
      age: 12,
      deaths: 30
    },
    {
      age: 13,
      deaths: 37
    },
    {
      age: 14,
      deaths: 46
    },
    {
      age: 15,
      deaths: 56
    },
    {
      age: 16,
      deaths: 77
    },
    {
      age: 17,
      deaths: 96
    },
    {
      age: 18,
      deaths: 118
    },
    {
      age: 19,
      deaths: 123
    },
    {
      age: 20,
      deaths: 124
    },
    {
      age: 21,
      deaths: 125
    },
    {
      age: 22,
      deaths: 128
    },
    {
      age: 23,
      deaths: 123
    },
    {
      age: 24,
      deaths: 125
    },
    {
      age: 25,
      deaths: 123
    },
    {
      age: 26,
      deaths: 121
    },
    {
      age: 27,
      deaths: 122
    },
    {
      age: 28,
      deaths: 123
    },
    {
      age: 29,
      deaths: 127
    },
    {
      age: 30,
      deaths: 118
    },
    {
      age: 31,
      deaths: 129
    },
    {
      age: 32,
      deaths: 135
    },
    {
      age: 33,
      deaths: 142
    },
    {
      age: 34,
      deaths: 149
    },
    {
      age: 35,
      deaths: 159
    },
    {
      age: 36,
      deaths: 169
    },
    {
      age: 37,
      deaths: 186
    },
    {
      age: 38,
      deaths: 209
    },
    {
      age: 39,
      deaths: 221
    },
    {
      age: 40,
      deaths: 245
    },
    {
      age: 41,
      deaths: 262
    },
    {
      age: 42,
      deaths: 291
    },
    {
      age: 43,
      deaths: 323
    },
    {
      age: 44,
      deaths: 347
    },
    {
      age: 45,
      deaths: 386
    },
    {
      age: 46,
      deaths: 414
    },
    {
      age: 47,
      deaths: 449
    },
    {
      age: 48,
      deaths: 497
    },
    {
      age: 49,
      deaths: 542
    },
    {
      age: 50,
      deaths: 590
    },
    {
      age: 51,
      deaths: 632
    },
    {
      age: 52,
      deaths: 691
    },
    {
      age: 53,
      deaths: 735
    },
    {
      age: 54,
      deaths: 782
    },
    {
      age: 55,
      deaths: 848
    },
    {
      age: 56,
      deaths: 931
    },
    {
      age: 57,
      deaths: 980
    },
    {
      age: 58,
      deaths: 1074
    },
    {
      age: 59,
      deaths: 1145
    },
    {
      age: 60,
      deaths: 1231
    },
    {
      age: 61,
      deaths: 1308
    },
    {
      age: 62,
      deaths: 1435
    },
    {
      age: 63,
      deaths: 1506
    },
    {
      age: 64,
      deaths: 1569
    },
    {
      age: 65,
      deaths: 1676
    },
    {
      age: 66,
      deaths: 1715
    },
    {
      age: 67,
      deaths: 1825
    },
    {
      age: 68,
      deaths: 1942
    },
    {
      age: 69,
      deaths: 2054
    },
    {
      age: 70,
      deaths: 2156
    },
    {
      age: 71,
      deaths: 2177
    },
    {
      age: 72,
      deaths: 2314
    },
    {
      age: 73,
      deaths: 2419
    },
    {
      age: 74,
      deaths: 2422
    },
    {
      age: 75,
      deaths: 2735
    },
    {
      age: 76,
      deaths: 2686
    },
    {
      age: 77,
      deaths: 2727
    },
    {
      age: 78,
      deaths: 2905
    },
    {
      age: 79,
      deaths: 2927
    },
    {
      age: 80,
      deaths: 3006
    },
    {
      age: 81,
      deaths: 2931
    },
    {
      age: 82,
      deaths: 3009
    },
    {
      age: 83,
      deaths: 3071
    },
    {
      age: 84,
      deaths: 2989
    },
    {
      age: 85,
      deaths: 2876
    },
    {
      age: 86,
      deaths: 2847
    },
    {
      age: 87,
      deaths: 2702
    },
    {
      age: 88,
      deaths: 2479
    },
    {
      age: 89,
      deaths: 2325
    },
    {
      age: 90,
      deaths: 2148
    },
    {
      age: 91,
      deaths: 1867
    },
    {
      age: 92,
      deaths: 1680
    },
    {
      age: 93,
      deaths: 1442
    },
    {
      age: 94,
      deaths: 1215
    },
    {
      age: 95,
      deaths: 995
    },
    {
      age: 96,
      deaths: 801
    },
    {
      age: 97,
      deaths: 629
    },
    {
      age: 98,
      deaths: 482
    },
    {
      age: 99,
      deaths: 359
    },
    {
      age: 100,
      deaths: 260
    },
    {
      age: 101,
      deaths: 183
    },
    {
      age: 102,
      deaths: 125
    },
    {
      age: 103,
      deaths: 83
    },
    {
      age: 104,
      deaths: 53
    },
    {
      age: 105,
      deaths: 33
    },
    {
      age: 106,
      deaths: 20
    },
    {
      age: 107,
      deaths: 12
    },
    {
      age: 108,
      deaths: 7
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  1977: [
    {
      age: 0,
      deaths: 1458
    },
    {
      age: 1,
      deaths: 100
    },
    {
      age: 2,
      deaths: 70
    },
    {
      age: 3,
      deaths: 51
    },
    {
      age: 4,
      deaths: 42
    },
    {
      age: 5,
      deaths: 40
    },
    {
      age: 6,
      deaths: 35
    },
    {
      age: 7,
      deaths: 30
    },
    {
      age: 8,
      deaths: 31
    },
    {
      age: 9,
      deaths: 29
    },
    {
      age: 10,
      deaths: 27
    },
    {
      age: 11,
      deaths: 29
    },
    {
      age: 12,
      deaths: 31
    },
    {
      age: 13,
      deaths: 36
    },
    {
      age: 14,
      deaths: 45
    },
    {
      age: 15,
      deaths: 60
    },
    {
      age: 16,
      deaths: 81
    },
    {
      age: 17,
      deaths: 97
    },
    {
      age: 18,
      deaths: 121
    },
    {
      age: 19,
      deaths: 128
    },
    {
      age: 20,
      deaths: 128
    },
    {
      age: 21,
      deaths: 129
    },
    {
      age: 22,
      deaths: 130
    },
    {
      age: 23,
      deaths: 123
    },
    {
      age: 24,
      deaths: 125
    },
    {
      age: 25,
      deaths: 122
    },
    {
      age: 26,
      deaths: 124
    },
    {
      age: 27,
      deaths: 121
    },
    {
      age: 28,
      deaths: 122
    },
    {
      age: 29,
      deaths: 126
    },
    {
      age: 30,
      deaths: 127
    },
    {
      age: 31,
      deaths: 120
    },
    {
      age: 32,
      deaths: 138
    },
    {
      age: 33,
      deaths: 136
    },
    {
      age: 34,
      deaths: 152
    },
    {
      age: 35,
      deaths: 158
    },
    {
      age: 36,
      deaths: 173
    },
    {
      age: 37,
      deaths: 183
    },
    {
      age: 38,
      deaths: 199
    },
    {
      age: 39,
      deaths: 214
    },
    {
      age: 40,
      deaths: 228
    },
    {
      age: 41,
      deaths: 260
    },
    {
      age: 42,
      deaths: 290
    },
    {
      age: 43,
      deaths: 314
    },
    {
      age: 44,
      deaths: 337
    },
    {
      age: 45,
      deaths: 370
    },
    {
      age: 46,
      deaths: 398
    },
    {
      age: 47,
      deaths: 432
    },
    {
      age: 48,
      deaths: 476
    },
    {
      age: 49,
      deaths: 539
    },
    {
      age: 50,
      deaths: 577
    },
    {
      age: 51,
      deaths: 618
    },
    {
      age: 52,
      deaths: 681
    },
    {
      age: 53,
      deaths: 727
    },
    {
      age: 54,
      deaths: 765
    },
    {
      age: 55,
      deaths: 850
    },
    {
      age: 56,
      deaths: 900
    },
    {
      age: 57,
      deaths: 976
    },
    {
      age: 58,
      deaths: 1021
    },
    {
      age: 59,
      deaths: 1124
    },
    {
      age: 60,
      deaths: 1193
    },
    {
      age: 61,
      deaths: 1267
    },
    {
      age: 62,
      deaths: 1395
    },
    {
      age: 63,
      deaths: 1473
    },
    {
      age: 64,
      deaths: 1547
    },
    {
      age: 65,
      deaths: 1633
    },
    {
      age: 66,
      deaths: 1703
    },
    {
      age: 67,
      deaths: 1806
    },
    {
      age: 68,
      deaths: 1880
    },
    {
      age: 69,
      deaths: 2030
    },
    {
      age: 70,
      deaths: 2106
    },
    {
      age: 71,
      deaths: 2185
    },
    {
      age: 72,
      deaths: 2278
    },
    {
      age: 73,
      deaths: 2393
    },
    {
      age: 74,
      deaths: 2474
    },
    {
      age: 75,
      deaths: 2549
    },
    {
      age: 76,
      deaths: 2739
    },
    {
      age: 77,
      deaths: 2744
    },
    {
      age: 78,
      deaths: 2746
    },
    {
      age: 79,
      deaths: 2939
    },
    {
      age: 80,
      deaths: 2989
    },
    {
      age: 81,
      deaths: 2957
    },
    {
      age: 82,
      deaths: 2984
    },
    {
      age: 83,
      deaths: 3036
    },
    {
      age: 84,
      deaths: 3034
    },
    {
      age: 85,
      deaths: 2898
    },
    {
      age: 86,
      deaths: 2805
    },
    {
      age: 87,
      deaths: 2706
    },
    {
      age: 88,
      deaths: 2545
    },
    {
      age: 89,
      deaths: 2372
    },
    {
      age: 90,
      deaths: 2190
    },
    {
      age: 91,
      deaths: 1977
    },
    {
      age: 92,
      deaths: 1764
    },
    {
      age: 93,
      deaths: 1513
    },
    {
      age: 94,
      deaths: 1281
    },
    {
      age: 95,
      deaths: 1078
    },
    {
      age: 96,
      deaths: 881
    },
    {
      age: 97,
      deaths: 703
    },
    {
      age: 98,
      deaths: 548
    },
    {
      age: 99,
      deaths: 417
    },
    {
      age: 100,
      deaths: 308
    },
    {
      age: 101,
      deaths: 222
    },
    {
      age: 102,
      deaths: 155
    },
    {
      age: 103,
      deaths: 106
    },
    {
      age: 104,
      deaths: 70
    },
    {
      age: 105,
      deaths: 45
    },
    {
      age: 106,
      deaths: 28
    },
    {
      age: 107,
      deaths: 17
    },
    {
      age: 108,
      deaths: 10
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1978: [
    {
      age: 0,
      deaths: 1389
    },
    {
      age: 1,
      deaths: 102
    },
    {
      age: 2,
      deaths: 68
    },
    {
      age: 3,
      deaths: 52
    },
    {
      age: 4,
      deaths: 42
    },
    {
      age: 5,
      deaths: 38
    },
    {
      age: 6,
      deaths: 36
    },
    {
      age: 7,
      deaths: 32
    },
    {
      age: 8,
      deaths: 29
    },
    {
      age: 9,
      deaths: 27
    },
    {
      age: 10,
      deaths: 27
    },
    {
      age: 11,
      deaths: 27
    },
    {
      age: 12,
      deaths: 29
    },
    {
      age: 13,
      deaths: 38
    },
    {
      age: 14,
      deaths: 42
    },
    {
      age: 15,
      deaths: 57
    },
    {
      age: 16,
      deaths: 80
    },
    {
      age: 17,
      deaths: 100
    },
    {
      age: 18,
      deaths: 118
    },
    {
      age: 19,
      deaths: 126
    },
    {
      age: 20,
      deaths: 127
    },
    {
      age: 21,
      deaths: 133
    },
    {
      age: 22,
      deaths: 129
    },
    {
      age: 23,
      deaths: 128
    },
    {
      age: 24,
      deaths: 122
    },
    {
      age: 25,
      deaths: 125
    },
    {
      age: 26,
      deaths: 121
    },
    {
      age: 27,
      deaths: 124
    },
    {
      age: 28,
      deaths: 121
    },
    {
      age: 29,
      deaths: 125
    },
    {
      age: 30,
      deaths: 122
    },
    {
      age: 31,
      deaths: 127
    },
    {
      age: 32,
      deaths: 125
    },
    {
      age: 33,
      deaths: 138
    },
    {
      age: 34,
      deaths: 145
    },
    {
      age: 35,
      deaths: 159
    },
    {
      age: 36,
      deaths: 166
    },
    {
      age: 37,
      deaths: 182
    },
    {
      age: 38,
      deaths: 192
    },
    {
      age: 39,
      deaths: 211
    },
    {
      age: 40,
      deaths: 227
    },
    {
      age: 41,
      deaths: 252
    },
    {
      age: 42,
      deaths: 281
    },
    {
      age: 43,
      deaths: 306
    },
    {
      age: 44,
      deaths: 326
    },
    {
      age: 45,
      deaths: 373
    },
    {
      age: 46,
      deaths: 392
    },
    {
      age: 47,
      deaths: 435
    },
    {
      age: 48,
      deaths: 465
    },
    {
      age: 49,
      deaths: 505
    },
    {
      age: 50,
      deaths: 568
    },
    {
      age: 51,
      deaths: 608
    },
    {
      age: 52,
      deaths: 660
    },
    {
      age: 53,
      deaths: 716
    },
    {
      age: 54,
      deaths: 773
    },
    {
      age: 55,
      deaths: 820
    },
    {
      age: 56,
      deaths: 888
    },
    {
      age: 57,
      deaths: 955
    },
    {
      age: 58,
      deaths: 1041
    },
    {
      age: 59,
      deaths: 1081
    },
    {
      age: 60,
      deaths: 1185
    },
    {
      age: 61,
      deaths: 1257
    },
    {
      age: 62,
      deaths: 1372
    },
    {
      age: 63,
      deaths: 1456
    },
    {
      age: 64,
      deaths: 1545
    },
    {
      age: 65,
      deaths: 1621
    },
    {
      age: 66,
      deaths: 1685
    },
    {
      age: 67,
      deaths: 1820
    },
    {
      age: 68,
      deaths: 1892
    },
    {
      age: 69,
      deaths: 1979
    },
    {
      age: 70,
      deaths: 2104
    },
    {
      age: 71,
      deaths: 2172
    },
    {
      age: 72,
      deaths: 2318
    },
    {
      age: 73,
      deaths: 2368
    },
    {
      age: 74,
      deaths: 2488
    },
    {
      age: 75,
      deaths: 2624
    },
    {
      age: 76,
      deaths: 2614
    },
    {
      age: 77,
      deaths: 2804
    },
    {
      age: 78,
      deaths: 2795
    },
    {
      age: 79,
      deaths: 2797
    },
    {
      age: 80,
      deaths: 2997
    },
    {
      age: 81,
      deaths: 3013
    },
    {
      age: 82,
      deaths: 3053
    },
    {
      age: 83,
      deaths: 3018
    },
    {
      age: 84,
      deaths: 3021
    },
    {
      age: 85,
      deaths: 2954
    },
    {
      age: 86,
      deaths: 2877
    },
    {
      age: 87,
      deaths: 2702
    },
    {
      age: 88,
      deaths: 2594
    },
    {
      age: 89,
      deaths: 2419
    },
    {
      age: 90,
      deaths: 2252
    },
    {
      age: 91,
      deaths: 1962
    },
    {
      age: 92,
      deaths: 1796
    },
    {
      age: 93,
      deaths: 1557
    },
    {
      age: 94,
      deaths: 1314
    },
    {
      age: 95,
      deaths: 1094
    },
    {
      age: 96,
      deaths: 892
    },
    {
      age: 97,
      deaths: 710
    },
    {
      age: 98,
      deaths: 552
    },
    {
      age: 99,
      deaths: 418
    },
    {
      age: 100,
      deaths: 308
    },
    {
      age: 101,
      deaths: 220
    },
    {
      age: 102,
      deaths: 153
    },
    {
      age: 103,
      deaths: 104
    },
    {
      age: 104,
      deaths: 68
    },
    {
      age: 105,
      deaths: 43
    },
    {
      age: 106,
      deaths: 27
    },
    {
      age: 107,
      deaths: 16
    },
    {
      age: 108,
      deaths: 9
    },
    {
      age: 109,
      deaths: 5
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1979: [
    {
      age: 0,
      deaths: 1336
    },
    {
      age: 1,
      deaths: 93
    },
    {
      age: 2,
      deaths: 63
    },
    {
      age: 3,
      deaths: 51
    },
    {
      age: 4,
      deaths: 42
    },
    {
      age: 5,
      deaths: 38
    },
    {
      age: 6,
      deaths: 34
    },
    {
      age: 7,
      deaths: 28
    },
    {
      age: 8,
      deaths: 28
    },
    {
      age: 9,
      deaths: 26
    },
    {
      age: 10,
      deaths: 25
    },
    {
      age: 11,
      deaths: 25
    },
    {
      age: 12,
      deaths: 29
    },
    {
      age: 13,
      deaths: 34
    },
    {
      age: 14,
      deaths: 42
    },
    {
      age: 15,
      deaths: 57
    },
    {
      age: 16,
      deaths: 80
    },
    {
      age: 17,
      deaths: 99
    },
    {
      age: 18,
      deaths: 120
    },
    {
      age: 19,
      deaths: 125
    },
    {
      age: 20,
      deaths: 127
    },
    {
      age: 21,
      deaths: 128
    },
    {
      age: 22,
      deaths: 130
    },
    {
      age: 23,
      deaths: 128
    },
    {
      age: 24,
      deaths: 125
    },
    {
      age: 25,
      deaths: 127
    },
    {
      age: 26,
      deaths: 125
    },
    {
      age: 27,
      deaths: 127
    },
    {
      age: 28,
      deaths: 125
    },
    {
      age: 29,
      deaths: 123
    },
    {
      age: 30,
      deaths: 125
    },
    {
      age: 31,
      deaths: 128
    },
    {
      age: 32,
      deaths: 133
    },
    {
      age: 33,
      deaths: 129
    },
    {
      age: 34,
      deaths: 142
    },
    {
      age: 35,
      deaths: 155
    },
    {
      age: 36,
      deaths: 169
    },
    {
      age: 37,
      deaths: 172
    },
    {
      age: 38,
      deaths: 189
    },
    {
      age: 39,
      deaths: 201
    },
    {
      age: 40,
      deaths: 218
    },
    {
      age: 41,
      deaths: 237
    },
    {
      age: 42,
      deaths: 267
    },
    {
      age: 43,
      deaths: 293
    },
    {
      age: 44,
      deaths: 323
    },
    {
      age: 45,
      deaths: 342
    },
    {
      age: 46,
      deaths: 392
    },
    {
      age: 47,
      deaths: 415
    },
    {
      age: 48,
      deaths: 449
    },
    {
      age: 49,
      deaths: 492
    },
    {
      age: 50,
      deaths: 540
    },
    {
      age: 51,
      deaths: 591
    },
    {
      age: 52,
      deaths: 643
    },
    {
      age: 53,
      deaths: 699
    },
    {
      age: 54,
      deaths: 750
    },
    {
      age: 55,
      deaths: 800
    },
    {
      age: 56,
      deaths: 846
    },
    {
      age: 57,
      deaths: 924
    },
    {
      age: 58,
      deaths: 995
    },
    {
      age: 59,
      deaths: 1077
    },
    {
      age: 60,
      deaths: 1128
    },
    {
      age: 61,
      deaths: 1221
    },
    {
      age: 62,
      deaths: 1336
    },
    {
      age: 63,
      deaths: 1407
    },
    {
      age: 64,
      deaths: 1505
    },
    {
      age: 65,
      deaths: 1609
    },
    {
      age: 66,
      deaths: 1655
    },
    {
      age: 67,
      deaths: 1733
    },
    {
      age: 68,
      deaths: 1862
    },
    {
      age: 69,
      deaths: 1959
    },
    {
      age: 70,
      deaths: 2048
    },
    {
      age: 71,
      deaths: 2160
    },
    {
      age: 72,
      deaths: 2286
    },
    {
      age: 73,
      deaths: 2365
    },
    {
      age: 74,
      deaths: 2457
    },
    {
      age: 75,
      deaths: 2576
    },
    {
      age: 76,
      deaths: 2629
    },
    {
      age: 77,
      deaths: 2641
    },
    {
      age: 78,
      deaths: 2846
    },
    {
      age: 79,
      deaths: 2832
    },
    {
      age: 80,
      deaths: 2964
    },
    {
      age: 81,
      deaths: 3002
    },
    {
      age: 82,
      deaths: 3034
    },
    {
      age: 83,
      deaths: 3066
    },
    {
      age: 84,
      deaths: 3047
    },
    {
      age: 85,
      deaths: 2961
    },
    {
      age: 86,
      deaths: 2937
    },
    {
      age: 87,
      deaths: 2782
    },
    {
      age: 88,
      deaths: 2582
    },
    {
      age: 89,
      deaths: 2472
    },
    {
      age: 90,
      deaths: 2312
    },
    {
      age: 91,
      deaths: 2049
    },
    {
      age: 92,
      deaths: 1828
    },
    {
      age: 93,
      deaths: 1646
    },
    {
      age: 94,
      deaths: 1418
    },
    {
      age: 95,
      deaths: 1179
    },
    {
      age: 96,
      deaths: 972
    },
    {
      age: 97,
      deaths: 784
    },
    {
      age: 98,
      deaths: 617
    },
    {
      age: 99,
      deaths: 474
    },
    {
      age: 100,
      deaths: 354
    },
    {
      age: 101,
      deaths: 258
    },
    {
      age: 102,
      deaths: 183
    },
    {
      age: 103,
      deaths: 126
    },
    {
      age: 104,
      deaths: 84
    },
    {
      age: 105,
      deaths: 54
    },
    {
      age: 106,
      deaths: 34
    },
    {
      age: 107,
      deaths: 21
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  1980: [
    {
      age: 0,
      deaths: 1279
    },
    {
      age: 1,
      deaths: 97
    },
    {
      age: 2,
      deaths: 64
    },
    {
      age: 3,
      deaths: 50
    },
    {
      age: 4,
      deaths: 38
    },
    {
      age: 5,
      deaths: 34
    },
    {
      age: 6,
      deaths: 32
    },
    {
      age: 7,
      deaths: 30
    },
    {
      age: 8,
      deaths: 28
    },
    {
      age: 9,
      deaths: 27
    },
    {
      age: 10,
      deaths: 25
    },
    {
      age: 11,
      deaths: 25
    },
    {
      age: 12,
      deaths: 28
    },
    {
      age: 13,
      deaths: 31
    },
    {
      age: 14,
      deaths: 41
    },
    {
      age: 15,
      deaths: 55
    },
    {
      age: 16,
      deaths: 81
    },
    {
      age: 17,
      deaths: 98
    },
    {
      age: 18,
      deaths: 121
    },
    {
      age: 19,
      deaths: 123
    },
    {
      age: 20,
      deaths: 127
    },
    {
      age: 21,
      deaths: 131
    },
    {
      age: 22,
      deaths: 129
    },
    {
      age: 23,
      deaths: 130
    },
    {
      age: 24,
      deaths: 128
    },
    {
      age: 25,
      deaths: 128
    },
    {
      age: 26,
      deaths: 127
    },
    {
      age: 27,
      deaths: 128
    },
    {
      age: 28,
      deaths: 128
    },
    {
      age: 29,
      deaths: 124
    },
    {
      age: 30,
      deaths: 127
    },
    {
      age: 31,
      deaths: 128
    },
    {
      age: 32,
      deaths: 132
    },
    {
      age: 33,
      deaths: 141
    },
    {
      age: 34,
      deaths: 139
    },
    {
      age: 35,
      deaths: 152
    },
    {
      age: 36,
      deaths: 162
    },
    {
      age: 37,
      deaths: 174
    },
    {
      age: 38,
      deaths: 187
    },
    {
      age: 39,
      deaths: 203
    },
    {
      age: 40,
      deaths: 224
    },
    {
      age: 41,
      deaths: 241
    },
    {
      age: 42,
      deaths: 263
    },
    {
      age: 43,
      deaths: 284
    },
    {
      age: 44,
      deaths: 311
    },
    {
      age: 45,
      deaths: 354
    },
    {
      age: 46,
      deaths: 366
    },
    {
      age: 47,
      deaths: 412
    },
    {
      age: 48,
      deaths: 463
    },
    {
      age: 49,
      deaths: 480
    },
    {
      age: 50,
      deaths: 538
    },
    {
      age: 51,
      deaths: 589
    },
    {
      age: 52,
      deaths: 634
    },
    {
      age: 53,
      deaths: 694
    },
    {
      age: 54,
      deaths: 746
    },
    {
      age: 55,
      deaths: 810
    },
    {
      age: 56,
      deaths: 870
    },
    {
      age: 57,
      deaths: 915
    },
    {
      age: 58,
      deaths: 1006
    },
    {
      age: 59,
      deaths: 1081
    },
    {
      age: 60,
      deaths: 1167
    },
    {
      age: 61,
      deaths: 1202
    },
    {
      age: 62,
      deaths: 1355
    },
    {
      age: 63,
      deaths: 1402
    },
    {
      age: 64,
      deaths: 1491
    },
    {
      age: 65,
      deaths: 1614
    },
    {
      age: 66,
      deaths: 1711
    },
    {
      age: 67,
      deaths: 1797
    },
    {
      age: 68,
      deaths: 1865
    },
    {
      age: 69,
      deaths: 1986
    },
    {
      age: 70,
      deaths: 2126
    },
    {
      age: 71,
      deaths: 2150
    },
    {
      age: 72,
      deaths: 2344
    },
    {
      age: 73,
      deaths: 2427
    },
    {
      age: 74,
      deaths: 2465
    },
    {
      age: 75,
      deaths: 2616
    },
    {
      age: 76,
      deaths: 2689
    },
    {
      age: 77,
      deaths: 2759
    },
    {
      age: 78,
      deaths: 2784
    },
    {
      age: 79,
      deaths: 2827
    },
    {
      age: 80,
      deaths: 3065
    },
    {
      age: 81,
      deaths: 3012
    },
    {
      age: 82,
      deaths: 3068
    },
    {
      age: 83,
      deaths: 3069
    },
    {
      age: 84,
      deaths: 3060
    },
    {
      age: 85,
      deaths: 2980
    },
    {
      age: 86,
      deaths: 2930
    },
    {
      age: 87,
      deaths: 2798
    },
    {
      age: 88,
      deaths: 2592
    },
    {
      age: 89,
      deaths: 2424
    },
    {
      age: 90,
      deaths: 2296
    },
    {
      age: 91,
      deaths: 2044
    },
    {
      age: 92,
      deaths: 1809
    },
    {
      age: 93,
      deaths: 1578
    },
    {
      age: 94,
      deaths: 1364
    },
    {
      age: 95,
      deaths: 1119
    },
    {
      age: 96,
      deaths: 912
    },
    {
      age: 97,
      deaths: 726
    },
    {
      age: 98,
      deaths: 563
    },
    {
      age: 99,
      deaths: 426
    },
    {
      age: 100,
      deaths: 313
    },
    {
      age: 101,
      deaths: 224
    },
    {
      age: 102,
      deaths: 155
    },
    {
      age: 103,
      deaths: 105
    },
    {
      age: 104,
      deaths: 68
    },
    {
      age: 105,
      deaths: 43
    },
    {
      age: 106,
      deaths: 27
    },
    {
      age: 107,
      deaths: 16
    },
    {
      age: 108,
      deaths: 9
    },
    {
      age: 109,
      deaths: 5
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1981: [
    {
      age: 0,
      deaths: 1190
    },
    {
      age: 1,
      deaths: 92
    },
    {
      age: 2,
      deaths: 62
    },
    {
      age: 3,
      deaths: 46
    },
    {
      age: 4,
      deaths: 39
    },
    {
      age: 5,
      deaths: 33
    },
    {
      age: 6,
      deaths: 32
    },
    {
      age: 7,
      deaths: 27
    },
    {
      age: 8,
      deaths: 28
    },
    {
      age: 9,
      deaths: 23
    },
    {
      age: 10,
      deaths: 23
    },
    {
      age: 11,
      deaths: 25
    },
    {
      age: 12,
      deaths: 28
    },
    {
      age: 13,
      deaths: 32
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 48
    },
    {
      age: 16,
      deaths: 70
    },
    {
      age: 17,
      deaths: 91
    },
    {
      age: 18,
      deaths: 112
    },
    {
      age: 19,
      deaths: 113
    },
    {
      age: 20,
      deaths: 115
    },
    {
      age: 21,
      deaths: 120
    },
    {
      age: 22,
      deaths: 123
    },
    {
      age: 23,
      deaths: 121
    },
    {
      age: 24,
      deaths: 123
    },
    {
      age: 25,
      deaths: 124
    },
    {
      age: 26,
      deaths: 122
    },
    {
      age: 27,
      deaths: 122
    },
    {
      age: 28,
      deaths: 132
    },
    {
      age: 29,
      deaths: 124
    },
    {
      age: 30,
      deaths: 128
    },
    {
      age: 31,
      deaths: 127
    },
    {
      age: 32,
      deaths: 131
    },
    {
      age: 33,
      deaths: 135
    },
    {
      age: 34,
      deaths: 145
    },
    {
      age: 35,
      deaths: 138
    },
    {
      age: 36,
      deaths: 162
    },
    {
      age: 37,
      deaths: 168
    },
    {
      age: 38,
      deaths: 195
    },
    {
      age: 39,
      deaths: 194
    },
    {
      age: 40,
      deaths: 213
    },
    {
      age: 41,
      deaths: 240
    },
    {
      age: 42,
      deaths: 260
    },
    {
      age: 43,
      deaths: 278
    },
    {
      age: 44,
      deaths: 296
    },
    {
      age: 45,
      deaths: 339
    },
    {
      age: 46,
      deaths: 374
    },
    {
      age: 47,
      deaths: 398
    },
    {
      age: 48,
      deaths: 464
    },
    {
      age: 49,
      deaths: 471
    },
    {
      age: 50,
      deaths: 522
    },
    {
      age: 51,
      deaths: 581
    },
    {
      age: 52,
      deaths: 628
    },
    {
      age: 53,
      deaths: 676
    },
    {
      age: 54,
      deaths: 730
    },
    {
      age: 55,
      deaths: 805
    },
    {
      age: 56,
      deaths: 869
    },
    {
      age: 57,
      deaths: 913
    },
    {
      age: 58,
      deaths: 985
    },
    {
      age: 59,
      deaths: 1055
    },
    {
      age: 60,
      deaths: 1123
    },
    {
      age: 61,
      deaths: 1231
    },
    {
      age: 62,
      deaths: 1329
    },
    {
      age: 63,
      deaths: 1409
    },
    {
      age: 64,
      deaths: 1443
    },
    {
      age: 65,
      deaths: 1572
    },
    {
      age: 66,
      deaths: 1696
    },
    {
      age: 67,
      deaths: 1779
    },
    {
      age: 68,
      deaths: 1859
    },
    {
      age: 69,
      deaths: 1922
    },
    {
      age: 70,
      deaths: 2089
    },
    {
      age: 71,
      deaths: 2173
    },
    {
      age: 72,
      deaths: 2267
    },
    {
      age: 73,
      deaths: 2439
    },
    {
      age: 74,
      deaths: 2430
    },
    {
      age: 75,
      deaths: 2563
    },
    {
      age: 76,
      deaths: 2672
    },
    {
      age: 77,
      deaths: 2705
    },
    {
      age: 78,
      deaths: 2812
    },
    {
      age: 79,
      deaths: 2692
    },
    {
      age: 80,
      deaths: 3077
    },
    {
      age: 81,
      deaths: 3089
    },
    {
      age: 82,
      deaths: 3044
    },
    {
      age: 83,
      deaths: 3067
    },
    {
      age: 84,
      deaths: 3040
    },
    {
      age: 85,
      deaths: 3015
    },
    {
      age: 86,
      deaths: 2949
    },
    {
      age: 87,
      deaths: 2817
    },
    {
      age: 88,
      deaths: 2679
    },
    {
      age: 89,
      deaths: 2496
    },
    {
      age: 90,
      deaths: 2294
    },
    {
      age: 91,
      deaths: 2112
    },
    {
      age: 92,
      deaths: 1890
    },
    {
      age: 93,
      deaths: 1659
    },
    {
      age: 94,
      deaths: 1407
    },
    {
      age: 95,
      deaths: 1186
    },
    {
      age: 96,
      deaths: 976
    },
    {
      age: 97,
      deaths: 785
    },
    {
      age: 98,
      deaths: 616
    },
    {
      age: 99,
      deaths: 472
    },
    {
      age: 100,
      deaths: 352
    },
    {
      age: 101,
      deaths: 255
    },
    {
      age: 102,
      deaths: 180
    },
    {
      age: 103,
      deaths: 123
    },
    {
      age: 104,
      deaths: 82
    },
    {
      age: 105,
      deaths: 53
    },
    {
      age: 106,
      deaths: 33
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  1982: [
    {
      age: 0,
      deaths: 1153
    },
    {
      age: 1,
      deaths: 89
    },
    {
      age: 2,
      deaths: 59
    },
    {
      age: 3,
      deaths: 46
    },
    {
      age: 4,
      deaths: 35
    },
    {
      age: 5,
      deaths: 32
    },
    {
      age: 6,
      deaths: 29
    },
    {
      age: 7,
      deaths: 28
    },
    {
      age: 8,
      deaths: 25
    },
    {
      age: 9,
      deaths: 25
    },
    {
      age: 10,
      deaths: 22
    },
    {
      age: 11,
      deaths: 23
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 30
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 47
    },
    {
      age: 16,
      deaths: 66
    },
    {
      age: 17,
      deaths: 85
    },
    {
      age: 18,
      deaths: 106
    },
    {
      age: 19,
      deaths: 108
    },
    {
      age: 20,
      deaths: 107
    },
    {
      age: 21,
      deaths: 114
    },
    {
      age: 22,
      deaths: 114
    },
    {
      age: 23,
      deaths: 115
    },
    {
      age: 24,
      deaths: 113
    },
    {
      age: 25,
      deaths: 112
    },
    {
      age: 26,
      deaths: 112
    },
    {
      age: 27,
      deaths: 116
    },
    {
      age: 28,
      deaths: 120
    },
    {
      age: 29,
      deaths: 117
    },
    {
      age: 30,
      deaths: 119
    },
    {
      age: 31,
      deaths: 126
    },
    {
      age: 32,
      deaths: 130
    },
    {
      age: 33,
      deaths: 132
    },
    {
      age: 34,
      deaths: 129
    },
    {
      age: 35,
      deaths: 145
    },
    {
      age: 36,
      deaths: 149
    },
    {
      age: 37,
      deaths: 161
    },
    {
      age: 38,
      deaths: 180
    },
    {
      age: 39,
      deaths: 188
    },
    {
      age: 40,
      deaths: 201
    },
    {
      age: 41,
      deaths: 225
    },
    {
      age: 42,
      deaths: 244
    },
    {
      age: 43,
      deaths: 263
    },
    {
      age: 44,
      deaths: 285
    },
    {
      age: 45,
      deaths: 317
    },
    {
      age: 46,
      deaths: 349
    },
    {
      age: 47,
      deaths: 387
    },
    {
      age: 48,
      deaths: 434
    },
    {
      age: 49,
      deaths: 474
    },
    {
      age: 50,
      deaths: 499
    },
    {
      age: 51,
      deaths: 562
    },
    {
      age: 52,
      deaths: 632
    },
    {
      age: 53,
      deaths: 649
    },
    {
      age: 54,
      deaths: 702
    },
    {
      age: 55,
      deaths: 768
    },
    {
      age: 56,
      deaths: 849
    },
    {
      age: 57,
      deaths: 892
    },
    {
      age: 58,
      deaths: 1001
    },
    {
      age: 59,
      deaths: 1025
    },
    {
      age: 60,
      deaths: 1111
    },
    {
      age: 61,
      deaths: 1194
    },
    {
      age: 62,
      deaths: 1376
    },
    {
      age: 63,
      deaths: 1363
    },
    {
      age: 64,
      deaths: 1445
    },
    {
      age: 65,
      deaths: 1537
    },
    {
      age: 66,
      deaths: 1653
    },
    {
      age: 67,
      deaths: 1769
    },
    {
      age: 68,
      deaths: 1888
    },
    {
      age: 69,
      deaths: 1929
    },
    {
      age: 70,
      deaths: 2044
    },
    {
      age: 71,
      deaths: 2168
    },
    {
      age: 72,
      deaths: 2280
    },
    {
      age: 73,
      deaths: 2396
    },
    {
      age: 74,
      deaths: 2437
    },
    {
      age: 75,
      deaths: 2545
    },
    {
      age: 76,
      deaths: 2645
    },
    {
      age: 77,
      deaths: 2734
    },
    {
      age: 78,
      deaths: 2782
    },
    {
      age: 79,
      deaths: 2870
    },
    {
      age: 80,
      deaths: 2952
    },
    {
      age: 81,
      deaths: 3103
    },
    {
      age: 82,
      deaths: 3119
    },
    {
      age: 83,
      deaths: 3066
    },
    {
      age: 84,
      deaths: 3066
    },
    {
      age: 85,
      deaths: 2991
    },
    {
      age: 86,
      deaths: 2963
    },
    {
      age: 87,
      deaths: 2829
    },
    {
      age: 88,
      deaths: 2670
    },
    {
      age: 89,
      deaths: 2534
    },
    {
      age: 90,
      deaths: 2327
    },
    {
      age: 91,
      deaths: 2101
    },
    {
      age: 92,
      deaths: 1927
    },
    {
      age: 93,
      deaths: 1707
    },
    {
      age: 94,
      deaths: 1485
    },
    {
      age: 95,
      deaths: 1246
    },
    {
      age: 96,
      deaths: 1034
    },
    {
      age: 97,
      deaths: 839
    },
    {
      age: 98,
      deaths: 665
    },
    {
      age: 99,
      deaths: 514
    },
    {
      age: 100,
      deaths: 387
    },
    {
      age: 101,
      deaths: 284
    },
    {
      age: 102,
      deaths: 203
    },
    {
      age: 103,
      deaths: 141
    },
    {
      age: 104,
      deaths: 95
    },
    {
      age: 105,
      deaths: 62
    },
    {
      age: 106,
      deaths: 39
    },
    {
      age: 107,
      deaths: 24
    },
    {
      age: 108,
      deaths: 15
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 10
    }
  ],
  1983: [
    {
      age: 0,
      deaths: 1107
    },
    {
      age: 1,
      deaths: 85
    },
    {
      age: 2,
      deaths: 58
    },
    {
      age: 3,
      deaths: 44
    },
    {
      age: 4,
      deaths: 35
    },
    {
      age: 5,
      deaths: 31
    },
    {
      age: 6,
      deaths: 27
    },
    {
      age: 7,
      deaths: 27
    },
    {
      age: 8,
      deaths: 25
    },
    {
      age: 9,
      deaths: 21
    },
    {
      age: 10,
      deaths: 21
    },
    {
      age: 11,
      deaths: 22
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 29
    },
    {
      age: 14,
      deaths: 36
    },
    {
      age: 15,
      deaths: 46
    },
    {
      age: 16,
      deaths: 64
    },
    {
      age: 17,
      deaths: 80
    },
    {
      age: 18,
      deaths: 98
    },
    {
      age: 19,
      deaths: 103
    },
    {
      age: 20,
      deaths: 102
    },
    {
      age: 21,
      deaths: 105
    },
    {
      age: 22,
      deaths: 107
    },
    {
      age: 23,
      deaths: 110
    },
    {
      age: 24,
      deaths: 104
    },
    {
      age: 25,
      deaths: 107
    },
    {
      age: 26,
      deaths: 110
    },
    {
      age: 27,
      deaths: 110
    },
    {
      age: 28,
      deaths: 119
    },
    {
      age: 29,
      deaths: 111
    },
    {
      age: 30,
      deaths: 114
    },
    {
      age: 31,
      deaths: 121
    },
    {
      age: 32,
      deaths: 125
    },
    {
      age: 33,
      deaths: 134
    },
    {
      age: 34,
      deaths: 131
    },
    {
      age: 35,
      deaths: 136
    },
    {
      age: 36,
      deaths: 148
    },
    {
      age: 37,
      deaths: 154
    },
    {
      age: 38,
      deaths: 180
    },
    {
      age: 39,
      deaths: 178
    },
    {
      age: 40,
      deaths: 201
    },
    {
      age: 41,
      deaths: 221
    },
    {
      age: 42,
      deaths: 241
    },
    {
      age: 43,
      deaths: 262
    },
    {
      age: 44,
      deaths: 276
    },
    {
      age: 45,
      deaths: 310
    },
    {
      age: 46,
      deaths: 338
    },
    {
      age: 47,
      deaths: 377
    },
    {
      age: 48,
      deaths: 434
    },
    {
      age: 49,
      deaths: 445
    },
    {
      age: 50,
      deaths: 504
    },
    {
      age: 51,
      deaths: 557
    },
    {
      age: 52,
      deaths: 599
    },
    {
      age: 53,
      deaths: 665
    },
    {
      age: 54,
      deaths: 692
    },
    {
      age: 55,
      deaths: 777
    },
    {
      age: 56,
      deaths: 847
    },
    {
      age: 57,
      deaths: 894
    },
    {
      age: 58,
      deaths: 1005
    },
    {
      age: 59,
      deaths: 1044
    },
    {
      age: 60,
      deaths: 1104
    },
    {
      age: 61,
      deaths: 1192
    },
    {
      age: 62,
      deaths: 1347
    },
    {
      age: 63,
      deaths: 1430
    },
    {
      age: 64,
      deaths: 1426
    },
    {
      age: 65,
      deaths: 1551
    },
    {
      age: 66,
      deaths: 1622
    },
    {
      age: 67,
      deaths: 1737
    },
    {
      age: 68,
      deaths: 1890
    },
    {
      age: 69,
      deaths: 1963
    },
    {
      age: 70,
      deaths: 2099
    },
    {
      age: 71,
      deaths: 2130
    },
    {
      age: 72,
      deaths: 2312
    },
    {
      age: 73,
      deaths: 2448
    },
    {
      age: 74,
      deaths: 2461
    },
    {
      age: 75,
      deaths: 2596
    },
    {
      age: 76,
      deaths: 2671
    },
    {
      age: 77,
      deaths: 2707
    },
    {
      age: 78,
      deaths: 2820
    },
    {
      age: 79,
      deaths: 2902
    },
    {
      age: 80,
      deaths: 3017
    },
    {
      age: 81,
      deaths: 2990
    },
    {
      age: 82,
      deaths: 3172
    },
    {
      age: 83,
      deaths: 3179
    },
    {
      age: 84,
      deaths: 3060
    },
    {
      age: 85,
      deaths: 3026
    },
    {
      age: 86,
      deaths: 2972
    },
    {
      age: 87,
      deaths: 2874
    },
    {
      age: 88,
      deaths: 2673
    },
    {
      age: 89,
      deaths: 2545
    },
    {
      age: 90,
      deaths: 2376
    },
    {
      age: 91,
      deaths: 2110
    },
    {
      age: 92,
      deaths: 1918
    },
    {
      age: 93,
      deaths: 1726
    },
    {
      age: 94,
      deaths: 1455
    },
    {
      age: 95,
      deaths: 1225
    },
    {
      age: 96,
      deaths: 1009
    },
    {
      age: 97,
      deaths: 811
    },
    {
      age: 98,
      deaths: 637
    },
    {
      age: 99,
      deaths: 487
    },
    {
      age: 100,
      deaths: 362
    },
    {
      age: 101,
      deaths: 262
    },
    {
      age: 102,
      deaths: 185
    },
    {
      age: 103,
      deaths: 126
    },
    {
      age: 104,
      deaths: 83
    },
    {
      age: 105,
      deaths: 54
    },
    {
      age: 106,
      deaths: 33
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  1984: [
    {
      age: 0,
      deaths: 1082
    },
    {
      age: 1,
      deaths: 82
    },
    {
      age: 2,
      deaths: 54
    },
    {
      age: 3,
      deaths: 39
    },
    {
      age: 4,
      deaths: 32
    },
    {
      age: 5,
      deaths: 28
    },
    {
      age: 6,
      deaths: 26
    },
    {
      age: 7,
      deaths: 25
    },
    {
      age: 8,
      deaths: 24
    },
    {
      age: 9,
      deaths: 21
    },
    {
      age: 10,
      deaths: 23
    },
    {
      age: 11,
      deaths: 23
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 31
    },
    {
      age: 14,
      deaths: 36
    },
    {
      age: 15,
      deaths: 48
    },
    {
      age: 16,
      deaths: 66
    },
    {
      age: 17,
      deaths: 79
    },
    {
      age: 18,
      deaths: 96
    },
    {
      age: 19,
      deaths: 100
    },
    {
      age: 20,
      deaths: 103
    },
    {
      age: 21,
      deaths: 110
    },
    {
      age: 22,
      deaths: 107
    },
    {
      age: 23,
      deaths: 110
    },
    {
      age: 24,
      deaths: 107
    },
    {
      age: 25,
      deaths: 107
    },
    {
      age: 26,
      deaths: 108
    },
    {
      age: 27,
      deaths: 110
    },
    {
      age: 28,
      deaths: 115
    },
    {
      age: 29,
      deaths: 109
    },
    {
      age: 30,
      deaths: 118
    },
    {
      age: 31,
      deaths: 125
    },
    {
      age: 32,
      deaths: 125
    },
    {
      age: 33,
      deaths: 134
    },
    {
      age: 34,
      deaths: 135
    },
    {
      age: 35,
      deaths: 141
    },
    {
      age: 36,
      deaths: 151
    },
    {
      age: 37,
      deaths: 165
    },
    {
      age: 38,
      deaths: 171
    },
    {
      age: 39,
      deaths: 182
    },
    {
      age: 40,
      deaths: 203
    },
    {
      age: 41,
      deaths: 223
    },
    {
      age: 42,
      deaths: 239
    },
    {
      age: 43,
      deaths: 258
    },
    {
      age: 44,
      deaths: 283
    },
    {
      age: 45,
      deaths: 307
    },
    {
      age: 46,
      deaths: 336
    },
    {
      age: 47,
      deaths: 367
    },
    {
      age: 48,
      deaths: 419
    },
    {
      age: 49,
      deaths: 450
    },
    {
      age: 50,
      deaths: 483
    },
    {
      age: 51,
      deaths: 558
    },
    {
      age: 52,
      deaths: 598
    },
    {
      age: 53,
      deaths: 636
    },
    {
      age: 54,
      deaths: 681
    },
    {
      age: 55,
      deaths: 748
    },
    {
      age: 56,
      deaths: 823
    },
    {
      age: 57,
      deaths: 886
    },
    {
      age: 58,
      deaths: 999
    },
    {
      age: 59,
      deaths: 1042
    },
    {
      age: 60,
      deaths: 1114
    },
    {
      age: 61,
      deaths: 1176
    },
    {
      age: 62,
      deaths: 1333
    },
    {
      age: 63,
      deaths: 1396
    },
    {
      age: 64,
      deaths: 1457
    },
    {
      age: 65,
      deaths: 1522
    },
    {
      age: 66,
      deaths: 1642
    },
    {
      age: 67,
      deaths: 1704
    },
    {
      age: 68,
      deaths: 1855
    },
    {
      age: 69,
      deaths: 1959
    },
    {
      age: 70,
      deaths: 2087
    },
    {
      age: 71,
      deaths: 2163
    },
    {
      age: 72,
      deaths: 2273
    },
    {
      age: 73,
      deaths: 2418
    },
    {
      age: 74,
      deaths: 2461
    },
    {
      age: 75,
      deaths: 2535
    },
    {
      age: 76,
      deaths: 2701
    },
    {
      age: 77,
      deaths: 2708
    },
    {
      age: 78,
      deaths: 2807
    },
    {
      age: 79,
      deaths: 2891
    },
    {
      age: 80,
      deaths: 3018
    },
    {
      age: 81,
      deaths: 3031
    },
    {
      age: 82,
      deaths: 3033
    },
    {
      age: 83,
      deaths: 3172
    },
    {
      age: 84,
      deaths: 3137
    },
    {
      age: 85,
      deaths: 3034
    },
    {
      age: 86,
      deaths: 2993
    },
    {
      age: 87,
      deaths: 2889
    },
    {
      age: 88,
      deaths: 2723
    },
    {
      age: 89,
      deaths: 2566
    },
    {
      age: 90,
      deaths: 2393
    },
    {
      age: 91,
      deaths: 2171
    },
    {
      age: 92,
      deaths: 1958
    },
    {
      age: 93,
      deaths: 1717
    },
    {
      age: 94,
      deaths: 1494
    },
    {
      age: 95,
      deaths: 1255
    },
    {
      age: 96,
      deaths: 1034
    },
    {
      age: 97,
      deaths: 832
    },
    {
      age: 98,
      deaths: 653
    },
    {
      age: 99,
      deaths: 500
    },
    {
      age: 100,
      deaths: 372
    },
    {
      age: 101,
      deaths: 269
    },
    {
      age: 102,
      deaths: 189
    },
    {
      age: 103,
      deaths: 129
    },
    {
      age: 104,
      deaths: 85
    },
    {
      age: 105,
      deaths: 55
    },
    {
      age: 106,
      deaths: 34
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  1985: [
    {
      age: 0,
      deaths: 1083
    },
    {
      age: 1,
      deaths: 80
    },
    {
      age: 2,
      deaths: 54
    },
    {
      age: 3,
      deaths: 39
    },
    {
      age: 4,
      deaths: 32
    },
    {
      age: 5,
      deaths: 28
    },
    {
      age: 6,
      deaths: 27
    },
    {
      age: 7,
      deaths: 23
    },
    {
      age: 8,
      deaths: 23
    },
    {
      age: 9,
      deaths: 22
    },
    {
      age: 10,
      deaths: 20
    },
    {
      age: 11,
      deaths: 22
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 30
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 49
    },
    {
      age: 16,
      deaths: 68
    },
    {
      age: 17,
      deaths: 78
    },
    {
      age: 18,
      deaths: 99
    },
    {
      age: 19,
      deaths: 100
    },
    {
      age: 20,
      deaths: 98
    },
    {
      age: 21,
      deaths: 108
    },
    {
      age: 22,
      deaths: 107
    },
    {
      age: 23,
      deaths: 108
    },
    {
      age: 24,
      deaths: 107
    },
    {
      age: 25,
      deaths: 108
    },
    {
      age: 26,
      deaths: 107
    },
    {
      age: 27,
      deaths: 110
    },
    {
      age: 28,
      deaths: 120
    },
    {
      age: 29,
      deaths: 111
    },
    {
      age: 30,
      deaths: 120
    },
    {
      age: 31,
      deaths: 125
    },
    {
      age: 32,
      deaths: 134
    },
    {
      age: 33,
      deaths: 139
    },
    {
      age: 34,
      deaths: 138
    },
    {
      age: 35,
      deaths: 148
    },
    {
      age: 36,
      deaths: 158
    },
    {
      age: 37,
      deaths: 163
    },
    {
      age: 38,
      deaths: 188
    },
    {
      age: 39,
      deaths: 171
    },
    {
      age: 40,
      deaths: 200
    },
    {
      age: 41,
      deaths: 220
    },
    {
      age: 42,
      deaths: 250
    },
    {
      age: 43,
      deaths: 254
    },
    {
      age: 44,
      deaths: 277
    },
    {
      age: 45,
      deaths: 307
    },
    {
      age: 46,
      deaths: 335
    },
    {
      age: 47,
      deaths: 364
    },
    {
      age: 48,
      deaths: 425
    },
    {
      age: 49,
      deaths: 435
    },
    {
      age: 50,
      deaths: 490
    },
    {
      age: 51,
      deaths: 541
    },
    {
      age: 52,
      deaths: 601
    },
    {
      age: 53,
      deaths: 640
    },
    {
      age: 54,
      deaths: 675
    },
    {
      age: 55,
      deaths: 763
    },
    {
      age: 56,
      deaths: 805
    },
    {
      age: 57,
      deaths: 879
    },
    {
      age: 58,
      deaths: 1003
    },
    {
      age: 59,
      deaths: 1024
    },
    {
      age: 60,
      deaths: 1133
    },
    {
      age: 61,
      deaths: 1195
    },
    {
      age: 62,
      deaths: 1315
    },
    {
      age: 63,
      deaths: 1377
    },
    {
      age: 64,
      deaths: 1437
    },
    {
      age: 65,
      deaths: 1581
    },
    {
      age: 66,
      deaths: 1606
    },
    {
      age: 67,
      deaths: 1703
    },
    {
      age: 68,
      deaths: 1823
    },
    {
      age: 69,
      deaths: 1925
    },
    {
      age: 70,
      deaths: 2087
    },
    {
      age: 71,
      deaths: 2176
    },
    {
      age: 72,
      deaths: 2310
    },
    {
      age: 73,
      deaths: 2392
    },
    {
      age: 74,
      deaths: 2463
    },
    {
      age: 75,
      deaths: 2581
    },
    {
      age: 76,
      deaths: 2647
    },
    {
      age: 77,
      deaths: 2761
    },
    {
      age: 78,
      deaths: 2828
    },
    {
      age: 79,
      deaths: 2884
    },
    {
      age: 80,
      deaths: 3045
    },
    {
      age: 81,
      deaths: 3070
    },
    {
      age: 82,
      deaths: 3103
    },
    {
      age: 83,
      deaths: 3083
    },
    {
      age: 84,
      deaths: 3185
    },
    {
      age: 85,
      deaths: 3111
    },
    {
      age: 86,
      deaths: 2969
    },
    {
      age: 87,
      deaths: 2875
    },
    {
      age: 88,
      deaths: 2756
    },
    {
      age: 89,
      deaths: 2588
    },
    {
      age: 90,
      deaths: 2393
    },
    {
      age: 91,
      deaths: 2148
    },
    {
      age: 92,
      deaths: 1989
    },
    {
      age: 93,
      deaths: 1730
    },
    {
      age: 94,
      deaths: 1482
    },
    {
      age: 95,
      deaths: 1228
    },
    {
      age: 96,
      deaths: 1008
    },
    {
      age: 97,
      deaths: 807
    },
    {
      age: 98,
      deaths: 630
    },
    {
      age: 99,
      deaths: 479
    },
    {
      age: 100,
      deaths: 354
    },
    {
      age: 101,
      deaths: 254
    },
    {
      age: 102,
      deaths: 177
    },
    {
      age: 103,
      deaths: 120
    },
    {
      age: 104,
      deaths: 78
    },
    {
      age: 105,
      deaths: 50
    },
    {
      age: 106,
      deaths: 31
    },
    {
      age: 107,
      deaths: 18
    },
    {
      age: 108,
      deaths: 10
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1986: [
    {
      age: 0,
      deaths: 1042
    },
    {
      age: 1,
      deaths: 80
    },
    {
      age: 2,
      deaths: 54
    },
    {
      age: 3,
      deaths: 41
    },
    {
      age: 4,
      deaths: 33
    },
    {
      age: 5,
      deaths: 26
    },
    {
      age: 6,
      deaths: 25
    },
    {
      age: 7,
      deaths: 23
    },
    {
      age: 8,
      deaths: 22
    },
    {
      age: 9,
      deaths: 21
    },
    {
      age: 10,
      deaths: 21
    },
    {
      age: 11,
      deaths: 22
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 29
    },
    {
      age: 14,
      deaths: 40
    },
    {
      age: 15,
      deaths: 53
    },
    {
      age: 16,
      deaths: 75
    },
    {
      age: 17,
      deaths: 88
    },
    {
      age: 18,
      deaths: 105
    },
    {
      age: 19,
      deaths: 104
    },
    {
      age: 20,
      deaths: 110
    },
    {
      age: 21,
      deaths: 114
    },
    {
      age: 22,
      deaths: 111
    },
    {
      age: 23,
      deaths: 112
    },
    {
      age: 24,
      deaths: 112
    },
    {
      age: 25,
      deaths: 113
    },
    {
      age: 26,
      deaths: 113
    },
    {
      age: 27,
      deaths: 117
    },
    {
      age: 28,
      deaths: 123
    },
    {
      age: 29,
      deaths: 124
    },
    {
      age: 30,
      deaths: 131
    },
    {
      age: 31,
      deaths: 136
    },
    {
      age: 32,
      deaths: 141
    },
    {
      age: 33,
      deaths: 152
    },
    {
      age: 34,
      deaths: 150
    },
    {
      age: 35,
      deaths: 160
    },
    {
      age: 36,
      deaths: 166
    },
    {
      age: 37,
      deaths: 169
    },
    {
      age: 38,
      deaths: 189
    },
    {
      age: 39,
      deaths: 191
    },
    {
      age: 40,
      deaths: 193
    },
    {
      age: 41,
      deaths: 221
    },
    {
      age: 42,
      deaths: 244
    },
    {
      age: 43,
      deaths: 268
    },
    {
      age: 44,
      deaths: 275
    },
    {
      age: 45,
      deaths: 307
    },
    {
      age: 46,
      deaths: 337
    },
    {
      age: 47,
      deaths: 354
    },
    {
      age: 48,
      deaths: 410
    },
    {
      age: 49,
      deaths: 417
    },
    {
      age: 50,
      deaths: 480
    },
    {
      age: 51,
      deaths: 536
    },
    {
      age: 52,
      deaths: 582
    },
    {
      age: 53,
      deaths: 631
    },
    {
      age: 54,
      deaths: 668
    },
    {
      age: 55,
      deaths: 730
    },
    {
      age: 56,
      deaths: 793
    },
    {
      age: 57,
      deaths: 835
    },
    {
      age: 58,
      deaths: 971
    },
    {
      age: 59,
      deaths: 1000
    },
    {
      age: 60,
      deaths: 1100
    },
    {
      age: 61,
      deaths: 1197
    },
    {
      age: 62,
      deaths: 1315
    },
    {
      age: 63,
      deaths: 1346
    },
    {
      age: 64,
      deaths: 1416
    },
    {
      age: 65,
      deaths: 1543
    },
    {
      age: 66,
      deaths: 1644
    },
    {
      age: 67,
      deaths: 1673
    },
    {
      age: 68,
      deaths: 1839
    },
    {
      age: 69,
      deaths: 1876
    },
    {
      age: 70,
      deaths: 2052
    },
    {
      age: 71,
      deaths: 2171
    },
    {
      age: 72,
      deaths: 2342
    },
    {
      age: 73,
      deaths: 2398
    },
    {
      age: 74,
      deaths: 2414
    },
    {
      age: 75,
      deaths: 2544
    },
    {
      age: 76,
      deaths: 2680
    },
    {
      age: 77,
      deaths: 2693
    },
    {
      age: 78,
      deaths: 2837
    },
    {
      age: 79,
      deaths: 2857
    },
    {
      age: 80,
      deaths: 3039
    },
    {
      age: 81,
      deaths: 3060
    },
    {
      age: 82,
      deaths: 3120
    },
    {
      age: 83,
      deaths: 3120
    },
    {
      age: 84,
      deaths: 3050
    },
    {
      age: 85,
      deaths: 3105
    },
    {
      age: 86,
      deaths: 3040
    },
    {
      age: 87,
      deaths: 2877
    },
    {
      age: 88,
      deaths: 2736
    },
    {
      age: 89,
      deaths: 2610
    },
    {
      age: 90,
      deaths: 2452
    },
    {
      age: 91,
      deaths: 2201
    },
    {
      age: 92,
      deaths: 1993
    },
    {
      age: 93,
      deaths: 1771
    },
    {
      age: 94,
      deaths: 1498
    },
    {
      age: 95,
      deaths: 1266
    },
    {
      age: 96,
      deaths: 1042
    },
    {
      age: 97,
      deaths: 838
    },
    {
      age: 98,
      deaths: 657
    },
    {
      age: 99,
      deaths: 501
    },
    {
      age: 100,
      deaths: 372
    },
    {
      age: 101,
      deaths: 268
    },
    {
      age: 102,
      deaths: 188
    },
    {
      age: 103,
      deaths: 127
    },
    {
      age: 104,
      deaths: 84
    },
    {
      age: 105,
      deaths: 53
    },
    {
      age: 106,
      deaths: 33
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1987: [
    {
      age: 0,
      deaths: 1023
    },
    {
      age: 1,
      deaths: 78
    },
    {
      age: 2,
      deaths: 53
    },
    {
      age: 3,
      deaths: 41
    },
    {
      age: 4,
      deaths: 33
    },
    {
      age: 5,
      deaths: 27
    },
    {
      age: 6,
      deaths: 26
    },
    {
      age: 7,
      deaths: 24
    },
    {
      age: 8,
      deaths: 24
    },
    {
      age: 9,
      deaths: 21
    },
    {
      age: 10,
      deaths: 19
    },
    {
      age: 11,
      deaths: 23
    },
    {
      age: 12,
      deaths: 25
    },
    {
      age: 13,
      deaths: 29
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 49
    },
    {
      age: 16,
      deaths: 73
    },
    {
      age: 17,
      deaths: 86
    },
    {
      age: 18,
      deaths: 102
    },
    {
      age: 19,
      deaths: 100
    },
    {
      age: 20,
      deaths: 104
    },
    {
      age: 21,
      deaths: 110
    },
    {
      age: 22,
      deaths: 109
    },
    {
      age: 23,
      deaths: 111
    },
    {
      age: 24,
      deaths: 109
    },
    {
      age: 25,
      deaths: 111
    },
    {
      age: 26,
      deaths: 116
    },
    {
      age: 27,
      deaths: 117
    },
    {
      age: 28,
      deaths: 125
    },
    {
      age: 29,
      deaths: 121
    },
    {
      age: 30,
      deaths: 132
    },
    {
      age: 31,
      deaths: 139
    },
    {
      age: 32,
      deaths: 144
    },
    {
      age: 33,
      deaths: 153
    },
    {
      age: 34,
      deaths: 153
    },
    {
      age: 35,
      deaths: 163
    },
    {
      age: 36,
      deaths: 172
    },
    {
      age: 37,
      deaths: 178
    },
    {
      age: 38,
      deaths: 196
    },
    {
      age: 39,
      deaths: 186
    },
    {
      age: 40,
      deaths: 211
    },
    {
      age: 41,
      deaths: 207
    },
    {
      age: 42,
      deaths: 241
    },
    {
      age: 43,
      deaths: 252
    },
    {
      age: 44,
      deaths: 279
    },
    {
      age: 45,
      deaths: 301
    },
    {
      age: 46,
      deaths: 330
    },
    {
      age: 47,
      deaths: 362
    },
    {
      age: 48,
      deaths: 406
    },
    {
      age: 49,
      deaths: 411
    },
    {
      age: 50,
      deaths: 465
    },
    {
      age: 51,
      deaths: 531
    },
    {
      age: 52,
      deaths: 583
    },
    {
      age: 53,
      deaths: 619
    },
    {
      age: 54,
      deaths: 674
    },
    {
      age: 55,
      deaths: 731
    },
    {
      age: 56,
      deaths: 768
    },
    {
      age: 57,
      deaths: 848
    },
    {
      age: 58,
      deaths: 951
    },
    {
      age: 59,
      deaths: 987
    },
    {
      age: 60,
      deaths: 1088
    },
    {
      age: 61,
      deaths: 1180
    },
    {
      age: 62,
      deaths: 1300
    },
    {
      age: 63,
      deaths: 1347
    },
    {
      age: 64,
      deaths: 1384
    },
    {
      age: 65,
      deaths: 1520
    },
    {
      age: 66,
      deaths: 1617
    },
    {
      age: 67,
      deaths: 1710
    },
    {
      age: 68,
      deaths: 1796
    },
    {
      age: 69,
      deaths: 1865
    },
    {
      age: 70,
      deaths: 1969
    },
    {
      age: 71,
      deaths: 2126
    },
    {
      age: 72,
      deaths: 2327
    },
    {
      age: 73,
      deaths: 2415
    },
    {
      age: 74,
      deaths: 2429
    },
    {
      age: 75,
      deaths: 2481
    },
    {
      age: 76,
      deaths: 2658
    },
    {
      age: 77,
      deaths: 2722
    },
    {
      age: 78,
      deaths: 2796
    },
    {
      age: 79,
      deaths: 2896
    },
    {
      age: 80,
      deaths: 3031
    },
    {
      age: 81,
      deaths: 3074
    },
    {
      age: 82,
      deaths: 3098
    },
    {
      age: 83,
      deaths: 3112
    },
    {
      age: 84,
      deaths: 3087
    },
    {
      age: 85,
      deaths: 3042
    },
    {
      age: 86,
      deaths: 3095
    },
    {
      age: 87,
      deaths: 2975
    },
    {
      age: 88,
      deaths: 2793
    },
    {
      age: 89,
      deaths: 2607
    },
    {
      age: 90,
      deaths: 2460
    },
    {
      age: 91,
      deaths: 2255
    },
    {
      age: 92,
      deaths: 2027
    },
    {
      age: 93,
      deaths: 1779
    },
    {
      age: 94,
      deaths: 1564
    },
    {
      age: 95,
      deaths: 1296
    },
    {
      age: 96,
      deaths: 1067
    },
    {
      age: 97,
      deaths: 856
    },
    {
      age: 98,
      deaths: 670
    },
    {
      age: 99,
      deaths: 510
    },
    {
      age: 100,
      deaths: 377
    },
    {
      age: 101,
      deaths: 271
    },
    {
      age: 102,
      deaths: 189
    },
    {
      age: 103,
      deaths: 127
    },
    {
      age: 104,
      deaths: 83
    },
    {
      age: 105,
      deaths: 53
    },
    {
      age: 106,
      deaths: 32
    },
    {
      age: 107,
      deaths: 19
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1988: [
    {
      age: 0,
      deaths: 1022
    },
    {
      age: 1,
      deaths: 79
    },
    {
      age: 2,
      deaths: 52
    },
    {
      age: 3,
      deaths: 40
    },
    {
      age: 4,
      deaths: 32
    },
    {
      age: 5,
      deaths: 28
    },
    {
      age: 6,
      deaths: 24
    },
    {
      age: 7,
      deaths: 23
    },
    {
      age: 8,
      deaths: 23
    },
    {
      age: 9,
      deaths: 23
    },
    {
      age: 10,
      deaths: 22
    },
    {
      age: 11,
      deaths: 22
    },
    {
      age: 12,
      deaths: 24
    },
    {
      age: 13,
      deaths: 30
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 51
    },
    {
      age: 16,
      deaths: 75
    },
    {
      age: 17,
      deaths: 88
    },
    {
      age: 18,
      deaths: 104
    },
    {
      age: 19,
      deaths: 104
    },
    {
      age: 20,
      deaths: 106
    },
    {
      age: 21,
      deaths: 110
    },
    {
      age: 22,
      deaths: 110
    },
    {
      age: 23,
      deaths: 113
    },
    {
      age: 24,
      deaths: 111
    },
    {
      age: 25,
      deaths: 113
    },
    {
      age: 26,
      deaths: 119
    },
    {
      age: 27,
      deaths: 118
    },
    {
      age: 28,
      deaths: 129
    },
    {
      age: 29,
      deaths: 121
    },
    {
      age: 30,
      deaths: 137
    },
    {
      age: 31,
      deaths: 142
    },
    {
      age: 32,
      deaths: 147
    },
    {
      age: 33,
      deaths: 153
    },
    {
      age: 34,
      deaths: 153
    },
    {
      age: 35,
      deaths: 169
    },
    {
      age: 36,
      deaths: 176
    },
    {
      age: 37,
      deaths: 182
    },
    {
      age: 38,
      deaths: 203
    },
    {
      age: 39,
      deaths: 195
    },
    {
      age: 40,
      deaths: 206
    },
    {
      age: 41,
      deaths: 230
    },
    {
      age: 42,
      deaths: 232
    },
    {
      age: 43,
      deaths: 262
    },
    {
      age: 44,
      deaths: 273
    },
    {
      age: 45,
      deaths: 305
    },
    {
      age: 46,
      deaths: 327
    },
    {
      age: 47,
      deaths: 362
    },
    {
      age: 48,
      deaths: 404
    },
    {
      age: 49,
      deaths: 411
    },
    {
      age: 50,
      deaths: 457
    },
    {
      age: 51,
      deaths: 509
    },
    {
      age: 52,
      deaths: 569
    },
    {
      age: 53,
      deaths: 628
    },
    {
      age: 54,
      deaths: 650
    },
    {
      age: 55,
      deaths: 739
    },
    {
      age: 56,
      deaths: 794
    },
    {
      age: 57,
      deaths: 822
    },
    {
      age: 58,
      deaths: 966
    },
    {
      age: 59,
      deaths: 959
    },
    {
      age: 60,
      deaths: 1087
    },
    {
      age: 61,
      deaths: 1168
    },
    {
      age: 62,
      deaths: 1270
    },
    {
      age: 63,
      deaths: 1351
    },
    {
      age: 64,
      deaths: 1389
    },
    {
      age: 65,
      deaths: 1501
    },
    {
      age: 66,
      deaths: 1600
    },
    {
      age: 67,
      deaths: 1667
    },
    {
      age: 68,
      deaths: 1847
    },
    {
      age: 69,
      deaths: 1841
    },
    {
      age: 70,
      deaths: 1980
    },
    {
      age: 71,
      deaths: 2059
    },
    {
      age: 72,
      deaths: 2282
    },
    {
      age: 73,
      deaths: 2403
    },
    {
      age: 74,
      deaths: 2464
    },
    {
      age: 75,
      deaths: 2531
    },
    {
      age: 76,
      deaths: 2612
    },
    {
      age: 77,
      deaths: 2717
    },
    {
      age: 78,
      deaths: 2816
    },
    {
      age: 79,
      deaths: 2876
    },
    {
      age: 80,
      deaths: 3067
    },
    {
      age: 81,
      deaths: 3087
    },
    {
      age: 82,
      deaths: 3150
    },
    {
      age: 83,
      deaths: 3153
    },
    {
      age: 84,
      deaths: 3163
    },
    {
      age: 85,
      deaths: 3106
    },
    {
      age: 86,
      deaths: 3005
    },
    {
      age: 87,
      deaths: 3018
    },
    {
      age: 88,
      deaths: 2841
    },
    {
      age: 89,
      deaths: 2672
    },
    {
      age: 90,
      deaths: 2482
    },
    {
      age: 91,
      deaths: 2244
    },
    {
      age: 92,
      deaths: 2033
    },
    {
      age: 93,
      deaths: 1781
    },
    {
      age: 94,
      deaths: 1530
    },
    {
      age: 95,
      deaths: 1278
    },
    {
      age: 96,
      deaths: 1046
    },
    {
      age: 97,
      deaths: 834
    },
    {
      age: 98,
      deaths: 648
    },
    {
      age: 99,
      deaths: 489
    },
    {
      age: 100,
      deaths: 359
    },
    {
      age: 101,
      deaths: 255
    },
    {
      age: 102,
      deaths: 176
    },
    {
      age: 103,
      deaths: 117
    },
    {
      age: 104,
      deaths: 76
    },
    {
      age: 105,
      deaths: 47
    },
    {
      age: 106,
      deaths: 29
    },
    {
      age: 107,
      deaths: 17
    },
    {
      age: 108,
      deaths: 10
    },
    {
      age: 109,
      deaths: 5
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1989: [
    {
      age: 0,
      deaths: 1032
    },
    {
      age: 1,
      deaths: 79
    },
    {
      age: 2,
      deaths: 48
    },
    {
      age: 3,
      deaths: 39
    },
    {
      age: 4,
      deaths: 31
    },
    {
      age: 5,
      deaths: 26
    },
    {
      age: 6,
      deaths: 24
    },
    {
      age: 7,
      deaths: 23
    },
    {
      age: 8,
      deaths: 23
    },
    {
      age: 9,
      deaths: 23
    },
    {
      age: 10,
      deaths: 21
    },
    {
      age: 11,
      deaths: 23
    },
    {
      age: 12,
      deaths: 26
    },
    {
      age: 13,
      deaths: 29
    },
    {
      age: 14,
      deaths: 37
    },
    {
      age: 15,
      deaths: 52
    },
    {
      age: 16,
      deaths: 74
    },
    {
      age: 17,
      deaths: 87
    },
    {
      age: 18,
      deaths: 104
    },
    {
      age: 19,
      deaths: 100
    },
    {
      age: 20,
      deaths: 99
    },
    {
      age: 21,
      deaths: 103
    },
    {
      age: 22,
      deaths: 106
    },
    {
      age: 23,
      deaths: 110
    },
    {
      age: 24,
      deaths: 111
    },
    {
      age: 25,
      deaths: 115
    },
    {
      age: 26,
      deaths: 117
    },
    {
      age: 27,
      deaths: 123
    },
    {
      age: 28,
      deaths: 130
    },
    {
      age: 29,
      deaths: 123
    },
    {
      age: 30,
      deaths: 132
    },
    {
      age: 31,
      deaths: 142
    },
    {
      age: 32,
      deaths: 155
    },
    {
      age: 33,
      deaths: 159
    },
    {
      age: 34,
      deaths: 163
    },
    {
      age: 35,
      deaths: 171
    },
    {
      age: 36,
      deaths: 180
    },
    {
      age: 37,
      deaths: 186
    },
    {
      age: 38,
      deaths: 206
    },
    {
      age: 39,
      deaths: 191
    },
    {
      age: 40,
      deaths: 213
    },
    {
      age: 41,
      deaths: 222
    },
    {
      age: 42,
      deaths: 251
    },
    {
      age: 43,
      deaths: 253
    },
    {
      age: 44,
      deaths: 272
    },
    {
      age: 45,
      deaths: 296
    },
    {
      age: 46,
      deaths: 336
    },
    {
      age: 47,
      deaths: 354
    },
    {
      age: 48,
      deaths: 399
    },
    {
      age: 49,
      deaths: 397
    },
    {
      age: 50,
      deaths: 456
    },
    {
      age: 51,
      deaths: 502
    },
    {
      age: 52,
      deaths: 547
    },
    {
      age: 53,
      deaths: 599
    },
    {
      age: 54,
      deaths: 647
    },
    {
      age: 55,
      deaths: 700
    },
    {
      age: 56,
      deaths: 776
    },
    {
      age: 57,
      deaths: 810
    },
    {
      age: 58,
      deaths: 936
    },
    {
      age: 59,
      deaths: 970
    },
    {
      age: 60,
      deaths: 1033
    },
    {
      age: 61,
      deaths: 1143
    },
    {
      age: 62,
      deaths: 1237
    },
    {
      age: 63,
      deaths: 1310
    },
    {
      age: 64,
      deaths: 1374
    },
    {
      age: 65,
      deaths: 1476
    },
    {
      age: 66,
      deaths: 1543
    },
    {
      age: 67,
      deaths: 1632
    },
    {
      age: 68,
      deaths: 1796
    },
    {
      age: 69,
      deaths: 1851
    },
    {
      age: 70,
      deaths: 1936
    },
    {
      age: 71,
      deaths: 2038
    },
    {
      age: 72,
      deaths: 2222
    },
    {
      age: 73,
      deaths: 2319
    },
    {
      age: 74,
      deaths: 2420
    },
    {
      age: 75,
      deaths: 2498
    },
    {
      age: 76,
      deaths: 2626
    },
    {
      age: 77,
      deaths: 2655
    },
    {
      age: 78,
      deaths: 2772
    },
    {
      age: 79,
      deaths: 2856
    },
    {
      age: 80,
      deaths: 3002
    },
    {
      age: 81,
      deaths: 3083
    },
    {
      age: 82,
      deaths: 3118
    },
    {
      age: 83,
      deaths: 3165
    },
    {
      age: 84,
      deaths: 3143
    },
    {
      age: 85,
      deaths: 3102
    },
    {
      age: 86,
      deaths: 3013
    },
    {
      age: 87,
      deaths: 2971
    },
    {
      age: 88,
      deaths: 2870
    },
    {
      age: 89,
      deaths: 2738
    },
    {
      age: 90,
      deaths: 2523
    },
    {
      age: 91,
      deaths: 2327
    },
    {
      age: 92,
      deaths: 2099
    },
    {
      age: 93,
      deaths: 1874
    },
    {
      age: 94,
      deaths: 1606
    },
    {
      age: 95,
      deaths: 1373
    },
    {
      age: 96,
      deaths: 1135
    },
    {
      age: 97,
      deaths: 915
    },
    {
      age: 98,
      deaths: 719
    },
    {
      age: 99,
      deaths: 549
    },
    {
      age: 100,
      deaths: 408
    },
    {
      age: 101,
      deaths: 294
    },
    {
      age: 102,
      deaths: 206
    },
    {
      age: 103,
      deaths: 139
    },
    {
      age: 104,
      deaths: 91
    },
    {
      age: 105,
      deaths: 58
    },
    {
      age: 106,
      deaths: 36
    },
    {
      age: 107,
      deaths: 21
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1990: [
    {
      age: 0,
      deaths: 975
    },
    {
      age: 1,
      deaths: 73
    },
    {
      age: 2,
      deaths: 46
    },
    {
      age: 3,
      deaths: 35
    },
    {
      age: 4,
      deaths: 29
    },
    {
      age: 5,
      deaths: 26
    },
    {
      age: 6,
      deaths: 23
    },
    {
      age: 7,
      deaths: 21
    },
    {
      age: 8,
      deaths: 20
    },
    {
      age: 9,
      deaths: 19
    },
    {
      age: 10,
      deaths: 19
    },
    {
      age: 11,
      deaths: 19
    },
    {
      age: 12,
      deaths: 23
    },
    {
      age: 13,
      deaths: 28
    },
    {
      age: 14,
      deaths: 39
    },
    {
      age: 15,
      deaths: 52
    },
    {
      age: 16,
      deaths: 74
    },
    {
      age: 17,
      deaths: 92
    },
    {
      age: 18,
      deaths: 109
    },
    {
      age: 19,
      deaths: 104
    },
    {
      age: 20,
      deaths: 102
    },
    {
      age: 21,
      deaths: 107
    },
    {
      age: 22,
      deaths: 109
    },
    {
      age: 23,
      deaths: 111
    },
    {
      age: 24,
      deaths: 108
    },
    {
      age: 25,
      deaths: 115
    },
    {
      age: 26,
      deaths: 117
    },
    {
      age: 27,
      deaths: 122
    },
    {
      age: 28,
      deaths: 128
    },
    {
      age: 29,
      deaths: 127
    },
    {
      age: 30,
      deaths: 135
    },
    {
      age: 31,
      deaths: 141
    },
    {
      age: 32,
      deaths: 148
    },
    {
      age: 33,
      deaths: 156
    },
    {
      age: 34,
      deaths: 158
    },
    {
      age: 35,
      deaths: 172
    },
    {
      age: 36,
      deaths: 180
    },
    {
      age: 37,
      deaths: 187
    },
    {
      age: 38,
      deaths: 205
    },
    {
      age: 39,
      deaths: 194
    },
    {
      age: 40,
      deaths: 210
    },
    {
      age: 41,
      deaths: 223
    },
    {
      age: 42,
      deaths: 239
    },
    {
      age: 43,
      deaths: 261
    },
    {
      age: 44,
      deaths: 254
    },
    {
      age: 45,
      deaths: 294
    },
    {
      age: 46,
      deaths: 324
    },
    {
      age: 47,
      deaths: 355
    },
    {
      age: 48,
      deaths: 393
    },
    {
      age: 49,
      deaths: 395
    },
    {
      age: 50,
      deaths: 445
    },
    {
      age: 51,
      deaths: 492
    },
    {
      age: 52,
      deaths: 543
    },
    {
      age: 53,
      deaths: 570
    },
    {
      age: 54,
      deaths: 626
    },
    {
      age: 55,
      deaths: 711
    },
    {
      age: 56,
      deaths: 738
    },
    {
      age: 57,
      deaths: 808
    },
    {
      age: 58,
      deaths: 921
    },
    {
      age: 59,
      deaths: 918
    },
    {
      age: 60,
      deaths: 1039
    },
    {
      age: 61,
      deaths: 1102
    },
    {
      age: 62,
      deaths: 1231
    },
    {
      age: 63,
      deaths: 1297
    },
    {
      age: 64,
      deaths: 1347
    },
    {
      age: 65,
      deaths: 1478
    },
    {
      age: 66,
      deaths: 1553
    },
    {
      age: 67,
      deaths: 1611
    },
    {
      age: 68,
      deaths: 1757
    },
    {
      age: 69,
      deaths: 1797
    },
    {
      age: 70,
      deaths: 1955
    },
    {
      age: 71,
      deaths: 2001
    },
    {
      age: 72,
      deaths: 2159
    },
    {
      age: 73,
      deaths: 2288
    },
    {
      age: 74,
      deaths: 2365
    },
    {
      age: 75,
      deaths: 2455
    },
    {
      age: 76,
      deaths: 2618
    },
    {
      age: 77,
      deaths: 2704
    },
    {
      age: 78,
      deaths: 2704
    },
    {
      age: 79,
      deaths: 2818
    },
    {
      age: 80,
      deaths: 3037
    },
    {
      age: 81,
      deaths: 3061
    },
    {
      age: 82,
      deaths: 3114
    },
    {
      age: 83,
      deaths: 3143
    },
    {
      age: 84,
      deaths: 3144
    },
    {
      age: 85,
      deaths: 3110
    },
    {
      age: 86,
      deaths: 3092
    },
    {
      age: 87,
      deaths: 2979
    },
    {
      age: 88,
      deaths: 2847
    },
    {
      age: 89,
      deaths: 2763
    },
    {
      age: 90,
      deaths: 2610
    },
    {
      age: 91,
      deaths: 2362
    },
    {
      age: 92,
      deaths: 2151
    },
    {
      age: 93,
      deaths: 1952
    },
    {
      age: 94,
      deaths: 1668
    },
    {
      age: 95,
      deaths: 1420
    },
    {
      age: 96,
      deaths: 1182
    },
    {
      age: 97,
      deaths: 961
    },
    {
      age: 98,
      deaths: 761
    },
    {
      age: 99,
      deaths: 587
    },
    {
      age: 100,
      deaths: 440
    },
    {
      age: 101,
      deaths: 320
    },
    {
      age: 102,
      deaths: 226
    },
    {
      age: 103,
      deaths: 155
    },
    {
      age: 104,
      deaths: 103
    },
    {
      age: 105,
      deaths: 66
    },
    {
      age: 106,
      deaths: 41
    },
    {
      age: 107,
      deaths: 25
    },
    {
      age: 108,
      deaths: 14
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 9
    }
  ],
  1991: [
    {
      age: 0,
      deaths: 927
    },
    {
      age: 1,
      deaths: 74
    },
    {
      age: 2,
      deaths: 48
    },
    {
      age: 3,
      deaths: 37
    },
    {
      age: 4,
      deaths: 28
    },
    {
      age: 5,
      deaths: 26
    },
    {
      age: 6,
      deaths: 22
    },
    {
      age: 7,
      deaths: 19
    },
    {
      age: 8,
      deaths: 20
    },
    {
      age: 9,
      deaths: 19
    },
    {
      age: 10,
      deaths: 19
    },
    {
      age: 11,
      deaths: 21
    },
    {
      age: 12,
      deaths: 22
    },
    {
      age: 13,
      deaths: 27
    },
    {
      age: 14,
      deaths: 39
    },
    {
      age: 15,
      deaths: 51
    },
    {
      age: 16,
      deaths: 77
    },
    {
      age: 17,
      deaths: 88
    },
    {
      age: 18,
      deaths: 109
    },
    {
      age: 19,
      deaths: 105
    },
    {
      age: 20,
      deaths: 107
    },
    {
      age: 21,
      deaths: 111
    },
    {
      age: 22,
      deaths: 110
    },
    {
      age: 23,
      deaths: 106
    },
    {
      age: 24,
      deaths: 105
    },
    {
      age: 25,
      deaths: 110
    },
    {
      age: 26,
      deaths: 114
    },
    {
      age: 27,
      deaths: 121
    },
    {
      age: 28,
      deaths: 126
    },
    {
      age: 29,
      deaths: 122
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 145
    },
    {
      age: 32,
      deaths: 145
    },
    {
      age: 33,
      deaths: 156
    },
    {
      age: 34,
      deaths: 161
    },
    {
      age: 35,
      deaths: 170
    },
    {
      age: 36,
      deaths: 181
    },
    {
      age: 37,
      deaths: 190
    },
    {
      age: 38,
      deaths: 208
    },
    {
      age: 39,
      deaths: 199
    },
    {
      age: 40,
      deaths: 217
    },
    {
      age: 41,
      deaths: 231
    },
    {
      age: 42,
      deaths: 244
    },
    {
      age: 43,
      deaths: 250
    },
    {
      age: 44,
      deaths: 278
    },
    {
      age: 45,
      deaths: 280
    },
    {
      age: 46,
      deaths: 332
    },
    {
      age: 47,
      deaths: 342
    },
    {
      age: 48,
      deaths: 403
    },
    {
      age: 49,
      deaths: 394
    },
    {
      age: 50,
      deaths: 440
    },
    {
      age: 51,
      deaths: 485
    },
    {
      age: 52,
      deaths: 527
    },
    {
      age: 53,
      deaths: 566
    },
    {
      age: 54,
      deaths: 602
    },
    {
      age: 55,
      deaths: 683
    },
    {
      age: 56,
      deaths: 747
    },
    {
      age: 57,
      deaths: 785
    },
    {
      age: 58,
      deaths: 910
    },
    {
      age: 59,
      deaths: 933
    },
    {
      age: 60,
      deaths: 1012
    },
    {
      age: 61,
      deaths: 1124
    },
    {
      age: 62,
      deaths: 1175
    },
    {
      age: 63,
      deaths: 1266
    },
    {
      age: 64,
      deaths: 1350
    },
    {
      age: 65,
      deaths: 1436
    },
    {
      age: 66,
      deaths: 1538
    },
    {
      age: 67,
      deaths: 1624
    },
    {
      age: 68,
      deaths: 1711
    },
    {
      age: 69,
      deaths: 1806
    },
    {
      age: 70,
      deaths: 1898
    },
    {
      age: 71,
      deaths: 2062
    },
    {
      age: 72,
      deaths: 2101
    },
    {
      age: 73,
      deaths: 2297
    },
    {
      age: 74,
      deaths: 2352
    },
    {
      age: 75,
      deaths: 2430
    },
    {
      age: 76,
      deaths: 2571
    },
    {
      age: 77,
      deaths: 2673
    },
    {
      age: 78,
      deaths: 2704
    },
    {
      age: 79,
      deaths: 2775
    },
    {
      age: 80,
      deaths: 3017
    },
    {
      age: 81,
      deaths: 3082
    },
    {
      age: 82,
      deaths: 3109
    },
    {
      age: 83,
      deaths: 3177
    },
    {
      age: 84,
      deaths: 3155
    },
    {
      age: 85,
      deaths: 3129
    },
    {
      age: 86,
      deaths: 3081
    },
    {
      age: 87,
      deaths: 3020
    },
    {
      age: 88,
      deaths: 2901
    },
    {
      age: 89,
      deaths: 2766
    },
    {
      age: 90,
      deaths: 2606
    },
    {
      age: 91,
      deaths: 2444
    },
    {
      age: 92,
      deaths: 2188
    },
    {
      age: 93,
      deaths: 1960
    },
    {
      age: 94,
      deaths: 1731
    },
    {
      age: 95,
      deaths: 1453
    },
    {
      age: 96,
      deaths: 1211
    },
    {
      age: 97,
      deaths: 985
    },
    {
      age: 98,
      deaths: 781
    },
    {
      age: 99,
      deaths: 603
    },
    {
      age: 100,
      deaths: 452
    },
    {
      age: 101,
      deaths: 330
    },
    {
      age: 102,
      deaths: 233
    },
    {
      age: 103,
      deaths: 160
    },
    {
      age: 104,
      deaths: 106
    },
    {
      age: 105,
      deaths: 68
    },
    {
      age: 106,
      deaths: 42
    },
    {
      age: 107,
      deaths: 25
    },
    {
      age: 108,
      deaths: 15
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 9
    }
  ],
  1992: [
    {
      age: 0,
      deaths: 872
    },
    {
      age: 1,
      deaths: 67
    },
    {
      age: 2,
      deaths: 45
    },
    {
      age: 3,
      deaths: 33
    },
    {
      age: 4,
      deaths: 27
    },
    {
      age: 5,
      deaths: 24
    },
    {
      age: 6,
      deaths: 20
    },
    {
      age: 7,
      deaths: 20
    },
    {
      age: 8,
      deaths: 19
    },
    {
      age: 9,
      deaths: 18
    },
    {
      age: 10,
      deaths: 17
    },
    {
      age: 11,
      deaths: 19
    },
    {
      age: 12,
      deaths: 22
    },
    {
      age: 13,
      deaths: 27
    },
    {
      age: 14,
      deaths: 37
    },
    {
      age: 15,
      deaths: 54
    },
    {
      age: 16,
      deaths: 74
    },
    {
      age: 17,
      deaths: 84
    },
    {
      age: 18,
      deaths: 99
    },
    {
      age: 19,
      deaths: 99
    },
    {
      age: 20,
      deaths: 99
    },
    {
      age: 21,
      deaths: 108
    },
    {
      age: 22,
      deaths: 104
    },
    {
      age: 23,
      deaths: 103
    },
    {
      age: 24,
      deaths: 101
    },
    {
      age: 25,
      deaths: 106
    },
    {
      age: 26,
      deaths: 109
    },
    {
      age: 27,
      deaths: 115
    },
    {
      age: 28,
      deaths: 126
    },
    {
      age: 29,
      deaths: 121
    },
    {
      age: 30,
      deaths: 131
    },
    {
      age: 31,
      deaths: 140
    },
    {
      age: 32,
      deaths: 147
    },
    {
      age: 33,
      deaths: 158
    },
    {
      age: 34,
      deaths: 161
    },
    {
      age: 35,
      deaths: 173
    },
    {
      age: 36,
      deaths: 181
    },
    {
      age: 37,
      deaths: 186
    },
    {
      age: 38,
      deaths: 213
    },
    {
      age: 39,
      deaths: 204
    },
    {
      age: 40,
      deaths: 222
    },
    {
      age: 41,
      deaths: 238
    },
    {
      age: 42,
      deaths: 251
    },
    {
      age: 43,
      deaths: 264
    },
    {
      age: 44,
      deaths: 266
    },
    {
      age: 45,
      deaths: 301
    },
    {
      age: 46,
      deaths: 312
    },
    {
      age: 47,
      deaths: 347
    },
    {
      age: 48,
      deaths: 383
    },
    {
      age: 49,
      deaths: 402
    },
    {
      age: 50,
      deaths: 426
    },
    {
      age: 51,
      deaths: 477
    },
    {
      age: 52,
      deaths: 522
    },
    {
      age: 53,
      deaths: 562
    },
    {
      age: 54,
      deaths: 595
    },
    {
      age: 55,
      deaths: 660
    },
    {
      age: 56,
      deaths: 723
    },
    {
      age: 57,
      deaths: 774
    },
    {
      age: 58,
      deaths: 885
    },
    {
      age: 59,
      deaths: 918
    },
    {
      age: 60,
      deaths: 1008
    },
    {
      age: 61,
      deaths: 1072
    },
    {
      age: 62,
      deaths: 1180
    },
    {
      age: 63,
      deaths: 1236
    },
    {
      age: 64,
      deaths: 1311
    },
    {
      age: 65,
      deaths: 1415
    },
    {
      age: 66,
      deaths: 1506
    },
    {
      age: 67,
      deaths: 1619
    },
    {
      age: 68,
      deaths: 1748
    },
    {
      age: 69,
      deaths: 1762
    },
    {
      age: 70,
      deaths: 1893
    },
    {
      age: 71,
      deaths: 2011
    },
    {
      age: 72,
      deaths: 2139
    },
    {
      age: 73,
      deaths: 2221
    },
    {
      age: 74,
      deaths: 2356
    },
    {
      age: 75,
      deaths: 2418
    },
    {
      age: 76,
      deaths: 2510
    },
    {
      age: 77,
      deaths: 2636
    },
    {
      age: 78,
      deaths: 2696
    },
    {
      age: 79,
      deaths: 2788
    },
    {
      age: 80,
      deaths: 2968
    },
    {
      age: 81,
      deaths: 3074
    },
    {
      age: 82,
      deaths: 3162
    },
    {
      age: 83,
      deaths: 3139
    },
    {
      age: 84,
      deaths: 3180
    },
    {
      age: 85,
      deaths: 3169
    },
    {
      age: 86,
      deaths: 3100
    },
    {
      age: 87,
      deaths: 3028
    },
    {
      age: 88,
      deaths: 2942
    },
    {
      age: 89,
      deaths: 2794
    },
    {
      age: 90,
      deaths: 2627
    },
    {
      age: 91,
      deaths: 2438
    },
    {
      age: 92,
      deaths: 2279
    },
    {
      age: 93,
      deaths: 2020
    },
    {
      age: 94,
      deaths: 1781
    },
    {
      age: 95,
      deaths: 1499
    },
    {
      age: 96,
      deaths: 1255
    },
    {
      age: 97,
      deaths: 1025
    },
    {
      age: 98,
      deaths: 817
    },
    {
      age: 99,
      deaths: 634
    },
    {
      age: 100,
      deaths: 478
    },
    {
      age: 101,
      deaths: 351
    },
    {
      age: 102,
      deaths: 249
    },
    {
      age: 103,
      deaths: 172
    },
    {
      age: 104,
      deaths: 115
    },
    {
      age: 105,
      deaths: 74
    },
    {
      age: 106,
      deaths: 47
    },
    {
      age: 107,
      deaths: 28
    },
    {
      age: 108,
      deaths: 17
    },
    {
      age: 109,
      deaths: 9
    },
    {
      age: 110,
      deaths: 11
    }
  ],
  1993: [
    {
      age: 0,
      deaths: 854
    },
    {
      age: 1,
      deaths: 68
    },
    {
      age: 2,
      deaths: 46
    },
    {
      age: 3,
      deaths: 36
    },
    {
      age: 4,
      deaths: 28
    },
    {
      age: 5,
      deaths: 22
    },
    {
      age: 6,
      deaths: 23
    },
    {
      age: 7,
      deaths: 21
    },
    {
      age: 8,
      deaths: 21
    },
    {
      age: 9,
      deaths: 18
    },
    {
      age: 10,
      deaths: 17
    },
    {
      age: 11,
      deaths: 18
    },
    {
      age: 12,
      deaths: 22
    },
    {
      age: 13,
      deaths: 29
    },
    {
      age: 14,
      deaths: 40
    },
    {
      age: 15,
      deaths: 51
    },
    {
      age: 16,
      deaths: 76
    },
    {
      age: 17,
      deaths: 85
    },
    {
      age: 18,
      deaths: 106
    },
    {
      age: 19,
      deaths: 104
    },
    {
      age: 20,
      deaths: 103
    },
    {
      age: 21,
      deaths: 109
    },
    {
      age: 22,
      deaths: 107
    },
    {
      age: 23,
      deaths: 108
    },
    {
      age: 24,
      deaths: 103
    },
    {
      age: 25,
      deaths: 105
    },
    {
      age: 26,
      deaths: 108
    },
    {
      age: 27,
      deaths: 114
    },
    {
      age: 28,
      deaths: 128
    },
    {
      age: 29,
      deaths: 127
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 146
    },
    {
      age: 32,
      deaths: 154
    },
    {
      age: 33,
      deaths: 162
    },
    {
      age: 34,
      deaths: 166
    },
    {
      age: 35,
      deaths: 170
    },
    {
      age: 36,
      deaths: 188
    },
    {
      age: 37,
      deaths: 198
    },
    {
      age: 38,
      deaths: 217
    },
    {
      age: 39,
      deaths: 212
    },
    {
      age: 40,
      deaths: 229
    },
    {
      age: 41,
      deaths: 245
    },
    {
      age: 42,
      deaths: 256
    },
    {
      age: 43,
      deaths: 273
    },
    {
      age: 44,
      deaths: 276
    },
    {
      age: 45,
      deaths: 293
    },
    {
      age: 46,
      deaths: 332
    },
    {
      age: 47,
      deaths: 338
    },
    {
      age: 48,
      deaths: 388
    },
    {
      age: 49,
      deaths: 391
    },
    {
      age: 50,
      deaths: 445
    },
    {
      age: 51,
      deaths: 478
    },
    {
      age: 52,
      deaths: 522
    },
    {
      age: 53,
      deaths: 570
    },
    {
      age: 54,
      deaths: 594
    },
    {
      age: 55,
      deaths: 655
    },
    {
      age: 56,
      deaths: 718
    },
    {
      age: 57,
      deaths: 776
    },
    {
      age: 58,
      deaths: 894
    },
    {
      age: 59,
      deaths: 913
    },
    {
      age: 60,
      deaths: 1012
    },
    {
      age: 61,
      deaths: 1103
    },
    {
      age: 62,
      deaths: 1174
    },
    {
      age: 63,
      deaths: 1253
    },
    {
      age: 64,
      deaths: 1295
    },
    {
      age: 65,
      deaths: 1426
    },
    {
      age: 66,
      deaths: 1500
    },
    {
      age: 67,
      deaths: 1605
    },
    {
      age: 68,
      deaths: 1771
    },
    {
      age: 69,
      deaths: 1812
    },
    {
      age: 70,
      deaths: 1896
    },
    {
      age: 71,
      deaths: 2028
    },
    {
      age: 72,
      deaths: 2121
    },
    {
      age: 73,
      deaths: 2292
    },
    {
      age: 74,
      deaths: 2315
    },
    {
      age: 75,
      deaths: 2447
    },
    {
      age: 76,
      deaths: 2563
    },
    {
      age: 77,
      deaths: 2645
    },
    {
      age: 78,
      deaths: 2754
    },
    {
      age: 79,
      deaths: 2824
    },
    {
      age: 80,
      deaths: 3061
    },
    {
      age: 81,
      deaths: 3106
    },
    {
      age: 82,
      deaths: 3185
    },
    {
      age: 83,
      deaths: 3221
    },
    {
      age: 84,
      deaths: 3180
    },
    {
      age: 85,
      deaths: 3201
    },
    {
      age: 86,
      deaths: 3150
    },
    {
      age: 87,
      deaths: 3064
    },
    {
      age: 88,
      deaths: 2947
    },
    {
      age: 89,
      deaths: 2838
    },
    {
      age: 90,
      deaths: 2623
    },
    {
      age: 91,
      deaths: 2377
    },
    {
      age: 92,
      deaths: 2212
    },
    {
      age: 93,
      deaths: 1989
    },
    {
      age: 94,
      deaths: 1711
    },
    {
      age: 95,
      deaths: 1429
    },
    {
      age: 96,
      deaths: 1180
    },
    {
      age: 97,
      deaths: 950
    },
    {
      age: 98,
      deaths: 745
    },
    {
      age: 99,
      deaths: 567
    },
    {
      age: 100,
      deaths: 420
    },
    {
      age: 101,
      deaths: 301
    },
    {
      age: 102,
      deaths: 209
    },
    {
      age: 103,
      deaths: 141
    },
    {
      age: 104,
      deaths: 92
    },
    {
      age: 105,
      deaths: 58
    },
    {
      age: 106,
      deaths: 35
    },
    {
      age: 107,
      deaths: 21
    },
    {
      age: 108,
      deaths: 12
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1994: [
    {
      age: 0,
      deaths: 821
    },
    {
      age: 1,
      deaths: 65
    },
    {
      age: 2,
      deaths: 44
    },
    {
      age: 3,
      deaths: 33
    },
    {
      age: 4,
      deaths: 28
    },
    {
      age: 5,
      deaths: 22
    },
    {
      age: 6,
      deaths: 21
    },
    {
      age: 7,
      deaths: 19
    },
    {
      age: 8,
      deaths: 19
    },
    {
      age: 9,
      deaths: 17
    },
    {
      age: 10,
      deaths: 18
    },
    {
      age: 11,
      deaths: 18
    },
    {
      age: 12,
      deaths: 22
    },
    {
      age: 13,
      deaths: 27
    },
    {
      age: 14,
      deaths: 39
    },
    {
      age: 15,
      deaths: 52
    },
    {
      age: 16,
      deaths: 73
    },
    {
      age: 17,
      deaths: 89
    },
    {
      age: 18,
      deaths: 103
    },
    {
      age: 19,
      deaths: 106
    },
    {
      age: 20,
      deaths: 103
    },
    {
      age: 21,
      deaths: 109
    },
    {
      age: 22,
      deaths: 106
    },
    {
      age: 23,
      deaths: 105
    },
    {
      age: 24,
      deaths: 103
    },
    {
      age: 25,
      deaths: 103
    },
    {
      age: 26,
      deaths: 108
    },
    {
      age: 27,
      deaths: 113
    },
    {
      age: 28,
      deaths: 126
    },
    {
      age: 29,
      deaths: 125
    },
    {
      age: 30,
      deaths: 136
    },
    {
      age: 31,
      deaths: 147
    },
    {
      age: 32,
      deaths: 154
    },
    {
      age: 33,
      deaths: 167
    },
    {
      age: 34,
      deaths: 168
    },
    {
      age: 35,
      deaths: 178
    },
    {
      age: 36,
      deaths: 186
    },
    {
      age: 37,
      deaths: 201
    },
    {
      age: 38,
      deaths: 215
    },
    {
      age: 39,
      deaths: 216
    },
    {
      age: 40,
      deaths: 230
    },
    {
      age: 41,
      deaths: 249
    },
    {
      age: 42,
      deaths: 261
    },
    {
      age: 43,
      deaths: 273
    },
    {
      age: 44,
      deaths: 285
    },
    {
      age: 45,
      deaths: 305
    },
    {
      age: 46,
      deaths: 323
    },
    {
      age: 47,
      deaths: 361
    },
    {
      age: 48,
      deaths: 366
    },
    {
      age: 49,
      deaths: 394
    },
    {
      age: 50,
      deaths: 426
    },
    {
      age: 51,
      deaths: 491
    },
    {
      age: 52,
      deaths: 518
    },
    {
      age: 53,
      deaths: 567
    },
    {
      age: 54,
      deaths: 597
    },
    {
      age: 55,
      deaths: 644
    },
    {
      age: 56,
      deaths: 699
    },
    {
      age: 57,
      deaths: 757
    },
    {
      age: 58,
      deaths: 857
    },
    {
      age: 59,
      deaths: 901
    },
    {
      age: 60,
      deaths: 979
    },
    {
      age: 61,
      deaths: 1093
    },
    {
      age: 62,
      deaths: 1167
    },
    {
      age: 63,
      deaths: 1214
    },
    {
      age: 64,
      deaths: 1303
    },
    {
      age: 65,
      deaths: 1366
    },
    {
      age: 66,
      deaths: 1484
    },
    {
      age: 67,
      deaths: 1583
    },
    {
      age: 68,
      deaths: 1712
    },
    {
      age: 69,
      deaths: 1822
    },
    {
      age: 70,
      deaths: 1904
    },
    {
      age: 71,
      deaths: 2004
    },
    {
      age: 72,
      deaths: 2110
    },
    {
      age: 73,
      deaths: 2215
    },
    {
      age: 74,
      deaths: 2324
    },
    {
      age: 75,
      deaths: 2365
    },
    {
      age: 76,
      deaths: 2551
    },
    {
      age: 77,
      deaths: 2631
    },
    {
      age: 78,
      deaths: 2699
    },
    {
      age: 79,
      deaths: 2821
    },
    {
      age: 80,
      deaths: 3066
    },
    {
      age: 81,
      deaths: 3113
    },
    {
      age: 82,
      deaths: 3159
    },
    {
      age: 83,
      deaths: 3241
    },
    {
      age: 84,
      deaths: 3250
    },
    {
      age: 85,
      deaths: 3228
    },
    {
      age: 86,
      deaths: 3183
    },
    {
      age: 87,
      deaths: 3076
    },
    {
      age: 88,
      deaths: 2996
    },
    {
      age: 89,
      deaths: 2829
    },
    {
      age: 90,
      deaths: 2692
    },
    {
      age: 91,
      deaths: 2444
    },
    {
      age: 92,
      deaths: 2238
    },
    {
      age: 93,
      deaths: 1997
    },
    {
      age: 94,
      deaths: 1748
    },
    {
      age: 95,
      deaths: 1464
    },
    {
      age: 96,
      deaths: 1213
    },
    {
      age: 97,
      deaths: 979
    },
    {
      age: 98,
      deaths: 770
    },
    {
      age: 99,
      deaths: 589
    },
    {
      age: 100,
      deaths: 437
    },
    {
      age: 101,
      deaths: 315
    },
    {
      age: 102,
      deaths: 220
    },
    {
      age: 103,
      deaths: 149
    },
    {
      age: 104,
      deaths: 97
    },
    {
      age: 105,
      deaths: 62
    },
    {
      age: 106,
      deaths: 38
    },
    {
      age: 107,
      deaths: 22
    },
    {
      age: 108,
      deaths: 13
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  1995: [
    {
      age: 0,
      deaths: 777
    },
    {
      age: 1,
      deaths: 63
    },
    {
      age: 2,
      deaths: 41
    },
    {
      age: 3,
      deaths: 32
    },
    {
      age: 4,
      deaths: 25
    },
    {
      age: 5,
      deaths: 21
    },
    {
      age: 6,
      deaths: 20
    },
    {
      age: 7,
      deaths: 19
    },
    {
      age: 8,
      deaths: 19
    },
    {
      age: 9,
      deaths: 17
    },
    {
      age: 10,
      deaths: 17
    },
    {
      age: 11,
      deaths: 19
    },
    {
      age: 12,
      deaths: 22
    },
    {
      age: 13,
      deaths: 28
    },
    {
      age: 14,
      deaths: 38
    },
    {
      age: 15,
      deaths: 49
    },
    {
      age: 16,
      deaths: 72
    },
    {
      age: 17,
      deaths: 85
    },
    {
      age: 18,
      deaths: 100
    },
    {
      age: 19,
      deaths: 101
    },
    {
      age: 20,
      deaths: 102
    },
    {
      age: 21,
      deaths: 105
    },
    {
      age: 22,
      deaths: 104
    },
    {
      age: 23,
      deaths: 103
    },
    {
      age: 24,
      deaths: 101
    },
    {
      age: 25,
      deaths: 100
    },
    {
      age: 26,
      deaths: 106
    },
    {
      age: 27,
      deaths: 110
    },
    {
      age: 28,
      deaths: 122
    },
    {
      age: 29,
      deaths: 123
    },
    {
      age: 30,
      deaths: 134
    },
    {
      age: 31,
      deaths: 149
    },
    {
      age: 32,
      deaths: 151
    },
    {
      age: 33,
      deaths: 157
    },
    {
      age: 34,
      deaths: 169
    },
    {
      age: 35,
      deaths: 174
    },
    {
      age: 36,
      deaths: 188
    },
    {
      age: 37,
      deaths: 199
    },
    {
      age: 38,
      deaths: 217
    },
    {
      age: 39,
      deaths: 218
    },
    {
      age: 40,
      deaths: 228
    },
    {
      age: 41,
      deaths: 253
    },
    {
      age: 42,
      deaths: 266
    },
    {
      age: 43,
      deaths: 281
    },
    {
      age: 44,
      deaths: 287
    },
    {
      age: 45,
      deaths: 303
    },
    {
      age: 46,
      deaths: 326
    },
    {
      age: 47,
      deaths: 345
    },
    {
      age: 48,
      deaths: 397
    },
    {
      age: 49,
      deaths: 372
    },
    {
      age: 50,
      deaths: 430
    },
    {
      age: 51,
      deaths: 471
    },
    {
      age: 52,
      deaths: 524
    },
    {
      age: 53,
      deaths: 555
    },
    {
      age: 54,
      deaths: 591
    },
    {
      age: 55,
      deaths: 639
    },
    {
      age: 56,
      deaths: 706
    },
    {
      age: 57,
      deaths: 757
    },
    {
      age: 58,
      deaths: 830
    },
    {
      age: 59,
      deaths: 885
    },
    {
      age: 60,
      deaths: 981
    },
    {
      age: 61,
      deaths: 1072
    },
    {
      age: 62,
      deaths: 1164
    },
    {
      age: 63,
      deaths: 1222
    },
    {
      age: 64,
      deaths: 1271
    },
    {
      age: 65,
      deaths: 1374
    },
    {
      age: 66,
      deaths: 1444
    },
    {
      age: 67,
      deaths: 1557
    },
    {
      age: 68,
      deaths: 1688
    },
    {
      age: 69,
      deaths: 1796
    },
    {
      age: 70,
      deaths: 1908
    },
    {
      age: 71,
      deaths: 2023
    },
    {
      age: 72,
      deaths: 2100
    },
    {
      age: 73,
      deaths: 2219
    },
    {
      age: 74,
      deaths: 2291
    },
    {
      age: 75,
      deaths: 2439
    },
    {
      age: 76,
      deaths: 2494
    },
    {
      age: 77,
      deaths: 2651
    },
    {
      age: 78,
      deaths: 2722
    },
    {
      age: 79,
      deaths: 2800
    },
    {
      age: 80,
      deaths: 3068
    },
    {
      age: 81,
      deaths: 3127
    },
    {
      age: 82,
      deaths: 3210
    },
    {
      age: 83,
      deaths: 3221
    },
    {
      age: 84,
      deaths: 3267
    },
    {
      age: 85,
      deaths: 3252
    },
    {
      age: 86,
      deaths: 3233
    },
    {
      age: 87,
      deaths: 3145
    },
    {
      age: 88,
      deaths: 3020
    },
    {
      age: 89,
      deaths: 2885
    },
    {
      age: 90,
      deaths: 2700
    },
    {
      age: 91,
      deaths: 2480
    },
    {
      age: 92,
      deaths: 2244
    },
    {
      age: 93,
      deaths: 2026
    },
    {
      age: 94,
      deaths: 1746
    },
    {
      age: 95,
      deaths: 1470
    },
    {
      age: 96,
      deaths: 1213
    },
    {
      age: 97,
      deaths: 975
    },
    {
      age: 98,
      deaths: 763
    },
    {
      age: 99,
      deaths: 580
    },
    {
      age: 100,
      deaths: 428
    },
    {
      age: 101,
      deaths: 306
    },
    {
      age: 102,
      deaths: 212
    },
    {
      age: 103,
      deaths: 142
    },
    {
      age: 104,
      deaths: 92
    },
    {
      age: 105,
      deaths: 57
    },
    {
      age: 106,
      deaths: 35
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1996: [
    {
      age: 0,
      deaths: 755
    },
    {
      age: 1,
      deaths: 58
    },
    {
      age: 2,
      deaths: 38
    },
    {
      age: 3,
      deaths: 32
    },
    {
      age: 4,
      deaths: 24
    },
    {
      age: 5,
      deaths: 21
    },
    {
      age: 6,
      deaths: 20
    },
    {
      age: 7,
      deaths: 18
    },
    {
      age: 8,
      deaths: 18
    },
    {
      age: 9,
      deaths: 17
    },
    {
      age: 10,
      deaths: 17
    },
    {
      age: 11,
      deaths: 18
    },
    {
      age: 12,
      deaths: 21
    },
    {
      age: 13,
      deaths: 26
    },
    {
      age: 14,
      deaths: 34
    },
    {
      age: 15,
      deaths: 45
    },
    {
      age: 16,
      deaths: 70
    },
    {
      age: 17,
      deaths: 79
    },
    {
      age: 18,
      deaths: 94
    },
    {
      age: 19,
      deaths: 98
    },
    {
      age: 20,
      deaths: 97
    },
    {
      age: 21,
      deaths: 102
    },
    {
      age: 22,
      deaths: 99
    },
    {
      age: 23,
      deaths: 95
    },
    {
      age: 24,
      deaths: 93
    },
    {
      age: 25,
      deaths: 98
    },
    {
      age: 26,
      deaths: 97
    },
    {
      age: 27,
      deaths: 99
    },
    {
      age: 28,
      deaths: 106
    },
    {
      age: 29,
      deaths: 111
    },
    {
      age: 30,
      deaths: 112
    },
    {
      age: 31,
      deaths: 129
    },
    {
      age: 32,
      deaths: 139
    },
    {
      age: 33,
      deaths: 143
    },
    {
      age: 34,
      deaths: 150
    },
    {
      age: 35,
      deaths: 156
    },
    {
      age: 36,
      deaths: 172
    },
    {
      age: 37,
      deaths: 180
    },
    {
      age: 38,
      deaths: 196
    },
    {
      age: 39,
      deaths: 198
    },
    {
      age: 40,
      deaths: 215
    },
    {
      age: 41,
      deaths: 238
    },
    {
      age: 42,
      deaths: 243
    },
    {
      age: 43,
      deaths: 263
    },
    {
      age: 44,
      deaths: 276
    },
    {
      age: 45,
      deaths: 297
    },
    {
      age: 46,
      deaths: 320
    },
    {
      age: 47,
      deaths: 342
    },
    {
      age: 48,
      deaths: 366
    },
    {
      age: 49,
      deaths: 390
    },
    {
      age: 50,
      deaths: 386
    },
    {
      age: 51,
      deaths: 463
    },
    {
      age: 52,
      deaths: 496
    },
    {
      age: 53,
      deaths: 554
    },
    {
      age: 54,
      deaths: 577
    },
    {
      age: 55,
      deaths: 627
    },
    {
      age: 56,
      deaths: 689
    },
    {
      age: 57,
      deaths: 739
    },
    {
      age: 58,
      deaths: 827
    },
    {
      age: 59,
      deaths: 864
    },
    {
      age: 60,
      deaths: 951
    },
    {
      age: 61,
      deaths: 1068
    },
    {
      age: 62,
      deaths: 1155
    },
    {
      age: 63,
      deaths: 1230
    },
    {
      age: 64,
      deaths: 1286
    },
    {
      age: 65,
      deaths: 1355
    },
    {
      age: 66,
      deaths: 1449
    },
    {
      age: 67,
      deaths: 1520
    },
    {
      age: 68,
      deaths: 1679
    },
    {
      age: 69,
      deaths: 1781
    },
    {
      age: 70,
      deaths: 1889
    },
    {
      age: 71,
      deaths: 2046
    },
    {
      age: 72,
      deaths: 2118
    },
    {
      age: 73,
      deaths: 2191
    },
    {
      age: 74,
      deaths: 2294
    },
    {
      age: 75,
      deaths: 2382
    },
    {
      age: 76,
      deaths: 2582
    },
    {
      age: 77,
      deaths: 2608
    },
    {
      age: 78,
      deaths: 2773
    },
    {
      age: 79,
      deaths: 2841
    },
    {
      age: 80,
      deaths: 3087
    },
    {
      age: 81,
      deaths: 3156
    },
    {
      age: 82,
      deaths: 3220
    },
    {
      age: 83,
      deaths: 3293
    },
    {
      age: 84,
      deaths: 3316
    },
    {
      age: 85,
      deaths: 3307
    },
    {
      age: 86,
      deaths: 3265
    },
    {
      age: 87,
      deaths: 3160
    },
    {
      age: 88,
      deaths: 3071
    },
    {
      age: 89,
      deaths: 2889
    },
    {
      age: 90,
      deaths: 2749
    },
    {
      age: 91,
      deaths: 2540
    },
    {
      age: 92,
      deaths: 2318
    },
    {
      age: 93,
      deaths: 2012
    },
    {
      age: 94,
      deaths: 1766
    },
    {
      age: 95,
      deaths: 1493
    },
    {
      age: 96,
      deaths: 1232
    },
    {
      age: 97,
      deaths: 991
    },
    {
      age: 98,
      deaths: 775
    },
    {
      age: 99,
      deaths: 589
    },
    {
      age: 100,
      deaths: 434
    },
    {
      age: 101,
      deaths: 310
    },
    {
      age: 102,
      deaths: 214
    },
    {
      age: 103,
      deaths: 143
    },
    {
      age: 104,
      deaths: 93
    },
    {
      age: 105,
      deaths: 58
    },
    {
      age: 106,
      deaths: 35
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  1997: [
    {
      age: 0,
      deaths: 744
    },
    {
      age: 1,
      deaths: 56
    },
    {
      age: 2,
      deaths: 35
    },
    {
      age: 3,
      deaths: 29
    },
    {
      age: 4,
      deaths: 22
    },
    {
      age: 5,
      deaths: 21
    },
    {
      age: 6,
      deaths: 18
    },
    {
      age: 7,
      deaths: 17
    },
    {
      age: 8,
      deaths: 17
    },
    {
      age: 9,
      deaths: 16
    },
    {
      age: 10,
      deaths: 17
    },
    {
      age: 11,
      deaths: 17
    },
    {
      age: 12,
      deaths: 21
    },
    {
      age: 13,
      deaths: 25
    },
    {
      age: 14,
      deaths: 32
    },
    {
      age: 15,
      deaths: 41
    },
    {
      age: 16,
      deaths: 62
    },
    {
      age: 17,
      deaths: 78
    },
    {
      age: 18,
      deaths: 90
    },
    {
      age: 19,
      deaths: 94
    },
    {
      age: 20,
      deaths: 92
    },
    {
      age: 21,
      deaths: 100
    },
    {
      age: 22,
      deaths: 96
    },
    {
      age: 23,
      deaths: 95
    },
    {
      age: 24,
      deaths: 90
    },
    {
      age: 25,
      deaths: 92
    },
    {
      age: 26,
      deaths: 94
    },
    {
      age: 27,
      deaths: 96
    },
    {
      age: 28,
      deaths: 96
    },
    {
      age: 29,
      deaths: 96
    },
    {
      age: 30,
      deaths: 103
    },
    {
      age: 31,
      deaths: 112
    },
    {
      age: 32,
      deaths: 118
    },
    {
      age: 33,
      deaths: 127
    },
    {
      age: 34,
      deaths: 134
    },
    {
      age: 35,
      deaths: 141
    },
    {
      age: 36,
      deaths: 151
    },
    {
      age: 37,
      deaths: 162
    },
    {
      age: 38,
      deaths: 174
    },
    {
      age: 39,
      deaths: 179
    },
    {
      age: 40,
      deaths: 199
    },
    {
      age: 41,
      deaths: 219
    },
    {
      age: 42,
      deaths: 226
    },
    {
      age: 43,
      deaths: 250
    },
    {
      age: 44,
      deaths: 260
    },
    {
      age: 45,
      deaths: 279
    },
    {
      age: 46,
      deaths: 305
    },
    {
      age: 47,
      deaths: 324
    },
    {
      age: 48,
      deaths: 360
    },
    {
      age: 49,
      deaths: 371
    },
    {
      age: 50,
      deaths: 408
    },
    {
      age: 51,
      deaths: 424
    },
    {
      age: 52,
      deaths: 490
    },
    {
      age: 53,
      deaths: 527
    },
    {
      age: 54,
      deaths: 576
    },
    {
      age: 55,
      deaths: 610
    },
    {
      age: 56,
      deaths: 680
    },
    {
      age: 57,
      deaths: 737
    },
    {
      age: 58,
      deaths: 806
    },
    {
      age: 59,
      deaths: 851
    },
    {
      age: 60,
      deaths: 918
    },
    {
      age: 61,
      deaths: 1032
    },
    {
      age: 62,
      deaths: 1128
    },
    {
      age: 63,
      deaths: 1189
    },
    {
      age: 64,
      deaths: 1289
    },
    {
      age: 65,
      deaths: 1354
    },
    {
      age: 66,
      deaths: 1433
    },
    {
      age: 67,
      deaths: 1529
    },
    {
      age: 68,
      deaths: 1648
    },
    {
      age: 69,
      deaths: 1756
    },
    {
      age: 70,
      deaths: 1874
    },
    {
      age: 71,
      deaths: 2015
    },
    {
      age: 72,
      deaths: 2137
    },
    {
      age: 73,
      deaths: 2207
    },
    {
      age: 74,
      deaths: 2258
    },
    {
      age: 75,
      deaths: 2399
    },
    {
      age: 76,
      deaths: 2518
    },
    {
      age: 77,
      deaths: 2700
    },
    {
      age: 78,
      deaths: 2726
    },
    {
      age: 79,
      deaths: 2880
    },
    {
      age: 80,
      deaths: 3086
    },
    {
      age: 81,
      deaths: 3191
    },
    {
      age: 82,
      deaths: 3260
    },
    {
      age: 83,
      deaths: 3326
    },
    {
      age: 84,
      deaths: 3332
    },
    {
      age: 85,
      deaths: 3337
    },
    {
      age: 86,
      deaths: 3328
    },
    {
      age: 87,
      deaths: 3264
    },
    {
      age: 88,
      deaths: 3094
    },
    {
      age: 89,
      deaths: 2977
    },
    {
      age: 90,
      deaths: 2833
    },
    {
      age: 91,
      deaths: 2578
    },
    {
      age: 92,
      deaths: 2336
    },
    {
      age: 93,
      deaths: 2099
    },
    {
      age: 94,
      deaths: 1811
    },
    {
      age: 95,
      deaths: 1514
    },
    {
      age: 96,
      deaths: 1248
    },
    {
      age: 97,
      deaths: 1001
    },
    {
      age: 98,
      deaths: 781
    },
    {
      age: 99,
      deaths: 591
    },
    {
      age: 100,
      deaths: 434
    },
    {
      age: 101,
      deaths: 309
    },
    {
      age: 102,
      deaths: 212
    },
    {
      age: 103,
      deaths: 141
    },
    {
      age: 104,
      deaths: 91
    },
    {
      age: 105,
      deaths: 56
    },
    {
      age: 106,
      deaths: 34
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1998: [
    {
      age: 0,
      deaths: 749
    },
    {
      age: 1,
      deaths: 54
    },
    {
      age: 2,
      deaths: 35
    },
    {
      age: 3,
      deaths: 26
    },
    {
      age: 4,
      deaths: 21
    },
    {
      age: 5,
      deaths: 20
    },
    {
      age: 6,
      deaths: 17
    },
    {
      age: 7,
      deaths: 18
    },
    {
      age: 8,
      deaths: 16
    },
    {
      age: 9,
      deaths: 15
    },
    {
      age: 10,
      deaths: 16
    },
    {
      age: 11,
      deaths: 17
    },
    {
      age: 12,
      deaths: 20
    },
    {
      age: 13,
      deaths: 24
    },
    {
      age: 14,
      deaths: 30
    },
    {
      age: 15,
      deaths: 40
    },
    {
      age: 16,
      deaths: 59
    },
    {
      age: 17,
      deaths: 70
    },
    {
      age: 18,
      deaths: 89
    },
    {
      age: 19,
      deaths: 88
    },
    {
      age: 20,
      deaths: 88
    },
    {
      age: 21,
      deaths: 95
    },
    {
      age: 22,
      deaths: 91
    },
    {
      age: 23,
      deaths: 91
    },
    {
      age: 24,
      deaths: 89
    },
    {
      age: 25,
      deaths: 88
    },
    {
      age: 26,
      deaths: 88
    },
    {
      age: 27,
      deaths: 92
    },
    {
      age: 28,
      deaths: 89
    },
    {
      age: 29,
      deaths: 92
    },
    {
      age: 30,
      deaths: 96
    },
    {
      age: 31,
      deaths: 107
    },
    {
      age: 32,
      deaths: 110
    },
    {
      age: 33,
      deaths: 122
    },
    {
      age: 34,
      deaths: 129
    },
    {
      age: 35,
      deaths: 135
    },
    {
      age: 36,
      deaths: 145
    },
    {
      age: 37,
      deaths: 158
    },
    {
      age: 38,
      deaths: 169
    },
    {
      age: 39,
      deaths: 179
    },
    {
      age: 40,
      deaths: 189
    },
    {
      age: 41,
      deaths: 211
    },
    {
      age: 42,
      deaths: 225
    },
    {
      age: 43,
      deaths: 248
    },
    {
      age: 44,
      deaths: 268
    },
    {
      age: 45,
      deaths: 276
    },
    {
      age: 46,
      deaths: 301
    },
    {
      age: 47,
      deaths: 321
    },
    {
      age: 48,
      deaths: 354
    },
    {
      age: 49,
      deaths: 366
    },
    {
      age: 50,
      deaths: 389
    },
    {
      age: 51,
      deaths: 456
    },
    {
      age: 52,
      deaths: 449
    },
    {
      age: 53,
      deaths: 521
    },
    {
      age: 54,
      deaths: 533
    },
    {
      age: 55,
      deaths: 622
    },
    {
      age: 56,
      deaths: 665
    },
    {
      age: 57,
      deaths: 716
    },
    {
      age: 58,
      deaths: 786
    },
    {
      age: 59,
      deaths: 833
    },
    {
      age: 60,
      deaths: 904
    },
    {
      age: 61,
      deaths: 1012
    },
    {
      age: 62,
      deaths: 1094
    },
    {
      age: 63,
      deaths: 1187
    },
    {
      age: 64,
      deaths: 1246
    },
    {
      age: 65,
      deaths: 1373
    },
    {
      age: 66,
      deaths: 1461
    },
    {
      age: 67,
      deaths: 1490
    },
    {
      age: 68,
      deaths: 1634
    },
    {
      age: 69,
      deaths: 1713
    },
    {
      age: 70,
      deaths: 1845
    },
    {
      age: 71,
      deaths: 2019
    },
    {
      age: 72,
      deaths: 2142
    },
    {
      age: 73,
      deaths: 2216
    },
    {
      age: 74,
      deaths: 2312
    },
    {
      age: 75,
      deaths: 2376
    },
    {
      age: 76,
      deaths: 2538
    },
    {
      age: 77,
      deaths: 2644
    },
    {
      age: 78,
      deaths: 2803
    },
    {
      age: 79,
      deaths: 2831
    },
    {
      age: 80,
      deaths: 3127
    },
    {
      age: 81,
      deaths: 3212
    },
    {
      age: 82,
      deaths: 3300
    },
    {
      age: 83,
      deaths: 3375
    },
    {
      age: 84,
      deaths: 3389
    },
    {
      age: 85,
      deaths: 3369
    },
    {
      age: 86,
      deaths: 3335
    },
    {
      age: 87,
      deaths: 3290
    },
    {
      age: 88,
      deaths: 3159
    },
    {
      age: 89,
      deaths: 3012
    },
    {
      age: 90,
      deaths: 2847
    },
    {
      age: 91,
      deaths: 2589
    },
    {
      age: 92,
      deaths: 2364
    },
    {
      age: 93,
      deaths: 2108
    },
    {
      age: 94,
      deaths: 1828
    },
    {
      age: 95,
      deaths: 1523
    },
    {
      age: 96,
      deaths: 1252
    },
    {
      age: 97,
      deaths: 1003
    },
    {
      age: 98,
      deaths: 780
    },
    {
      age: 99,
      deaths: 589
    },
    {
      age: 100,
      deaths: 431
    },
    {
      age: 101,
      deaths: 306
    },
    {
      age: 102,
      deaths: 210
    },
    {
      age: 103,
      deaths: 139
    },
    {
      age: 104,
      deaths: 89
    },
    {
      age: 105,
      deaths: 55
    },
    {
      age: 106,
      deaths: 33
    },
    {
      age: 107,
      deaths: 19
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  1999: [
    {
      age: 0,
      deaths: 732
    },
    {
      age: 1,
      deaths: 53
    },
    {
      age: 2,
      deaths: 36
    },
    {
      age: 3,
      deaths: 27
    },
    {
      age: 4,
      deaths: 21
    },
    {
      age: 5,
      deaths: 19
    },
    {
      age: 6,
      deaths: 17
    },
    {
      age: 7,
      deaths: 16
    },
    {
      age: 8,
      deaths: 16
    },
    {
      age: 9,
      deaths: 15
    },
    {
      age: 10,
      deaths: 16
    },
    {
      age: 11,
      deaths: 17
    },
    {
      age: 12,
      deaths: 18
    },
    {
      age: 13,
      deaths: 21
    },
    {
      age: 14,
      deaths: 29
    },
    {
      age: 15,
      deaths: 38
    },
    {
      age: 16,
      deaths: 58
    },
    {
      age: 17,
      deaths: 71
    },
    {
      age: 18,
      deaths: 84
    },
    {
      age: 19,
      deaths: 87
    },
    {
      age: 20,
      deaths: 88
    },
    {
      age: 21,
      deaths: 93
    },
    {
      age: 22,
      deaths: 91
    },
    {
      age: 23,
      deaths: 90
    },
    {
      age: 24,
      deaths: 87
    },
    {
      age: 25,
      deaths: 83
    },
    {
      age: 26,
      deaths: 87
    },
    {
      age: 27,
      deaths: 88
    },
    {
      age: 28,
      deaths: 90
    },
    {
      age: 29,
      deaths: 94
    },
    {
      age: 30,
      deaths: 94
    },
    {
      age: 31,
      deaths: 103
    },
    {
      age: 32,
      deaths: 110
    },
    {
      age: 33,
      deaths: 117
    },
    {
      age: 34,
      deaths: 125
    },
    {
      age: 35,
      deaths: 132
    },
    {
      age: 36,
      deaths: 142
    },
    {
      age: 37,
      deaths: 155
    },
    {
      age: 38,
      deaths: 171
    },
    {
      age: 39,
      deaths: 178
    },
    {
      age: 40,
      deaths: 186
    },
    {
      age: 41,
      deaths: 212
    },
    {
      age: 42,
      deaths: 225
    },
    {
      age: 43,
      deaths: 249
    },
    {
      age: 44,
      deaths: 269
    },
    {
      age: 45,
      deaths: 279
    },
    {
      age: 46,
      deaths: 312
    },
    {
      age: 47,
      deaths: 328
    },
    {
      age: 48,
      deaths: 355
    },
    {
      age: 49,
      deaths: 376
    },
    {
      age: 50,
      deaths: 388
    },
    {
      age: 51,
      deaths: 429
    },
    {
      age: 52,
      deaths: 483
    },
    {
      age: 53,
      deaths: 507
    },
    {
      age: 54,
      deaths: 554
    },
    {
      age: 55,
      deaths: 603
    },
    {
      age: 56,
      deaths: 682
    },
    {
      age: 57,
      deaths: 717
    },
    {
      age: 58,
      deaths: 787
    },
    {
      age: 59,
      deaths: 851
    },
    {
      age: 60,
      deaths: 892
    },
    {
      age: 61,
      deaths: 1002
    },
    {
      age: 62,
      deaths: 1070
    },
    {
      age: 63,
      deaths: 1151
    },
    {
      age: 64,
      deaths: 1266
    },
    {
      age: 65,
      deaths: 1344
    },
    {
      age: 66,
      deaths: 1450
    },
    {
      age: 67,
      deaths: 1490
    },
    {
      age: 68,
      deaths: 1613
    },
    {
      age: 69,
      deaths: 1746
    },
    {
      age: 70,
      deaths: 1794
    },
    {
      age: 71,
      deaths: 1980
    },
    {
      age: 72,
      deaths: 2128
    },
    {
      age: 73,
      deaths: 2214
    },
    {
      age: 74,
      deaths: 2309
    },
    {
      age: 75,
      deaths: 2421
    },
    {
      age: 76,
      deaths: 2514
    },
    {
      age: 77,
      deaths: 2674
    },
    {
      age: 78,
      deaths: 2799
    },
    {
      age: 79,
      deaths: 2970
    },
    {
      age: 80,
      deaths: 3128
    },
    {
      age: 81,
      deaths: 3235
    },
    {
      age: 82,
      deaths: 3345
    },
    {
      age: 83,
      deaths: 3402
    },
    {
      age: 84,
      deaths: 3421
    },
    {
      age: 85,
      deaths: 3438
    },
    {
      age: 86,
      deaths: 3401
    },
    {
      age: 87,
      deaths: 3314
    },
    {
      age: 88,
      deaths: 3231
    },
    {
      age: 89,
      deaths: 3054
    },
    {
      age: 90,
      deaths: 2832
    },
    {
      age: 91,
      deaths: 2652
    },
    {
      age: 92,
      deaths: 2369
    },
    {
      age: 93,
      deaths: 2055
    },
    {
      age: 94,
      deaths: 1796
    },
    {
      age: 95,
      deaths: 1489
    },
    {
      age: 96,
      deaths: 1212
    },
    {
      age: 97,
      deaths: 959
    },
    {
      age: 98,
      deaths: 737
    },
    {
      age: 99,
      deaths: 548
    },
    {
      age: 100,
      deaths: 395
    },
    {
      age: 101,
      deaths: 275
    },
    {
      age: 102,
      deaths: 185
    },
    {
      age: 103,
      deaths: 120
    },
    {
      age: 104,
      deaths: 75
    },
    {
      age: 105,
      deaths: 45
    },
    {
      age: 106,
      deaths: 26
    },
    {
      age: 107,
      deaths: 15
    },
    {
      age: 108,
      deaths: 8
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2000: [
    {
      age: 0,
      deaths: 725
    },
    {
      age: 1,
      deaths: 51
    },
    {
      age: 2,
      deaths: 34
    },
    {
      age: 3,
      deaths: 24
    },
    {
      age: 4,
      deaths: 20
    },
    {
      age: 5,
      deaths: 19
    },
    {
      age: 6,
      deaths: 16
    },
    {
      age: 7,
      deaths: 15
    },
    {
      age: 8,
      deaths: 15
    },
    {
      age: 9,
      deaths: 14
    },
    {
      age: 10,
      deaths: 15
    },
    {
      age: 11,
      deaths: 18
    },
    {
      age: 12,
      deaths: 18
    },
    {
      age: 13,
      deaths: 21
    },
    {
      age: 14,
      deaths: 28
    },
    {
      age: 15,
      deaths: 38
    },
    {
      age: 16,
      deaths: 54
    },
    {
      age: 17,
      deaths: 67
    },
    {
      age: 18,
      deaths: 82
    },
    {
      age: 19,
      deaths: 88
    },
    {
      age: 20,
      deaths: 91
    },
    {
      age: 21,
      deaths: 96
    },
    {
      age: 22,
      deaths: 91
    },
    {
      age: 23,
      deaths: 91
    },
    {
      age: 24,
      deaths: 89
    },
    {
      age: 25,
      deaths: 87
    },
    {
      age: 26,
      deaths: 87
    },
    {
      age: 27,
      deaths: 89
    },
    {
      age: 28,
      deaths: 89
    },
    {
      age: 29,
      deaths: 94
    },
    {
      age: 30,
      deaths: 93
    },
    {
      age: 31,
      deaths: 103
    },
    {
      age: 32,
      deaths: 106
    },
    {
      age: 33,
      deaths: 115
    },
    {
      age: 34,
      deaths: 123
    },
    {
      age: 35,
      deaths: 128
    },
    {
      age: 36,
      deaths: 141
    },
    {
      age: 37,
      deaths: 154
    },
    {
      age: 38,
      deaths: 168
    },
    {
      age: 39,
      deaths: 179
    },
    {
      age: 40,
      deaths: 194
    },
    {
      age: 41,
      deaths: 212
    },
    {
      age: 42,
      deaths: 225
    },
    {
      age: 43,
      deaths: 253
    },
    {
      age: 44,
      deaths: 266
    },
    {
      age: 45,
      deaths: 285
    },
    {
      age: 46,
      deaths: 309
    },
    {
      age: 47,
      deaths: 334
    },
    {
      age: 48,
      deaths: 364
    },
    {
      age: 49,
      deaths: 383
    },
    {
      age: 50,
      deaths: 403
    },
    {
      age: 51,
      deaths: 434
    },
    {
      age: 52,
      deaths: 458
    },
    {
      age: 53,
      deaths: 556
    },
    {
      age: 54,
      deaths: 522
    },
    {
      age: 55,
      deaths: 599
    },
    {
      age: 56,
      deaths: 642
    },
    {
      age: 57,
      deaths: 734
    },
    {
      age: 58,
      deaths: 769
    },
    {
      age: 59,
      deaths: 837
    },
    {
      age: 60,
      deaths: 890
    },
    {
      age: 61,
      deaths: 972
    },
    {
      age: 62,
      deaths: 1069
    },
    {
      age: 63,
      deaths: 1135
    },
    {
      age: 64,
      deaths: 1225
    },
    {
      age: 65,
      deaths: 1322
    },
    {
      age: 66,
      deaths: 1400
    },
    {
      age: 67,
      deaths: 1502
    },
    {
      age: 68,
      deaths: 1597
    },
    {
      age: 69,
      deaths: 1676
    },
    {
      age: 70,
      deaths: 1813
    },
    {
      age: 71,
      deaths: 1914
    },
    {
      age: 72,
      deaths: 2077
    },
    {
      age: 73,
      deaths: 2176
    },
    {
      age: 74,
      deaths: 2265
    },
    {
      age: 75,
      deaths: 2425
    },
    {
      age: 76,
      deaths: 2532
    },
    {
      age: 77,
      deaths: 2634
    },
    {
      age: 78,
      deaths: 2777
    },
    {
      age: 79,
      deaths: 2941
    },
    {
      age: 80,
      deaths: 3191
    },
    {
      age: 81,
      deaths: 3188
    },
    {
      age: 82,
      deaths: 3328
    },
    {
      age: 83,
      deaths: 3387
    },
    {
      age: 84,
      deaths: 3444
    },
    {
      age: 85,
      deaths: 3469
    },
    {
      age: 86,
      deaths: 3436
    },
    {
      age: 87,
      deaths: 3367
    },
    {
      age: 88,
      deaths: 3231
    },
    {
      age: 89,
      deaths: 3110
    },
    {
      age: 90,
      deaths: 2910
    },
    {
      age: 91,
      deaths: 2670
    },
    {
      age: 92,
      deaths: 2442
    },
    {
      age: 93,
      deaths: 2124
    },
    {
      age: 94,
      deaths: 1824
    },
    {
      age: 95,
      deaths: 1511
    },
    {
      age: 96,
      deaths: 1230
    },
    {
      age: 97,
      deaths: 973
    },
    {
      age: 98,
      deaths: 746
    },
    {
      age: 99,
      deaths: 555
    },
    {
      age: 100,
      deaths: 399
    },
    {
      age: 101,
      deaths: 277
    },
    {
      age: 102,
      deaths: 186
    },
    {
      age: 103,
      deaths: 120
    },
    {
      age: 104,
      deaths: 75
    },
    {
      age: 105,
      deaths: 45
    },
    {
      age: 106,
      deaths: 26
    },
    {
      age: 107,
      deaths: 15
    },
    {
      age: 108,
      deaths: 8
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2001: [
    {
      age: 0,
      deaths: 696
    },
    {
      age: 1,
      deaths: 51
    },
    {
      age: 2,
      deaths: 33
    },
    {
      age: 3,
      deaths: 26
    },
    {
      age: 4,
      deaths: 21
    },
    {
      age: 5,
      deaths: 17
    },
    {
      age: 6,
      deaths: 16
    },
    {
      age: 7,
      deaths: 14
    },
    {
      age: 8,
      deaths: 14
    },
    {
      age: 9,
      deaths: 15
    },
    {
      age: 10,
      deaths: 15
    },
    {
      age: 11,
      deaths: 16
    },
    {
      age: 12,
      deaths: 17
    },
    {
      age: 13,
      deaths: 21
    },
    {
      age: 14,
      deaths: 27
    },
    {
      age: 15,
      deaths: 36
    },
    {
      age: 16,
      deaths: 53
    },
    {
      age: 17,
      deaths: 67
    },
    {
      age: 18,
      deaths: 82
    },
    {
      age: 19,
      deaths: 90
    },
    {
      age: 20,
      deaths: 90
    },
    {
      age: 21,
      deaths: 99
    },
    {
      age: 22,
      deaths: 93
    },
    {
      age: 23,
      deaths: 94
    },
    {
      age: 24,
      deaths: 93
    },
    {
      age: 25,
      deaths: 90
    },
    {
      age: 26,
      deaths: 94
    },
    {
      age: 27,
      deaths: 92
    },
    {
      age: 28,
      deaths: 97
    },
    {
      age: 29,
      deaths: 97
    },
    {
      age: 30,
      deaths: 102
    },
    {
      age: 31,
      deaths: 103
    },
    {
      age: 32,
      deaths: 108
    },
    {
      age: 33,
      deaths: 118
    },
    {
      age: 34,
      deaths: 124
    },
    {
      age: 35,
      deaths: 135
    },
    {
      age: 36,
      deaths: 145
    },
    {
      age: 37,
      deaths: 160
    },
    {
      age: 38,
      deaths: 174
    },
    {
      age: 39,
      deaths: 185
    },
    {
      age: 40,
      deaths: 198
    },
    {
      age: 41,
      deaths: 213
    },
    {
      age: 42,
      deaths: 223
    },
    {
      age: 43,
      deaths: 253
    },
    {
      age: 44,
      deaths: 270
    },
    {
      age: 45,
      deaths: 289
    },
    {
      age: 46,
      deaths: 313
    },
    {
      age: 47,
      deaths: 337
    },
    {
      age: 48,
      deaths: 360
    },
    {
      age: 49,
      deaths: 393
    },
    {
      age: 50,
      deaths: 407
    },
    {
      age: 51,
      deaths: 436
    },
    {
      age: 52,
      deaths: 462
    },
    {
      age: 53,
      deaths: 504
    },
    {
      age: 54,
      deaths: 573
    },
    {
      age: 55,
      deaths: 560
    },
    {
      age: 56,
      deaths: 638
    },
    {
      age: 57,
      deaths: 698
    },
    {
      age: 58,
      deaths: 789
    },
    {
      age: 59,
      deaths: 814
    },
    {
      age: 60,
      deaths: 884
    },
    {
      age: 61,
      deaths: 946
    },
    {
      age: 62,
      deaths: 1030
    },
    {
      age: 63,
      deaths: 1124
    },
    {
      age: 64,
      deaths: 1188
    },
    {
      age: 65,
      deaths: 1278
    },
    {
      age: 66,
      deaths: 1382
    },
    {
      age: 67,
      deaths: 1457
    },
    {
      age: 68,
      deaths: 1570
    },
    {
      age: 69,
      deaths: 1677
    },
    {
      age: 70,
      deaths: 1766
    },
    {
      age: 71,
      deaths: 1899
    },
    {
      age: 72,
      deaths: 1990
    },
    {
      age: 73,
      deaths: 2168
    },
    {
      age: 74,
      deaths: 2250
    },
    {
      age: 75,
      deaths: 2369
    },
    {
      age: 76,
      deaths: 2532
    },
    {
      age: 77,
      deaths: 2635
    },
    {
      age: 78,
      deaths: 2715
    },
    {
      age: 79,
      deaths: 2920
    },
    {
      age: 80,
      deaths: 3072
    },
    {
      age: 81,
      deaths: 3272
    },
    {
      age: 82,
      deaths: 3309
    },
    {
      age: 83,
      deaths: 3398
    },
    {
      age: 84,
      deaths: 3456
    },
    {
      age: 85,
      deaths: 3494
    },
    {
      age: 86,
      deaths: 3471
    },
    {
      age: 87,
      deaths: 3417
    },
    {
      age: 88,
      deaths: 3300
    },
    {
      age: 89,
      deaths: 3157
    },
    {
      age: 90,
      deaths: 2942
    },
    {
      age: 91,
      deaths: 2715
    },
    {
      age: 92,
      deaths: 2440
    },
    {
      age: 93,
      deaths: 2197
    },
    {
      age: 94,
      deaths: 1870
    },
    {
      age: 95,
      deaths: 1554
    },
    {
      age: 96,
      deaths: 1266
    },
    {
      age: 97,
      deaths: 1003
    },
    {
      age: 98,
      deaths: 770
    },
    {
      age: 99,
      deaths: 572
    },
    {
      age: 100,
      deaths: 412
    },
    {
      age: 101,
      deaths: 286
    },
    {
      age: 102,
      deaths: 192
    },
    {
      age: 103,
      deaths: 124
    },
    {
      age: 104,
      deaths: 77
    },
    {
      age: 105,
      deaths: 46
    },
    {
      age: 106,
      deaths: 27
    },
    {
      age: 107,
      deaths: 15
    },
    {
      age: 108,
      deaths: 8
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2002: [
    {
      age: 0,
      deaths: 701
    },
    {
      age: 1,
      deaths: 48
    },
    {
      age: 2,
      deaths: 32
    },
    {
      age: 3,
      deaths: 24
    },
    {
      age: 4,
      deaths: 20
    },
    {
      age: 5,
      deaths: 17
    },
    {
      age: 6,
      deaths: 14
    },
    {
      age: 7,
      deaths: 14
    },
    {
      age: 8,
      deaths: 15
    },
    {
      age: 9,
      deaths: 14
    },
    {
      age: 10,
      deaths: 15
    },
    {
      age: 11,
      deaths: 15
    },
    {
      age: 12,
      deaths: 18
    },
    {
      age: 13,
      deaths: 22
    },
    {
      age: 14,
      deaths: 26
    },
    {
      age: 15,
      deaths: 34
    },
    {
      age: 16,
      deaths: 56
    },
    {
      age: 17,
      deaths: 67
    },
    {
      age: 18,
      deaths: 83
    },
    {
      age: 19,
      deaths: 92
    },
    {
      age: 20,
      deaths: 91
    },
    {
      age: 21,
      deaths: 97
    },
    {
      age: 22,
      deaths: 93
    },
    {
      age: 23,
      deaths: 93
    },
    {
      age: 24,
      deaths: 94
    },
    {
      age: 25,
      deaths: 91
    },
    {
      age: 26,
      deaths: 95
    },
    {
      age: 27,
      deaths: 92
    },
    {
      age: 28,
      deaths: 93
    },
    {
      age: 29,
      deaths: 99
    },
    {
      age: 30,
      deaths: 102
    },
    {
      age: 31,
      deaths: 106
    },
    {
      age: 32,
      deaths: 107
    },
    {
      age: 33,
      deaths: 116
    },
    {
      age: 34,
      deaths: 123
    },
    {
      age: 35,
      deaths: 133
    },
    {
      age: 36,
      deaths: 141
    },
    {
      age: 37,
      deaths: 155
    },
    {
      age: 38,
      deaths: 168
    },
    {
      age: 39,
      deaths: 185
    },
    {
      age: 40,
      deaths: 202
    },
    {
      age: 41,
      deaths: 214
    },
    {
      age: 42,
      deaths: 231
    },
    {
      age: 43,
      deaths: 250
    },
    {
      age: 44,
      deaths: 273
    },
    {
      age: 45,
      deaths: 292
    },
    {
      age: 46,
      deaths: 314
    },
    {
      age: 47,
      deaths: 335
    },
    {
      age: 48,
      deaths: 367
    },
    {
      age: 49,
      deaths: 388
    },
    {
      age: 50,
      deaths: 419
    },
    {
      age: 51,
      deaths: 441
    },
    {
      age: 52,
      deaths: 468
    },
    {
      age: 53,
      deaths: 506
    },
    {
      age: 54,
      deaths: 537
    },
    {
      age: 55,
      deaths: 615
    },
    {
      age: 56,
      deaths: 599
    },
    {
      age: 57,
      deaths: 681
    },
    {
      age: 58,
      deaths: 742
    },
    {
      age: 59,
      deaths: 831
    },
    {
      age: 60,
      deaths: 876
    },
    {
      age: 61,
      deaths: 929
    },
    {
      age: 62,
      deaths: 1020
    },
    {
      age: 63,
      deaths: 1088
    },
    {
      age: 64,
      deaths: 1185
    },
    {
      age: 65,
      deaths: 1246
    },
    {
      age: 66,
      deaths: 1331
    },
    {
      age: 67,
      deaths: 1457
    },
    {
      age: 68,
      deaths: 1520
    },
    {
      age: 69,
      deaths: 1655
    },
    {
      age: 70,
      deaths: 1783
    },
    {
      age: 71,
      deaths: 1864
    },
    {
      age: 72,
      deaths: 1980
    },
    {
      age: 73,
      deaths: 2097
    },
    {
      age: 74,
      deaths: 2251
    },
    {
      age: 75,
      deaths: 2385
    },
    {
      age: 76,
      deaths: 2470
    },
    {
      age: 77,
      deaths: 2630
    },
    {
      age: 78,
      deaths: 2733
    },
    {
      age: 79,
      deaths: 2844
    },
    {
      age: 80,
      deaths: 3114
    },
    {
      age: 81,
      deaths: 3186
    },
    {
      age: 82,
      deaths: 3405
    },
    {
      age: 83,
      deaths: 3393
    },
    {
      age: 84,
      deaths: 3483
    },
    {
      age: 85,
      deaths: 3497
    },
    {
      age: 86,
      deaths: 3531
    },
    {
      age: 87,
      deaths: 3446
    },
    {
      age: 88,
      deaths: 3350
    },
    {
      age: 89,
      deaths: 3214
    },
    {
      age: 90,
      deaths: 2988
    },
    {
      age: 91,
      deaths: 2769
    },
    {
      age: 92,
      deaths: 2507
    },
    {
      age: 93,
      deaths: 2221
    },
    {
      age: 94,
      deaths: 1912
    },
    {
      age: 95,
      deaths: 1564
    },
    {
      age: 96,
      deaths: 1271
    },
    {
      age: 97,
      deaths: 1002
    },
    {
      age: 98,
      deaths: 766
    },
    {
      age: 99,
      deaths: 567
    },
    {
      age: 100,
      deaths: 405
    },
    {
      age: 101,
      deaths: 280
    },
    {
      age: 102,
      deaths: 186
    },
    {
      age: 103,
      deaths: 119
    },
    {
      age: 104,
      deaths: 74
    },
    {
      age: 105,
      deaths: 44
    },
    {
      age: 106,
      deaths: 25
    },
    {
      age: 107,
      deaths: 14
    },
    {
      age: 108,
      deaths: 7
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2003: [
    {
      age: 0,
      deaths: 699
    },
    {
      age: 1,
      deaths: 47
    },
    {
      age: 2,
      deaths: 33
    },
    {
      age: 3,
      deaths: 26
    },
    {
      age: 4,
      deaths: 20
    },
    {
      age: 5,
      deaths: 17
    },
    {
      age: 6,
      deaths: 15
    },
    {
      age: 7,
      deaths: 14
    },
    {
      age: 8,
      deaths: 14
    },
    {
      age: 9,
      deaths: 13
    },
    {
      age: 10,
      deaths: 16
    },
    {
      age: 11,
      deaths: 14
    },
    {
      age: 12,
      deaths: 17
    },
    {
      age: 13,
      deaths: 21
    },
    {
      age: 14,
      deaths: 25
    },
    {
      age: 15,
      deaths: 33
    },
    {
      age: 16,
      deaths: 52
    },
    {
      age: 17,
      deaths: 64
    },
    {
      age: 18,
      deaths: 84
    },
    {
      age: 19,
      deaths: 89
    },
    {
      age: 20,
      deaths: 94
    },
    {
      age: 21,
      deaths: 95
    },
    {
      age: 22,
      deaths: 98
    },
    {
      age: 23,
      deaths: 96
    },
    {
      age: 24,
      deaths: 95
    },
    {
      age: 25,
      deaths: 92
    },
    {
      age: 26,
      deaths: 96
    },
    {
      age: 27,
      deaths: 94
    },
    {
      age: 28,
      deaths: 93
    },
    {
      age: 29,
      deaths: 99
    },
    {
      age: 30,
      deaths: 100
    },
    {
      age: 31,
      deaths: 104
    },
    {
      age: 32,
      deaths: 109
    },
    {
      age: 33,
      deaths: 116
    },
    {
      age: 34,
      deaths: 120
    },
    {
      age: 35,
      deaths: 132
    },
    {
      age: 36,
      deaths: 142
    },
    {
      age: 37,
      deaths: 153
    },
    {
      age: 38,
      deaths: 165
    },
    {
      age: 39,
      deaths: 179
    },
    {
      age: 40,
      deaths: 196
    },
    {
      age: 41,
      deaths: 214
    },
    {
      age: 42,
      deaths: 230
    },
    {
      age: 43,
      deaths: 248
    },
    {
      age: 44,
      deaths: 271
    },
    {
      age: 45,
      deaths: 290
    },
    {
      age: 46,
      deaths: 321
    },
    {
      age: 47,
      deaths: 338
    },
    {
      age: 48,
      deaths: 366
    },
    {
      age: 49,
      deaths: 391
    },
    {
      age: 50,
      deaths: 422
    },
    {
      age: 51,
      deaths: 450
    },
    {
      age: 52,
      deaths: 473
    },
    {
      age: 53,
      deaths: 496
    },
    {
      age: 54,
      deaths: 547
    },
    {
      age: 55,
      deaths: 579
    },
    {
      age: 56,
      deaths: 654
    },
    {
      age: 57,
      deaths: 638
    },
    {
      age: 58,
      deaths: 737
    },
    {
      age: 59,
      deaths: 802
    },
    {
      age: 60,
      deaths: 896
    },
    {
      age: 61,
      deaths: 925
    },
    {
      age: 62,
      deaths: 1007
    },
    {
      age: 63,
      deaths: 1071
    },
    {
      age: 64,
      deaths: 1162
    },
    {
      age: 65,
      deaths: 1237
    },
    {
      age: 66,
      deaths: 1303
    },
    {
      age: 67,
      deaths: 1412
    },
    {
      age: 68,
      deaths: 1513
    },
    {
      age: 69,
      deaths: 1600
    },
    {
      age: 70,
      deaths: 1747
    },
    {
      age: 71,
      deaths: 1816
    },
    {
      age: 72,
      deaths: 1949
    },
    {
      age: 73,
      deaths: 2074
    },
    {
      age: 74,
      deaths: 2164
    },
    {
      age: 75,
      deaths: 2363
    },
    {
      age: 76,
      deaths: 2467
    },
    {
      age: 77,
      deaths: 2575
    },
    {
      age: 78,
      deaths: 2709
    },
    {
      age: 79,
      deaths: 2842
    },
    {
      age: 80,
      deaths: 3064
    },
    {
      age: 81,
      deaths: 3186
    },
    {
      age: 82,
      deaths: 3268
    },
    {
      age: 83,
      deaths: 3476
    },
    {
      age: 84,
      deaths: 3443
    },
    {
      age: 85,
      deaths: 3510
    },
    {
      age: 86,
      deaths: 3535
    },
    {
      age: 87,
      deaths: 3492
    },
    {
      age: 88,
      deaths: 3404
    },
    {
      age: 89,
      deaths: 3260
    },
    {
      age: 90,
      deaths: 3067
    },
    {
      age: 91,
      deaths: 2829
    },
    {
      age: 92,
      deaths: 2574
    },
    {
      age: 93,
      deaths: 2278
    },
    {
      age: 94,
      deaths: 1936
    },
    {
      age: 95,
      deaths: 1628
    },
    {
      age: 96,
      deaths: 1325
    },
    {
      age: 97,
      deaths: 1046
    },
    {
      age: 98,
      deaths: 800
    },
    {
      age: 99,
      deaths: 591
    },
    {
      age: 100,
      deaths: 422
    },
    {
      age: 101,
      deaths: 291
    },
    {
      age: 102,
      deaths: 193
    },
    {
      age: 103,
      deaths: 124
    },
    {
      age: 104,
      deaths: 76
    },
    {
      age: 105,
      deaths: 45
    },
    {
      age: 106,
      deaths: 26
    },
    {
      age: 107,
      deaths: 14
    },
    {
      age: 108,
      deaths: 8
    },
    {
      age: 109,
      deaths: 4
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2004: [
    {
      age: 0,
      deaths: 693
    },
    {
      age: 1,
      deaths: 49
    },
    {
      age: 2,
      deaths: 30
    },
    {
      age: 3,
      deaths: 22
    },
    {
      age: 4,
      deaths: 19
    },
    {
      age: 5,
      deaths: 17
    },
    {
      age: 6,
      deaths: 15
    },
    {
      age: 7,
      deaths: 14
    },
    {
      age: 8,
      deaths: 13
    },
    {
      age: 9,
      deaths: 14
    },
    {
      age: 10,
      deaths: 14
    },
    {
      age: 11,
      deaths: 14
    },
    {
      age: 12,
      deaths: 17
    },
    {
      age: 13,
      deaths: 20
    },
    {
      age: 14,
      deaths: 27
    },
    {
      age: 15,
      deaths: 35
    },
    {
      age: 16,
      deaths: 52
    },
    {
      age: 17,
      deaths: 64
    },
    {
      age: 18,
      deaths: 82
    },
    {
      age: 19,
      deaths: 88
    },
    {
      age: 20,
      deaths: 89
    },
    {
      age: 21,
      deaths: 97
    },
    {
      age: 22,
      deaths: 95
    },
    {
      age: 23,
      deaths: 92
    },
    {
      age: 24,
      deaths: 94
    },
    {
      age: 25,
      deaths: 94
    },
    {
      age: 26,
      deaths: 97
    },
    {
      age: 27,
      deaths: 98
    },
    {
      age: 28,
      deaths: 93
    },
    {
      age: 29,
      deaths: 100
    },
    {
      age: 30,
      deaths: 92
    },
    {
      age: 31,
      deaths: 102
    },
    {
      age: 32,
      deaths: 109
    },
    {
      age: 33,
      deaths: 111
    },
    {
      age: 34,
      deaths: 119
    },
    {
      age: 35,
      deaths: 128
    },
    {
      age: 36,
      deaths: 134
    },
    {
      age: 37,
      deaths: 148
    },
    {
      age: 38,
      deaths: 157
    },
    {
      age: 39,
      deaths: 170
    },
    {
      age: 40,
      deaths: 189
    },
    {
      age: 41,
      deaths: 205
    },
    {
      age: 42,
      deaths: 226
    },
    {
      age: 43,
      deaths: 242
    },
    {
      age: 44,
      deaths: 258
    },
    {
      age: 45,
      deaths: 286
    },
    {
      age: 46,
      deaths: 313
    },
    {
      age: 47,
      deaths: 337
    },
    {
      age: 48,
      deaths: 356
    },
    {
      age: 49,
      deaths: 381
    },
    {
      age: 50,
      deaths: 416
    },
    {
      age: 51,
      deaths: 444
    },
    {
      age: 52,
      deaths: 477
    },
    {
      age: 53,
      deaths: 499
    },
    {
      age: 54,
      deaths: 530
    },
    {
      age: 55,
      deaths: 569
    },
    {
      age: 56,
      deaths: 601
    },
    {
      age: 57,
      deaths: 691
    },
    {
      age: 58,
      deaths: 674
    },
    {
      age: 59,
      deaths: 765
    },
    {
      age: 60,
      deaths: 834
    },
    {
      age: 61,
      deaths: 931
    },
    {
      age: 62,
      deaths: 966
    },
    {
      age: 63,
      deaths: 1038
    },
    {
      age: 64,
      deaths: 1117
    },
    {
      age: 65,
      deaths: 1193
    },
    {
      age: 66,
      deaths: 1287
    },
    {
      age: 67,
      deaths: 1362
    },
    {
      age: 68,
      deaths: 1450
    },
    {
      age: 69,
      deaths: 1556
    },
    {
      age: 70,
      deaths: 1664
    },
    {
      age: 71,
      deaths: 1776
    },
    {
      age: 72,
      deaths: 1900
    },
    {
      age: 73,
      deaths: 1991
    },
    {
      age: 74,
      deaths: 2140
    },
    {
      age: 75,
      deaths: 2259
    },
    {
      age: 76,
      deaths: 2404
    },
    {
      age: 77,
      deaths: 2535
    },
    {
      age: 78,
      deaths: 2654
    },
    {
      age: 79,
      deaths: 2780
    },
    {
      age: 80,
      deaths: 3004
    },
    {
      age: 81,
      deaths: 3133
    },
    {
      age: 82,
      deaths: 3282
    },
    {
      age: 83,
      deaths: 3348
    },
    {
      age: 84,
      deaths: 3540
    },
    {
      age: 85,
      deaths: 3467
    },
    {
      age: 86,
      deaths: 3534
    },
    {
      age: 87,
      deaths: 3515
    },
    {
      age: 88,
      deaths: 3450
    },
    {
      age: 89,
      deaths: 3326
    },
    {
      age: 90,
      deaths: 3142
    },
    {
      age: 91,
      deaths: 2925
    },
    {
      age: 92,
      deaths: 2654
    },
    {
      age: 93,
      deaths: 2403
    },
    {
      age: 94,
      deaths: 2102
    },
    {
      age: 95,
      deaths: 1742
    },
    {
      age: 96,
      deaths: 1438
    },
    {
      age: 97,
      deaths: 1153
    },
    {
      age: 98,
      deaths: 896
    },
    {
      age: 99,
      deaths: 675
    },
    {
      age: 100,
      deaths: 491
    },
    {
      age: 101,
      deaths: 346
    },
    {
      age: 102,
      deaths: 234
    },
    {
      age: 103,
      deaths: 153
    },
    {
      age: 104,
      deaths: 97
    },
    {
      age: 105,
      deaths: 59
    },
    {
      age: 106,
      deaths: 34
    },
    {
      age: 107,
      deaths: 19
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 5
    }
  ],
  2005: [
    {
      age: 0,
      deaths: 704
    },
    {
      age: 1,
      deaths: 48
    },
    {
      age: 2,
      deaths: 31
    },
    {
      age: 3,
      deaths: 22
    },
    {
      age: 4,
      deaths: 18
    },
    {
      age: 5,
      deaths: 15
    },
    {
      age: 6,
      deaths: 16
    },
    {
      age: 7,
      deaths: 14
    },
    {
      age: 8,
      deaths: 14
    },
    {
      age: 9,
      deaths: 14
    },
    {
      age: 10,
      deaths: 12
    },
    {
      age: 11,
      deaths: 14
    },
    {
      age: 12,
      deaths: 15
    },
    {
      age: 13,
      deaths: 20
    },
    {
      age: 14,
      deaths: 26
    },
    {
      age: 15,
      deaths: 34
    },
    {
      age: 16,
      deaths: 48
    },
    {
      age: 17,
      deaths: 64
    },
    {
      age: 18,
      deaths: 80
    },
    {
      age: 19,
      deaths: 90
    },
    {
      age: 20,
      deaths: 90
    },
    {
      age: 21,
      deaths: 98
    },
    {
      age: 22,
      deaths: 99
    },
    {
      age: 23,
      deaths: 98
    },
    {
      age: 24,
      deaths: 97
    },
    {
      age: 25,
      deaths: 97
    },
    {
      age: 26,
      deaths: 99
    },
    {
      age: 27,
      deaths: 98
    },
    {
      age: 28,
      deaths: 100
    },
    {
      age: 29,
      deaths: 96
    },
    {
      age: 30,
      deaths: 101
    },
    {
      age: 31,
      deaths: 105
    },
    {
      age: 32,
      deaths: 112
    },
    {
      age: 33,
      deaths: 113
    },
    {
      age: 34,
      deaths: 120
    },
    {
      age: 35,
      deaths: 124
    },
    {
      age: 36,
      deaths: 131
    },
    {
      age: 37,
      deaths: 145
    },
    {
      age: 38,
      deaths: 157
    },
    {
      age: 39,
      deaths: 172
    },
    {
      age: 40,
      deaths: 188
    },
    {
      age: 41,
      deaths: 203
    },
    {
      age: 42,
      deaths: 227
    },
    {
      age: 43,
      deaths: 245
    },
    {
      age: 44,
      deaths: 265
    },
    {
      age: 45,
      deaths: 286
    },
    {
      age: 46,
      deaths: 307
    },
    {
      age: 47,
      deaths: 331
    },
    {
      age: 48,
      deaths: 362
    },
    {
      age: 49,
      deaths: 392
    },
    {
      age: 50,
      deaths: 423
    },
    {
      age: 51,
      deaths: 447
    },
    {
      age: 52,
      deaths: 491
    },
    {
      age: 53,
      deaths: 513
    },
    {
      age: 54,
      deaths: 534
    },
    {
      age: 55,
      deaths: 574
    },
    {
      age: 56,
      deaths: 602
    },
    {
      age: 57,
      deaths: 649
    },
    {
      age: 58,
      deaths: 735
    },
    {
      age: 59,
      deaths: 724
    },
    {
      age: 60,
      deaths: 829
    },
    {
      age: 61,
      deaths: 876
    },
    {
      age: 62,
      deaths: 1004
    },
    {
      age: 63,
      deaths: 1032
    },
    {
      age: 64,
      deaths: 1108
    },
    {
      age: 65,
      deaths: 1167
    },
    {
      age: 66,
      deaths: 1249
    },
    {
      age: 67,
      deaths: 1353
    },
    {
      age: 68,
      deaths: 1423
    },
    {
      age: 69,
      deaths: 1521
    },
    {
      age: 70,
      deaths: 1669
    },
    {
      age: 71,
      deaths: 1749
    },
    {
      age: 72,
      deaths: 1886
    },
    {
      age: 73,
      deaths: 2025
    },
    {
      age: 74,
      deaths: 2110
    },
    {
      age: 75,
      deaths: 2246
    },
    {
      age: 76,
      deaths: 2336
    },
    {
      age: 77,
      deaths: 2542
    },
    {
      age: 78,
      deaths: 2674
    },
    {
      age: 79,
      deaths: 2780
    },
    {
      age: 80,
      deaths: 3019
    },
    {
      age: 81,
      deaths: 3139
    },
    {
      age: 82,
      deaths: 3256
    },
    {
      age: 83,
      deaths: 3370
    },
    {
      age: 84,
      deaths: 3438
    },
    {
      age: 85,
      deaths: 3614
    },
    {
      age: 86,
      deaths: 3524
    },
    {
      age: 87,
      deaths: 3547
    },
    {
      age: 88,
      deaths: 3496
    },
    {
      age: 89,
      deaths: 3375
    },
    {
      age: 90,
      deaths: 3216
    },
    {
      age: 91,
      deaths: 2975
    },
    {
      age: 92,
      deaths: 2716
    },
    {
      age: 93,
      deaths: 2403
    },
    {
      age: 94,
      deaths: 2082
    },
    {
      age: 95,
      deaths: 1743
    },
    {
      age: 96,
      deaths: 1430
    },
    {
      age: 97,
      deaths: 1138
    },
    {
      age: 98,
      deaths: 877
    },
    {
      age: 99,
      deaths: 654
    },
    {
      age: 100,
      deaths: 471
    },
    {
      age: 101,
      deaths: 327
    },
    {
      age: 102,
      deaths: 219
    },
    {
      age: 103,
      deaths: 141
    },
    {
      age: 104,
      deaths: 87
    },
    {
      age: 105,
      deaths: 52
    },
    {
      age: 106,
      deaths: 30
    },
    {
      age: 107,
      deaths: 17
    },
    {
      age: 108,
      deaths: 9
    },
    {
      age: 109,
      deaths: 5
    },
    {
      age: 110,
      deaths: 4
    }
  ],
  2006: [
    {
      age: 0,
      deaths: 698
    },
    {
      age: 1,
      deaths: 46
    },
    {
      age: 2,
      deaths: 30
    },
    {
      age: 3,
      deaths: 21
    },
    {
      age: 4,
      deaths: 18
    },
    {
      age: 5,
      deaths: 17
    },
    {
      age: 6,
      deaths: 14
    },
    {
      age: 7,
      deaths: 13
    },
    {
      age: 8,
      deaths: 13
    },
    {
      age: 9,
      deaths: 12
    },
    {
      age: 10,
      deaths: 12
    },
    {
      age: 11,
      deaths: 14
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 18
    },
    {
      age: 14,
      deaths: 23
    },
    {
      age: 15,
      deaths: 32
    },
    {
      age: 16,
      deaths: 48
    },
    {
      age: 17,
      deaths: 62
    },
    {
      age: 18,
      deaths: 81
    },
    {
      age: 19,
      deaths: 90
    },
    {
      age: 20,
      deaths: 93
    },
    {
      age: 21,
      deaths: 102
    },
    {
      age: 22,
      deaths: 98
    },
    {
      age: 23,
      deaths: 101
    },
    {
      age: 24,
      deaths: 102
    },
    {
      age: 25,
      deaths: 101
    },
    {
      age: 26,
      deaths: 100
    },
    {
      age: 27,
      deaths: 104
    },
    {
      age: 28,
      deaths: 100
    },
    {
      age: 29,
      deaths: 105
    },
    {
      age: 30,
      deaths: 102
    },
    {
      age: 31,
      deaths: 110
    },
    {
      age: 32,
      deaths: 108
    },
    {
      age: 33,
      deaths: 114
    },
    {
      age: 34,
      deaths: 121
    },
    {
      age: 35,
      deaths: 131
    },
    {
      age: 36,
      deaths: 133
    },
    {
      age: 37,
      deaths: 140
    },
    {
      age: 38,
      deaths: 156
    },
    {
      age: 39,
      deaths: 171
    },
    {
      age: 40,
      deaths: 184
    },
    {
      age: 41,
      deaths: 199
    },
    {
      age: 42,
      deaths: 222
    },
    {
      age: 43,
      deaths: 242
    },
    {
      age: 44,
      deaths: 259
    },
    {
      age: 45,
      deaths: 281
    },
    {
      age: 46,
      deaths: 299
    },
    {
      age: 47,
      deaths: 328
    },
    {
      age: 48,
      deaths: 362
    },
    {
      age: 49,
      deaths: 386
    },
    {
      age: 50,
      deaths: 415
    },
    {
      age: 51,
      deaths: 442
    },
    {
      age: 52,
      deaths: 478
    },
    {
      age: 53,
      deaths: 508
    },
    {
      age: 54,
      deaths: 542
    },
    {
      age: 55,
      deaths: 572
    },
    {
      age: 56,
      deaths: 595
    },
    {
      age: 57,
      deaths: 645
    },
    {
      age: 58,
      deaths: 697
    },
    {
      age: 59,
      deaths: 783
    },
    {
      age: 60,
      deaths: 771
    },
    {
      age: 61,
      deaths: 852
    },
    {
      age: 62,
      deaths: 934
    },
    {
      age: 63,
      deaths: 1041
    },
    {
      age: 64,
      deaths: 1065
    },
    {
      age: 65,
      deaths: 1139
    },
    {
      age: 66,
      deaths: 1213
    },
    {
      age: 67,
      deaths: 1308
    },
    {
      age: 68,
      deaths: 1395
    },
    {
      age: 69,
      deaths: 1488
    },
    {
      age: 70,
      deaths: 1606
    },
    {
      age: 71,
      deaths: 1712
    },
    {
      age: 72,
      deaths: 1796
    },
    {
      age: 73,
      deaths: 1957
    },
    {
      age: 74,
      deaths: 2082
    },
    {
      age: 75,
      deaths: 2199
    },
    {
      age: 76,
      deaths: 2343
    },
    {
      age: 77,
      deaths: 2451
    },
    {
      age: 78,
      deaths: 2612
    },
    {
      age: 79,
      deaths: 2742
    },
    {
      age: 80,
      deaths: 2955
    },
    {
      age: 81,
      deaths: 3102
    },
    {
      age: 82,
      deaths: 3239
    },
    {
      age: 83,
      deaths: 3314
    },
    {
      age: 84,
      deaths: 3428
    },
    {
      age: 85,
      deaths: 3471
    },
    {
      age: 86,
      deaths: 3632
    },
    {
      age: 87,
      deaths: 3510
    },
    {
      age: 88,
      deaths: 3525
    },
    {
      age: 89,
      deaths: 3413
    },
    {
      age: 90,
      deaths: 3273
    },
    {
      age: 91,
      deaths: 3070
    },
    {
      age: 92,
      deaths: 2820
    },
    {
      age: 93,
      deaths: 2509
    },
    {
      age: 94,
      deaths: 2182
    },
    {
      age: 95,
      deaths: 1844
    },
    {
      age: 96,
      deaths: 1528
    },
    {
      age: 97,
      deaths: 1229
    },
    {
      age: 98,
      deaths: 958
    },
    {
      age: 99,
      deaths: 723
    },
    {
      age: 100,
      deaths: 527
    },
    {
      age: 101,
      deaths: 371
    },
    {
      age: 102,
      deaths: 251
    },
    {
      age: 103,
      deaths: 164
    },
    {
      age: 104,
      deaths: 103
    },
    {
      age: 105,
      deaths: 63
    },
    {
      age: 106,
      deaths: 36
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  2007: [
    {
      age: 0,
      deaths: 703
    },
    {
      age: 1,
      deaths: 48
    },
    {
      age: 2,
      deaths: 29
    },
    {
      age: 3,
      deaths: 22
    },
    {
      age: 4,
      deaths: 17
    },
    {
      age: 5,
      deaths: 15
    },
    {
      age: 6,
      deaths: 14
    },
    {
      age: 7,
      deaths: 13
    },
    {
      age: 8,
      deaths: 13
    },
    {
      age: 9,
      deaths: 13
    },
    {
      age: 10,
      deaths: 13
    },
    {
      age: 11,
      deaths: 13
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 18
    },
    {
      age: 14,
      deaths: 23
    },
    {
      age: 15,
      deaths: 31
    },
    {
      age: 16,
      deaths: 45
    },
    {
      age: 17,
      deaths: 58
    },
    {
      age: 18,
      deaths: 79
    },
    {
      age: 19,
      deaths: 87
    },
    {
      age: 20,
      deaths: 88
    },
    {
      age: 21,
      deaths: 97
    },
    {
      age: 22,
      deaths: 101
    },
    {
      age: 23,
      deaths: 97
    },
    {
      age: 24,
      deaths: 101
    },
    {
      age: 25,
      deaths: 95
    },
    {
      age: 26,
      deaths: 99
    },
    {
      age: 27,
      deaths: 99
    },
    {
      age: 28,
      deaths: 102
    },
    {
      age: 29,
      deaths: 105
    },
    {
      age: 30,
      deaths: 104
    },
    {
      age: 31,
      deaths: 106
    },
    {
      age: 32,
      deaths: 108
    },
    {
      age: 33,
      deaths: 111
    },
    {
      age: 34,
      deaths: 120
    },
    {
      age: 35,
      deaths: 126
    },
    {
      age: 36,
      deaths: 133
    },
    {
      age: 37,
      deaths: 141
    },
    {
      age: 38,
      deaths: 153
    },
    {
      age: 39,
      deaths: 162
    },
    {
      age: 40,
      deaths: 179
    },
    {
      age: 41,
      deaths: 193
    },
    {
      age: 42,
      deaths: 207
    },
    {
      age: 43,
      deaths: 231
    },
    {
      age: 44,
      deaths: 258
    },
    {
      age: 45,
      deaths: 276
    },
    {
      age: 46,
      deaths: 298
    },
    {
      age: 47,
      deaths: 321
    },
    {
      age: 48,
      deaths: 348
    },
    {
      age: 49,
      deaths: 374
    },
    {
      age: 50,
      deaths: 413
    },
    {
      age: 51,
      deaths: 443
    },
    {
      age: 52,
      deaths: 468
    },
    {
      age: 53,
      deaths: 500
    },
    {
      age: 54,
      deaths: 531
    },
    {
      age: 55,
      deaths: 577
    },
    {
      age: 56,
      deaths: 597
    },
    {
      age: 57,
      deaths: 641
    },
    {
      age: 58,
      deaths: 678
    },
    {
      age: 59,
      deaths: 728
    },
    {
      age: 60,
      deaths: 831
    },
    {
      age: 61,
      deaths: 792
    },
    {
      age: 62,
      deaths: 904
    },
    {
      age: 63,
      deaths: 983
    },
    {
      age: 64,
      deaths: 1098
    },
    {
      age: 65,
      deaths: 1130
    },
    {
      age: 66,
      deaths: 1199
    },
    {
      age: 67,
      deaths: 1270
    },
    {
      age: 68,
      deaths: 1356
    },
    {
      age: 69,
      deaths: 1481
    },
    {
      age: 70,
      deaths: 1560
    },
    {
      age: 71,
      deaths: 1657
    },
    {
      age: 72,
      deaths: 1810
    },
    {
      age: 73,
      deaths: 1889
    },
    {
      age: 74,
      deaths: 2043
    },
    {
      age: 75,
      deaths: 2203
    },
    {
      age: 76,
      deaths: 2285
    },
    {
      age: 77,
      deaths: 2435
    },
    {
      age: 78,
      deaths: 2540
    },
    {
      age: 79,
      deaths: 2718
    },
    {
      age: 80,
      deaths: 2900
    },
    {
      age: 81,
      deaths: 3062
    },
    {
      age: 82,
      deaths: 3205
    },
    {
      age: 83,
      deaths: 3315
    },
    {
      age: 84,
      deaths: 3421
    },
    {
      age: 85,
      deaths: 3521
    },
    {
      age: 86,
      deaths: 3548
    },
    {
      age: 87,
      deaths: 3633
    },
    {
      age: 88,
      deaths: 3500
    },
    {
      age: 89,
      deaths: 3440
    },
    {
      age: 90,
      deaths: 3319
    },
    {
      age: 91,
      deaths: 3136
    },
    {
      age: 92,
      deaths: 2876
    },
    {
      age: 93,
      deaths: 2584
    },
    {
      age: 94,
      deaths: 2276
    },
    {
      age: 95,
      deaths: 1921
    },
    {
      age: 96,
      deaths: 1602
    },
    {
      age: 97,
      deaths: 1296
    },
    {
      age: 98,
      deaths: 1017
    },
    {
      age: 99,
      deaths: 772
    },
    {
      age: 100,
      deaths: 567
    },
    {
      age: 101,
      deaths: 401
    },
    {
      age: 102,
      deaths: 274
    },
    {
      age: 103,
      deaths: 180
    },
    {
      age: 104,
      deaths: 114
    },
    {
      age: 105,
      deaths: 70
    },
    {
      age: 106,
      deaths: 41
    },
    {
      age: 107,
      deaths: 23
    },
    {
      age: 108,
      deaths: 13
    },
    {
      age: 109,
      deaths: 7
    },
    {
      age: 110,
      deaths: 6
    }
  ],
  2008: [
    {
      age: 0,
      deaths: 679
    },
    {
      age: 1,
      deaths: 48
    },
    {
      age: 2,
      deaths: 29
    },
    {
      age: 3,
      deaths: 22
    },
    {
      age: 4,
      deaths: 17
    },
    {
      age: 5,
      deaths: 14
    },
    {
      age: 6,
      deaths: 12
    },
    {
      age: 7,
      deaths: 12
    },
    {
      age: 8,
      deaths: 12
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 12
    },
    {
      age: 11,
      deaths: 12
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 21
    },
    {
      age: 15,
      deaths: 30
    },
    {
      age: 16,
      deaths: 41
    },
    {
      age: 17,
      deaths: 53
    },
    {
      age: 18,
      deaths: 72
    },
    {
      age: 19,
      deaths: 80
    },
    {
      age: 20,
      deaths: 87
    },
    {
      age: 21,
      deaths: 91
    },
    {
      age: 22,
      deaths: 95
    },
    {
      age: 23,
      deaths: 94
    },
    {
      age: 24,
      deaths: 93
    },
    {
      age: 25,
      deaths: 96
    },
    {
      age: 26,
      deaths: 94
    },
    {
      age: 27,
      deaths: 96
    },
    {
      age: 28,
      deaths: 100
    },
    {
      age: 29,
      deaths: 103
    },
    {
      age: 30,
      deaths: 104
    },
    {
      age: 31,
      deaths: 107
    },
    {
      age: 32,
      deaths: 106
    },
    {
      age: 33,
      deaths: 111
    },
    {
      age: 34,
      deaths: 114
    },
    {
      age: 35,
      deaths: 122
    },
    {
      age: 36,
      deaths: 127
    },
    {
      age: 37,
      deaths: 142
    },
    {
      age: 38,
      deaths: 148
    },
    {
      age: 39,
      deaths: 155
    },
    {
      age: 40,
      deaths: 171
    },
    {
      age: 41,
      deaths: 190
    },
    {
      age: 42,
      deaths: 204
    },
    {
      age: 43,
      deaths: 223
    },
    {
      age: 44,
      deaths: 251
    },
    {
      age: 45,
      deaths: 270
    },
    {
      age: 46,
      deaths: 296
    },
    {
      age: 47,
      deaths: 321
    },
    {
      age: 48,
      deaths: 343
    },
    {
      age: 49,
      deaths: 377
    },
    {
      age: 50,
      deaths: 412
    },
    {
      age: 51,
      deaths: 436
    },
    {
      age: 52,
      deaths: 470
    },
    {
      age: 53,
      deaths: 497
    },
    {
      age: 54,
      deaths: 534
    },
    {
      age: 55,
      deaths: 580
    },
    {
      age: 56,
      deaths: 610
    },
    {
      age: 57,
      deaths: 636
    },
    {
      age: 58,
      deaths: 677
    },
    {
      age: 59,
      deaths: 724
    },
    {
      age: 60,
      deaths: 799
    },
    {
      age: 61,
      deaths: 883
    },
    {
      age: 62,
      deaths: 854
    },
    {
      age: 63,
      deaths: 971
    },
    {
      age: 64,
      deaths: 1065
    },
    {
      age: 65,
      deaths: 1164
    },
    {
      age: 66,
      deaths: 1193
    },
    {
      age: 67,
      deaths: 1272
    },
    {
      age: 68,
      deaths: 1353
    },
    {
      age: 69,
      deaths: 1471
    },
    {
      age: 70,
      deaths: 1581
    },
    {
      age: 71,
      deaths: 1643
    },
    {
      age: 72,
      deaths: 1800
    },
    {
      age: 73,
      deaths: 1926
    },
    {
      age: 74,
      deaths: 2017
    },
    {
      age: 75,
      deaths: 2201
    },
    {
      age: 76,
      deaths: 2295
    },
    {
      age: 77,
      deaths: 2435
    },
    {
      age: 78,
      deaths: 2584
    },
    {
      age: 79,
      deaths: 2696
    },
    {
      age: 80,
      deaths: 2878
    },
    {
      age: 81,
      deaths: 3037
    },
    {
      age: 82,
      deaths: 3178
    },
    {
      age: 83,
      deaths: 3338
    },
    {
      age: 84,
      deaths: 3437
    },
    {
      age: 85,
      deaths: 3519
    },
    {
      age: 86,
      deaths: 3596
    },
    {
      age: 87,
      deaths: 3563
    },
    {
      age: 88,
      deaths: 3657
    },
    {
      age: 89,
      deaths: 3450
    },
    {
      age: 90,
      deaths: 3360
    },
    {
      age: 91,
      deaths: 3154
    },
    {
      age: 92,
      deaths: 2913
    },
    {
      age: 93,
      deaths: 2611
    },
    {
      age: 94,
      deaths: 2305
    },
    {
      age: 95,
      deaths: 1926
    },
    {
      age: 96,
      deaths: 1598
    },
    {
      age: 97,
      deaths: 1287
    },
    {
      age: 98,
      deaths: 1003
    },
    {
      age: 99,
      deaths: 756
    },
    {
      age: 100,
      deaths: 550
    },
    {
      age: 101,
      deaths: 385
    },
    {
      age: 102,
      deaths: 260
    },
    {
      age: 103,
      deaths: 169
    },
    {
      age: 104,
      deaths: 106
    },
    {
      age: 105,
      deaths: 63
    },
    {
      age: 106,
      deaths: 37
    },
    {
      age: 107,
      deaths: 20
    },
    {
      age: 108,
      deaths: 11
    },
    {
      age: 109,
      deaths: 6
    },
    {
      age: 110,
      deaths: 5
    }
  ],
  2009: [
    {
      age: 0,
      deaths: 652
    },
    {
      age: 1,
      deaths: 43
    },
    {
      age: 2,
      deaths: 28
    },
    {
      age: 3,
      deaths: 22
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 14
    },
    {
      age: 6,
      deaths: 13
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 13
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 14
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 20
    },
    {
      age: 15,
      deaths: 27
    },
    {
      age: 16,
      deaths: 39
    },
    {
      age: 17,
      deaths: 50
    },
    {
      age: 18,
      deaths: 67
    },
    {
      age: 19,
      deaths: 72
    },
    {
      age: 20,
      deaths: 79
    },
    {
      age: 21,
      deaths: 89
    },
    {
      age: 22,
      deaths: 85
    },
    {
      age: 23,
      deaths: 92
    },
    {
      age: 24,
      deaths: 91
    },
    {
      age: 25,
      deaths: 90
    },
    {
      age: 26,
      deaths: 96
    },
    {
      age: 27,
      deaths: 93
    },
    {
      age: 28,
      deaths: 98
    },
    {
      age: 29,
      deaths: 104
    },
    {
      age: 30,
      deaths: 103
    },
    {
      age: 31,
      deaths: 106
    },
    {
      age: 32,
      deaths: 108
    },
    {
      age: 33,
      deaths: 112
    },
    {
      age: 34,
      deaths: 118
    },
    {
      age: 35,
      deaths: 123
    },
    {
      age: 36,
      deaths: 130
    },
    {
      age: 37,
      deaths: 138
    },
    {
      age: 38,
      deaths: 148
    },
    {
      age: 39,
      deaths: 156
    },
    {
      age: 40,
      deaths: 173
    },
    {
      age: 41,
      deaths: 189
    },
    {
      age: 42,
      deaths: 204
    },
    {
      age: 43,
      deaths: 218
    },
    {
      age: 44,
      deaths: 244
    },
    {
      age: 45,
      deaths: 266
    },
    {
      age: 46,
      deaths: 293
    },
    {
      age: 47,
      deaths: 319
    },
    {
      age: 48,
      deaths: 343
    },
    {
      age: 49,
      deaths: 365
    },
    {
      age: 50,
      deaths: 404
    },
    {
      age: 51,
      deaths: 435
    },
    {
      age: 52,
      deaths: 480
    },
    {
      age: 53,
      deaths: 508
    },
    {
      age: 54,
      deaths: 530
    },
    {
      age: 55,
      deaths: 574
    },
    {
      age: 56,
      deaths: 611
    },
    {
      age: 57,
      deaths: 653
    },
    {
      age: 58,
      deaths: 678
    },
    {
      age: 59,
      deaths: 722
    },
    {
      age: 60,
      deaths: 791
    },
    {
      age: 61,
      deaths: 818
    },
    {
      age: 62,
      deaths: 924
    },
    {
      age: 63,
      deaths: 943
    },
    {
      age: 64,
      deaths: 1024
    },
    {
      age: 65,
      deaths: 1101
    },
    {
      age: 66,
      deaths: 1207
    },
    {
      age: 67,
      deaths: 1253
    },
    {
      age: 68,
      deaths: 1336
    },
    {
      age: 69,
      deaths: 1404
    },
    {
      age: 70,
      deaths: 1532
    },
    {
      age: 71,
      deaths: 1626
    },
    {
      age: 72,
      deaths: 1726
    },
    {
      age: 73,
      deaths: 1846
    },
    {
      age: 74,
      deaths: 1995
    },
    {
      age: 75,
      deaths: 2118
    },
    {
      age: 76,
      deaths: 2231
    },
    {
      age: 77,
      deaths: 2379
    },
    {
      age: 78,
      deaths: 2530
    },
    {
      age: 79,
      deaths: 2619
    },
    {
      age: 80,
      deaths: 2791
    },
    {
      age: 81,
      deaths: 2947
    },
    {
      age: 82,
      deaths: 3134
    },
    {
      age: 83,
      deaths: 3249
    },
    {
      age: 84,
      deaths: 3387
    },
    {
      age: 85,
      deaths: 3480
    },
    {
      age: 86,
      deaths: 3563
    },
    {
      age: 87,
      deaths: 3587
    },
    {
      age: 88,
      deaths: 3536
    },
    {
      age: 89,
      deaths: 3558
    },
    {
      age: 90,
      deaths: 3347
    },
    {
      age: 91,
      deaths: 3191
    },
    {
      age: 92,
      deaths: 3000
    },
    {
      age: 93,
      deaths: 2744
    },
    {
      age: 94,
      deaths: 2413
    },
    {
      age: 95,
      deaths: 2045
    },
    {
      age: 96,
      deaths: 1725
    },
    {
      age: 97,
      deaths: 1414
    },
    {
      age: 98,
      deaths: 1124
    },
    {
      age: 99,
      deaths: 865
    },
    {
      age: 100,
      deaths: 644
    },
    {
      age: 101,
      deaths: 463
    },
    {
      age: 102,
      deaths: 321
    },
    {
      age: 103,
      deaths: 214
    },
    {
      age: 104,
      deaths: 138
    },
    {
      age: 105,
      deaths: 86
    },
    {
      age: 106,
      deaths: 51
    },
    {
      age: 107,
      deaths: 29
    },
    {
      age: 108,
      deaths: 16
    },
    {
      age: 109,
      deaths: 9
    },
    {
      age: 110,
      deaths: 9
    }
  ],
  2010: [
    {
      age: 0,
      deaths: 616
    },
    {
      age: 1,
      deaths: 43
    },
    {
      age: 2,
      deaths: 27
    },
    {
      age: 3,
      deaths: 21
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 12
    },
    {
      age: 6,
      deaths: 11
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 10
    },
    {
      age: 11,
      deaths: 13
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 15
    },
    {
      age: 14,
      deaths: 20
    },
    {
      age: 15,
      deaths: 25
    },
    {
      age: 16,
      deaths: 37
    },
    {
      age: 17,
      deaths: 46
    },
    {
      age: 18,
      deaths: 64
    },
    {
      age: 19,
      deaths: 70
    },
    {
      age: 20,
      deaths: 76
    },
    {
      age: 21,
      deaths: 86
    },
    {
      age: 22,
      deaths: 85
    },
    {
      age: 23,
      deaths: 87
    },
    {
      age: 24,
      deaths: 93
    },
    {
      age: 25,
      deaths: 91
    },
    {
      age: 26,
      deaths: 93
    },
    {
      age: 27,
      deaths: 91
    },
    {
      age: 28,
      deaths: 97
    },
    {
      age: 29,
      deaths: 99
    },
    {
      age: 30,
      deaths: 103
    },
    {
      age: 31,
      deaths: 106
    },
    {
      age: 32,
      deaths: 108
    },
    {
      age: 33,
      deaths: 111
    },
    {
      age: 34,
      deaths: 111
    },
    {
      age: 35,
      deaths: 119
    },
    {
      age: 36,
      deaths: 126
    },
    {
      age: 37,
      deaths: 133
    },
    {
      age: 38,
      deaths: 141
    },
    {
      age: 39,
      deaths: 153
    },
    {
      age: 40,
      deaths: 160
    },
    {
      age: 41,
      deaths: 177
    },
    {
      age: 42,
      deaths: 192
    },
    {
      age: 43,
      deaths: 207
    },
    {
      age: 44,
      deaths: 230
    },
    {
      age: 45,
      deaths: 251
    },
    {
      age: 46,
      deaths: 286
    },
    {
      age: 47,
      deaths: 309
    },
    {
      age: 48,
      deaths: 332
    },
    {
      age: 49,
      deaths: 364
    },
    {
      age: 50,
      deaths: 396
    },
    {
      age: 51,
      deaths: 421
    },
    {
      age: 52,
      deaths: 464
    },
    {
      age: 53,
      deaths: 497
    },
    {
      age: 54,
      deaths: 523
    },
    {
      age: 55,
      deaths: 560
    },
    {
      age: 56,
      deaths: 607
    },
    {
      age: 57,
      deaths: 645
    },
    {
      age: 58,
      deaths: 692
    },
    {
      age: 59,
      deaths: 728
    },
    {
      age: 60,
      deaths: 768
    },
    {
      age: 61,
      deaths: 811
    },
    {
      age: 62,
      deaths: 861
    },
    {
      age: 63,
      deaths: 1018
    },
    {
      age: 64,
      deaths: 984
    },
    {
      age: 65,
      deaths: 1079
    },
    {
      age: 66,
      deaths: 1149
    },
    {
      age: 67,
      deaths: 1281
    },
    {
      age: 68,
      deaths: 1338
    },
    {
      age: 69,
      deaths: 1421
    },
    {
      age: 70,
      deaths: 1525
    },
    {
      age: 71,
      deaths: 1607
    },
    {
      age: 72,
      deaths: 1752
    },
    {
      age: 73,
      deaths: 1829
    },
    {
      age: 74,
      deaths: 1960
    },
    {
      age: 75,
      deaths: 2149
    },
    {
      age: 76,
      deaths: 2222
    },
    {
      age: 77,
      deaths: 2378
    },
    {
      age: 78,
      deaths: 2524
    },
    {
      age: 79,
      deaths: 2608
    },
    {
      age: 80,
      deaths: 2791
    },
    {
      age: 81,
      deaths: 2937
    },
    {
      age: 82,
      deaths: 3114
    },
    {
      age: 83,
      deaths: 3233
    },
    {
      age: 84,
      deaths: 3404
    },
    {
      age: 85,
      deaths: 3535
    },
    {
      age: 86,
      deaths: 3572
    },
    {
      age: 87,
      deaths: 3594
    },
    {
      age: 88,
      deaths: 3611
    },
    {
      age: 89,
      deaths: 3524
    },
    {
      age: 90,
      deaths: 3533
    },
    {
      age: 91,
      deaths: 3202
    },
    {
      age: 92,
      deaths: 3048
    },
    {
      age: 93,
      deaths: 2756
    },
    {
      age: 94,
      deaths: 2453
    },
    {
      age: 95,
      deaths: 2075
    },
    {
      age: 96,
      deaths: 1747
    },
    {
      age: 97,
      deaths: 1427
    },
    {
      age: 98,
      deaths: 1130
    },
    {
      age: 99,
      deaths: 866
    },
    {
      age: 100,
      deaths: 641
    },
    {
      age: 101,
      deaths: 458
    },
    {
      age: 102,
      deaths: 315
    },
    {
      age: 103,
      deaths: 209
    },
    {
      age: 104,
      deaths: 133
    },
    {
      age: 105,
      deaths: 82
    },
    {
      age: 106,
      deaths: 48
    },
    {
      age: 107,
      deaths: 27
    },
    {
      age: 108,
      deaths: 15
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  2011: [
    {
      age: 0,
      deaths: 604
    },
    {
      age: 1,
      deaths: 42
    },
    {
      age: 2,
      deaths: 26
    },
    {
      age: 3,
      deaths: 20
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 14
    },
    {
      age: 6,
      deaths: 12
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 12
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 11
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 15
    },
    {
      age: 14,
      deaths: 20
    },
    {
      age: 15,
      deaths: 24
    },
    {
      age: 16,
      deaths: 36
    },
    {
      age: 17,
      deaths: 46
    },
    {
      age: 18,
      deaths: 62
    },
    {
      age: 19,
      deaths: 72
    },
    {
      age: 20,
      deaths: 74
    },
    {
      age: 21,
      deaths: 84
    },
    {
      age: 22,
      deaths: 89
    },
    {
      age: 23,
      deaths: 91
    },
    {
      age: 24,
      deaths: 89
    },
    {
      age: 25,
      deaths: 93
    },
    {
      age: 26,
      deaths: 95
    },
    {
      age: 27,
      deaths: 92
    },
    {
      age: 28,
      deaths: 96
    },
    {
      age: 29,
      deaths: 100
    },
    {
      age: 30,
      deaths: 102
    },
    {
      age: 31,
      deaths: 109
    },
    {
      age: 32,
      deaths: 111
    },
    {
      age: 33,
      deaths: 114
    },
    {
      age: 34,
      deaths: 119
    },
    {
      age: 35,
      deaths: 119
    },
    {
      age: 36,
      deaths: 128
    },
    {
      age: 37,
      deaths: 132
    },
    {
      age: 38,
      deaths: 143
    },
    {
      age: 39,
      deaths: 153
    },
    {
      age: 40,
      deaths: 168
    },
    {
      age: 41,
      deaths: 176
    },
    {
      age: 42,
      deaths: 194
    },
    {
      age: 43,
      deaths: 211
    },
    {
      age: 44,
      deaths: 226
    },
    {
      age: 45,
      deaths: 249
    },
    {
      age: 46,
      deaths: 277
    },
    {
      age: 47,
      deaths: 306
    },
    {
      age: 48,
      deaths: 332
    },
    {
      age: 49,
      deaths: 365
    },
    {
      age: 50,
      deaths: 397
    },
    {
      age: 51,
      deaths: 426
    },
    {
      age: 52,
      deaths: 463
    },
    {
      age: 53,
      deaths: 496
    },
    {
      age: 54,
      deaths: 534
    },
    {
      age: 55,
      deaths: 568
    },
    {
      age: 56,
      deaths: 610
    },
    {
      age: 57,
      deaths: 648
    },
    {
      age: 58,
      deaths: 682
    },
    {
      age: 59,
      deaths: 732
    },
    {
      age: 60,
      deaths: 770
    },
    {
      age: 61,
      deaths: 808
    },
    {
      age: 62,
      deaths: 859
    },
    {
      age: 63,
      deaths: 938
    },
    {
      age: 64,
      deaths: 1069
    },
    {
      age: 65,
      deaths: 998
    },
    {
      age: 66,
      deaths: 1120
    },
    {
      age: 67,
      deaths: 1217
    },
    {
      age: 68,
      deaths: 1361
    },
    {
      age: 69,
      deaths: 1395
    },
    {
      age: 70,
      deaths: 1511
    },
    {
      age: 71,
      deaths: 1589
    },
    {
      age: 72,
      deaths: 1715
    },
    {
      age: 73,
      deaths: 1844
    },
    {
      age: 74,
      deaths: 1932
    },
    {
      age: 75,
      deaths: 2078
    },
    {
      age: 76,
      deaths: 2260
    },
    {
      age: 77,
      deaths: 2328
    },
    {
      age: 78,
      deaths: 2508
    },
    {
      age: 79,
      deaths: 2633
    },
    {
      age: 80,
      deaths: 2769
    },
    {
      age: 81,
      deaths: 2966
    },
    {
      age: 82,
      deaths: 3101
    },
    {
      age: 83,
      deaths: 3255
    },
    {
      age: 84,
      deaths: 3382
    },
    {
      age: 85,
      deaths: 3501
    },
    {
      age: 86,
      deaths: 3572
    },
    {
      age: 87,
      deaths: 3634
    },
    {
      age: 88,
      deaths: 3611
    },
    {
      age: 89,
      deaths: 3599
    },
    {
      age: 90,
      deaths: 3465
    },
    {
      age: 91,
      deaths: 3375
    },
    {
      age: 92,
      deaths: 3059
    },
    {
      age: 93,
      deaths: 2782
    },
    {
      age: 94,
      deaths: 2462
    },
    {
      age: 95,
      deaths: 2101
    },
    {
      age: 96,
      deaths: 1767
    },
    {
      age: 97,
      deaths: 1442
    },
    {
      age: 98,
      deaths: 1140
    },
    {
      age: 99,
      deaths: 872
    },
    {
      age: 100,
      deaths: 644
    },
    {
      age: 101,
      deaths: 458
    },
    {
      age: 102,
      deaths: 314
    },
    {
      age: 103,
      deaths: 207
    },
    {
      age: 104,
      deaths: 132
    },
    {
      age: 105,
      deaths: 80
    },
    {
      age: 106,
      deaths: 47
    },
    {
      age: 107,
      deaths: 27
    },
    {
      age: 108,
      deaths: 14
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 7
    }
  ],
  2012: [
    {
      age: 0,
      deaths: 596
    },
    {
      age: 1,
      deaths: 41
    },
    {
      age: 2,
      deaths: 27
    },
    {
      age: 3,
      deaths: 20
    },
    {
      age: 4,
      deaths: 17
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 11
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 10
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 11
    },
    {
      age: 12,
      deaths: 12
    },
    {
      age: 13,
      deaths: 15
    },
    {
      age: 14,
      deaths: 20
    },
    {
      age: 15,
      deaths: 26
    },
    {
      age: 16,
      deaths: 34
    },
    {
      age: 17,
      deaths: 43
    },
    {
      age: 18,
      deaths: 61
    },
    {
      age: 19,
      deaths: 67
    },
    {
      age: 20,
      deaths: 74
    },
    {
      age: 21,
      deaths: 81
    },
    {
      age: 22,
      deaths: 88
    },
    {
      age: 23,
      deaths: 85
    },
    {
      age: 24,
      deaths: 90
    },
    {
      age: 25,
      deaths: 91
    },
    {
      age: 26,
      deaths: 94
    },
    {
      age: 27,
      deaths: 94
    },
    {
      age: 28,
      deaths: 99
    },
    {
      age: 29,
      deaths: 104
    },
    {
      age: 30,
      deaths: 105
    },
    {
      age: 31,
      deaths: 108
    },
    {
      age: 32,
      deaths: 107
    },
    {
      age: 33,
      deaths: 115
    },
    {
      age: 34,
      deaths: 118
    },
    {
      age: 35,
      deaths: 124
    },
    {
      age: 36,
      deaths: 129
    },
    {
      age: 37,
      deaths: 135
    },
    {
      age: 38,
      deaths: 140
    },
    {
      age: 39,
      deaths: 152
    },
    {
      age: 40,
      deaths: 165
    },
    {
      age: 41,
      deaths: 179
    },
    {
      age: 42,
      deaths: 189
    },
    {
      age: 43,
      deaths: 204
    },
    {
      age: 44,
      deaths: 223
    },
    {
      age: 45,
      deaths: 243
    },
    {
      age: 46,
      deaths: 269
    },
    {
      age: 47,
      deaths: 298
    },
    {
      age: 48,
      deaths: 329
    },
    {
      age: 49,
      deaths: 355
    },
    {
      age: 50,
      deaths: 394
    },
    {
      age: 51,
      deaths: 429
    },
    {
      age: 52,
      deaths: 457
    },
    {
      age: 53,
      deaths: 487
    },
    {
      age: 54,
      deaths: 528
    },
    {
      age: 55,
      deaths: 577
    },
    {
      age: 56,
      deaths: 611
    },
    {
      age: 57,
      deaths: 646
    },
    {
      age: 58,
      deaths: 691
    },
    {
      age: 59,
      deaths: 735
    },
    {
      age: 60,
      deaths: 784
    },
    {
      age: 61,
      deaths: 824
    },
    {
      age: 62,
      deaths: 869
    },
    {
      age: 63,
      deaths: 929
    },
    {
      age: 64,
      deaths: 980
    },
    {
      age: 65,
      deaths: 1101
    },
    {
      age: 66,
      deaths: 1068
    },
    {
      age: 67,
      deaths: 1199
    },
    {
      age: 68,
      deaths: 1300
    },
    {
      age: 69,
      deaths: 1425
    },
    {
      age: 70,
      deaths: 1472
    },
    {
      age: 71,
      deaths: 1582
    },
    {
      age: 72,
      deaths: 1677
    },
    {
      age: 73,
      deaths: 1781
    },
    {
      age: 74,
      deaths: 1931
    },
    {
      age: 75,
      deaths: 2034
    },
    {
      age: 76,
      deaths: 2190
    },
    {
      age: 77,
      deaths: 2369
    },
    {
      age: 78,
      deaths: 2441
    },
    {
      age: 79,
      deaths: 2656
    },
    {
      age: 80,
      deaths: 2722
    },
    {
      age: 81,
      deaths: 2883
    },
    {
      age: 82,
      deaths: 3083
    },
    {
      age: 83,
      deaths: 3216
    },
    {
      age: 84,
      deaths: 3361
    },
    {
      age: 85,
      deaths: 3480
    },
    {
      age: 86,
      deaths: 3569
    },
    {
      age: 87,
      deaths: 3633
    },
    {
      age: 88,
      deaths: 3663
    },
    {
      age: 89,
      deaths: 3607
    },
    {
      age: 90,
      deaths: 3547
    },
    {
      age: 91,
      deaths: 3311
    },
    {
      age: 92,
      deaths: 3151
    },
    {
      age: 93,
      deaths: 2794
    },
    {
      age: 94,
      deaths: 2562
    },
    {
      age: 95,
      deaths: 2159
    },
    {
      age: 96,
      deaths: 1822
    },
    {
      age: 97,
      deaths: 1493
    },
    {
      age: 98,
      deaths: 1184
    },
    {
      age: 99,
      deaths: 909
    },
    {
      age: 100,
      deaths: 673
    },
    {
      age: 101,
      deaths: 481
    },
    {
      age: 102,
      deaths: 331
    },
    {
      age: 103,
      deaths: 219
    },
    {
      age: 104,
      deaths: 139
    },
    {
      age: 105,
      deaths: 85
    },
    {
      age: 106,
      deaths: 50
    },
    {
      age: 107,
      deaths: 28
    },
    {
      age: 108,
      deaths: 15
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  2013: [
    {
      age: 0,
      deaths: 592
    },
    {
      age: 1,
      deaths: 42
    },
    {
      age: 2,
      deaths: 25
    },
    {
      age: 3,
      deaths: 19
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 14
    },
    {
      age: 6,
      deaths: 12
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 10
    },
    {
      age: 11,
      deaths: 11
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 19
    },
    {
      age: 15,
      deaths: 25
    },
    {
      age: 16,
      deaths: 32
    },
    {
      age: 17,
      deaths: 43
    },
    {
      age: 18,
      deaths: 55
    },
    {
      age: 19,
      deaths: 65
    },
    {
      age: 20,
      deaths: 72
    },
    {
      age: 21,
      deaths: 80
    },
    {
      age: 22,
      deaths: 83
    },
    {
      age: 23,
      deaths: 88
    },
    {
      age: 24,
      deaths: 89
    },
    {
      age: 25,
      deaths: 89
    },
    {
      age: 26,
      deaths: 95
    },
    {
      age: 27,
      deaths: 98
    },
    {
      age: 28,
      deaths: 98
    },
    {
      age: 29,
      deaths: 100
    },
    {
      age: 30,
      deaths: 106
    },
    {
      age: 31,
      deaths: 108
    },
    {
      age: 32,
      deaths: 110
    },
    {
      age: 33,
      deaths: 116
    },
    {
      age: 34,
      deaths: 123
    },
    {
      age: 35,
      deaths: 128
    },
    {
      age: 36,
      deaths: 130
    },
    {
      age: 37,
      deaths: 136
    },
    {
      age: 38,
      deaths: 143
    },
    {
      age: 39,
      deaths: 152
    },
    {
      age: 40,
      deaths: 166
    },
    {
      age: 41,
      deaths: 176
    },
    {
      age: 42,
      deaths: 195
    },
    {
      age: 43,
      deaths: 206
    },
    {
      age: 44,
      deaths: 222
    },
    {
      age: 45,
      deaths: 242
    },
    {
      age: 46,
      deaths: 270
    },
    {
      age: 47,
      deaths: 297
    },
    {
      age: 48,
      deaths: 322
    },
    {
      age: 49,
      deaths: 359
    },
    {
      age: 50,
      deaths: 390
    },
    {
      age: 51,
      deaths: 427
    },
    {
      age: 52,
      deaths: 457
    },
    {
      age: 53,
      deaths: 491
    },
    {
      age: 54,
      deaths: 528
    },
    {
      age: 55,
      deaths: 575
    },
    {
      age: 56,
      deaths: 620
    },
    {
      age: 57,
      deaths: 652
    },
    {
      age: 58,
      deaths: 699
    },
    {
      age: 59,
      deaths: 736
    },
    {
      age: 60,
      deaths: 790
    },
    {
      age: 61,
      deaths: 837
    },
    {
      age: 62,
      deaths: 882
    },
    {
      age: 63,
      deaths: 932
    },
    {
      age: 64,
      deaths: 995
    },
    {
      age: 65,
      deaths: 1048
    },
    {
      age: 66,
      deaths: 1186
    },
    {
      age: 67,
      deaths: 1133
    },
    {
      age: 68,
      deaths: 1284
    },
    {
      age: 69,
      deaths: 1393
    },
    {
      age: 70,
      deaths: 1531
    },
    {
      age: 71,
      deaths: 1579
    },
    {
      age: 72,
      deaths: 1680
    },
    {
      age: 73,
      deaths: 1788
    },
    {
      age: 74,
      deaths: 1921
    },
    {
      age: 75,
      deaths: 2050
    },
    {
      age: 76,
      deaths: 2167
    },
    {
      age: 77,
      deaths: 2339
    },
    {
      age: 78,
      deaths: 2503
    },
    {
      age: 79,
      deaths: 2583
    },
    {
      age: 80,
      deaths: 2695
    },
    {
      age: 81,
      deaths: 2862
    },
    {
      age: 82,
      deaths: 3045
    },
    {
      age: 83,
      deaths: 3199
    },
    {
      age: 84,
      deaths: 3339
    },
    {
      age: 85,
      deaths: 3475
    },
    {
      age: 86,
      deaths: 3553
    },
    {
      age: 87,
      deaths: 3642
    },
    {
      age: 88,
      deaths: 3642
    },
    {
      age: 89,
      deaths: 3607
    },
    {
      age: 90,
      deaths: 3512
    },
    {
      age: 91,
      deaths: 3339
    },
    {
      age: 92,
      deaths: 3119
    },
    {
      age: 93,
      deaths: 2921
    },
    {
      age: 94,
      deaths: 2516
    },
    {
      age: 95,
      deaths: 2172
    },
    {
      age: 96,
      deaths: 1836
    },
    {
      age: 97,
      deaths: 1506
    },
    {
      age: 98,
      deaths: 1197
    },
    {
      age: 99,
      deaths: 920
    },
    {
      age: 100,
      deaths: 682
    },
    {
      age: 101,
      deaths: 488
    },
    {
      age: 102,
      deaths: 336
    },
    {
      age: 103,
      deaths: 223
    },
    {
      age: 104,
      deaths: 142
    },
    {
      age: 105,
      deaths: 87
    },
    {
      age: 106,
      deaths: 51
    },
    {
      age: 107,
      deaths: 29
    },
    {
      age: 108,
      deaths: 16
    },
    {
      age: 109,
      deaths: 8
    },
    {
      age: 110,
      deaths: 8
    }
  ],
  2014: [
    {
      age: 0,
      deaths: 583
    },
    {
      age: 1,
      deaths: 37
    },
    {
      age: 2,
      deaths: 25
    },
    {
      age: 3,
      deaths: 18
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 11
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 10
    },
    {
      age: 11,
      deaths: 11
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 19
    },
    {
      age: 15,
      deaths: 24
    },
    {
      age: 16,
      deaths: 34
    },
    {
      age: 17,
      deaths: 43
    },
    {
      age: 18,
      deaths: 57
    },
    {
      age: 19,
      deaths: 66
    },
    {
      age: 20,
      deaths: 72
    },
    {
      age: 21,
      deaths: 81
    },
    {
      age: 22,
      deaths: 87
    },
    {
      age: 23,
      deaths: 86
    },
    {
      age: 24,
      deaths: 90
    },
    {
      age: 25,
      deaths: 93
    },
    {
      age: 26,
      deaths: 97
    },
    {
      age: 27,
      deaths: 96
    },
    {
      age: 28,
      deaths: 101
    },
    {
      age: 29,
      deaths: 104
    },
    {
      age: 30,
      deaths: 103
    },
    {
      age: 31,
      deaths: 113
    },
    {
      age: 32,
      deaths: 114
    },
    {
      age: 33,
      deaths: 118
    },
    {
      age: 34,
      deaths: 127
    },
    {
      age: 35,
      deaths: 133
    },
    {
      age: 36,
      deaths: 137
    },
    {
      age: 37,
      deaths: 144
    },
    {
      age: 38,
      deaths: 146
    },
    {
      age: 39,
      deaths: 157
    },
    {
      age: 40,
      deaths: 165
    },
    {
      age: 41,
      deaths: 185
    },
    {
      age: 42,
      deaths: 194
    },
    {
      age: 43,
      deaths: 208
    },
    {
      age: 44,
      deaths: 223
    },
    {
      age: 45,
      deaths: 241
    },
    {
      age: 46,
      deaths: 266
    },
    {
      age: 47,
      deaths: 292
    },
    {
      age: 48,
      deaths: 322
    },
    {
      age: 49,
      deaths: 354
    },
    {
      age: 50,
      deaths: 386
    },
    {
      age: 51,
      deaths: 428
    },
    {
      age: 52,
      deaths: 456
    },
    {
      age: 53,
      deaths: 500
    },
    {
      age: 54,
      deaths: 530
    },
    {
      age: 55,
      deaths: 579
    },
    {
      age: 56,
      deaths: 623
    },
    {
      age: 57,
      deaths: 670
    },
    {
      age: 58,
      deaths: 702
    },
    {
      age: 59,
      deaths: 744
    },
    {
      age: 60,
      deaths: 796
    },
    {
      age: 61,
      deaths: 856
    },
    {
      age: 62,
      deaths: 903
    },
    {
      age: 63,
      deaths: 949
    },
    {
      age: 64,
      deaths: 983
    },
    {
      age: 65,
      deaths: 1048
    },
    {
      age: 66,
      deaths: 1111
    },
    {
      age: 67,
      deaths: 1255
    },
    {
      age: 68,
      deaths: 1209
    },
    {
      age: 69,
      deaths: 1355
    },
    {
      age: 70,
      deaths: 1466
    },
    {
      age: 71,
      deaths: 1627
    },
    {
      age: 72,
      deaths: 1670
    },
    {
      age: 73,
      deaths: 1779
    },
    {
      age: 74,
      deaths: 1885
    },
    {
      age: 75,
      deaths: 2034
    },
    {
      age: 76,
      deaths: 2169
    },
    {
      age: 77,
      deaths: 2286
    },
    {
      age: 78,
      deaths: 2437
    },
    {
      age: 79,
      deaths: 2609
    },
    {
      age: 80,
      deaths: 2676
    },
    {
      age: 81,
      deaths: 2809
    },
    {
      age: 82,
      deaths: 2970
    },
    {
      age: 83,
      deaths: 3113
    },
    {
      age: 84,
      deaths: 3313
    },
    {
      age: 85,
      deaths: 3378
    },
    {
      age: 86,
      deaths: 3491
    },
    {
      age: 87,
      deaths: 3584
    },
    {
      age: 88,
      deaths: 3581
    },
    {
      age: 89,
      deaths: 3559
    },
    {
      age: 90,
      deaths: 3515
    },
    {
      age: 91,
      deaths: 3375
    },
    {
      age: 92,
      deaths: 3184
    },
    {
      age: 93,
      deaths: 2902
    },
    {
      age: 94,
      deaths: 2653
    },
    {
      age: 95,
      deaths: 2219
    },
    {
      age: 96,
      deaths: 1891
    },
    {
      age: 97,
      deaths: 1565
    },
    {
      age: 98,
      deaths: 1256
    },
    {
      age: 99,
      deaths: 975
    },
    {
      age: 100,
      deaths: 732
    },
    {
      age: 101,
      deaths: 530
    },
    {
      age: 102,
      deaths: 369
    },
    {
      age: 103,
      deaths: 248
    },
    {
      age: 104,
      deaths: 160
    },
    {
      age: 105,
      deaths: 100
    },
    {
      age: 106,
      deaths: 60
    },
    {
      age: 107,
      deaths: 34
    },
    {
      age: 108,
      deaths: 19
    },
    {
      age: 109,
      deaths: 10
    },
    {
      age: 110,
      deaths: 10
    }
  ],
  2015: [
    {
      age: 0,
      deaths: 587
    },
    {
      age: 1,
      deaths: 40
    },
    {
      age: 2,
      deaths: 25
    },
    {
      age: 3,
      deaths: 19
    },
    {
      age: 4,
      deaths: 15
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 13
    },
    {
      age: 7,
      deaths: 11
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 10
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 11
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 17
    },
    {
      age: 14,
      deaths: 21
    },
    {
      age: 15,
      deaths: 26
    },
    {
      age: 16,
      deaths: 35
    },
    {
      age: 17,
      deaths: 47
    },
    {
      age: 18,
      deaths: 61
    },
    {
      age: 19,
      deaths: 71
    },
    {
      age: 20,
      deaths: 77
    },
    {
      age: 21,
      deaths: 86
    },
    {
      age: 22,
      deaths: 91
    },
    {
      age: 23,
      deaths: 92
    },
    {
      age: 24,
      deaths: 97
    },
    {
      age: 25,
      deaths: 100
    },
    {
      age: 26,
      deaths: 98
    },
    {
      age: 27,
      deaths: 105
    },
    {
      age: 28,
      deaths: 107
    },
    {
      age: 29,
      deaths: 114
    },
    {
      age: 30,
      deaths: 115
    },
    {
      age: 31,
      deaths: 118
    },
    {
      age: 32,
      deaths: 126
    },
    {
      age: 33,
      deaths: 130
    },
    {
      age: 34,
      deaths: 134
    },
    {
      age: 35,
      deaths: 138
    },
    {
      age: 36,
      deaths: 145
    },
    {
      age: 37,
      deaths: 150
    },
    {
      age: 38,
      deaths: 158
    },
    {
      age: 39,
      deaths: 160
    },
    {
      age: 40,
      deaths: 170
    },
    {
      age: 41,
      deaths: 183
    },
    {
      age: 42,
      deaths: 198
    },
    {
      age: 43,
      deaths: 208
    },
    {
      age: 44,
      deaths: 233
    },
    {
      age: 45,
      deaths: 245
    },
    {
      age: 46,
      deaths: 269
    },
    {
      age: 47,
      deaths: 286
    },
    {
      age: 48,
      deaths: 319
    },
    {
      age: 49,
      deaths: 351
    },
    {
      age: 50,
      deaths: 386
    },
    {
      age: 51,
      deaths: 422
    },
    {
      age: 52,
      deaths: 459
    },
    {
      age: 53,
      deaths: 498
    },
    {
      age: 54,
      deaths: 539
    },
    {
      age: 55,
      deaths: 572
    },
    {
      age: 56,
      deaths: 619
    },
    {
      age: 57,
      deaths: 670
    },
    {
      age: 58,
      deaths: 712
    },
    {
      age: 59,
      deaths: 749
    },
    {
      age: 60,
      deaths: 793
    },
    {
      age: 61,
      deaths: 846
    },
    {
      age: 62,
      deaths: 905
    },
    {
      age: 63,
      deaths: 973
    },
    {
      age: 64,
      deaths: 1005
    },
    {
      age: 65,
      deaths: 1056
    },
    {
      age: 66,
      deaths: 1122
    },
    {
      age: 67,
      deaths: 1180
    },
    {
      age: 68,
      deaths: 1331
    },
    {
      age: 69,
      deaths: 1294
    },
    {
      age: 70,
      deaths: 1460
    },
    {
      age: 71,
      deaths: 1574
    },
    {
      age: 72,
      deaths: 1742
    },
    {
      age: 73,
      deaths: 1778
    },
    {
      age: 74,
      deaths: 1888
    },
    {
      age: 75,
      deaths: 2033
    },
    {
      age: 76,
      deaths: 2156
    },
    {
      age: 77,
      deaths: 2320
    },
    {
      age: 78,
      deaths: 2419
    },
    {
      age: 79,
      deaths: 2589
    },
    {
      age: 80,
      deaths: 2782
    },
    {
      age: 81,
      deaths: 2819
    },
    {
      age: 82,
      deaths: 2952
    },
    {
      age: 83,
      deaths: 3103
    },
    {
      age: 84,
      deaths: 3257
    },
    {
      age: 85,
      deaths: 3388
    },
    {
      age: 86,
      deaths: 3471
    },
    {
      age: 87,
      deaths: 3588
    },
    {
      age: 88,
      deaths: 3604
    },
    {
      age: 89,
      deaths: 3561
    },
    {
      age: 90,
      deaths: 3519
    },
    {
      age: 91,
      deaths: 3363
    },
    {
      age: 92,
      deaths: 3155
    },
    {
      age: 93,
      deaths: 2914
    },
    {
      age: 94,
      deaths: 2568
    },
    {
      age: 95,
      deaths: 2208
    },
    {
      age: 96,
      deaths: 1875
    },
    {
      age: 97,
      deaths: 1546
    },
    {
      age: 98,
      deaths: 1236
    },
    {
      age: 99,
      deaths: 955
    },
    {
      age: 100,
      deaths: 713
    },
    {
      age: 101,
      deaths: 513
    },
    {
      age: 102,
      deaths: 356
    },
    {
      age: 103,
      deaths: 237
    },
    {
      age: 104,
      deaths: 152
    },
    {
      age: 105,
      deaths: 94
    },
    {
      age: 106,
      deaths: 56
    },
    {
      age: 107,
      deaths: 32
    },
    {
      age: 108,
      deaths: 17
    },
    {
      age: 109,
      deaths: 9
    },
    {
      age: 110,
      deaths: 9
    }
  ],
  2016: [
    {
      age: 0,
      deaths: 583
    },
    {
      age: 1,
      deaths: 39
    },
    {
      age: 2,
      deaths: 26
    },
    {
      age: 3,
      deaths: 20
    },
    {
      age: 4,
      deaths: 16
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 12
    },
    {
      age: 7,
      deaths: 13
    },
    {
      age: 8,
      deaths: 12
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 12
    },
    {
      age: 12,
      deaths: 13
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 21
    },
    {
      age: 15,
      deaths: 28
    },
    {
      age: 16,
      deaths: 37
    },
    {
      age: 17,
      deaths: 48
    },
    {
      age: 18,
      deaths: 66
    },
    {
      age: 19,
      deaths: 74
    },
    {
      age: 20,
      deaths: 81
    },
    {
      age: 21,
      deaths: 94
    },
    {
      age: 22,
      deaths: 97
    },
    {
      age: 23,
      deaths: 102
    },
    {
      age: 24,
      deaths: 105
    },
    {
      age: 25,
      deaths: 110
    },
    {
      age: 26,
      deaths: 110
    },
    {
      age: 27,
      deaths: 117
    },
    {
      age: 28,
      deaths: 123
    },
    {
      age: 29,
      deaths: 121
    },
    {
      age: 30,
      deaths: 126
    },
    {
      age: 31,
      deaths: 132
    },
    {
      age: 32,
      deaths: 138
    },
    {
      age: 33,
      deaths: 140
    },
    {
      age: 34,
      deaths: 148
    },
    {
      age: 35,
      deaths: 152
    },
    {
      age: 36,
      deaths: 159
    },
    {
      age: 37,
      deaths: 163
    },
    {
      age: 38,
      deaths: 172
    },
    {
      age: 39,
      deaths: 178
    },
    {
      age: 40,
      deaths: 180
    },
    {
      age: 41,
      deaths: 191
    },
    {
      age: 42,
      deaths: 199
    },
    {
      age: 43,
      deaths: 223
    },
    {
      age: 44,
      deaths: 235
    },
    {
      age: 45,
      deaths: 256
    },
    {
      age: 46,
      deaths: 273
    },
    {
      age: 47,
      deaths: 289
    },
    {
      age: 48,
      deaths: 319
    },
    {
      age: 49,
      deaths: 352
    },
    {
      age: 50,
      deaths: 382
    },
    {
      age: 51,
      deaths: 414
    },
    {
      age: 52,
      deaths: 457
    },
    {
      age: 53,
      deaths: 497
    },
    {
      age: 54,
      deaths: 538
    },
    {
      age: 55,
      deaths: 583
    },
    {
      age: 56,
      deaths: 614
    },
    {
      age: 57,
      deaths: 666
    },
    {
      age: 58,
      deaths: 709
    },
    {
      age: 59,
      deaths: 765
    },
    {
      age: 60,
      deaths: 800
    },
    {
      age: 61,
      deaths: 853
    },
    {
      age: 62,
      deaths: 907
    },
    {
      age: 63,
      deaths: 954
    },
    {
      age: 64,
      deaths: 1019
    },
    {
      age: 65,
      deaths: 1064
    },
    {
      age: 66,
      deaths: 1105
    },
    {
      age: 67,
      deaths: 1182
    },
    {
      age: 68,
      deaths: 1246
    },
    {
      age: 69,
      deaths: 1420
    },
    {
      age: 70,
      deaths: 1351
    },
    {
      age: 71,
      deaths: 1541
    },
    {
      age: 72,
      deaths: 1650
    },
    {
      age: 73,
      deaths: 1828
    },
    {
      age: 74,
      deaths: 1872
    },
    {
      age: 75,
      deaths: 2015
    },
    {
      age: 76,
      deaths: 2108
    },
    {
      age: 77,
      deaths: 2266
    },
    {
      age: 78,
      deaths: 2400
    },
    {
      age: 79,
      deaths: 2534
    },
    {
      age: 80,
      deaths: 2710
    },
    {
      age: 81,
      deaths: 2866
    },
    {
      age: 82,
      deaths: 2906
    },
    {
      age: 83,
      deaths: 2989
    },
    {
      age: 84,
      deaths: 3152
    },
    {
      age: 85,
      deaths: 3304
    },
    {
      age: 86,
      deaths: 3419
    },
    {
      age: 87,
      deaths: 3487
    },
    {
      age: 88,
      deaths: 3540
    },
    {
      age: 89,
      deaths: 3551
    },
    {
      age: 90,
      deaths: 3485
    },
    {
      age: 91,
      deaths: 3373
    },
    {
      age: 92,
      deaths: 3163
    },
    {
      age: 93,
      deaths: 2920
    },
    {
      age: 94,
      deaths: 2628
    },
    {
      age: 95,
      deaths: 2259
    },
    {
      age: 96,
      deaths: 1938
    },
    {
      age: 97,
      deaths: 1616
    },
    {
      age: 98,
      deaths: 1308
    },
    {
      age: 99,
      deaths: 1025
    },
    {
      age: 100,
      deaths: 777
    },
    {
      age: 101,
      deaths: 568
    },
    {
      age: 102,
      deaths: 401
    },
    {
      age: 103,
      deaths: 273
    },
    {
      age: 104,
      deaths: 179
    },
    {
      age: 105,
      deaths: 113
    },
    {
      age: 106,
      deaths: 68
    },
    {
      age: 107,
      deaths: 40
    },
    {
      age: 108,
      deaths: 22
    },
    {
      age: 109,
      deaths: 12
    },
    {
      age: 110,
      deaths: 12
    }
  ],
  2017: [
    {
      age: 0,
      deaths: 571
    },
    {
      age: 1,
      deaths: 38
    },
    {
      age: 2,
      deaths: 25
    },
    {
      age: 3,
      deaths: 19
    },
    {
      age: 4,
      deaths: 15
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 11
    },
    {
      age: 7,
      deaths: 12
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 12
    },
    {
      age: 11,
      deaths: 12
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 17
    },
    {
      age: 14,
      deaths: 22
    },
    {
      age: 15,
      deaths: 29
    },
    {
      age: 16,
      deaths: 40
    },
    {
      age: 17,
      deaths: 46
    },
    {
      age: 18,
      deaths: 64
    },
    {
      age: 19,
      deaths: 76
    },
    {
      age: 20,
      deaths: 82
    },
    {
      age: 21,
      deaths: 89
    },
    {
      age: 22,
      deaths: 94
    },
    {
      age: 23,
      deaths: 101
    },
    {
      age: 24,
      deaths: 106
    },
    {
      age: 25,
      deaths: 111
    },
    {
      age: 26,
      deaths: 114
    },
    {
      age: 27,
      deaths: 121
    },
    {
      age: 28,
      deaths: 126
    },
    {
      age: 29,
      deaths: 126
    },
    {
      age: 30,
      deaths: 129
    },
    {
      age: 31,
      deaths: 136
    },
    {
      age: 32,
      deaths: 143
    },
    {
      age: 33,
      deaths: 148
    },
    {
      age: 34,
      deaths: 154
    },
    {
      age: 35,
      deaths: 157
    },
    {
      age: 36,
      deaths: 162
    },
    {
      age: 37,
      deaths: 171
    },
    {
      age: 38,
      deaths: 171
    },
    {
      age: 39,
      deaths: 183
    },
    {
      age: 40,
      deaths: 194
    },
    {
      age: 41,
      deaths: 196
    },
    {
      age: 42,
      deaths: 208
    },
    {
      age: 43,
      deaths: 218
    },
    {
      age: 44,
      deaths: 230
    },
    {
      age: 45,
      deaths: 256
    },
    {
      age: 46,
      deaths: 279
    },
    {
      age: 47,
      deaths: 299
    },
    {
      age: 48,
      deaths: 315
    },
    {
      age: 49,
      deaths: 341
    },
    {
      age: 50,
      deaths: 379
    },
    {
      age: 51,
      deaths: 416
    },
    {
      age: 52,
      deaths: 446
    },
    {
      age: 53,
      deaths: 488
    },
    {
      age: 54,
      deaths: 530
    },
    {
      age: 55,
      deaths: 578
    },
    {
      age: 56,
      deaths: 617
    },
    {
      age: 57,
      deaths: 664
    },
    {
      age: 58,
      deaths: 706
    },
    {
      age: 59,
      deaths: 760
    },
    {
      age: 60,
      deaths: 814
    },
    {
      age: 61,
      deaths: 852
    },
    {
      age: 62,
      deaths: 900
    },
    {
      age: 63,
      deaths: 964
    },
    {
      age: 64,
      deaths: 1020
    },
    {
      age: 65,
      deaths: 1075
    },
    {
      age: 66,
      deaths: 1115
    },
    {
      age: 67,
      deaths: 1175
    },
    {
      age: 68,
      deaths: 1256
    },
    {
      age: 69,
      deaths: 1331
    },
    {
      age: 70,
      deaths: 1508
    },
    {
      age: 71,
      deaths: 1447
    },
    {
      age: 72,
      deaths: 1643
    },
    {
      age: 73,
      deaths: 1758
    },
    {
      age: 74,
      deaths: 1953
    },
    {
      age: 75,
      deaths: 1980
    },
    {
      age: 76,
      deaths: 2116
    },
    {
      age: 77,
      deaths: 2251
    },
    {
      age: 78,
      deaths: 2404
    },
    {
      age: 79,
      deaths: 2577
    },
    {
      age: 80,
      deaths: 2692
    },
    {
      age: 81,
      deaths: 2842
    },
    {
      age: 82,
      deaths: 3037
    },
    {
      age: 83,
      deaths: 3059
    },
    {
      age: 84,
      deaths: 3153
    },
    {
      age: 85,
      deaths: 3256
    },
    {
      age: 86,
      deaths: 3390
    },
    {
      age: 87,
      deaths: 3492
    },
    {
      age: 88,
      deaths: 3508
    },
    {
      age: 89,
      deaths: 3501
    },
    {
      age: 90,
      deaths: 3485
    },
    {
      age: 91,
      deaths: 3340
    },
    {
      age: 92,
      deaths: 3195
    },
    {
      age: 93,
      deaths: 2904
    },
    {
      age: 94,
      deaths: 2634
    },
    {
      age: 95,
      deaths: 2255
    },
    {
      age: 96,
      deaths: 1934
    },
    {
      age: 97,
      deaths: 1612
    },
    {
      age: 98,
      deaths: 1303
    },
    {
      age: 99,
      deaths: 1021
    },
    {
      age: 100,
      deaths: 773
    },
    {
      age: 101,
      deaths: 566
    },
    {
      age: 102,
      deaths: 399
    },
    {
      age: 103,
      deaths: 271
    },
    {
      age: 104,
      deaths: 177
    },
    {
      age: 105,
      deaths: 112
    },
    {
      age: 106,
      deaths: 68
    },
    {
      age: 107,
      deaths: 39
    },
    {
      age: 108,
      deaths: 22
    },
    {
      age: 109,
      deaths: 12
    },
    {
      age: 110,
      deaths: 12
    }
  ],
  2018: [
    {
      age: 0,
      deaths: 558
    },
    {
      age: 1,
      deaths: 37
    },
    {
      age: 2,
      deaths: 26
    },
    {
      age: 3,
      deaths: 18
    },
    {
      age: 4,
      deaths: 15
    },
    {
      age: 5,
      deaths: 13
    },
    {
      age: 6,
      deaths: 11
    },
    {
      age: 7,
      deaths: 10
    },
    {
      age: 8,
      deaths: 11
    },
    {
      age: 9,
      deaths: 11
    },
    {
      age: 10,
      deaths: 11
    },
    {
      age: 11,
      deaths: 12
    },
    {
      age: 12,
      deaths: 14
    },
    {
      age: 13,
      deaths: 16
    },
    {
      age: 14,
      deaths: 21
    },
    {
      age: 15,
      deaths: 28
    },
    {
      age: 16,
      deaths: 36
    },
    {
      age: 17,
      deaths: 48
    },
    {
      age: 18,
      deaths: 63
    },
    {
      age: 19,
      deaths: 69
    },
    {
      age: 20,
      deaths: 76
    },
    {
      age: 21,
      deaths: 86
    },
    {
      age: 22,
      deaths: 90
    },
    {
      age: 23,
      deaths: 95
    },
    {
      age: 24,
      deaths: 100
    },
    {
      age: 25,
      deaths: 104
    },
    {
      age: 26,
      deaths: 109
    },
    {
      age: 27,
      deaths: 117
    },
    {
      age: 28,
      deaths: 121
    },
    {
      age: 29,
      deaths: 124
    },
    {
      age: 30,
      deaths: 133
    },
    {
      age: 31,
      deaths: 131
    },
    {
      age: 32,
      deaths: 139
    },
    {
      age: 33,
      deaths: 143
    },
    {
      age: 34,
      deaths: 146
    },
    {
      age: 35,
      deaths: 158
    },
    {
      age: 36,
      deaths: 161
    },
    {
      age: 37,
      deaths: 167
    },
    {
      age: 38,
      deaths: 176
    },
    {
      age: 39,
      deaths: 186
    },
    {
      age: 40,
      deaths: 189
    },
    {
      age: 41,
      deaths: 199
    },
    {
      age: 42,
      deaths: 203
    },
    {
      age: 43,
      deaths: 218
    },
    {
      age: 44,
      deaths: 233
    },
    {
      age: 45,
      deaths: 255
    },
    {
      age: 46,
      deaths: 277
    },
    {
      age: 47,
      deaths: 295
    },
    {
      age: 48,
      deaths: 313
    },
    {
      age: 49,
      deaths: 340
    },
    {
      age: 50,
      deaths: 367
    },
    {
      age: 51,
      deaths: 405
    },
    {
      age: 52,
      deaths: 439
    },
    {
      age: 53,
      deaths: 477
    },
    {
      age: 54,
      deaths: 524
    },
    {
      age: 55,
      deaths: 569
    },
    {
      age: 56,
      deaths: 618
    },
    {
      age: 57,
      deaths: 665
    },
    {
      age: 58,
      deaths: 704
    },
    {
      age: 59,
      deaths: 752
    },
    {
      age: 60,
      deaths: 801
    },
    {
      age: 61,
      deaths: 863
    },
    {
      age: 62,
      deaths: 908
    },
    {
      age: 63,
      deaths: 971
    },
    {
      age: 64,
      deaths: 1013
    },
    {
      age: 65,
      deaths: 1074
    },
    {
      age: 66,
      deaths: 1135
    },
    {
      age: 67,
      deaths: 1187
    },
    {
      age: 68,
      deaths: 1236
    },
    {
      age: 69,
      deaths: 1331
    },
    {
      age: 70,
      deaths: 1409
    },
    {
      age: 71,
      deaths: 1605
    },
    {
      age: 72,
      deaths: 1543
    },
    {
      age: 73,
      deaths: 1740
    },
    {
      age: 74,
      deaths: 1871
    },
    {
      age: 75,
      deaths: 2074
    },
    {
      age: 76,
      deaths: 2105
    },
    {
      age: 77,
      deaths: 2253
    },
    {
      age: 78,
      deaths: 2358
    },
    {
      age: 79,
      deaths: 2526
    },
    {
      age: 80,
      deaths: 2695
    },
    {
      age: 81,
      deaths: 2812
    },
    {
      age: 82,
      deaths: 2987
    },
    {
      age: 83,
      deaths: 3139
    },
    {
      age: 84,
      deaths: 3150
    },
    {
      age: 85,
      deaths: 3222
    },
    {
      age: 86,
      deaths: 3328
    },
    {
      age: 87,
      deaths: 3419
    },
    {
      age: 88,
      deaths: 3524
    },
    {
      age: 89,
      deaths: 3500
    },
    {
      age: 90,
      deaths: 3459
    },
    {
      age: 91,
      deaths: 3360
    },
    {
      age: 92,
      deaths: 3149
    },
    {
      age: 93,
      deaths: 2941
    },
    {
      age: 94,
      deaths: 2663
    },
    {
      age: 95,
      deaths: 2293
    },
    {
      age: 96,
      deaths: 1979
    },
    {
      age: 97,
      deaths: 1661
    },
    {
      age: 98,
      deaths: 1355
    },
    {
      age: 99,
      deaths: 1071
    },
    {
      age: 100,
      deaths: 819
    },
    {
      age: 101,
      deaths: 606
    },
    {
      age: 102,
      deaths: 433
    },
    {
      age: 103,
      deaths: 298
    },
    {
      age: 104,
      deaths: 198
    },
    {
      age: 105,
      deaths: 126
    },
    {
      age: 106,
      deaths: 77
    },
    {
      age: 107,
      deaths: 46
    },
    {
      age: 108,
      deaths: 26
    },
    {
      age: 109,
      deaths: 14
    },
    {
      age: 110,
      deaths: 15
    }
  ]
};
