export const retroo = {
  1900: {
    0: 1811000,
    1: 1835000,
    2: 1846000,
    3: 1848000,
    4: 1841000,
    5: 1827000,
    6: 1806000,
    7: 1780000,
    8: 1750000,
    9: 1717000,
    10: 1682000,
    11: 1644000,
    12: 1611000,
    13: 1585000,
    14: 1564000,
    15: 1544000,
    16: 1519000,
    17: 1505000,
    18: 1500000,
    19: 1500000,
    20: 1500000,
    21: 1500000,
    22: 1491000,
    23: 1465000,
    24: 1427000,
    25: 1392000,
    26: 1356000,
    27: 1316000,
    28: 1275000,
    29: 1233000,
    30: 1191000,
    31: 1145000,
    32: 1108000,
    33: 1082000,
    34: 1063000,
    35: 1044000,
    36: 1025000,
    37: 1004000,
    38: 977000,
    39: 946000,
    40: 917000,
    41: 888000,
    42: 859000,
    43: 824000,
    44: 789000,
    45: 753000,
    46: 718000,
    47: 688000,
    48: 666000,
    49: 650000,
    50: 635000,
    51: 622000,
    52: 600000,
    53: 571000,
    54: 534000,
    55: 500000,
    56: 465000,
    57: 437000,
    58: 417000,
    59: 405000,
    60: 391000,
    61: 380000,
    62: 364000,
    63: 345000,
    64: 322000,
    65: 302000,
    66: 281000,
    67: 262000,
    68: 243000,
    69: 224000,
    70: 207000,
    71: 191000,
    72: 176000,
    73: 163000,
    74: 151000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 899000
  },
  1901: {
    0: 1850000,
    1: 1869000,
    2: 1876000,
    3: 1876000,
    4: 1865000,
    5: 1849000,
    6: 1826000,
    7: 1799000,
    8: 1768000,
    9: 1735000,
    10: 1699000,
    11: 1661000,
    12: 1630000,
    13: 1605000,
    14: 1586000,
    15: 1567000,
    16: 1544000,
    17: 1531000,
    18: 1527000,
    19: 1529000,
    20: 1530000,
    21: 1532000,
    22: 1524000,
    23: 1498000,
    24: 1460000,
    25: 1424000,
    26: 1387000,
    27: 1349000,
    28: 1306000,
    29: 1263000,
    30: 1217000,
    31: 1170000,
    32: 1131000,
    33: 1106000,
    34: 1089000,
    35: 1070000,
    36: 1052000,
    37: 1031000,
    38: 1003000,
    39: 970000,
    40: 940000,
    41: 910000,
    42: 877000,
    43: 843000,
    44: 808000,
    45: 773000,
    46: 734000,
    47: 704000,
    48: 683000,
    49: 667000,
    50: 651000,
    51: 639000,
    52: 619000,
    53: 587000,
    54: 549000,
    55: 514000,
    56: 477000,
    57: 446000,
    58: 427000,
    59: 415000,
    60: 401000,
    61: 387000,
    62: 372000,
    63: 353000,
    64: 330000,
    65: 308000,
    66: 288000,
    67: 268000,
    68: 250000,
    69: 231000,
    70: 212000,
    71: 196000,
    72: 179000,
    73: 166000,
    74: 154000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 922000
  },
  1902: {
    0: 1892000,
    1: 1906000,
    2: 1910000,
    3: 1904000,
    4: 1890000,
    5: 1873000,
    6: 1848000,
    7: 1818000,
    8: 1788000,
    9: 1752000,
    10: 1717000,
    11: 1681000,
    12: 1648000,
    13: 1625000,
    14: 1610000,
    15: 1591000,
    16: 1572000,
    17: 1560000,
    18: 1558000,
    19: 1561000,
    20: 1563000,
    21: 1566000,
    22: 1557000,
    23: 1532000,
    24: 1495000,
    25: 1457000,
    26: 1422000,
    27: 1381000,
    28: 1338000,
    29: 1292000,
    30: 1246000,
    31: 1197000,
    32: 1157000,
    33: 1132000,
    34: 1115000,
    35: 1096000,
    36: 1081000,
    37: 1059000,
    38: 1030000,
    39: 995000,
    40: 963000,
    41: 932000,
    42: 899000,
    43: 863000,
    44: 827000,
    45: 790000,
    46: 754000,
    47: 723000,
    48: 701000,
    49: 686000,
    50: 671000,
    51: 657000,
    52: 637000,
    53: 604000,
    54: 565000,
    55: 527000,
    56: 490000,
    57: 459000,
    58: 438000,
    59: 424000,
    60: 409000,
    61: 396000,
    62: 380000,
    63: 360000,
    64: 337000,
    65: 316000,
    66: 296000,
    67: 275000,
    68: 256000,
    69: 238000,
    70: 218000,
    71: 201000,
    72: 185000,
    73: 170000,
    74: 156000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 945000
  },
  1903: {
    0: 1930000,
    1: 1938000,
    2: 1938000,
    3: 1927000,
    4: 1912000,
    5: 1889000,
    6: 1864000,
    7: 1834000,
    8: 1801000,
    9: 1767000,
    10: 1733000,
    11: 1695000,
    12: 1666000,
    13: 1644000,
    14: 1631000,
    15: 1615000,
    16: 1598000,
    17: 1589000,
    18: 1588000,
    19: 1592000,
    20: 1595000,
    21: 1598000,
    22: 1591000,
    23: 1565000,
    24: 1527000,
    25: 1492000,
    26: 1454000,
    27: 1413000,
    28: 1368000,
    29: 1321000,
    30: 1273000,
    31: 1221000,
    32: 1181000,
    33: 1155000,
    34: 1141000,
    35: 1124000,
    36: 1107000,
    37: 1086000,
    38: 1057000,
    39: 1020000,
    40: 985000,
    41: 951000,
    42: 918000,
    43: 881000,
    44: 845000,
    45: 809000,
    46: 771000,
    47: 740000,
    48: 720000,
    49: 704000,
    50: 689000,
    51: 676000,
    52: 654000,
    53: 621000,
    54: 580000,
    55: 540000,
    56: 501000,
    57: 468000,
    58: 447000,
    59: 434000,
    60: 418000,
    61: 404000,
    62: 389000,
    63: 368000,
    64: 344000,
    65: 324000,
    66: 303000,
    67: 282000,
    68: 262000,
    69: 243000,
    70: 225000,
    71: 206000,
    72: 189000,
    73: 173000,
    74: 158000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 970000
  },
  1904: {
    0: 1969000,
    1: 1971000,
    2: 1965000,
    3: 1953000,
    4: 1933000,
    5: 1909000,
    6: 1880000,
    7: 1849000,
    8: 1817000,
    9: 1782000,
    10: 1748000,
    11: 1712000,
    12: 1683000,
    13: 1664000,
    14: 1653000,
    15: 1640000,
    16: 1627000,
    17: 1619000,
    18: 1620000,
    19: 1625000,
    20: 1628000,
    21: 1632000,
    22: 1625000,
    23: 1599000,
    24: 1563000,
    25: 1526000,
    26: 1488000,
    27: 1445000,
    28: 1400000,
    29: 1351000,
    30: 1301000,
    31: 1249000,
    32: 1206000,
    33: 1182000,
    34: 1167000,
    35: 1150000,
    36: 1137000,
    37: 1116000,
    38: 1083000,
    39: 1044000,
    40: 1008000,
    41: 973000,
    42: 936000,
    43: 900000,
    44: 864000,
    45: 827000,
    46: 790000,
    47: 759000,
    48: 738000,
    49: 723000,
    50: 709000,
    51: 696000,
    52: 675000,
    53: 638000,
    54: 595000,
    55: 555000,
    56: 512000,
    57: 478000,
    58: 457000,
    59: 442000,
    60: 426000,
    61: 413000,
    62: 397000,
    63: 376000,
    64: 354000,
    65: 331000,
    66: 310000,
    67: 290000,
    68: 270000,
    69: 249000,
    70: 231000,
    71: 211000,
    72: 194000,
    73: 176000,
    74: 159000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 993000
  },
  1905: {
    0: 2010000,
    1: 2006000,
    2: 1995000,
    3: 1977000,
    4: 1956000,
    5: 1929000,
    6: 1898000,
    7: 1867000,
    8: 1832000,
    9: 1800000,
    10: 1765000,
    11: 1730000,
    12: 1703000,
    13: 1686000,
    14: 1678000,
    15: 1668000,
    16: 1656000,
    17: 1652000,
    18: 1653000,
    19: 1660000,
    20: 1665000,
    21: 1670000,
    22: 1664000,
    23: 1638000,
    24: 1600000,
    25: 1561000,
    26: 1523000,
    27: 1481000,
    28: 1434000,
    29: 1383000,
    30: 1332000,
    31: 1277000,
    32: 1235000,
    33: 1210000,
    34: 1195000,
    35: 1181000,
    36: 1166000,
    37: 1146000,
    38: 1113000,
    39: 1071000,
    40: 1032000,
    41: 994000,
    42: 955000,
    43: 919000,
    44: 884000,
    45: 848000,
    46: 811000,
    47: 779000,
    48: 758000,
    49: 744000,
    50: 730000,
    51: 716000,
    52: 695000,
    53: 657000,
    54: 612000,
    55: 570000,
    56: 526000,
    57: 490000,
    58: 467000,
    59: 451000,
    60: 437000,
    61: 423000,
    62: 405000,
    63: 386000,
    64: 362000,
    65: 339000,
    66: 319000,
    67: 297000,
    68: 277000,
    69: 257000,
    70: 236000,
    71: 217000,
    72: 199000,
    73: 181000,
    74: 164000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1019000
  },
  1906: {
    0: 2050000,
    1: 2040000,
    2: 2024000,
    3: 2002000,
    4: 1976000,
    5: 1947000,
    6: 1914000,
    7: 1881000,
    8: 1848000,
    9: 1815000,
    10: 1782000,
    11: 1748000,
    12: 1722000,
    13: 1708000,
    14: 1702000,
    15: 1696000,
    16: 1687000,
    17: 1684000,
    18: 1688000,
    19: 1695000,
    20: 1699000,
    21: 1705000,
    22: 1699000,
    23: 1675000,
    24: 1636000,
    25: 1596000,
    26: 1559000,
    27: 1515000,
    28: 1466000,
    29: 1417000,
    30: 1363000,
    31: 1308000,
    32: 1263000,
    33: 1239000,
    34: 1226000,
    35: 1210000,
    36: 1198000,
    37: 1177000,
    38: 1142000,
    39: 1096000,
    40: 1056000,
    41: 1013000,
    42: 974000,
    43: 937000,
    44: 902000,
    45: 867000,
    46: 831000,
    47: 800000,
    48: 780000,
    49: 765000,
    50: 750000,
    51: 738000,
    52: 716000,
    53: 677000,
    54: 630000,
    55: 584000,
    56: 537000,
    57: 499000,
    58: 475000,
    59: 463000,
    60: 447000,
    61: 433000,
    62: 417000,
    63: 395000,
    64: 371000,
    65: 349000,
    66: 327000,
    67: 305000,
    68: 284000,
    69: 263000,
    70: 242000,
    71: 223000,
    72: 204000,
    73: 185000,
    74: 167000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1046000
  },
  1907: {
    0: 2086000,
    1: 2069000,
    2: 2049000,
    3: 2023000,
    4: 1993000,
    5: 1963000,
    6: 1930000,
    7: 1896000,
    8: 1861000,
    9: 1830000,
    10: 1797000,
    11: 1765000,
    12: 1741000,
    13: 1730000,
    14: 1726000,
    15: 1721000,
    16: 1716000,
    17: 1715000,
    18: 1720000,
    19: 1728000,
    20: 1733000,
    21: 1739000,
    22: 1734000,
    23: 1709000,
    24: 1669000,
    25: 1631000,
    26: 1592000,
    27: 1548000,
    28: 1498000,
    29: 1446000,
    30: 1392000,
    31: 1335000,
    32: 1292000,
    33: 1267000,
    34: 1256000,
    35: 1240000,
    36: 1228000,
    37: 1207000,
    38: 1170000,
    39: 1122000,
    40: 1077000,
    41: 1033000,
    42: 992000,
    43: 955000,
    44: 921000,
    45: 887000,
    46: 851000,
    47: 822000,
    48: 800000,
    49: 786000,
    50: 771000,
    51: 759000,
    52: 737000,
    53: 695000,
    54: 647000,
    55: 598000,
    56: 549000,
    57: 509000,
    58: 486000,
    59: 473000,
    60: 457000,
    61: 442000,
    62: 426000,
    63: 404000,
    64: 380000,
    65: 357000,
    66: 334000,
    67: 313000,
    68: 291000,
    69: 270000,
    70: 249000,
    71: 229000,
    72: 208000,
    73: 189000,
    74: 171000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1073000
  },
  1908: {
    0: 2125000,
    1: 2102000,
    2: 2076000,
    3: 2046000,
    4: 2015000,
    5: 1981000,
    6: 1948000,
    7: 1913000,
    8: 1880000,
    9: 1847000,
    10: 1816000,
    11: 1785000,
    12: 1764000,
    13: 1753000,
    14: 1753000,
    15: 1749000,
    16: 1748000,
    17: 1749000,
    18: 1754000,
    19: 1762000,
    20: 1769000,
    21: 1775000,
    22: 1770000,
    23: 1744000,
    24: 1706000,
    25: 1666000,
    26: 1628000,
    27: 1582000,
    28: 1532000,
    29: 1480000,
    30: 1425000,
    31: 1367000,
    32: 1322000,
    33: 1297000,
    34: 1286000,
    35: 1273000,
    36: 1261000,
    37: 1239000,
    38: 1199000,
    39: 1149000,
    40: 1101000,
    41: 1053000,
    42: 1011000,
    43: 974000,
    44: 942000,
    45: 909000,
    46: 873000,
    47: 845000,
    48: 825000,
    49: 810000,
    50: 793000,
    51: 780000,
    52: 758000,
    53: 717000,
    54: 664000,
    55: 613000,
    56: 563000,
    57: 521000,
    58: 496000,
    59: 483000,
    60: 467000,
    61: 455000,
    62: 436000,
    63: 416000,
    64: 390000,
    65: 366000,
    66: 345000,
    67: 321000,
    68: 299000,
    69: 276000,
    70: 255000,
    71: 234000,
    72: 213000,
    73: 193000,
    74: 174000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1103000
  },
  1909: {
    0: 2162000,
    1: 2134000,
    2: 2104000,
    3: 2072000,
    4: 2037000,
    5: 2002000,
    6: 1967000,
    7: 1933000,
    8: 1899000,
    9: 1869000,
    10: 1839000,
    11: 1809000,
    12: 1789000,
    13: 1781000,
    14: 1782000,
    15: 1780000,
    16: 1779000,
    17: 1782000,
    18: 1789000,
    19: 1798000,
    20: 1804000,
    21: 1811000,
    22: 1806000,
    23: 1781000,
    24: 1741000,
    25: 1702000,
    26: 1663000,
    27: 1618000,
    28: 1567000,
    29: 1513000,
    30: 1458000,
    31: 1399000,
    32: 1354000,
    33: 1330000,
    34: 1319000,
    35: 1305000,
    36: 1295000,
    37: 1273000,
    38: 1231000,
    39: 1177000,
    40: 1127000,
    41: 1075000,
    42: 1030000,
    43: 994000,
    44: 964000,
    45: 930000,
    46: 897000,
    47: 870000,
    48: 850000,
    49: 834000,
    50: 818000,
    51: 805000,
    52: 781000,
    53: 737000,
    54: 682000,
    55: 630000,
    56: 575000,
    57: 533000,
    58: 508000,
    59: 495000,
    60: 480000,
    61: 466000,
    62: 450000,
    63: 426000,
    64: 401000,
    65: 376000,
    66: 353000,
    67: 329000,
    68: 307000,
    69: 284000,
    70: 261000,
    71: 239000,
    72: 218000,
    73: 198000,
    74: 179000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1134000
  },
  1910: {
    0: 2203000,
    1: 2168000,
    2: 2135000,
    3: 2101000,
    4: 2064000,
    5: 2029000,
    6: 1993000,
    7: 1959000,
    8: 1926000,
    9: 1896000,
    10: 1867000,
    11: 1839000,
    12: 1818000,
    13: 1811000,
    14: 1812000,
    15: 1812000,
    16: 1813000,
    17: 1815000,
    18: 1823000,
    19: 1832000,
    20: 1839000,
    21: 1846000,
    22: 1841000,
    23: 1815000,
    24: 1776000,
    25: 1737000,
    26: 1699000,
    27: 1655000,
    28: 1603000,
    29: 1549000,
    30: 1494000,
    31: 1433000,
    32: 1387000,
    33: 1364000,
    34: 1353000,
    35: 1341000,
    36: 1332000,
    37: 1309000,
    38: 1264000,
    39: 1207000,
    40: 1154000,
    41: 1099000,
    42: 1052000,
    43: 1015000,
    44: 986000,
    45: 956000,
    46: 924000,
    47: 897000,
    48: 878000,
    49: 861000,
    50: 843000,
    51: 830000,
    52: 804000,
    53: 759000,
    54: 702000,
    55: 647000,
    56: 591000,
    57: 547000,
    58: 521000,
    59: 507000,
    60: 493000,
    61: 480000,
    62: 464000,
    63: 439000,
    64: 412000,
    65: 387000,
    66: 362000,
    67: 338000,
    68: 315000,
    69: 290000,
    70: 267000,
    71: 245000,
    72: 224000,
    73: 203000,
    74: 185000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1170000
  },
  1911: {
    0: 2227000,
    1: 2194000,
    2: 2161000,
    3: 2125000,
    4: 2089000,
    5: 2055000,
    6: 2019000,
    7: 1985000,
    8: 1952000,
    9: 1923000,
    10: 1895000,
    11: 1867000,
    12: 1846000,
    13: 1837000,
    14: 1835000,
    15: 1831000,
    16: 1826000,
    17: 1827000,
    18: 1836000,
    19: 1843000,
    20: 1851000,
    21: 1859000,
    22: 1854000,
    23: 1832000,
    24: 1796000,
    25: 1760000,
    26: 1724000,
    27: 1681000,
    28: 1630000,
    29: 1576000,
    30: 1520000,
    31: 1458000,
    32: 1411000,
    33: 1389000,
    34: 1381000,
    35: 1370000,
    36: 1362000,
    37: 1340000,
    38: 1294000,
    39: 1232000,
    40: 1176000,
    41: 1118000,
    42: 1069000,
    43: 1034000,
    44: 1008000,
    45: 979000,
    46: 949000,
    47: 922000,
    48: 901000,
    49: 882000,
    50: 863000,
    51: 849000,
    52: 821000,
    53: 774000,
    54: 717000,
    55: 662000,
    56: 605000,
    57: 559000,
    58: 533000,
    59: 521000,
    60: 507000,
    61: 494000,
    62: 477000,
    63: 452000,
    64: 424000,
    65: 396000,
    66: 371000,
    67: 347000,
    68: 322000,
    69: 296000,
    70: 273000,
    71: 250000,
    72: 228000,
    73: 207000,
    74: 188000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1196000
  },
  1912: {
    0: 2250000,
    1: 2217000,
    2: 2184000,
    3: 2149000,
    4: 2115000,
    5: 2080000,
    6: 2047000,
    7: 2013000,
    8: 1984000,
    9: 1953000,
    10: 1926000,
    11: 1899000,
    12: 1878000,
    13: 1865000,
    14: 1858000,
    15: 1849000,
    16: 1841000,
    17: 1840000,
    18: 1844000,
    19: 1854000,
    20: 1861000,
    21: 1869000,
    22: 1864000,
    23: 1844000,
    24: 1811000,
    25: 1780000,
    26: 1746000,
    27: 1707000,
    28: 1657000,
    29: 1601000,
    30: 1544000,
    31: 1482000,
    32: 1435000,
    33: 1413000,
    34: 1407000,
    35: 1398000,
    36: 1392000,
    37: 1370000,
    38: 1322000,
    39: 1260000,
    40: 1200000,
    41: 1139000,
    42: 1087000,
    43: 1054000,
    44: 1030000,
    45: 1003000,
    46: 975000,
    47: 950000,
    48: 928000,
    49: 906000,
    50: 885000,
    51: 867000,
    52: 838000,
    53: 791000,
    54: 732000,
    55: 677000,
    56: 618000,
    57: 573000,
    58: 546000,
    59: 536000,
    60: 521000,
    61: 508000,
    62: 492000,
    63: 466000,
    64: 435000,
    65: 407000,
    66: 379000,
    67: 354000,
    68: 326000,
    69: 302000,
    70: 277000,
    71: 253000,
    72: 234000,
    73: 213000,
    74: 197000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1227000
  },
  1913: {
    0: 2279000,
    1: 2249000,
    2: 2217000,
    3: 2185000,
    4: 2152000,
    5: 2120000,
    6: 2088000,
    7: 2055000,
    8: 2026000,
    9: 1996000,
    10: 1968000,
    11: 1943000,
    12: 1919000,
    13: 1901000,
    14: 1888000,
    15: 1875000,
    16: 1860000,
    17: 1854000,
    18: 1859000,
    19: 1868000,
    20: 1875000,
    21: 1883000,
    22: 1880000,
    23: 1862000,
    24: 1833000,
    25: 1804000,
    26: 1774000,
    27: 1738000,
    28: 1687000,
    29: 1631000,
    30: 1574000,
    31: 1512000,
    32: 1465000,
    33: 1445000,
    34: 1440000,
    35: 1433000,
    36: 1429000,
    37: 1407000,
    38: 1358000,
    39: 1293000,
    40: 1229000,
    41: 1165000,
    42: 1113000,
    43: 1078000,
    44: 1057000,
    45: 1033000,
    46: 1007000,
    47: 982000,
    48: 958000,
    49: 933000,
    50: 911000,
    51: 890000,
    52: 858000,
    53: 811000,
    54: 752000,
    55: 696000,
    56: 636000,
    57: 591000,
    58: 564000,
    59: 553000,
    60: 538000,
    61: 526000,
    62: 508000,
    63: 481000,
    64: 449000,
    65: 419000,
    66: 390000,
    67: 362000,
    68: 335000,
    69: 309000,
    70: 283000,
    71: 259000,
    72: 239000,
    73: 219000,
    74: 203000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1263000
  },
  1914: {
    0: 2305000,
    1: 2278000,
    2: 2250000,
    3: 2220000,
    4: 2191000,
    5: 2160000,
    6: 2129000,
    7: 2099000,
    8: 2070000,
    9: 2040000,
    10: 2012000,
    11: 1986000,
    12: 1961000,
    13: 1940000,
    14: 1919000,
    15: 1899000,
    16: 1878000,
    17: 1867000,
    18: 1869000,
    19: 1879000,
    20: 1887000,
    21: 1894000,
    22: 1893000,
    23: 1878000,
    24: 1852000,
    25: 1827000,
    26: 1802000,
    27: 1768000,
    28: 1720000,
    29: 1662000,
    30: 1604000,
    31: 1542000,
    32: 1495000,
    33: 1476000,
    34: 1474000,
    35: 1468000,
    36: 1465000,
    37: 1444000,
    38: 1395000,
    39: 1325000,
    40: 1259000,
    41: 1191000,
    42: 1137000,
    43: 1105000,
    44: 1086000,
    45: 1063000,
    46: 1040000,
    47: 1017000,
    48: 991000,
    49: 962000,
    50: 936000,
    51: 912000,
    52: 878000,
    53: 829000,
    54: 770000,
    55: 712000,
    56: 654000,
    57: 608000,
    58: 583000,
    59: 570000,
    60: 556000,
    61: 542000,
    62: 526000,
    63: 497000,
    64: 463000,
    65: 432000,
    66: 401000,
    67: 371000,
    68: 343000,
    69: 316000,
    70: 290000,
    71: 266000,
    72: 244000,
    73: 226000,
    74: 211000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1301000
  },
  1915: {
    0: 2317000,
    1: 2295000,
    2: 2270000,
    3: 2246000,
    4: 2219000,
    5: 2191000,
    6: 2163000,
    7: 2135000,
    8: 2106000,
    9: 2079000,
    10: 2051000,
    11: 2024000,
    12: 1998000,
    13: 1970000,
    14: 1943000,
    15: 1916000,
    16: 1889000,
    17: 1871000,
    18: 1871000,
    19: 1881000,
    20: 1887000,
    21: 1895000,
    22: 1893000,
    23: 1881000,
    24: 1860000,
    25: 1839000,
    26: 1820000,
    27: 1788000,
    28: 1741000,
    29: 1685000,
    30: 1626000,
    31: 1563000,
    32: 1518000,
    33: 1499000,
    34: 1501000,
    35: 1496000,
    36: 1495000,
    37: 1475000,
    38: 1423000,
    39: 1352000,
    40: 1283000,
    41: 1213000,
    42: 1157000,
    43: 1126000,
    44: 1110000,
    45: 1089000,
    46: 1069000,
    47: 1046000,
    48: 1018000,
    49: 987000,
    50: 956000,
    51: 930000,
    52: 894000,
    53: 844000,
    54: 785000,
    55: 729000,
    56: 669000,
    57: 624000,
    58: 598000,
    59: 586000,
    60: 572000,
    61: 560000,
    62: 541000,
    63: 512000,
    64: 475000,
    65: 443000,
    66: 411000,
    67: 379000,
    68: 348000,
    69: 320000,
    70: 294000,
    71: 270000,
    72: 249000,
    73: 233000,
    74: 220000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1334000
  },
  1916: {
    0: 2325000,
    1: 2308000,
    2: 2291000,
    3: 2270000,
    4: 2248000,
    5: 2223000,
    6: 2199000,
    7: 2172000,
    8: 2146000,
    9: 2118000,
    10: 2088000,
    11: 2063000,
    12: 2034000,
    13: 2000000,
    14: 1965000,
    15: 1932000,
    16: 1896000,
    17: 1872000,
    18: 1869000,
    19: 1880000,
    20: 1886000,
    21: 1893000,
    22: 1894000,
    23: 1884000,
    24: 1866000,
    25: 1851000,
    26: 1835000,
    27: 1807000,
    28: 1762000,
    29: 1704000,
    30: 1647000,
    31: 1583000,
    32: 1539000,
    33: 1523000,
    34: 1525000,
    35: 1523000,
    36: 1524000,
    37: 1505000,
    38: 1452000,
    39: 1379000,
    40: 1308000,
    41: 1236000,
    42: 1179000,
    43: 1148000,
    44: 1134000,
    45: 1116000,
    46: 1099000,
    47: 1077000,
    48: 1046000,
    49: 1012000,
    50: 978000,
    51: 948000,
    52: 910000,
    53: 859000,
    54: 801000,
    55: 745000,
    56: 686000,
    57: 640000,
    58: 615000,
    59: 602000,
    60: 589000,
    61: 576000,
    62: 558000,
    63: 527000,
    64: 488000,
    65: 453000,
    66: 419000,
    67: 388000,
    68: 356000,
    69: 325000,
    70: 298000,
    71: 275000,
    72: 254000,
    73: 238000,
    74: 229000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1368000
  },
  1917: {
    0: 2329000,
    1: 2320000,
    2: 2308000,
    3: 2294000,
    4: 2276000,
    5: 2257000,
    6: 2235000,
    7: 2210000,
    8: 2185000,
    9: 2158000,
    10: 2129000,
    11: 2103000,
    12: 2071000,
    13: 2032000,
    14: 1989000,
    15: 1947000,
    16: 1904000,
    17: 1875000,
    18: 1868000,
    19: 1872000,
    20: 1876000,
    21: 1880000,
    22: 1880000,
    23: 1872000,
    24: 1862000,
    25: 1848000,
    26: 1840000,
    27: 1817000,
    28: 1774000,
    29: 1718000,
    30: 1663000,
    31: 1601000,
    32: 1557000,
    33: 1545000,
    34: 1550000,
    35: 1549000,
    36: 1554000,
    37: 1536000,
    38: 1481000,
    39: 1406000,
    40: 1334000,
    41: 1258000,
    42: 1200000,
    43: 1170000,
    44: 1159000,
    45: 1144000,
    46: 1127000,
    47: 1108000,
    48: 1073000,
    49: 1036000,
    50: 999000,
    51: 966000,
    52: 925000,
    53: 873000,
    54: 817000,
    55: 762000,
    56: 704000,
    57: 658000,
    58: 632000,
    59: 621000,
    60: 606000,
    61: 593000,
    62: 575000,
    63: 542000,
    64: 501000,
    65: 464000,
    66: 430000,
    67: 396000,
    68: 361000,
    69: 332000,
    70: 305000,
    71: 281000,
    72: 261000,
    73: 246000,
    74: 237000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1401000
  },
  1918: {
    0: 2332000,
    1: 2330000,
    2: 2324000,
    3: 2316000,
    4: 2304000,
    5: 2288000,
    6: 2270000,
    7: 2250000,
    8: 2226000,
    9: 2199000,
    10: 2171000,
    11: 2141000,
    12: 2107000,
    13: 2064000,
    14: 2016000,
    15: 1971000,
    16: 1925000,
    17: 1882000,
    18: 1843000,
    19: 1808000,
    20: 1771000,
    21: 1734000,
    22: 1711000,
    23: 1709000,
    24: 1717000,
    25: 1725000,
    26: 1738000,
    27: 1735000,
    28: 1708000,
    29: 1667000,
    30: 1626000,
    31: 1582000,
    32: 1553000,
    33: 1549000,
    34: 1562000,
    35: 1567000,
    36: 1578000,
    37: 1563000,
    38: 1509000,
    39: 1431000,
    40: 1358000,
    41: 1279000,
    42: 1219000,
    43: 1191000,
    44: 1184000,
    45: 1170000,
    46: 1158000,
    47: 1137000,
    48: 1103000,
    49: 1060000,
    50: 1020000,
    51: 983000,
    52: 940000,
    53: 889000,
    54: 833000,
    55: 777000,
    56: 720000,
    57: 674000,
    58: 650000,
    59: 637000,
    60: 623000,
    61: 611000,
    62: 591000,
    63: 558000,
    64: 515000,
    65: 476000,
    66: 440000,
    67: 404000,
    68: 370000,
    69: 337000,
    70: 311000,
    71: 286000,
    72: 267000,
    73: 253000,
    74: 247000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1435000
  },
  1919: {
    0: 2299000,
    1: 2308000,
    2: 2312000,
    3: 2312000,
    4: 2305000,
    5: 2297000,
    6: 2282000,
    7: 2263000,
    8: 2242000,
    9: 2214000,
    10: 2186000,
    11: 2158000,
    12: 2121000,
    13: 2071000,
    14: 2015000,
    15: 1959000,
    16: 1903000,
    17: 1860000,
    18: 1839000,
    19: 1833000,
    20: 1824000,
    21: 1816000,
    22: 1811000,
    23: 1810000,
    24: 1810000,
    25: 1811000,
    26: 1815000,
    27: 1805000,
    28: 1770000,
    29: 1717000,
    30: 1667000,
    31: 1611000,
    32: 1574000,
    33: 1566000,
    34: 1576000,
    35: 1582000,
    36: 1592000,
    37: 1577000,
    38: 1521000,
    39: 1443000,
    40: 1369000,
    41: 1291000,
    42: 1231000,
    43: 1204000,
    44: 1198000,
    45: 1187000,
    46: 1174000,
    47: 1153000,
    48: 1118000,
    49: 1072000,
    50: 1030000,
    51: 990000,
    52: 945000,
    53: 893000,
    54: 840000,
    55: 785000,
    56: 730000,
    57: 685000,
    58: 661000,
    59: 649000,
    60: 634000,
    61: 621000,
    62: 602000,
    63: 567000,
    64: 522000,
    65: 483000,
    66: 444000,
    67: 408000,
    68: 372000,
    69: 341000,
    70: 313000,
    71: 289000,
    72: 271000,
    73: 257000,
    74: 254000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1454000
  },
  1920: {
    0: 2277000,
    1: 2313000,
    2: 2337000,
    3: 2351000,
    4: 2353000,
    5: 2347000,
    6: 2332000,
    7: 2311000,
    8: 2283000,
    9: 2250000,
    10: 2213000,
    11: 2173000,
    12: 2129000,
    13: 2084000,
    14: 2036000,
    15: 1989000,
    16: 1940000,
    17: 1904000,
    18: 1880000,
    19: 1869000,
    20: 1855000,
    21: 1840000,
    22: 1835000,
    23: 1845000,
    24: 1864000,
    25: 1881000,
    26: 1904000,
    27: 1900000,
    28: 1854000,
    29: 1782000,
    30: 1711000,
    31: 1636000,
    32: 1584000,
    33: 1574000,
    34: 1590000,
    35: 1599000,
    36: 1610000,
    37: 1599000,
    38: 1553000,
    39: 1482000,
    40: 1417000,
    41: 1348000,
    42: 1291000,
    43: 1254000,
    44: 1229000,
    45: 1201000,
    46: 1171000,
    47: 1140000,
    48: 1108000,
    49: 1073000,
    50: 1042000,
    51: 1012000,
    52: 973000,
    53: 922000,
    54: 863000,
    55: 806000,
    56: 748000,
    57: 701000,
    58: 676000,
    59: 665000,
    60: 650000,
    61: 639000,
    62: 619000,
    63: 581000,
    64: 534000,
    65: 493000,
    66: 452000,
    67: 413000,
    68: 377000,
    69: 343000,
    70: 315000,
    71: 291000,
    72: 273000,
    73: 263000,
    74: 260000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1449000
  },
  1921: {
    0: 2362000,
    1: 2375000,
    2: 2383000,
    3: 2382000,
    4: 2377000,
    5: 2366000,
    6: 2351000,
    7: 2332000,
    8: 2308000,
    9: 2279000,
    10: 2250000,
    11: 2220000,
    12: 2182000,
    13: 2138000,
    14: 2089000,
    15: 2042000,
    16: 1994000,
    17: 1954000,
    18: 1925000,
    19: 1902000,
    20: 1881000,
    21: 1855000,
    22: 1845000,
    23: 1858000,
    24: 1884000,
    25: 1909000,
    26: 1940000,
    27: 1942000,
    28: 1895000,
    29: 1819000,
    30: 1746000,
    31: 1668000,
    32: 1613000,
    33: 1600000,
    34: 1615000,
    35: 1619000,
    36: 1627000,
    37: 1616000,
    38: 1572000,
    39: 1508000,
    40: 1448000,
    41: 1389000,
    42: 1333000,
    43: 1293000,
    44: 1260000,
    45: 1223000,
    46: 1185000,
    47: 1151000,
    48: 1119000,
    49: 1091000,
    50: 1064000,
    51: 1039000,
    52: 1004000,
    53: 953000,
    54: 892000,
    55: 833000,
    56: 772000,
    57: 725000,
    58: 698000,
    59: 682000,
    60: 665000,
    61: 649000,
    62: 628000,
    63: 591000,
    64: 548000,
    65: 507000,
    66: 469000,
    67: 432000,
    68: 395000,
    69: 362000,
    70: 331000,
    71: 304000,
    72: 281000,
    73: 265000,
    74: 253000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1481000
  },
  1922: {
    0: 2413000,
    1: 2413000,
    2: 2408000,
    3: 2403000,
    4: 2394000,
    5: 2382000,
    6: 2366000,
    7: 2349000,
    8: 2328000,
    9: 2303000,
    10: 2278000,
    11: 2252000,
    12: 2220000,
    13: 2178000,
    14: 2132000,
    15: 2086000,
    16: 2041000,
    17: 2001000,
    18: 1966000,
    19: 1935000,
    20: 1904000,
    21: 1870000,
    22: 1853000,
    23: 1861000,
    24: 1885000,
    25: 1906000,
    26: 1933000,
    27: 1934000,
    28: 1896000,
    29: 1833000,
    30: 1773000,
    31: 1710000,
    32: 1662000,
    33: 1641000,
    34: 1636000,
    35: 1626000,
    36: 1616000,
    37: 1596000,
    38: 1561000,
    39: 1515000,
    40: 1471000,
    41: 1426000,
    42: 1382000,
    43: 1338000,
    44: 1292000,
    45: 1245000,
    46: 1197000,
    47: 1156000,
    48: 1127000,
    49: 1104000,
    50: 1081000,
    51: 1060000,
    52: 1029000,
    53: 979000,
    54: 921000,
    55: 864000,
    56: 807000,
    57: 759000,
    58: 726000,
    59: 701000,
    60: 676000,
    61: 651000,
    62: 623000,
    63: 591000,
    64: 553000,
    65: 518000,
    66: 485000,
    67: 451000,
    68: 418000,
    69: 383000,
    70: 352000,
    71: 322000,
    72: 292000,
    73: 263000,
    74: 235000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1512000
  },
  1923: {
    0: 2433000,
    1: 2430000,
    2: 2426000,
    3: 2420000,
    4: 2410000,
    5: 2401000,
    6: 2387000,
    7: 2372000,
    8: 2352000,
    9: 2332000,
    10: 2309000,
    11: 2286000,
    12: 2256000,
    13: 2218000,
    14: 2176000,
    15: 2133000,
    16: 2093000,
    17: 2052000,
    18: 2015000,
    19: 1981000,
    20: 1947000,
    21: 1910000,
    22: 1888000,
    23: 1885000,
    24: 1894000,
    25: 1901000,
    26: 1910000,
    27: 1908000,
    28: 1886000,
    29: 1853000,
    30: 1821000,
    31: 1788000,
    32: 1755000,
    33: 1721000,
    34: 1688000,
    35: 1654000,
    36: 1615000,
    37: 1582000,
    38: 1552000,
    39: 1526000,
    40: 1499000,
    41: 1473000,
    42: 1439000,
    43: 1391000,
    44: 1335000,
    45: 1281000,
    46: 1224000,
    47: 1176000,
    48: 1142000,
    49: 1118000,
    50: 1092000,
    51: 1066000,
    52: 1034000,
    53: 992000,
    54: 943000,
    55: 896000,
    56: 850000,
    57: 805000,
    58: 765000,
    59: 729000,
    60: 692000,
    61: 655000,
    62: 621000,
    63: 590000,
    64: 562000,
    65: 532000,
    66: 503000,
    67: 473000,
    68: 443000,
    69: 411000,
    70: 377000,
    71: 342000,
    72: 303000,
    73: 263000,
    74: 218000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1546000
  },
  1924: {
    0: 2476000,
    1: 2465000,
    2: 2454000,
    3: 2443000,
    4: 2431000,
    5: 2419000,
    6: 2404000,
    7: 2389000,
    8: 2373000,
    9: 2354000,
    10: 2335000,
    11: 2315000,
    12: 2290000,
    13: 2257000,
    14: 2222000,
    15: 2185000,
    16: 2149000,
    17: 2113000,
    18: 2076000,
    19: 2040000,
    20: 2005000,
    21: 1970000,
    22: 1941000,
    23: 1923000,
    24: 1912000,
    25: 1901000,
    26: 1888000,
    27: 1880000,
    28: 1874000,
    29: 1872000,
    30: 1869000,
    31: 1868000,
    32: 1850000,
    33: 1807000,
    34: 1748000,
    35: 1690000,
    36: 1627000,
    37: 1579000,
    38: 1554000,
    39: 1542000,
    40: 1527000,
    41: 1513000,
    42: 1486000,
    43: 1439000,
    44: 1380000,
    45: 1322000,
    46: 1264000,
    47: 1213000,
    48: 1172000,
    49: 1140000,
    50: 1105000,
    51: 1070000,
    52: 1035000,
    53: 997000,
    54: 960000,
    55: 923000,
    56: 887000,
    57: 849000,
    58: 805000,
    59: 757000,
    60: 712000,
    61: 664000,
    62: 623000,
    63: 594000,
    64: 573000,
    65: 547000,
    66: 521000,
    67: 494000,
    68: 466000,
    69: 435000,
    70: 401000,
    71: 362000,
    72: 317000,
    73: 268000,
    74: 211000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1587000
  },
  1925: {
    0: 2460000,
    1: 2465000,
    2: 2467000,
    3: 2465000,
    4: 2459000,
    5: 2449000,
    6: 2436000,
    7: 2420000,
    8: 2401000,
    9: 2381000,
    10: 2358000,
    11: 2333000,
    12: 2307000,
    13: 2279000,
    14: 2250000,
    15: 2222000,
    16: 2193000,
    17: 2160000,
    18: 2124000,
    19: 2085000,
    20: 2047000,
    21: 2012000,
    22: 1978000,
    23: 1947000,
    24: 1923000,
    25: 1896000,
    26: 1866000,
    27: 1852000,
    28: 1859000,
    29: 1877000,
    30: 1893000,
    31: 1915000,
    32: 1909000,
    33: 1863000,
    34: 1790000,
    35: 1720000,
    36: 1644000,
    37: 1588000,
    38: 1564000,
    39: 1560000,
    40: 1551000,
    41: 1540000,
    42: 1518000,
    43: 1474000,
    44: 1417000,
    45: 1363000,
    46: 1309000,
    47: 1256000,
    48: 1209000,
    49: 1167000,
    50: 1123000,
    51: 1079000,
    52: 1039000,
    53: 1003000,
    54: 973000,
    55: 944000,
    56: 916000,
    57: 882000,
    58: 837000,
    59: 783000,
    60: 733000,
    61: 681000,
    62: 637000,
    63: 607000,
    64: 585000,
    65: 562000,
    66: 536000,
    67: 509000,
    68: 481000,
    69: 449000,
    70: 415000,
    71: 376000,
    72: 329000,
    73: 279000,
    74: 219000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1631000
  },
  1926: {
    0: 2366000,
    1: 2414000,
    2: 2451000,
    3: 2473000,
    4: 2485000,
    5: 2486000,
    6: 2479000,
    7: 2464000,
    8: 2444000,
    9: 2417000,
    10: 2386000,
    11: 2350000,
    12: 2315000,
    13: 2292000,
    14: 2273000,
    15: 2252000,
    16: 2229000,
    17: 2202000,
    18: 2166000,
    19: 2124000,
    20: 2085000,
    21: 2049000,
    22: 2012000,
    23: 1976000,
    24: 1942000,
    25: 1911000,
    26: 1873000,
    27: 1854000,
    28: 1862000,
    29: 1887000,
    30: 1909000,
    31: 1935000,
    32: 1934000,
    33: 1888000,
    34: 1814000,
    35: 1743000,
    36: 1667000,
    37: 1611000,
    38: 1588000,
    39: 1586000,
    40: 1578000,
    41: 1570000,
    42: 1548000,
    43: 1506000,
    44: 1450000,
    45: 1396000,
    46: 1343000,
    47: 1289000,
    48: 1242000,
    49: 1196000,
    50: 1149000,
    51: 1100000,
    52: 1056000,
    53: 1021000,
    54: 994000,
    55: 965000,
    56: 940000,
    57: 905000,
    58: 858000,
    59: 805000,
    60: 752000,
    61: 700000,
    62: 655000,
    63: 624000,
    64: 601000,
    65: 576000,
    66: 548000,
    67: 520000,
    68: 491000,
    69: 461000,
    70: 426000,
    71: 388000,
    72: 343000,
    73: 294000,
    74: 236000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1677000
  },
  1927: {
    0: 2305000,
    1: 2383000,
    2: 2441000,
    3: 2480000,
    4: 2502000,
    5: 2512000,
    6: 2509000,
    7: 2496000,
    8: 2474000,
    9: 2445000,
    10: 2410000,
    11: 2366000,
    12: 2330000,
    13: 2311000,
    14: 2299000,
    15: 2283000,
    16: 2267000,
    17: 2244000,
    18: 2210000,
    19: 2168000,
    20: 2128000,
    21: 2090000,
    22: 2052000,
    23: 2013000,
    24: 1975000,
    25: 1937000,
    26: 1897000,
    27: 1872000,
    28: 1875000,
    29: 1892000,
    30: 1907000,
    31: 1926000,
    32: 1925000,
    33: 1888000,
    34: 1829000,
    35: 1774000,
    36: 1714000,
    37: 1668000,
    38: 1641000,
    39: 1627000,
    40: 1608000,
    41: 1588000,
    42: 1560000,
    43: 1520000,
    44: 1472000,
    45: 1426000,
    46: 1379000,
    47: 1332000,
    48: 1281000,
    49: 1231000,
    50: 1179000,
    51: 1127000,
    52: 1080000,
    53: 1043000,
    54: 1014000,
    55: 983000,
    56: 955000,
    57: 918000,
    58: 874000,
    59: 824000,
    60: 775000,
    61: 725000,
    62: 681000,
    63: 648000,
    64: 620000,
    65: 590000,
    66: 558000,
    67: 528000,
    68: 497000,
    69: 466000,
    70: 430000,
    71: 395000,
    72: 355000,
    73: 312000,
    74: 267000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1729000
  },
  1928: {
    0: 2251000,
    1: 2345000,
    2: 2416000,
    3: 2466000,
    4: 2500000,
    5: 2515000,
    6: 2518000,
    7: 2509000,
    8: 2490000,
    9: 2464000,
    10: 2429000,
    11: 2385000,
    12: 2351000,
    13: 2334000,
    14: 2325000,
    15: 2313000,
    16: 2298000,
    17: 2277000,
    18: 2245000,
    19: 2206000,
    20: 2171000,
    21: 2134000,
    22: 2097000,
    23: 2056000,
    24: 2014000,
    25: 1973000,
    26: 1931000,
    27: 1900000,
    28: 1889000,
    29: 1891000,
    30: 1892000,
    31: 1891000,
    32: 1885000,
    33: 1865000,
    34: 1836000,
    35: 1808000,
    36: 1781000,
    37: 1750000,
    38: 1715000,
    39: 1678000,
    40: 1639000,
    41: 1599000,
    42: 1560000,
    43: 1523000,
    44: 1487000,
    45: 1451000,
    46: 1416000,
    47: 1374000,
    48: 1324000,
    49: 1270000,
    50: 1217000,
    51: 1162000,
    52: 1111000,
    53: 1071000,
    54: 1034000,
    55: 997000,
    56: 960000,
    57: 923000,
    58: 882000,
    59: 838000,
    60: 796000,
    61: 753000,
    62: 714000,
    63: 676000,
    64: 639000,
    65: 604000,
    66: 567000,
    67: 533000,
    68: 498000,
    69: 465000,
    70: 432000,
    71: 400000,
    72: 369000,
    73: 337000,
    74: 307000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1787000
  },
  1929: {
    0: 2142000,
    1: 2274000,
    2: 2374000,
    3: 2447000,
    4: 2497000,
    5: 2526000,
    6: 2535000,
    7: 2529000,
    8: 2512000,
    9: 2485000,
    10: 2446000,
    11: 2398000,
    12: 2358000,
    13: 2342000,
    14: 2339000,
    15: 2330000,
    16: 2316000,
    17: 2298000,
    18: 2273000,
    19: 2240000,
    20: 2209000,
    21: 2179000,
    22: 2143000,
    23: 2103000,
    24: 2060000,
    25: 2018000,
    26: 1974000,
    27: 1937000,
    28: 1910000,
    29: 1890000,
    30: 1870000,
    31: 1847000,
    32: 1832000,
    33: 1829000,
    34: 1834000,
    35: 1837000,
    36: 1843000,
    37: 1829000,
    38: 1791000,
    39: 1732000,
    40: 1677000,
    41: 1617000,
    42: 1565000,
    43: 1529000,
    44: 1501000,
    45: 1470000,
    46: 1440000,
    47: 1403000,
    48: 1357000,
    49: 1306000,
    50: 1254000,
    51: 1207000,
    52: 1156000,
    53: 1108000,
    54: 1060000,
    55: 1013000,
    56: 964000,
    57: 919000,
    58: 882000,
    59: 847000,
    60: 811000,
    61: 777000,
    62: 742000,
    63: 701000,
    64: 659000,
    65: 617000,
    66: 578000,
    67: 540000,
    68: 503000,
    69: 470000,
    70: 436000,
    71: 406000,
    72: 380000,
    73: 354000,
    74: 336000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1854000
  },
  1930: {
    0: 2181398,
    1: 2164397,
    2: 2272951,
    3: 2375736,
    4: 2377633,
    5: 2489604,
    6: 2512951,
    7: 2482957,
    8: 2569834,
    9: 2534824,
    10: 2472639,
    11: 2430418,
    12: 2396985,
    13: 2375924,
    14: 2364654,
    15: 2351648,
    16: 2335712,
    17: 2317496,
    18: 2295997,
    19: 2271276,
    20: 2245650,
    21: 2220398,
    22: 2190420,
    23: 2151904,
    24: 2106848,
    25: 2061558,
    26: 2017439,
    27: 1975370,
    28: 1936950,
    29: 1902713,
    30: 1869017,
    31: 1832401,
    32: 1808190,
    33: 1808434,
    34: 1826764,
    35: 1846872,
    36: 1869177,
    37: 1874189,
    38: 1843703,
    39: 1784523,
    40: 1721420,
    41: 1656726,
    42: 1597764,
    43: 1553910,
    44: 1522279,
    45: 1489630,
    46: 1454197,
    47: 1417238,
    48: 1378010,
    49: 1336549,
    50: 1295061,
    51: 1254791,
    52: 1210584,
    53: 1158516,
    54: 1100655,
    55: 1042234,
    56: 983123,
    57: 929633,
    58: 887636,
    59: 854905,
    60: 823587,
    61: 793172,
    62: 760527,
    63: 722084,
    64: 679137,
    65: 636589,
    66: 594731,
    67: 555171,
    68: 519894,
    69: 488113,
    70: 457195,
    71: 427255,
    72: 396062,
    73: 361419,
    74: 324337,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1945053
  },
  1931: {
    0: 2138681,
    1: 2140300,
    2: 2225565,
    3: 2300649,
    4: 2374212,
    5: 2444300,
    6: 2480998,
    7: 2505440,
    8: 2480593,
    9: 2557405,
    10: 2522846,
    11: 2463316,
    12: 2421068,
    13: 2387124,
    14: 2365836,
    15: 2354622,
    16: 2341673,
    17: 2325848,
    18: 2307372,
    19: 2284853,
    20: 2258530,
    21: 2231227,
    22: 2204264,
    23: 2173482,
    24: 2135900,
    25: 2093086,
    26: 2050165,
    27: 2008335,
    28: 1968174,
    29: 1930868,
    30: 1896973,
    31: 1863544,
    32: 1827400,
    33: 1802673,
    34: 1800580,
    35: 1815328,
    36: 1831577,
    37: 1849712,
    38: 1852003,
    39: 1821808,
    40: 1765261,
    41: 1705029,
    42: 1643354,
    43: 1586237,
    44: 1541659,
    45: 1507260,
    46: 1471699,
    47: 1433448,
    48: 1394170,
    49: 1353779,
    50: 1312221,
    51: 1270700,
    52: 1230260,
    53: 1186554,
    54: 1136234,
    55: 1081000,
    56: 1025437,
    57: 969449,
    58: 918022,
    59: 875870,
    60: 841186,
    61: 807603,
    62: 774927,
    63: 740343,
    64: 700937,
    65: 657852,
    66: 615103,
    67: 572915,
    68: 532994,
    69: 497324,
    70: 465200,
    71: 434011,
    72: 403826,
    73: 372673,
    74: 338502,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2038279
  },
  1932: {
    0: 2040919,
    1: 2101784,
    2: 2208897,
    3: 2250398,
    4: 2301105,
    5: 2435190,
    6: 2436210,
    7: 2472188,
    8: 2497622,
    9: 2477759,
    10: 2544521,
    11: 2510332,
    12: 2453347,
    13: 2410850,
    14: 2376177,
    15: 2354569,
    16: 2343380,
    17: 2330480,
    18: 2314809,
    19: 2296173,
    20: 2272673,
    21: 2244739,
    22: 2215865,
    23: 2187348,
    24: 2155910,
    25: 2119286,
    26: 2078713,
    27: 2038119,
    28: 1998540,
    29: 1960268,
    30: 1924044,
    31: 1890488,
    32: 1857301,
    33: 1821514,
    34: 1796148,
    35: 1791757,
    36: 1802901,
    37: 1815361,
    38: 1829430,
    39: 1829088,
    40: 1799179,
    41: 1745185,
    42: 1687721,
    43: 1628947,
    44: 1573576,
    45: 1528258,
    46: 1491096,
    47: 1452662,
    48: 1411618,
    49: 1370057,
    50: 1328538,
    51: 1286858,
    52: 1245344,
    53: 1204789,
    54: 1161653,
    55: 1113032,
    56: 1060360,
    57: 1007524,
    58: 954404,
    59: 904821,
    60: 862443,
    61: 825835,
    62: 790060,
    63: 755243,
    64: 718847,
    65: 678544,
    66: 635272,
    67: 592326,
    68: 549842,
    69: 509616,
    70: 473635,
    71: 441222,
    72: 409821,
    73: 379500,
    74: 348459,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2127981
  },
  1933: {
    0: 1937890,
    1: 2011141,
    2: 2183375,
    3: 2229269,
    4: 2250477,
    5: 2365010,
    6: 2424948,
    7: 2429945,
    8: 2465138,
    9: 2491440,
    10: 2476490,
    11: 2533211,
    12: 2499260,
    13: 2444536,
    14: 2401537,
    15: 2366022,
    16: 2344056,
    17: 2332876,
    18: 2320061,
    19: 2304623,
    20: 2285833,
    21: 2261319,
    22: 2231855,
    23: 2201538,
    24: 2171599,
    25: 2139522,
    26: 2103838,
    27: 2065435,
    28: 2027095,
    29: 1989714,
    30: 1953259,
    31: 1918075,
    32: 1884798,
    33: 1851714,
    34: 1816121,
    35: 1790123,
    36: 1783399,
    37: 1791014,
    38: 1799795,
    39: 1809909,
    40: 1806959,
    41: 1777259,
    42: 1725671,
    43: 1670814,
    44: 1614775,
    45: 1561040,
    46: 1514915,
    47: 1474993,
    48: 1433674,
    49: 1389849,
    50: 1346036,
    51: 1303359,
    52: 1261557,
    53: 1220073,
    54: 1179452,
    55: 1136836,
    56: 1089838,
    57: 1039561,
    58: 989167,
    59: 938621,
    60: 890694,
    61: 848014,
    62: 809506,
    63: 771624,
    64: 734772,
    65: 696639,
    66: 655393,
    67: 611895,
    68: 568744,
    69: 525988,
    70: 485519,
    71: 449271,
    72: 416612,
    73: 385091,
    74: 354694,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2212598
  },
  1934: {
    0: 1874566,
    1: 1917215,
    2: 2107742,
    3: 2204350,
    4: 2226918,
    5: 2314488,
    6: 2360580,
    7: 2418028,
    8: 2426895,
    9: 2461216,
    10: 2488388,
    11: 2478235,
    12: 2524902,
    13: 2490904,
    14: 2438135,
    15: 2394491,
    16: 2358069,
    17: 2335716,
    18: 2324571,
    19: 2311912,
    20: 2296708,
    21: 2277711,
    22: 2252239,
    23: 2221351,
    24: 2189700,
    25: 2158347,
    26: 2125615,
    27: 2090799,
    28: 2054477,
    29: 2018315,
    30: 1983039,
    31: 1948327,
    32: 1914096,
    33: 1880940,
    34: 1847771,
    35: 1812339,
    36: 1785632,
    37: 1776617,
    38: 1780802,
    39: 1786003,
    40: 1792212,
    41: 1786626,
    42: 1757015,
    43: 1707653,
    44: 1655208,
    45: 1601747,
    46: 1549502,
    47: 1502491,
    48: 1459762,
    49: 1415542,
    50: 1368941,
    51: 1322842,
    52: 1279010,
    53: 1237089,
    54: 1195651,
    55: 1154909,
    56: 1112756,
    57: 1067220,
    58: 1019038,
    59: 970775,
    60: 922554,
    61: 876093,
    62: 833032,
    63: 792665,
    64: 752753,
    65: 713921,
    66: 674017,
    67: 631809,
    68: 588043,
    69: 544668,
    70: 501675,
    71: 460993,
    72: 424519,
    73: 391695,
    74: 360103,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2291095
  },
  1935: {
    0: 1951444,
    1: 1862375,
    2: 2027696,
    3: 2127788,
    4: 2201074,
    5: 2285507,
    6: 2309343,
    7: 2357112,
    8: 2412005,
    9: 2424601,
    10: 2458035,
    11: 2486000,
    12: 2480517,
    13: 2516918,
    14: 2482624,
    15: 2431698,
    16: 2387374,
    17: 2350041,
    18: 2327340,
    19: 2316318,
    20: 2303828,
    21: 2288818,
    22: 2269685,
    23: 2243381,
    24: 2211208,
    25: 2178258,
    26: 2145509,
    27: 2112105,
    28: 2078118,
    29: 2043844,
    30: 2009807,
    31: 1976593,
    32: 1943565,
    33: 1910146,
    34: 1876952,
    35: 1843703,
    36: 1808375,
    37: 1780985,
    38: 1769750,
    39: 1770586,
    40: 1772232,
    41: 1774524,
    42: 1766251,
    43: 1736632,
    44: 1689360,
    45: 1639233,
    46: 1588237,
    47: 1537402,
    48: 1489459,
    49: 1443929,
    50: 1396845,
    51: 1347485,
    52: 1299172,
    53: 1254256,
    54: 1212273,
    55: 1170864,
    56: 1130002,
    57: 1088230,
    58: 1043935,
    59: 997598,
    60: 951288,
    61: 905222,
    62: 860106,
    63: 816660,
    64: 774527,
    65: 732667,
    66: 691864,
    67: 650248,
    68: 607111,
    69: 563102,
    70: 519567,
    71: 476412,
    72: 435607,
    73: 399041,
    74: 366139,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2361726
  },
  1936: {
    0: 1944835,
    1: 1942228,
    2: 1987308,
    3: 2046505,
    4: 2122916,
    5: 2256622,
    6: 2275046,
    7: 2303852,
    8: 2353194,
    9: 2405393,
    10: 2421680,
    11: 2454135,
    12: 2482768,
    13: 2481706,
    14: 2507599,
    15: 2472922,
    16: 2423825,
    17: 2378845,
    18: 2340668,
    19: 2317717,
    20: 2306833,
    21: 2294488,
    22: 2279746,
    23: 2260608,
    24: 2233621,
    25: 2200215,
    26: 2166000,
    27: 2131860,
    28: 2097780,
    29: 2064625,
    30: 2032364,
    31: 2000430,
    32: 1969238,
    33: 1937761,
    34: 1905006,
    35: 1871791,
    36: 1838425,
    37: 1803236,
    38: 1775230,
    39: 1761848,
    40: 1759353,
    41: 1757429,
    42: 1755769,
    43: 1744757,
    44: 1715040,
    45: 1669796,
    46: 1621905,
    47: 1573305,
    48: 1523814,
    49: 1474928,
    50: 1426659,
    51: 1376748,
    52: 1324738,
    53: 1274342,
    54: 1228457,
    55: 1186424,
    56: 1145057,
    57: 1104037,
    58: 1062488,
    59: 1019238,
    60: 974602,
    61: 930119,
    62: 886084,
    63: 842248,
    64: 798451,
    65: 754639,
    66: 710859,
    67: 668168,
    68: 624947,
    69: 580975,
    70: 536807,
    71: 493200,
    72: 449999,
    73: 409244,
    74: 372712,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2424973
  },
  1937: {
    0: 1932237,
    1: 1948567,
    2: 2079031,
    3: 2007264,
    4: 2041574,
    5: 2176641,
    6: 2245185,
    7: 2264960,
    8: 2298622,
    9: 2349413,
    10: 2398903,
    11: 2418789,
    12: 2450155,
    13: 2479212,
    14: 2482314,
    15: 2497614,
    16: 2462532,
    17: 2415265,
    18: 2369686,
    19: 2330760,
    20: 2307580,
    21: 2296809,
    22: 2284673,
    23: 2270319,
    24: 2251310,
    25: 2223677,
    26: 2189057,
    27: 2153576,
    28: 2118045,
    29: 2083292,
    30: 2050939,
    31: 2020656,
    32: 1990773,
    33: 1961453,
    34: 1931357,
    35: 1899263,
    36: 1865981,
    37: 1832529,
    38: 1797531,
    39: 1768960,
    40: 1753435,
    41: 1747594,
    42: 1742060,
    43: 1736411,
    44: 1722595,
    45: 1692734,
    46: 1649422,
    47: 1603688,
    48: 1557382,
    49: 1509160,
    50: 1459332,
    51: 1408340,
    52: 1355696,
    53: 1301164,
    54: 1248823,
    55: 1201999,
    56: 1159924,
    57: 1118544,
    58: 1077214,
    59: 1035710,
    60: 993380,
    61: 950324,
    62: 907546,
    63: 865428,
    64: 822800,
    65: 778645,
    66: 733145,
    67: 687512,
    68: 643039,
    69: 598334,
    70: 553650,
    71: 509397,
    72: 465808,
    73: 422728,
    74: 382158,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2483204
  },
  1938: {
    0: 2018652,
    1: 1942637,
    2: 2108385,
    3: 2099909,
    4: 2006846,
    5: 2096956,
    6: 2167475,
    7: 2236930,
    8: 2257979,
    9: 2296412,
    10: 2348694,
    11: 2395459,
    12: 2418852,
    13: 2448931,
    14: 2478197,
    15: 2485347,
    16: 2490059,
    17: 2454527,
    18: 2409083,
    19: 2362946,
    20: 2323249,
    21: 2299790,
    22: 2289185,
    23: 2277365,
    24: 2263509,
    25: 2244632,
    26: 2216331,
    27: 2180449,
    28: 2143653,
    29: 2106690,
    30: 2071210,
    31: 2039596,
    32: 2011214,
    33: 1983198,
    34: 1955539,
    35: 1926774,
    36: 1895241,
    37: 1861881,
    38: 1828355,
    39: 1793551,
    40: 1764365,
    41: 1746643,
    42: 1737401,
    43: 1728204,
    44: 1718503,
    45: 1701859,
    46: 1671754,
    47: 1630247,
    48: 1586517,
    49: 1542351,
    50: 1495284,
    51: 1444417,
    52: 1390721,
    53: 1335400,
    54: 1278432,
    55: 1224144,
    56: 1176369,
    57: 1134141,
    58: 1092535,
    59: 1050686,
    60: 1009094,
    61: 967547,
    62: 925906,
    63: 884694,
    64: 844342,
    65: 802759,
    66: 758083,
    67: 710885,
    68: 663756,
    69: 617255,
    70: 571186,
    71: 525853,
    72: 481552,
    73: 438083,
    74: 395210,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2543073
  },
  1939: {
    0: 2041014,
    1: 2025350,
    2: 2115730,
    3: 2136944,
    4: 2099288,
    5: 2064071,
    6: 2089083,
    7: 2159685,
    8: 2229989,
    9: 2252207,
    10: 2295409,
    11: 2349128,
    12: 2393080,
    13: 2419751,
    14: 2448310,
    15: 2477691,
    16: 2488849,
    17: 2482975,
    18: 2447033,
    19: 2403479,
    20: 2356786,
    21: 2316287,
    22: 2292607,
    23: 2282282,
    24: 2270901,
    25: 2257566,
    26: 2238816,
    27: 2209819,
    28: 2172653,
    29: 2134523,
    30: 2096113,
    31: 2059880,
    32: 2028961,
    33: 2002319,
    34: 1975986,
    35: 1949961,
    36: 1922451,
    37: 1891487,
    38: 1858088,
    39: 1823847,
    40: 1789868,
    41: 1760001,
    42: 1740005,
    43: 1727301,
    44: 1714369,
    45: 1700614,
    46: 1681102,
    47: 1650699,
    48: 1610895,
    49: 1569058,
    50: 1526940,
    51: 1480916,
    52: 1429012,
    53: 1372699,
    54: 1314826,
    55: 1255463,
    56: 1199284,
    57: 1150514,
    58: 1107957,
    59: 1065941,
    60: 1023492,
    61: 981752,
    62: 940898,
    63: 900591,
    64: 860846,
    65: 822091,
    66: 781324,
    67: 736053,
    68: 687206,
    69: 638391,
    70: 590314,
    71: 543033,
    72: 497173,
    73: 452961,
    74: 409724,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2606006
  },
  1940: {
    0: 2025235,
    1: 2057692,
    2: 2219308,
    3: 2137535,
    4: 2138873,
    5: 2106522,
    6: 2100480,
    7: 2114510,
    8: 2143466,
    9: 2183319,
    10: 2241070,
    11: 2298723,
    12: 2353015,
    13: 2395435,
    14: 2426715,
    15: 2448196,
    16: 2476570,
    17: 2491660,
    18: 2480070,
    19: 2446141,
    20: 2407683,
    21: 2364954,
    22: 2326145,
    23: 2301974,
    24: 2289605,
    25: 2277467,
    26: 2260929,
    27: 2239393,
    28: 2208515,
    29: 2169957,
    30: 2128372,
    31: 2089068,
    32: 2052711,
    33: 2022480,
    34: 1997057,
    35: 1975136,
    36: 1949822,
    37: 1922654,
    38: 1891509,
    39: 1857554,
    40: 1823210,
    41: 1788406,
    42: 1757727,
    43: 1735689,
    44: 1719959,
    45: 1701919,
    46: 1684634,
    47: 1662540,
    48: 1631640,
    49: 1592999,
    50: 1549138,
    51: 1508129,
    52: 1462569,
    53: 1409733,
    54: 1351317,
    55: 1292752,
    56: 1231634,
    57: 1174016,
    58: 1124213,
    59: 1080883,
    60: 1041845,
    61: 998690,
    62: 956573,
    63: 916269,
    64: 877311,
    65: 838519,
    66: 801110,
    67: 761108,
    68: 715537,
    69: 665506,
    70: 612254,
    71: 563229,
    72: 515356,
    73: 469160,
    74: 424908,
    75: 381099,
    76: 339378,
    77: 300292,
    78: 263718,
    79: 229989,
    80: 201032,
    81: 174420,
    82: 151706,
    83: 133226,
    84: 119309,
    85: 370275,
    "75+": 0
  },
  1941: {
    0: 2166858,
    1: 2082260,
    2: 2232740,
    3: 2231007,
    4: 2136767,
    5: 2172236,
    6: 2077259,
    7: 2069053,
    8: 2086545,
    9: 2117947,
    10: 2201186,
    11: 2261364,
    12: 2318355,
    13: 2370687,
    14: 2414581,
    15: 2426872,
    16: 2439439,
    17: 2461490,
    18: 2475853,
    19: 2463655,
    20: 2429716,
    21: 2398194,
    22: 2359656,
    23: 2321684,
    24: 2298088,
    25: 2295320,
    26: 2279029,
    27: 2260266,
    28: 2237260,
    29: 2205642,
    30: 2156831,
    31: 2116650,
    32: 2078727,
    33: 2044786,
    34: 2016200,
    35: 2002209,
    36: 1977193,
    37: 1950120,
    38: 1920916,
    39: 1888269,
    40: 1853375,
    41: 1821509,
    42: 1787306,
    43: 1756818,
    44: 1734357,
    45: 1717046,
    46: 1697571,
    47: 1678541,
    48: 1654870,
    49: 1623007,
    50: 1568909,
    51: 1523449,
    52: 1480907,
    53: 1434188,
    54: 1380888,
    55: 1322947,
    56: 1264708,
    57: 1204243,
    58: 1147325,
    59: 1097534,
    60: 1067457,
    61: 1028621,
    62: 984993,
    63: 941576,
    64: 899735,
    65: 858629,
    66: 819065,
    67: 780071,
    68: 738749,
    69: 692428,
    70: 631858,
    71: 577512,
    72: 528432,
    73: 481695,
    74: 437344,
    75: 395207,
    76: 348803,
    77: 307707,
    78: 270692,
    79: 235879,
    80: 208346,
    81: 181087,
    82: 156374,
    83: 135132,
    84: 117686,
    85: 384985,
    "75+": 0
  },
  1942: {
    0: 2325004,
    1: 2198704,
    2: 2261296,
    3: 2271410,
    4: 2245082,
    5: 2149276,
    6: 2145282,
    7: 2039242,
    8: 2028925,
    9: 2049676,
    10: 2155174,
    11: 2226745,
    12: 2288680,
    13: 2345055,
    14: 2395539,
    15: 2408479,
    16: 2416741,
    17: 2422415,
    18: 2438410,
    19: 2452135,
    20: 2448250,
    21: 2422754,
    22: 2396760,
    23: 2362383,
    24: 2325335,
    25: 2308977,
    26: 2296949,
    27: 2277664,
    28: 2256833,
    29: 2232555,
    30: 2187504,
    31: 2143157,
    32: 2103932,
    33: 2067585,
    34: 2036091,
    35: 2022690,
    36: 2004612,
    37: 1977025,
    38: 1948300,
    39: 1917114,
    40: 1877565,
    41: 1847385,
    42: 1817538,
    43: 1783919,
    44: 1753609,
    45: 1737691,
    46: 1716975,
    47: 1696246,
    48: 1675360,
    49: 1650057,
    50: 1600449,
    51: 1543708,
    52: 1497168,
    53: 1453105,
    54: 1405257,
    55: 1348512,
    56: 1291844,
    57: 1233745,
    58: 1174000,
    59: 1117877,
    60: 1084637,
    61: 1055074,
    62: 1015934,
    63: 971728,
    64: 926946,
    65: 883894,
    66: 841299,
    67: 800873,
    68: 760154,
    69: 717359,
    70: 659578,
    71: 597503,
    72: 542508,
    73: 493565,
    74: 448034,
    75: 406344,
    76: 366171,
    77: 318218,
    78: 277964,
    79: 243004,
    80: 213710,
    81: 188671,
    82: 162680,
    83: 139760,
    84: 119949,
    85: 402246,
    "75+": 0
  },
  1943: {
    0: 2692725,
    1: 2371411,
    2: 2388589,
    3: 2297887,
    4: 2265745,
    5: 2212045,
    6: 2115201,
    7: 2112215,
    8: 2005936,
    9: 1993445,
    10: 2107267,
    11: 2188240,
    12: 2260275,
    13: 2324096,
    14: 2379939,
    15: 2393695,
    16: 2391050,
    17: 2402539,
    18: 2401595,
    19: 2411694,
    20: 2430035,
    21: 2444059,
    22: 2420307,
    23: 2400010,
    24: 2369981,
    25: 2349507,
    26: 2318735,
    27: 2303068,
    28: 2280889,
    29: 2258112,
    30: 2214556,
    31: 2177085,
    32: 2134629,
    33: 2096416,
    34: 2061587,
    35: 2046604,
    36: 2028151,
    37: 2008094,
    38: 1977974,
    39: 1947593,
    40: 1905258,
    41: 1871667,
    42: 1843370,
    43: 1815410,
    44: 1782250,
    45: 1761370,
    46: 1740563,
    47: 1717580,
    48: 1695500,
    49: 1672717,
    50: 1628248,
    51: 1573515,
    52: 1516728,
    53: 1469118,
    54: 1423621,
    55: 1370738,
    56: 1315647,
    57: 1259377,
    58: 1201443,
    59: 1142446,
    60: 1103475,
    61: 1074176,
    62: 1042807,
    63: 1003258,
    64: 958399,
    65: 912138,
    66: 865398,
    67: 821548,
    68: 780239,
    69: 737826,
    70: 682583,
    71: 620868,
    72: 559747,
    73: 504695,
    74: 456364,
    75: 413700,
    76: 374923,
    77: 337082,
    78: 288282,
    79: 249215,
    80: 218364,
    81: 192354,
    82: 168431,
    83: 143972,
    84: 123002,
    85: 416960,
    "75+": 0
  },
  1944: {
    0: 2515859,
    1: 2724581,
    2: 2573419,
    3: 2416020,
    4: 2293935,
    5: 2222993,
    6: 2195216,
    7: 2100096,
    8: 2095447,
    9: 2008232,
    10: 2062006,
    11: 2109962,
    12: 2190510,
    13: 2261990,
    14: 2326690,
    15: 2376060,
    16: 2387381,
    17: 2383596,
    18: 2398635,
    19: 2391184,
    20: 2386866,
    21: 2419316,
    22: 2437959,
    23: 2416175,
    24: 2401925,
    25: 2399008,
    26: 2360761,
    27: 2325753,
    28: 2306564,
    29: 2281664,
    30: 2237658,
    31: 2205201,
    32: 2170932,
    33: 2130411,
    34: 2093144,
    35: 2074373,
    36: 2054639,
    37: 2034609,
    38: 2012539,
    39: 1979819,
    40: 1935379,
    41: 1897284,
    42: 1865135,
    43: 1838562,
    44: 1812377,
    45: 1789572,
    46: 1763704,
    47: 1740255,
    48: 1714974,
    49: 1691549,
    50: 1650563,
    51: 1599007,
    52: 1544016,
    53: 1487193,
    54: 1438636,
    55: 1389876,
    56: 1338704,
    57: 1284000,
    58: 1228022,
    59: 1170184,
    60: 1126025,
    61: 1094925,
    62: 1064449,
    63: 1031183,
    64: 991120,
    65: 944294,
    66: 891344,
    67: 842549,
    68: 797542,
    69: 755432,
    70: 701525,
    71: 639873,
    72: 579248,
    73: 519526,
    74: 465006,
    75: 419783,
    76: 381690,
    77: 344797,
    78: 309167,
    79: 260065,
    80: 223119,
    81: 196055,
    82: 171428,
    83: 148768,
    84: 125987,
    85: 430325,
    "75+": 0
  },
  1945: {
    0: 2463569,
    1: 2580905,
    2: 2922095,
    3: 2592601,
    4: 2419641,
    5: 2244654,
    6: 2212753,
    7: 2186637,
    8: 2089735,
    9: 2088357,
    10: 2102685,
    11: 2077834,
    12: 2121420,
    13: 2201871,
    14: 2272984,
    15: 2307980,
    16: 2328526,
    17: 2341163,
    18: 2336638,
    19: 2354584,
    20: 2354270,
    21: 2387255,
    22: 2422464,
    23: 2445961,
    24: 2426252,
    25: 2430959,
    26: 2398786,
    27: 2355916,
    28: 2316982,
    29: 2294458,
    30: 2247258,
    31: 2218748,
    32: 2190296,
    33: 2159395,
    34: 2120909,
    35: 2104441,
    36: 2084507,
    37: 2063793,
    38: 2042126,
    39: 2017937,
    40: 1968557,
    41: 1926211,
    42: 1888708,
    43: 1857799,
    44: 1832763,
    45: 1817339,
    46: 1789432,
    47: 1761127,
    48: 1735030,
    49: 1707506,
    50: 1669385,
    51: 1621565,
    52: 1569608,
    53: 1514276,
    54: 1457458,
    55: 1409418,
    56: 1363387,
    57: 1311958,
    58: 1257363,
    59: 1201411,
    60: 1154747,
    61: 1121025,
    62: 1089202,
    63: 1057388,
    64: 1022098,
    65: 979140,
    66: 923398,
    67: 868148,
    68: 817345,
    69: 771217,
    70: 720954,
    71: 659821,
    72: 598829,
    73: 539298,
    74: 481011,
    75: 430285,
    76: 391918,
    77: 355003,
    78: 319547,
    79: 285674,
    80: 237366,
    81: 202174,
    82: 176558,
    83: 153106,
    84: 131586,
    85: 451681,
    "75+": 0
  },
  1946: {
    0: 2401211,
    1: 2541726,
    2: 2748274,
    3: 2954919,
    4: 2597793,
    5: 2362293,
    6: 2243741,
    7: 2213616,
    8: 2184746,
    9: 2091817,
    10: 2160697,
    11: 2131296,
    12: 2098889,
    13: 2138151,
    14: 2218667,
    15: 2258532,
    16: 2263381,
    17: 2277948,
    18: 2291901,
    19: 2286677,
    20: 2327077,
    21: 2365278,
    22: 2402338,
    23: 2440529,
    24: 2469090,
    25: 2453530,
    26: 2421315,
    27: 2384695,
    28: 2337710,
    29: 2295259,
    30: 2256960,
    31: 2238474,
    32: 2214200,
    33: 2189649,
    34: 2161885,
    35: 2140581,
    36: 2112115,
    37: 2090911,
    38: 2069196,
    39: 2045819,
    40: 2007292,
    41: 1958930,
    42: 1917372,
    43: 1880246,
    44: 1850355,
    45: 1831507,
    46: 1809678,
    47: 1779521,
    48: 1748874,
    49: 1720258,
    50: 1680399,
    51: 1638326,
    52: 1590526,
    53: 1538123,
    54: 1482580,
    55: 1429278,
    56: 1385649,
    57: 1338883,
    58: 1287077,
    59: 1232443,
    60: 1184138,
    61: 1147117,
    62: 1112601,
    63: 1080073,
    64: 1046984,
    65: 1006869,
    66: 952025,
    67: 894929,
    68: 837744,
    69: 785327,
    70: 733015,
    71: 678958,
    72: 618502,
    73: 558285,
    74: 499995,
    75: 446262,
    76: 405263,
    77: 367324,
    78: 331365,
    79: 297125,
    80: 266323,
    81: 212110,
    82: 178620,
    83: 154842,
    84: 133035,
    85: 469532,
    "75+": 0
  },
  1947: {
    0: 3452304,
    1: 2496515,
    2: 2695485,
    3: 2800738,
    4: 2960693,
    5: 2531834,
    6: 2365076,
    7: 2248431,
    8: 2214936,
    9: 2190853,
    10: 2160278,
    11: 2159237,
    12: 2143975,
    13: 2104091,
    14: 2138538,
    15: 2211141,
    16: 2261286,
    17: 2267345,
    18: 2276399,
    19: 2291909,
    20: 2285142,
    21: 2324104,
    22: 2360731,
    23: 2401690,
    24: 2442687,
    25: 2483725,
    26: 2448449,
    27: 2413397,
    28: 2372286,
    29: 2321150,
    30: 2265564,
    31: 2257716,
    32: 2244971,
    33: 2224760,
    34: 2203857,
    35: 2192389,
    36: 2158369,
    37: 2124922,
    38: 2102332,
    39: 2079473,
    40: 2041998,
    41: 1996154,
    42: 1948218,
    43: 1907212,
    44: 1870240,
    45: 1843392,
    46: 1818398,
    47: 1795102,
    48: 1762738,
    49: 1729845,
    50: 1692642,
    51: 1650150,
    52: 1609055,
    53: 1561080,
    54: 1508265,
    55: 1456358,
    56: 1411558,
    57: 1366869,
    58: 1319167,
    59: 1266735,
    60: 1216288,
    61: 1177676,
    62: 1138912,
    63: 1103536,
    64: 1070273,
    65: 1034139,
    66: 976989,
    67: 921066,
    68: 862752,
    69: 803908,
    70: 746032,
    71: 692160,
    72: 639320,
    73: 579458,
    74: 520019,
    75: 465441,
    76: 421680,
    77: 380611,
    78: 343142,
    79: 308131,
    80: 276510,
    81: 241853,
    82: 188433,
    83: 156728,
    84: 134778,
    85: 492282,
    "75+": 0
  },
  1948: {
    0: 3169318,
    1: 3514716,
    2: 2680284,
    3: 2759307,
    4: 2795306,
    5: 2885424,
    6: 2539920,
    7: 2375063,
    8: 2253977,
    9: 2225928,
    10: 2256007,
    11: 2155505,
    12: 2152157,
    13: 2146282,
    14: 2098916,
    15: 2119785,
    16: 2188582,
    17: 2248389,
    18: 2255787,
    19: 2259307,
    20: 2285900,
    21: 2319466,
    22: 2357125,
    23: 2392841,
    24: 2438485,
    25: 2482261,
    26: 2475886,
    27: 2439451,
    28: 2401518,
    29: 2355928,
    30: 2294302,
    31: 2271774,
    32: 2267910,
    33: 2260742,
    34: 2244367,
    35: 2236444,
    36: 2212893,
    37: 2174615,
    38: 2136018,
    39: 2111912,
    40: 2078552,
    41: 2029377,
    42: 1983367,
    43: 1935626,
    44: 1894965,
    45: 1858794,
    46: 1825025,
    47: 1798981,
    48: 1774208,
    49: 1739720,
    50: 1702025,
    51: 1665894,
    52: 1623728,
    53: 1583310,
    54: 1535143,
    55: 1484755,
    56: 1442446,
    57: 1396999,
    58: 1351115,
    59: 1302307,
    60: 1250876,
    61: 1208191,
    62: 1168070,
    63: 1127595,
    64: 1091411,
    65: 1056568,
    66: 1003249,
    67: 943896,
    68: 886928,
    69: 827581,
    70: 764571,
    71: 703972,
    72: 651311,
    73: 599664,
    74: 540619,
    75: 483946,
    76: 439022,
    77: 395622,
    78: 354768,
    79: 317994,
    80: 284995,
    81: 249961,
    82: 216689,
    83: 164938,
    84: 135380,
    85: 517350,
    "75+": 0
  },
  1949: {
    0: 3169644,
    1: 3269273,
    2: 3706727,
    3: 2710023,
    4: 2751429,
    5: 2727530,
    6: 2889816,
    7: 2546627,
    8: 2376632,
    9: 2261929,
    10: 2280422,
    11: 2250172,
    12: 2151902,
    13: 2148710,
    14: 2136172,
    15: 2082363,
    16: 2106021,
    17: 2178016,
    18: 2247525,
    19: 2256122,
    20: 2257371,
    21: 2305216,
    22: 2343412,
    23: 2379639,
    24: 2414353,
    25: 2464955,
    26: 2483764,
    27: 2473549,
    28: 2435782,
    29: 2394819,
    30: 2341457,
    31: 2298548,
    32: 2279142,
    33: 2279116,
    34: 2277263,
    35: 2269282,
    36: 2260055,
    37: 2232742,
    38: 2189973,
    39: 2145958,
    40: 2115385,
    41: 2064907,
    42: 2016513,
    43: 1970113,
    44: 1922326,
    45: 1882292,
    46: 1838111,
    47: 1803349,
    48: 1776211,
    49: 1749947,
    50: 1712727,
    51: 1682690,
    52: 1646371,
    53: 1604143,
    54: 1564272,
    55: 1516941,
    56: 1475948,
    57: 1432120,
    58: 1385852,
    59: 1338570,
    60: 1288833,
    61: 1241151,
    62: 1197006,
    63: 1155375,
    64: 1113252,
    65: 1076033,
    66: 1028685,
    67: 973497,
    68: 911770,
    69: 853694,
    70: 792016,
    71: 722681,
    72: 662917,
    73: 611363,
    74: 560892,
    75: 503685,
    76: 456761,
    77: 411848,
    78: 368991,
    79: 328591,
    80: 293069,
    81: 257090,
    82: 223357,
    83: 191755,
    84: 142408,
    85: 549171,
    "75+": 0
  },
  1950: {
    0: 3162567,
    1: 3299863,
    2: 3450042,
    3: 3771883,
    4: 2725600,
    5: 2714560,
    6: 2755636,
    7: 2932959,
    8: 2569056,
    9: 2402326,
    10: 2325360,
    11: 2292510,
    12: 2265018,
    13: 2166039,
    14: 2163732,
    15: 2132602,
    16: 2055407,
    17: 2091956,
    18: 2164344,
    19: 2231096,
    20: 2258243,
    21: 2293186,
    22: 2324914,
    23: 2391008,
    24: 2413041,
    25: 2437842,
    26: 2494150,
    27: 2515690,
    28: 2455939,
    29: 2457975,
    30: 2416372,
    31: 2350474,
    32: 2296579,
    33: 2305591,
    34: 2305161,
    35: 2296670,
    36: 2302269,
    37: 2286825,
    38: 2257573,
    39: 2203312,
    40: 2146026,
    41: 2108459,
    42: 2061284,
    43: 2010872,
    44: 1963179,
    45: 1920595,
    46: 1861858,
    47: 1823655,
    48: 1792824,
    49: 1743067,
    50: 1730428,
    51: 1705308,
    52: 1668072,
    53: 1623091,
    54: 1584046,
    55: 1544901,
    56: 1499706,
    57: 1456723,
    58: 1419461,
    59: 1372293,
    60: 1308533,
    61: 1261628,
    62: 1219039,
    63: 1176591,
    64: 1137232,
    65: 1102643,
    66: 1068359,
    67: 1025449,
    68: 959546,
    69: 892792,
    70: 820399,
    71: 748822,
    72: 678081,
    73: 624363,
    74: 572323,
    75: 526042,
    76: 475302,
    77: 427724,
    78: 384529,
    79: 341701,
    80: 301331,
    81: 264897,
    82: 228777,
    83: 197083,
    84: 167401,
    85: 589612,
    "75+": 0
  },
  1951: {
    0: 3315027,
    1: 3306360,
    2: 3458209,
    3: 3495214,
    4: 3758637,
    5: 2673394,
    6: 2720557,
    7: 2777810,
    8: 2919475,
    9: 2576394,
    10: 2447996,
    11: 2324584,
    12: 2294245,
    13: 2263382,
    14: 2169776,
    15: 2110824,
    16: 2155565,
    17: 2062072,
    18: 2085366,
    19: 2143533,
    20: 2226752,
    21: 2285116,
    22: 2258475,
    23: 2384385,
    24: 2397456,
    25: 2382044,
    26: 2485281,
    27: 2550054,
    28: 2388417,
    29: 2489071,
    30: 2501511,
    31: 2406926,
    32: 2296222,
    33: 2346510,
    34: 2343344,
    35: 2301006,
    36: 2318955,
    37: 2308762,
    38: 2294968,
    39: 2238493,
    40: 2152206,
    41: 2137402,
    42: 2107882,
    43: 2053470,
    44: 2000532,
    45: 1973176,
    46: 1896650,
    47: 1865352,
    48: 1846607,
    49: 1731205,
    50: 1739683,
    51: 1736590,
    52: 1685405,
    53: 1616865,
    54: 1585288,
    55: 1562796,
    56: 1513994,
    57: 1473791,
    58: 1466950,
    59: 1431170,
    60: 1327936,
    61: 1283071,
    62: 1259031,
    63: 1210922,
    64: 1155186,
    65: 1122337,
    66: 1094587,
    67: 1061328,
    68: 984827,
    69: 925655,
    70: 849494,
    71: 776796,
    72: 694919,
    73: 644857,
    74: 586650,
    75: 547875,
    76: 495619,
    77: 443320,
    78: 401306,
    79: 355965,
    80: 310899,
    81: 274292,
    82: 233122,
    83: 200470,
    84: 170539,
    85: 627704,
    "75+": 0
  },
  1952: {
    0: 3429237,
    1: 3450008,
    2: 3447304,
    3: 3497803,
    4: 3487906,
    5: 3697873,
    6: 2678521,
    7: 2743435,
    8: 2764331,
    9: 2926644,
    10: 2623103,
    11: 2448869,
    12: 2327720,
    13: 2290686,
    14: 2271955,
    15: 2121429,
    16: 2128050,
    17: 2163113,
    18: 2058190,
    19: 2064397,
    20: 2140294,
    21: 2252033,
    22: 2254917,
    23: 2313831,
    24: 2389011,
    25: 2368562,
    26: 2430840,
    27: 2539590,
    28: 2433957,
    29: 2414400,
    30: 2530686,
    31: 2498967,
    32: 2356367,
    33: 2345235,
    34: 2383137,
    35: 2336914,
    36: 2322823,
    37: 2329787,
    38: 2306547,
    39: 2276059,
    40: 2196027,
    41: 2145206,
    42: 2131833,
    43: 2101458,
    44: 2046150,
    45: 2008515,
    46: 1951101,
    47: 1899084,
    48: 1886424,
    49: 1788803,
    50: 1723153,
    51: 1741462,
    52: 1716856,
    53: 1637802,
    54: 1581823,
    55: 1566919,
    56: 1534245,
    57: 1490847,
    58: 1484062,
    59: 1470910,
    60: 1385931,
    61: 1302807,
    62: 1277776,
    63: 1247344,
    64: 1188280,
    65: 1139636,
    66: 1112306,
    67: 1084556,
    68: 1021860,
    69: 952558,
    70: 881190,
    71: 804974,
    72: 724682,
    73: 661775,
    74: 606084,
    75: 559772,
    76: 516011,
    77: 463196,
    78: 414946,
    79: 371448,
    80: 323884,
    81: 282444,
    82: 240744,
    83: 203506,
    84: 172672,
    85: 665147,
    "75+": 0
  },
  1953: {
    0: 3546301,
    1: 3548477,
    2: 3574323,
    3: 3477405,
    4: 3491830,
    5: 3439101,
    6: 3699058,
    7: 2699180,
    8: 2726494,
    9: 2768580,
    10: 2974006,
    11: 2623543,
    12: 2451326,
    13: 2320100,
    14: 2302026,
    15: 2223638,
    16: 2131213,
    17: 2134566,
    18: 2159585,
    19: 2034765,
    20: 2059911,
    21: 2160664,
    22: 2223594,
    23: 2304737,
    24: 2313239,
    25: 2358201,
    26: 2414901,
    27: 2477356,
    28: 2432040,
    29: 2449315,
    30: 2447966,
    31: 2531563,
    32: 2448242,
    33: 2402883,
    34: 2377540,
    35: 2371899,
    36: 2356096,
    37: 2335651,
    38: 2314771,
    39: 2285831,
    40: 2240392,
    41: 2188562,
    42: 2132547,
    43: 2124780,
    44: 2095250,
    45: 2050027,
    46: 1986586,
    47: 1950647,
    48: 1916269,
    49: 1831607,
    50: 1774906,
    51: 1719360,
    52: 1721237,
    53: 1672117,
    54: 1604742,
    55: 1565919,
    56: 1540543,
    57: 1513668,
    58: 1500969,
    59: 1479280,
    60: 1424970,
    61: 1360993,
    62: 1294512,
    63: 1262015,
    64: 1223318,
    65: 1172152,
    66: 1127640,
    67: 1099179,
    68: 1046629,
    69: 991651,
    70: 906930,
    71: 835573,
    72: 754659,
    73: 691543,
    74: 621918,
    75: 576873,
    76: 526573,
    77: 483490,
    78: 433207,
    79: 384086,
    80: 338508,
    81: 294477,
    82: 247375,
    83: 209833,
    84: 174145,
    85: 700618,
    "75+": 0
  },
  1954: {
    0: 3670654,
    1: 3653661,
    2: 3656495,
    3: 3599983,
    4: 3476664,
    5: 3453939,
    6: 3439355,
    7: 3727076,
    8: 2681218,
    9: 2730119,
    10: 2811166,
    11: 2975880,
    12: 2627071,
    13: 2440657,
    14: 2335904,
    15: 2257092,
    16: 2227465,
    17: 2138662,
    18: 2135040,
    19: 2135882,
    20: 2033130,
    21: 2080459,
    22: 2140366,
    23: 2273536,
    24: 2304924,
    25: 2286831,
    26: 2407547,
    27: 2460279,
    28: 2386122,
    29: 2441590,
    30: 2481660,
    31: 2456844,
    32: 2486547,
    33: 2496921,
    34: 2435687,
    35: 2365843,
    36: 2392378,
    37: 2375045,
    38: 2311547,
    39: 2295945,
    40: 2261167,
    41: 2236059,
    42: 2172140,
    43: 2128286,
    44: 2123055,
    45: 2098128,
    46: 2031448,
    47: 1986117,
    48: 1967095,
    49: 1867702,
    50: 1814252,
    51: 1768402,
    52: 1701137,
    53: 1682261,
    54: 1643274,
    55: 1593667,
    56: 1544001,
    57: 1524815,
    58: 1526042,
    59: 1489617,
    60: 1435218,
    61: 1402234,
    62: 1351998,
    63: 1276387,
    64: 1238339,
    65: 1208291,
    66: 1159956,
    67: 1112897,
    68: 1064430,
    69: 1019929,
    70: 946111,
    71: 861463,
    72: 788354,
    73: 722642,
    74: 651585,
    75: 591388,
    76: 543531,
    77: 495142,
    78: 453080,
    79: 402708,
    80: 351569,
    81: 309400,
    82: 258980,
    83: 216216,
    84: 179705,
    85: 738452,
    "75+": 0
  },
  1955: {
    0: 3777404,
    1: 3764469,
    2: 3744444,
    3: 3676466,
    4: 3603530,
    5: 3449161,
    6: 3452324,
    7: 3465773,
    8: 3698230,
    9: 2683429,
    10: 2769205,
    11: 2814443,
    12: 2980158,
    13: 2612448,
    14: 2461584,
    15: 2294550,
    16: 2254733,
    17: 2235977,
    18: 2142406,
    19: 2111504,
    20: 2135741,
    21: 2052912,
    22: 2065719,
    23: 2186847,
    24: 2272449,
    25: 2279892,
    26: 2335789,
    27: 2449896,
    28: 2381075,
    29: 2387915,
    30: 2470040,
    31: 2496671,
    32: 2417837,
    33: 2534395,
    34: 2528720,
    35: 2421307,
    36: 2385690,
    37: 2416193,
    38: 2339986,
    39: 2293467,
    40: 2281314,
    41: 2258974,
    42: 2214696,
    43: 2169826,
    44: 2130086,
    45: 2123773,
    46: 2082012,
    47: 2029956,
    48: 2000462,
    49: 1923642,
    50: 1845729,
    51: 1803755,
    52: 1750976,
    53: 1667258,
    54: 1656852,
    55: 1636072,
    56: 1574959,
    57: 1531865,
    58: 1537925,
    59: 1506930,
    60: 1446324,
    61: 1413664,
    62: 1391249,
    63: 1330549,
    64: 1252105,
    65: 1223262,
    66: 1194738,
    67: 1142541,
    68: 1080208,
    69: 1040011,
    70: 973290,
    71: 899494,
    72: 816386,
    73: 756238,
    74: 681157,
    75: 618233,
    76: 556252,
    77: 511619,
    78: 462459,
    79: 421232,
    80: 368972,
    81: 321134,
    82: 271953,
    83: 226309,
    84: 184365,
    85: 775617,
    "75+": 0
  },
  1956: {
    0: 3860003,
    1: 3857934,
    2: 3839092,
    3: 3759958,
    4: 3686410,
    5: 3587147,
    6: 3447168,
    7: 3480478,
    8: 3438169,
    9: 3699221,
    10: 2719985,
    11: 2774962,
    12: 2820730,
    13: 2961160,
    14: 2640702,
    15: 2423680,
    16: 2287083,
    17: 2265473,
    18: 2244273,
    19: 2119953,
    20: 2114781,
    21: 2157495,
    22: 2044985,
    23: 2111474,
    24: 2187004,
    25: 2251515,
    26: 2332479,
    27: 2376674,
    28: 2384752,
    29: 2377271,
    30: 2413901,
    31: 2492691,
    32: 2462517,
    33: 2464261,
    34: 2565674,
    35: 2512341,
    36: 2441990,
    37: 2414743,
    38: 2370532,
    39: 2323075,
    40: 2289541,
    41: 2281709,
    42: 2233007,
    43: 2214657,
    44: 2175605,
    45: 2128724,
    46: 2110423,
    47: 2079532,
    48: 2042257,
    49: 1962889,
    50: 1896811,
    51: 1831199,
    52: 1787340,
    53: 1721462,
    54: 1645282,
    55: 1653083,
    56: 1620064,
    57: 1565753,
    58: 1544679,
    59: 1510043,
    60: 1463365,
    61: 1425006,
    62: 1399337,
    63: 1365289,
    64: 1304579,
    65: 1235999,
    66: 1207122,
    67: 1173570,
    68: 1111188,
    69: 1057235,
    70: 991438,
    71: 924740,
    72: 855929,
    73: 783343,
    74: 712341,
    75: 643966,
    76: 580409,
    77: 522902,
    78: 475657,
    79: 428091,
    80: 385155,
    81: 336042,
    82: 280499,
    83: 236421,
    84: 191237,
    85: 804375,
    "75+": 0
  },
  1957: {
    0: 4035155,
    1: 3923917,
    2: 3912759,
    3: 3847648,
    4: 3774329,
    5: 3679934,
    6: 3582421,
    7: 3474852,
    8: 3449670,
    9: 3437541,
    10: 3742297,
    11: 2726273,
    12: 2781183,
    13: 2798675,
    14: 2996847,
    15: 2603159,
    16: 2407100,
    17: 2296166,
    18: 2274288,
    19: 2217436,
    20: 2122410,
    21: 2133334,
    22: 2151036,
    23: 2087107,
    24: 2109080,
    25: 2167481,
    26: 2303937,
    27: 2370125,
    28: 2325111,
    29: 2373166,
    30: 2399249,
    31: 2441863,
    32: 2462482,
    33: 2507964,
    34: 2492400,
    35: 2546473,
    36: 2533176,
    37: 2476432,
    38: 2358489,
    39: 2353961,
    40: 2329121,
    41: 2291428,
    42: 2249872,
    43: 2234056,
    44: 2223462,
    45: 2171200,
    46: 2117289,
    47: 2105965,
    48: 2088827,
    49: 2009895,
    50: 1930233,
    51: 1877070,
    52: 1814963,
    53: 1761836,
    54: 1702245,
    55: 1644532,
    56: 1639945,
    57: 1614360,
    58: 1579415,
    59: 1508629,
    60: 1466903,
    61: 1442808,
    62: 1407798,
    63: 1369323,
    64: 1338209,
    65: 1288341,
    66: 1217874,
    67: 1182606,
    68: 1144535,
    69: 1090995,
    70: 1007696,
    71: 942004,
    72: 883994,
    73: 823290,
    74: 738330,
    75: 672569,
    76: 604925,
    77: 547425,
    78: 485321,
    79: 440604,
    80: 391499,
    81: 351548,
    82: 293837,
    83: 243699,
    84: 199435,
    85: 837293,
    "75+": 0
  },
  1958: {
    0: 4073113,
    1: 4081641,
    2: 3955569,
    3: 3911814,
    4: 3864465,
    5: 3776286,
    6: 3670427,
    7: 3608923,
    8: 3439532,
    9: 3445744,
    10: 3473006,
    11: 3749985,
    12: 2731853,
    13: 2754957,
    14: 2836085,
    15: 2957721,
    16: 2576464,
    17: 2415841,
    18: 2306640,
    19: 2244557,
    20: 2218649,
    21: 2137506,
    22: 2128631,
    23: 2190431,
    24: 2080581,
    25: 2089151,
    26: 2216196,
    27: 2335664,
    28: 2327219,
    29: 2303935,
    30: 2388297,
    31: 2429780,
    32: 2413401,
    33: 2503250,
    34: 2531425,
    35: 2468902,
    36: 2564641,
    37: 2571791,
    38: 2405883,
    39: 2340678,
    40: 2368515,
    41: 2330882,
    42: 2252125,
    43: 2250438,
    44: 2244353,
    45: 2214636,
    46: 2160534,
    47: 2109794,
    48: 2110924,
    49: 2060911,
    50: 1970024,
    51: 1903914,
    52: 1859706,
    53: 1792438,
    54: 1744354,
    55: 1704180,
    56: 1633094,
    57: 1636568,
    58: 1628208,
    59: 1533905,
    60: 1465009,
    61: 1446114,
    62: 1421798,
    63: 1372692,
    64: 1340078,
    65: 1320412,
    66: 1266948,
    67: 1188273,
    68: 1154064,
    69: 1125536,
    70: 1038546,
    71: 955589,
    72: 902398,
    73: 849766,
    74: 774993,
    75: 693623,
    76: 629920,
    77: 569887,
    78: 505659,
    79: 446987,
    80: 400988,
    81: 354724,
    82: 305632,
    83: 254028,
    84: 203622,
    85: 864481,
    "75+": 0
  },
  1959: {
    0: 4097383,
    1: 4103414,
    2: 4092616,
    3: 3947785,
    4: 3933920,
    5: 3877965,
    6: 3764441,
    7: 3697523,
    8: 3569753,
    9: 3434413,
    10: 3478283,
    11: 3482375,
    12: 3758204,
    13: 2703780,
    14: 2797368,
    15: 2804400,
    16: 2919823,
    17: 2586987,
    18: 2431016,
    19: 2276715,
    20: 2247873,
    21: 2233988,
    22: 2137920,
    23: 2166724,
    24: 2182455,
    25: 2062600,
    26: 2137130,
    27: 2244217,
    28: 2304454,
    29: 2298561,
    30: 2314904,
    31: 2424507,
    32: 2405564,
    33: 2452018,
    34: 2524791,
    35: 2505462,
    36: 2486313,
    37: 2609363,
    38: 2487881,
    39: 2389131,
    40: 2366032,
    41: 2372830,
    42: 2286160,
    43: 2254757,
    44: 2264838,
    45: 2233517,
    46: 2207227,
    47: 2152367,
    48: 2112595,
    49: 2090332,
    50: 2016087,
    51: 1939474,
    52: 1888124,
    53: 1842910,
    54: 1779285,
    55: 1751321,
    56: 1696982,
    57: 1633812,
    58: 1652113,
    59: 1575047,
    60: 1491951,
    61: 1446654,
    62: 1424132,
    63: 1384605,
    64: 1344533,
    65: 1323956,
    66: 1299233,
    67: 1236144,
    68: 1164286,
    69: 1139912,
    70: 1074172,
    71: 987571,
    72: 921036,
    73: 870230,
    74: 801837,
    75: 728589,
    76: 650616,
    77: 596116,
    78: 526970,
    79: 467184,
    80: 407389,
    81: 364053,
    82: 308239,
    83: 265630,
    84: 213541,
    85: 901219,
    "75+": 0
  },
  1960: {
    0: 4093802,
    1: 4104163,
    2: 4080463,
    3: 4077609,
    4: 3985212,
    5: 3958677,
    6: 3852247,
    7: 3797917,
    8: 3635589,
    9: 3565587,
    10: 3473252,
    11: 3489877,
    12: 3491706,
    13: 3700245,
    14: 2770401,
    15: 2759342,
    16: 2750756,
    17: 2938626,
    18: 2612578,
    19: 2380875,
    20: 2295794,
    21: 2265728,
    22: 2238646,
    23: 2174395,
    24: 2159614,
    25: 2165795,
    26: 2111395,
    27: 2163619,
    28: 2224932,
    29: 2269886,
    30: 2310669,
    31: 2355860,
    32: 2403565,
    33: 2441333,
    34: 2471476,
    35: 2497353,
    36: 2523550,
    37: 2533543,
    38: 2514885,
    39: 2473141,
    40: 2424758,
    41: 2370496,
    42: 2321952,
    43: 2290300,
    44: 2271385,
    45: 2250666,
    46: 2227858,
    47: 2195145,
    48: 2148220,
    49: 2092119,
    50: 2036229,
    51: 1977497,
    52: 1926119,
    53: 1882850,
    54: 1841419,
    55: 1795689,
    56: 1751551,
    57: 1700406,
    58: 1641590,
    59: 1581662,
    60: 1525828,
    61: 1467415,
    62: 1417997,
    63: 1383905,
    64: 1358968,
    65: 1331942,
    66: 1304419,
    67: 1269656,
    68: 1218968,
    69: 1155127,
    70: 1089339,
    71: 1023577,
    72: 955657,
    73: 886757,
    74: 818031,
    75: 749616,
    76: 681648,
    77: 614477,
    78: 548964,
    79: 485738,
    80: 425557,
    81: 368485,
    82: 315562,
    83: 267285,
    84: 224172,
    85: 940054,
    "75+": 0
  },
  1961: {
    0: 4172988,
    1: 4084896,
    2: 4057693,
    3: 4080156,
    4: 4126537,
    5: 3998349,
    6: 3925679,
    7: 3890068,
    8: 3726338,
    9: 3655512,
    10: 3614497,
    11: 3476571,
    12: 3495250,
    13: 3434732,
    14: 3814413,
    15: 2734077,
    16: 2721876,
    17: 2781674,
    18: 2975853,
    19: 2545669,
    20: 2452277,
    21: 2315777,
    22: 2276632,
    23: 2246879,
    24: 2191006,
    25: 2170374,
    26: 2172236,
    27: 2121018,
    28: 2172992,
    29: 2233120,
    30: 2292994,
    31: 2313524,
    32: 2361971,
    33: 2404988,
    34: 2448385,
    35: 2475014,
    36: 2499150,
    37: 2523272,
    38: 2531451,
    39: 2511597,
    40: 2468852,
    41: 2419281,
    42: 2364895,
    43: 2316082,
    44: 2282829,
    45: 2262392,
    46: 2242108,
    47: 2210739,
    48: 2167263,
    49: 2116663,
    50: 2065078,
    51: 2007327,
    52: 1965612,
    53: 1936516,
    54: 1901801,
    55: 1851867,
    56: 1802980,
    57: 1739316,
    58: 1662232,
    59: 1590101,
    60: 1534453,
    61: 1478537,
    62: 1428861,
    63: 1392847,
    64: 1366154,
    65: 1339135,
    66: 1308975,
    67: 1280469,
    68: 1242949,
    69: 1188701,
    70: 1121903,
    71: 1054360,
    72: 983727,
    73: 910453,
    74: 838437,
    75: 769465,
    76: 701945,
    77: 634491,
    78: 568310,
    79: 504365,
    80: 443979,
    81: 385537,
    82: 331045,
    83: 281045,
    84: 235686,
    85: 964233,
    "75+": 0
  },
  1962: {
    0: 4083751,
    1: 4160703,
    2: 4034016,
    3: 4060410,
    4: 4129913,
    5: 4137071,
    6: 3971329,
    7: 3962111,
    8: 3820035,
    9: 3750578,
    10: 3717727,
    11: 3606125,
    12: 3485569,
    13: 3446619,
    14: 3537894,
    15: 3769670,
    16: 2700044,
    17: 2751782,
    18: 2816416,
    19: 2912447,
    20: 2620396,
    21: 2464576,
    22: 2322896,
    23: 2281132,
    24: 2270117,
    25: 2197507,
    26: 2171778,
    27: 2182297,
    28: 2127996,
    29: 2177651,
    30: 2265719,
    31: 2280054,
    32: 2317585,
    33: 2358428,
    34: 2415151,
    35: 2449621,
    36: 2475642,
    37: 2498551,
    38: 2521431,
    39: 2528331,
    40: 2506986,
    41: 2462691,
    42: 2412210,
    43: 2356871,
    44: 2306453,
    45: 2272120,
    46: 2252143,
    47: 2224855,
    48: 2184752,
    49: 2138487,
    50: 2091873,
    51: 2038684,
    52: 1995779,
    53: 1973331,
    54: 1951592,
    55: 1909268,
    56: 1856471,
    57: 1789519,
    58: 1702118,
    59: 1613346,
    60: 1545564,
    61: 1489646,
    62: 1442477,
    63: 1405947,
    64: 1376882,
    65: 1347530,
    66: 1317006,
    67: 1284509,
    68: 1251866,
    69: 1210170,
    70: 1153063,
    71: 1084817,
    72: 1012356,
    73: 936221,
    74: 859634,
    75: 787457,
    76: 719204,
    77: 652199,
    78: 585788,
    79: 521276,
    80: 460568,
    81: 401800,
    82: 346133,
    83: 294796,
    84: 248012,
    85: 982198,
    "75+": 0
  },
  1963: {
    0: 4012510,
    1: 4071602,
    2: 4105855,
    3: 4040638,
    4: 4110926,
    5: 4137064,
    6: 4114451,
    7: 4004689,
    8: 3892608,
    9: 3847088,
    10: 3825073,
    11: 3695383,
    12: 3617270,
    13: 3444174,
    14: 3545889,
    15: 3501539,
    16: 3724202,
    17: 2728595,
    18: 2785555,
    19: 2769149,
    20: 2993742,
    21: 2622269,
    22: 2465430,
    23: 2321819,
    24: 2310253,
    25: 2272391,
    26: 2195043,
    27: 2184246,
    28: 2190051,
    29: 2132221,
    30: 2222398,
    31: 2241035,
    32: 2285911,
    33: 2312604,
    34: 2374082,
    35: 2415506,
    36: 2449378,
    37: 2474073,
    38: 2496093,
    39: 2518130,
    40: 2523791,
    41: 2501247,
    42: 2455815,
    43: 2403801,
    44: 2346512,
    45: 2294990,
    46: 2260709,
    47: 2234924,
    48: 2200770,
    49: 2158820,
    50: 2116081,
    51: 2068191,
    52: 2027582,
    53: 2000456,
    54: 1983376,
    55: 1954413,
    56: 1909068,
    57: 1839258,
    58: 1750475,
    59: 1652735,
    60: 1568398,
    61: 1500230,
    62: 1453083,
    63: 1419014,
    64: 1389467,
    65: 1357847,
    66: 1325285,
    67: 1291689,
    68: 1253728,
    69: 1216024,
    70: 1171256,
    71: 1112758,
    72: 1039311,
    73: 961163,
    74: 881718,
    75: 805334,
    76: 733920,
    77: 666380,
    78: 600660,
    79: 536172,
    80: 475454,
    81: 416316,
    82: 360573,
    83: 308280,
    84: 260371,
    85: 1003418,
    "75+": 0
  },
  1964: {
    0: 3946853,
    1: 3998118,
    2: 4013585,
    3: 4115310,
    4: 4090999,
    5: 4114053,
    6: 4119428,
    7: 4144687,
    8: 3935856,
    9: 3921848,
    10: 3933774,
    11: 3786885,
    12: 3707388,
    13: 3580494,
    14: 3538419,
    15: 3513943,
    16: 3462482,
    17: 3758932,
    18: 2762842,
    19: 2752014,
    20: 2846740,
    21: 2984824,
    22: 2618388,
    23: 2460044,
    24: 2358554,
    25: 2308915,
    26: 2265698,
    27: 2209452,
    28: 2191294,
    29: 2192610,
    30: 2187051,
    31: 2184852,
    32: 2246695,
    33: 2277689,
    34: 2332045,
    35: 2372325,
    36: 2413578,
    37: 2446394,
    38: 2470780,
    39: 2492463,
    40: 2513549,
    41: 2518435,
    42: 2494560,
    43: 2446979,
    44: 2392579,
    45: 2334084,
    46: 2282131,
    47: 2243234,
    48: 2212608,
    49: 2177806,
    50: 2139217,
    51: 2095845,
    52: 2058466,
    53: 2030287,
    54: 2006453,
    55: 1982543,
    56: 1950542,
    57: 1889533,
    58: 1800029,
    59: 1702226,
    60: 1608903,
    61: 1524071,
    62: 1464853,
    63: 1430865,
    64: 1403994,
    65: 1372266,
    66: 1338071,
    67: 1301962,
    68: 1261738,
    69: 1217757,
    70: 1176674,
    71: 1130510,
    72: 1066546,
    73: 987329,
    74: 906079,
    75: 827423,
    76: 752004,
    77: 681637,
    78: 615677,
    79: 552115,
    80: 491897,
    81: 432481,
    82: 376307,
    83: 323724,
    84: 274479,
    85: 1040022,
    "75+": 0
  },
  1965: {
    0: 3770049,
    1: 3928418,
    2: 3935365,
    3: 4024448,
    4: 4165561,
    5: 4089460,
    6: 4101067,
    7: 4145207,
    8: 4074807,
    9: 3967031,
    10: 4020780,
    11: 3878958,
    12: 3800029,
    13: 3676247,
    14: 3672861,
    15: 3510524,
    16: 3476677,
    17: 3493366,
    18: 3804349,
    19: 2741815,
    20: 2828397,
    21: 2829293,
    22: 2974287,
    23: 2608334,
    24: 2506128,
    25: 2353320,
    26: 2297761,
    27: 2281873,
    28: 2215580,
    29: 2191676,
    30: 2260151,
    31: 2137036,
    32: 2190392,
    33: 2235678,
    34: 2301530,
    35: 2329048,
    36: 2369898,
    37: 2410563,
    38: 2443529,
    39: 2467646,
    40: 2488120,
    41: 2508284,
    42: 2511192,
    43: 2484440,
    44: 2434326,
    45: 2379012,
    46: 2319988,
    47: 2264928,
    48: 2223279,
    49: 2192928,
    50: 2160922,
    51: 2121977,
    52: 2086683,
    53: 2058183,
    54: 2031279,
    55: 2001015,
    56: 1974040,
    57: 1927957,
    58: 1849577,
    59: 1752428,
    60: 1658851,
    61: 1564762,
    62: 1489103,
    63: 1442985,
    64: 1416172,
    65: 1387207,
    66: 1353251,
    67: 1314860,
    68: 1270969,
    69: 1224004,
    70: 1176625,
    71: 1134421,
    72: 1082762,
    73: 1012772,
    74: 930126,
    75: 849660,
    76: 771665,
    77: 697251,
    78: 628482,
    79: 564733,
    80: 505885,
    81: 446842,
    82: 390716,
    83: 338150,
    84: 289252,
    85: 1081760,
    "75+": 0
  },
  1966: {
    0: 3555346,
    1: 3754179,
    2: 3866725,
    3: 3953247,
    4: 4078477,
    5: 4164778,
    6: 4086431,
    7: 4127481,
    8: 4081690,
    9: 4113116,
    10: 4082146,
    11: 3952738,
    12: 3896753,
    13: 3777761,
    14: 3768291,
    15: 3650609,
    16: 3477928,
    17: 3507925,
    18: 3535518,
    19: 3789819,
    20: 2816582,
    21: 2800665,
    22: 2813697,
    23: 2955441,
    24: 2664034,
    25: 2496628,
    26: 2339109,
    27: 2318338,
    28: 2290407,
    29: 2217275,
    30: 2273628,
    31: 2198383,
    32: 2145554,
    33: 2179608,
    34: 2266239,
    35: 2299308,
    36: 2327682,
    37: 2367995,
    38: 2409227,
    39: 2442344,
    40: 2465450,
    41: 2485314,
    42: 2503209,
    43: 2502460,
    44: 2472528,
    45: 2421211,
    46: 2364671,
    47: 2303584,
    48: 2247692,
    49: 2207371,
    50: 2179413,
    51: 2147484,
    52: 2114281,
    53: 2084320,
    54: 2055086,
    55: 2022247,
    56: 1988847,
    57: 1949482,
    58: 1888550,
    59: 1803853,
    60: 1710650,
    61: 1615843,
    62: 1531088,
    63: 1468413,
    64: 1429058,
    65: 1399910,
    66: 1368739,
    67: 1329669,
    68: 1282238,
    69: 1230866,
    70: 1180517,
    71: 1132308,
    72: 1084674,
    73: 1026983,
    74: 953532,
    75: 872070,
    76: 792291,
    77: 715539,
    78: 643002,
    79: 576689,
    80: 518162,
    81: 460261,
    82: 404604,
    83: 352128,
    84: 303252,
    85: 1127697,
    "75+": 0
  },
  1967: {
    0: 3450000,
    1: 3535546,
    2: 3688418,
    3: 3884351,
    4: 4004194,
    5: 4072240,
    6: 4165426,
    7: 4107922,
    8: 4065534,
    9: 4121978,
    10: 4243904,
    11: 3998066,
    12: 3972967,
    13: 3881399,
    14: 3866498,
    15: 3748478,
    16: 3616414,
    17: 3503826,
    18: 3544889,
    19: 3534168,
    20: 3882941,
    21: 2776018,
    22: 2777109,
    23: 2788578,
    24: 3023227,
    25: 2646286,
    26: 2473980,
    27: 2359639,
    28: 2324541,
    29: 2288828,
    30: 2310986,
    31: 2197971,
    32: 2207215,
    33: 2132456,
    34: 2214306,
    35: 2262957,
    36: 2297497,
    37: 2325766,
    38: 2367327,
    39: 2409301,
    40: 2441744,
    41: 2464690,
    42: 2482104,
    43: 2495653,
    44: 2491117,
    45: 2459682,
    46: 2406418,
    47: 2348920,
    48: 2289115,
    49: 2235902,
    50: 2197883,
    51: 2170768,
    52: 2142441,
    53: 2110896,
    54: 2077792,
    55: 2042681,
    56: 2006225,
    57: 1961818,
    58: 1910264,
    59: 1844667,
    60: 1763861,
    61: 1669258,
    62: 1584234,
    63: 1512742,
    64: 1456619,
    65: 1414629,
    66: 1383451,
    67: 1346416,
    68: 1297144,
    69: 1241462,
    70: 1186609,
    71: 1135613,
    72: 1081946,
    73: 1028250,
    74: 966960,
    75: 894893,
    76: 814050,
    77: 735659,
    78: 660983,
    79: 591131,
    80: 530576,
    81: 472767,
    82: 418377,
    83: 366464,
    84: 317793,
    85: 1186242,
    "75+": 0
  },
  1968: {
    0: 3366388,
    1: 3430838,
    2: 3471495,
    3: 3709110,
    4: 3935584,
    5: 3995171,
    6: 4078802,
    7: 4183649,
    8: 4048616,
    9: 4108108,
    10: 4264703,
    11: 4140607,
    12: 4020126,
    13: 3964708,
    14: 3967100,
    15: 3850540,
    16: 3715305,
    17: 3640360,
    18: 3539053,
    19: 3558084,
    20: 3619894,
    21: 3811492,
    22: 2746998,
    23: 2747093,
    24: 2860687,
    25: 2997171,
    26: 2617403,
    27: 2497786,
    28: 2365794,
    29: 2321733,
    30: 2398208,
    31: 2221421,
    32: 2207531,
    33: 2191378,
    34: 2171326,
    35: 2210160,
    36: 2260707,
    37: 2295439,
    38: 2325479,
    39: 2368059,
    40: 2409347,
    41: 2441740,
    42: 2461820,
    43: 2474241,
    44: 2483719,
    45: 2477718,
    46: 2443940,
    47: 2391176,
    48: 2337170,
    49: 2281190,
    50: 2229848,
    51: 2193100,
    52: 2167182,
    53: 2136803,
    54: 2099908,
    55: 2061296,
    56: 2022374,
    57: 1976557,
    58: 1922765,
    59: 1868127,
    60: 1805981,
    61: 1723215,
    62: 1638593,
    63: 1567038,
    64: 1501981,
    65: 1442779,
    66: 1398652,
    67: 1360788,
    68: 1312478,
    69: 1254376,
    70: 1195293,
    71: 1140178,
    72: 1083811,
    73: 1024028,
    74: 966365,
    75: 906225,
    76: 834265,
    77: 754708,
    78: 678393,
    79: 606491,
    80: 542969,
    81: 482999,
    82: 429073,
    83: 378951,
    84: 331616,
    85: 1240679,
    "75+": 0
  },
  1969: {
    0: 3412562,
    1: 3345777,
    2: 3364853,
    3: 3493737,
    4: 3758584,
    5: 3923088,
    6: 4007178,
    7: 4093268,
    8: 4125569,
    9: 4093622,
    10: 4262865,
    11: 4145705,
    12: 4165280,
    13: 4019792,
    14: 4047383,
    15: 3955996,
    16: 3819366,
    17: 3737682,
    18: 3676105,
    19: 3567070,
    20: 3642408,
    21: 3540175,
    22: 3761493,
    23: 2711237,
    24: 2824480,
    25: 2829895,
    26: 2956788,
    27: 2642288,
    28: 2501601,
    29: 2359906,
    30: 2444035,
    31: 2291001,
    32: 2231047,
    33: 2188598,
    34: 2235511,
    35: 2165933,
    36: 2206835,
    37: 2257746,
    38: 2294773,
    39: 2326226,
    40: 2368232,
    41: 2409687,
    42: 2438891,
    43: 2453350,
    44: 2461378,
    45: 2469363,
    46: 2460507,
    47: 2428585,
    48: 2381535,
    49: 2332481,
    50: 2277866,
    51: 2228111,
    52: 2189984,
    53: 2158263,
    54: 2120393,
    55: 2078471,
    56: 2035956,
    57: 1989460,
    58: 1937145,
    59: 1881960,
    60: 1830333,
    61: 1765604,
    62: 1692902,
    63: 1621868,
    64: 1556698,
    65: 1488120,
    66: 1426484,
    67: 1374516,
    68: 1324240,
    69: 1266546,
    70: 1205143,
    71: 1146136,
    72: 1085805,
    73: 1023647,
    74: 960394,
    75: 904503,
    76: 844551,
    77: 774174,
    78: 697112,
    79: 623926,
    80: 559029,
    81: 495767,
    82: 439600,
    83: 389645,
    84: 343637,
    85: 1307490,
    "75+": 0
  },
  1970: {
    0: 3508096,
    1: 3401442,
    2: 3295270,
    3: 3406502,
    4: 3554881,
    5: 3756969,
    6: 3946319,
    7: 4019585,
    8: 4032394,
    9: 4164170,
    10: 4263921,
    11: 4138932,
    12: 4175165,
    13: 4174574,
    14: 4100251,
    15: 4043855,
    16: 3931856,
    17: 3847703,
    18: 3781499,
    19: 3728184,
    20: 3653488,
    21: 3556227,
    22: 3494642,
    23: 3716091,
    24: 2781658,
    25: 2802151,
    26: 2804268,
    27: 3000699,
    28: 2641863,
    29: 2487167,
    30: 2487628,
    31: 2320918,
    32: 2305215,
    33: 2218979,
    34: 2254644,
    35: 2225239,
    36: 2184809,
    37: 2222057,
    38: 2230004,
    39: 2292567,
    40: 2422721,
    41: 2363323,
    42: 2397821,
    43: 2392199,
    44: 2418912,
    45: 2465964,
    46: 2433301,
    47: 2415765,
    48: 2448256,
    49: 2387341,
    50: 2427106,
    51: 2299963,
    52: 2199245,
    53: 2130839,
    54: 2108622,
    55: 2105906,
    56: 2050273,
    57: 2010442,
    58: 1942941,
    59: 1896914,
    60: 1912080,
    61: 1791616,
    62: 1732996,
    63: 1640281,
    64: 1598736,
    65: 1550071,
    66: 1475305,
    67: 1408965,
    68: 1287308,
    69: 1304600,
    70: 1271974,
    71: 1146673,
    72: 1056582,
    73: 1013178,
    74: 978334,
    75: 919310,
    76: 839268,
    77: 787600,
    78: 699225,
    79: 625561,
    80: 605554,
    81: 539114,
    82: 445989,
    83: 382693,
    84: 338932,
    85: 1430493,
    "75+": 0
  },
  1971: {
    0: 3601094,
    1: 3489276,
    2: 3348331,
    3: 3337236,
    4: 3468031,
    5: 3556777,
    6: 3787320,
    7: 3977564,
    8: 3974264,
    9: 4099362,
    10: 4333927,
    11: 4139134,
    12: 4174936,
    13: 4192739,
    14: 4254445,
    15: 4100796,
    16: 4019319,
    17: 3953863,
    18: 3878359,
    19: 3836171,
    20: 3796605,
    21: 3553466,
    22: 3509578,
    23: 3459578,
    24: 3839808,
    25: 2756978,
    26: 2773244,
    27: 2847814,
    28: 3003167,
    29: 2660149,
    30: 2631233,
    31: 2367317,
    32: 2343542,
    33: 2293383,
    34: 2281467,
    35: 2252836,
    36: 2222348,
    37: 2184977,
    38: 2219512,
    39: 2240196,
    40: 2304588,
    41: 2422420,
    42: 2361689,
    43: 2386965,
    44: 2382516,
    45: 2405799,
    46: 2450645,
    47: 2423579,
    48: 2407120,
    49: 2454763,
    50: 2374820,
    51: 2426681,
    52: 2288583,
    53: 2180835,
    54: 2105749,
    55: 2082108,
    56: 2081236,
    57: 2024298,
    58: 1982253,
    59: 1919926,
    60: 1856716,
    61: 1885972,
    62: 1772301,
    63: 1724388,
    64: 1633125,
    65: 1585875,
    66: 1532190,
    67: 1445724,
    68: 1367181,
    69: 1238556,
    70: 1239995,
    71: 1223707,
    72: 1091316,
    73: 1002799,
    74: 956616,
    75: 924315,
    76: 869665,
    77: 791081,
    78: 745950,
    79: 655410,
    80: 579633,
    81: 567154,
    82: 501102,
    83: 409310,
    84: 346871,
    85: 1487010,
    "75+": 0
  },
  1972: {
    0: 3305565,
    1: 3567411,
    2: 3439449,
    3: 3392093,
    4: 3396835,
    5: 3468940,
    6: 3582479,
    7: 3823954,
    8: 3922524,
    9: 4048928,
    10: 4256429,
    11: 4215705,
    12: 4166881,
    13: 4193163,
    14: 4266512,
    15: 4257060,
    16: 4077625,
    17: 4037981,
    18: 3975748,
    19: 3947500,
    20: 3895789,
    21: 3696985,
    22: 3510975,
    23: 3481136,
    24: 3568208,
    25: 3810706,
    26: 2730854,
    27: 2815750,
    28: 2837491,
    29: 3045673,
    30: 2795484,
    31: 2510244,
    32: 2389361,
    33: 2329854,
    34: 2358520,
    35: 2275416,
    36: 2245082,
    37: 2222773,
    38: 2177719,
    39: 2237432,
    40: 2255241,
    41: 2294800,
    42: 2421323,
    43: 2348590,
    44: 2381131,
    45: 2368042,
    46: 2390643,
    47: 2442225,
    48: 2410240,
    49: 2421471,
    50: 2430489,
    51: 2381271,
    52: 2414096,
    53: 2272102,
    54: 2155553,
    55: 2078269,
    56: 2058659,
    57: 2056295,
    58: 1996197,
    59: 1964521,
    60: 1866721,
    61: 1843374,
    62: 1868900,
    63: 1762498,
    64: 1715315,
    65: 1617903,
    66: 1565388,
    67: 1499968,
    68: 1403814,
    69: 1317163,
    70: 1165820,
    71: 1200090,
    72: 1164701,
    73: 1041074,
    74: 946418,
    75: 902220,
    76: 877299,
    77: 821522,
    78: 751913,
    79: 698374,
    80: 600143,
    81: 545217,
    82: 526766,
    83: 462095,
    84: 369492,
    85: 1542441,
    "75+": 0
  },
  1973: {
    0: 3128246,
    1: 3262329,
    2: 3522332,
    3: 3485898,
    4: 3452041,
    5: 3396993,
    6: 3491086,
    7: 3623683,
    8: 3761820,
    9: 4005252,
    10: 4194673,
    11: 4149643,
    12: 4236385,
    13: 4186826,
    14: 4262073,
    15: 4273105,
    16: 4236799,
    17: 4095001,
    18: 4052657,
    19: 4061281,
    20: 3998373,
    21: 3797654,
    22: 3655348,
    23: 3487642,
    24: 3582406,
    25: 3547445,
    26: 3774520,
    27: 2771417,
    28: 2792958,
    29: 2899904,
    30: 3177852,
    31: 2673715,
    32: 2531847,
    33: 2373292,
    34: 2396127,
    35: 2347687,
    36: 2262621,
    37: 2245782,
    38: 2210794,
    39: 2203108,
    40: 2256186,
    41: 2236841,
    42: 2294161,
    43: 2406060,
    44: 2346827,
    45: 2365456,
    46: 2353253,
    47: 2383629,
    48: 2424640,
    49: 2433195,
    50: 2386051,
    51: 2445651,
    52: 2367807,
    53: 2400538,
    54: 2246798,
    55: 2127097,
    56: 2055940,
    57: 2034584,
    58: 2027978,
    59: 1983358,
    60: 1897202,
    61: 1865564,
    62: 1827066,
    63: 1859114,
    64: 1750243,
    65: 1698598,
    66: 1594781,
    67: 1530467,
    68: 1458335,
    69: 1353465,
    70: 1232619,
    71: 1134140,
    72: 1139517,
    73: 1119838,
    74: 985307,
    75: 892135,
    76: 857800,
    77: 829257,
    78: 785936,
    79: 700339,
    80: 632789,
    81: 570527,
    82: 501303,
    83: 483987,
    84: 417054,
    85: 1606740,
    "75+": 0
  },
  1974: {
    0: 3065474,
    1: 3076817,
    2: 3227239,
    3: 3571284,
    4: 3545915,
    5: 3450079,
    6: 3414384,
    7: 3536215,
    8: 3555210,
    9: 3849313,
    10: 4139447,
    11: 4098453,
    12: 4162888,
    13: 4258683,
    14: 4251081,
    15: 4273266,
    16: 4257208,
    17: 4253657,
    18: 4102644,
    19: 4154823,
    20: 4102553,
    21: 3900766,
    22: 3756547,
    23: 3635084,
    24: 3580457,
    25: 3566868,
    26: 3515744,
    27: 3824862,
    28: 2737606,
    29: 2876037,
    30: 3007714,
    31: 3047968,
    32: 2694785,
    33: 2512797,
    34: 2441019,
    35: 2380890,
    36: 2329766,
    37: 2264038,
    38: 2229425,
    39: 2244801,
    40: 2225105,
    41: 2229195,
    42: 2236552,
    43: 2277211,
    44: 2408506,
    45: 2330127,
    46: 2351167,
    47: 2348110,
    48: 2362543,
    49: 2456746,
    50: 2386889,
    51: 2409373,
    52: 2431597,
    53: 2356947,
    54: 2375247,
    55: 2217740,
    56: 2106654,
    57: 2033465,
    58: 2006778,
    59: 2021247,
    60: 1903449,
    61: 1910517,
    62: 1851218,
    63: 1816165,
    64: 1846090,
    65: 1732362,
    66: 1675017,
    67: 1558455,
    68: 1490560,
    69: 1407520,
    70: 1258581,
    71: 1211742,
    72: 1074128,
    73: 1100498,
    74: 1065364,
    75: 932416,
    76: 851913,
    77: 811685,
    78: 798790,
    79: 731586,
    80: 627172,
    81: 611424,
    82: 525655,
    83: 456169,
    84: 434142,
    85: 1706304,
    "75+": 0
  },
  1975: {
    0: 3152345,
    1: 3005595,
    2: 3051605,
    3: 3276743,
    4: 3635195,
    5: 3545888,
    6: 3467501,
    7: 3467426,
    8: 3463857,
    9: 3649460,
    10: 3972391,
    11: 4056388,
    12: 4106910,
    13: 4189041,
    14: 4321156,
    15: 4268471,
    16: 4263489,
    17: 4274385,
    18: 4255537,
    19: 4222944,
    20: 4187886,
    21: 4007933,
    22: 3862914,
    23: 3742778,
    24: 3725325,
    25: 3572437,
    26: 3538718,
    27: 3564531,
    28: 3762889,
    29: 2841781,
    30: 2966547,
    31: 2893216,
    32: 3070791,
    33: 2673948,
    34: 2586404,
    35: 2422829,
    36: 2359517,
    37: 2333477,
    38: 2244458,
    39: 2273389,
    40: 2272232,
    41: 2191105,
    42: 2230660,
    43: 2218980,
    44: 2284183,
    45: 2391229,
    46: 2317277,
    47: 2348673,
    48: 2324153,
    49: 2403030,
    50: 2399716,
    51: 2419176,
    52: 2395310,
    53: 2424569,
    54: 2333508,
    55: 2346160,
    56: 2200058,
    57: 2086289,
    58: 2006936,
    59: 2006314,
    60: 1928253,
    61: 1931107,
    62: 1899484,
    63: 1839876,
    64: 1800531,
    65: 1827810,
    66: 1707370,
    67: 1636899,
    68: 1519930,
    69: 1440341,
    70: 1300705,
    71: 1248110,
    72: 1149406,
    73: 1038666,
    74: 1047781,
    75: 1013748,
    76: 896927,
    77: 807752,
    78: 785263,
    79: 742366,
    80: 650375,
    81: 612719,
    82: 566180,
    83: 479380,
    84: 403527,
    85: 1821040,
    "75+": 0
  },
  1976: {
    0: 3115391,
    1: 3079357,
    2: 2986220,
    3: 3100598,
    4: 3335549,
    5: 3634324,
    6: 3560219,
    7: 3526690,
    8: 3387656,
    9: 3562384,
    10: 3756460,
    11: 3900197,
    12: 4055659,
    13: 4132265,
    14: 4243008,
    15: 4340062,
    16: 4259631,
    17: 4275907,
    18: 4265516,
    19: 4393025,
    20: 4243079,
    21: 4093340,
    22: 3970413,
    23: 3852956,
    24: 3826361,
    25: 3721512,
    26: 3545226,
    27: 3585646,
    28: 3490185,
    29: 3931716,
    30: 2912673,
    31: 2860541,
    32: 2914670,
    33: 3043499,
    34: 2753188,
    35: 2562647,
    36: 2396170,
    37: 2363665,
    38: 2308235,
    39: 2296776,
    40: 2304558,
    41: 2228690,
    42: 2192835,
    43: 2211075,
    44: 2229200,
    45: 2266037,
    46: 2378001,
    47: 2315799,
    48: 2320528,
    49: 2371690,
    50: 2335316,
    51: 2440346,
    52: 2403540,
    53: 2390178,
    54: 2400661,
    55: 2303077,
    56: 2329991,
    57: 2179968,
    58: 2059645,
    59: 2011432,
    60: 1901134,
    61: 1970410,
    62: 1921812,
    63: 1887693,
    64: 1820831,
    65: 1779323,
    66: 1801871,
    67: 1666707,
    68: 1599695,
    69: 1470385,
    70: 1322033,
    71: 1302736,
    72: 1184467,
    73: 1120924,
    74: 988452,
    75: 996921,
    76: 985573,
    77: 853527,
    78: 783383,
    79: 725770,
    80: 651460,
    81: 643468,
    82: 565230,
    83: 517210,
    84: 422671,
    85: 1896295,
    "75+": 0
  },
  1977: {
    0: 3278804,
    1: 3031462,
    2: 3063419,
    3: 3034854,
    4: 3155120,
    5: 3333554,
    6: 3644207,
    7: 3625593,
    8: 3435855,
    9: 3490526,
    10: 3656833,
    11: 3695735,
    12: 3891370,
    13: 4081028,
    14: 4178966,
    15: 4264166,
    16: 4333169,
    17: 4268477,
    18: 4257434,
    19: 4417073,
    20: 4400497,
    21: 4149501,
    22: 4056115,
    23: 3964026,
    24: 3929083,
    25: 3826800,
    26: 3693845,
    27: 3589831,
    28: 3494422,
    29: 3672595,
    30: 4000734,
    31: 2815514,
    32: 2880847,
    33: 2887476,
    34: 3136162,
    35: 2722642,
    36: 2528601,
    37: 2400270,
    38: 2332394,
    39: 2369817,
    40: 2331327,
    41: 2251042,
    42: 2230600,
    43: 2171159,
    44: 2224619,
    45: 2209811,
    46: 2253199,
    47: 2377766,
    48: 2284194,
    49: 2376177,
    50: 2294077,
    51: 2383193,
    52: 2424062,
    53: 2401390,
    54: 2366486,
    55: 2369269,
    56: 2287932,
    57: 2310159,
    58: 2154049,
    59: 2070191,
    60: 1893305,
    61: 1955363,
    62: 1963589,
    63: 1909245,
    64: 1866559,
    65: 1798332,
    66: 1751748,
    67: 1758964,
    68: 1631626,
    69: 1549926,
    70: 1340517,
    71: 1335833,
    72: 1236501,
    73: 1162381,
    74: 1072264,
    75: 938830,
    76: 973432,
    77: 944545,
    78: 834999,
    79: 723205,
    80: 628694,
    81: 652138,
    82: 594765,
    83: 515163,
    84: 456302,
    85: 1991680,
    "75+": 0
  },
  1978: {
    0: 3326388,
    1: 3178511,
    2: 3022264,
    3: 3117040,
    4: 3090801,
    5: 3156246,
    6: 3343315,
    7: 3721084,
    8: 3527457,
    9: 3551925,
    10: 3578599,
    11: 3609470,
    12: 3684367,
    13: 3921073,
    14: 4126053,
    15: 4208291,
    16: 4266046,
    17: 4345269,
    18: 4247327,
    19: 4429193,
    20: 4417809,
    21: 4312079,
    22: 4118791,
    23: 4059239,
    24: 4038042,
    25: 3939843,
    26: 3804556,
    27: 3742779,
    28: 3487909,
    29: 3707919,
    30: 3720727,
    31: 3881900,
    32: 2839749,
    33: 2858221,
    34: 2979500,
    35: 3100910,
    36: 2685064,
    37: 2537539,
    38: 2366745,
    39: 2406607,
    40: 2412529,
    41: 2271342,
    42: 2256218,
    43: 2209173,
    44: 2190694,
    45: 2206330,
    46: 2200002,
    47: 2256874,
    48: 2345248,
    49: 2349475,
    50: 2289787,
    51: 2351038,
    52: 2368039,
    53: 2426806,
    54: 2380002,
    55: 2336478,
    56: 2357458,
    57: 2271458,
    58: 2287211,
    59: 2172836,
    60: 1937220,
    61: 1961844,
    62: 1950836,
    63: 1951261,
    64: 1885688,
    65: 1842735,
    66: 1768230,
    67: 1706417,
    68: 1726241,
    69: 1581887,
    70: 1403978,
    71: 1364772,
    72: 1266367,
    73: 1221003,
    74: 1113647,
    75: 1021824,
    76: 911426,
    77: 931770,
    78: 935241,
    79: 770914,
    80: 617301,
    81: 632018,
    82: 600711,
    83: 540861,
    84: 449750,
    85: 2094958,
    "75+": 0
  },
  1979: {
    0: 3426309,
    1: 3211427,
    2: 3173548,
    3: 3077017,
    4: 3174581,
    5: 3092294,
    6: 3163905,
    7: 3421248,
    8: 3613454,
    9: 3655724,
    10: 3634230,
    11: 3541822,
    12: 3593400,
    13: 3715376,
    14: 3960321,
    15: 4159794,
    16: 4214501,
    17: 4276495,
    18: 4316231,
    19: 4434889,
    20: 4419481,
    21: 4334896,
    22: 4284816,
    23: 4129391,
    24: 4127989,
    25: 4056482,
    26: 3919839,
    27: 3853208,
    28: 3621118,
    29: 3727046,
    30: 3733982,
    31: 3619819,
    32: 3907187,
    33: 2815685,
    34: 2948258,
    35: 2941406,
    36: 3050832,
    37: 2695152,
    38: 2496870,
    39: 2451245,
    40: 2454323,
    41: 2342000,
    42: 2277691,
    43: 2233036,
    44: 2233444,
    45: 2171900,
    46: 2196871,
    47: 2205236,
    48: 2222041,
    49: 2421037,
    50: 2253574,
    51: 2355872,
    52: 2335726,
    53: 2373821,
    54: 2405947,
    55: 2349711,
    56: 2327033,
    57: 2342711,
    58: 2247848,
    59: 2314488,
    60: 2020358,
    61: 2022892,
    62: 1959688,
    63: 1937597,
    64: 1925630,
    65: 1860719,
    66: 1811718,
    67: 1722200,
    68: 1675949,
    69: 1674541,
    70: 1423281,
    71: 1444508,
    72: 1293962,
    73: 1257794,
    74: 1173380,
    75: 1064334,
    76: 1001631,
    77: 871161,
    78: 926320,
    79: 860125,
    80: 653467,
    81: 625233,
    82: 580415,
    83: 545463,
    84: 470622,
    85: 2196921,
    "75+": 0
  }
};

export const retro = [
  {
    year: 1900,
    0: 1811000,
    1: 1835000,
    2: 1846000,
    3: 1848000,
    4: 1841000,
    5: 1827000,
    6: 1806000,
    7: 1780000,
    8: 1750000,
    9: 1717000,
    10: 1682000,
    11: 1644000,
    12: 1611000,
    13: 1585000,
    14: 1564000,
    15: 1544000,
    16: 1519000,
    17: 1505000,
    18: 1500000,
    19: 1500000,
    20: 1500000,
    21: 1500000,
    22: 1491000,
    23: 1465000,
    24: 1427000,
    25: 1392000,
    26: 1356000,
    27: 1316000,
    28: 1275000,
    29: 1233000,
    30: 1191000,
    31: 1145000,
    32: 1108000,
    33: 1082000,
    34: 1063000,
    35: 1044000,
    36: 1025000,
    37: 1004000,
    38: 977000,
    39: 946000,
    40: 917000,
    41: 888000,
    42: 859000,
    43: 824000,
    44: 789000,
    45: 753000,
    46: 718000,
    47: 688000,
    48: 666000,
    49: 650000,
    50: 635000,
    51: 622000,
    52: 600000,
    53: 571000,
    54: 534000,
    55: 500000,
    56: 465000,
    57: 437000,
    58: 417000,
    59: 405000,
    60: 391000,
    61: 380000,
    62: 364000,
    63: 345000,
    64: 322000,
    65: 302000,
    66: 281000,
    67: 262000,
    68: 243000,
    69: 224000,
    70: 207000,
    71: 191000,
    72: 176000,
    73: 163000,
    74: 151000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 899000
  },
  {
    year: 1901,
    0: 1850000,
    1: 1869000,
    2: 1876000,
    3: 1876000,
    4: 1865000,
    5: 1849000,
    6: 1826000,
    7: 1799000,
    8: 1768000,
    9: 1735000,
    10: 1699000,
    11: 1661000,
    12: 1630000,
    13: 1605000,
    14: 1586000,
    15: 1567000,
    16: 1544000,
    17: 1531000,
    18: 1527000,
    19: 1529000,
    20: 1530000,
    21: 1532000,
    22: 1524000,
    23: 1498000,
    24: 1460000,
    25: 1424000,
    26: 1387000,
    27: 1349000,
    28: 1306000,
    29: 1263000,
    30: 1217000,
    31: 1170000,
    32: 1131000,
    33: 1106000,
    34: 1089000,
    35: 1070000,
    36: 1052000,
    37: 1031000,
    38: 1003000,
    39: 970000,
    40: 940000,
    41: 910000,
    42: 877000,
    43: 843000,
    44: 808000,
    45: 773000,
    46: 734000,
    47: 704000,
    48: 683000,
    49: 667000,
    50: 651000,
    51: 639000,
    52: 619000,
    53: 587000,
    54: 549000,
    55: 514000,
    56: 477000,
    57: 446000,
    58: 427000,
    59: 415000,
    60: 401000,
    61: 387000,
    62: 372000,
    63: 353000,
    64: 330000,
    65: 308000,
    66: 288000,
    67: 268000,
    68: 250000,
    69: 231000,
    70: 212000,
    71: 196000,
    72: 179000,
    73: 166000,
    74: 154000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 922000
  },
  {
    year: 1902,
    0: 1892000,
    1: 1906000,
    2: 1910000,
    3: 1904000,
    4: 1890000,
    5: 1873000,
    6: 1848000,
    7: 1818000,
    8: 1788000,
    9: 1752000,
    10: 1717000,
    11: 1681000,
    12: 1648000,
    13: 1625000,
    14: 1610000,
    15: 1591000,
    16: 1572000,
    17: 1560000,
    18: 1558000,
    19: 1561000,
    20: 1563000,
    21: 1566000,
    22: 1557000,
    23: 1532000,
    24: 1495000,
    25: 1457000,
    26: 1422000,
    27: 1381000,
    28: 1338000,
    29: 1292000,
    30: 1246000,
    31: 1197000,
    32: 1157000,
    33: 1132000,
    34: 1115000,
    35: 1096000,
    36: 1081000,
    37: 1059000,
    38: 1030000,
    39: 995000,
    40: 963000,
    41: 932000,
    42: 899000,
    43: 863000,
    44: 827000,
    45: 790000,
    46: 754000,
    47: 723000,
    48: 701000,
    49: 686000,
    50: 671000,
    51: 657000,
    52: 637000,
    53: 604000,
    54: 565000,
    55: 527000,
    56: 490000,
    57: 459000,
    58: 438000,
    59: 424000,
    60: 409000,
    61: 396000,
    62: 380000,
    63: 360000,
    64: 337000,
    65: 316000,
    66: 296000,
    67: 275000,
    68: 256000,
    69: 238000,
    70: 218000,
    71: 201000,
    72: 185000,
    73: 170000,
    74: 156000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 945000
  },
  {
    year: 1903,
    0: 1930000,
    1: 1938000,
    2: 1938000,
    3: 1927000,
    4: 1912000,
    5: 1889000,
    6: 1864000,
    7: 1834000,
    8: 1801000,
    9: 1767000,
    10: 1733000,
    11: 1695000,
    12: 1666000,
    13: 1644000,
    14: 1631000,
    15: 1615000,
    16: 1598000,
    17: 1589000,
    18: 1588000,
    19: 1592000,
    20: 1595000,
    21: 1598000,
    22: 1591000,
    23: 1565000,
    24: 1527000,
    25: 1492000,
    26: 1454000,
    27: 1413000,
    28: 1368000,
    29: 1321000,
    30: 1273000,
    31: 1221000,
    32: 1181000,
    33: 1155000,
    34: 1141000,
    35: 1124000,
    36: 1107000,
    37: 1086000,
    38: 1057000,
    39: 1020000,
    40: 985000,
    41: 951000,
    42: 918000,
    43: 881000,
    44: 845000,
    45: 809000,
    46: 771000,
    47: 740000,
    48: 720000,
    49: 704000,
    50: 689000,
    51: 676000,
    52: 654000,
    53: 621000,
    54: 580000,
    55: 540000,
    56: 501000,
    57: 468000,
    58: 447000,
    59: 434000,
    60: 418000,
    61: 404000,
    62: 389000,
    63: 368000,
    64: 344000,
    65: 324000,
    66: 303000,
    67: 282000,
    68: 262000,
    69: 243000,
    70: 225000,
    71: 206000,
    72: 189000,
    73: 173000,
    74: 158000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 970000
  },
  {
    year: 1904,
    0: 1969000,
    1: 1971000,
    2: 1965000,
    3: 1953000,
    4: 1933000,
    5: 1909000,
    6: 1880000,
    7: 1849000,
    8: 1817000,
    9: 1782000,
    10: 1748000,
    11: 1712000,
    12: 1683000,
    13: 1664000,
    14: 1653000,
    15: 1640000,
    16: 1627000,
    17: 1619000,
    18: 1620000,
    19: 1625000,
    20: 1628000,
    21: 1632000,
    22: 1625000,
    23: 1599000,
    24: 1563000,
    25: 1526000,
    26: 1488000,
    27: 1445000,
    28: 1400000,
    29: 1351000,
    30: 1301000,
    31: 1249000,
    32: 1206000,
    33: 1182000,
    34: 1167000,
    35: 1150000,
    36: 1137000,
    37: 1116000,
    38: 1083000,
    39: 1044000,
    40: 1008000,
    41: 973000,
    42: 936000,
    43: 900000,
    44: 864000,
    45: 827000,
    46: 790000,
    47: 759000,
    48: 738000,
    49: 723000,
    50: 709000,
    51: 696000,
    52: 675000,
    53: 638000,
    54: 595000,
    55: 555000,
    56: 512000,
    57: 478000,
    58: 457000,
    59: 442000,
    60: 426000,
    61: 413000,
    62: 397000,
    63: 376000,
    64: 354000,
    65: 331000,
    66: 310000,
    67: 290000,
    68: 270000,
    69: 249000,
    70: 231000,
    71: 211000,
    72: 194000,
    73: 176000,
    74: 159000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 993000
  },
  {
    year: 1905,
    0: 2010000,
    1: 2006000,
    2: 1995000,
    3: 1977000,
    4: 1956000,
    5: 1929000,
    6: 1898000,
    7: 1867000,
    8: 1832000,
    9: 1800000,
    10: 1765000,
    11: 1730000,
    12: 1703000,
    13: 1686000,
    14: 1678000,
    15: 1668000,
    16: 1656000,
    17: 1652000,
    18: 1653000,
    19: 1660000,
    20: 1665000,
    21: 1670000,
    22: 1664000,
    23: 1638000,
    24: 1600000,
    25: 1561000,
    26: 1523000,
    27: 1481000,
    28: 1434000,
    29: 1383000,
    30: 1332000,
    31: 1277000,
    32: 1235000,
    33: 1210000,
    34: 1195000,
    35: 1181000,
    36: 1166000,
    37: 1146000,
    38: 1113000,
    39: 1071000,
    40: 1032000,
    41: 994000,
    42: 955000,
    43: 919000,
    44: 884000,
    45: 848000,
    46: 811000,
    47: 779000,
    48: 758000,
    49: 744000,
    50: 730000,
    51: 716000,
    52: 695000,
    53: 657000,
    54: 612000,
    55: 570000,
    56: 526000,
    57: 490000,
    58: 467000,
    59: 451000,
    60: 437000,
    61: 423000,
    62: 405000,
    63: 386000,
    64: 362000,
    65: 339000,
    66: 319000,
    67: 297000,
    68: 277000,
    69: 257000,
    70: 236000,
    71: 217000,
    72: 199000,
    73: 181000,
    74: 164000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1019000
  },
  {
    year: 1906,
    0: 2050000,
    1: 2040000,
    2: 2024000,
    3: 2002000,
    4: 1976000,
    5: 1947000,
    6: 1914000,
    7: 1881000,
    8: 1848000,
    9: 1815000,
    10: 1782000,
    11: 1748000,
    12: 1722000,
    13: 1708000,
    14: 1702000,
    15: 1696000,
    16: 1687000,
    17: 1684000,
    18: 1688000,
    19: 1695000,
    20: 1699000,
    21: 1705000,
    22: 1699000,
    23: 1675000,
    24: 1636000,
    25: 1596000,
    26: 1559000,
    27: 1515000,
    28: 1466000,
    29: 1417000,
    30: 1363000,
    31: 1308000,
    32: 1263000,
    33: 1239000,
    34: 1226000,
    35: 1210000,
    36: 1198000,
    37: 1177000,
    38: 1142000,
    39: 1096000,
    40: 1056000,
    41: 1013000,
    42: 974000,
    43: 937000,
    44: 902000,
    45: 867000,
    46: 831000,
    47: 800000,
    48: 780000,
    49: 765000,
    50: 750000,
    51: 738000,
    52: 716000,
    53: 677000,
    54: 630000,
    55: 584000,
    56: 537000,
    57: 499000,
    58: 475000,
    59: 463000,
    60: 447000,
    61: 433000,
    62: 417000,
    63: 395000,
    64: 371000,
    65: 349000,
    66: 327000,
    67: 305000,
    68: 284000,
    69: 263000,
    70: 242000,
    71: 223000,
    72: 204000,
    73: 185000,
    74: 167000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1046000
  },
  {
    year: 1907,
    0: 2086000,
    1: 2069000,
    2: 2049000,
    3: 2023000,
    4: 1993000,
    5: 1963000,
    6: 1930000,
    7: 1896000,
    8: 1861000,
    9: 1830000,
    10: 1797000,
    11: 1765000,
    12: 1741000,
    13: 1730000,
    14: 1726000,
    15: 1721000,
    16: 1716000,
    17: 1715000,
    18: 1720000,
    19: 1728000,
    20: 1733000,
    21: 1739000,
    22: 1734000,
    23: 1709000,
    24: 1669000,
    25: 1631000,
    26: 1592000,
    27: 1548000,
    28: 1498000,
    29: 1446000,
    30: 1392000,
    31: 1335000,
    32: 1292000,
    33: 1267000,
    34: 1256000,
    35: 1240000,
    36: 1228000,
    37: 1207000,
    38: 1170000,
    39: 1122000,
    40: 1077000,
    41: 1033000,
    42: 992000,
    43: 955000,
    44: 921000,
    45: 887000,
    46: 851000,
    47: 822000,
    48: 800000,
    49: 786000,
    50: 771000,
    51: 759000,
    52: 737000,
    53: 695000,
    54: 647000,
    55: 598000,
    56: 549000,
    57: 509000,
    58: 486000,
    59: 473000,
    60: 457000,
    61: 442000,
    62: 426000,
    63: 404000,
    64: 380000,
    65: 357000,
    66: 334000,
    67: 313000,
    68: 291000,
    69: 270000,
    70: 249000,
    71: 229000,
    72: 208000,
    73: 189000,
    74: 171000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1073000
  },
  {
    year: 1908,
    0: 2125000,
    1: 2102000,
    2: 2076000,
    3: 2046000,
    4: 2015000,
    5: 1981000,
    6: 1948000,
    7: 1913000,
    8: 1880000,
    9: 1847000,
    10: 1816000,
    11: 1785000,
    12: 1764000,
    13: 1753000,
    14: 1753000,
    15: 1749000,
    16: 1748000,
    17: 1749000,
    18: 1754000,
    19: 1762000,
    20: 1769000,
    21: 1775000,
    22: 1770000,
    23: 1744000,
    24: 1706000,
    25: 1666000,
    26: 1628000,
    27: 1582000,
    28: 1532000,
    29: 1480000,
    30: 1425000,
    31: 1367000,
    32: 1322000,
    33: 1297000,
    34: 1286000,
    35: 1273000,
    36: 1261000,
    37: 1239000,
    38: 1199000,
    39: 1149000,
    40: 1101000,
    41: 1053000,
    42: 1011000,
    43: 974000,
    44: 942000,
    45: 909000,
    46: 873000,
    47: 845000,
    48: 825000,
    49: 810000,
    50: 793000,
    51: 780000,
    52: 758000,
    53: 717000,
    54: 664000,
    55: 613000,
    56: 563000,
    57: 521000,
    58: 496000,
    59: 483000,
    60: 467000,
    61: 455000,
    62: 436000,
    63: 416000,
    64: 390000,
    65: 366000,
    66: 345000,
    67: 321000,
    68: 299000,
    69: 276000,
    70: 255000,
    71: 234000,
    72: 213000,
    73: 193000,
    74: 174000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1103000
  },
  {
    year: 1909,
    0: 2162000,
    1: 2134000,
    2: 2104000,
    3: 2072000,
    4: 2037000,
    5: 2002000,
    6: 1967000,
    7: 1933000,
    8: 1899000,
    9: 1869000,
    10: 1839000,
    11: 1809000,
    12: 1789000,
    13: 1781000,
    14: 1782000,
    15: 1780000,
    16: 1779000,
    17: 1782000,
    18: 1789000,
    19: 1798000,
    20: 1804000,
    21: 1811000,
    22: 1806000,
    23: 1781000,
    24: 1741000,
    25: 1702000,
    26: 1663000,
    27: 1618000,
    28: 1567000,
    29: 1513000,
    30: 1458000,
    31: 1399000,
    32: 1354000,
    33: 1330000,
    34: 1319000,
    35: 1305000,
    36: 1295000,
    37: 1273000,
    38: 1231000,
    39: 1177000,
    40: 1127000,
    41: 1075000,
    42: 1030000,
    43: 994000,
    44: 964000,
    45: 930000,
    46: 897000,
    47: 870000,
    48: 850000,
    49: 834000,
    50: 818000,
    51: 805000,
    52: 781000,
    53: 737000,
    54: 682000,
    55: 630000,
    56: 575000,
    57: 533000,
    58: 508000,
    59: 495000,
    60: 480000,
    61: 466000,
    62: 450000,
    63: 426000,
    64: 401000,
    65: 376000,
    66: 353000,
    67: 329000,
    68: 307000,
    69: 284000,
    70: 261000,
    71: 239000,
    72: 218000,
    73: 198000,
    74: 179000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1134000
  },
  {
    year: 1910,
    0: 2203000,
    1: 2168000,
    2: 2135000,
    3: 2101000,
    4: 2064000,
    5: 2029000,
    6: 1993000,
    7: 1959000,
    8: 1926000,
    9: 1896000,
    10: 1867000,
    11: 1839000,
    12: 1818000,
    13: 1811000,
    14: 1812000,
    15: 1812000,
    16: 1813000,
    17: 1815000,
    18: 1823000,
    19: 1832000,
    20: 1839000,
    21: 1846000,
    22: 1841000,
    23: 1815000,
    24: 1776000,
    25: 1737000,
    26: 1699000,
    27: 1655000,
    28: 1603000,
    29: 1549000,
    30: 1494000,
    31: 1433000,
    32: 1387000,
    33: 1364000,
    34: 1353000,
    35: 1341000,
    36: 1332000,
    37: 1309000,
    38: 1264000,
    39: 1207000,
    40: 1154000,
    41: 1099000,
    42: 1052000,
    43: 1015000,
    44: 986000,
    45: 956000,
    46: 924000,
    47: 897000,
    48: 878000,
    49: 861000,
    50: 843000,
    51: 830000,
    52: 804000,
    53: 759000,
    54: 702000,
    55: 647000,
    56: 591000,
    57: 547000,
    58: 521000,
    59: 507000,
    60: 493000,
    61: 480000,
    62: 464000,
    63: 439000,
    64: 412000,
    65: 387000,
    66: 362000,
    67: 338000,
    68: 315000,
    69: 290000,
    70: 267000,
    71: 245000,
    72: 224000,
    73: 203000,
    74: 185000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1170000
  },
  {
    year: 1911,
    0: 2227000,
    1: 2194000,
    2: 2161000,
    3: 2125000,
    4: 2089000,
    5: 2055000,
    6: 2019000,
    7: 1985000,
    8: 1952000,
    9: 1923000,
    10: 1895000,
    11: 1867000,
    12: 1846000,
    13: 1837000,
    14: 1835000,
    15: 1831000,
    16: 1826000,
    17: 1827000,
    18: 1836000,
    19: 1843000,
    20: 1851000,
    21: 1859000,
    22: 1854000,
    23: 1832000,
    24: 1796000,
    25: 1760000,
    26: 1724000,
    27: 1681000,
    28: 1630000,
    29: 1576000,
    30: 1520000,
    31: 1458000,
    32: 1411000,
    33: 1389000,
    34: 1381000,
    35: 1370000,
    36: 1362000,
    37: 1340000,
    38: 1294000,
    39: 1232000,
    40: 1176000,
    41: 1118000,
    42: 1069000,
    43: 1034000,
    44: 1008000,
    45: 979000,
    46: 949000,
    47: 922000,
    48: 901000,
    49: 882000,
    50: 863000,
    51: 849000,
    52: 821000,
    53: 774000,
    54: 717000,
    55: 662000,
    56: 605000,
    57: 559000,
    58: 533000,
    59: 521000,
    60: 507000,
    61: 494000,
    62: 477000,
    63: 452000,
    64: 424000,
    65: 396000,
    66: 371000,
    67: 347000,
    68: 322000,
    69: 296000,
    70: 273000,
    71: 250000,
    72: 228000,
    73: 207000,
    74: 188000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1196000
  },
  {
    year: 1912,
    0: 2250000,
    1: 2217000,
    2: 2184000,
    3: 2149000,
    4: 2115000,
    5: 2080000,
    6: 2047000,
    7: 2013000,
    8: 1984000,
    9: 1953000,
    10: 1926000,
    11: 1899000,
    12: 1878000,
    13: 1865000,
    14: 1858000,
    15: 1849000,
    16: 1841000,
    17: 1840000,
    18: 1844000,
    19: 1854000,
    20: 1861000,
    21: 1869000,
    22: 1864000,
    23: 1844000,
    24: 1811000,
    25: 1780000,
    26: 1746000,
    27: 1707000,
    28: 1657000,
    29: 1601000,
    30: 1544000,
    31: 1482000,
    32: 1435000,
    33: 1413000,
    34: 1407000,
    35: 1398000,
    36: 1392000,
    37: 1370000,
    38: 1322000,
    39: 1260000,
    40: 1200000,
    41: 1139000,
    42: 1087000,
    43: 1054000,
    44: 1030000,
    45: 1003000,
    46: 975000,
    47: 950000,
    48: 928000,
    49: 906000,
    50: 885000,
    51: 867000,
    52: 838000,
    53: 791000,
    54: 732000,
    55: 677000,
    56: 618000,
    57: 573000,
    58: 546000,
    59: 536000,
    60: 521000,
    61: 508000,
    62: 492000,
    63: 466000,
    64: 435000,
    65: 407000,
    66: 379000,
    67: 354000,
    68: 326000,
    69: 302000,
    70: 277000,
    71: 253000,
    72: 234000,
    73: 213000,
    74: 197000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1227000
  },
  {
    year: 1913,
    0: 2279000,
    1: 2249000,
    2: 2217000,
    3: 2185000,
    4: 2152000,
    5: 2120000,
    6: 2088000,
    7: 2055000,
    8: 2026000,
    9: 1996000,
    10: 1968000,
    11: 1943000,
    12: 1919000,
    13: 1901000,
    14: 1888000,
    15: 1875000,
    16: 1860000,
    17: 1854000,
    18: 1859000,
    19: 1868000,
    20: 1875000,
    21: 1883000,
    22: 1880000,
    23: 1862000,
    24: 1833000,
    25: 1804000,
    26: 1774000,
    27: 1738000,
    28: 1687000,
    29: 1631000,
    30: 1574000,
    31: 1512000,
    32: 1465000,
    33: 1445000,
    34: 1440000,
    35: 1433000,
    36: 1429000,
    37: 1407000,
    38: 1358000,
    39: 1293000,
    40: 1229000,
    41: 1165000,
    42: 1113000,
    43: 1078000,
    44: 1057000,
    45: 1033000,
    46: 1007000,
    47: 982000,
    48: 958000,
    49: 933000,
    50: 911000,
    51: 890000,
    52: 858000,
    53: 811000,
    54: 752000,
    55: 696000,
    56: 636000,
    57: 591000,
    58: 564000,
    59: 553000,
    60: 538000,
    61: 526000,
    62: 508000,
    63: 481000,
    64: 449000,
    65: 419000,
    66: 390000,
    67: 362000,
    68: 335000,
    69: 309000,
    70: 283000,
    71: 259000,
    72: 239000,
    73: 219000,
    74: 203000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1263000
  },
  {
    year: 1914,
    0: 2305000,
    1: 2278000,
    2: 2250000,
    3: 2220000,
    4: 2191000,
    5: 2160000,
    6: 2129000,
    7: 2099000,
    8: 2070000,
    9: 2040000,
    10: 2012000,
    11: 1986000,
    12: 1961000,
    13: 1940000,
    14: 1919000,
    15: 1899000,
    16: 1878000,
    17: 1867000,
    18: 1869000,
    19: 1879000,
    20: 1887000,
    21: 1894000,
    22: 1893000,
    23: 1878000,
    24: 1852000,
    25: 1827000,
    26: 1802000,
    27: 1768000,
    28: 1720000,
    29: 1662000,
    30: 1604000,
    31: 1542000,
    32: 1495000,
    33: 1476000,
    34: 1474000,
    35: 1468000,
    36: 1465000,
    37: 1444000,
    38: 1395000,
    39: 1325000,
    40: 1259000,
    41: 1191000,
    42: 1137000,
    43: 1105000,
    44: 1086000,
    45: 1063000,
    46: 1040000,
    47: 1017000,
    48: 991000,
    49: 962000,
    50: 936000,
    51: 912000,
    52: 878000,
    53: 829000,
    54: 770000,
    55: 712000,
    56: 654000,
    57: 608000,
    58: 583000,
    59: 570000,
    60: 556000,
    61: 542000,
    62: 526000,
    63: 497000,
    64: 463000,
    65: 432000,
    66: 401000,
    67: 371000,
    68: 343000,
    69: 316000,
    70: 290000,
    71: 266000,
    72: 244000,
    73: 226000,
    74: 211000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1301000
  },
  {
    year: 1915,
    0: 2317000,
    1: 2295000,
    2: 2270000,
    3: 2246000,
    4: 2219000,
    5: 2191000,
    6: 2163000,
    7: 2135000,
    8: 2106000,
    9: 2079000,
    10: 2051000,
    11: 2024000,
    12: 1998000,
    13: 1970000,
    14: 1943000,
    15: 1916000,
    16: 1889000,
    17: 1871000,
    18: 1871000,
    19: 1881000,
    20: 1887000,
    21: 1895000,
    22: 1893000,
    23: 1881000,
    24: 1860000,
    25: 1839000,
    26: 1820000,
    27: 1788000,
    28: 1741000,
    29: 1685000,
    30: 1626000,
    31: 1563000,
    32: 1518000,
    33: 1499000,
    34: 1501000,
    35: 1496000,
    36: 1495000,
    37: 1475000,
    38: 1423000,
    39: 1352000,
    40: 1283000,
    41: 1213000,
    42: 1157000,
    43: 1126000,
    44: 1110000,
    45: 1089000,
    46: 1069000,
    47: 1046000,
    48: 1018000,
    49: 987000,
    50: 956000,
    51: 930000,
    52: 894000,
    53: 844000,
    54: 785000,
    55: 729000,
    56: 669000,
    57: 624000,
    58: 598000,
    59: 586000,
    60: 572000,
    61: 560000,
    62: 541000,
    63: 512000,
    64: 475000,
    65: 443000,
    66: 411000,
    67: 379000,
    68: 348000,
    69: 320000,
    70: 294000,
    71: 270000,
    72: 249000,
    73: 233000,
    74: 220000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1334000
  },
  {
    year: 1916,
    0: 2325000,
    1: 2308000,
    2: 2291000,
    3: 2270000,
    4: 2248000,
    5: 2223000,
    6: 2199000,
    7: 2172000,
    8: 2146000,
    9: 2118000,
    10: 2088000,
    11: 2063000,
    12: 2034000,
    13: 2000000,
    14: 1965000,
    15: 1932000,
    16: 1896000,
    17: 1872000,
    18: 1869000,
    19: 1880000,
    20: 1886000,
    21: 1893000,
    22: 1894000,
    23: 1884000,
    24: 1866000,
    25: 1851000,
    26: 1835000,
    27: 1807000,
    28: 1762000,
    29: 1704000,
    30: 1647000,
    31: 1583000,
    32: 1539000,
    33: 1523000,
    34: 1525000,
    35: 1523000,
    36: 1524000,
    37: 1505000,
    38: 1452000,
    39: 1379000,
    40: 1308000,
    41: 1236000,
    42: 1179000,
    43: 1148000,
    44: 1134000,
    45: 1116000,
    46: 1099000,
    47: 1077000,
    48: 1046000,
    49: 1012000,
    50: 978000,
    51: 948000,
    52: 910000,
    53: 859000,
    54: 801000,
    55: 745000,
    56: 686000,
    57: 640000,
    58: 615000,
    59: 602000,
    60: 589000,
    61: 576000,
    62: 558000,
    63: 527000,
    64: 488000,
    65: 453000,
    66: 419000,
    67: 388000,
    68: 356000,
    69: 325000,
    70: 298000,
    71: 275000,
    72: 254000,
    73: 238000,
    74: 229000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1368000
  },
  {
    year: 1917,
    0: 2329000,
    1: 2320000,
    2: 2308000,
    3: 2294000,
    4: 2276000,
    5: 2257000,
    6: 2235000,
    7: 2210000,
    8: 2185000,
    9: 2158000,
    10: 2129000,
    11: 2103000,
    12: 2071000,
    13: 2032000,
    14: 1989000,
    15: 1947000,
    16: 1904000,
    17: 1875000,
    18: 1868000,
    19: 1872000,
    20: 1876000,
    21: 1880000,
    22: 1880000,
    23: 1872000,
    24: 1862000,
    25: 1848000,
    26: 1840000,
    27: 1817000,
    28: 1774000,
    29: 1718000,
    30: 1663000,
    31: 1601000,
    32: 1557000,
    33: 1545000,
    34: 1550000,
    35: 1549000,
    36: 1554000,
    37: 1536000,
    38: 1481000,
    39: 1406000,
    40: 1334000,
    41: 1258000,
    42: 1200000,
    43: 1170000,
    44: 1159000,
    45: 1144000,
    46: 1127000,
    47: 1108000,
    48: 1073000,
    49: 1036000,
    50: 999000,
    51: 966000,
    52: 925000,
    53: 873000,
    54: 817000,
    55: 762000,
    56: 704000,
    57: 658000,
    58: 632000,
    59: 621000,
    60: 606000,
    61: 593000,
    62: 575000,
    63: 542000,
    64: 501000,
    65: 464000,
    66: 430000,
    67: 396000,
    68: 361000,
    69: 332000,
    70: 305000,
    71: 281000,
    72: 261000,
    73: 246000,
    74: 237000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1401000
  },
  {
    year: 1918,
    0: 2332000,
    1: 2330000,
    2: 2324000,
    3: 2316000,
    4: 2304000,
    5: 2288000,
    6: 2270000,
    7: 2250000,
    8: 2226000,
    9: 2199000,
    10: 2171000,
    11: 2141000,
    12: 2107000,
    13: 2064000,
    14: 2016000,
    15: 1971000,
    16: 1925000,
    17: 1882000,
    18: 1843000,
    19: 1808000,
    20: 1771000,
    21: 1734000,
    22: 1711000,
    23: 1709000,
    24: 1717000,
    25: 1725000,
    26: 1738000,
    27: 1735000,
    28: 1708000,
    29: 1667000,
    30: 1626000,
    31: 1582000,
    32: 1553000,
    33: 1549000,
    34: 1562000,
    35: 1567000,
    36: 1578000,
    37: 1563000,
    38: 1509000,
    39: 1431000,
    40: 1358000,
    41: 1279000,
    42: 1219000,
    43: 1191000,
    44: 1184000,
    45: 1170000,
    46: 1158000,
    47: 1137000,
    48: 1103000,
    49: 1060000,
    50: 1020000,
    51: 983000,
    52: 940000,
    53: 889000,
    54: 833000,
    55: 777000,
    56: 720000,
    57: 674000,
    58: 650000,
    59: 637000,
    60: 623000,
    61: 611000,
    62: 591000,
    63: 558000,
    64: 515000,
    65: 476000,
    66: 440000,
    67: 404000,
    68: 370000,
    69: 337000,
    70: 311000,
    71: 286000,
    72: 267000,
    73: 253000,
    74: 247000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1435000
  },
  {
    year: 1919,
    0: 2299000,
    1: 2308000,
    2: 2312000,
    3: 2312000,
    4: 2305000,
    5: 2297000,
    6: 2282000,
    7: 2263000,
    8: 2242000,
    9: 2214000,
    10: 2186000,
    11: 2158000,
    12: 2121000,
    13: 2071000,
    14: 2015000,
    15: 1959000,
    16: 1903000,
    17: 1860000,
    18: 1839000,
    19: 1833000,
    20: 1824000,
    21: 1816000,
    22: 1811000,
    23: 1810000,
    24: 1810000,
    25: 1811000,
    26: 1815000,
    27: 1805000,
    28: 1770000,
    29: 1717000,
    30: 1667000,
    31: 1611000,
    32: 1574000,
    33: 1566000,
    34: 1576000,
    35: 1582000,
    36: 1592000,
    37: 1577000,
    38: 1521000,
    39: 1443000,
    40: 1369000,
    41: 1291000,
    42: 1231000,
    43: 1204000,
    44: 1198000,
    45: 1187000,
    46: 1174000,
    47: 1153000,
    48: 1118000,
    49: 1072000,
    50: 1030000,
    51: 990000,
    52: 945000,
    53: 893000,
    54: 840000,
    55: 785000,
    56: 730000,
    57: 685000,
    58: 661000,
    59: 649000,
    60: 634000,
    61: 621000,
    62: 602000,
    63: 567000,
    64: 522000,
    65: 483000,
    66: 444000,
    67: 408000,
    68: 372000,
    69: 341000,
    70: 313000,
    71: 289000,
    72: 271000,
    73: 257000,
    74: 254000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1454000
  },
  {
    year: 1920,
    0: 2277000,
    1: 2313000,
    2: 2337000,
    3: 2351000,
    4: 2353000,
    5: 2347000,
    6: 2332000,
    7: 2311000,
    8: 2283000,
    9: 2250000,
    10: 2213000,
    11: 2173000,
    12: 2129000,
    13: 2084000,
    14: 2036000,
    15: 1989000,
    16: 1940000,
    17: 1904000,
    18: 1880000,
    19: 1869000,
    20: 1855000,
    21: 1840000,
    22: 1835000,
    23: 1845000,
    24: 1864000,
    25: 1881000,
    26: 1904000,
    27: 1900000,
    28: 1854000,
    29: 1782000,
    30: 1711000,
    31: 1636000,
    32: 1584000,
    33: 1574000,
    34: 1590000,
    35: 1599000,
    36: 1610000,
    37: 1599000,
    38: 1553000,
    39: 1482000,
    40: 1417000,
    41: 1348000,
    42: 1291000,
    43: 1254000,
    44: 1229000,
    45: 1201000,
    46: 1171000,
    47: 1140000,
    48: 1108000,
    49: 1073000,
    50: 1042000,
    51: 1012000,
    52: 973000,
    53: 922000,
    54: 863000,
    55: 806000,
    56: 748000,
    57: 701000,
    58: 676000,
    59: 665000,
    60: 650000,
    61: 639000,
    62: 619000,
    63: 581000,
    64: 534000,
    65: 493000,
    66: 452000,
    67: 413000,
    68: 377000,
    69: 343000,
    70: 315000,
    71: 291000,
    72: 273000,
    73: 263000,
    74: 260000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1449000
  },
  {
    year: 1921,
    0: 2362000,
    1: 2375000,
    2: 2383000,
    3: 2382000,
    4: 2377000,
    5: 2366000,
    6: 2351000,
    7: 2332000,
    8: 2308000,
    9: 2279000,
    10: 2250000,
    11: 2220000,
    12: 2182000,
    13: 2138000,
    14: 2089000,
    15: 2042000,
    16: 1994000,
    17: 1954000,
    18: 1925000,
    19: 1902000,
    20: 1881000,
    21: 1855000,
    22: 1845000,
    23: 1858000,
    24: 1884000,
    25: 1909000,
    26: 1940000,
    27: 1942000,
    28: 1895000,
    29: 1819000,
    30: 1746000,
    31: 1668000,
    32: 1613000,
    33: 1600000,
    34: 1615000,
    35: 1619000,
    36: 1627000,
    37: 1616000,
    38: 1572000,
    39: 1508000,
    40: 1448000,
    41: 1389000,
    42: 1333000,
    43: 1293000,
    44: 1260000,
    45: 1223000,
    46: 1185000,
    47: 1151000,
    48: 1119000,
    49: 1091000,
    50: 1064000,
    51: 1039000,
    52: 1004000,
    53: 953000,
    54: 892000,
    55: 833000,
    56: 772000,
    57: 725000,
    58: 698000,
    59: 682000,
    60: 665000,
    61: 649000,
    62: 628000,
    63: 591000,
    64: 548000,
    65: 507000,
    66: 469000,
    67: 432000,
    68: 395000,
    69: 362000,
    70: 331000,
    71: 304000,
    72: 281000,
    73: 265000,
    74: 253000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1481000
  },
  {
    year: 1922,
    0: 2413000,
    1: 2413000,
    2: 2408000,
    3: 2403000,
    4: 2394000,
    5: 2382000,
    6: 2366000,
    7: 2349000,
    8: 2328000,
    9: 2303000,
    10: 2278000,
    11: 2252000,
    12: 2220000,
    13: 2178000,
    14: 2132000,
    15: 2086000,
    16: 2041000,
    17: 2001000,
    18: 1966000,
    19: 1935000,
    20: 1904000,
    21: 1870000,
    22: 1853000,
    23: 1861000,
    24: 1885000,
    25: 1906000,
    26: 1933000,
    27: 1934000,
    28: 1896000,
    29: 1833000,
    30: 1773000,
    31: 1710000,
    32: 1662000,
    33: 1641000,
    34: 1636000,
    35: 1626000,
    36: 1616000,
    37: 1596000,
    38: 1561000,
    39: 1515000,
    40: 1471000,
    41: 1426000,
    42: 1382000,
    43: 1338000,
    44: 1292000,
    45: 1245000,
    46: 1197000,
    47: 1156000,
    48: 1127000,
    49: 1104000,
    50: 1081000,
    51: 1060000,
    52: 1029000,
    53: 979000,
    54: 921000,
    55: 864000,
    56: 807000,
    57: 759000,
    58: 726000,
    59: 701000,
    60: 676000,
    61: 651000,
    62: 623000,
    63: 591000,
    64: 553000,
    65: 518000,
    66: 485000,
    67: 451000,
    68: 418000,
    69: 383000,
    70: 352000,
    71: 322000,
    72: 292000,
    73: 263000,
    74: 235000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1512000
  },
  {
    year: 1923,
    0: 2433000,
    1: 2430000,
    2: 2426000,
    3: 2420000,
    4: 2410000,
    5: 2401000,
    6: 2387000,
    7: 2372000,
    8: 2352000,
    9: 2332000,
    10: 2309000,
    11: 2286000,
    12: 2256000,
    13: 2218000,
    14: 2176000,
    15: 2133000,
    16: 2093000,
    17: 2052000,
    18: 2015000,
    19: 1981000,
    20: 1947000,
    21: 1910000,
    22: 1888000,
    23: 1885000,
    24: 1894000,
    25: 1901000,
    26: 1910000,
    27: 1908000,
    28: 1886000,
    29: 1853000,
    30: 1821000,
    31: 1788000,
    32: 1755000,
    33: 1721000,
    34: 1688000,
    35: 1654000,
    36: 1615000,
    37: 1582000,
    38: 1552000,
    39: 1526000,
    40: 1499000,
    41: 1473000,
    42: 1439000,
    43: 1391000,
    44: 1335000,
    45: 1281000,
    46: 1224000,
    47: 1176000,
    48: 1142000,
    49: 1118000,
    50: 1092000,
    51: 1066000,
    52: 1034000,
    53: 992000,
    54: 943000,
    55: 896000,
    56: 850000,
    57: 805000,
    58: 765000,
    59: 729000,
    60: 692000,
    61: 655000,
    62: 621000,
    63: 590000,
    64: 562000,
    65: 532000,
    66: 503000,
    67: 473000,
    68: 443000,
    69: 411000,
    70: 377000,
    71: 342000,
    72: 303000,
    73: 263000,
    74: 218000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1546000
  },
  {
    year: 1924,
    0: 2476000,
    1: 2465000,
    2: 2454000,
    3: 2443000,
    4: 2431000,
    5: 2419000,
    6: 2404000,
    7: 2389000,
    8: 2373000,
    9: 2354000,
    10: 2335000,
    11: 2315000,
    12: 2290000,
    13: 2257000,
    14: 2222000,
    15: 2185000,
    16: 2149000,
    17: 2113000,
    18: 2076000,
    19: 2040000,
    20: 2005000,
    21: 1970000,
    22: 1941000,
    23: 1923000,
    24: 1912000,
    25: 1901000,
    26: 1888000,
    27: 1880000,
    28: 1874000,
    29: 1872000,
    30: 1869000,
    31: 1868000,
    32: 1850000,
    33: 1807000,
    34: 1748000,
    35: 1690000,
    36: 1627000,
    37: 1579000,
    38: 1554000,
    39: 1542000,
    40: 1527000,
    41: 1513000,
    42: 1486000,
    43: 1439000,
    44: 1380000,
    45: 1322000,
    46: 1264000,
    47: 1213000,
    48: 1172000,
    49: 1140000,
    50: 1105000,
    51: 1070000,
    52: 1035000,
    53: 997000,
    54: 960000,
    55: 923000,
    56: 887000,
    57: 849000,
    58: 805000,
    59: 757000,
    60: 712000,
    61: 664000,
    62: 623000,
    63: 594000,
    64: 573000,
    65: 547000,
    66: 521000,
    67: 494000,
    68: 466000,
    69: 435000,
    70: 401000,
    71: 362000,
    72: 317000,
    73: 268000,
    74: 211000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1587000
  },
  {
    year: 1925,
    0: 2460000,
    1: 2465000,
    2: 2467000,
    3: 2465000,
    4: 2459000,
    5: 2449000,
    6: 2436000,
    7: 2420000,
    8: 2401000,
    9: 2381000,
    10: 2358000,
    11: 2333000,
    12: 2307000,
    13: 2279000,
    14: 2250000,
    15: 2222000,
    16: 2193000,
    17: 2160000,
    18: 2124000,
    19: 2085000,
    20: 2047000,
    21: 2012000,
    22: 1978000,
    23: 1947000,
    24: 1923000,
    25: 1896000,
    26: 1866000,
    27: 1852000,
    28: 1859000,
    29: 1877000,
    30: 1893000,
    31: 1915000,
    32: 1909000,
    33: 1863000,
    34: 1790000,
    35: 1720000,
    36: 1644000,
    37: 1588000,
    38: 1564000,
    39: 1560000,
    40: 1551000,
    41: 1540000,
    42: 1518000,
    43: 1474000,
    44: 1417000,
    45: 1363000,
    46: 1309000,
    47: 1256000,
    48: 1209000,
    49: 1167000,
    50: 1123000,
    51: 1079000,
    52: 1039000,
    53: 1003000,
    54: 973000,
    55: 944000,
    56: 916000,
    57: 882000,
    58: 837000,
    59: 783000,
    60: 733000,
    61: 681000,
    62: 637000,
    63: 607000,
    64: 585000,
    65: 562000,
    66: 536000,
    67: 509000,
    68: 481000,
    69: 449000,
    70: 415000,
    71: 376000,
    72: 329000,
    73: 279000,
    74: 219000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1631000
  },
  {
    year: 1926,
    0: 2366000,
    1: 2414000,
    2: 2451000,
    3: 2473000,
    4: 2485000,
    5: 2486000,
    6: 2479000,
    7: 2464000,
    8: 2444000,
    9: 2417000,
    10: 2386000,
    11: 2350000,
    12: 2315000,
    13: 2292000,
    14: 2273000,
    15: 2252000,
    16: 2229000,
    17: 2202000,
    18: 2166000,
    19: 2124000,
    20: 2085000,
    21: 2049000,
    22: 2012000,
    23: 1976000,
    24: 1942000,
    25: 1911000,
    26: 1873000,
    27: 1854000,
    28: 1862000,
    29: 1887000,
    30: 1909000,
    31: 1935000,
    32: 1934000,
    33: 1888000,
    34: 1814000,
    35: 1743000,
    36: 1667000,
    37: 1611000,
    38: 1588000,
    39: 1586000,
    40: 1578000,
    41: 1570000,
    42: 1548000,
    43: 1506000,
    44: 1450000,
    45: 1396000,
    46: 1343000,
    47: 1289000,
    48: 1242000,
    49: 1196000,
    50: 1149000,
    51: 1100000,
    52: 1056000,
    53: 1021000,
    54: 994000,
    55: 965000,
    56: 940000,
    57: 905000,
    58: 858000,
    59: 805000,
    60: 752000,
    61: 700000,
    62: 655000,
    63: 624000,
    64: 601000,
    65: 576000,
    66: 548000,
    67: 520000,
    68: 491000,
    69: 461000,
    70: 426000,
    71: 388000,
    72: 343000,
    73: 294000,
    74: 236000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1677000
  },
  {
    year: 1927,
    0: 2305000,
    1: 2383000,
    2: 2441000,
    3: 2480000,
    4: 2502000,
    5: 2512000,
    6: 2509000,
    7: 2496000,
    8: 2474000,
    9: 2445000,
    10: 2410000,
    11: 2366000,
    12: 2330000,
    13: 2311000,
    14: 2299000,
    15: 2283000,
    16: 2267000,
    17: 2244000,
    18: 2210000,
    19: 2168000,
    20: 2128000,
    21: 2090000,
    22: 2052000,
    23: 2013000,
    24: 1975000,
    25: 1937000,
    26: 1897000,
    27: 1872000,
    28: 1875000,
    29: 1892000,
    30: 1907000,
    31: 1926000,
    32: 1925000,
    33: 1888000,
    34: 1829000,
    35: 1774000,
    36: 1714000,
    37: 1668000,
    38: 1641000,
    39: 1627000,
    40: 1608000,
    41: 1588000,
    42: 1560000,
    43: 1520000,
    44: 1472000,
    45: 1426000,
    46: 1379000,
    47: 1332000,
    48: 1281000,
    49: 1231000,
    50: 1179000,
    51: 1127000,
    52: 1080000,
    53: 1043000,
    54: 1014000,
    55: 983000,
    56: 955000,
    57: 918000,
    58: 874000,
    59: 824000,
    60: 775000,
    61: 725000,
    62: 681000,
    63: 648000,
    64: 620000,
    65: 590000,
    66: 558000,
    67: 528000,
    68: 497000,
    69: 466000,
    70: 430000,
    71: 395000,
    72: 355000,
    73: 312000,
    74: 267000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1729000
  },
  {
    year: 1928,
    0: 2251000,
    1: 2345000,
    2: 2416000,
    3: 2466000,
    4: 2500000,
    5: 2515000,
    6: 2518000,
    7: 2509000,
    8: 2490000,
    9: 2464000,
    10: 2429000,
    11: 2385000,
    12: 2351000,
    13: 2334000,
    14: 2325000,
    15: 2313000,
    16: 2298000,
    17: 2277000,
    18: 2245000,
    19: 2206000,
    20: 2171000,
    21: 2134000,
    22: 2097000,
    23: 2056000,
    24: 2014000,
    25: 1973000,
    26: 1931000,
    27: 1900000,
    28: 1889000,
    29: 1891000,
    30: 1892000,
    31: 1891000,
    32: 1885000,
    33: 1865000,
    34: 1836000,
    35: 1808000,
    36: 1781000,
    37: 1750000,
    38: 1715000,
    39: 1678000,
    40: 1639000,
    41: 1599000,
    42: 1560000,
    43: 1523000,
    44: 1487000,
    45: 1451000,
    46: 1416000,
    47: 1374000,
    48: 1324000,
    49: 1270000,
    50: 1217000,
    51: 1162000,
    52: 1111000,
    53: 1071000,
    54: 1034000,
    55: 997000,
    56: 960000,
    57: 923000,
    58: 882000,
    59: 838000,
    60: 796000,
    61: 753000,
    62: 714000,
    63: 676000,
    64: 639000,
    65: 604000,
    66: 567000,
    67: 533000,
    68: 498000,
    69: 465000,
    70: 432000,
    71: 400000,
    72: 369000,
    73: 337000,
    74: 307000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1787000
  },
  {
    year: 1929,
    0: 2142000,
    1: 2274000,
    2: 2374000,
    3: 2447000,
    4: 2497000,
    5: 2526000,
    6: 2535000,
    7: 2529000,
    8: 2512000,
    9: 2485000,
    10: 2446000,
    11: 2398000,
    12: 2358000,
    13: 2342000,
    14: 2339000,
    15: 2330000,
    16: 2316000,
    17: 2298000,
    18: 2273000,
    19: 2240000,
    20: 2209000,
    21: 2179000,
    22: 2143000,
    23: 2103000,
    24: 2060000,
    25: 2018000,
    26: 1974000,
    27: 1937000,
    28: 1910000,
    29: 1890000,
    30: 1870000,
    31: 1847000,
    32: 1832000,
    33: 1829000,
    34: 1834000,
    35: 1837000,
    36: 1843000,
    37: 1829000,
    38: 1791000,
    39: 1732000,
    40: 1677000,
    41: 1617000,
    42: 1565000,
    43: 1529000,
    44: 1501000,
    45: 1470000,
    46: 1440000,
    47: 1403000,
    48: 1357000,
    49: 1306000,
    50: 1254000,
    51: 1207000,
    52: 1156000,
    53: 1108000,
    54: 1060000,
    55: 1013000,
    56: 964000,
    57: 919000,
    58: 882000,
    59: 847000,
    60: 811000,
    61: 777000,
    62: 742000,
    63: 701000,
    64: 659000,
    65: 617000,
    66: 578000,
    67: 540000,
    68: 503000,
    69: 470000,
    70: 436000,
    71: 406000,
    72: 380000,
    73: 354000,
    74: 336000,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1854000
  },
  {
    year: 1930,
    0: 2181398,
    1: 2164397,
    2: 2272951,
    3: 2375736,
    4: 2377633,
    5: 2489604,
    6: 2512951,
    7: 2482957,
    8: 2569834,
    9: 2534824,
    10: 2472639,
    11: 2430418,
    12: 2396985,
    13: 2375924,
    14: 2364654,
    15: 2351648,
    16: 2335712,
    17: 2317496,
    18: 2295997,
    19: 2271276,
    20: 2245650,
    21: 2220398,
    22: 2190420,
    23: 2151904,
    24: 2106848,
    25: 2061558,
    26: 2017439,
    27: 1975370,
    28: 1936950,
    29: 1902713,
    30: 1869017,
    31: 1832401,
    32: 1808190,
    33: 1808434,
    34: 1826764,
    35: 1846872,
    36: 1869177,
    37: 1874189,
    38: 1843703,
    39: 1784523,
    40: 1721420,
    41: 1656726,
    42: 1597764,
    43: 1553910,
    44: 1522279,
    45: 1489630,
    46: 1454197,
    47: 1417238,
    48: 1378010,
    49: 1336549,
    50: 1295061,
    51: 1254791,
    52: 1210584,
    53: 1158516,
    54: 1100655,
    55: 1042234,
    56: 983123,
    57: 929633,
    58: 887636,
    59: 854905,
    60: 823587,
    61: 793172,
    62: 760527,
    63: 722084,
    64: 679137,
    65: 636589,
    66: 594731,
    67: 555171,
    68: 519894,
    69: 488113,
    70: 457195,
    71: 427255,
    72: 396062,
    73: 361419,
    74: 324337,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 1945053
  },
  {
    year: 1931,
    0: 2138681,
    1: 2140300,
    2: 2225565,
    3: 2300649,
    4: 2374212,
    5: 2444300,
    6: 2480998,
    7: 2505440,
    8: 2480593,
    9: 2557405,
    10: 2522846,
    11: 2463316,
    12: 2421068,
    13: 2387124,
    14: 2365836,
    15: 2354622,
    16: 2341673,
    17: 2325848,
    18: 2307372,
    19: 2284853,
    20: 2258530,
    21: 2231227,
    22: 2204264,
    23: 2173482,
    24: 2135900,
    25: 2093086,
    26: 2050165,
    27: 2008335,
    28: 1968174,
    29: 1930868,
    30: 1896973,
    31: 1863544,
    32: 1827400,
    33: 1802673,
    34: 1800580,
    35: 1815328,
    36: 1831577,
    37: 1849712,
    38: 1852003,
    39: 1821808,
    40: 1765261,
    41: 1705029,
    42: 1643354,
    43: 1586237,
    44: 1541659,
    45: 1507260,
    46: 1471699,
    47: 1433448,
    48: 1394170,
    49: 1353779,
    50: 1312221,
    51: 1270700,
    52: 1230260,
    53: 1186554,
    54: 1136234,
    55: 1081000,
    56: 1025437,
    57: 969449,
    58: 918022,
    59: 875870,
    60: 841186,
    61: 807603,
    62: 774927,
    63: 740343,
    64: 700937,
    65: 657852,
    66: 615103,
    67: 572915,
    68: 532994,
    69: 497324,
    70: 465200,
    71: 434011,
    72: 403826,
    73: 372673,
    74: 338502,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2038279
  },
  {
    year: 1932,
    0: 2040919,
    1: 2101784,
    2: 2208897,
    3: 2250398,
    4: 2301105,
    5: 2435190,
    6: 2436210,
    7: 2472188,
    8: 2497622,
    9: 2477759,
    10: 2544521,
    11: 2510332,
    12: 2453347,
    13: 2410850,
    14: 2376177,
    15: 2354569,
    16: 2343380,
    17: 2330480,
    18: 2314809,
    19: 2296173,
    20: 2272673,
    21: 2244739,
    22: 2215865,
    23: 2187348,
    24: 2155910,
    25: 2119286,
    26: 2078713,
    27: 2038119,
    28: 1998540,
    29: 1960268,
    30: 1924044,
    31: 1890488,
    32: 1857301,
    33: 1821514,
    34: 1796148,
    35: 1791757,
    36: 1802901,
    37: 1815361,
    38: 1829430,
    39: 1829088,
    40: 1799179,
    41: 1745185,
    42: 1687721,
    43: 1628947,
    44: 1573576,
    45: 1528258,
    46: 1491096,
    47: 1452662,
    48: 1411618,
    49: 1370057,
    50: 1328538,
    51: 1286858,
    52: 1245344,
    53: 1204789,
    54: 1161653,
    55: 1113032,
    56: 1060360,
    57: 1007524,
    58: 954404,
    59: 904821,
    60: 862443,
    61: 825835,
    62: 790060,
    63: 755243,
    64: 718847,
    65: 678544,
    66: 635272,
    67: 592326,
    68: 549842,
    69: 509616,
    70: 473635,
    71: 441222,
    72: 409821,
    73: 379500,
    74: 348459,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2127981
  },
  {
    year: 1933,
    0: 1937890,
    1: 2011141,
    2: 2183375,
    3: 2229269,
    4: 2250477,
    5: 2365010,
    6: 2424948,
    7: 2429945,
    8: 2465138,
    9: 2491440,
    10: 2476490,
    11: 2533211,
    12: 2499260,
    13: 2444536,
    14: 2401537,
    15: 2366022,
    16: 2344056,
    17: 2332876,
    18: 2320061,
    19: 2304623,
    20: 2285833,
    21: 2261319,
    22: 2231855,
    23: 2201538,
    24: 2171599,
    25: 2139522,
    26: 2103838,
    27: 2065435,
    28: 2027095,
    29: 1989714,
    30: 1953259,
    31: 1918075,
    32: 1884798,
    33: 1851714,
    34: 1816121,
    35: 1790123,
    36: 1783399,
    37: 1791014,
    38: 1799795,
    39: 1809909,
    40: 1806959,
    41: 1777259,
    42: 1725671,
    43: 1670814,
    44: 1614775,
    45: 1561040,
    46: 1514915,
    47: 1474993,
    48: 1433674,
    49: 1389849,
    50: 1346036,
    51: 1303359,
    52: 1261557,
    53: 1220073,
    54: 1179452,
    55: 1136836,
    56: 1089838,
    57: 1039561,
    58: 989167,
    59: 938621,
    60: 890694,
    61: 848014,
    62: 809506,
    63: 771624,
    64: 734772,
    65: 696639,
    66: 655393,
    67: 611895,
    68: 568744,
    69: 525988,
    70: 485519,
    71: 449271,
    72: 416612,
    73: 385091,
    74: 354694,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2212598
  },
  {
    year: 1934,
    0: 1874566,
    1: 1917215,
    2: 2107742,
    3: 2204350,
    4: 2226918,
    5: 2314488,
    6: 2360580,
    7: 2418028,
    8: 2426895,
    9: 2461216,
    10: 2488388,
    11: 2478235,
    12: 2524902,
    13: 2490904,
    14: 2438135,
    15: 2394491,
    16: 2358069,
    17: 2335716,
    18: 2324571,
    19: 2311912,
    20: 2296708,
    21: 2277711,
    22: 2252239,
    23: 2221351,
    24: 2189700,
    25: 2158347,
    26: 2125615,
    27: 2090799,
    28: 2054477,
    29: 2018315,
    30: 1983039,
    31: 1948327,
    32: 1914096,
    33: 1880940,
    34: 1847771,
    35: 1812339,
    36: 1785632,
    37: 1776617,
    38: 1780802,
    39: 1786003,
    40: 1792212,
    41: 1786626,
    42: 1757015,
    43: 1707653,
    44: 1655208,
    45: 1601747,
    46: 1549502,
    47: 1502491,
    48: 1459762,
    49: 1415542,
    50: 1368941,
    51: 1322842,
    52: 1279010,
    53: 1237089,
    54: 1195651,
    55: 1154909,
    56: 1112756,
    57: 1067220,
    58: 1019038,
    59: 970775,
    60: 922554,
    61: 876093,
    62: 833032,
    63: 792665,
    64: 752753,
    65: 713921,
    66: 674017,
    67: 631809,
    68: 588043,
    69: 544668,
    70: 501675,
    71: 460993,
    72: 424519,
    73: 391695,
    74: 360103,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2291095
  },
  {
    year: 1935,
    0: 1951444,
    1: 1862375,
    2: 2027696,
    3: 2127788,
    4: 2201074,
    5: 2285507,
    6: 2309343,
    7: 2357112,
    8: 2412005,
    9: 2424601,
    10: 2458035,
    11: 2486000,
    12: 2480517,
    13: 2516918,
    14: 2482624,
    15: 2431698,
    16: 2387374,
    17: 2350041,
    18: 2327340,
    19: 2316318,
    20: 2303828,
    21: 2288818,
    22: 2269685,
    23: 2243381,
    24: 2211208,
    25: 2178258,
    26: 2145509,
    27: 2112105,
    28: 2078118,
    29: 2043844,
    30: 2009807,
    31: 1976593,
    32: 1943565,
    33: 1910146,
    34: 1876952,
    35: 1843703,
    36: 1808375,
    37: 1780985,
    38: 1769750,
    39: 1770586,
    40: 1772232,
    41: 1774524,
    42: 1766251,
    43: 1736632,
    44: 1689360,
    45: 1639233,
    46: 1588237,
    47: 1537402,
    48: 1489459,
    49: 1443929,
    50: 1396845,
    51: 1347485,
    52: 1299172,
    53: 1254256,
    54: 1212273,
    55: 1170864,
    56: 1130002,
    57: 1088230,
    58: 1043935,
    59: 997598,
    60: 951288,
    61: 905222,
    62: 860106,
    63: 816660,
    64: 774527,
    65: 732667,
    66: 691864,
    67: 650248,
    68: 607111,
    69: 563102,
    70: 519567,
    71: 476412,
    72: 435607,
    73: 399041,
    74: 366139,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2361726
  },
  {
    year: 1936,
    0: 1944835,
    1: 1942228,
    2: 1987308,
    3: 2046505,
    4: 2122916,
    5: 2256622,
    6: 2275046,
    7: 2303852,
    8: 2353194,
    9: 2405393,
    10: 2421680,
    11: 2454135,
    12: 2482768,
    13: 2481706,
    14: 2507599,
    15: 2472922,
    16: 2423825,
    17: 2378845,
    18: 2340668,
    19: 2317717,
    20: 2306833,
    21: 2294488,
    22: 2279746,
    23: 2260608,
    24: 2233621,
    25: 2200215,
    26: 2166000,
    27: 2131860,
    28: 2097780,
    29: 2064625,
    30: 2032364,
    31: 2000430,
    32: 1969238,
    33: 1937761,
    34: 1905006,
    35: 1871791,
    36: 1838425,
    37: 1803236,
    38: 1775230,
    39: 1761848,
    40: 1759353,
    41: 1757429,
    42: 1755769,
    43: 1744757,
    44: 1715040,
    45: 1669796,
    46: 1621905,
    47: 1573305,
    48: 1523814,
    49: 1474928,
    50: 1426659,
    51: 1376748,
    52: 1324738,
    53: 1274342,
    54: 1228457,
    55: 1186424,
    56: 1145057,
    57: 1104037,
    58: 1062488,
    59: 1019238,
    60: 974602,
    61: 930119,
    62: 886084,
    63: 842248,
    64: 798451,
    65: 754639,
    66: 710859,
    67: 668168,
    68: 624947,
    69: 580975,
    70: 536807,
    71: 493200,
    72: 449999,
    73: 409244,
    74: 372712,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2424973
  },
  {
    year: 1937,
    0: 1932237,
    1: 1948567,
    2: 2079031,
    3: 2007264,
    4: 2041574,
    5: 2176641,
    6: 2245185,
    7: 2264960,
    8: 2298622,
    9: 2349413,
    10: 2398903,
    11: 2418789,
    12: 2450155,
    13: 2479212,
    14: 2482314,
    15: 2497614,
    16: 2462532,
    17: 2415265,
    18: 2369686,
    19: 2330760,
    20: 2307580,
    21: 2296809,
    22: 2284673,
    23: 2270319,
    24: 2251310,
    25: 2223677,
    26: 2189057,
    27: 2153576,
    28: 2118045,
    29: 2083292,
    30: 2050939,
    31: 2020656,
    32: 1990773,
    33: 1961453,
    34: 1931357,
    35: 1899263,
    36: 1865981,
    37: 1832529,
    38: 1797531,
    39: 1768960,
    40: 1753435,
    41: 1747594,
    42: 1742060,
    43: 1736411,
    44: 1722595,
    45: 1692734,
    46: 1649422,
    47: 1603688,
    48: 1557382,
    49: 1509160,
    50: 1459332,
    51: 1408340,
    52: 1355696,
    53: 1301164,
    54: 1248823,
    55: 1201999,
    56: 1159924,
    57: 1118544,
    58: 1077214,
    59: 1035710,
    60: 993380,
    61: 950324,
    62: 907546,
    63: 865428,
    64: 822800,
    65: 778645,
    66: 733145,
    67: 687512,
    68: 643039,
    69: 598334,
    70: 553650,
    71: 509397,
    72: 465808,
    73: 422728,
    74: 382158,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2483204
  },
  {
    year: 1938,
    0: 2018652,
    1: 1942637,
    2: 2108385,
    3: 2099909,
    4: 2006846,
    5: 2096956,
    6: 2167475,
    7: 2236930,
    8: 2257979,
    9: 2296412,
    10: 2348694,
    11: 2395459,
    12: 2418852,
    13: 2448931,
    14: 2478197,
    15: 2485347,
    16: 2490059,
    17: 2454527,
    18: 2409083,
    19: 2362946,
    20: 2323249,
    21: 2299790,
    22: 2289185,
    23: 2277365,
    24: 2263509,
    25: 2244632,
    26: 2216331,
    27: 2180449,
    28: 2143653,
    29: 2106690,
    30: 2071210,
    31: 2039596,
    32: 2011214,
    33: 1983198,
    34: 1955539,
    35: 1926774,
    36: 1895241,
    37: 1861881,
    38: 1828355,
    39: 1793551,
    40: 1764365,
    41: 1746643,
    42: 1737401,
    43: 1728204,
    44: 1718503,
    45: 1701859,
    46: 1671754,
    47: 1630247,
    48: 1586517,
    49: 1542351,
    50: 1495284,
    51: 1444417,
    52: 1390721,
    53: 1335400,
    54: 1278432,
    55: 1224144,
    56: 1176369,
    57: 1134141,
    58: 1092535,
    59: 1050686,
    60: 1009094,
    61: 967547,
    62: 925906,
    63: 884694,
    64: 844342,
    65: 802759,
    66: 758083,
    67: 710885,
    68: 663756,
    69: 617255,
    70: 571186,
    71: 525853,
    72: 481552,
    73: 438083,
    74: 395210,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2543073
  },
  {
    year: 1939,
    0: 2041014,
    1: 2025350,
    2: 2115730,
    3: 2136944,
    4: 2099288,
    5: 2064071,
    6: 2089083,
    7: 2159685,
    8: 2229989,
    9: 2252207,
    10: 2295409,
    11: 2349128,
    12: 2393080,
    13: 2419751,
    14: 2448310,
    15: 2477691,
    16: 2488849,
    17: 2482975,
    18: 2447033,
    19: 2403479,
    20: 2356786,
    21: 2316287,
    22: 2292607,
    23: 2282282,
    24: 2270901,
    25: 2257566,
    26: 2238816,
    27: 2209819,
    28: 2172653,
    29: 2134523,
    30: 2096113,
    31: 2059880,
    32: 2028961,
    33: 2002319,
    34: 1975986,
    35: 1949961,
    36: 1922451,
    37: 1891487,
    38: 1858088,
    39: 1823847,
    40: 1789868,
    41: 1760001,
    42: 1740005,
    43: 1727301,
    44: 1714369,
    45: 1700614,
    46: 1681102,
    47: 1650699,
    48: 1610895,
    49: 1569058,
    50: 1526940,
    51: 1480916,
    52: 1429012,
    53: 1372699,
    54: 1314826,
    55: 1255463,
    56: 1199284,
    57: 1150514,
    58: 1107957,
    59: 1065941,
    60: 1023492,
    61: 981752,
    62: 940898,
    63: 900591,
    64: 860846,
    65: 822091,
    66: 781324,
    67: 736053,
    68: 687206,
    69: 638391,
    70: 590314,
    71: 543033,
    72: 497173,
    73: 452961,
    74: 409724,
    75: 0,
    76: 0,
    77: 0,
    78: 0,
    79: 0,
    80: 0,
    81: 0,
    82: 0,
    83: 0,
    84: 0,
    85: 0,
    "75+": 2606006
  },
  {
    year: 1940,
    0: 2025235,
    1: 2057692,
    2: 2219308,
    3: 2137535,
    4: 2138873,
    5: 2106522,
    6: 2100480,
    7: 2114510,
    8: 2143466,
    9: 2183319,
    10: 2241070,
    11: 2298723,
    12: 2353015,
    13: 2395435,
    14: 2426715,
    15: 2448196,
    16: 2476570,
    17: 2491660,
    18: 2480070,
    19: 2446141,
    20: 2407683,
    21: 2364954,
    22: 2326145,
    23: 2301974,
    24: 2289605,
    25: 2277467,
    26: 2260929,
    27: 2239393,
    28: 2208515,
    29: 2169957,
    30: 2128372,
    31: 2089068,
    32: 2052711,
    33: 2022480,
    34: 1997057,
    35: 1975136,
    36: 1949822,
    37: 1922654,
    38: 1891509,
    39: 1857554,
    40: 1823210,
    41: 1788406,
    42: 1757727,
    43: 1735689,
    44: 1719959,
    45: 1701919,
    46: 1684634,
    47: 1662540,
    48: 1631640,
    49: 1592999,
    50: 1549138,
    51: 1508129,
    52: 1462569,
    53: 1409733,
    54: 1351317,
    55: 1292752,
    56: 1231634,
    57: 1174016,
    58: 1124213,
    59: 1080883,
    60: 1041845,
    61: 998690,
    62: 956573,
    63: 916269,
    64: 877311,
    65: 838519,
    66: 801110,
    67: 761108,
    68: 715537,
    69: 665506,
    70: 612254,
    71: 563229,
    72: 515356,
    73: 469160,
    74: 424908,
    75: 381099,
    76: 339378,
    77: 300292,
    78: 263718,
    79: 229989,
    80: 201032,
    81: 174420,
    82: 151706,
    83: 133226,
    84: 119309,
    85: 370275,
    "75+": 0
  },
  {
    year: 1941,
    0: 2166858,
    1: 2082260,
    2: 2232740,
    3: 2231007,
    4: 2136767,
    5: 2172236,
    6: 2077259,
    7: 2069053,
    8: 2086545,
    9: 2117947,
    10: 2201186,
    11: 2261364,
    12: 2318355,
    13: 2370687,
    14: 2414581,
    15: 2426872,
    16: 2439439,
    17: 2461490,
    18: 2475853,
    19: 2463655,
    20: 2429716,
    21: 2398194,
    22: 2359656,
    23: 2321684,
    24: 2298088,
    25: 2295320,
    26: 2279029,
    27: 2260266,
    28: 2237260,
    29: 2205642,
    30: 2156831,
    31: 2116650,
    32: 2078727,
    33: 2044786,
    34: 2016200,
    35: 2002209,
    36: 1977193,
    37: 1950120,
    38: 1920916,
    39: 1888269,
    40: 1853375,
    41: 1821509,
    42: 1787306,
    43: 1756818,
    44: 1734357,
    45: 1717046,
    46: 1697571,
    47: 1678541,
    48: 1654870,
    49: 1623007,
    50: 1568909,
    51: 1523449,
    52: 1480907,
    53: 1434188,
    54: 1380888,
    55: 1322947,
    56: 1264708,
    57: 1204243,
    58: 1147325,
    59: 1097534,
    60: 1067457,
    61: 1028621,
    62: 984993,
    63: 941576,
    64: 899735,
    65: 858629,
    66: 819065,
    67: 780071,
    68: 738749,
    69: 692428,
    70: 631858,
    71: 577512,
    72: 528432,
    73: 481695,
    74: 437344,
    75: 395207,
    76: 348803,
    77: 307707,
    78: 270692,
    79: 235879,
    80: 208346,
    81: 181087,
    82: 156374,
    83: 135132,
    84: 117686,
    85: 384985,
    "75+": 0
  },
  {
    year: 1942,
    0: 2325004,
    1: 2198704,
    2: 2261296,
    3: 2271410,
    4: 2245082,
    5: 2149276,
    6: 2145282,
    7: 2039242,
    8: 2028925,
    9: 2049676,
    10: 2155174,
    11: 2226745,
    12: 2288680,
    13: 2345055,
    14: 2395539,
    15: 2408479,
    16: 2416741,
    17: 2422415,
    18: 2438410,
    19: 2452135,
    20: 2448250,
    21: 2422754,
    22: 2396760,
    23: 2362383,
    24: 2325335,
    25: 2308977,
    26: 2296949,
    27: 2277664,
    28: 2256833,
    29: 2232555,
    30: 2187504,
    31: 2143157,
    32: 2103932,
    33: 2067585,
    34: 2036091,
    35: 2022690,
    36: 2004612,
    37: 1977025,
    38: 1948300,
    39: 1917114,
    40: 1877565,
    41: 1847385,
    42: 1817538,
    43: 1783919,
    44: 1753609,
    45: 1737691,
    46: 1716975,
    47: 1696246,
    48: 1675360,
    49: 1650057,
    50: 1600449,
    51: 1543708,
    52: 1497168,
    53: 1453105,
    54: 1405257,
    55: 1348512,
    56: 1291844,
    57: 1233745,
    58: 1174000,
    59: 1117877,
    60: 1084637,
    61: 1055074,
    62: 1015934,
    63: 971728,
    64: 926946,
    65: 883894,
    66: 841299,
    67: 800873,
    68: 760154,
    69: 717359,
    70: 659578,
    71: 597503,
    72: 542508,
    73: 493565,
    74: 448034,
    75: 406344,
    76: 366171,
    77: 318218,
    78: 277964,
    79: 243004,
    80: 213710,
    81: 188671,
    82: 162680,
    83: 139760,
    84: 119949,
    85: 402246,
    "75+": 0
  },
  {
    year: 1943,
    0: 2692725,
    1: 2371411,
    2: 2388589,
    3: 2297887,
    4: 2265745,
    5: 2212045,
    6: 2115201,
    7: 2112215,
    8: 2005936,
    9: 1993445,
    10: 2107267,
    11: 2188240,
    12: 2260275,
    13: 2324096,
    14: 2379939,
    15: 2393695,
    16: 2391050,
    17: 2402539,
    18: 2401595,
    19: 2411694,
    20: 2430035,
    21: 2444059,
    22: 2420307,
    23: 2400010,
    24: 2369981,
    25: 2349507,
    26: 2318735,
    27: 2303068,
    28: 2280889,
    29: 2258112,
    30: 2214556,
    31: 2177085,
    32: 2134629,
    33: 2096416,
    34: 2061587,
    35: 2046604,
    36: 2028151,
    37: 2008094,
    38: 1977974,
    39: 1947593,
    40: 1905258,
    41: 1871667,
    42: 1843370,
    43: 1815410,
    44: 1782250,
    45: 1761370,
    46: 1740563,
    47: 1717580,
    48: 1695500,
    49: 1672717,
    50: 1628248,
    51: 1573515,
    52: 1516728,
    53: 1469118,
    54: 1423621,
    55: 1370738,
    56: 1315647,
    57: 1259377,
    58: 1201443,
    59: 1142446,
    60: 1103475,
    61: 1074176,
    62: 1042807,
    63: 1003258,
    64: 958399,
    65: 912138,
    66: 865398,
    67: 821548,
    68: 780239,
    69: 737826,
    70: 682583,
    71: 620868,
    72: 559747,
    73: 504695,
    74: 456364,
    75: 413700,
    76: 374923,
    77: 337082,
    78: 288282,
    79: 249215,
    80: 218364,
    81: 192354,
    82: 168431,
    83: 143972,
    84: 123002,
    85: 416960,
    "75+": 0
  },
  {
    year: 1944,
    0: 2515859,
    1: 2724581,
    2: 2573419,
    3: 2416020,
    4: 2293935,
    5: 2222993,
    6: 2195216,
    7: 2100096,
    8: 2095447,
    9: 2008232,
    10: 2062006,
    11: 2109962,
    12: 2190510,
    13: 2261990,
    14: 2326690,
    15: 2376060,
    16: 2387381,
    17: 2383596,
    18: 2398635,
    19: 2391184,
    20: 2386866,
    21: 2419316,
    22: 2437959,
    23: 2416175,
    24: 2401925,
    25: 2399008,
    26: 2360761,
    27: 2325753,
    28: 2306564,
    29: 2281664,
    30: 2237658,
    31: 2205201,
    32: 2170932,
    33: 2130411,
    34: 2093144,
    35: 2074373,
    36: 2054639,
    37: 2034609,
    38: 2012539,
    39: 1979819,
    40: 1935379,
    41: 1897284,
    42: 1865135,
    43: 1838562,
    44: 1812377,
    45: 1789572,
    46: 1763704,
    47: 1740255,
    48: 1714974,
    49: 1691549,
    50: 1650563,
    51: 1599007,
    52: 1544016,
    53: 1487193,
    54: 1438636,
    55: 1389876,
    56: 1338704,
    57: 1284000,
    58: 1228022,
    59: 1170184,
    60: 1126025,
    61: 1094925,
    62: 1064449,
    63: 1031183,
    64: 991120,
    65: 944294,
    66: 891344,
    67: 842549,
    68: 797542,
    69: 755432,
    70: 701525,
    71: 639873,
    72: 579248,
    73: 519526,
    74: 465006,
    75: 419783,
    76: 381690,
    77: 344797,
    78: 309167,
    79: 260065,
    80: 223119,
    81: 196055,
    82: 171428,
    83: 148768,
    84: 125987,
    85: 430325,
    "75+": 0
  },
  {
    year: 1945,
    0: 2463569,
    1: 2580905,
    2: 2922095,
    3: 2592601,
    4: 2419641,
    5: 2244654,
    6: 2212753,
    7: 2186637,
    8: 2089735,
    9: 2088357,
    10: 2102685,
    11: 2077834,
    12: 2121420,
    13: 2201871,
    14: 2272984,
    15: 2307980,
    16: 2328526,
    17: 2341163,
    18: 2336638,
    19: 2354584,
    20: 2354270,
    21: 2387255,
    22: 2422464,
    23: 2445961,
    24: 2426252,
    25: 2430959,
    26: 2398786,
    27: 2355916,
    28: 2316982,
    29: 2294458,
    30: 2247258,
    31: 2218748,
    32: 2190296,
    33: 2159395,
    34: 2120909,
    35: 2104441,
    36: 2084507,
    37: 2063793,
    38: 2042126,
    39: 2017937,
    40: 1968557,
    41: 1926211,
    42: 1888708,
    43: 1857799,
    44: 1832763,
    45: 1817339,
    46: 1789432,
    47: 1761127,
    48: 1735030,
    49: 1707506,
    50: 1669385,
    51: 1621565,
    52: 1569608,
    53: 1514276,
    54: 1457458,
    55: 1409418,
    56: 1363387,
    57: 1311958,
    58: 1257363,
    59: 1201411,
    60: 1154747,
    61: 1121025,
    62: 1089202,
    63: 1057388,
    64: 1022098,
    65: 979140,
    66: 923398,
    67: 868148,
    68: 817345,
    69: 771217,
    70: 720954,
    71: 659821,
    72: 598829,
    73: 539298,
    74: 481011,
    75: 430285,
    76: 391918,
    77: 355003,
    78: 319547,
    79: 285674,
    80: 237366,
    81: 202174,
    82: 176558,
    83: 153106,
    84: 131586,
    85: 451681,
    "75+": 0
  },
  {
    year: 1946,
    0: 2401211,
    1: 2541726,
    2: 2748274,
    3: 2954919,
    4: 2597793,
    5: 2362293,
    6: 2243741,
    7: 2213616,
    8: 2184746,
    9: 2091817,
    10: 2160697,
    11: 2131296,
    12: 2098889,
    13: 2138151,
    14: 2218667,
    15: 2258532,
    16: 2263381,
    17: 2277948,
    18: 2291901,
    19: 2286677,
    20: 2327077,
    21: 2365278,
    22: 2402338,
    23: 2440529,
    24: 2469090,
    25: 2453530,
    26: 2421315,
    27: 2384695,
    28: 2337710,
    29: 2295259,
    30: 2256960,
    31: 2238474,
    32: 2214200,
    33: 2189649,
    34: 2161885,
    35: 2140581,
    36: 2112115,
    37: 2090911,
    38: 2069196,
    39: 2045819,
    40: 2007292,
    41: 1958930,
    42: 1917372,
    43: 1880246,
    44: 1850355,
    45: 1831507,
    46: 1809678,
    47: 1779521,
    48: 1748874,
    49: 1720258,
    50: 1680399,
    51: 1638326,
    52: 1590526,
    53: 1538123,
    54: 1482580,
    55: 1429278,
    56: 1385649,
    57: 1338883,
    58: 1287077,
    59: 1232443,
    60: 1184138,
    61: 1147117,
    62: 1112601,
    63: 1080073,
    64: 1046984,
    65: 1006869,
    66: 952025,
    67: 894929,
    68: 837744,
    69: 785327,
    70: 733015,
    71: 678958,
    72: 618502,
    73: 558285,
    74: 499995,
    75: 446262,
    76: 405263,
    77: 367324,
    78: 331365,
    79: 297125,
    80: 266323,
    81: 212110,
    82: 178620,
    83: 154842,
    84: 133035,
    85: 469532,
    "75+": 0
  },
  {
    year: 1947,
    0: 3452304,
    1: 2496515,
    2: 2695485,
    3: 2800738,
    4: 2960693,
    5: 2531834,
    6: 2365076,
    7: 2248431,
    8: 2214936,
    9: 2190853,
    10: 2160278,
    11: 2159237,
    12: 2143975,
    13: 2104091,
    14: 2138538,
    15: 2211141,
    16: 2261286,
    17: 2267345,
    18: 2276399,
    19: 2291909,
    20: 2285142,
    21: 2324104,
    22: 2360731,
    23: 2401690,
    24: 2442687,
    25: 2483725,
    26: 2448449,
    27: 2413397,
    28: 2372286,
    29: 2321150,
    30: 2265564,
    31: 2257716,
    32: 2244971,
    33: 2224760,
    34: 2203857,
    35: 2192389,
    36: 2158369,
    37: 2124922,
    38: 2102332,
    39: 2079473,
    40: 2041998,
    41: 1996154,
    42: 1948218,
    43: 1907212,
    44: 1870240,
    45: 1843392,
    46: 1818398,
    47: 1795102,
    48: 1762738,
    49: 1729845,
    50: 1692642,
    51: 1650150,
    52: 1609055,
    53: 1561080,
    54: 1508265,
    55: 1456358,
    56: 1411558,
    57: 1366869,
    58: 1319167,
    59: 1266735,
    60: 1216288,
    61: 1177676,
    62: 1138912,
    63: 1103536,
    64: 1070273,
    65: 1034139,
    66: 976989,
    67: 921066,
    68: 862752,
    69: 803908,
    70: 746032,
    71: 692160,
    72: 639320,
    73: 579458,
    74: 520019,
    75: 465441,
    76: 421680,
    77: 380611,
    78: 343142,
    79: 308131,
    80: 276510,
    81: 241853,
    82: 188433,
    83: 156728,
    84: 134778,
    85: 492282,
    "75+": 0
  },
  {
    year: 1948,
    0: 3169318,
    1: 3514716,
    2: 2680284,
    3: 2759307,
    4: 2795306,
    5: 2885424,
    6: 2539920,
    7: 2375063,
    8: 2253977,
    9: 2225928,
    10: 2256007,
    11: 2155505,
    12: 2152157,
    13: 2146282,
    14: 2098916,
    15: 2119785,
    16: 2188582,
    17: 2248389,
    18: 2255787,
    19: 2259307,
    20: 2285900,
    21: 2319466,
    22: 2357125,
    23: 2392841,
    24: 2438485,
    25: 2482261,
    26: 2475886,
    27: 2439451,
    28: 2401518,
    29: 2355928,
    30: 2294302,
    31: 2271774,
    32: 2267910,
    33: 2260742,
    34: 2244367,
    35: 2236444,
    36: 2212893,
    37: 2174615,
    38: 2136018,
    39: 2111912,
    40: 2078552,
    41: 2029377,
    42: 1983367,
    43: 1935626,
    44: 1894965,
    45: 1858794,
    46: 1825025,
    47: 1798981,
    48: 1774208,
    49: 1739720,
    50: 1702025,
    51: 1665894,
    52: 1623728,
    53: 1583310,
    54: 1535143,
    55: 1484755,
    56: 1442446,
    57: 1396999,
    58: 1351115,
    59: 1302307,
    60: 1250876,
    61: 1208191,
    62: 1168070,
    63: 1127595,
    64: 1091411,
    65: 1056568,
    66: 1003249,
    67: 943896,
    68: 886928,
    69: 827581,
    70: 764571,
    71: 703972,
    72: 651311,
    73: 599664,
    74: 540619,
    75: 483946,
    76: 439022,
    77: 395622,
    78: 354768,
    79: 317994,
    80: 284995,
    81: 249961,
    82: 216689,
    83: 164938,
    84: 135380,
    85: 517350,
    "75+": 0
  },
  {
    year: 1949,
    0: 3169644,
    1: 3269273,
    2: 3706727,
    3: 2710023,
    4: 2751429,
    5: 2727530,
    6: 2889816,
    7: 2546627,
    8: 2376632,
    9: 2261929,
    10: 2280422,
    11: 2250172,
    12: 2151902,
    13: 2148710,
    14: 2136172,
    15: 2082363,
    16: 2106021,
    17: 2178016,
    18: 2247525,
    19: 2256122,
    20: 2257371,
    21: 2305216,
    22: 2343412,
    23: 2379639,
    24: 2414353,
    25: 2464955,
    26: 2483764,
    27: 2473549,
    28: 2435782,
    29: 2394819,
    30: 2341457,
    31: 2298548,
    32: 2279142,
    33: 2279116,
    34: 2277263,
    35: 2269282,
    36: 2260055,
    37: 2232742,
    38: 2189973,
    39: 2145958,
    40: 2115385,
    41: 2064907,
    42: 2016513,
    43: 1970113,
    44: 1922326,
    45: 1882292,
    46: 1838111,
    47: 1803349,
    48: 1776211,
    49: 1749947,
    50: 1712727,
    51: 1682690,
    52: 1646371,
    53: 1604143,
    54: 1564272,
    55: 1516941,
    56: 1475948,
    57: 1432120,
    58: 1385852,
    59: 1338570,
    60: 1288833,
    61: 1241151,
    62: 1197006,
    63: 1155375,
    64: 1113252,
    65: 1076033,
    66: 1028685,
    67: 973497,
    68: 911770,
    69: 853694,
    70: 792016,
    71: 722681,
    72: 662917,
    73: 611363,
    74: 560892,
    75: 503685,
    76: 456761,
    77: 411848,
    78: 368991,
    79: 328591,
    80: 293069,
    81: 257090,
    82: 223357,
    83: 191755,
    84: 142408,
    85: 549171,
    "75+": 0
  },
  {
    year: 1950,
    0: 3162567,
    1: 3299863,
    2: 3450042,
    3: 3771883,
    4: 2725600,
    5: 2714560,
    6: 2755636,
    7: 2932959,
    8: 2569056,
    9: 2402326,
    10: 2325360,
    11: 2292510,
    12: 2265018,
    13: 2166039,
    14: 2163732,
    15: 2132602,
    16: 2055407,
    17: 2091956,
    18: 2164344,
    19: 2231096,
    20: 2258243,
    21: 2293186,
    22: 2324914,
    23: 2391008,
    24: 2413041,
    25: 2437842,
    26: 2494150,
    27: 2515690,
    28: 2455939,
    29: 2457975,
    30: 2416372,
    31: 2350474,
    32: 2296579,
    33: 2305591,
    34: 2305161,
    35: 2296670,
    36: 2302269,
    37: 2286825,
    38: 2257573,
    39: 2203312,
    40: 2146026,
    41: 2108459,
    42: 2061284,
    43: 2010872,
    44: 1963179,
    45: 1920595,
    46: 1861858,
    47: 1823655,
    48: 1792824,
    49: 1743067,
    50: 1730428,
    51: 1705308,
    52: 1668072,
    53: 1623091,
    54: 1584046,
    55: 1544901,
    56: 1499706,
    57: 1456723,
    58: 1419461,
    59: 1372293,
    60: 1308533,
    61: 1261628,
    62: 1219039,
    63: 1176591,
    64: 1137232,
    65: 1102643,
    66: 1068359,
    67: 1025449,
    68: 959546,
    69: 892792,
    70: 820399,
    71: 748822,
    72: 678081,
    73: 624363,
    74: 572323,
    75: 526042,
    76: 475302,
    77: 427724,
    78: 384529,
    79: 341701,
    80: 301331,
    81: 264897,
    82: 228777,
    83: 197083,
    84: 167401,
    85: 589612,
    "75+": 0
  },
  {
    year: 1951,
    0: 3315027,
    1: 3306360,
    2: 3458209,
    3: 3495214,
    4: 3758637,
    5: 2673394,
    6: 2720557,
    7: 2777810,
    8: 2919475,
    9: 2576394,
    10: 2447996,
    11: 2324584,
    12: 2294245,
    13: 2263382,
    14: 2169776,
    15: 2110824,
    16: 2155565,
    17: 2062072,
    18: 2085366,
    19: 2143533,
    20: 2226752,
    21: 2285116,
    22: 2258475,
    23: 2384385,
    24: 2397456,
    25: 2382044,
    26: 2485281,
    27: 2550054,
    28: 2388417,
    29: 2489071,
    30: 2501511,
    31: 2406926,
    32: 2296222,
    33: 2346510,
    34: 2343344,
    35: 2301006,
    36: 2318955,
    37: 2308762,
    38: 2294968,
    39: 2238493,
    40: 2152206,
    41: 2137402,
    42: 2107882,
    43: 2053470,
    44: 2000532,
    45: 1973176,
    46: 1896650,
    47: 1865352,
    48: 1846607,
    49: 1731205,
    50: 1739683,
    51: 1736590,
    52: 1685405,
    53: 1616865,
    54: 1585288,
    55: 1562796,
    56: 1513994,
    57: 1473791,
    58: 1466950,
    59: 1431170,
    60: 1327936,
    61: 1283071,
    62: 1259031,
    63: 1210922,
    64: 1155186,
    65: 1122337,
    66: 1094587,
    67: 1061328,
    68: 984827,
    69: 925655,
    70: 849494,
    71: 776796,
    72: 694919,
    73: 644857,
    74: 586650,
    75: 547875,
    76: 495619,
    77: 443320,
    78: 401306,
    79: 355965,
    80: 310899,
    81: 274292,
    82: 233122,
    83: 200470,
    84: 170539,
    85: 627704,
    "75+": 0
  },
  {
    year: 1952,
    0: 3429237,
    1: 3450008,
    2: 3447304,
    3: 3497803,
    4: 3487906,
    5: 3697873,
    6: 2678521,
    7: 2743435,
    8: 2764331,
    9: 2926644,
    10: 2623103,
    11: 2448869,
    12: 2327720,
    13: 2290686,
    14: 2271955,
    15: 2121429,
    16: 2128050,
    17: 2163113,
    18: 2058190,
    19: 2064397,
    20: 2140294,
    21: 2252033,
    22: 2254917,
    23: 2313831,
    24: 2389011,
    25: 2368562,
    26: 2430840,
    27: 2539590,
    28: 2433957,
    29: 2414400,
    30: 2530686,
    31: 2498967,
    32: 2356367,
    33: 2345235,
    34: 2383137,
    35: 2336914,
    36: 2322823,
    37: 2329787,
    38: 2306547,
    39: 2276059,
    40: 2196027,
    41: 2145206,
    42: 2131833,
    43: 2101458,
    44: 2046150,
    45: 2008515,
    46: 1951101,
    47: 1899084,
    48: 1886424,
    49: 1788803,
    50: 1723153,
    51: 1741462,
    52: 1716856,
    53: 1637802,
    54: 1581823,
    55: 1566919,
    56: 1534245,
    57: 1490847,
    58: 1484062,
    59: 1470910,
    60: 1385931,
    61: 1302807,
    62: 1277776,
    63: 1247344,
    64: 1188280,
    65: 1139636,
    66: 1112306,
    67: 1084556,
    68: 1021860,
    69: 952558,
    70: 881190,
    71: 804974,
    72: 724682,
    73: 661775,
    74: 606084,
    75: 559772,
    76: 516011,
    77: 463196,
    78: 414946,
    79: 371448,
    80: 323884,
    81: 282444,
    82: 240744,
    83: 203506,
    84: 172672,
    85: 665147,
    "75+": 0
  },
  {
    year: 1953,
    0: 3546301,
    1: 3548477,
    2: 3574323,
    3: 3477405,
    4: 3491830,
    5: 3439101,
    6: 3699058,
    7: 2699180,
    8: 2726494,
    9: 2768580,
    10: 2974006,
    11: 2623543,
    12: 2451326,
    13: 2320100,
    14: 2302026,
    15: 2223638,
    16: 2131213,
    17: 2134566,
    18: 2159585,
    19: 2034765,
    20: 2059911,
    21: 2160664,
    22: 2223594,
    23: 2304737,
    24: 2313239,
    25: 2358201,
    26: 2414901,
    27: 2477356,
    28: 2432040,
    29: 2449315,
    30: 2447966,
    31: 2531563,
    32: 2448242,
    33: 2402883,
    34: 2377540,
    35: 2371899,
    36: 2356096,
    37: 2335651,
    38: 2314771,
    39: 2285831,
    40: 2240392,
    41: 2188562,
    42: 2132547,
    43: 2124780,
    44: 2095250,
    45: 2050027,
    46: 1986586,
    47: 1950647,
    48: 1916269,
    49: 1831607,
    50: 1774906,
    51: 1719360,
    52: 1721237,
    53: 1672117,
    54: 1604742,
    55: 1565919,
    56: 1540543,
    57: 1513668,
    58: 1500969,
    59: 1479280,
    60: 1424970,
    61: 1360993,
    62: 1294512,
    63: 1262015,
    64: 1223318,
    65: 1172152,
    66: 1127640,
    67: 1099179,
    68: 1046629,
    69: 991651,
    70: 906930,
    71: 835573,
    72: 754659,
    73: 691543,
    74: 621918,
    75: 576873,
    76: 526573,
    77: 483490,
    78: 433207,
    79: 384086,
    80: 338508,
    81: 294477,
    82: 247375,
    83: 209833,
    84: 174145,
    85: 700618,
    "75+": 0
  },
  {
    year: 1954,
    0: 3670654,
    1: 3653661,
    2: 3656495,
    3: 3599983,
    4: 3476664,
    5: 3453939,
    6: 3439355,
    7: 3727076,
    8: 2681218,
    9: 2730119,
    10: 2811166,
    11: 2975880,
    12: 2627071,
    13: 2440657,
    14: 2335904,
    15: 2257092,
    16: 2227465,
    17: 2138662,
    18: 2135040,
    19: 2135882,
    20: 2033130,
    21: 2080459,
    22: 2140366,
    23: 2273536,
    24: 2304924,
    25: 2286831,
    26: 2407547,
    27: 2460279,
    28: 2386122,
    29: 2441590,
    30: 2481660,
    31: 2456844,
    32: 2486547,
    33: 2496921,
    34: 2435687,
    35: 2365843,
    36: 2392378,
    37: 2375045,
    38: 2311547,
    39: 2295945,
    40: 2261167,
    41: 2236059,
    42: 2172140,
    43: 2128286,
    44: 2123055,
    45: 2098128,
    46: 2031448,
    47: 1986117,
    48: 1967095,
    49: 1867702,
    50: 1814252,
    51: 1768402,
    52: 1701137,
    53: 1682261,
    54: 1643274,
    55: 1593667,
    56: 1544001,
    57: 1524815,
    58: 1526042,
    59: 1489617,
    60: 1435218,
    61: 1402234,
    62: 1351998,
    63: 1276387,
    64: 1238339,
    65: 1208291,
    66: 1159956,
    67: 1112897,
    68: 1064430,
    69: 1019929,
    70: 946111,
    71: 861463,
    72: 788354,
    73: 722642,
    74: 651585,
    75: 591388,
    76: 543531,
    77: 495142,
    78: 453080,
    79: 402708,
    80: 351569,
    81: 309400,
    82: 258980,
    83: 216216,
    84: 179705,
    85: 738452,
    "75+": 0
  },
  {
    year: 1955,
    0: 3777404,
    1: 3764469,
    2: 3744444,
    3: 3676466,
    4: 3603530,
    5: 3449161,
    6: 3452324,
    7: 3465773,
    8: 3698230,
    9: 2683429,
    10: 2769205,
    11: 2814443,
    12: 2980158,
    13: 2612448,
    14: 2461584,
    15: 2294550,
    16: 2254733,
    17: 2235977,
    18: 2142406,
    19: 2111504,
    20: 2135741,
    21: 2052912,
    22: 2065719,
    23: 2186847,
    24: 2272449,
    25: 2279892,
    26: 2335789,
    27: 2449896,
    28: 2381075,
    29: 2387915,
    30: 2470040,
    31: 2496671,
    32: 2417837,
    33: 2534395,
    34: 2528720,
    35: 2421307,
    36: 2385690,
    37: 2416193,
    38: 2339986,
    39: 2293467,
    40: 2281314,
    41: 2258974,
    42: 2214696,
    43: 2169826,
    44: 2130086,
    45: 2123773,
    46: 2082012,
    47: 2029956,
    48: 2000462,
    49: 1923642,
    50: 1845729,
    51: 1803755,
    52: 1750976,
    53: 1667258,
    54: 1656852,
    55: 1636072,
    56: 1574959,
    57: 1531865,
    58: 1537925,
    59: 1506930,
    60: 1446324,
    61: 1413664,
    62: 1391249,
    63: 1330549,
    64: 1252105,
    65: 1223262,
    66: 1194738,
    67: 1142541,
    68: 1080208,
    69: 1040011,
    70: 973290,
    71: 899494,
    72: 816386,
    73: 756238,
    74: 681157,
    75: 618233,
    76: 556252,
    77: 511619,
    78: 462459,
    79: 421232,
    80: 368972,
    81: 321134,
    82: 271953,
    83: 226309,
    84: 184365,
    85: 775617,
    "75+": 0
  },
  {
    year: 1956,
    0: 3860003,
    1: 3857934,
    2: 3839092,
    3: 3759958,
    4: 3686410,
    5: 3587147,
    6: 3447168,
    7: 3480478,
    8: 3438169,
    9: 3699221,
    10: 2719985,
    11: 2774962,
    12: 2820730,
    13: 2961160,
    14: 2640702,
    15: 2423680,
    16: 2287083,
    17: 2265473,
    18: 2244273,
    19: 2119953,
    20: 2114781,
    21: 2157495,
    22: 2044985,
    23: 2111474,
    24: 2187004,
    25: 2251515,
    26: 2332479,
    27: 2376674,
    28: 2384752,
    29: 2377271,
    30: 2413901,
    31: 2492691,
    32: 2462517,
    33: 2464261,
    34: 2565674,
    35: 2512341,
    36: 2441990,
    37: 2414743,
    38: 2370532,
    39: 2323075,
    40: 2289541,
    41: 2281709,
    42: 2233007,
    43: 2214657,
    44: 2175605,
    45: 2128724,
    46: 2110423,
    47: 2079532,
    48: 2042257,
    49: 1962889,
    50: 1896811,
    51: 1831199,
    52: 1787340,
    53: 1721462,
    54: 1645282,
    55: 1653083,
    56: 1620064,
    57: 1565753,
    58: 1544679,
    59: 1510043,
    60: 1463365,
    61: 1425006,
    62: 1399337,
    63: 1365289,
    64: 1304579,
    65: 1235999,
    66: 1207122,
    67: 1173570,
    68: 1111188,
    69: 1057235,
    70: 991438,
    71: 924740,
    72: 855929,
    73: 783343,
    74: 712341,
    75: 643966,
    76: 580409,
    77: 522902,
    78: 475657,
    79: 428091,
    80: 385155,
    81: 336042,
    82: 280499,
    83: 236421,
    84: 191237,
    85: 804375,
    "75+": 0
  },
  {
    year: 1957,
    0: 4035155,
    1: 3923917,
    2: 3912759,
    3: 3847648,
    4: 3774329,
    5: 3679934,
    6: 3582421,
    7: 3474852,
    8: 3449670,
    9: 3437541,
    10: 3742297,
    11: 2726273,
    12: 2781183,
    13: 2798675,
    14: 2996847,
    15: 2603159,
    16: 2407100,
    17: 2296166,
    18: 2274288,
    19: 2217436,
    20: 2122410,
    21: 2133334,
    22: 2151036,
    23: 2087107,
    24: 2109080,
    25: 2167481,
    26: 2303937,
    27: 2370125,
    28: 2325111,
    29: 2373166,
    30: 2399249,
    31: 2441863,
    32: 2462482,
    33: 2507964,
    34: 2492400,
    35: 2546473,
    36: 2533176,
    37: 2476432,
    38: 2358489,
    39: 2353961,
    40: 2329121,
    41: 2291428,
    42: 2249872,
    43: 2234056,
    44: 2223462,
    45: 2171200,
    46: 2117289,
    47: 2105965,
    48: 2088827,
    49: 2009895,
    50: 1930233,
    51: 1877070,
    52: 1814963,
    53: 1761836,
    54: 1702245,
    55: 1644532,
    56: 1639945,
    57: 1614360,
    58: 1579415,
    59: 1508629,
    60: 1466903,
    61: 1442808,
    62: 1407798,
    63: 1369323,
    64: 1338209,
    65: 1288341,
    66: 1217874,
    67: 1182606,
    68: 1144535,
    69: 1090995,
    70: 1007696,
    71: 942004,
    72: 883994,
    73: 823290,
    74: 738330,
    75: 672569,
    76: 604925,
    77: 547425,
    78: 485321,
    79: 440604,
    80: 391499,
    81: 351548,
    82: 293837,
    83: 243699,
    84: 199435,
    85: 837293,
    "75+": 0
  },
  {
    year: 1958,
    0: 4073113,
    1: 4081641,
    2: 3955569,
    3: 3911814,
    4: 3864465,
    5: 3776286,
    6: 3670427,
    7: 3608923,
    8: 3439532,
    9: 3445744,
    10: 3473006,
    11: 3749985,
    12: 2731853,
    13: 2754957,
    14: 2836085,
    15: 2957721,
    16: 2576464,
    17: 2415841,
    18: 2306640,
    19: 2244557,
    20: 2218649,
    21: 2137506,
    22: 2128631,
    23: 2190431,
    24: 2080581,
    25: 2089151,
    26: 2216196,
    27: 2335664,
    28: 2327219,
    29: 2303935,
    30: 2388297,
    31: 2429780,
    32: 2413401,
    33: 2503250,
    34: 2531425,
    35: 2468902,
    36: 2564641,
    37: 2571791,
    38: 2405883,
    39: 2340678,
    40: 2368515,
    41: 2330882,
    42: 2252125,
    43: 2250438,
    44: 2244353,
    45: 2214636,
    46: 2160534,
    47: 2109794,
    48: 2110924,
    49: 2060911,
    50: 1970024,
    51: 1903914,
    52: 1859706,
    53: 1792438,
    54: 1744354,
    55: 1704180,
    56: 1633094,
    57: 1636568,
    58: 1628208,
    59: 1533905,
    60: 1465009,
    61: 1446114,
    62: 1421798,
    63: 1372692,
    64: 1340078,
    65: 1320412,
    66: 1266948,
    67: 1188273,
    68: 1154064,
    69: 1125536,
    70: 1038546,
    71: 955589,
    72: 902398,
    73: 849766,
    74: 774993,
    75: 693623,
    76: 629920,
    77: 569887,
    78: 505659,
    79: 446987,
    80: 400988,
    81: 354724,
    82: 305632,
    83: 254028,
    84: 203622,
    85: 864481,
    "75+": 0
  },
  {
    year: 1959,
    0: 4097383,
    1: 4103414,
    2: 4092616,
    3: 3947785,
    4: 3933920,
    5: 3877965,
    6: 3764441,
    7: 3697523,
    8: 3569753,
    9: 3434413,
    10: 3478283,
    11: 3482375,
    12: 3758204,
    13: 2703780,
    14: 2797368,
    15: 2804400,
    16: 2919823,
    17: 2586987,
    18: 2431016,
    19: 2276715,
    20: 2247873,
    21: 2233988,
    22: 2137920,
    23: 2166724,
    24: 2182455,
    25: 2062600,
    26: 2137130,
    27: 2244217,
    28: 2304454,
    29: 2298561,
    30: 2314904,
    31: 2424507,
    32: 2405564,
    33: 2452018,
    34: 2524791,
    35: 2505462,
    36: 2486313,
    37: 2609363,
    38: 2487881,
    39: 2389131,
    40: 2366032,
    41: 2372830,
    42: 2286160,
    43: 2254757,
    44: 2264838,
    45: 2233517,
    46: 2207227,
    47: 2152367,
    48: 2112595,
    49: 2090332,
    50: 2016087,
    51: 1939474,
    52: 1888124,
    53: 1842910,
    54: 1779285,
    55: 1751321,
    56: 1696982,
    57: 1633812,
    58: 1652113,
    59: 1575047,
    60: 1491951,
    61: 1446654,
    62: 1424132,
    63: 1384605,
    64: 1344533,
    65: 1323956,
    66: 1299233,
    67: 1236144,
    68: 1164286,
    69: 1139912,
    70: 1074172,
    71: 987571,
    72: 921036,
    73: 870230,
    74: 801837,
    75: 728589,
    76: 650616,
    77: 596116,
    78: 526970,
    79: 467184,
    80: 407389,
    81: 364053,
    82: 308239,
    83: 265630,
    84: 213541,
    85: 901219,
    "75+": 0
  },
  {
    year: 1960,
    0: 4093802,
    1: 4104163,
    2: 4080463,
    3: 4077609,
    4: 3985212,
    5: 3958677,
    6: 3852247,
    7: 3797917,
    8: 3635589,
    9: 3565587,
    10: 3473252,
    11: 3489877,
    12: 3491706,
    13: 3700245,
    14: 2770401,
    15: 2759342,
    16: 2750756,
    17: 2938626,
    18: 2612578,
    19: 2380875,
    20: 2295794,
    21: 2265728,
    22: 2238646,
    23: 2174395,
    24: 2159614,
    25: 2165795,
    26: 2111395,
    27: 2163619,
    28: 2224932,
    29: 2269886,
    30: 2310669,
    31: 2355860,
    32: 2403565,
    33: 2441333,
    34: 2471476,
    35: 2497353,
    36: 2523550,
    37: 2533543,
    38: 2514885,
    39: 2473141,
    40: 2424758,
    41: 2370496,
    42: 2321952,
    43: 2290300,
    44: 2271385,
    45: 2250666,
    46: 2227858,
    47: 2195145,
    48: 2148220,
    49: 2092119,
    50: 2036229,
    51: 1977497,
    52: 1926119,
    53: 1882850,
    54: 1841419,
    55: 1795689,
    56: 1751551,
    57: 1700406,
    58: 1641590,
    59: 1581662,
    60: 1525828,
    61: 1467415,
    62: 1417997,
    63: 1383905,
    64: 1358968,
    65: 1331942,
    66: 1304419,
    67: 1269656,
    68: 1218968,
    69: 1155127,
    70: 1089339,
    71: 1023577,
    72: 955657,
    73: 886757,
    74: 818031,
    75: 749616,
    76: 681648,
    77: 614477,
    78: 548964,
    79: 485738,
    80: 425557,
    81: 368485,
    82: 315562,
    83: 267285,
    84: 224172,
    85: 940054,
    "75+": 0
  },
  {
    year: 1961,
    0: 4172988,
    1: 4084896,
    2: 4057693,
    3: 4080156,
    4: 4126537,
    5: 3998349,
    6: 3925679,
    7: 3890068,
    8: 3726338,
    9: 3655512,
    10: 3614497,
    11: 3476571,
    12: 3495250,
    13: 3434732,
    14: 3814413,
    15: 2734077,
    16: 2721876,
    17: 2781674,
    18: 2975853,
    19: 2545669,
    20: 2452277,
    21: 2315777,
    22: 2276632,
    23: 2246879,
    24: 2191006,
    25: 2170374,
    26: 2172236,
    27: 2121018,
    28: 2172992,
    29: 2233120,
    30: 2292994,
    31: 2313524,
    32: 2361971,
    33: 2404988,
    34: 2448385,
    35: 2475014,
    36: 2499150,
    37: 2523272,
    38: 2531451,
    39: 2511597,
    40: 2468852,
    41: 2419281,
    42: 2364895,
    43: 2316082,
    44: 2282829,
    45: 2262392,
    46: 2242108,
    47: 2210739,
    48: 2167263,
    49: 2116663,
    50: 2065078,
    51: 2007327,
    52: 1965612,
    53: 1936516,
    54: 1901801,
    55: 1851867,
    56: 1802980,
    57: 1739316,
    58: 1662232,
    59: 1590101,
    60: 1534453,
    61: 1478537,
    62: 1428861,
    63: 1392847,
    64: 1366154,
    65: 1339135,
    66: 1308975,
    67: 1280469,
    68: 1242949,
    69: 1188701,
    70: 1121903,
    71: 1054360,
    72: 983727,
    73: 910453,
    74: 838437,
    75: 769465,
    76: 701945,
    77: 634491,
    78: 568310,
    79: 504365,
    80: 443979,
    81: 385537,
    82: 331045,
    83: 281045,
    84: 235686,
    85: 964233,
    "75+": 0
  },
  {
    year: 1962,
    0: 4083751,
    1: 4160703,
    2: 4034016,
    3: 4060410,
    4: 4129913,
    5: 4137071,
    6: 3971329,
    7: 3962111,
    8: 3820035,
    9: 3750578,
    10: 3717727,
    11: 3606125,
    12: 3485569,
    13: 3446619,
    14: 3537894,
    15: 3769670,
    16: 2700044,
    17: 2751782,
    18: 2816416,
    19: 2912447,
    20: 2620396,
    21: 2464576,
    22: 2322896,
    23: 2281132,
    24: 2270117,
    25: 2197507,
    26: 2171778,
    27: 2182297,
    28: 2127996,
    29: 2177651,
    30: 2265719,
    31: 2280054,
    32: 2317585,
    33: 2358428,
    34: 2415151,
    35: 2449621,
    36: 2475642,
    37: 2498551,
    38: 2521431,
    39: 2528331,
    40: 2506986,
    41: 2462691,
    42: 2412210,
    43: 2356871,
    44: 2306453,
    45: 2272120,
    46: 2252143,
    47: 2224855,
    48: 2184752,
    49: 2138487,
    50: 2091873,
    51: 2038684,
    52: 1995779,
    53: 1973331,
    54: 1951592,
    55: 1909268,
    56: 1856471,
    57: 1789519,
    58: 1702118,
    59: 1613346,
    60: 1545564,
    61: 1489646,
    62: 1442477,
    63: 1405947,
    64: 1376882,
    65: 1347530,
    66: 1317006,
    67: 1284509,
    68: 1251866,
    69: 1210170,
    70: 1153063,
    71: 1084817,
    72: 1012356,
    73: 936221,
    74: 859634,
    75: 787457,
    76: 719204,
    77: 652199,
    78: 585788,
    79: 521276,
    80: 460568,
    81: 401800,
    82: 346133,
    83: 294796,
    84: 248012,
    85: 982198,
    "75+": 0
  },
  {
    year: 1963,
    0: 4012510,
    1: 4071602,
    2: 4105855,
    3: 4040638,
    4: 4110926,
    5: 4137064,
    6: 4114451,
    7: 4004689,
    8: 3892608,
    9: 3847088,
    10: 3825073,
    11: 3695383,
    12: 3617270,
    13: 3444174,
    14: 3545889,
    15: 3501539,
    16: 3724202,
    17: 2728595,
    18: 2785555,
    19: 2769149,
    20: 2993742,
    21: 2622269,
    22: 2465430,
    23: 2321819,
    24: 2310253,
    25: 2272391,
    26: 2195043,
    27: 2184246,
    28: 2190051,
    29: 2132221,
    30: 2222398,
    31: 2241035,
    32: 2285911,
    33: 2312604,
    34: 2374082,
    35: 2415506,
    36: 2449378,
    37: 2474073,
    38: 2496093,
    39: 2518130,
    40: 2523791,
    41: 2501247,
    42: 2455815,
    43: 2403801,
    44: 2346512,
    45: 2294990,
    46: 2260709,
    47: 2234924,
    48: 2200770,
    49: 2158820,
    50: 2116081,
    51: 2068191,
    52: 2027582,
    53: 2000456,
    54: 1983376,
    55: 1954413,
    56: 1909068,
    57: 1839258,
    58: 1750475,
    59: 1652735,
    60: 1568398,
    61: 1500230,
    62: 1453083,
    63: 1419014,
    64: 1389467,
    65: 1357847,
    66: 1325285,
    67: 1291689,
    68: 1253728,
    69: 1216024,
    70: 1171256,
    71: 1112758,
    72: 1039311,
    73: 961163,
    74: 881718,
    75: 805334,
    76: 733920,
    77: 666380,
    78: 600660,
    79: 536172,
    80: 475454,
    81: 416316,
    82: 360573,
    83: 308280,
    84: 260371,
    85: 1003418,
    "75+": 0
  },
  {
    year: 1964,
    0: 3946853,
    1: 3998118,
    2: 4013585,
    3: 4115310,
    4: 4090999,
    5: 4114053,
    6: 4119428,
    7: 4144687,
    8: 3935856,
    9: 3921848,
    10: 3933774,
    11: 3786885,
    12: 3707388,
    13: 3580494,
    14: 3538419,
    15: 3513943,
    16: 3462482,
    17: 3758932,
    18: 2762842,
    19: 2752014,
    20: 2846740,
    21: 2984824,
    22: 2618388,
    23: 2460044,
    24: 2358554,
    25: 2308915,
    26: 2265698,
    27: 2209452,
    28: 2191294,
    29: 2192610,
    30: 2187051,
    31: 2184852,
    32: 2246695,
    33: 2277689,
    34: 2332045,
    35: 2372325,
    36: 2413578,
    37: 2446394,
    38: 2470780,
    39: 2492463,
    40: 2513549,
    41: 2518435,
    42: 2494560,
    43: 2446979,
    44: 2392579,
    45: 2334084,
    46: 2282131,
    47: 2243234,
    48: 2212608,
    49: 2177806,
    50: 2139217,
    51: 2095845,
    52: 2058466,
    53: 2030287,
    54: 2006453,
    55: 1982543,
    56: 1950542,
    57: 1889533,
    58: 1800029,
    59: 1702226,
    60: 1608903,
    61: 1524071,
    62: 1464853,
    63: 1430865,
    64: 1403994,
    65: 1372266,
    66: 1338071,
    67: 1301962,
    68: 1261738,
    69: 1217757,
    70: 1176674,
    71: 1130510,
    72: 1066546,
    73: 987329,
    74: 906079,
    75: 827423,
    76: 752004,
    77: 681637,
    78: 615677,
    79: 552115,
    80: 491897,
    81: 432481,
    82: 376307,
    83: 323724,
    84: 274479,
    85: 1040022,
    "75+": 0
  },
  {
    year: 1965,
    0: 3770049,
    1: 3928418,
    2: 3935365,
    3: 4024448,
    4: 4165561,
    5: 4089460,
    6: 4101067,
    7: 4145207,
    8: 4074807,
    9: 3967031,
    10: 4020780,
    11: 3878958,
    12: 3800029,
    13: 3676247,
    14: 3672861,
    15: 3510524,
    16: 3476677,
    17: 3493366,
    18: 3804349,
    19: 2741815,
    20: 2828397,
    21: 2829293,
    22: 2974287,
    23: 2608334,
    24: 2506128,
    25: 2353320,
    26: 2297761,
    27: 2281873,
    28: 2215580,
    29: 2191676,
    30: 2260151,
    31: 2137036,
    32: 2190392,
    33: 2235678,
    34: 2301530,
    35: 2329048,
    36: 2369898,
    37: 2410563,
    38: 2443529,
    39: 2467646,
    40: 2488120,
    41: 2508284,
    42: 2511192,
    43: 2484440,
    44: 2434326,
    45: 2379012,
    46: 2319988,
    47: 2264928,
    48: 2223279,
    49: 2192928,
    50: 2160922,
    51: 2121977,
    52: 2086683,
    53: 2058183,
    54: 2031279,
    55: 2001015,
    56: 1974040,
    57: 1927957,
    58: 1849577,
    59: 1752428,
    60: 1658851,
    61: 1564762,
    62: 1489103,
    63: 1442985,
    64: 1416172,
    65: 1387207,
    66: 1353251,
    67: 1314860,
    68: 1270969,
    69: 1224004,
    70: 1176625,
    71: 1134421,
    72: 1082762,
    73: 1012772,
    74: 930126,
    75: 849660,
    76: 771665,
    77: 697251,
    78: 628482,
    79: 564733,
    80: 505885,
    81: 446842,
    82: 390716,
    83: 338150,
    84: 289252,
    85: 1081760,
    "75+": 0
  },
  {
    year: 1966,
    0: 3555346,
    1: 3754179,
    2: 3866725,
    3: 3953247,
    4: 4078477,
    5: 4164778,
    6: 4086431,
    7: 4127481,
    8: 4081690,
    9: 4113116,
    10: 4082146,
    11: 3952738,
    12: 3896753,
    13: 3777761,
    14: 3768291,
    15: 3650609,
    16: 3477928,
    17: 3507925,
    18: 3535518,
    19: 3789819,
    20: 2816582,
    21: 2800665,
    22: 2813697,
    23: 2955441,
    24: 2664034,
    25: 2496628,
    26: 2339109,
    27: 2318338,
    28: 2290407,
    29: 2217275,
    30: 2273628,
    31: 2198383,
    32: 2145554,
    33: 2179608,
    34: 2266239,
    35: 2299308,
    36: 2327682,
    37: 2367995,
    38: 2409227,
    39: 2442344,
    40: 2465450,
    41: 2485314,
    42: 2503209,
    43: 2502460,
    44: 2472528,
    45: 2421211,
    46: 2364671,
    47: 2303584,
    48: 2247692,
    49: 2207371,
    50: 2179413,
    51: 2147484,
    52: 2114281,
    53: 2084320,
    54: 2055086,
    55: 2022247,
    56: 1988847,
    57: 1949482,
    58: 1888550,
    59: 1803853,
    60: 1710650,
    61: 1615843,
    62: 1531088,
    63: 1468413,
    64: 1429058,
    65: 1399910,
    66: 1368739,
    67: 1329669,
    68: 1282238,
    69: 1230866,
    70: 1180517,
    71: 1132308,
    72: 1084674,
    73: 1026983,
    74: 953532,
    75: 872070,
    76: 792291,
    77: 715539,
    78: 643002,
    79: 576689,
    80: 518162,
    81: 460261,
    82: 404604,
    83: 352128,
    84: 303252,
    85: 1127697,
    "75+": 0
  },
  {
    year: 1967,
    0: 3450000,
    1: 3535546,
    2: 3688418,
    3: 3884351,
    4: 4004194,
    5: 4072240,
    6: 4165426,
    7: 4107922,
    8: 4065534,
    9: 4121978,
    10: 4243904,
    11: 3998066,
    12: 3972967,
    13: 3881399,
    14: 3866498,
    15: 3748478,
    16: 3616414,
    17: 3503826,
    18: 3544889,
    19: 3534168,
    20: 3882941,
    21: 2776018,
    22: 2777109,
    23: 2788578,
    24: 3023227,
    25: 2646286,
    26: 2473980,
    27: 2359639,
    28: 2324541,
    29: 2288828,
    30: 2310986,
    31: 2197971,
    32: 2207215,
    33: 2132456,
    34: 2214306,
    35: 2262957,
    36: 2297497,
    37: 2325766,
    38: 2367327,
    39: 2409301,
    40: 2441744,
    41: 2464690,
    42: 2482104,
    43: 2495653,
    44: 2491117,
    45: 2459682,
    46: 2406418,
    47: 2348920,
    48: 2289115,
    49: 2235902,
    50: 2197883,
    51: 2170768,
    52: 2142441,
    53: 2110896,
    54: 2077792,
    55: 2042681,
    56: 2006225,
    57: 1961818,
    58: 1910264,
    59: 1844667,
    60: 1763861,
    61: 1669258,
    62: 1584234,
    63: 1512742,
    64: 1456619,
    65: 1414629,
    66: 1383451,
    67: 1346416,
    68: 1297144,
    69: 1241462,
    70: 1186609,
    71: 1135613,
    72: 1081946,
    73: 1028250,
    74: 966960,
    75: 894893,
    76: 814050,
    77: 735659,
    78: 660983,
    79: 591131,
    80: 530576,
    81: 472767,
    82: 418377,
    83: 366464,
    84: 317793,
    85: 1186242,
    "75+": 0
  },
  {
    year: 1968,
    0: 3366388,
    1: 3430838,
    2: 3471495,
    3: 3709110,
    4: 3935584,
    5: 3995171,
    6: 4078802,
    7: 4183649,
    8: 4048616,
    9: 4108108,
    10: 4264703,
    11: 4140607,
    12: 4020126,
    13: 3964708,
    14: 3967100,
    15: 3850540,
    16: 3715305,
    17: 3640360,
    18: 3539053,
    19: 3558084,
    20: 3619894,
    21: 3811492,
    22: 2746998,
    23: 2747093,
    24: 2860687,
    25: 2997171,
    26: 2617403,
    27: 2497786,
    28: 2365794,
    29: 2321733,
    30: 2398208,
    31: 2221421,
    32: 2207531,
    33: 2191378,
    34: 2171326,
    35: 2210160,
    36: 2260707,
    37: 2295439,
    38: 2325479,
    39: 2368059,
    40: 2409347,
    41: 2441740,
    42: 2461820,
    43: 2474241,
    44: 2483719,
    45: 2477718,
    46: 2443940,
    47: 2391176,
    48: 2337170,
    49: 2281190,
    50: 2229848,
    51: 2193100,
    52: 2167182,
    53: 2136803,
    54: 2099908,
    55: 2061296,
    56: 2022374,
    57: 1976557,
    58: 1922765,
    59: 1868127,
    60: 1805981,
    61: 1723215,
    62: 1638593,
    63: 1567038,
    64: 1501981,
    65: 1442779,
    66: 1398652,
    67: 1360788,
    68: 1312478,
    69: 1254376,
    70: 1195293,
    71: 1140178,
    72: 1083811,
    73: 1024028,
    74: 966365,
    75: 906225,
    76: 834265,
    77: 754708,
    78: 678393,
    79: 606491,
    80: 542969,
    81: 482999,
    82: 429073,
    83: 378951,
    84: 331616,
    85: 1240679,
    "75+": 0
  },
  {
    year: 1969,
    0: 3412562,
    1: 3345777,
    2: 3364853,
    3: 3493737,
    4: 3758584,
    5: 3923088,
    6: 4007178,
    7: 4093268,
    8: 4125569,
    9: 4093622,
    10: 4262865,
    11: 4145705,
    12: 4165280,
    13: 4019792,
    14: 4047383,
    15: 3955996,
    16: 3819366,
    17: 3737682,
    18: 3676105,
    19: 3567070,
    20: 3642408,
    21: 3540175,
    22: 3761493,
    23: 2711237,
    24: 2824480,
    25: 2829895,
    26: 2956788,
    27: 2642288,
    28: 2501601,
    29: 2359906,
    30: 2444035,
    31: 2291001,
    32: 2231047,
    33: 2188598,
    34: 2235511,
    35: 2165933,
    36: 2206835,
    37: 2257746,
    38: 2294773,
    39: 2326226,
    40: 2368232,
    41: 2409687,
    42: 2438891,
    43: 2453350,
    44: 2461378,
    45: 2469363,
    46: 2460507,
    47: 2428585,
    48: 2381535,
    49: 2332481,
    50: 2277866,
    51: 2228111,
    52: 2189984,
    53: 2158263,
    54: 2120393,
    55: 2078471,
    56: 2035956,
    57: 1989460,
    58: 1937145,
    59: 1881960,
    60: 1830333,
    61: 1765604,
    62: 1692902,
    63: 1621868,
    64: 1556698,
    65: 1488120,
    66: 1426484,
    67: 1374516,
    68: 1324240,
    69: 1266546,
    70: 1205143,
    71: 1146136,
    72: 1085805,
    73: 1023647,
    74: 960394,
    75: 904503,
    76: 844551,
    77: 774174,
    78: 697112,
    79: 623926,
    80: 559029,
    81: 495767,
    82: 439600,
    83: 389645,
    84: 343637,
    85: 1307490,
    "75+": 0
  },
  {
    year: 1970,
    0: 3508096,
    1: 3401442,
    2: 3295270,
    3: 3406502,
    4: 3554881,
    5: 3756969,
    6: 3946319,
    7: 4019585,
    8: 4032394,
    9: 4164170,
    10: 4263921,
    11: 4138932,
    12: 4175165,
    13: 4174574,
    14: 4100251,
    15: 4043855,
    16: 3931856,
    17: 3847703,
    18: 3781499,
    19: 3728184,
    20: 3653488,
    21: 3556227,
    22: 3494642,
    23: 3716091,
    24: 2781658,
    25: 2802151,
    26: 2804268,
    27: 3000699,
    28: 2641863,
    29: 2487167,
    30: 2487628,
    31: 2320918,
    32: 2305215,
    33: 2218979,
    34: 2254644,
    35: 2225239,
    36: 2184809,
    37: 2222057,
    38: 2230004,
    39: 2292567,
    40: 2422721,
    41: 2363323,
    42: 2397821,
    43: 2392199,
    44: 2418912,
    45: 2465964,
    46: 2433301,
    47: 2415765,
    48: 2448256,
    49: 2387341,
    50: 2427106,
    51: 2299963,
    52: 2199245,
    53: 2130839,
    54: 2108622,
    55: 2105906,
    56: 2050273,
    57: 2010442,
    58: 1942941,
    59: 1896914,
    60: 1912080,
    61: 1791616,
    62: 1732996,
    63: 1640281,
    64: 1598736,
    65: 1550071,
    66: 1475305,
    67: 1408965,
    68: 1287308,
    69: 1304600,
    70: 1271974,
    71: 1146673,
    72: 1056582,
    73: 1013178,
    74: 978334,
    75: 919310,
    76: 839268,
    77: 787600,
    78: 699225,
    79: 625561,
    80: 605554,
    81: 539114,
    82: 445989,
    83: 382693,
    84: 338932,
    85: 1430493,
    "75+": 0
  },
  {
    year: 1971,
    0: 3601094,
    1: 3489276,
    2: 3348331,
    3: 3337236,
    4: 3468031,
    5: 3556777,
    6: 3787320,
    7: 3977564,
    8: 3974264,
    9: 4099362,
    10: 4333927,
    11: 4139134,
    12: 4174936,
    13: 4192739,
    14: 4254445,
    15: 4100796,
    16: 4019319,
    17: 3953863,
    18: 3878359,
    19: 3836171,
    20: 3796605,
    21: 3553466,
    22: 3509578,
    23: 3459578,
    24: 3839808,
    25: 2756978,
    26: 2773244,
    27: 2847814,
    28: 3003167,
    29: 2660149,
    30: 2631233,
    31: 2367317,
    32: 2343542,
    33: 2293383,
    34: 2281467,
    35: 2252836,
    36: 2222348,
    37: 2184977,
    38: 2219512,
    39: 2240196,
    40: 2304588,
    41: 2422420,
    42: 2361689,
    43: 2386965,
    44: 2382516,
    45: 2405799,
    46: 2450645,
    47: 2423579,
    48: 2407120,
    49: 2454763,
    50: 2374820,
    51: 2426681,
    52: 2288583,
    53: 2180835,
    54: 2105749,
    55: 2082108,
    56: 2081236,
    57: 2024298,
    58: 1982253,
    59: 1919926,
    60: 1856716,
    61: 1885972,
    62: 1772301,
    63: 1724388,
    64: 1633125,
    65: 1585875,
    66: 1532190,
    67: 1445724,
    68: 1367181,
    69: 1238556,
    70: 1239995,
    71: 1223707,
    72: 1091316,
    73: 1002799,
    74: 956616,
    75: 924315,
    76: 869665,
    77: 791081,
    78: 745950,
    79: 655410,
    80: 579633,
    81: 567154,
    82: 501102,
    83: 409310,
    84: 346871,
    85: 1487010,
    "75+": 0
  },
  {
    year: 1972,
    0: 3305565,
    1: 3567411,
    2: 3439449,
    3: 3392093,
    4: 3396835,
    5: 3468940,
    6: 3582479,
    7: 3823954,
    8: 3922524,
    9: 4048928,
    10: 4256429,
    11: 4215705,
    12: 4166881,
    13: 4193163,
    14: 4266512,
    15: 4257060,
    16: 4077625,
    17: 4037981,
    18: 3975748,
    19: 3947500,
    20: 3895789,
    21: 3696985,
    22: 3510975,
    23: 3481136,
    24: 3568208,
    25: 3810706,
    26: 2730854,
    27: 2815750,
    28: 2837491,
    29: 3045673,
    30: 2795484,
    31: 2510244,
    32: 2389361,
    33: 2329854,
    34: 2358520,
    35: 2275416,
    36: 2245082,
    37: 2222773,
    38: 2177719,
    39: 2237432,
    40: 2255241,
    41: 2294800,
    42: 2421323,
    43: 2348590,
    44: 2381131,
    45: 2368042,
    46: 2390643,
    47: 2442225,
    48: 2410240,
    49: 2421471,
    50: 2430489,
    51: 2381271,
    52: 2414096,
    53: 2272102,
    54: 2155553,
    55: 2078269,
    56: 2058659,
    57: 2056295,
    58: 1996197,
    59: 1964521,
    60: 1866721,
    61: 1843374,
    62: 1868900,
    63: 1762498,
    64: 1715315,
    65: 1617903,
    66: 1565388,
    67: 1499968,
    68: 1403814,
    69: 1317163,
    70: 1165820,
    71: 1200090,
    72: 1164701,
    73: 1041074,
    74: 946418,
    75: 902220,
    76: 877299,
    77: 821522,
    78: 751913,
    79: 698374,
    80: 600143,
    81: 545217,
    82: 526766,
    83: 462095,
    84: 369492,
    85: 1542441,
    "75+": 0
  },
  {
    year: 1973,
    0: 3128246,
    1: 3262329,
    2: 3522332,
    3: 3485898,
    4: 3452041,
    5: 3396993,
    6: 3491086,
    7: 3623683,
    8: 3761820,
    9: 4005252,
    10: 4194673,
    11: 4149643,
    12: 4236385,
    13: 4186826,
    14: 4262073,
    15: 4273105,
    16: 4236799,
    17: 4095001,
    18: 4052657,
    19: 4061281,
    20: 3998373,
    21: 3797654,
    22: 3655348,
    23: 3487642,
    24: 3582406,
    25: 3547445,
    26: 3774520,
    27: 2771417,
    28: 2792958,
    29: 2899904,
    30: 3177852,
    31: 2673715,
    32: 2531847,
    33: 2373292,
    34: 2396127,
    35: 2347687,
    36: 2262621,
    37: 2245782,
    38: 2210794,
    39: 2203108,
    40: 2256186,
    41: 2236841,
    42: 2294161,
    43: 2406060,
    44: 2346827,
    45: 2365456,
    46: 2353253,
    47: 2383629,
    48: 2424640,
    49: 2433195,
    50: 2386051,
    51: 2445651,
    52: 2367807,
    53: 2400538,
    54: 2246798,
    55: 2127097,
    56: 2055940,
    57: 2034584,
    58: 2027978,
    59: 1983358,
    60: 1897202,
    61: 1865564,
    62: 1827066,
    63: 1859114,
    64: 1750243,
    65: 1698598,
    66: 1594781,
    67: 1530467,
    68: 1458335,
    69: 1353465,
    70: 1232619,
    71: 1134140,
    72: 1139517,
    73: 1119838,
    74: 985307,
    75: 892135,
    76: 857800,
    77: 829257,
    78: 785936,
    79: 700339,
    80: 632789,
    81: 570527,
    82: 501303,
    83: 483987,
    84: 417054,
    85: 1606740,
    "75+": 0
  },
  {
    year: 1974,
    0: 3065474,
    1: 3076817,
    2: 3227239,
    3: 3571284,
    4: 3545915,
    5: 3450079,
    6: 3414384,
    7: 3536215,
    8: 3555210,
    9: 3849313,
    10: 4139447,
    11: 4098453,
    12: 4162888,
    13: 4258683,
    14: 4251081,
    15: 4273266,
    16: 4257208,
    17: 4253657,
    18: 4102644,
    19: 4154823,
    20: 4102553,
    21: 3900766,
    22: 3756547,
    23: 3635084,
    24: 3580457,
    25: 3566868,
    26: 3515744,
    27: 3824862,
    28: 2737606,
    29: 2876037,
    30: 3007714,
    31: 3047968,
    32: 2694785,
    33: 2512797,
    34: 2441019,
    35: 2380890,
    36: 2329766,
    37: 2264038,
    38: 2229425,
    39: 2244801,
    40: 2225105,
    41: 2229195,
    42: 2236552,
    43: 2277211,
    44: 2408506,
    45: 2330127,
    46: 2351167,
    47: 2348110,
    48: 2362543,
    49: 2456746,
    50: 2386889,
    51: 2409373,
    52: 2431597,
    53: 2356947,
    54: 2375247,
    55: 2217740,
    56: 2106654,
    57: 2033465,
    58: 2006778,
    59: 2021247,
    60: 1903449,
    61: 1910517,
    62: 1851218,
    63: 1816165,
    64: 1846090,
    65: 1732362,
    66: 1675017,
    67: 1558455,
    68: 1490560,
    69: 1407520,
    70: 1258581,
    71: 1211742,
    72: 1074128,
    73: 1100498,
    74: 1065364,
    75: 932416,
    76: 851913,
    77: 811685,
    78: 798790,
    79: 731586,
    80: 627172,
    81: 611424,
    82: 525655,
    83: 456169,
    84: 434142,
    85: 1706304,
    "75+": 0
  },
  {
    year: 1975,
    0: 3152345,
    1: 3005595,
    2: 3051605,
    3: 3276743,
    4: 3635195,
    5: 3545888,
    6: 3467501,
    7: 3467426,
    8: 3463857,
    9: 3649460,
    10: 3972391,
    11: 4056388,
    12: 4106910,
    13: 4189041,
    14: 4321156,
    15: 4268471,
    16: 4263489,
    17: 4274385,
    18: 4255537,
    19: 4222944,
    20: 4187886,
    21: 4007933,
    22: 3862914,
    23: 3742778,
    24: 3725325,
    25: 3572437,
    26: 3538718,
    27: 3564531,
    28: 3762889,
    29: 2841781,
    30: 2966547,
    31: 2893216,
    32: 3070791,
    33: 2673948,
    34: 2586404,
    35: 2422829,
    36: 2359517,
    37: 2333477,
    38: 2244458,
    39: 2273389,
    40: 2272232,
    41: 2191105,
    42: 2230660,
    43: 2218980,
    44: 2284183,
    45: 2391229,
    46: 2317277,
    47: 2348673,
    48: 2324153,
    49: 2403030,
    50: 2399716,
    51: 2419176,
    52: 2395310,
    53: 2424569,
    54: 2333508,
    55: 2346160,
    56: 2200058,
    57: 2086289,
    58: 2006936,
    59: 2006314,
    60: 1928253,
    61: 1931107,
    62: 1899484,
    63: 1839876,
    64: 1800531,
    65: 1827810,
    66: 1707370,
    67: 1636899,
    68: 1519930,
    69: 1440341,
    70: 1300705,
    71: 1248110,
    72: 1149406,
    73: 1038666,
    74: 1047781,
    75: 1013748,
    76: 896927,
    77: 807752,
    78: 785263,
    79: 742366,
    80: 650375,
    81: 612719,
    82: 566180,
    83: 479380,
    84: 403527,
    85: 1821040,
    "75+": 0
  },
  {
    year: 1976,
    0: 3115391,
    1: 3079357,
    2: 2986220,
    3: 3100598,
    4: 3335549,
    5: 3634324,
    6: 3560219,
    7: 3526690,
    8: 3387656,
    9: 3562384,
    10: 3756460,
    11: 3900197,
    12: 4055659,
    13: 4132265,
    14: 4243008,
    15: 4340062,
    16: 4259631,
    17: 4275907,
    18: 4265516,
    19: 4393025,
    20: 4243079,
    21: 4093340,
    22: 3970413,
    23: 3852956,
    24: 3826361,
    25: 3721512,
    26: 3545226,
    27: 3585646,
    28: 3490185,
    29: 3931716,
    30: 2912673,
    31: 2860541,
    32: 2914670,
    33: 3043499,
    34: 2753188,
    35: 2562647,
    36: 2396170,
    37: 2363665,
    38: 2308235,
    39: 2296776,
    40: 2304558,
    41: 2228690,
    42: 2192835,
    43: 2211075,
    44: 2229200,
    45: 2266037,
    46: 2378001,
    47: 2315799,
    48: 2320528,
    49: 2371690,
    50: 2335316,
    51: 2440346,
    52: 2403540,
    53: 2390178,
    54: 2400661,
    55: 2303077,
    56: 2329991,
    57: 2179968,
    58: 2059645,
    59: 2011432,
    60: 1901134,
    61: 1970410,
    62: 1921812,
    63: 1887693,
    64: 1820831,
    65: 1779323,
    66: 1801871,
    67: 1666707,
    68: 1599695,
    69: 1470385,
    70: 1322033,
    71: 1302736,
    72: 1184467,
    73: 1120924,
    74: 988452,
    75: 996921,
    76: 985573,
    77: 853527,
    78: 783383,
    79: 725770,
    80: 651460,
    81: 643468,
    82: 565230,
    83: 517210,
    84: 422671,
    85: 1896295,
    "75+": 0
  },
  {
    year: 1977,
    0: 3278804,
    1: 3031462,
    2: 3063419,
    3: 3034854,
    4: 3155120,
    5: 3333554,
    6: 3644207,
    7: 3625593,
    8: 3435855,
    9: 3490526,
    10: 3656833,
    11: 3695735,
    12: 3891370,
    13: 4081028,
    14: 4178966,
    15: 4264166,
    16: 4333169,
    17: 4268477,
    18: 4257434,
    19: 4417073,
    20: 4400497,
    21: 4149501,
    22: 4056115,
    23: 3964026,
    24: 3929083,
    25: 3826800,
    26: 3693845,
    27: 3589831,
    28: 3494422,
    29: 3672595,
    30: 4000734,
    31: 2815514,
    32: 2880847,
    33: 2887476,
    34: 3136162,
    35: 2722642,
    36: 2528601,
    37: 2400270,
    38: 2332394,
    39: 2369817,
    40: 2331327,
    41: 2251042,
    42: 2230600,
    43: 2171159,
    44: 2224619,
    45: 2209811,
    46: 2253199,
    47: 2377766,
    48: 2284194,
    49: 2376177,
    50: 2294077,
    51: 2383193,
    52: 2424062,
    53: 2401390,
    54: 2366486,
    55: 2369269,
    56: 2287932,
    57: 2310159,
    58: 2154049,
    59: 2070191,
    60: 1893305,
    61: 1955363,
    62: 1963589,
    63: 1909245,
    64: 1866559,
    65: 1798332,
    66: 1751748,
    67: 1758964,
    68: 1631626,
    69: 1549926,
    70: 1340517,
    71: 1335833,
    72: 1236501,
    73: 1162381,
    74: 1072264,
    75: 938830,
    76: 973432,
    77: 944545,
    78: 834999,
    79: 723205,
    80: 628694,
    81: 652138,
    82: 594765,
    83: 515163,
    84: 456302,
    85: 1991680,
    "75+": 0
  },
  {
    year: 1978,
    0: 3326388,
    1: 3178511,
    2: 3022264,
    3: 3117040,
    4: 3090801,
    5: 3156246,
    6: 3343315,
    7: 3721084,
    8: 3527457,
    9: 3551925,
    10: 3578599,
    11: 3609470,
    12: 3684367,
    13: 3921073,
    14: 4126053,
    15: 4208291,
    16: 4266046,
    17: 4345269,
    18: 4247327,
    19: 4429193,
    20: 4417809,
    21: 4312079,
    22: 4118791,
    23: 4059239,
    24: 4038042,
    25: 3939843,
    26: 3804556,
    27: 3742779,
    28: 3487909,
    29: 3707919,
    30: 3720727,
    31: 3881900,
    32: 2839749,
    33: 2858221,
    34: 2979500,
    35: 3100910,
    36: 2685064,
    37: 2537539,
    38: 2366745,
    39: 2406607,
    40: 2412529,
    41: 2271342,
    42: 2256218,
    43: 2209173,
    44: 2190694,
    45: 2206330,
    46: 2200002,
    47: 2256874,
    48: 2345248,
    49: 2349475,
    50: 2289787,
    51: 2351038,
    52: 2368039,
    53: 2426806,
    54: 2380002,
    55: 2336478,
    56: 2357458,
    57: 2271458,
    58: 2287211,
    59: 2172836,
    60: 1937220,
    61: 1961844,
    62: 1950836,
    63: 1951261,
    64: 1885688,
    65: 1842735,
    66: 1768230,
    67: 1706417,
    68: 1726241,
    69: 1581887,
    70: 1403978,
    71: 1364772,
    72: 1266367,
    73: 1221003,
    74: 1113647,
    75: 1021824,
    76: 911426,
    77: 931770,
    78: 935241,
    79: 770914,
    80: 617301,
    81: 632018,
    82: 600711,
    83: 540861,
    84: 449750,
    85: 2094958,
    "75+": 0
  },
  {
    year: 1979,
    0: 3426309,
    1: 3211427,
    2: 3173548,
    3: 3077017,
    4: 3174581,
    5: 3092294,
    6: 3163905,
    7: 3421248,
    8: 3613454,
    9: 3655724,
    10: 3634230,
    11: 3541822,
    12: 3593400,
    13: 3715376,
    14: 3960321,
    15: 4159794,
    16: 4214501,
    17: 4276495,
    18: 4316231,
    19: 4434889,
    20: 4419481,
    21: 4334896,
    22: 4284816,
    23: 4129391,
    24: 4127989,
    25: 4056482,
    26: 3919839,
    27: 3853208,
    28: 3621118,
    29: 3727046,
    30: 3733982,
    31: 3619819,
    32: 3907187,
    33: 2815685,
    34: 2948258,
    35: 2941406,
    36: 3050832,
    37: 2695152,
    38: 2496870,
    39: 2451245,
    40: 2454323,
    41: 2342000,
    42: 2277691,
    43: 2233036,
    44: 2233444,
    45: 2171900,
    46: 2196871,
    47: 2205236,
    48: 2222041,
    49: 2421037,
    50: 2253574,
    51: 2355872,
    52: 2335726,
    53: 2373821,
    54: 2405947,
    55: 2349711,
    56: 2327033,
    57: 2342711,
    58: 2247848,
    59: 2314488,
    60: 2020358,
    61: 2022892,
    62: 1959688,
    63: 1937597,
    64: 1925630,
    65: 1860719,
    66: 1811718,
    67: 1722200,
    68: 1675949,
    69: 1674541,
    70: 1423281,
    71: 1444508,
    72: 1293962,
    73: 1257794,
    74: 1173380,
    75: 1064334,
    76: 1001631,
    77: 871161,
    78: 926320,
    79: 860125,
    80: 653467,
    81: 625233,
    82: 580415,
    83: 545463,
    84: 470622,
    85: 2196921,
    "75+": 0
  }
];
